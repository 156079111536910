import { useState, useEffect } from 'react'
import { Link as RouteLink } from 'react-router-dom'
import { Flex, Text, Link, Icon, Box } from "@chakra-ui/react"
import { ImFacebook2, ImLinkedin, ImTwitter, ImInstagram, ImYoutube, ImPinterest } from "react-icons/im"
import { ExternalLinkIcon } from '@chakra-ui/icons'
import useDomain from '../../service/DomainHook';
import DomainUtils from '../../service/utils/DomainUtils'
import { getFooter } from '../../service/Footer'

const Footer = () => {
    const [hasSubDomain] = useDomain();

    const [footer, setFooter] = useState({});
    const [social, setSocial] = useState([]);
    const [hasMenu, setHasMenu] = useState(false);

    const getData = async () => {
        let url = `?tagType=BLOG&adminType=SA`;
        const params = {
            adminType: 'SA'
        };

        if (hasSubDomain) {
            params.adminType = 'CA';
            const [franchisee] = await DomainUtils();
            params.franchiseeId = franchisee;
            url = `?tagType=BLOG&adminType=CA&franchiseeId=${franchisee}`
        }

        const footerSection = await getFooter(params);
        const { menu } = footerSection.footer;
     
        if (menu.length > 0) {
            const hasMenuResult = menu.some(m => m.links.length > 0);
            setHasMenu(hasMenuResult);
        }else {
            setHasMenu(false);
        }
    
        setFooter(footerSection?.footer);
        setSocial(footerSection?.social);
    };

    useEffect(() => {
        getData();
    }, []);


    return (
        <>
            <footer id="footer" style={ footer?.style }>
                <div className="lan-container">
                    <div className="flex">
                        <div className="footer-left">
                            <Text as="h1" fontWeight="bold" fontSize="2rem">{footer?.title}</Text>
                            <Text as="small">{footer?.copyrightText}</Text>
                            <Flex mt={3} justifyContent="center">
                                {
                                    social?.[0]?.link &&
                                    <Link href={social[0]?.link} isExternal mr={5}>
                                        <Icon as={ImFacebook2} w={8} h={8} cursor="pointer" color="#3B5998" />
                                    </Link>
                                }

                                {
                                    social?.[1]?.link &&
                                    <Link href={social[1]?.link} isExternal mr={5}>
                                        <Icon as={ImLinkedin} w={8} h={8} cursor="pointer" color="#0E76A8" />
                                    </Link>
                                }

                                {
                                    social?.[2]?.link &&
                                    <Link href={social[2]?.link} isExternal mr={5}>
                                        <Icon as={ImTwitter} w={8} h={8} cursor="pointer" color="#00acee" />
                                    </Link>
                                }

                                {
                                    social?.[3]?.link &&
                                    <Link href={social[3]?.link} isExternal mr={5}>
                                        <Icon as={ImInstagram} w={8} h={8} cursor="pointer" color="#3F729B" />
                                    </Link>
                                }

                                {
                                    social?.[4]?.link &&
                                    <Link href={social[4]?.link} isExternal mr={5}>
                                        <Icon as={ImYoutube} w={8} h={8} cursor="pointer" color="#FF0000" />
                                    </Link>
                                }
                                {
                                    social[5]?.link &&
                                    <Link href={social[5]?.link} isExternal>
                                        <Icon as={ImPinterest} w={8} h={8} cursor="pointer" color="#FF0000" />
                                    </Link>
                                }
                            </Flex>

                        </div>

                        {
                            hasMenu &&
                            <div className="footer-right flex">
                                {
                                    footer?.menu?.map((item, index) => {
                                        if (item.links.length > 0) {
                                            return (
                                                <div className="footer-right-item" key={`${item.title}-${index}`}>

                                                    {
                                                        item.title !== ''
                                                            ? <Text mb={1} fontSize=".85rem" fontWeight="700">{item.title}</Text>
                                                            : null
                                                    }

                                                    <Box className="footer-link">
                                                        {
                                                            item.links.map((link, index) => (
                                                                link.linkType === "INTERNAL"
                                                                ? <Link
                                                                    letterSpacing="1px"
                                                                    p="2px"
                                                                    as={RouteLink}
                                                                    to={`/page-content/${link.slug}`}
                                                                    display="block"
                                                                    key={`${link._id}-${index}`}>
                                                                    {link.pageName}
                                                                </Link>
                                                                : <Link
                                                                    isExternal
                                                                    letterSpacing="1px"
                                                                    p="2px"
                                                                    // href={link.route}
                                                                    href={ link.route.includes("https://") || link.route.includes("http://")
                                                                    ? link.route
                                                                    : `//${link.route}`
                                                                    }
                                                                    display="block"
                                                                    key={`${link._id}-${index}`}>
                                                                    {link.name} <ExternalLinkIcon mt="-5px" />
                                                                </Link>
                                                            ))
                                                        }
                                                    </Box>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        }

                        
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer; 