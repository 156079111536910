import React, { useMemo, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import { config } from '../../../config'
import {
    Flex, Heading, Box, Image,
    FormLabel, FormControl, Text,
} from '@chakra-ui/react';
import DatePickerComponent from '../DatePickerComponent';
import TopSellingTable from './TopSellingTable';
import useDomain from '../../../service/DomainHook';


const TopSellingProduct = ({ styles }) => {
    const [startDate, setStartDate] = useState(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000));
    const [endDate, setEndDate] = useState(new Date());

    const [orderData, setOrderData] = useState([]);
    const [pageCount, setPageCount] = useState(0);

    const franchiseeId = localStorage.getItem('franchisee');
    const token = localStorage.getItem('token');
    const fetchIdRef = useRef(0);
    const baseURL = `${config.baseUrl}/api/v1`;
    const [hasSubDomain] = useDomain();

    const formatDate = (date) => {
        return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    };

    const onFetchData = useCallback(({ pageSize, pageIndex }) => {

        let payload = {
            franchiseeId: franchiseeId,
            fromDate: franchiseeId ? formatDate(startDate) : null,
            toDate: franchiseeId ? formatDate(endDate) : null,
        }

        const fetchId = ++fetchIdRef.current
        axios({
            url: '/products/topSellingProducts',
            method: 'POST',
            baseURL: baseURL,
            data: payload,
            headers: {
                'Authorization': `Bearer ${token}`
            }

        }).then((res) => {
            const serverData = res.data.data;
            if (fetchId === fetchIdRef.current) {
                const startRow = pageSize * pageIndex
                const endRow = startRow + pageSize
                setOrderData(serverData.slice(startRow, endRow))
                setPageCount(Math.ceil(serverData.length / pageSize))
            }

        }).catch((err) => {
            console.log(err.message);
        })

    }, [startDate, endDate])


    const columns = useMemo(() => [
        {
            Header: 'Image',
            accessor: 'image',
            Cell: (props) => {
                let data = props.row.original;
                return (
                    <Image src={data.image ? data.image : '/productImage.jpg' } width="60px" rounded="8px" />
                )
            }

        },
        {
            Header: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Total Sale (Qty)',
            accessor: 'totalSalesQty',
            Cell: props => (
                <Text as={"h6"} style={{ textAlign: 'center', marginRight: '25px' }} >
                    {props.value}
                </Text>
            )
        },
        {
            Header: 'Total Sale (Price)',
            accessor: 'totalSalesPrice',
            Cell: props => {
                let data = props.row.original
                return (
                    <span>${parseFloat(data.totalSalesPrice).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                );
            }

        }
    ], []);

    const data = useMemo(() => (orderData), [orderData]);


    return (
        <Box {...styles} width={["100%", "100%", "50%", "50%"]}>
            <Heading size="md" textAlign="center">Top Selling Products</Heading>

            {
                hasSubDomain ?
                    <Flex justifyContent="flex-end" align='center' mt={2}>
                        <FormControl flex="1 1">
                            <Flex justifyContent="flex-end">
                                <FormLabel alignSelf="flex-end" m={0} px="10px">From Date:</FormLabel>
                                <DatePickerComponent startDate={startDate} setStartDate={setStartDate} />
                            </Flex>
                        </FormControl>
                        <FormControl flex="1 1">
                            <Flex justifyContent="flex-end">
                                <FormLabel alignSelf="flex-end" m={0} px="10px">To Date:</FormLabel>
                                <DatePickerComponent startDate={endDate} setStartDate={setEndDate} />
                            </Flex>
                        </FormControl>
                    </Flex>
                    : null
            }

            <TopSellingTable columns={columns} onFetchData={onFetchData} data={data} pageCount={pageCount} title='' />

        </Box>
    )
}

export default TopSellingProduct;