import React from 'react'
import { useForm } from 'react-hook-form';

import {
    Box, Input, Button, useToast,
    FormControl, FormLabel, FormErrorMessage, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import axios from 'axios';
import { config } from '../../../config';


const AddTag = ({ onClose, isOpen, getTagList, setValue, getValues }) => {


    const toast = useToast();

    const baseURL = `${config.baseUrl}/api/v1`;

    const { register, handleSubmit, errors } = useForm({
        mode: 'onTouched',
        shouldFocusError: false
    })

    const tagSubmitHandler = (data) => {
        const token = localStorage.getItem('token');
        const franchiseeId = localStorage.getItem('franchiseeId');


        data.adminType = 'SA';

        if (franchiseeId !== "null") {
            data.adminType = "CA";
            data.franchiseeId = franchiseeId;
        }

        axios({
            url: '/tags',
            method: 'post',
            baseURL: baseURL,
            headers: { 'Authorization': `Bearer ${token}` },
            data
        })
            .then((res) => {
                const { name, _id } = res.data.data;
                const tags = getValues('tags');
                tags?.push({ value: _id, label: name });
                setValue('tags', tags);
                toast({
                    title: "Tag created successfully",
                    description: "You have just created a tag successfully",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                })

            }).then(() => {
                getTagList();
            })
            .catch((err) => {
                console.log({ err })
                if (err.response) {
                    toast({
                        title: "Error!",
                        description: err.response.data.message,
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    })
                } else {
                    toast({
                        title: "Error!",
                        description: "Something went wrong!",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    })
                }
            })
    }
    return (
        <Box mt='5px'>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add New Tag</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                        <form onSubmit={handleSubmit(tagSubmitHandler)}>
                            <FormControl mb="15px" isInvalid={errors.name}>
                                <FormLabel m={0}> Name </FormLabel>
                                <Input
                                    placeholder="Enter Tag Name"
                                    ref={register({ required: 'Tag Name is required' })}
                                    variant="flushed" borderColor="gray.300" size="sm"
                                    name="name" type="text" />
                                <FormErrorMessage mb="20px">
                                    {errors?.name?.message}
                                </FormErrorMessage>
                            </FormControl>

                            <Button onClick={onClose} type="submit" colorScheme="teal" variant="outline" size="sm" rounded={4}>
                                Save
                            </Button>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
};

export default AddTag;