import React, { useState, useRef, useCallback } from 'react';
import {
    Container, Box, Flex, Button, Spacer, Link, Heading
} from '@chakra-ui/react';
import { Link as RouteLink, useRouteMatch } from 'react-router-dom'
import axios from 'axios'

import { config } from '../../config'
import FranchiseeTable from './FranchiseeTable'
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb'
import useDomain from '../../service/DomainHook';


function Franchisees(props) {
    const { baseUrl } = config
    let { url } = useRouteMatch();
    const [hasSubDomain] = useDomain();

    let [list, setList] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const fetchIdRef = useRef(0)

    let columns = [
        {
            Header: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Email',
            accessor: 'email'
        },
        {
            Header: 'Phone',
            accessor: 'phone'
        },
        {
            Header: 'Generated Domain',
            accessor: 'generatedDomain'
        },
        {
            Header: 'Domain',
            accessor: 'domain'
        }
    ]

    let sortOn = ['Name', 'Email']


    const fetchData = useCallback(({ pageSize, pageIndex, globalFilter, skipPageResetRef }) => {
        const token = localStorage.getItem('token');
        const fetchId = ++fetchIdRef.current
        const fields = 'name,email,phone,generatedDomain,domain,createdAt';

        if (globalFilter) {
            // Filtering on name field
            axios({
                url: `/api/v1/franchisees?search=${globalFilter}`,
                method: 'get',
                baseURL: baseUrl,
                headers: { 'Authorization': `Bearer ${token}` }
            })
                .then((res) => {
                    const { data: serverData, result: totalLength } = res.data
                    if (fetchId === fetchIdRef.current) {
                        if (serverData.length >= 1) {
                            skipPageResetRef.current = true
                            setList(serverData.reverse())
                            setPageCount(Math.ceil(totalLength / pageSize))
                        }
                    }
                })
                .catch(err => console.log(err, 'Data fetch error'))
        }
        else {
            // Pagination 
            axios({
                url: `/api/v1/franchisees?page=${(pageIndex) + 1}&limit=${pageSize}&fields=${fields}&sort=-createdAt`,
                method: 'get',
                baseURL: baseUrl,
                headers: { 'Authorization': `Bearer ${token}` }
            })
                .then((res) => {
                    const { data: serverData, result: totalLength } = res.data
                    if (fetchId === fetchIdRef.current) {
                        skipPageResetRef.current = true
                        setList(serverData.reverse())
                        setPageCount(Math.ceil(totalLength / pageSize))
                    }
                })
                .catch(err => console.log(err, 'Data fetch error'))
        }
    }, [])


    return (
        <>
            {
                props?.componentType != "dashboard" ?
                    <Container maxWidth={1100} centerContent py={6}>
                        <Box w="100%" bg="white" rounded="lg" boxShadow="lg" p={4}>
                            <Flex>
                                <Box>
                                    <Breadcrumb pathname="Franchisee" />
                                </Box>
                                <Spacer />
                                <Box>
                                    <Button colorScheme="teal" variant="outline" size="sm">
                                        <Link color="blue.500" lineHeight="32px" as={RouteLink} to={`${url}/register`}>
                                            Register
                                        </Link>
                                    </Button>
                                </Box>
                            </Flex>


                            <FranchiseeTable
                                data={list}
                                columns={columns}
                                pageCount={pageCount}
                                fetchData={fetchData}
                                sortOn={sortOn}
                                defaultPageSize={10}
                                tableHeightInPage='50vh'
                                selectNoOfRows={[5, 10, 20, 30, 50, 100]}
                                componentType={props?.componentType}
                            />
                        </Box>
                    </Container>
                    :
                    <Flex flexDirection="column" {...props?.styles} width={["100%", "100%", "50%", "50%"]}>
                        <Heading size="md" textAlign="center" mb={6}>Recent Franchisees</Heading>
                        <FranchiseeTable
                            data={list}
                            columns={columns}
                            pageCount={pageCount}
                            fetchData={fetchData}
                            sortOn={sortOn}
                            defaultPageSize={10}
                            tableHeightInPage='59vh'
                            selectNoOfRows={[5, 10, 20, 30, 50, 100]}
                            componentType={props?.componentType}
                        />
                    </Flex>

            }
        </>
    )
}

export default Franchisees;