import {useState, useRef, useCallback} from 'react';
import { Link as RouteLink, useRouteMatch } from 'react-router-dom';
import {
    Box, Container, Flex, Button, Link 
} from '@chakra-ui/react'; 
import axios from 'axios'; 
import {config} from '../../config';

import GreetingTable from './GreetingTable'; 
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';


function Greeting() { 
    const [allCards, setAllCards] = useState([]); 
    const baseURL = `${config.baseUrl}/api/v1`; 
    const token = localStorage.getItem('token'); 
    const franchiseeId = localStorage.getItem('franchiseeId'); 
    let { url } = useRouteMatch(); 

    const [pageCount, setPageCount] = useState(0); 
    const fetchIdRef = useRef(0); 

    let columns = [ 
        { 
            Header: 'Name', 
            accessor: 'name'
        },
        // { 
        //     Header: 'Business', 
        //     accessor: 'businessId.name'
        // },
        { 
            Header: 'Price', 
            accessor: 'price'
        },
        { 
            Header: 'Active', 
            accessor: 'active'
        } 
    ] 
    let sortOn = ['Name', 'Price']; 

    // if(role === 'SUPERADMIN') { 
    //     for(let i = columns.length; i > 1; i--) { 
    //         columns[i] = columns[i-1]; 
    //     } 
    //     columns[1] = { 
    //         Header: 'Franchisee', 
    //         accessor: 'franchiseeId.name' 
    //     } 
    //     sortOn.push('Franchisee'); 
    // } 


    const fetchData = useCallback(({ pageSize, pageIndex, globalFilter, skipPageResetRef }) => { 
        const fetchId = ++fetchIdRef.current; 
        const fields = 'name,price,active,franchiseeId,businessId';
        
        let getUrl = franchiseeId !== 'null'
            ? `greetingCards?franchiseeId=${franchiseeId}&` 
            : 'greetingCards?'; 
        
        let getSearchUrl = franchiseeId !== 'null' 
            ? `greetingCards?franchiseeId=${franchiseeId}&` 
            : 'greetingCards?'; 
        
        if(globalFilter) { 
          // Filtering on name field
            axios({
                url: `${getSearchUrl}search=${globalFilter}`,
                method: 'get',
                baseURL: baseURL, 
                headers: {'Authorization': `Bearer ${token}`} 
            }) 
            .then((res) => { 
                const {data: serverData, result: totalLength} = res.data 
                if (fetchId === fetchIdRef.current) { 
                    if(serverData.length >= 1) { 
                        skipPageResetRef.current = true 
                        setAllCards(serverData) 
                        setPageCount(Math.ceil(totalLength/pageSize)) 
                    } 
                } 
            }) 
            .catch(err => console.log(err, 'Data fetch error')) 
        } 
        else { 
          // Pagination 
            axios({ 
                url: `${getUrl}page=${(pageIndex)+1}&limit=${pageSize}&fields=${fields}`, 
                method: 'get', 
                baseURL: baseURL, 
                headers: {'Authorization': `Bearer ${token}`} 
            }) 
            .then((res) => { 
                const {data: serverData, result: totalLength} = res.data 
                if (fetchId === fetchIdRef.current) { 
                    skipPageResetRef.current = true 
                    setAllCards(serverData) 
                    setPageCount(Math.ceil(totalLength/pageSize)) 
                } 
            }) 
            .catch(err => console.log(err, 'Data fetch error')) 
        } 
    }, []) 



    return ( 
        <Container maxWidth={1100} centerContent  my={6}> 
            <Box w="100%" bg="white" rounded="lg" boxShadow="lg"> 
                <Flex justifyContent="space-between" pt={4} px={4}> 
                    <Breadcrumb pathname="Greeting Card" /> 
                    <Button colorScheme="teal" variant="outline" size="sm">
                        <Link color="blue.500" lineHeight="32px" as={RouteLink} to={`${url}/create`}> 
                            Create new 
                        </Link> 
                    </Button> 
                </Flex> 

                
                <GreetingTable 
                    data={allCards} 
                    columns={columns} 
                    pageCount={pageCount} 
                    fetchData={fetchData} 
                    sortOn={sortOn} 
                    defaultPageSize={10} 
                    tableHeightInPage='58vh' 
                    selectNoOfRows={[5, 10, 20, 30, 50, 100]} 
                /> 
            </Box> 
        </Container> 
    ) 
} 

export default Greeting; 