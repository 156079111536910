import React from 'react'; 
import { Box, SimpleGrid } from '@chakra-ui/react'; 
import SingleProduct from '../SingleProduct';

function ProductList(props) { 
    const { products } = props; 

    return ( 
        <Box> 
            { 
                products.length > 0 && 
                <SimpleGrid 
                    columns={{base: 1, sm: 2, md: 2, lg: 3, xl: 4, '2xl': 5}}
                    spacing="15px" mb={5}> 
                    { 
                        products.map((p, i) => <SingleProduct key={i} product={p} /> ) 
                    } 
                </SimpleGrid> 
            } 
        </Box> 
    ) 
} 

export default ProductList; 