import React, { Component } from 'react';
import parse from 'html-react-parser';


class GoogleAd extends Component {
  googleInit = null;

  componentDidMount() {
    this.googleInit = setTimeout(() => {
      if (typeof window !== 'undefined'){
        console.log('Running ads');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    }, 200);
  }

  componentWillUnmount() {
    if (this.googleInit){
        console.log('Running ads');
        clearTimeout(this.googleInit);
    }
  }

  render() {
    // const { classNames, slot, googleAdId, style, format } = this.props;
    const options = {
      replace: (domNode) => {
        if (domNode.attribs && domNode.attribs.class === 'remove') {
          return <></>;
        }
      },
    };
  
    return (
      <div>
        {/* <ins className="adsbygoogle" style={{display:'block', textAlign:'center'}} data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-3004774718992198" data-ad-slot="7309351058"></ins> */}
        {parse(this.props.ins, options)}
      </div>
    );
  }
}

export default GoogleAd;