import React from "react";
import { Box, Text } from '@chakra-ui/react';

const ListContainer = (props) => { 
  const { node, children } = props; 

  return ( 
    <Box 
      px={2} pb={2} pt={1}
      boxShadow="md" rounded="4px"
      m="0" bg="gray.200"
    > 
      <Text my={3} mx={1}> {node?.name} </Text> 
      <Box> {children} </Box> 
    </Box> 
  ) 
} 


export default ListContainer; 