import React, { useState } from 'react';
import {
    Box, Text, Flex, Input, Textarea, Image,
    Button,
    FormControl, FormLabel,
    useToast
} from '@chakra-ui/react'
import { config } from '../../config'
import axios from 'axios';
import { FaTrash } from 'react-icons/fa';

function About({ id, register, setPageContent, pageContent }) {
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);

    const toast = useToast();
    const { baseUrl } = config;

    function imageHandler(e) {
        const formData = new FormData();
        formData.append('aboutImage', e.target.files[0]);
        setLoading(true);

        axios({
            url: `/api/v1/pages/upload/${id}`,
            method: 'post',
            baseURL: baseUrl,
            data: formData,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => {
                setPageContent({
                    ...pageContent,
                    pageAbout: {
                        ...pageContent.pageAbout,
                        image: response.data.data.pageAbout.image
                    }
                });
                toast({
                    title: "About image updated successfully.",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                })
            })
            .catch(err => {
                console.log('error', err);
                toast({
                    title: "About image update failed.",
                    description: err?.response?.data?.message,
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                })
            }).finally(() => {
                setLoading(false);
            })
    }


    const removeAboutImage = () => {
        const about = pageContent.pageAbout;
        about.image = '';

        const dataObj = {
            imageUrl: pageContent.pageAbout.image,
            field: "pageAbout.image"
        };

        axios({
            url: `/api/v1/pages/removeImage/${id}`,
            method: 'PATCH',
            baseURL: baseUrl,
            data: dataObj,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            setPageContent({
                ...pageContent,
                pageAbout: about
            });
            toast({
                title: "About image deleted successfully.",
                status: "success",
                duration: 4000,
                isClosable: true,
            })
        })
            .catch(error => {
                console.log(error.message);
            });
    };

    return (
        <Box bg="white" rounded="md" boxShadow="lg" p={{ base: '10px', md: '30px' }} mt="3rem">
            <Text as="h1" fontSize="25px" mb="20px">
                About Section
            </Text>
            <Flex mb="20px" alignItems="center" flexWrap="wrap">
                <FormControl>
                    <FormLabel fontWeight="600"> Title </FormLabel>
                    <Input
                        type="text" placeholder="Title" name="about_title"
                        variant="flushed"
                        ref={register()}
                    />
                </FormControl>
            </Flex>

            <Flex>
                <FormControl>
                    <FormLabel fontWeight="600"> Details </FormLabel>
                    <Textarea
                        placeholder="Details"
                        name="about_text"
                        variant="flushed"
                        minH='150px'
                        p={2}
                        ref={register()}
                    />
                </FormControl>
            </Flex>

            <Box mt='15px'>
                <Text fontWeight="600">About Image</Text>
                <FormControl mb={2}>
                    <Box
                    >
                        <FormLabel htmlFor='aboutImg'
                            w='100%'
                            h='100%'
                            lineHeight="60px"
                            border="1px dashed #BDBDBD"
                            textAlign="center" cursor="pointer"
                            // color="gray.500" 
                            color={loading ? 'teal.500' : "gray.500"}
                            p='10px'
                        >

                            {loading ? 'Uploading About Image' : ' Click To Upload About Image'}

                        </FormLabel>
                        <Input
                            isDisabled={loading}
                            type="file"
                            name="aboutImg"
                            id='aboutImg'
                            accept="image/*"
                            onChange={(e) => imageHandler(e)}
                            style={{ display: "none" }} />

                    </Box>

                </FormControl>
            </Box>

            {
                pageContent.pageAbout.image &&
                <Box bg="red.300" display="inline-block" position="relative">
                    <Image src={pageContent.pageAbout.image} alt="header_image" style={{ maxHeight: '250px' }} />
                    <Button
                        position="absolute"
                        top="2px"
                        right="2px"
                        size="xs"
                        borderRadius={0}
                        _hover={{ outline: 'none' }}
                        _focus={{ outline: 'none' }}
                        _active={{ bg: 'red.400', color: 'white' }}
                        onClick={removeAboutImage}
                    >
                        <FaTrash />
                    </Button>
                </Box>
            }

        </Box>
    )
}

export default About;













