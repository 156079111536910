import React from 'react'
import { Box, Image } from '@chakra-ui/react'
// import { StarIcon } from '@chakra-ui/icons'
import { useHistory } from 'react-router-dom'
import {ClampText, ClampTitle} from '../../components/elements/ClampedText'

function SingleProduct(props) {
    const theme = JSON.parse(localStorage.getItem('theme')); 
    // const pReview = JSON.parse(localStorage.getItem('pReview')); 
    const product = props.product;
    const history = useHistory();
    const noImage ='https://via.placeholder.com/350/000000/FFFFFF/?text=No Product Image'

    return (

        <>
            {
                product ? 
                    <Box 
                        key={product._id} 
                        w="100%" 
                        borderWidth="1px" 
                        rounded="md"  
                        borderColor="gray.200" 
                        overflow="hidden" 
                        cursor="pointer"  
                        onClick={() => history.push(`/shop/product/${product.slug}`, { product: product })}
                        style={{
                            display: "grid",
                            alignContent: "space-between"
                        }}
                    >
                        <Image w="100%" 
                            // height={{base: "22rem", sm: "22rem", md: "22rem", lg: "22rem", xl: "22rem" }}
                            // width={{base: "22rem", sm: "22rem", md: "22rem", lg: "22rem", xl: "22rem" }}
                            objectFit="cover" 
                            src={
                                product.businessId?.image ?
                                    product.businessId?.image :
                                    product.images.links.length > 0 ?
                                        product.images.links[product.images.defaultImage - 1] : noImage
                                } 
                            alt={product.businessId?.name || product.name} 
                            cursor="pointer"
                           
                        /> 

                        <Box p="3" height="140px">  
                            <ClampTitle
                                size=".9rem" weight="700" line={2}
                                color={`${theme.color.primary}`}
                            >
                                {product?.name}
                            </ClampTitle>
                            <ClampText size=".8rem" weight="300" line={3} color={`${theme.color.secondary}`}>{product.summary}</ClampText>
                            <Box mt="6px">
                                <Box 
                                    as="span" 
                                    fontSize=".9rem" 
                                    fontWeight="700"
                                    color={`${theme.color.primary}`}
                                >
                                    {
                                    product.priceType === "RANGED" 
                                    ? `$${product.minPrice.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}+`
                                    : `$${product.price.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
                                    }
                                </Box>
                            </Box>
                        </Box> 
                    </Box> 
                : null 
            } 
        </> 
    )
}

export default SingleProduct
