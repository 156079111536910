import React from 'react';
import { Box, Flex, Text, Icon } from '@chakra-ui/react' 
import { StarIcon } from '@chakra-ui/icons' 
import { FaUserAlt } from "react-icons/fa"; 
import mapDate from '../../service/utils/MapDate'

function ProductReviewList(props) { 
    const theme = JSON.parse(localStorage.getItem('theme')); 
    const {reviews, total} = props 
    return ( 
        <Box> 
            { 
                reviews.length > 0 && 
                <Box mt="30px"> 
                    <Text fontWeight="600" mb="10px"> 
                        Reviews ({total}) : 
                    </Text> 
                    { 
                        reviews.map((review, i) => { 
                            const reviewRating = [] 
                            for(let i = 1; i <= 5; i++) { 
                                if(i <= review.rating) { 
                                    reviewRating.push({iconColor: `rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 1'})`}) 
                                } 
                                else { 
                                    reviewRating.push({iconColor: 'gray.400'}) 
                                } 
                            } 
                            const date = mapDate(review.commentAt); 
                            
                            return ( 
                                <Box 
                                    key={i} mt="30px" p={6} rounded="md" boxShadow="sm" 
                                    borderWidth="1px" borderColor="gray.200"> 
                                    <Flex> 
                                        <Icon as={FaUserAlt} color="gray.700" /> 
                                        <Text 
                                            fontWeight="700" mb="2px" ml="10px" 
                                            fontSize="14px" color="gray.700" > 
                                            {review.authorName} 
                                        </Text> 
                                    </Flex> 
                                    <Text fontSize="13px" color="gray.400" fontStyle="italic" mb="10px">
                                        Created at: {`${date.day}-${date.month}-${date.year}`}
                                    </Text> 

                                    <Box> 
                                        { 
                                            reviewRating.map((obj, i) => ( 
                                                <StarIcon 
                                                    key={i} 
                                                    w={4} h={4} mr="5px" 
                                                    color={obj.iconColor} 
                                                /> 
                                            )) 
                                        } 
                                    </Box>
                                    <Text fontSize="14px" mt="25px" color="gray.600"> 
                                        {review.message} 
                                    </Text> 
                                </Box> 
                            ) 
                        }) 
                    } 
                </Box> 
            } 
        </Box> 
    )
}

export default ProductReviewList;