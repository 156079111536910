import React, { useState, useEffect, useRef } from 'react'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import dataURItoBlob from '../Blog/helpers/dataURLtoBlob';
import {
    Box, Flex, Input,
    FormControl, FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, Button, useToast, Text
} from '@chakra-ui/react';

const ProductImageModal = ({ files, setFiles, isOpen, onClose, isEdit }) => {

    const [image, setImage] = useState('');
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();
    const [isCropped, setIsCropped] = useState(false);
    const isMounted = useRef(null);
    const toast = useToast();

    function imageHandler(e) {

        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setImage(reader.result);
            }
        };

        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0])
        };
    };

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas().toDataURL());
            setIsCropped(true);
            toast({
                title: "Image Is Cropped",
                description: 'Image Successfully Cropped',
                status: "success",
                duration: 2000,
                isClosable: true,
                // position: 'top'
            })
        };
    };

    const saveImage = () => {
        if (!image) {
            toast({
                title: "Please Select Image",
                description: 'Select Image To Upload',
                status: "error",
                duration: 1500,
                isClosable: true
            })
            return;
        };

        if (image && !isCropped) {
            toast({
                title: "Product Image Must Be Cropped",
                description: 'Crop Your Product Image',
                status: "error",
                duration: 1000,
                isClosable: true
            })
            return;
        };

        let blob = dataURItoBlob(cropData);
        setFiles([...files, blob]);

        if(!isEdit && isCropped) {
            toast({
                title: "Product Image Added Successfully.",
                status: "success",
                duration: 4000,
                isClosable: true,
            })
        }

        if (isEdit) {
            onClose();
        }

    };

    useEffect(() => {

    }, [cropData]);

    useEffect(() => {

        isMounted.current = true;

        return function cleanup() {
            isMounted.current = false;
        };

    }, []);


    return (

        <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Product Image</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl my="15px">
                        <Box>
                            <FormLabel
                                w='100%'
                                h='100%'
                                lineHeight="60px"
                                border="1px dashed #BDBDBD"
                                htmlFor='product_image' textAlign="center" cursor="pointer"
                                // color="gray.500"
                                p='10px'
                            >
                                Click To Upload Image
                            </FormLabel>
                            <Input
                                type="file"
                                name="product_image"
                                id='product_image'
                                accept="image/*"
                                onChange={(e) => imageHandler(e)}
                                style={{ display: "none" }} />
                        </Box>
                    </FormControl>
                    <Flex>
                        <Flex flexDirection='column'>
                            {image ? <Cropper
                                style={{ height: 350, width: "100%" }}
                                zoomTo={0.0}
                                initialAspectRatio={1}
                                src={image}
                                aspectRatio={1}
                                viewMode={1}
                                preview=".img-preview"
                                minCropBoxHeight={10}
                                minCropBoxWidth={10}
                                background={false}
                                responsive={true}
                                autoCropArea={1}
                                cropBoxResizable={false}
                                checkOrientation={false}
                                onInitialized={(instance) => {
                                    setCropper(instance);
                                }}
                                guides={true}
                            />
                                : null}
                            <Box mt='5px' ml='auto'>
                                {image ? <Button
                                    _hover={{ outline: 'none' }}
                                    _focus={{ outline: 'none' }}
                                    _active={{ bg: 'transparent', color: 'teal' }}
                                    colorScheme="teal" variant="outline" size="sm"
                                    onClick={getCropData}>
                                    Crop Image
                                </Button> : null}
                            </Box>
                        </Flex>

                        {image ? <Box display="inline-block" p='0 10px 10px' boxSizing="border-box">
                            <Text>Preview</Text>
                            <Box className="img-preview" overflow="hidden" w='300px' height='300px'>
                            </Box>
                        </Box> : null}
                    </Flex>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='red' variant="outline" mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button variant='outline' colorScheme='green' onClick={saveImage}>Save</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>

    )
}

export default ProductImageModal