import {
    Box, Button, Container, FormControl, FormErrorMessage, Input, useToast
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import { config } from '../../config';
import useDomain from '../../service/DomainHook';
import DomainUtils from '../../service/utils/DomainUtils';
import SubCategories from './SubCategories';

function Category() {
    const { control, register, handleSubmit, errors } = useForm({
        mode: 'onTouched',
        shouldFocusError: false
    });

    const [categories, setCategory] = useState([]);
    const [subCategories, setSubCategory] = useState([]);

    const baseUrl = config.baseUrl;
    const history = useHistory();
    const toast = useToast();
    const [hasSubDomain] = useDomain();
    const franchiseeId = localStorage.getItem('franchiseeId');
    const token = localStorage.getItem('token');


    const optionMapHandler = (options) => {

        let newOptions = [];

        if (options.length) {
            newOptions = options.map(option => {
                let item = {};
                item.value = option._id;
                item.label = option.name;
                return item;
            })
        }

        return newOptions;
    }


    useEffect(() => {


        axios({
            url: '/api/v1/categories',
            baseURL: baseUrl,
            method: 'get',
            params: {
                limit: 1000,
                franchiseeId,
                sort: 'name'
            }
        })
            .then((response) => {
                let options = optionMapHandler(response.data.data);
                setCategory(options);
            })
            .catch((error) => {
                console.log(error);
            })

    }, [])

    const submitSubCategories = (id) => {
        if (subCategories.length === 0) return;

        const data = {
            franchiseeId: franchiseeId,
            parentId: id,
            subCategories: subCategories
        };

        axios({
            url: '/api/v1/categories/subcategory',
            method: 'post',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` },
            data: data
        })
            .then(res => {
                // toast({
                //     title: "Category Created Successfully.",
                //     description: "You have created a category.",
                //     status: "success",
                //     duration: 4000,
                //     isClosable: true,
                // })
            }).catch(err => {
                if (err.response) {
                    toast({
                        title: "Error!",
                        description: err.response.data.message,
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    })
                }
            })
    };

    const formSubmit = async (data) => {

        if (!data.parent) {
            delete data.parent;
        }
        else {
            data.parentId = data?.parent?.value;
            delete data.parent;
        }
        let getUrl = '/api/v1/categories';


        if (hasSubDomain) {
            const [franchisee] = await DomainUtils();
            data.franchiseeId = franchisee;
        };

        axios({
            url: getUrl,
            method: 'post',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` },
            data: data
        })
            .then(res => {
                const id = res.data.data._id;

                toast({
                    title: "Category Created Successfully.",
                    description: "You have created a category.",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                })
                if (subCategories.length > 0) {
                    submitSubCategories(id);
                }
                history.push(`/admin/category`);
            })
            .catch(err => {
                if (err.response) {
                    toast({
                        title: "Error!",
                        description: err.response.data.message,
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    })
                }
            })
    }

    useEffect(() => {

    }, [subCategories]);

    return (
        <Container maxWidth={1100} centerContent py={6}>
            <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg">
                <Breadcrumb pathname="Category" create={true} link={`/admin/category`} mb={5} />


                <form id="create-cat-form">
                    <FormControl id="name" mb="15px" isInvalid={errors.name}>
                        <Input
                            placeholder="Category Name"
                            ref={register({ required: true })}
                            variant="flushed" borderColor="gray.300" size="sm"
                            name="name" type="text" />
                        <FormErrorMessage mb="20px">{errors.name && "Name field is required"}</FormErrorMessage>
                    </FormControl>
                    {/* <FormControl id="parent" mb="20px" isInvalid={errors.parent}>
                        <FormLabel>Parent Category</FormLabel>
                        <Controller
                            name="parent"
                            control={control}
                            options={categories}
                            as={select}
                            defaultValue={null}
                            isClearable
                        />
                    </FormControl> */}
                    <SubCategories subCategories={subCategories} setSubCategory={setSubCategory} />
                </form>
                <Button mt={5} onClick={handleSubmit(formSubmit)} colorScheme="teal" variant="outline" size="sm">Save</Button>
            </Box>
        </Container>
    )
}

export default Category;
