import React, {useEffect} from 'react'; 
import { Box, Flex, Link } from '@chakra-ui/react'; 
import { StarIcon } from '@chakra-ui/icons'; 

function ProductAvgRating(props) { 
    const theme = JSON.parse(localStorage.getItem('theme')); 
    const { countComment, fractions, reviewData } = props; 

    useEffect(() => { 
        props.newReviewCreatedHandler(); 
    }, [props.productId]) 


    return ( 
        <Box mt="5px"> 
            <Flex> 
                { 
                    reviewData?.map((review, i) => { 
                        let color; 
                        color = review.iconColor === 'mix' && fractions >= 0.5 
                                ? `rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 1'})` 
                                : 'gray.500';
                        
                        return ( 
                            <Box key={i}> 
                                { 
                                    review.iconColor === 'mix' 
                                        ? <StarIcon w={4} h={4} mr="5px" color={color} /> 
                                        : <StarIcon w={4} h={4} mr="5px" color={review.iconColor} /> 
                                } 
                            </Box> 
                        ) 
                    }) 
                } 

                <Link 
                    fontSize="14px" mt="4px" ml="5px" 
                    color={countComment > 0 ? `rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 1'})` : 'gray.500'} 
                    cursor={countComment > 0 && "pointer"} 
                    _hover={{ textDecoration: countComment === 0 ? "none" : 'underline' }} 
                    href={countComment > 0 ? "#productComment" : "#"}> 
                    { 
                        countComment > 1 
                            ? `${countComment} Ratings` 
                            : `${countComment} Rating` 
                    } 
                </Link> 
            </Flex> 
        </Box> 
    ) 
} 

export default ProductAvgRating; 