import { useState, useEffect } from 'react';
import Carousel from 'react-elastic-carousel';
import styled from 'styled-components';
import { Center, Text, AspectRatio, Image, Flex, Box } from '@chakra-ui/react';
import axios from 'axios';
import { config } from '../config';
import { Tooltip } from '@chakra-ui/react';
import DomainUtils from '../service/utils/DomainUtils';
import city from '../img/city1.jpg';
import business_concept from '../img/concept.jpg';
import { Link, useParams } from 'react-router-dom';

const CarousalItem = styled.div`
  width: 400px;
  margin: 10px 10px;
  border: 1px solid #bdbdbd;
  background-color: #ffffff;
`;

const CarousalImage = styled.img`
  width: 100%;
  border-radius: 4px 4px 0 0;
  height: 220px;
  object-fit: contain;

  ${
    '' /* @media only screen and (max-width: 760px){
    object-fit: cover; 
    height: 250px;
} */
  }
`;

const ElasticCarousal = (props) => {
  const { hasSubDomain, pageSlider } = props;
  const [state, setState] = useState([]);
  const [image, setImage] = useState(city);

  const breakPoints = [
    {
      width: 1,
      itemsToShow: parseInt(pageSlider?.itemsToShow),
      verticalMode: true,
    },
    {
      width: 550,
      itemsToShow: parseInt(pageSlider?.itemsToShow),
      verticalMode: true,
    },
    {
      width: 650,
      verticalMode: true,
      itemsToShow: parseInt(pageSlider?.itemsToShow),
    },

    {
      width: 768,
      itemsToShow: parseInt(pageSlider?.itemsToShow),
      verticalMode: true,
    },
    { width: 1100, itemsToShow: parseInt(pageSlider?.itemsToShow) },
    { width: 1400, itemsToShow: parseInt(pageSlider?.itemsToShow) },
    { width: 1750, itemsToShow: parseInt(pageSlider?.itemsToShow) },
  ];

  const getData = async () => {
    const token = localStorage.getItem('token');
    const baseUrl = config.baseUrl;
    let api =
      '/api/v1/franchisees?fields=name,domain,generatedDomain,image&sort=name';

    const [franchisee] = await DomainUtils();

    if (hasSubDomain) {
      if (pageSlider?.businessType === 'featured') {
        api = `/api/v1/business/with_product?franchiseeId=${franchisee}&active=true&isFeatured=true&sort=name&fields=name,domain,generatedDomain,image,productInfo`;
      } else {
        api = `/api/v1/business/with_product?franchiseeId=${franchisee}&active=true&sort=name&fields=name,domain,generatedDomain,image,productInfo`;
      }
      setImage(business_concept);
    }

    axios({
      url: api,
      method: 'get',
      baseURL: baseUrl,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        // console.log("ElasticCarousal res.data.data:::", res?.data?.data);
        setState(res?.data?.data);
      })
      .catch((err) => console.log(err, 'Data fetch error'));
  };

  useEffect(() => {
    if (pageSlider) {
      getData();
    }
  }, [pageSlider]);

  return pageSlider ? (
    <>
      <Center mb="2rem">
        {hasSubDomain ? (
          <Text as="h1" fontSize="2rem" fontWeight="700">
            {pageSlider?.title}
          </Text>
        ) : (
          <Text fontSize="2rem" fontWeight="700" mb="2rem">
            {pageSlider?.title}
          </Text>
        )}
      </Center>

      <Carousel
        breakPoints={breakPoints}
        showArrows={false}
        pagination={true}
        itemsToShow={parseInt(pageSlider?.itemsToShow)}
        itemsToScroll={1}>
        {state?.length > 0 &&
          state?.map((obj, i) => {
            const domain = obj.domain
              ? '//' + obj.domain
              : '//' + obj.generatedDomain;
            const tooltipLabel = obj.domain ? obj.domain : obj.generatedDomain;
            const carouselImg = obj.image || image;
            return (
              <CarousalItem key={i}>
                {hasSubDomain ? (
                  <Tooltip
                    label={`${obj?.productInfo?.slug}`}
                    fontSize="sm"
                    bg="gray.300"
                    color="black">
                    <Link
                      to={`/shop/product/${obj?.productInfo?.slug}`}
                      rel="noopener noreferrer">
                      <AspectRatio maxW="400px" ratio={1 / 1}>
                        <Image
                          display="block"
                          m="10 auto"
                          w="100%"
                          src={carouselImg}
                          alt="business picture"
                          objectFit="cover"
                        />
                      </AspectRatio>
                      <Text textAlign="center" fontWeight="600" my={3}>
                        {obj?.name}
                      </Text>
                    </Link>
                  </Tooltip>
                ) : (
                  <Tooltip
                    label={tooltipLabel}
                    fontSize="sm"
                    bg="gray.300"
                    color="black">
                    <a
                      target={'_blank'}
                      href={domain}
                      rel="noopener noreferrer">
                      <AspectRatio maxW="400px" ratio={1 / 1}>
                        <Image
                          display="block"
                          m="10 auto"
                          w="100%"
                          src={carouselImg}
                          alt="business picture"
                          objectFit="cover"
                        />
                      </AspectRatio>
                      <Text
                        as="h4"
                        textAlign="center"
                        fontWeight="600"
                        color="gray.500"
                        mb={3}>
                        {obj.name}
                      </Text>
                    </a>
                  </Tooltip>
                )}
              </CarousalItem>
            );
          })}
      </Carousel>
    </>
  ) : null;
};

export default ElasticCarousal;
