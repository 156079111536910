import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Center, Container } from '@chakra-ui/react'
import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MetaDecorator from '../components/metaDecorator'
import { config } from '../config';


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Verification = () => {
  const baseURL = `${config.baseUrl}/api/v1`;
  let query = useQuery();
  const token = query.get("token");
  const id = query.get("id");
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const [host, setHost] = useState('aaaaaaa');

  useEffect(() => {
    if (id == null || token == null) {
      setLoading(false);
      setVerified(false);
      setHost(String(window.origin));
    } else {
      axios({
        url: `auth/verification/verify-account?token=${token}&id=${id}`,
        method: 'get',
        baseURL: baseURL,
      })
        .then((res) => {
          setVerified(true);
          setLoading(false);
          setHost(String(window.origin));
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setVerified(false);
          console.log(String(window.origin));
          setHost(String(window.origin));
        });
    }
  }, [])

  if (loading) {
    return (
      <div>
        <Box>
          <MetaDecorator
            title={'Verification'}
            description={'Email Verification'}
          />
          <Box h="60px" bg="#edf2f9" />
          <Box bg="#edf2f9">
            <Container
              maxWidth={1100}
              py={{ base: 0, sm: 0, md: 4 }}
              px={{ base: 0, sm: 0, md: 4 }}>
              <Box
                w="100%"
                bg="white"
                py={{ base: 4, sm: 4, md: 10, lg: 10 }}
                my={{ base: 6, sm: 6, md: 10, lg: 10 }}
                px={{ base: 4, sm: 4, md: 10, lg: 10 }}
                rounded={{ base: null, sm: null, md: 'md', lg: 'md' }}
                boxShadow={{ base: null, sm: null, md: 'md', lg: 'md' }}>
                <Center><h1>Verifying...</h1></Center>
              </Box>
            </Container>
          </Box>
        </Box>
      </div>)

  }

  return (
    <div>
      <Box>
        <MetaDecorator
          title={'Verification'}
          description={'Email Verification'}
        />
        <Box h="60px" bg="#edf2f9" />
        <Box bg="#edf2f9">
          <Container
            maxWidth={1100}
            py={{ base: 0, sm: 0, md: 4 }}
            px={{ base: 0, sm: 0, md: 4 }}>
            {verified ? <Box
              w="100%"
              bg="white"
              my={{ base: 6, sm: 6, md: 10, lg: 10 }}
              rounded={{ base: null, sm: null, md: '2xl', lg: '2xl' }}
              boxShadow={{ base: null, sm: null, md: 'md', lg: 'md' }}>
              <Alert
                py={{ base: 4, sm: 4, md: 10, lg: 10 }}
                px={{ base: 4, sm: 4, md: 10, lg: 10 }}
                status='success'
                variant='subtle'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                textAlign='center'
              >
                <AlertIcon boxSize='80px' mr={0} />
                <AlertTitle mt={5} mb={3} fontSize='3xl'>
                  Verified!
                </AlertTitle>
                <AlertDescription maxWidth='xl'>
                  You have successfully verified your account.
                </AlertDescription>
                <a href={host}>
                  <Button
                    mt={4}
                    size='md'
                    height='48px'
                    width='200px'
                    border='2px'
                    borderColor='green.500'
                    backgroundColor='green.200'
                    color={'black'}
                    _hover={{ backgroundColor: 'green.300' }}
                  >
                    Back to home
                  </Button></a>
              </Alert>
            </Box> : <Box
              w="100%"
              bg="white"
              my={{ base: 6, sm: 6, md: 10, lg: 10 }}
              rounded={{ base: null, sm: null, md: '2xl', lg: '2xl' }}
              boxShadow={{ base: null, sm: null, md: 'md', lg: 'md' }}>
              <Alert
                py={{ base: 4, sm: 4, md: 10, lg: 10 }}
                px={{ base: 4, sm: 4, md: 10, lg: 10 }}
                status='error'
                variant='subtle'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                textAlign='center'
              >
                <AlertIcon boxSize='80px' mr={0} />
                <AlertTitle mt={5} mb={3} fontSize='3xl' color={'red'}>
                  Failed!
                </AlertTitle>
                <AlertDescription maxWidth='xl' color={'red'}>
                  Verification link expired, or account already verified.
                </AlertDescription>
                <a href={host}>
                  <Button
                    mt={4}
                    size='md'
                    height='48px'
                    width='200px'
                    border='2px'
                    borderColor='red.500'
                    backgroundColor='red.200'
                    color={'white'}
                    _hover={{ backgroundColor: 'red.300' }}
                  >
                    Back to home
                  </Button></a>

              </Alert>
            </Box>}

          </Container>
        </Box>
      </Box>
    </div>
  )
}

export default Verification