import React from 'react'
import Shop from './Shop'

function ShopPage() {
    // let shopLayout = localStorage.getItem('shop');
    // shopLayout = shopLayout ? shopLayout : 'DEFAULT';
    // let shopComponent = <Shop />;
    // if (shopLayout === 'CUSTOM') {
    //     shopComponent =  <CustomShop /> 
    // }
    return <Shop />;
}

export default ShopPage
