import Select from 'react-select'
import { v4 as uuid }  from 'uuid'; 

const SystemMenuSelect = (props) => { 
    const { 
        Controller, control, options, 
        index, item, menuItems, setMenuItems, 
        tab=null, tabIndex=null, setLatestSelectedValue 
    } = props; 

    let itemId = uuid(); 

    return (
        <>
            <Controller 
                control={control} 
                name="something" 
                defaultValue='' 
                render={( 
                    { onChange, onBlur, value, name, ref }, 
                    { invalid, isTouched, isDirty } 
                ) => ( 
                    <Select 
                        menuPortalTarget={document.body} 
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        options={options} 
                        onChange={(value) => { 
                            onChange(value); 
                            value.data = { 
                                id: itemId, 
                                name: value.label, 
                                slug: value.slug, 
                                optionName: value.label
                            }; 
                            value.type = 'SYSTEM_MENU'; 
                            value.hasChildren = false; 
                            value.isExpanded = false; 
                            value.isChildrenLoading = false; 
                            value.children = []; 
                            setLatestSelectedValue(value); 
                            itemId = uuid(); 
                        }} 
                        value={value} 
                    /> 
                )} 
            /> 
        </> 
    ) 
}; 

export default SystemMenuSelect;