import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Textarea,
  FormControl,
  Image,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  SimpleGrid,
  useToast,
  InputGroup,
  InputRightElement,
  InputLeftElement,
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';

import { config } from '../../config';
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';

function FranchiseeDetails() {
  const token = localStorage.getItem('token');
  const { baseUrl, siteURL } = config;
  const toast = useToast();
  const history = useHistory();
  const { id: _id } = useParams();

  const {
    register,
    handleSubmit,
    errors,
    reset,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: false,
  });

  const [name, setName] = useState('');
  const [firstDomainKey, setFirstDomainKey] = useState('');
  const [previewImage, setPreviewImage] = useState('');

  function imageHandler(e) {
    // To preview before upload --- Converting to base 64
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreviewImage(reader.result);

        const formData = new FormData();
        formData.append('image', e.target.files[0]);

        axios({
          url: `/api/v1/franchisees/${_id}`,
          method: 'post',
          baseURL: baseUrl,
          data: formData,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(({ data }) => {
            toast({
              title: 'Image updated.',
              description: 'Image updated successfully',
              status: 'success',
              duration: 4000,
              isClosable: true,
            });
          })
          .catch((err) => {
            toast({
              title: 'Image not updated',
              description: JSON.stringify(err),
              status: 'fail',
              duration: 4000,
              isClosable: true,
            });
          });
      }
    };

    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function submitDelete() {
    //console.log('sddddd');
    axios({
      url: `/api/v1/franchisees/delete/${_id}`,
      method: 'delete',
      baseURL: baseUrl,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        toast({
          title: 'Franchisee Deleted.',
          description: 'Franchisee deleted successfully',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        history.push('/admin/franchisees');
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: 'Delete Failed.',
          description: 'Try again later.',
          status: 'fail',
          duration: 4000,
          isClosable: true,
        });
      });
  }

  useEffect(() => {
    axios({
      url: `/api/v1/franchisees/${_id}`,
      method: 'get',
      baseURL: baseUrl,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        reset(res.data?.data);
        setName(res?.data?.data?.name);
        setPreviewImage(res.data.data?.image);
        setFirstDomainKey(res?.data?.data?.domainKey);
      })
      .catch((err) => console.log(err));
  }, []);

  const subDomainChecker = () => {
    const token = localStorage.getItem('token');
    let domainKey = getValues('domainKey');

    domainKey = domainKey.replaceAll(' ', '-');

    let slug = '';
    let symbolArr = [
      '"',
      '~',
      '`',
      '!',
      '@',
      '#',
      '$',
      '%',
      '^',
      '&',
      '*',
      '(',
      ')',
      '+',
      '=',
      '{',
      '}',
      '[',
      ']',
      ';',
      ':',
      "'",
      '|',
      '<',
      '>',
      ',',
      '.',
      '?',
      '/',
    ];
    for (let i = 0; i < domainKey.length; i++) {
      if (!symbolArr.includes(domainKey[i]) && domainKey[i] !== '\\') {
        slug = slug + domainKey[i].toLowerCase();
      }
    }

    if (slug && firstDomainKey !== domainKey) {
      axios({
        url: `/api/v1/franchisees?domainSlug=${slug}&limit=1`,
        method: 'get',
        baseURL: baseUrl,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(({ data }) => {
          let { result } = data;
          if (result >= 1) {
            setError('domainKey', {
              type: 'duplicate',
              message: `${domainKey} already exist!`,
            });
          } else {
            clearErrors('domainKey');
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const onSubmit = (data) => {
    axios({
      url: `/api/v1/franchisees/${_id}`,
      method: 'patch',
      baseURL: baseUrl,
      headers: { Authorization: `Bearer ${token}` },
      data,
    })
      .then((res) => {
        toast({
          title: 'Franchisee Updated Successful.',
          description: 'You have updated a franchesee data.',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        history.push('/admin/franchisees');
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: 'Registration Unsuccessful.',
          description: 'Error occured',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      });
  };

  return (
    <Container maxWidth={1100} centerContent py={6}>
      <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg">
        <Breadcrumb
          pathname="Franchisees"
          nameOrTitle={name}
          link={`/admin/franchisees`}
          mb={5}
        />
        {/* <Text as="h4" fontSize="1.4rem" mb="20px" fontWeight="600">Update Franchisee Data</Text>  */}

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl mb="15px" isInvalid={errors.name}>
            <FormLabel m={0}>Name</FormLabel>
            <Input
              ref={register({ required: 'Name is required' })}
              variant="flushed"
              borderColor="gray.300"
              size="sm"
              name="name"
              type="text"
            />
            <FormErrorMessage mb="20px">
              {errors?.name?.message}
            </FormErrorMessage>
          </FormControl>

          {/* Email & phone */}
          <SimpleGrid
            columns={{ base: 1, sm: 1, md: 2 }}
            spacing={{ base: '10px', md: '20px', lg: '20px' }}>
            <FormControl mb="15px" isInvalid={errors.email}>
              <FormLabel m={0}>Email</FormLabel>
              <Input
                ref={register({
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
                variant="flushed"
                // readOnly
                borderColor="gray.300"
                size="sm"
                name="email"
                type="text"
              />
              <FormErrorMessage mb="20px">
                {errors?.email?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl mb="15px" isInvalid={errors.phone}>
              <FormLabel m={0}>Phone</FormLabel>
              <Input
                type="tel"
                name="phone"
                ref={register({
                  // required: 'Phone Number is required',
                  minLength: { value: 14, message: 'Minimum 14 characters' },
                })}
                variant="flushed"
                borderColor="gray.300"
                size="sm"
              />
              <FormErrorMessage mb="20px">
                {errors?.phone?.message}
              </FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          {/* Image */}
          <FormControl mb="15px" isInvalid={errors.image} mt="15px">
            <Box>
              <FormLabel
                htmlFor="franchiseeImage"
                textAlign="center"
                cursor="pointer"
                w="100%"
                h="100%"
                lineHeight="60px"
                border="1px dashed #BDBDBD"
                // color="gray.500"
                p="10px">
                Click to upload Image
              </FormLabel>
              <Input
                type="file"
                name="franchiseeImage"
                ref={register}
                id="franchiseeImage"
                accept="image/*"
                onChange={(e) => imageHandler(e)}
                style={{ display: 'none' }}
              />
            </Box>
            {previewImage && (
              <Image
                mt="5px"
                src={previewImage}
                alt="Franchisee image"
                maxH="200px"
              />
            )}
          </FormControl>

          <FormControl mb="15px" isInvalid={errors?.domainKey}>
            <FormLabel m={0}>Sub domain</FormLabel>
            <InputGroup>
              <Input
                ref={register({ required: 'Sub domain is required' })}
                variant="flushed"
                borderColor="gray.300"
                size="sm"
                name="domainKey"
                type="text"
                onBlur={subDomainChecker}
              />
              <InputRightElement
                pointerEvents="none"
                w="15rem"
                h="2rem"
                bg="gray.200"
                color="gray.400"
                fontWeight="500">
                {siteURL}
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage mb="20px">
              {errors?.domainKey?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl mb="15px">
            <FormLabel m={0}>Generated Domain</FormLabel>
            <Input
              ref={register}
              variant="flushed"
              borderColor="gray.300"
              size="sm"
              isDisabled
              name="generatedDomain"
              type="text"
            />
          </FormControl>

          <FormControl mb="15px">
            <FormLabel m={0}>Domain</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                w="4rem"
                h="1.9rem"
                bg="gray.200"
                color="gray.400"
                fontWeight="500">
                www.
              </InputLeftElement>
              <Input
                ref={register}
                variant="flushed"
                borderColor="gray.300"
                size="sm"
                pl="4rem"
                name="domain"
                type="text"
              />
            </InputGroup>
          </FormControl>

          <FormControl mb="15px" isInvalid={errors?.address}>
            <FormLabel m={0}>Address</FormLabel>
            <Textarea
              ref={register({ required: 'Address is required' })}
              variant="flushed"
              borderColor="gray.300"
              size="sm"
              name="address"
              type="text"
            />
            <FormErrorMessage mb="20px">
              {errors?.address?.message}
            </FormErrorMessage>
          </FormControl>

          <Button
            mr={2}
            onClick={submitDelete}
            colorScheme="red"
            variant="outline"
            size="sm">
            Delete
          </Button>
          <Button type="submit" colorScheme="teal" variant="outline" size="sm">
            Save
          </Button>
        </form>
      </Box>
    </Container>
  );
}

export default FranchiseeDetails;
