import React, {useState, useRef, useCallback} from 'react';
import { Link as RouteLink, useRouteMatch } from 'react-router-dom';
import {
    Box, Container, Flex, Button, Link 
} from '@chakra-ui/react'
import axios from 'axios' 
import {config} from '../../config'

import TagTable from './TagTable'
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';



function Tag() { 
    const franchiseeId = localStorage.getItem('franchiseeId'); 
    const [allTags, setAllTags] = useState([]) 

    const baseURL = `${config.baseUrl}/api/v1`; 
    const token = localStorage.getItem('token'); 
    let { url } = useRouteMatch(); 

    const [pageCount, setPageCount] = useState(0); 
    const fetchIdRef = useRef(0); 

    let columns = [ 
        { 
            Header: 'Tag Name', 
            accessor: 'name'
        },
    ] 

    let sortOn = ['Tag Name'] 

    const fetchData = useCallback(({ pageSize, pageIndex, globalFilter, skipPageResetRef }) => { 
        const fetchId = ++fetchIdRef.current 
        let getUrl = franchiseeId !== 'null'
            ? `/tags?adminType=CA&franchiseeId=${franchiseeId}` 
            : '/tags?adminType=SA'; 
        
        let getSearchUrl = franchiseeId !== 'null'
            ? `/tags?search=${globalFilter}&adminType=CA&franchiseeId=${franchiseeId}&sort=name` 
            : `/tags?search=${globalFilter}&adminType=SA&sort=name`; 
        
        if(globalFilter) { 
          // Filtering on name field 
            axios({ 
                url: getSearchUrl, 
                method: 'get', 
                baseURL: baseURL, 
                headers: {'Authorization': `Bearer ${token}`} 
            }) 
            .then((res) => { 
                const {data: serverData, result: totalLength} = res.data 
                if (fetchId === fetchIdRef.current) { 
                    if(serverData.length >= 1) { 
                        skipPageResetRef.current = true 
                        setAllTags(serverData) 
                        setPageCount(Math.ceil(totalLength/pageSize)) 
                    } 
                } 
            }) 
            .catch(err => console.log(err, 'Data fetch error')) 
        } 
        else { 
          // Pagination 
            axios({ 
                url: `${getUrl}&page=${(pageIndex)+1}&limit=${pageSize}&sort=name`, 
                method: 'get', 
                baseURL: baseURL, 
                headers: {'Authorization': `Bearer ${token}`} 
            }) 
            .then((res) => { 
                const {data: serverData, result: totalLength} = res.data 
                if (fetchId === fetchIdRef.current) { 
                    skipPageResetRef.current = true 
                    setAllTags(serverData) 
                    setPageCount(Math.ceil(totalLength/pageSize)) 
                } 
            }) 
            .catch(err => console.log(err, 'Data fetch error')) 
        } 
    }, []) 



    return ( 
        <Container maxWidth={1100} centerContent  my={6}> 
            <Box w="100%" bg="white" rounded="lg" boxShadow="lg"> 
                <Flex justifyContent="space-between" pt={4} px={4}> 
                    <Breadcrumb pathname="Tag" /> 
                    <Button colorScheme="teal" variant="outline" size="sm">
                        <Link color="blue.500" lineHeight="32px" as={RouteLink} to={`${url}/create`}>Create new</Link>
                    </Button>
                </Flex> 

                
                <TagTable 
                    data={allTags} 
                    columns={columns} 
                    pageCount={pageCount} 
                    fetchData={fetchData} 
                    sortOn={sortOn} 
                    defaultPageSize={10} 
                    tableHeightInPage='58vh' 
                    selectNoOfRows={[5, 10, 20, 30, 50, 100]} 
                /> 
            </Box> 
        </Container> 
    ) 
} 

export default Tag; 