import React from 'react';
import {Box, Text, SimpleGrid} from '@chakra-ui/react'

function AuthWarning(props) { 
    const pathname = props.location.state.from.pathname; 
    
    return ( 
        <SimpleGrid placeItems="center" h="100vh" bg="#edf2f9" fontFamily="Poppins"> 
            <Box m={{base: '15px', md: '0px'}}> 
                <Text as="h1" fontSize={{base: '22px', md: '32px'}}> 
                    Only authenticated user can visit {" "} 
                    <span style={{color: '#d25d50'}}> 
                        {pathname} 
                    </span> 
                </Text> 
                <Text as="h2" fontSize={{base: '20px', md: '25px'}} mt="10px" textAlign={{base: 'left', md: 'center'}}> 
                    Please Signin 
                </Text> 
            </Box> 
        </SimpleGrid> 
    ) 
} 

export default AuthWarning;