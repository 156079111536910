import axios from 'axios';
import React, { useState, useRef, useCallback } from 'react';
import { config } from '../../config';
import {
    Container, Box, Link, useToast,
    Flex, Spacer, Button, useDisclosure, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import { Link as RouteLink, useRouteMatch } from 'react-router-dom';
import ScTable from './UserTable';
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import useDomain from '../../service/DomainHook';
import { FaTrash } from 'react-icons/fa';


function Users() {
    const role = localStorage.getItem('role');
    const token = localStorage.getItem('token');
    const franchiseeId = localStorage.getItem('franchiseeId');
    const [hasSubDomain] = useDomain();

    const [pages, setPages] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0)
    const baseURL = `${config.baseUrl}/api/v1`;
    const [selectedUser, setSelectedUser] = useState();
    const [reload, setReload] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const deleteUser = () => {
        let url = `/users/${selectedUser}`;
        if (hasSubDomain) {
            url = `/users/${selectedUser}/${franchiseeId}`;
        }
        axios({
            url: url,
            method: 'delete',
            baseURL: baseURL,
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then((res) => {
             
                toast({
                    title: res.data.message,
                    status: "success",
                    duration: 4000,
                    isClosable: true
                })
                setReload((res) => !res);
            })
            .catch((err) => {
                toast({
                    title: err?.response?.data?.message,
                    status: "error",
                    duration: 4000,
                    isClosable: true
                })
            })
    }

    const sortOn = ['First Name', 'Last Name', 'Email', 'Phone'];

    let columns = [
        {
            Header: 'First Name',
            accessor: 'firstName'
        },
        {
            Header: 'Last Name',
            accessor: 'lastName'
        },
        {
            Header: 'Email',
            accessor: 'email'
        },
        {
            Header: 'Phone',
            accessor: 'phone'
        },
        {
            Header: 'Action',
            accessor: '',
            Cell: props => {
                let data = props.row.original;
                return (
                    <Button bg='none' onClick={() => {
                        onOpen();
                        setSelectedUser(data.email);
                    }}>
                        <FaTrash />
                    </Button>
                );
            }
        }
    ]

    let { url } = useRouteMatch();

    const fetchData = useCallback(({ pageSize, pageIndex, globalFilter, skipPageResetRef}) => {
        const fetchId = ++fetchIdRef.current;
        const fields = 'firstName,lastName,phone,email';

        let url = `/users?role=SUPERADMIN&fields=${fields}&sort=name`
        let searchUrl = `/users?role=${role}&search=${globalFilter}`;


        if (hasSubDomain) {
            url = `/users?franchiseeId=${franchiseeId}&fields=${fields}`;
            searchUrl = `/users?franchiseeId=${franchiseeId}&search=${globalFilter}`;
        }


        if (globalFilter) {
            // Filtering on name field 
            axios({
                url: searchUrl,
                method: 'get',
                baseURL: baseURL,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then((res) => {
                    const { data: serverData, result: totalLength } = res.data;
                    if (fetchId === fetchIdRef.current) {
                        if (serverData.length >= 1) {
                            skipPageResetRef.current = true;
                            setPages(serverData);
                            setPageCount(Math.ceil(totalLength / pageSize));
                        }
                    }
                })
                .catch(err => console.log(err, 'Data fetch error'))
        }
        else {
            // Pagination 
            axios({
                url,
                method: 'get',
                baseURL: baseURL,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then((res) => {
                    const { data: serverData, result: totalLength } = res.data

                    if (fetchId === fetchIdRef.current) {
                        skipPageResetRef.current = true
                        setPages(serverData)
                        setPageCount(Math.ceil(totalLength / pageSize))
                    }
                })
                .catch(err => console.log(err, 'Data fetch error'))
        }
    }, [])

    return (
        <Box height="calc(100vh - 50px)" overflowY="auto" pb="5px">
            <Container maxWidth={1100} centerContent my={6}>
                <Box w="100%" bg="white" rounded="lg" boxShadow="lg">

                    <Flex pt={4} px={4}>
                        <Breadcrumb pathname="Users" />
                        <Spacer />
                        <Box>
                            <Button colorScheme="teal" variant="outline" size="sm">
                                <Link color="blue.500" lineHeight="32px" as={RouteLink} to={`${url}/create`}>Create new</Link>
                            </Button>
                        </Box>
                    </Flex>
                    <ScTable
                        reload={reload}
                        data={pages}
                        columns={columns}
                        pageCount={pageCount}
                        fetchData={fetchData}
                        sortOn={sortOn}
                        defaultPageSize={10}
                        tableHeightInPage='58vh'
                        selectNoOfRows={[5, 10, 20, 30, 50, 100]}
                    />
                </Box>

            </Container>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent m={5}>
                    <ModalHeader >Delete User {selectedUser}?</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Are you sure, you want to delete?
                    </ModalBody>

                    <ModalFooter>
                        <Button size="sm" mr={3} colorScheme="teal" variant="outline" onClick={onClose}>
                            No
                        </Button>

                        <Button size="sm" colorScheme="red" variant="outline"
                            onClick={() => {
                                deleteUser();
                                onClose();
                            }}>Yes</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default Users; 
