import * as moment from 'moment';

let timeout;
export const debounce = function (func, delay = 300) {
  clearTimeout(timeout);

  timeout = setTimeout(func, delay);
};


// JSON to CSV Converter

//https://www.codegrepper.com/code-examples/javascript/javascript+array+to+csv+string

export const CSVConverter = {
  objectToCSVRow: function (dataObject) {
    var dataArray = [];
    for (var o in dataObject) {
      var innerValue = typeof dataObject[o] == 'undefined' ? '' : dataObject[o].toString();
      var result = innerValue.replace(/"/g, '""');
      result = '"' + result + '"';
      dataArray.push(result);
    }
    return dataArray.join(',') + '\r\n';
  },

  findbystring: function (o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  },
  pushUnique: function (arr, item) {
    if (item != "" && !arr.includes(item))
      arr.push(item);
  },
  getLabels: function (name, item, labels) {
    if (typeof item == 'object') {
      for (var index in item) {
        let thisname = ""
        if (name != "") thisname = name + ".";
        thisname += index;
        this.getLabels(thisname, item[index], labels);
      }
    } else {
      this.pushUnique(labels, name);
    }
  },
  ConvertToCSV: function (objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    var labels = [];

    for (var i = 0; i < array.length; i++) {
      this.getLabels("", array[i], labels);

    }

    str += this.objectToCSVRow(labels);

    for (var i = 0; i < array.length; i++) {

      var line = [];
      for (var label in labels) {

        line.push(this.findbystring(array[i], labels[label]));

      }

      str += this.objectToCSVRow(line);
    }

    return str;
  },
  arrayToCSVConverter: function (csvData) {

    // console.log("csvData==>", csvData);

    let totalProductHandlingFee = 0;
    let data = []
    csvData?.forEach((item, index) => {
      data.push({
        orderNo: moment(item?.orderDate).format('YYYY-MM-DD') + "\n"
          + item?.orderNo,
        orderDescription: item?.billingAddress?.email + "\n"
          + item?.billingAddress?.phone + "\n"
          + item?.billingAddress?.house + item?.billingAddress?.street + ",\n"
          + item?.billingAddress?.city + item?.billingAddress?.state + ",\n"
          + item?.billingAddress?.country,
        handlingType: item?.handleType,
        subOrdersNo: '',
        productName: '',
        productHandlingFee: '',
        productPrice: '',
        productQty: '',
        discount: '',
        productTotal: '',
        subTotal: '',

        // totalShippingFee: '',
        // totalHandlingFee: '',
        // orderSubTotalAmount: '',
        // orderTotalAmount: '',
        totalAmount: '',

        greetingCardName: '',
        greetingCardMessage: '',
      });

      for (let i = 0; i < item?.subOrders?.length; i++) {
        let orderTotalAmount = 0
        // orderTotalAmount -= item?.discount?.value || 0;

        const subOrders = item?.subOrders[i];
        orderTotalAmount += subOrders?.greetingCard?.price || 0;

        if (i === 0) {
          Object.assign(data[data?.length - 1], {
            subOrdersNo: subOrders?.orderNo + "\n"
              + subOrders?.shippingAddress?.house + subOrders?.shippingAddress?.street + ",\n"
              + subOrders?.shippingAddress?.city + subOrders?.shippingAddress?.state + ",\n"
              + subOrders?.shippingAddress?.country,
          })
        }
        else {
          data.push({
            subOrdersNo: subOrders?.orderNo + "\n"
              + subOrders?.shippingAddress?.house + subOrders?.shippingAddress?.street + ",\n"
              + subOrders?.shippingAddress?.city + subOrders?.shippingAddress?.state + ",\n"
              + subOrders?.shippingAddress?.country,
          });
        }

        for (let j = 0; j < subOrders?.products?.length; j++) {
          const product = subOrders?.products[j];
          orderTotalAmount += (product?.qty * product?.amount);
          // orderTotalAmount += (product?.qty * product?.price);

          if (j === 0) {
            totalProductHandlingFee = totalProductHandlingFee + product?.handleFee

            Object.assign(data[data?.length - 1], {
              productName: product?.name,
              productPrice: product?.amount,
              // productPrice: product?.price,
              productQty: product?.qty,
              productHandlingFee: product?.handleFee || 0,
              productTotal: (product?.qty * product?.amount),
              // productTotal: (product?.qty * product?.price),
            })
          }
          else {
            totalProductHandlingFee = totalProductHandlingFee + product?.handleFee

            data.push({
              productName: product?.name,
              productPrice: product?.amount,
              // productPrice: product?.price,
              productQty: product?.qty,
              productHandlingFee: product?.handleFee || 0,
              productTotal: (product?.qty * product?.amount),
              // productTotal: (product?.qty * product?.price),
            });
          }
          if ((subOrders?.products?.length - 1) === j) {
            data.push({
              productName: 'Greeting Card',
              productPrice: subOrders?.greetingCard?.price,
              productQty: 1,
              productTotal: subOrders?.greetingCard?.price,
              greetingCardName: subOrders?.greetingCard?.name,
              greetingCardMessage: subOrders?.greetingCard?.message,
            });

            // data.push({
            //   productName: 'Discount',
            //   discount: item?.discount?.value || 0,
            //   productTotal: String(`-${item?.discount?.value || 0}`),
            // });
          }

        }

        data.push({
          subTotal: orderTotalAmount || 0,
        });
      }


      // data.push({
      //   totalShippingFee: item?.shipping?.charge || 0,
      // })
      data.push({
        productName: 'Shipping Fee',
        totalAmount: item?.shipping?.charge || 0,
      });


      // data.push({
      //   totalHandlingFee: item?.totalHandleFee || totalProductHandlingFee || 0,
      // })
      data.push({
        productName: 'Total Handling Fee',
        totalAmount: item?.handleType == 'BY_ITEM' ? totalProductHandlingFee : item?.totalHandleFee || 0,
        // totalAmount: item?.totalHandleFee || totalProductHandlingFee || 0,
      });


      // data.push({
      //   orderSubTotalAmount: orderSubTotalAmountCount || 0
      //   // orderSubTotalAmount: item?.subTotal || 0
      // })
      data.push({
        productName: 'Order SubTotal Amount',
        totalAmount: item?.subTotal || 0,
      });


      data.push({
        productName: 'Discount',
        totalAmount: item?.discount?.value || 0,
      });


      // data.push({
      //   orderTotalAmount: item?.total || 0
      // })
      data.push({
        productName: 'Order Total Amount',
        totalAmount: item?.total || 0,
      });

    });

    return data;
  }
}

export const generateSlug = field => {
  let slug = ''
  return new Promise((resolve, reject) => {
    if (field) {
      // remove some special characters from domain
      slug = field
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');

      resolve(slug);
    } else reject({ message: 'Nothing to slugify', statusCode: 400 });
  });
};

/**
 * Manage data for adsence INS & Script
 * @param {*} nodeName 
 * @param {*} node 
 * @returns chunk data
 */
export const customChunkRenderer = (nodeName, node) => {
  if (nodeName === 'div') {
    return {
      type: 'DIV',
      mutability: 'MUTABLE',
      data: { // Pass whatever you want here (like id, or classList, etc.)
        innerText: node.innerText,
        innerHTML: node.innerHTML
      }
    };
  }
  if (nodeName === 'script') {
    return {
      type: 'SCRIPT',
      mutability: 'MUTABLE',
      data: { // Pass whatever you want here (like id, or keyEvents, etc.)
        innerText: node.innerText,
        innerHTML: node.innerHTML
      }
    };
  }
  if (nodeName === 'ins') {
    console.log("Preview node Blog Details:::", node);
    return {
      type: 'INS',
      mutability: 'MUTABLE',
      data: node
    };
  }
  return null;
}
