import { config } from '../config'; 

var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date(); 

async function tawktoConfig() { 
   const franchiseeId = localStorage.getItem('franchisee') || null; 
   let api = franchiseeId
      ? `${config.baseUrl}/api/v1/chatConfig?adminType=CA&franchiseeId=${franchiseeId}&limit=1&active=true`
      : `${config.baseUrl}/api/v1/chatConfig?adminType=SA&limit=1&active=true`; 
   
   fetch(api, { 
      method: 'get', 
      headers: { 'Content-Type': 'application/json' } 
   }) 
   .then(response => response.json()) 
   .then(res => { 
      if(res.data[0]) { 
         const { propertyId, chatId, chatType } = res.data[0]; 
         if((propertyId && chatId) && chatType === 'twakto') { 
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0]; 
            s1.async=true; 
            s1.src=`https://embed.tawk.to/${propertyId}/${chatId}`; 
            s1.setAttribute('crossorigin','*'); 
            s0.parentNode.insertBefore(s1,s0); 
         } 
      } 
   }) 
   .catch((error) => console.error('Error:', error)); 
}; 

export default tawktoConfig; 