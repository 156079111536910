import React, { useState, useEffect, useRef, useContext } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { Container, Box, Text, Stack,
    Image, Center, Input, Flex, Spacer,
    NumberInput,
    NumberInputField,
    Divider, IconButton, Button, useDisclosure, 
    Alert, AlertTitle, AlertDescription, FormControl, useToast, SimpleGrid,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton 
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { config } from '../../config';
import useDomain from '../../service/DomainHook';
import DomainUtils from '../../service/utils/DomainUtils';
import { CartContext } from '../../context/CartContext';

function Cart() {

    const {1:dispatch} = useContext(CartContext);
    const theme = JSON.parse(localStorage.getItem('theme')); 
    const [cart, setCart] = useState(null);
    const [isEmpty, setIsEmpty ] = useState(false);
    const [shippingMethod, setShippingMethod] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure() 

    const baseURL = `${config.baseUrl}/api/v1`;

    const history = useHistory();
    const toast = useToast();

    const [ hasSubDomain ] = useDomain();
    let isLoggedIn = useRef(true);
    const couponInput = useRef();
    const removableProductId = useRef(null);


    useEffect(async () => { 
        const token = localStorage.getItem('token');

        let franchisee = null;
        if (hasSubDomain) {
            const [ franchiseeId ] = await DomainUtils();
            franchisee = franchiseeId;
        } 

        try {
            const {data: {data: [shipping]}} = await axios({
                url: '/shippingMethods',
                method: 'get',
                baseURL: baseURL,
                params: {franchiseeId: franchisee, adminType: 'CA', isDefault: true}
            });
            setShippingMethod(shipping);
        } catch (error) {
            console.log("No shipping method found")
        }

        isLoggedIn.current = token ? true : true; 
    }, []);

    // Set Cart
    useEffect(() => { 
        const cartToken = localStorage.getItem('cartToken');
        if (cartToken) { 
            axios({url: `/cart/${cartToken}`, method:'GET', baseURL:baseURL})
            .then(response => {
                if (response.data.data) {
                    const { products } = response.data?.data;
                    setCart(response.data.data);
                    if (products.length < 1) {
                        setIsEmpty(true);
                    }else {
                        setIsEmpty(false);
                    }
                }else {
                    setIsEmpty(true);
                }
            })  
            .catch(error => console.log(error))
        } else { 
            setIsEmpty(true); 
        } 
    }, []) 
   
    const handleQuantity = (value, id) => {
        if (value) {
            const intValue = parseInt(value);
            const cartToken = localStorage.getItem('cartToken');
            if (cartToken) { 
                axios({
                    url: `/cart/${cartToken}`, 
                    method:'PATCH', 
                    baseURL:baseURL,
                    data: {
                        itemId: id,
                        qty: intValue
                    }
                })
                .then(response => {
                    setCart(response.data.data);
                })  
                .catch(error => console.log(error))
            } 
        }

    };

    const handleProductRemove = () => { 
        if (!removableProductId.current) return;
        const cartToken = localStorage.getItem('cartToken');
        if (cartToken) { 
            axios({
                url: `/cart/${cartToken}`, 
                method:'PUT', 
                baseURL:baseURL,
                data: {
                    itemId: removableProductId.current,
                }
            })
            .then(response => {
                const { products } = response.data.data;
                setCart(response.data.data);
                removableProductId.current = null;
                onClose();
                if (products.length < 1) {
                    setIsEmpty(true);
                }
                dispatch({
                    type: 'SET_CART',
                    payload: products.length
                })
            })  
            .catch(error => console.log(error))
        }else {
            removableProductId.current = null;
            onClose();
        }
    } 

    const openProductDeleteModal = (id) => {
        removableProductId.current = id;
        onOpen();
    }

    const handleCheckout = () => {
        history.push(`/checkout`);
    }

    const handleCoupon = async () => { 
        const cartToken = localStorage.getItem('cartToken');
        const [franchisee] = await DomainUtils();
        if (couponInput.current.value) {
            if (cartToken) { 
                axios({
                    url: `/coupons/apply`, 
                    method:'POST', 
                    baseURL:baseURL,
                    data: {
                        code: couponInput.current.value,
                        franchiseeId: franchisee,
                        token: cartToken
                    }
                })
                .then(response => {
                    
                    // if (getTotalAmout().toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) < response.data.data?.couponDiscount) {
                    //     toast({
                    //         title: "Error!",
                    //         description: "Coupon amount exceeds total amount",
                    //         status: "error",
                    //         duration: 3000,
                    //         isClosable: true,
                    //     });
                    // }else{}
                    
                    setCart(response.data.data);
                    couponInput.current.value = ""
                    toast({
                        title: "Success!",
                        description: "Coupon applied successfully",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                })  
                .catch(error => {
                    couponInput.current.value = ""
                    if (error.response) {
                        const {message} = error.response.data;
                        toast({
                            title: "Error!",
                            description: message,
                            status: "error",
                            duration: 3000,
                            isClosable: true,
                        });
                    }
                })
            } 
        }
        else {
            toast({
                title: "Error!",
                description: "Input value is empty",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    }

    const handleRedirectShop = () => { 
        history.push('shop'); 
    } 

    const getTotalAmout = () => {
        let total = 0;
        if (cart) {
            total = cart?.cartTotal + shippingMethod?.cost - cart.couponDiscount;
            if (cart.handleType === 'BY_ORDER') {
                total += cart.totalHandleFee;
            }
        }
        return total;
    }


    return (
        <>
        <Box bg="#EDF2F9" overflowY="auto">
            <Container maxWidth={1140}> 
                <Box mt="60px" mb={5}>
                    <Stack direction={{base:"column", sm:"column", md:"row", lg:"row"}} spacing="20px">
                        <Box 
                            w={{
                                base:"100%", 
                                sm:"100%", 
                                md:(cart && cart.products.length > 0) ? "66%" : "100%", 
                                lg:(cart && cart.products.length > 0) ? "66%" : "100%"
                            }} 
                            pt={5}
                        >
                            <Text as={'h2'}>
                                Shopping Cart 
                            </Text> 
                            <Divider mb={2} />

                            {
                                isEmpty ?
                                <Alert
                                    status="info"
                                    variant="subtle"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    textAlign="center"
                                    bg={`rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 0.4'})`}
                                    height="200px"> 
                                    {/* <AlertIcon boxSize="40px" mr={0} /> */}
                                    <AlertTitle mt={4} mb={1} fontSize="lg">
                                        Cart is empty!
                                    </AlertTitle>
                                    <AlertDescription maxWidth="sm">
                                    <Button 
                                        bg={`rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 1'})`}
                                        color="white" border={`1px solid ${theme?.color?.accent}`} 
                                        _hover={{ 
                                            bg:`rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 0.95'})`
                                        }}
                                        mt={4} 
                                        onClick={handleRedirectShop}> 
                                        Continue shopping 
                                    </Button> 
                                    </AlertDescription> 
                                </Alert> : null 
                            } 

                            {
                                (cart && cart.products.length > 0) && 
                                <Stack spacing={5} w="100%"> 
                                    { 
                                        cart?.products.map((item, index) => { 
                                            return ( 
                                                <Box key={item._id} bg="white" shadow="sm" rounded={5} position="relative">
                                                    <Stack direction={["column", "row"]} spacing="10px" p={3}>
                                                        <Box w={["100%", "180px"]} h={["200px", "150px"]}>
                                                            <Image w="100%" h="100%" rounded={4} objectFit="cover" src={item.image} />
                                                        </Box>
                                                        <Box w="18rem">
                                                            <Text fontWeight="700" fontSize=".9rem" lineHeight="1rem" mb={1}>{item.name}</Text>
                                                            <Text fontSize=".8rem" >Chosen: ${item.amount.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
                                                            {
                                                                item.handleFee > 0 && 
                                                                <>
                                                                <Text fontSize=".8rem" color="gray.600">
                                                                    <Text as="span" fontSize=".8rem" color="gray.600">Quantity: 1</Text>
                                                                    <Text px="5px" as="span" color="gray.800">|</Text>
                                                                    <Text as="span" fontSize=".8rem" color="gray.600">Handle Fee: ${item.handleFee.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
                                                                </Text>
                                                                <Text fontSize=".8rem" color="gray.600">Total: ${item.price.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
                                                                </>
                                                            }
                    
                                                        </Box>
                                                        <SimpleGrid columns={3} spacing={1}>
                                                            <Box w="5rem">
                                                                <Text fontSize=".9rem">
                                                                    ${item.price.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                                </Text>
                                                            </Box>
                                                            <Box w="4rem">
                                                                <NumberInput 
                                                                    size="sm" defaultValue={item.qty} 
                                                                    min={1}
                                                                    onChange={(value) => handleQuantity(value, item._id)}
                                                                >
                                                                    <NumberInputField />
                                                                </NumberInput>
                                                            </Box>
                                                            <Box w="5rem">
                                                                <Text textAlign="center" fontSize=".9rem">
                                                                    ${(item.totalPrice).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                                </Text>
                                                            </Box>
                                                        </SimpleGrid>
                                                    </Stack>
                                                    <Box bg="red.100" 
                                                        position={{ base:"inherit", sm:"inherit", md:"absolute", lg:"absolute" }} 
                                                        top={{ base:"none", sm:"none", md:"0", lg:"0" }} 
                                                        right={{base:"none", sm:"none", md:"-5px", lg:"-5px"}} 
                                                        height="100%" 
                                                        rounded={2} 
                                                        onClick={() => openProductDeleteModal(item._id)} 
                                                        id={item.id}
                                                        cursor="pointer"> 
                                                        <Center height="100%">
                                                            <IconButton 
                                                                bg="transparent" 
                                                                size="xs" 
                                                                icon={ 
                                                                    <DeleteIcon 
                                                                        pointerEvents="none" color="red.500" 
                                                                        w={4} h={4} /> 
                                                                }/> 
                                                        </Center>
                                                    </Box>
                                                </Box>
                                            );
                                        })
                                    } 
                                </Stack> 
                            }

                        </Box>

                        {
                            (cart && cart.products.length > 0) && 
                            <Box w={{base:"100%", sm:"100%", md:"38%", lg:"38%"}} pt={5}>

                                <Text as={"h2"} textTransform="uppercase" letterSpacing={1}>Coupon</Text>
                                <Divider mb={2} />
                                <Box p={3} bg="white" rounded={5} mb="20px" shadow="sm">
                                    <FormControl mb={2}>
                                        <Input ref={couponInput} size="sm" name="coupon" placeholder="Coupon code" />
                                    </FormControl>
                                    <SimpleGrid minChildWidth="2rem" spacing="10px">
                                        <Button 
                                            bg={`rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 1'})`}
                                            color="white" border={`1px solid ${theme?.color?.accent}`} 
                                            _hover={{ 
                                                bg:`rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 0.95'})`
                                            }} 
                                            size="sm"
                                            rounded={4}
                                            isDisabled={isEmpty}
                                            onClick={handleCoupon}>
                                            Apply
                                        </Button> 
                                    </SimpleGrid> 
                                </Box> 

                                <Text  as={"h2"} textTransform="uppercase" letterSpacing={1}>Cart Total</Text>
                                <Divider mb={2} />
                                <Box p={3} bg="white" rounded={5} fontSize="1rem" color="gray.700" shadow="sm"> 
                                    <Flex mb={2}>
                                        <Text fontWeight="700">
                                            Sub Total
                                        </Text>
                                        <Spacer />
                                        <Text fontWeight="700" color="#264653">
                                            ${cart?.cartTotal.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                        </Text>
                                    </Flex>

                                    {
                                        cart?.couponDiscount  > 0 ?
                                            <Flex mb={2}>
                                                <Text fontWeight="700">
                                                    Discount
                                                </Text>
                                                <Spacer />
                                                <Text fontWeight="700" color="#2a9d8f">
                                                    - ${cart?.couponDiscount.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }
                                                </Text>
                                            </Flex>
                                            : null
                                    }

                                    <Flex mb={2}>
                                        <Text fontWeight="700">
                                            Shipping Charge
                                        </Text>
                                        <Spacer />
                                        <Text fontWeight="700" color="#f4a261">
                                            {
                                                shippingMethod ?
                                                `+ $${(cart?.products.length && shippingMethod.cost).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
                                                : 0
                                            }
                                        </Text>
                                    </Flex>

                                    <Flex mb={2}>
                                        <Text fontWeight="700">
                                            Handling Charge 
                                            {
                                                cart.handleType === 'BY_ITEM' 
                                                && <Text ml={1} fontWeight={300} as="span" fontSize="14px">(Included)</Text>
                                            }
                                        </Text>
                                        <Spacer />
                                        <Text fontWeight="700" color={cart.handleType === 'BY_ORDER' ? "#f4a261" : "blue.300" }>
                                            {
                                                cart.handleType === 'BY_ORDER' 
                                                && <Text mr="2px" as="span" px="2px">+</Text>
                                            }
                                            ${(cart?.totalHandleFee).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                        </Text>
                                    </Flex>
                                    
                                    <Flex mb={2}>
                                        <Text fontWeight="700">
                                            Total
                                        </Text>
                                        <Spacer />
                                        <Text fontWeight="700" color="#264653">
                                            {/* ${(cart?.cartTotal + shippingMethod?.cost - cart.couponDiscount).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })} */}
                                            ${getTotalAmout().toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                        </Text>
                                    </Flex>
                                    <Button 
                                        bg={`rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 1'})`}
                                        color="white" border={`1px solid ${theme?.color?.accent}`} 
                                        _hover={{ 
                                            bg:`rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 0.95'})`
                                        }} 
                                        size="sm" isFullWidth
                                        rounded={4}
                                        isDisabled={isEmpty}
                                        onClick={handleCheckout}>
                                        Checkout
                                    </Button> 
                                </Box>
                            </Box>
                        }

                      
                    </Stack>
                    
                </Box>

            </Container>
        </Box>
        

        { 
            <Modal isOpen={isOpen} onClose={onClose} motionPreset='slideInBottom' isCentered> 
                <ModalOverlay /> 
                <ModalContent m={5}> 
                    <ModalHeader>Remove Product</ModalHeader> 
                    <ModalCloseButton /> 
                    <ModalBody> 
                        <Text> 
                            Are you sure, you want to remove product from your cart? 
                        </Text> 
                        <Box my={3}>
                            <Button 
                                size="sm" colorScheme="red" variant="outline"
                                onClick={handleProductRemove}> 
                                Yes 
                            </Button> 
                            <Button 
                                size="sm" ml={3} colorScheme="teal" variant="outline" 
                                onClick={() => {
                                    removableProductId.current = null;
                                    onClose();
                                }}> 
                                No 
                            </Button> 
                        </Box> 
                    </ModalBody> 
                </ModalContent> 
            </Modal> 
        } 
        </> 
    ) 
} 

export default Cart; 
