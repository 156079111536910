import React from 'react'
import {Flex, Box, Skeleton, SkeletonText} from '@chakra-ui/react'

function ProductDetailSkeleton() {
    return (
        <Box>
            <Flex flexWrap="wrap">
                <Box flexGrow="1" flexBasis={["100%", "100%", "40%", "40%"]} minHeight="280px">
                    <Box>
                        <Skeleton>
                            <Box minHeight="250px"></Box>
                        </Skeleton>
                    </Box>
                </Box>
                <Box flexGrow="1" flexBasis={["100%", "100%", "60%", "60%"]} pl={["0", "0", "15px", "20px"]}>
                    <Skeleton mt="4" height="20px" />
                    <SkeletonText mt="4" noOfLines={5} spacing="4" />
                </Box>
            </Flex>
            <Box mt="30px">
                <Skeleton height="20px" />
                <SkeletonText mt="4" noOfLines={6} spacing="5" />
            </Box>
        </Box>
    )
}

export default ProductDetailSkeleton
