import { config } from '../../config';
import { getFranchisee } from '../Franchisee';

const DomainUtils = async () => {

    const siteURL = config.siteURL;
    const urlPath = window.location.host.split(siteURL);
    let franchisee = null;

    if (urlPath[0] !== '') {

        franchisee = localStorage.getItem('franchisee');

        if (franchisee) {
            
            return [franchisee];

        }else{

            let subDomain = window.location.host;

            if (urlPath.length > 1) {
                subDomain = urlPath[0].split(".");
                subDomain = subDomain[0];
            }

            const response = await getFranchisee(subDomain);
            if (response.status === 'success') {
                if (response.isValid) {
                    franchisee = response.franchisee;
                    localStorage.setItem('franchisee', franchisee);
                }else{
                    console.log('Invalid domain provided');
                }
            } else {
                console.log('Api call failed');
            }

            return [franchisee];
        }
    } 

    return [franchisee]; 
}

export default DomainUtils;