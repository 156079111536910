import React from 'react';
import {Helmet} from "react-helmet-async";

const MetaDecorator = ({ title, description, keywords, featureImage='' }) => {
    const url = window.location.href; 
    return (
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={url} /> 
            {/* <meta name="title" content={title} /> 
            <meta name="keywords" content={keywords} /> 
            <meta name="description" content={description} /> 
            <meta name="url" content={url} /> 
            <meta name="image" content={url} /> 
            <meta property="site_name" content="Gift MDI" /> 

            <meta property="twitter:type" content="article" /> 
            <meta property="twitter:title" content={title} /> 
            <meta property="twitter:description" content={description} /> 
            <meta property="twitter:url" content={url} /> 
            <meta property="twitter:image" content={featureImage} /> 
            <meta property="twitter:site_name" content="Gift MDI" /> 

            <meta property="og:type" content="article" /> 
            <meta property="og:title" content={title} /> 
            <meta property="og:description" content={description} /> 
            <meta property="og:url" content={url} /> 
            <meta property="og:image" content={featureImage} /> 
            <meta property="og:site_name" content="Gift MDI" />  */}
        </Helmet> 
    ) 
} 

export default MetaDecorator;

