import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Container,
  Box,
  Text,
  Flex,
  Image,
  Tag,
  Icon,
  Link,
  Center,
} from '@chakra-ui/react';
import { FaCalendarAlt, FaUserAlt, FaCommentAlt } from 'react-icons/fa';
import DOMPurify from 'dompurify';
import axios from 'axios';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled from 'styled-components';
import { config } from '../../config';
import './webBlog.css';
import MetaDecorator from '../../components/metaDecorator';
import useDomain from '../../service/DomainHook';
import CreateBlogComment from './Comments/CreateBlogComment';
import FetchListData from './Comments/FetchListData';
import GoogleAd from './GoogleAd';
import { splitTextByTag } from './split-text-by-tag';

export const getDate = (date) => {
  let day = new Date(date).getDate();
  day = day > 9 ? day : `0${day}`;

  let month = new Date(date).getMonth() + 1;
  month = month > 9 ? month : `0${month}`;

  let year = new Date(date).getFullYear();

  switch (month) {
    case 1: {
      month = 'January';
      break;
    }
    case 2: {
      month = 'February';
      break;
    }
    case 3: {
      month = 'March';
      break;
    }
    case 4: {
      month = 'April';
      break;
    }
    case 5: {
      month = 'May';
      break;
    }
    case 6: {
      month = 'June';
      break;
    }
    case 7: {
      month = 'July';
      break;
    }
    case 8: {
      month = 'August';
      break;
    }
    case 9: {
      month = 'September';
      break;
    }
    case 10: {
      month = 'October';
      break;
    }
    case 11: {
      month = 'November';
      break;
    }
    case 12: {
      month = 'December';
      break;
    }
    // default: return;
  }

  return `${year}-${month}-${day}`;
};

function BlogDetails() {
  const history = useHistory();
  const baseURL = `${config.baseUrl}/api/v1`;
  const theme = JSON.parse(localStorage.getItem('theme'));

  const BlogBody = styled.div`
    margin-top: 0px;
    padding: 0px 15px;
    // overflow-y: hidden;
    p {
      margin: 10px 0;
      min-height: 10px;
    }
    ul,
    li {
      margin-left: 1.5em;
    }
    ol,
    li {
      margin-left: 1.5em;
    }
    blockquote {
      text-align: justify;
      border-left: 5px solid #dddddd;
      padding: 20px 15px 20px 40px;
      margin: 20px 0;
      background: #edf2f7;
      border-radius: 5px;
      @media (max-width: 500px) {
        padding: 10px 15px 10px 25px;
      }
    }
    img {
      margin: 10px 0;
    }
    pre {
      background: #f1f1f1;
      border-radius: 3px;
      padding: 1px 10px;
      margin: 15px 0;
      padding: 20px;
    }
  `;

  const [post, setPost] = useState({});
  const [postBody, setPostBody] = useState(null);
  const [postId, setPostId] = useState('');
  const [keywords, setKeywords] = useState('');
  const [notFound, setNotFound] = useState(false);
  const [hasSubDomain] = useDomain();

  const location = useLocation();
  let pathname = location.pathname;
  const length = pathname.split('/').length;
  const slug = pathname.split('/')[2];

  // Code for Comment
  const [totalComment, setTotalComment] = useState(0);
  const [comments, setComments] = useState([]);
  const [totalComment2, setTotalComment2] = useState(0);
  const [page, setPage] = useState(1);
  const limit = 2;

  const [render, setRender] = useState(false);

  const updateCommentCounter = (data) => {
    setTotalComment(data);
  };

  const commentListHandler = () => {
    axios({
      url: `/blogComments?postId=${postId}&page=${page}&limit=${limit}`,
      method: 'get',
      baseURL: baseURL,
    })
      .then((res) => {
        setComments(res.data?.data);
        setTotalComment2(res.data?.result);
        setTotalComment(res.data?.result);
      })
      .catch((err) => console.log(err));
  };

  // *** END *** //

  useEffect(() => {
    window.scrollTo(0, 0);

    const params = {};
    const menuParams = {};
    params.slug = slug;
    if (hasSubDomain) {
      const franchisee = localStorage.getItem('franchisee');
      params.adminType = 'CA';
      params.franchiseeId = franchisee;
      menuParams.adminType = 'CA';
      menuParams.franchiseeId = franchisee;
    } else {
      params.adminType = 'SA';
      menuParams.adminType = 'SA';
    }
    menuParams.publish = true;
    params.limit = 1;

    axios({
      url: '/posts',
      method: 'get',
      baseURL: baseURL,
      params: params,
    })
      .then(({ data }) => {
        if (data?.data.length > 0) {
          setPost(data.data[0]);
          setPostId(data.data[0]._id);
          const body = data.data[0]?.body;
          if(body){
            const splitBody = splitTextByTag(body,'<ins>');
            setPostBody(splitBody);
          }
          
          let sentence = '';
          for (let obj of data.data?.[0].tags) {
            if (!sentence) sentence = obj.name;
            else sentence = sentence + ', ' + obj.name;
          }
          setKeywords(sentence);
        } else {
          setNotFound(true);
        }
      })
      .catch((err) => { console.log(err); setNotFound(true); });
  }, []);

  const mapDate = (date) => {
    let day = new Date(date).getDate();
    day = day > 9 ? day : `0${day}`;

    let month = new Date(date).getMonth() + 1; // january gives 0
    // month = month > 9 ? month : `0${month}`
    switch (month) {
      case 1: {
        month = 'January';
        break;
      }
      case 2: {
        month = 'February';
        break;
      }
      case 3: {
        month = 'March';
        break;
      }
      case 4: {
        month = 'April';
        break;
      }
      case 5: {
        month = 'May';
        break;
      }
      case 6: {
        month = 'June';
        break;
      }
      case 7: {
        month = 'July';
        break;
      }
      case 8: {
        month = 'August';
        break;
      }
      case 9: {
        month = 'September';
        break;
      }
      case 10: {
        month = 'October';
        break;
      }
      case 11: {
        month = 'November';
        break;
      }
      case 12: {
        month = 'December';
        break;
      }
    }

    let year = new Date(date).getFullYear();
    year = year > 9 ? year : `0${year}`;

    return { day, month, year };
  };

// useEffect(() => {
//   try {
//     (window.adsbygoogle = window.adsbygoogle || []).push({});
//   } catch (e) {
//     console.log('Error in showing add');
//     console.log(e);
//   }
// }, [render])


  return (
    <>
      {notFound && <Center my={'200px'}>
        <h1 color='red'>Blog Not Found</h1>
      </Center>}
      {Object.keys(post).length > 0 && (
        <Box>
          <MetaDecorator
            title={post?.title}
            description={post?.excerpt}
            keywords={keywords}
            featureImage={''}
          />

          <Box h="60px" bg="#edf2f9" />

          <Box bg="#edf2f9">
            <Container
              maxWidth={1100}
              pt={{ base: 0, sm: 0, md: 2 }}
              pb={{ base: 0, sm: 0, md: 4 }}
              px={{ base: 0, sm: 0, md: 4 }}>
              <Box
                w="100%"
                bg="white"
                py={{ base: 4, sm: 4, md: 10, lg: 10 }}
                my={{ base: 6, sm: 6, md: 10, lg: 10 }}
                px={{ base: 4, sm: 4, md: 10, lg: 10 }}
                rounded={{ base: null, sm: null, md: 'md', lg: 'md' }}
                boxShadow={{ base: null, sm: null, md: 'lg', lg: 'lg' }}>
                <Box mb="10px">
                  <Text
                    as="h1"
                    fontSize={['18px', '18px', '20px', '25px']}
                    fontWeight="600">
                    {post.title}
                  </Text>
                  <Flex
                    flexWrap="wrap"
                    fontSize={['13px', '13px', '14px', '15px']}>
                    <Box>
                      <Flex flexWrap="wrap">
                        <Icon as={FaCalendarAlt} mt="2px" /> &nbsp; &nbsp;
                        <Text mr="10px" color={theme?.color?.accent}>
                          {`${mapDate(post.createdAt).month} ${mapDate(post.createdAt).day
                            }, ${mapDate(post.createdAt).year}`}
                        </Text>{' '}
                        |
                      </Flex>
                    </Box>

                    <Box>
                      <Flex flexWrap="wrap">
                        <Icon as={FaUserAlt} mt="2px" ml="10px" /> &nbsp; &nbsp;
                        <Text
                          mr="10px"
                          color={theme?.color?.accent}
                          cursor="pointer"
                          _hover={{ filter: 'brightness(85%)' }}>
                          {`${post.authorId?.firstName}`}
                        </Text>
                      </Flex>
                    </Box>

                    <Box>
                      <Flex flexWrap="wrap">
                        | <Icon as={FaCommentAlt} mt="2px" ml="10px" />
                        &nbsp; &nbsp;
                        <Link
                          color={theme?.color?.accent}
                          cursor={totalComment > 0 && 'pointer'}
                          _hover={{
                            textDecoration:
                              totalComment === 0 ? 'none' : 'underline',
                            filter: 'brightness(85%)',
                          }}
                          href={totalComment > 0 ? '#blogComment' : '#'}>
                          {totalComment > 1
                            ? `${totalComment} comments`
                            : `${totalComment} comment`}
                        </Link>
                      </Flex>
                    </Box>
                  </Flex>

                  <Box className="clearFix" mt="20px">
                    <Box>
                    </Box>
                    {postBody?.map((body,index)=>
                      (
                        <>
                        { body.type == 'html' ? 
                        <BlogBody
                          className="rich-editor"
                          dangerouslySetInnerHTML={{ __html: body.content }}
                        /> : <GoogleAd ins={body.content}/>}
                        </>
                      )
                    )}
                    
                    {post?.tags?.map((tag, i) => (
                      <Tag
                        key={i}
                        rounded={2}
                        textTransform="uppercase"
                        cursor="pointer"
                        mr="10px"
                        mt="10px"
                        // bg="linear-gradient(to right, #BEE3F8 50%, #edf2f9 50%)"
                        backgroundPosition="right bottom"
                        backgroundSize="200% 100%"
                        transition="all .5s ease-out"
                        // _hover={{ backgroundPosition: 'left bottom' }}
                        _hover={{
                          backgroundPosition: 'left bottom',
                          backgroundColor: theme?.color?.accent,
                        }}
                        onClick={() => history.push(`/blog/tag/${tag._id}`)}>
                        {tag.name}
                      </Tag>
                    ))}
                  </Box>
                </Box>

                {/* Blog Comment */}
                <Box id="blogComment" pt="35px">
                  <FetchListData
                    postSlug={post?.slug}
                    totalComment={totalComment}
                    updateCommentCounter={updateCommentCounter}
                    comments={comments}
                    setComments={setComments}
                    totalComment2={totalComment2}
                    setTotalComment2={setTotalComment2}
                    limit={limit}
                    // mapDate={mapDate}
                    getDate={getDate}
                    postId={post._id}
                    page={page}
                    setPage={setPage}
                  />
                  <CreateBlogComment
                    postId={post?._id}
                    totalComment={totalComment}
                    updateCommentCounter={updateCommentCounter}
                    commentListHandler={commentListHandler}
                  />
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>
      )}
    </>
  );
}

export default BlogDetails;
