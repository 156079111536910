import axios from "axios"
import {config} from '../config'

const baseUrl = `${config.baseUrl}/api/v1`;

export const PostRequest = async (params) => {

    try {
        const response = await axios({
            url: params.url,
            method: params.method,
            baseURL: baseUrl,
            headers: {'Authorization': `Bearer ${params.token}`},
            data: params.data
        });

        return response.data;

      } catch (error) {
        return error.response;
      }
}