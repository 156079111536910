import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ThemeContextProvider from "./context/ThemeContext";
import ChakraThemeProvider from './ChakraThemeProvider'
import CartContextProvider from "./context/CartContext";
// const stripePromise = loadStripe(
// 	"pk_test_2Q1mGi01loYjgDczY12NwfUR"
// );

ReactDOM.render(


		<Router>
			<HelmetProvider>
				<ThemeContextProvider>
					<CartContextProvider>
						<ChakraThemeProvider />
					</CartContextProvider>
				</ThemeContextProvider>
			</HelmetProvider>
		</Router>,

	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


{/* <React.StrictMode>
		<Router>
			<HelmetProvider>
				<ThemeContextProvider>
					<CartContextProvider>
						<ChakraThemeProvider />
					</CartContextProvider>
				</ThemeContextProvider>
			</HelmetProvider>
		</Router>
	</React.StrictMode>, */}