import React, {useState, useEffect} from 'react'; 
import { useForm } from 'react-hook-form'; 
import { 
    Container, Box, Text, Button, Flex, Input, Checkbox, 
    FormControl, FormLabel, InputGroup, InputRightElement, 
    FormErrorMessage, useToast, SimpleGrid, Select
} from '@chakra-ui/react'; 
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'; 
import axios from 'axios'; 
import {config} from  "../../config"; 
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb'; 


function PaymentConfig() { 
    const { baseUrl } = config; 
    const toast = useToast(); 
    
    const { register, handleSubmit, errors, reset } = useForm({ 
        mode: 'onChange', 
        shouldFocusError: false 
    }) 


    const [passwordVisible, setPasswordVisible] = useState(false); 
    const [ID, setID] = useState(''); 
    const [ stripeActive, setStripeActive] = useState();
    const [ paypalActive, setPaypalActive] = useState();

    useEffect(() => { 
        const token = localStorage.getItem('token') 
        const franchiseeId = localStorage.getItem('franchiseeId') 
        const role = localStorage.getItem('role') 
        let url = ''

        if(!franchiseeId || franchiseeId == null || role === 'SUPERADMIN'){
            url = `/api/v1/payments/payment-gateway-settings?adminType=SA`
        }
        else{
            url = `/api/v1/payments/payment-gateway-settings?adminType=CA&franchiseeId=${franchiseeId}`
        }

        axios({ 
            url: url, 
            method: 'get', 
            baseURL: baseUrl, 
            headers: {'Authorization': `Bearer ${token}`} 
        }) 
        .then(res => { 
            setID(res?.data?.data?._id) 
            setStripeActive(res?.data?.data?.isStripeActive)
            setPaypalActive(res?.data?.data?.isPaypalActive)
            reset(res?.data?.data) 
        }) 
        .catch(err => console.log(err)) 
    }, []) 

    const submitHandler = (data) => { 
        const franchiseeId = localStorage.getItem('franchiseeId') 
        const token = localStorage.getItem('token'); 
        const role = localStorage.getItem('role')

        let method = 'post'; 
        let url = `/api/v1/payments/payment-gateway-settings`; 

        if(!franchiseeId || franchiseeId == null || role === 'SUPERADMIN'){
            data.adminType = 'SA'; 
        }
        else{
            data.adminType = 'CA'; 
            data.franchiseeId = franchiseeId; 
        }
        

        if(ID) { 
            method = 'patch'; 
            url = `/api/v1/payments/payment-gateway-settings/${ID}`; 
        } 

        // if(data.paypalClientId){
        //     data.gatewayName = 'paypal'
        // }
        // else if(data.paypalClientId){
        //     data.gatewayName = 'paypal'
        // }


        axios({ 
            url: url, 
            method: method, 
            baseURL: baseUrl, 
            headers: {'Authorization': `Bearer ${token}`}, 
            data: data 
        }) 
        .then(res => {
            if(ID) { 
                toast({ 
                    title: "Payment Configuration Updated", 
                    // description: "You have just updated mail configuration successfully.", 
                    status: "success", 
                    duration: 4000, 
                    isClosable: true, 
                }) 
            } 
            else {
                toast({ 
                    title: "Payment Configuration Created", 
                    status: "success", 
                    duration: 4000, 
                    isClosable: true, 
                }) 
            }

            setID(res.data.data._id); 
        }) 
        .catch(err => console.log(err)) 
    } 

    return ( 
        <Container maxWidth={1100} centerContent my={6}> 
            <Box w="100%" bg="white" rounded="lg" boxShadow="lg" p={4}> 
                <Flex> 
                    <Breadcrumb pathname="Payment Config" /> 
                </Flex> 

                <form onSubmit={handleSubmit(submitHandler)}> 



                    {/* <Text fontWeight="600" mt={6}> 
                        Stripe
                    </Text>  */}

                    <SimpleGrid 
                        columns={{base: 1, sm: 1, md: 2, lg: 2}} 
                        spacing="15px"
                    > 
                        <FormLabel as={'h1'} fontWeight="semibold" mt={6}> 
                            Stripe
                        </FormLabel> 

                        <FormControl> 
                            <Flex mt={6}> 
                                <FormLabel as={'h1'} fontWeight="semibold" m={0}>Active</FormLabel> 
                                <Checkbox 
                                    ml={2}
                                    isChecked={stripeActive}
                                    name="isStripeActive" 
                                    ref={register} 
                                    onChange={(e) => setStripeActive(e.target.checked)}
                                /> 
                            </Flex> 
                        </FormControl> 
                    </SimpleGrid> 

                    <SimpleGrid 
                        columns={{base: 1, sm: 1, md: 2, lg: 2}} 
                        spacing="15px"
                    > 
                        <FormControl isInvalid={errors.publicKey} mr={4}> 
                            <FormLabel as={'p'} fontWeight="semibold" m={0} mt={4}>Public Key</FormLabel> 
                            <Input 
                                type="text" name="publicKey" variant="flushed" size="sm" 
                                ref={register}
                            /> 
                            <FormErrorMessage>{errors.publicKey?.message}</FormErrorMessage>
                        </FormControl> 

                        <FormControl isInvalid={errors.privateKey} mr={4}> 
                            <FormLabel as={'p'} fontWeight="semibold" m={0} mt={4}>Private Key</FormLabel> 
                            <Input 
                                type="text" name="privateKey" variant="flushed" size="sm" 
                                ref={register}
                            /> 
                            <FormErrorMessage>{errors.privateKey?.message}</FormErrorMessage>
                        </FormControl> 
                    </SimpleGrid> 


                    <SimpleGrid 
                        columns={{base: 1, sm: 1, md: 2, lg: 2}} 
                        spacing="15px"
                    > 
                        <FormLabel as={'h1'} fontWeight="semibold" mt={6}> 
                            Paypal
                        </FormLabel> 

                        <FormControl> 
                            <Flex mt={6}> 
                                <FormLabel as={'h1'} fontWeight="semibold" m={0}>Active</FormLabel> 
                                <Checkbox 
                                    ml={2}
                                    isChecked={paypalActive}
                                    name="isPaypalActive" 
                                    ref={register} 
                                    onChange={(e) => setPaypalActive(e.target.checked)}
                                /> 
                            </Flex> 
                        </FormControl> 
                    </SimpleGrid> 

                    <SimpleGrid 
                        columns={{base: 1, sm: 1, md: 2, lg: 2}} 
                        spacing="15px"
                    > 
                        <FormControl isInvalid={errors.paypalEmail} mr={4}> 
                            <FormLabel as={'p'} fontWeight="semibold" m={0} mt={4}>Email</FormLabel> 
                            <Input 
                                type="text" name="paypalEmail" variant="flushed" size="sm" 
                                ref={register}
                            /> 
                            <FormErrorMessage>{errors.paypalEmail?.message}</FormErrorMessage>
                        </FormControl> 

                        <FormControl isInvalid={errors.paypalClientId} mr={4}> 
                            <FormLabel as={'p'} fontWeight="semibold" m={0} mt={4}>Client ID</FormLabel>
                            <Input 
                                type="text" name="paypalClientId" variant="flushed" size="sm" 
                                ref={register}
                            /> 
                            <FormErrorMessage>{errors.paypalClientId?.message}</FormErrorMessage>
                        </FormControl> 
                    </SimpleGrid> 
                    





                    

                    <Button type="submit" colorScheme="teal" variant="outline" size="sm" mt={6}> 
                        Save 
                    </Button> 
                </form> 
            </Box> 
        </Container> 
    ) 
} 

export default PaymentConfig;