import React, { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { config } from '../../config'
import { Box, Flex, Heading, FormLabel, FormControl } from '@chakra-ui/react';
import DatePickerComponent from './DatePickerComponent';

import TableComponent from './Table';
import useDomain from '../../service/DomainHook';
const BlogComments = ({ styles }) => {
    const baseURL = `${config.baseUrl}/api/v1`;

    const [blogCommentsData, setBlogCommentsData] = useState([]);
    const [startDate, setStartDate] = useState(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000));
    const [endDate, setEndDate] = useState(new Date());

    const franchiseeId = localStorage.getItem('franchisee');
    const token = localStorage.getItem('token');
    const [hasSubDomain] = useDomain();

    const formatDate = (date) => {
        return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    };

    const handleSubmit = () => {


        let data = {};

        if (hasSubDomain) {
            data = {
                adminType: 'CA',
                franchiseeId: franchiseeId,
                fromDate: formatDate(startDate),
                toDate: formatDate(endDate)
            }
        }
        else {
            data = {
                adminType: 'SA',
                franchiseeId: franchiseeId,
            }
        }

        axios({
            url: `/blogComments/latestComments`,
            method: 'post',
            baseURL: baseURL,
            headers: { 'Authorization': `Bearer ${token}` },
            data: data
        })
            .then(res => {
                setBlogCommentsData(res.data.data)
            }).catch(error => {
                console.log(error)
            })
    };

    useEffect(() => {

        let data = {}

        if(hasSubDomain){
            data = {
                adminType: 'CA',
                franchiseeId: franchiseeId,
                fromDate: formatDate(startDate),
                toDate: formatDate(endDate)
            };
        }
        else{
            data = {
                adminType: 'SA',
                franchiseeId: franchiseeId
            };
        }

        axios({
            url: `/blogComments/latestComments`,
            method: 'post',
            baseURL: baseURL,
            headers: { 'Authorization': `Bearer ${token}` },
            data: data
        })
            .then(res => {
                setBlogCommentsData(res.data.data)
            }).catch(error => {
                console.log(error)
            })

    }, [])

    useEffect(() => {
        handleSubmit();
    }, [startDate, endDate])

    const columns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'authorName',
        },

        {
            Header: 'Email',
            accessor: 'authorEmail',
            Cell: props => {
                return (
                    <>
                        {!props.value ? 'N/A' : props.value}
                    </>
                );
            }

        },
        {
            Header: 'Comment',
            accessor: 'message',
        },

    ], []);

    const data = useMemo(() => (blogCommentsData), [blogCommentsData]);

    return (
        <Box {...styles} width={["100%", "100%", "50%", "50%"]}>
            <Heading size="md" textAlign="center">Latest Blog Comments</Heading>

            {
                hasSubDomain ?
                    <Flex justifyContent="flex-end" align='center' mt={2}>
                        <FormControl flex="1 1">
                            <Flex justifyContent="flex-end">
                                <FormLabel alignSelf="flex-end" m={0} px="10px">From Date:</FormLabel>
                                <DatePickerComponent startDate={startDate} setStartDate={setStartDate} />
                            </Flex>
                        </FormControl>
                        <FormControl flex="1 1">
                            <Flex justifyContent="flex-end">
                                <FormLabel alignSelf="flex-end" m={0} px="10px">To Date:</FormLabel>
                                <DatePickerComponent startDate={endDate} setStartDate={setEndDate} />
                            </Flex>
                        </FormControl>
                    </Flex>
                    : null
            }
            <TableComponent columns={columns} data={data} title='' />
        </Box>
    )
}

export default BlogComments;