import React, { useState, useEffect, useRef, useContext } from 'react'
import {
    Container, Box, Text, Center, FormControl, FormLabel,
    Input, SimpleGrid, InputGroup, InputRightAddon, Button,
    Modal, ModalOverlay, ModalContent, useDisclosure, Image,
    useToast, Checkbox, Select, NumberInput, NumberInputField, NumberInputStepper,
    NumberIncrementStepper, NumberDecrementStepper, Flex, HStack, ModalHeader, ModalCloseButton, ModalBody, FormErrorMessage,
} from '@chakra-ui/react';
import { SketchPicker } from 'react-color';
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { config } from '../../config';
import { ThemeContext } from '../../context/ThemeContext';
import SelectMultiple from 'react-select'
import { editorFontList } from '../../service/utils/FontList';




function GlobalSetting() {

    const { 1: dispatch } = useContext(ThemeContext);
    const token = localStorage.getItem('token');
    const [state, setState] = useState({
        color: "#BDBDBD",
        primaryColor: "#BDBDBD",
        secondaryColor: "#BDBDBD",
        accentColor: "#BDBDBD",
    });

    const [fontState, setFontState] = useState({
        fontFamily: "Poppins",
        fontSize: 14,
        fontWeight: 400,
    })

    const [headerFontState, setHeaderFontState] = useState({
        fontFamily: "Poppins",
        fontSize: 40,
        fontWeight: 600,
        textAlign: 'left',
        color: '#BDBDBD'
    })

    const [id, setId] = useState(0);
    const [logo, setLogo] = useState(null);
    const [previewImage, setPreviewImage] = useState('');
    const [loading, setLoading] = useState(false);

    const [imgUpLoad, setImgUpLoad] = useState(false);

    const [previewFavIcon, setPreviewFavIcon] = useState('');

    const [previewShopHeaderImage, setPreviewShopHeaderImage] = useState('');
    const [previewBlogHeaderImage, setPreviewBlogHeaderImage] = useState('');
    const [notificationEmailOption, setNotificationEmailOption] = useState([]);
    const [dialogInputEmail, setDialogInputEmail] = useState({});
    const [handleFormData, setHandleFormData] = useState({
        logoData: null,
        favData: null,
        shopData: null,
        blogData: null,
    });
    const [updateStatus, setUpdateStatus] = useState({
        isLogoChange: false,
        isFavChange: false,
        isBlogChange: false,
        isShopChange: false,
    });

    const toast = useToast();
    const baseURL = `${config.baseUrl}/api/v1`;
    const { register, handleSubmit, control, reset, setValue, errors } = useForm();
    const colorRef = useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenEM, onOpen: onOpenEM, onClose: onCloseEM } = useDisclosure();
    const { isOpen: isOpenHeaderColor, onOpen: onOpenHeaderColor, onClose: onCloseHeaderColor } = useDisclosure();

    const handleImageUpdate = async (id, type, data) => {

        axios({
            url: `/globalSettings/${id}`,
            method: 'post',
            baseURL: baseURL,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({ data }) => {

                // toast({
                //     title: "Header banner updated.",
                //     description: "Header banner updated successfully",
                //     status: "success",
                //     duration: 4000,
                //     isClosable: true
                // })
            })
            .catch(err => {
                if (err instanceof Error) {
                    toast({
                        title: `${type} image not updated`,
                        description: err?.response?.data?.message,
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    })
                }

            })
    }

    function imageHandler(e) {
        // To preview before upload --- Converting to base 64 
        const reader = new FileReader()
        const name = e.target.name
        const logoFormData = new FormData();
        const favFormData = new FormData();
        reader.onload = () => {
            if (reader.readyState === 2) {
                if ((name === 'logo')) {
                    setPreviewImage(reader.result);
                    setUpdateStatus({ ...updateStatus, isLogoChange: true })
                    logoFormData.append('logo', e.target.files[0]);

                    setHandleFormData({
                        ...handleFormData,
                        logoData: logoFormData,

                    })

                }

                if ((name === 'favIcon')) {
                    setPreviewFavIcon(reader.result);
                    favFormData.append('favIcon', e.target.files[0]);
                    setUpdateStatus({ ...updateStatus, isFavChange: true })
                    setHandleFormData({
                        ...handleFormData,
                        // logoData: logoFormData,
                        favData: favFormData
                    })
                }
            }
        }

        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0])
        }
    };

    function headerImageHandlerV2(e) {
        const reader = new FileReader()
        const headerImageName = e.target.name
        const shopFormData = new FormData();
        const blogFormData = new FormData();

        reader.onload = () => {
            if (reader.readyState === 2) {


                if (headerImageName === 'shopHeaderImage') {
                    setPreviewShopHeaderImage(reader.result);
                    shopFormData.append('shopHeaderImage', e.target.files[0]);
                    setUpdateStatus({ ...updateStatus, isShopChange: true })
                    setHandleFormData({
                        ...handleFormData,
                        shopData: shopFormData
                    })

                }
                if (headerImageName === 'blogHeaderImage') {
                    setPreviewBlogHeaderImage(reader.result);
                    blogFormData.append('blogHeaderImage', e.target.files[0]);
                    setUpdateStatus({ ...updateStatus, isBlogChange: true })
                    setHandleFormData({
                        ...handleFormData,
                        blogData: blogFormData
                    })
                }
            }
        }

        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0])
        }
    }

    useEffect(async () => {
        const franchiseeId = localStorage.getItem('franchiseeId');
        let userEmail = []
        await axios({
            url: `users?adminType=SA&fields=email`,
            method: 'get',
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
            .then((res) => {
                const { data: serverData, result: totalLength } = res.data

                userEmail = serverData.map((item, index) => {
                    return {
                        label: item.email,
                        value: item.email,
                    }
                })
            })
            .catch(err => console.log(err, 'Data fetch error'))

        await axios({
            url: `/globalSettings?adminType=SA&sort=createdAt&limit=1`,
            method: 'get',
            baseURL: baseURL
        })
            .then(response => {
                const data = response.data.data;
                // console.log('&sort=createdAt&limit=1')
                if (data.length) {
                    const globalSettings = data[0];
                    setId(globalSettings._id);
                    // setHasList(true); 

                    const resetData = {};
                    resetData.title = globalSettings.title;
                    resetData.brand = globalSettings.brand;
                    // resetData.shopLayout = globalSettings.shopLayout;
                    // resetData.pReview = globalSettings.pReview;
                    resetData.primaryColor = globalSettings.theme.color.primary;
                    resetData.secondaryColor = globalSettings.theme.color.secondary;
                    resetData.accentColor = globalSettings.theme.color.accent;
                    resetData.fontFamily = globalSettings?.fontFamily;
                    resetData.fontSize = globalSettings?.fontSize;
                    resetData.fontWeight = globalSettings?.fontWeight;
                    // resetData.handlingCharge = globalSettings?.handlingCharge;
                    // resetData.handleType = globalSettings?.handleType;
                    resetData.notificationEmail = globalSettings?.notificationEmail;
                    resetData.pageHeaderStyle = globalSettings?.pageHeaderStyle;

                    // notificationEmail
                    reset(resetData);

                    if (globalSettings.logo) {
                        // setLogo(globalSettings.logo);
                        setPreviewImage(globalSettings.logo);
                    };

                    if (globalSettings?.favIcon) {
                        // setFavIcon(globalSettings?.favIcon);
                        setPreviewFavIcon(globalSettings?.favIcon);
                    };

                    if (globalSettings.shopHeaderImage) {
                        // setShopHeaderImage(globalSettings.shopHeaderImage);
                        setPreviewShopHeaderImage(globalSettings.shopHeaderImage);
                    };

                    if (globalSettings.blogHeaderImage) {
                        // setBlogHeaderImage(globalSettings.blogHeaderImage);
                        setPreviewBlogHeaderImage(globalSettings.blogHeaderImage);
                    };

                    setState({
                        ...state,
                        primaryColor: globalSettings.theme.color.primary,
                        secondaryColor: globalSettings.theme.color.secondary,
                        accentColor: globalSettings.theme.color.accent,
                    });

                    setFontState({
                        ...headerFontState,
                        fontFamily: globalSettings.fontFamily,
                        fontSize: parseInt(globalSettings.fontSize),
                        fontWeight: globalSettings.fontWeight,
                    });

                    setHeaderFontState({
                        ...headerFontState,
                        fontFamily: globalSettings?.pageHeaderStyle?.fontFamily,
                        fontSize: parseInt(globalSettings?.pageHeaderStyle?.fontSize),
                        fontWeight: globalSettings?.pageHeaderStyle?.fontWeight,
                        color: globalSettings?.pageHeaderStyle?.color,
                        textAlign: globalSettings?.pageHeaderStyle?.textAlign,
                    });

                    setNotificationEmailOption([
                        ...notificationEmailOption,
                        ...globalSettings.notificationEmail,
                        ...userEmail
                    ])

                }
            })
            .catch(error => console.log(error))
    }, [])

    const handleModalOpen = (e) => {
        colorRef.current = e.target.id;
        onOpen();
    }

    const handleColor = (color) => {
        const newState = { ...state };
        newState.color = color.hex;
        newState[colorRef.current] = color.hex;
        setValue(colorRef.current, color.hex);
        setState(newState);
    };

    const handleHeaderColorModalOpen = (e) => {
        colorRef.current = e.target.id;
        onOpenHeaderColor();
    }

    const handleHeaderColor = (color) => {
        const newHeaderFontState = { ...headerFontState };
        newHeaderFontState.color = color.hex;
        newHeaderFontState[colorRef.current] = color.hex;
        setValue('pageHeaderStyle.color', color.hex);
        setHeaderFontState(newHeaderFontState);
    };

    const formSubmit = (data) => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const franchiseeId = localStorage.getItem('franchiseeId');
        // localStorage.setItem('pReview', data.pReview);
        localStorage.setItem('shop', data.shopLayout);
        const formData = {
            adminType: 'SA',
            // franchiseeId: franchiseeId,
            title: data.title,
            brand: data.brand,
            theme: {
                color: {
                    primary: data.primaryColor,
                    secondary: data.secondaryColor,
                    accent: data.accentColor
                }
            },
            fontFamily: data.fontFamily,
            fontSize: data.fontSize,
            fontWeight: data.fontWeight,
            // shopLayout: data.shopLayout,
            // pReview: data.pReview,
            // handlingCharge: data.handlingCharge,
            // handleType: data.handleType,
            notificationEmail: data.notificationEmail,
            pageHeaderStyle: data.pageHeaderStyle
        }

        let url = `/globalSettings/${id}`;
        let method = 'patch';
        if (!id) {
            url = `/globalSettings`;
            method = 'post';
        }

        localStorage.setItem('theme', JSON.stringify(formData?.theme));

        axios({
            url: url,
            method: method,
            baseURL: baseURL,
            headers: { 'Authorization': `Bearer ${token}` },
            data: formData
        })
            .then(async (response) => {
                setId(response.data.data._id);
                const { _id } = response.data.data;

                if (updateStatus.isLogoChange) {
                    await handleImageUpdate(_id, 'Logo', handleFormData.logoData).then((res) => {
                        setUpdateStatus({ ...updateStatus, isLogoChange: false });
                    })
                }

                if (updateStatus.isFavChange) {
                    await handleImageUpdate(_id, 'FavIcon', handleFormData.favData).then(() => {
                        setUpdateStatus({ ...updateStatus, isFavChange: false });
                    })
                }

                // if (updateStatus.isShopChange) {
                //     await handleImageUpdate(_id, 'Shop', handleFormData.shopData).then(() => {
                //         setUpdateStatus({ ...updateStatus, isShopChange: false });
                //     })
                // }

                if (updateStatus.isBlogChange) {
                    await handleImageUpdate(_id, 'Blog', handleFormData.blogData).then(() => {
                        setUpdateStatus({ ...updateStatus, isBlogChange: false });
                    })
                }

                toast({
                    title: "Global Settings Updated Successfully",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });

                dispatch({
                    type: 'THEME_CHANGE',
                    payload: {
                        fonts: {
                            size: data.fontSize,
                            family: data.fontFamily,
                            weight: data.fontWeight
                        },
                        color: {
                            primary: data.primaryColor,
                            secondary: data.secondaryColor,
                            accent: data.accentColor
                        }
                    }
                })
            })
            .catch(error => {
                toast({
                    title: "Error!",
                    description: error?.response?.data?.message,
                    position: 'top',
                    status: "error",
                    duration: 8000,
                    isClosable: true
                });
            }).finally(() => {
                setLoading(false)
            })
    }

    console.log({ saId: id })

    return (
        <>
            <Container maxWidth={1100} centerContent mt={6} mb={5}>
                <Box w="100%" bg="white" rounded="md" boxShadow="lg" p="15px">
                    <Breadcrumb pathname="Global setting" />

                    <Center>
                        <Box width="80%" p={5}>
                            <form onSubmit={handleSubmit(formSubmit)}>
                                <Box mb="30px">
                                    <SimpleGrid minChildWidth="12rem" spacing="15px">
                                        <FormControl id="title" isInvalid={errors.title}>
                                            <FormLabel fontSize="1rem" fontWeight="700">Title</FormLabel>
                                            <Input type="text" name="title" ref={register({ required: 'Title is required' })}
                                            />
                                            <FormErrorMessage mb="20px">{errors?.title?.message}</FormErrorMessage>
                                        </FormControl>

                                        <FormControl id="brand" isInvalid={errors.brand}>
                                            <FormLabel fontSize="1rem" fontWeight="700">Brand</FormLabel>
                                            <Input type="text" name="brand" ref={register({ required: 'Brand is required' })} />
                                            <FormErrorMessage mb="20px">{errors?.brand?.message}</FormErrorMessage>
                                        </FormControl>
                                    </SimpleGrid>
                                </Box>

                                <Box mb="30px">
                                    <Text fontSize="1.1rem" fontWeight="700" mb={5}>Logo</Text>

                                    <FormControl mb={4}>
                                        <Box>
                                            <FormLabel htmlFor='logo' textAlign="center" cursor="pointer"
                                                w='100%'
                                                h='100%'
                                                lineHeight="60px"
                                                border="1px dashed #BDBDBD"
                                                // color="gray.500" 
                                                p='10px'
                                            >
                                                Click To Upload Logo
                                            </FormLabel>
                                            <Input
                                                type="file"
                                                name="logo"
                                                ref={register}
                                                id='logo'
                                                accept="image/*"
                                                onChange={(e) => imageHandler(e)}
                                                style={{ display: "none" }} />

                                        </Box>
                                        {previewImage && <Image mt='5px' src={previewImage} alt="Logo" maxH="200px" />}
                                    </FormControl>
                                </Box>

                                <Box mb="30px">
                                    <Text fontSize="1.1rem" fontWeight="700" mb={5}>Fav Icon</Text>

                                    <FormControl mb={4}>
                                        <Box>
                                            <FormLabel htmlFor='favIcon' textAlign="center" cursor="pointer"
                                                w='100%'
                                                h='100%'
                                                lineHeight="60px"
                                                border="1px dashed #BDBDBD"
                                                // color="gray.500" 
                                                p='10px'
                                            >
                                                Click To Upload Fav Icon
                                            </FormLabel>
                                            <Input
                                                type="file"
                                                name="favIcon"
                                                ref={register}
                                                id='favIcon'
                                                accept="image/*"
                                                onChange={(e) => imageHandler(e)}
                                                style={{ display: "none" }} />

                                        </Box>
                                        {previewFavIcon && <Image mt='5px' src={previewFavIcon} alt="FavIcon" maxH="100px" />}
                                    </FormControl>
                                </Box>

                                <Box mb="30px">
                                    <Text as={"h1"} fontSize="1.1rem" fontWeight="700" mb={5}>Theme</Text>
                                    <SimpleGrid minChildWidth="12rem" spacing="15px">
                                        <Box>
                                            <FormControl as={'p'} fontWeight="semibold" id="primaryColor">
                                                <FormLabel as={'p'} fontWeight="semibold" fontSize="1rem">Primary Color</FormLabel>
                                                <InputGroup>
                                                    <Input defaultValue={state.secondaryColor} ref={register} name="primaryColor" />
                                                    <InputRightAddon id="primaryColor" cursor="pointer" bg={state.primaryColor} onClick={(e) => handleModalOpen(e)} />
                                                </InputGroup>
                                            </FormControl>
                                        </Box>

                                        <Box>
                                            <FormControl id="secondaryColor">
                                                <FormLabel as={'p'} fontWeight="semibold" fontSize="1rem">Secondary Color</FormLabel>
                                                <InputGroup>
                                                    <Input defaultValue={state.secondaryColor} ref={register} name="secondaryColor" />
                                                    <InputRightAddon id="secondaryColor" cursor="pointer" bg={state.secondaryColor} onClick={(e) => handleModalOpen(e)} />
                                                </InputGroup>
                                            </FormControl>
                                        </Box>


                                        <FormControl id="accentColor">
                                            <FormLabel as={'p'} fontWeight="semibold" fontSize="1rem">Accent Color</FormLabel>
                                            <InputGroup>
                                                <Input defaultValue={state.accentColor} ref={register} name="accentColor" />
                                                <InputRightAddon id="accentColor" cursor="pointer" bg={state.accentColor} onClick={(e) => handleModalOpen(e)} />
                                            </InputGroup>
                                        </FormControl>
                                    </SimpleGrid>

                                </Box>


                                {/* Page heading font style */}
                                <Box mb="30px">
                                    <FormControl>
                                        <Box mb="30px">
                                            <Text as={"h1"} fontSize="1.1rem" fontWeight="700" mb={5}>Page Header Font Style</Text>
                                            <SimpleGrid minChildWidth="12rem" spacing="15px">

                                                <Box>
                                                    <FormLabel as={'p'} fontWeight="semibold" fontSize="16px">Font Family</FormLabel>

                                                    <Controller
                                                        defaultValue='default'
                                                        control={control}
                                                        name="pageHeaderStyle.fontFamily"
                                                        render={(
                                                            { onChange, value, name, ref }
                                                        ) => (
                                                            <Select
                                                                size="sm"
                                                                value={value}
                                                                onChange={(e) => onChange(e.target.value)}
                                                            >
                                                                {
                                                                    editorFontList.map(font => (
                                                                        <option key={font} value={font}>{font}</option>
                                                                    ))
                                                                }
                                                            </Select>)}
                                                    />
                                                </Box>

                                                <Box>
                                                    <FormLabel as={'p'} fontWeight="semibold">Font Size</FormLabel>

                                                    <Controller
                                                        defaultValue={14}
                                                        control={control}
                                                        name="pageHeaderStyle.fontSize"
                                                        render={(
                                                            { onChange, value, }
                                                        ) => (

                                                            <NumberInput
                                                                size="sm"
                                                                max={100} min={5}
                                                                value={value}
                                                                onChange={(value) => onChange(value)}
                                                            >
                                                                <NumberInputField />
                                                                <NumberInputStepper>
                                                                    <NumberIncrementStepper />
                                                                    <NumberDecrementStepper />
                                                                </NumberInputStepper>
                                                            </NumberInput>

                                                        )}
                                                    />
                                                </Box>

                                                <FormControl id="fontWeight">
                                                    <FormLabel as={'p'} fontWeight="semibold" fontSize="16px">Font Weight</FormLabel>
                                                    <Select
                                                        ref={register} name="pageHeaderStyle.fontWeight"
                                                        size="sm" defaultValue={headerFontState?.fontWeight}
                                                    >
                                                        {
                                                            ["200", "300", "400", "500", "600", "700", "800"].map(item => (
                                                                <option key={item} value={item}>{item}</option>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>

                                                <FormControl id="textAlign">
                                                    <FormLabel as={'p'} fontWeight="semibold">Text align</FormLabel>
                                                    <Select
                                                        ref={register} name="pageHeaderStyle.textAlign"
                                                        size="sm" defaultValue={headerFontState?.textAlign}
                                                    >
                                                        {
                                                            ["left", "center", "right"].map(item => (
                                                                <option key={item} value={item}>{item}</option>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>

                                                <FormControl id="headerTextColor">
                                                    <FormLabel as={'p'} fontWeight="semibold">Text Color</FormLabel>
                                                    <InputGroup>
                                                        <Input defaultValue={headerFontState.color} ref={register} name="pageHeaderStyle.color" />
                                                        <InputRightAddon id="accentColor" cursor="pointer" bg={headerFontState.color} onClick={(e) => handleHeaderColorModalOpen(e)} />
                                                    </InputGroup>
                                                </FormControl>

                                            </SimpleGrid>
                                        </Box>
                                    </FormControl>
                                </Box>


                                <Box my="30px">
                                    <Text as={"h1"} fontSize="1.1rem" fontWeight="700" mb={5}>Global Font</Text>
                                    <SimpleGrid minChildWidth="12rem" spacing="15px">

                                        {/* Font Family */}
                                        <Box>
                                            <FormLabel as={'p'} fontWeight="semibold">Font Family</FormLabel>

                                            <Controller
                                                defaultValue='default'
                                                control={control}
                                                name="fontFamily"
                                                render={(
                                                    { onChange, value, name, ref }
                                                ) => (
                                                    <Select
                                                        size="sm"
                                                        value={value}
                                                        onChange={(e) => onChange(e.target.value)}
                                                    >
                                                        {
                                                            editorFontList.map(font => (
                                                                <option key={font} value={font}>{font}</option>
                                                            ))
                                                        }
                                                    </Select>)}
                                            />
                                        </Box>



                                        {/* font size */}
                                        <Box>
                                            <FormLabel as={'p'} fontWeight="semibold">Font Size</FormLabel>

                                            <Controller
                                                defaultValue={14}
                                                control={control}
                                                name="fontSize"
                                                render={(
                                                    { onChange, value, }
                                                ) => (

                                                    <NumberInput
                                                        size="sm"
                                                        max={100} min={5}
                                                        value={value}
                                                        onChange={(value) => onChange(value)}
                                                    >
                                                        <NumberInputField />
                                                        <NumberInputStepper>
                                                            <NumberIncrementStepper />
                                                            <NumberDecrementStepper />
                                                        </NumberInputStepper>
                                                    </NumberInput>

                                                )}
                                            />
                                        </Box>




                                        {/* font weight */}
                                        <FormControl id="fontWeight">
                                            <FormLabel as={'p'} fontWeight="semibold">Font Weight</FormLabel>
                                            <Select
                                                ref={register} name="fontWeight"
                                                size="sm" defaultValue={fontState?.fontWeight}
                                            >
                                                {
                                                    ["200", "300", "400", "500", "600", "700", "800"].map(item => (
                                                        <option key={item} value={item}>{item}</option>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>

                                    </SimpleGrid>
                                </Box>

                                {/* <Box mb="30px">
                                    <Text fontSize="1.1rem" fontWeight="700" mb={5}>
                                        Shop Layout
                                    </Text>
                                    <FormControl>
                                        <Controller
                                            control={control}
                                            name="shopLayout"
                                            defaultValue="DEFAULT"
                                            render={({ onChange, onBlur, value }) => (
                                                <RadioGroup defaultValue="DEFAULT" mb="20px" id="shopLayout" onChange={onChange} value={value}>
                                                    <Stack spacing={5} direction="row">
                                                        <Radio colorScheme="teal" borderColor="gray.300" value="DEFAULT">
                                                            Default
                                                        </Radio>
                                                        <Radio colorScheme="teal" borderColor="gray.300" value="CUSTOM">
                                                            Custom
                                                        </Radio>
                                                    </Stack>
                                                </RadioGroup>
                                            )}
                                        />
                                    </FormControl>
                                </Box> */}

                                {/* <Box mb="30px">
                                    <FormLabel as={'p'} fontWeight="semibold" mb={5}>Shop Header Image</FormLabel>

                                    <FormControl mb={4}>
                                        <Box>
                                            <FormLabel htmlFor='shopHeaderImage' textAlign="center" cursor="pointer"
                                                w='100%'
                                                h='100%'
                                                lineHeight="60px"
                                                border="1px dashed #BDBDBD"
                                                // color="gray.500" 
                                                p='10px'
                                            >
                                                Click To Upload Banner Image
                                            </FormLabel>
                                            <Input
                                                type="file"
                                                name="shopHeaderImage"
                                                ref={register}
                                                id='shopHeaderImage'
                                                accept="image/*"
                                                onChange={(e) => headerImageHandler(e, 'shopHeaderImage')}
                                                style={{ display: "none" }} />

                                        </Box>
                                        {previewShopHeaderImage && <Image mt='5px' src={previewShopHeaderImage} alt="shopHeaderImage" maxH="200px" />}
                                    </FormControl>
                                </Box> */}
                                <Box mb="30px">
                                    <Text as={'p'} fontWeight="semibold" mb={5}>Blog Header Image</Text>

                                    <FormControl mb={4}>
                                        <Box>
                                            <FormLabel htmlFor='blogHeaderImage' textAlign="center" cursor="pointer"
                                                w='100%'
                                                h='100%'
                                                lineHeight="60px"
                                                border="1px dashed #BDBDBD"
                                                // color="gray.500" 
                                                p='10px'
                                            >
                                                Click To Upload Banner Image
                                            </FormLabel>
                                            <Input
                                                type="file"
                                                name="blogHeaderImage"
                                                ref={register}
                                                id='blogHeaderImage'
                                                accept="image/*"
                                                onChange={(e) => headerImageHandlerV2(e)}
                                                style={{ display: "none" }} />

                                        </Box>
                                        {previewBlogHeaderImage && <Image mt='5px' src={previewBlogHeaderImage} alt="blogHeaderImage" maxH="200px" />}
                                    </FormControl>
                                </Box>


                                {/* <Box mb="10px">
                                    <Text fontSize="1.1rem" fontWeight="700" mb={5}>
                                        Product Review
                                    </Text>
                                    <FormControl>
                                        <Controller
                                            control={control}
                                            name="pReview"
                                            defaultValue={false}
                                            render={(
                                                { onChange, onBlur, value, name, ref },
                                                { invalid, isTouched, isDirty }
                                            ) => {
                                                return (
                                                    <Checkbox
                                                        mb={5}
                                                        colorScheme="teal"
                                                        onChange={(e) => onChange(e.target.checked)}
                                                        isChecked={value}>
                                                        Allow
                                                    </Checkbox>
                                                )
                                            }}
                                        />
                                    </FormControl>
                                </Box> */}

                                {/* Handling Charge */}
                                {/* <Flex wrap="wrap">
                                    <Box mb="30px" flex="0 0" flexBasis="auto" width="45%">
                                        <FormControl as={'p'} fontWeight="semibold" mb={5}>
                                            Handling Charge $
                                        </FormControl>
                                        <FormControl width={{ base: '100%', md: '30%' }}>
                                            <Controller
                                                defaultValue={0}
                                                control={control}
                                                name="handlingCharge"
                                                render={(
                                                    { onChange, value, }
                                                ) => (

                                                    <NumberInput
                                                        precision={2}
                                                        size="sm"
                                                        min={0}
                                                        value={value}
                                                        onChange={(value) => onChange(value)}
                                                    >
                                                        <NumberInputField />
                                                        <NumberInputStepper>
                                                            <NumberIncrementStepper />
                                                            <NumberDecrementStepper />
                                                        </NumberInputStepper>
                                                    </NumberInput>

                                                )}
                                            />
                                        </FormControl>
                                    </Box>

                                    <Box mb="30px" flex="0 0" flexBasis="auto" width="45%">
                                        <FormControl as={'p'} fontWeight="semibold" mb={5}>
                                            Handling Type
                                        </FormControl>
                                        <FormControl>
                                            <Select name="handleType" ref={register} size="sm" defaultValue="BY_ITEM">
                                                <option value="BY_ITEM">By Item</option>
                                                <option value="BY_ORDER">By Order</option>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Flex> */}


                                {/* Add multiple Email for notification */}
                                <Flex wrap="wrap">

                                    <Box mb="30px" flex="0 0" flexBasis="auto" width="100%">
                                        <HStack mb={1}>
                                            <Text fontWeight="700">
                                                Email Notification
                                            </Text>
                                            <Button
                                                px='8px'
                                                lineHeight="16px"
                                                fontWeight='300'
                                                rounded='sm'
                                                fontSize='10px'
                                                _focus={{
                                                    outline: 'none'
                                                }}
                                                colorScheme="gray"
                                                size="xs"
                                                onClick={onOpenEM}>
                                                Add New
                                            </Button>
                                        </HStack>
                                        <FormControl>
                                            <Controller
                                                name="notificationEmail"
                                                control={control}
                                                options={notificationEmailOption}
                                                as={SelectMultiple}
                                                isMulti
                                                defaultValue={``}
                                                rules={{ required: false }}
                                            />

                                        </FormControl>
                                    </Box>
                                </Flex>

                                <Button isLoading={loading} loadingText='Saving...' size="sm" px="20px" variant="outline" rounded={4} type="submit" colorScheme="teal" disabled={imgUpLoad}>
                                    Save
                                </Button>
                            </form>
                        </Box>
                    </Center>
                </Box>
            </Container>

            <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom" size="sm">
                <ModalOverlay />
                <ModalContent>
                    <SketchPicker width="100%" color={state.color} onChange={handleColor} />
                </ModalContent>
            </Modal>

            <Modal isCentered onClose={onCloseHeaderColor} isOpen={isOpenHeaderColor} motionPreset="slideInBottom" size="sm">
                <ModalOverlay />
                <ModalContent>
                    <SketchPicker width="100%" color={headerFontState.color} onChange={handleHeaderColor} />
                </ModalContent>
            </Modal>

            <Modal isCentered onClose={onCloseEM} isOpen={isOpenEM} motionPreset="slideInBottom" size="sm">
                <ModalOverlay />
                <ModalContent>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Add New Email</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Box mb="30px">
                                <SimpleGrid minChildWidth="12rem" spacing="15px">
                                    <FormControl id="email">
                                        <FormLabel fontSize="1rem" fontWeight="700">Email</FormLabel>
                                        <Input
                                            type="email"
                                            name="email"
                                            // ref={register} 
                                            onChange={(e) => {
                                                setDialogInputEmail({
                                                    label: e.target.value,
                                                    value: e.target.value,
                                                })

                                            }}
                                        />
                                    </FormControl>

                                    <Button
                                        px='8px'
                                        lineHeight="16px"
                                        fontWeight='300'
                                        rounded='sm'
                                        fontSize='10px'
                                        _focus={{
                                            outline: 'none'
                                        }}
                                        colorScheme="gray"
                                        size="xs"
                                        onClick={() => {
                                            setNotificationEmailOption([
                                                ...notificationEmailOption,
                                                dialogInputEmail
                                            ])

                                            onCloseEM()
                                        }}
                                    >
                                        Add New
                                    </Button>

                                </SimpleGrid>
                            </Box>
                        </ModalBody>
                    </ModalContent>
                </ModalContent>
            </Modal>
        </>
    )
}

export default GlobalSetting; 