import React from 'react'
import { useForm } from 'react-hook-form'
import {
    Container, Box, FormControl, FormLabel, FormHelperText, Input, Button, FormErrorMessage, useToast
} from '@chakra-ui/react'
import axios from 'axios';
import { config } from '../../config'
import { useHistory } from 'react-router-dom'
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb'

function BusinessType() {

    const { register, handleSubmit, errors } = useForm({
        mode: 'onTouched',
        shouldFocusError: false
    });

    const baseUrl = config.baseUrl;

    const history = useHistory();
    const toast = useToast()

    const formSubmit = (data) => { 
        const token = localStorage.getItem('token'); 

        axios({ 
            url: '/api/v1/businessTypes', 
            method: 'post', 
            baseURL: baseUrl, 
            headers: {'Authorization': `Bearer ${token}`}, 
            data:data 
        }) 
        .then((response) => { 

            toast({ 
                title: "Business Type Created Successfully.", 
                description: "You have created a business type.", 
                status: "success", 
                duration: 4000, 
                isClosable: true 
            }) 
            history.push('/admin/business-type'); 
        }) 
        .catch((error) => console.log(error)) 
    } 

    return (
        <Container maxWidth={1100} centerContent py={6}> 
            <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg"> 
                <Breadcrumb pathname="Business Type" create={true} link={`/admin/business-type`} mb={5} />
                <form onSubmit={handleSubmit(formSubmit)}>
                    <FormControl id="businessType" mb="20px" isInvalid={errors.name}>
                        <FormLabel>Type</FormLabel>
                        <Input borderColor="gray.300" ref={register({ required: "Name field is required"})} name="name" type="text" />
                        {
                            errors.name 
                            ? <FormErrorMessage>{ errors?.name?.message }</FormErrorMessage>
                            : <FormHelperText>Example: Retails, LifeStyle Tourism</FormHelperText>
                        }
                    </FormControl>
                    <Button type="submit" colorScheme="teal" variant="outline" size="sm">Save</Button>
                </form> 
            </Box> 
        </Container> 
    )
}

export default BusinessType; 