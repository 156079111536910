import React, { useState, useEffect } from 'react'
import {
    Box,  Button,
    FormControl, Input,
    FormErrorMessage,
    ListItem,
    UnorderedList,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
  
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Flex,
    FormLabel
} from '@chakra-ui/react'
import { useForm} from 'react-hook-form'


const SubCategories = ({ subCategories, setSubCategory }) => {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { register, handleSubmit, errors } = useForm({
        mode: 'onTouched',
        shouldFocusError: false
    });

    const subCategorySubmit = async (data, e) => {
        setSubCategory([...subCategories, data.subcategoryName])
        onClose();
    };

    return (
        <Box>
            <Flex align="center" mt={10}>
                <FormLabel as={'p'} fontWeight="semibold" mr={5}>Sub Categories</FormLabel>
                <Button onClick={onOpen} colorScheme="teal" variant="outline" size="xs">Add</Button>
            </Flex>
            <UnorderedList ml={5}>
                {subCategories && subCategories.map((item, i) => (
                    <ListItem key={i}>
                        {item}
                    </ListItem>
                ))}
            </UnorderedList>

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add New Sub Category</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <form onSubmit={handleSubmit(subCategorySubmit)} id="sub-cat-form">

                            <FormControl id="subcategoryName" mb="15px" isInvalid={errors.subcategoryName}>
                                <Input placeholder="Sub Category Name" ref={register({ required: true })}
                                    variant="flushed" borderColor="gray.300" size="sm"
                                    name="subcategoryName" type="text" />
                                <FormErrorMessage mb="20px">{errors.subcategoryName && "Sub category field is required"}</FormErrorMessage>
                            </FormControl>
                            <Button colorScheme='blue' mr={3} onClick={onClose} variant='outline' size="sm">
                                Close
                            </Button>
                            <Button colorScheme="teal" variant="outline" size="sm" type="submit">Add</Button>
                        </form>


                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default SubCategories;