import React from 'react';
import { useHistory } from 'react-router-dom'
import {
    Breadcrumb as Brcmb, BreadcrumbItem, BreadcrumbLink,
} from '@chakra-ui/react'
import {ChevronRightIcon} from '@chakra-ui/icons'


function Breadcrumb({ pathname = '', nameOrTitle = null, link = null, create = false, mb = 0 }) {
    const history = useHistory();

    return (
        <Brcmb mb={mb} fontWeight="medium" separator={<ChevronRightIcon color="gray.700" />}> 
            <BreadcrumbItem> 
                <BreadcrumbLink 
                    _hover={{ 
                        textDecoration: link ? 'underline' : 'none', 
                        cursor: link ? 'pointer' : 'auto' 
                    }} 
                    onClick={() => link && history.push(link)}> 
                    {pathname} 
                    { !link && !nameOrTitle && <ChevronRightIcon color="gray.700" />} 
                </BreadcrumbLink> 
            </BreadcrumbItem> 

            { 
                create && 
                <BreadcrumbItem> 
                    <BreadcrumbLink 
                        _hover={{ textDecoration: 'none', cursor: 'auto' }}> 
                        Create 
                    </BreadcrumbLink> 
                </BreadcrumbItem> 
            } 

            { 
                nameOrTitle && 
                <BreadcrumbItem> 
                    <BreadcrumbLink 
                        _hover={{ textDecoration: 'none', cursor: 'auto' }}> 
                        { nameOrTitle } 
                    </BreadcrumbLink> 
                </BreadcrumbItem> 
            } 
        </Brcmb> 
    ) 
} 

export default Breadcrumb; 