import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom'; 
import {useForm} from 'react-hook-form'; 
import {
    Box, Container, Input, Button, useToast, useDisclosure, 
    Modal, ModalOverlay, ModalContent, ModalHeader, 
    ModalBody, ModalCloseButton, Text, 
    FormControl, FormLabel, FormErrorMessage, 
} from '@chakra-ui/react'
import axios from 'axios'
import {config} from '../../config'
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';


function TagDetails(props) { 
    const {id: _id} = props.location.state; 
    const baseUrl = `${config.baseUrl}/api/v1`; 
    const token = localStorage.getItem('token'); 
    const toast = useToast(); 
    const history = useHistory(); 
    const { isOpen, onOpen, onClose } = useDisclosure() 

    const { register, handleSubmit, errors, reset } = useForm({ 
        mode: 'onTouched', 
        shouldFocusError: false 
    }) 

    const [tagName, setTagName] = useState(''); 
    // const [tagType, setTagType] = useState('BLOG'); 
    const [isTagTypeNotSelected, setIsTagTypeNotSelected] = useState(false) 
    const [softConfirmDelete, setSoftConfirmDelete] = useState(false) 

    useEffect(() => { 
        axios({ 
            url: `/tags/${_id}`, 
            method: 'get', 
            baseURL: baseUrl, 
            headers: {'Authorization': `Bearer ${token}`}, 
        }) 
        .then(res => { 
            reset(res?.data?.data); 
            let type = res?.data?.data?.tagType; 
            // type = type ? type : 'BLOG'; 
            // setTagType(type); 
            setTagName(res?.data?.data?.name)
        }) 
        .catch(err => console.log(err)) 
    }, []) 

    const confirmDelete = () => { 
        axios({ 
            url: `/tags/${_id}`, 
            method: 'delete', 
            baseURL: baseUrl, 
            headers: {'Authorization': `Bearer ${token}`}, 
        }) 
        .then((res) => { 
            toast({ 
                title: "Tag Deleted Successfully.", 
                description: "You have just deleted the tag", 
                status: "success", 
                duration: 4000, 
                isClosable: true, 
            }) 
            history.push(`/admin/tag`); 
        }) 
        .catch((err) => console.log('Error', err) ) 
    }  

    const submitHandler = (data) => { 
        // if(tagType) { 
            const franchiseeId = localStorage.getItem('franchiseeId'); 

            // data.tagType = tagType; 
            data.adminType = 'SA'; 

            if (franchiseeId !== "null") { 
                data.adminType = "CA"; 
                data.franchiseeId = franchiseeId; 
            } 

            axios({ 
                url: `/tags/${_id}`, 
                method: 'patch', 
                baseURL: baseUrl, 
                headers: {'Authorization': `Bearer ${token}`}, 
                data 
            }) 
            .then((res) => { 
                toast({ 
                    title: "Tag Updated Successfully.", 
                    description: "You have just updated the tag", 
                    status: "success", 
                    duration: 4000, 
                    isClosable: true, 
                }) 
                history.push(`/admin/tag`); 
            }) 
            .catch((err) => console.log('Error', err) ) 
        // } 
        // else {
        //     setIsTagTypeNotSelected(true); 
        // }
    }

    return ( 
        <> 
            <Container maxWidth={1100} centerContent my={6}> 
                <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg"> 
                <Breadcrumb pathname="Tag" nameOrTitle={tagName} link={`/admin/tag`} mb={5} /> 

                    <form onSubmit={handleSubmit(submitHandler)}> 
                        <FormControl mb="15px" isInvalid={errors.name}>
                            <FormLabel m={0}> Name </FormLabel> 
                            <Input
                                ref={register({ required: 'Tag name is required' })}
                                variant="flushed" borderColor="gray.300" size="sm"
                                name="name" type="text" />
                            <FormErrorMessage mb="20px"> 
                                {errors?.name?.message} 
                            </FormErrorMessage> 
                        </FormControl> 

                        {/* <FormControl mb="15px" isInvalid={errors.tagType}> 
                            <Box mt={4} display="flex"> 
                                <Text mr={4}>Select a tag type :</Text> 
                                <RadioGroup 
                                    onChange={(v) => { 
                                        setTagType(v); 
                                        setIsTagTypeNotSelected(false)
                                    }} 
                                    value={tagType}> 
                                    <Stack direction="row"> 
                                        <Radio value="BLOG"> 
                                            Blog Type 
                                        </Radio> 
                                        <Radio value="PRODUCT"> 
                                            Product Type 
                                        </Radio> 
                                    </Stack> 
                                </RadioGroup> 
                            </Box> 
                            { 
                                isTagTypeNotSelected && 
                                <chakra.span color='#E53E3E' mb="20px" fontSize="0.875rem" mt={2}> 
                                    Tag type is required 
                                </chakra.span> 
                            } 
                        </FormControl>  */}
                        <Button 
                            colorScheme="red" variant="outline" size="sm" mr={3} 
                            onClick={() => { 
                                setSoftConfirmDelete(!softConfirmDelete) 
                                onOpen() 
                            }}> 
                            Delete 
                        </Button> 
                        <Button 
                            type="submit" colorScheme="teal" 
                            variant="outline" size="sm" rounded={4} 
                            // onClick={() => { 
                            //     if(!tagType) {
                            //         setIsTagTypeNotSelected(true);
                            //     }
                            // }}
                            >
                            Save 
                        </Button> 
                    </form> 
                </Box> 
                
                {
                    <Modal isOpen={isOpen} onClose={onClose} isCentered> 
                        <ModalOverlay /> 
                        <ModalContent m={5}> 
                            <ModalHeader>Delete Tag</ModalHeader> 
                            <ModalCloseButton /> 
                            <ModalBody> 
                                <Text> 
                                    Are you sure, you want to delete? 
                                </Text> 
                                <Box my={3}>
                                    <Button 
                                        size="sm" mr={3} colorScheme="teal" variant="outline" 
                                        onClick={() => { 
                                            setSoftConfirmDelete(!softConfirmDelete) 
                                            onClose() 
                                        }}> 
                                        No 
                                    </Button> 
                                    <Button 
                                        size="sm" colorScheme="red" variant="outline"
                                        onClick={() => { 
                                            confirmDelete() 
                                            setSoftConfirmDelete(!softConfirmDelete) 
                                            onClose() 
                                        }}> 
                                        Confirm
                                    </Button> 
                                </Box> 
                            </ModalBody> 
                        </ModalContent> 
                    </Modal> 
                } 
            </Container> 
        </> 
    ) 
} 

export default TagDetails; 
















// ---------------------------------------------------------------
// ---------------------------------------------------------------
// ---------------------------------------------------------------
// ---------------------------------------------------------------


// import React, {useState, useEffect} from 'react';
// import {useHistory} from 'react-router-dom'
// import {
//     Box, Container, Input, Button, useToast, useDisclosure, 
//     Modal, ModalOverlay, ModalContent, ModalHeader, 
//     ModalBody, ModalCloseButton, Radio, RadioGroup, Stack, Text
// } from '@chakra-ui/react'
// import axios from 'axios'
// import {config} from '../../config'
// import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';

// function TagDetails(props) { 
//     const {id: _id} = props.location.state; 
//     const baseUrl = `${config.baseUrl}/api/v1`; 
//     const token = localStorage.getItem('token'); 
//     const toast = useToast(); 
//     const history = useHistory(); 
//     const { isOpen, onOpen, onClose } = useDisclosure() 

//     const [tagName, setTagName] = useState('') 
//     const [tagType, setTagType] = useState('BLOG'); 
//     const [isTagTypeNotSelected, setIsTagTypeNotSelected] = useState(false) 
//     const [softConfirmDelete, setSoftConfirmDelete] = useState(false) 

//     // const franchiseeId = localStorage.getItem('franchiseeId'); 
//     console.log({isTagTypeNotSelected});

//     useEffect(() => { 
//         axios({ 
//             url: `/tags/${_id}`, 
//             method: 'get', 
//             baseURL: baseUrl, 
//             headers: {'Authorization': `Bearer ${token}`}, 
//         }) 
//         .then(res => { 
//             setTagName(res?.data?.data?.name); 
//             let type = res?.data?.data?.tagType; 
//             // type = type ? type : 'BLOG'; 
//             setTagType(type); 
//         }) 
//         .catch(err => console.log(err)) 
//     }, []) 

//     const confirmDelete = () => { 
//         axios({ 
//             url: `/tags/${_id}`, 
//             method: 'delete', 
//             baseURL: baseUrl, 
//             headers: {'Authorization': `Bearer ${token}`}, 
//         }) 
//         .then((res) => { 
//             toast({ 
//                 title: "Tag Deleted Successfully.", 
//                 description: "You have just deleted the tag", 
//                 status: "success", 
//                 duration: 4000, 
//                 isClosable: true, 
//             }) 
//             history.push(`/admin/tag`); 
//         }) 
//         .catch((err) => console.log('Error', err) ) 
//     }  

//     const submitHandler = () => { 
//         if(tagType) {
//             let data = { 
//                 name: tagName, 
//                 tagType: tagType
//             } 
    
//             tagName ? ( 
//                 axios({ 
//                     url: `/tags/${_id}`, 
//                     method: 'patch', 
//                     baseURL: baseUrl, 
//                     headers: {'Authorization': `Bearer ${token}`}, 
//                     data 
//                 }) 
//                 .then((res) => { 
//                     toast({ 
//                         title: "Tag Updated Successfully.", 
//                         description: "You have just updated the tag", 
//                         status: "success", 
//                         duration: 4000, 
//                         isClosable: true, 
//                     }) 
//                     history.push(`/admin/tag`); 
//                 }) 
//                 .catch((err) => console.log('Error', err) ) 
//             ) 
//             : ( 
//                 toast({ 
//                     title: "Error Occured.",
//                     description: "Unable to create empty tag!",
//                     status: "error",
//                     duration: 2500,
//                     isClosable: true,
//                 }) 
//             ) 
//         } 
//         else { 
//             setIsTagTypeNotSelected(true); 
//             toast({ 
//                 title: "Error Occured.",
//                 description: "Unable to create empty tag!",
//                 status: "error",
//                 duration: 2500,
//                 isClosable: true,
//             }) 
//         } 
//     } 


//     return ( 
//         <Container maxWidth={1100} centerContent my={6}> 
//             <Box w="100%" bg="white" rounded="lg" boxShadow="lg" p={4}> 
//                 <Breadcrumb pathname="Tag" nameOrTitle={tagName} link={`/admin/tag`} mb={5} /> 
//                 <Input 
//                     type="text" mb={3} display="block" value={tagName}
//                     onChange={ (e) => setTagName(e.target.value) } 
//                     placeholder="Enter a tag name" autoFocus 
//                     variant="flushed" borderColor="#a0a3a8" 
//                 /> 
//                 <Box my={4} display="flex"> 
//                     <Text mr={4}>Select a tag type :</Text> 
//                     <RadioGroup onChange={setTagType} value={tagType}> 
//                         <Stack direction="row"> 
//                             <Radio size="md" value="BLOG">Blog Type</Radio> 
//                             <Radio value="PRODUCT">Product Type</Radio> 
//                         </Stack> 
//                     </RadioGroup> 
//                 </Box> 
                
//                 <Button 
//                     onClick={submitHandler} 
//                     colorScheme="teal" variant="outline" size="sm"> 
//                     Save 
//                 </Button> 
//                 <Button 
//                     colorScheme="red" variant="outline" size="sm" ml={3}
//                     onClick={() => { 
//                         setSoftConfirmDelete(!softConfirmDelete) 
//                         onOpen() 
//                     }}> 
//                     Delete 
//                 </Button> 
//             </Box> 
            
            
//             {
//                 <Modal isOpen={isOpen} onClose={onClose} isCentered> 
//                     <ModalOverlay /> 
//                     <ModalContent m={5}> 
//                         <ModalHeader>Delete Tag</ModalHeader> 
//                         <ModalCloseButton /> 
//                         <ModalBody> 
//                             <Text> 
//                                 Are you sure, you want to delete? 
//                             </Text> 
//                             <Box my={3}>
//                                 <Button 
//                                     size="sm" colorScheme="red" variant="outline"
//                                     onClick={() => { 
//                                         confirmDelete() 
//                                         setSoftConfirmDelete(!softConfirmDelete) 
//                                         onClose() 
//                                     }}> 
//                                     Yes
//                                 </Button>
//                                 <Button 
//                                     size="sm" ml={3} colorScheme="teal" variant="outline" 
//                                     onClick={() => { 
//                                         setSoftConfirmDelete(!softConfirmDelete) 
//                                         onClose() 
//                                     }}> 
//                                     No 
//                                 </Button> 
//                             </Box> 
//                         </ModalBody> 
//                     </ModalContent> 
//                 </Modal> 
//             }
//         </Container> 
//     ) 
// } 

// export default TagDetails;






