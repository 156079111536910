import React from 'react';
import { Box, Flex, Text, Icon } from '@chakra-ui/react'; 
import { FaUserAlt } from "react-icons/fa"; 

function CommentList(props) { 
    const {comments, totalComment, getDate} = props 
    return ( 
        <Box> 
            { 
                comments.length > 0 &&  
                <Box mt="30px">  
                    <Text as="h3" mb="25px" fontSize="1.3em" fontWeight="600"> 
                        { 
                            totalComment > 1 
                                ? `Reviews (${totalComment}) :` 
                                : `Review (${totalComment}) :` 
                        } 
                    </Text> 
                    <Box> 
                        { 
                            comments.map((comment, i) => { 
                                // const date = props.mapDate(comment.commentAt) 
                                return ( 
                                    <Box 
                                        key={i} mt="30px" p={6} rounded="md" boxShadow="sm" 
                                        borderWidth="1px" borderColor="gray.200"> 
                                        <Flex> 
                                            <Icon as={FaUserAlt} color="gray.700" /> 
                                            <Text 
                                                fontWeight="700" mb="2px" ml="10px" 
                                                fontSize="14px" color="gray.700" > 
                                                {comment?.authorName} 
                                            </Text> 
                                        </Flex>
                                        <Text fontSize="13px" color="gray.400" fontStyle="italic" mb="15px">
                                           {/* Created at: {`${date.day}-${date.month}-${date.year}`} */}
                                           Created at: {getDate(comment?.commentAt)}
                                        </Text> 
                                        <Text fontSize="14px" mt="5px" color="gray.600"> 
                                            {comment?.message} 
                                        </Text>  
                                    </Box> 
                                ) 
                            }) 
                        } 
                    </Box> 
                </Box> 
            } 
        </Box> 
    )
}

export default CommentList; 