import {Container, Box, Skeleton, HStack } from '@chakra-ui/react'
import React from 'react'

function BlogListSkeleton() {
    return (
        <Box mt="80px">
            <Container maxWidth={1160}>
                {
                    Array(5).fill().map((_, i) => {
                        return (
                            <Box mb="20px" p="30px" shadow="lg" border="1px solid #EEE" rounded={4} key={i}>
                                <Skeleton h="20px" w="80%" mb={4} />
                                <HStack mb={2} spacing={4}>
                                    <Skeleton h="10px" w="100px" />
                                    <Skeleton h="10px" w="100px" />
                                    <Skeleton h="10px" w="100px" />
                                </HStack>
                                <Skeleton>
                                    <Box minHeight="280px"></Box>
                                </Skeleton>
                                <Box mt={5}>
                                    <Skeleton h="8px" w="100%" mb="20px" />
                                    <Skeleton h="8px" w="90%" mb="20px" />
                                    <Skeleton h="8px" w="100%" mb="20px" />
                                    <Skeleton h="8px" w="90%" mb="20px" />
                                    <Skeleton h="8px" w="95%" mb="20px" />
                                    <Skeleton h="8px" w="80%" />
                                </Box>
                            </Box>
                        )
                    })
                }
            </Container>
        </Box>
    )
}

export default BlogListSkeleton
