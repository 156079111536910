import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import {
    Container, Box, Text, Input, SimpleGrid,
    FormControl, FormLabel, Textarea,
    Radio, RadioGroup, Stack, Checkbox,
    Button, useToast
} from '@chakra-ui/react'

import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Select from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import axios from 'axios'
import { config } from '../../config';
import ReactSelectOptionMapper from '../../service/ReactSelect';
import { useDropzone } from 'react-dropzone';
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import { editorFontList } from '../../service/utils/FontList';


const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};



function Product() {
    const { control, register, handleSubmit, watch } = useForm();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [business, setBusiness] = useState([]);
    const [category, setCategory] = useState([]);
    const [tags, setTag] = useState([]);

    const watchPriceType = watch("priceType", 'FIXED');

    const baseUrl = config.baseUrl;
    let history = useHistory();
    const toast = useToast();

    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    alt="product thumbs"
                    src={file.preview}
                    style={img}
                />
            </div>
        </div>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);


    useEffect(() => {
        const franchiseeId = localStorage.getItem('franchiseeId');
        // Business
        axios({
            url: '/api/v1/business',
            baseURL: baseUrl,
            method: 'get',
            params: {
                franchiseeId: franchiseeId
            }
        })
            .then(response => {
                let options = ReactSelectOptionMapper(response.data.data);
                setBusiness(options);
            })
            .catch(error => {
                console.log(error);
            })

        // Category
        axios({
            url: '/api/v1/categories',
            baseURL: baseUrl,
            method: 'get'
        })
            .then((response) => {
                let options = ReactSelectOptionMapper(response.data.data);
                setCategory(options);
            })
            .catch((error) => {
                console.log(error);
            })

        // Tags 
        let url = franchiseeId !== 'null'
            ? `/api/v1/tags?adminType=CA&franchiseeId=${franchiseeId}&tagType=PRODUCT`
            : '/api/v1/tags?adminType=SA&tagType=PRODUCT';

        axios({
            url: url,
            method: 'get',
            baseURL: baseUrl,
        })
            .then((res) => {
                let tags = ReactSelectOptionMapper(res.data.data);
                setTag(tags);
            })
            .catch((err) => {
                console.log('Error', err);
            })
    }, [])



    const franchiseeId = localStorage.getItem('franchiseeId');
    const submitForm = (data) => {
        const token = localStorage.getItem('token');
        let details = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        data.details = details;

        data.tags = data.tags?.map(type => type.value);
        data.categories = data.categories.map(category => category.value)
        data.franchiseeId = franchiseeId
        data.businessId = data.business.value

        axios({
            url: '/api/v1/products',
            method: 'post',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` },
            data: data
        })
            .then((response) => {

                let { _id } = response.data.data;
                const formData = new FormData();

                if (files.length) {
                    files.forEach(file => {
                        formData.append('images', file);
                    })

                    axios({
                        url: `/api/v1/products/${_id}`,
                        method: 'post',
                        baseURL: baseUrl,
                        headers: { 'Authorization': `Bearer ${token}` },
                        data: formData
                    })
                    .then(response => {
                        
                    })
                    .catch(error => {
                        console.log(error)
                    });
                }
                toast({
                    title: "Product created.",
                    description: "We've created a product for you.",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                })

                history.push(`/admin/product`);

            })
            .catch((error) => console.log(error))
    }

    return (
        <Container maxWidth={1100} centerContent py={6}>
            <Box p={4} w="100%" bg="white" rounded="lg" id="Product" boxShadow="lg">
                <Breadcrumb pathname="Product" create={true} mb={5} link={`/admin/product`} />

                <form onSubmit={handleSubmit(submitForm)}>
                    <FormControl id="name" mb={4}>
                        <FormLabel>Name</FormLabel>
                        <Input
                            borderColor="gray.300"
                            type="text"
                            ref={register}
                            name="name"
                            variant="flushed" size="sm"
                        />
                    </FormControl>

                    <FormControl id="summary" mb={4}>
                        <FormLabel>Short Description</FormLabel>
                        <Textarea
                            borderColor="gray.300"
                            ref={register}
                            name="summary"
                            variant="flushed" size="sm"
                        />
                    </FormControl>

                    <FormControl mb={4}>
                        <FormLabel>Upload product images</FormLabel>
                        <Box
                            // p={4} 
                            lineHeight="60px"
                            border="1px dashed #BDBDBD">
                            <Box {...getRootProps({ className: 'dropzone' })}>
                                <Input {...getInputProps()} type="file" />
                                <Text
                                    textAlign="center"
                                    p="10px" cursor="pointer"
                                    color="gray.500">
                                    Click to select files
                                </Text>
                            </Box>
                            {
                                thumbs.length > 0 &&
                                <Box style={thumbsContainer}>
                                    {thumbs}
                                </Box>
                            }
                        </Box>
                    </FormControl>

                    <FormControl mb={4}>
                        {/* <FormLabel m={0}>Description</FormLabel> */}
                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(editorState) => setEditorState(editorState)}
                            toolbar={{
                                fontFamily: {
                                    options: editorFontList,
                                    className: undefined,
                                    component: undefined,
                                    dropdownClassName: undefined,
                                },
                                options: ['inline', 'blockType', 'fontSize', 'fontFamily',
                                    'list', 'textAlign', 'colorPicker', 'link', 'embedded',
                                    'emoji', 'remove', 'history'],
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true }
                            }}
                        />
                    </FormControl>

                    <SimpleGrid minChildWidth="120px" spacing="40px">
                        <Box mb={4}>
                            <FormControl id="categories">
                                <FormLabel>Category</FormLabel>
                                <Controller
                                    control={control}
                                    name="categories"
                                    as={Select}
                                    isMulti
                                    options={category}
                                    defaultValue={[]}
                                />
                            </FormControl>
                        </Box>

                        <Box mb={4}>
                            <FormControl id="business">
                                <FormLabel>Business</FormLabel>
                                <Controller
                                    control={control}
                                    name="business"
                                    as={Select}
                                    options={business}
                                    defaultValue={[]}
                                />
                            </FormControl>
                        </Box>

                    </SimpleGrid>

                    <Box mb={4}>
                        <FormControl id="tags">
                            <FormLabel>Tags</FormLabel>
                            <Controller
                                control={control}
                                name="tags"
                                as={Select}
                                isMulti
                                options={tags}
                                defaultValue={[]}
                            />
                        </FormControl>
                    </Box>

                    <Box mb={4}>
                        <FormControl as="fieldset">
                            <RadioGroup defaultValue="FIXED" mb="20px" id="priceType">
                                <Stack spacing={5} direction="row">
                                    <Text>Price Type</Text>
                                    <Radio name="priceType" ref={register} borderColor="gray.300" value="FIXED">
                                        Fixed
                                    </Radio>
                                    <Radio name="priceType" ref={register} borderColor="gray.300" value="RANGED">
                                        Range
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                        {
                            watchPriceType === 'RANGED'
                                ?
                                <SimpleGrid minChildWidth="18rem" spacing={{ base: "5px", sm: "10px", md: "15px", lg: "30px", xl: "30px" }}>
                                    <Box>
                                        <Stack direction={["column", "row"]}>
                                            <Text w={{ base: "100%", sm: "100%", md: "40%", lg: "30%", xl: "30%" }}>Min price</Text>
                                            <FormControl w={{ base: "100%", sm: "100%", md: "60%", lg: "70%", xl: "70%" }} id="minPrice">
                                                <Input type="text" ref={register} name="minPrice" variant="flushed" size="sm" borderColor="gray.300" />
                                            </FormControl>
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Stack direction={["column", "row"]}>
                                            <Text w={{ base: "100%", sm: "100%", md: "40%", lg: "30%", xl: "30%" }}>Max price</Text>
                                            <FormControl w={{ base: "100%", sm: "100%", md: "60%", lg: "70%", xl: "70%" }} id="maxPrice">
                                                <Input type="text" ref={register} name="maxPrice" variant="flushed" size="sm" borderColor="gray.300" />
                                            </FormControl>
                                        </Stack>
                                    </Box>
                                </SimpleGrid>
                                : <Box>
                                    <Stack direction={["column", "row"]}>
                                        <Text w={{ base: "100%", sm: "100%", md: "25%", lg: "20%", xl: "20%" }}>Price</Text>
                                        <FormControl w={{ base: "100%", sm: "100%", md: "75%", lg: "80%", xl: "80%" }} id="price">
                                            <Input type="text" ref={register} name="price" variant="flushed" size="sm" borderColor="gray.300" />
                                        </FormControl>
                                    </Stack>
                                </Box>
                        }
                    </Box>

                    <Box>
                        <FormControl as="fieldset">
                            <Stack spacing={10} direction="row">
                                <Checkbox colorScheme="red" defaultIsChecked name="publish" ref={register}>
                                    Publish
                                </Checkbox>
                            </Stack>
                        </FormControl>
                    </Box>

                    <Button type="submit" colorScheme="teal" variant="outline" size="sm" mt={5}>Save</Button>
                </form>

            </Box>
        </Container>
    )
}

export default Product;