import React, { useContext } from 'react'
import { extendTheme, ChakraProvider } from '@chakra-ui/react'
import { ThemeContext } from './context/ThemeContext'
import App from './App'

const theme = {
  styles: {
    global: {
      body: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: '400'
      },
      h1: {
        fontSize: "2em",
        fontWeight: '600' || 'bold',
        margin: '15px 0'
      },
      h2: {
        fontSize: "1.6em",
        fontWeight: '600' || 'bold',
        // margin: '10px 0'
      },
      h3: {
        // fontSize: "1.4em",
        fontWeight: '600' || 'bold',
        // margin: '10px 0'
      },
      h4: {
        fontSize: "1.2em",
        fontWeight: '600' || 'bold',

      },
      h5: {
        fontSize: "1.1em",
        fontWeight: '600' || 'bold',

      },
      h6: {
        fontSize: "1em",
        // fontWeight: 'bold', 

      },
      a: {
        // color: "teal.500",
        _hover: {
          textDecoration: "underline",
        },
      },
      ".rich-editor": {
        h1: {
          fontSize: "2em",
          fontWeight: '600' || 'bold',
          margin: '25px 0'
        },
        h2: {
          fontSize: "1.6em",
          fontWeight: '600' || 'bold',
          margin: '15px 0'
        },
        h3: {
          fontSize: "1.4em",
          fontWeight: '600' || 'bold',
          margin: '10px 0'
        },
        h4: {
          fontSize: "1.2em",
          fontWeight: '600' || 'bold',

        },
        h5: {
          fontSize: "1.1em",
          fontWeight: '600' || 'bold',

        },
        h6: {
          fontSize: "1em",
          // fontWeight: 'bold', 

        },
        a: {
          // color: "teal.500",
          _hover: {
            textDecoration: "underline",
          },
        }
      },
    },
  }
}

function ChakraThemeProvider() {
  const [state] = useContext(ThemeContext);

  theme.styles.global = {
    ".rec-dot_active": {
      "background-color": `${state.color.accent} !important`,
    },
    ".rec-dot": {
      "box-shadow": `0 0 1px 1px ${state.color.primary} !important`,
    }
  }

  theme.styles.global.body = {
    fontFamily: state.fonts.family,
    fontSize: `${state.fonts.size}px`,
    fontWeight: state.fonts.weight,
    color: state.color.primary
  }

  theme.styles.global.input = {
    color: state.color.primary
  }

  theme.styles.global.textArea = {
    color: state.color.primary
  }

  theme.styles.global.select = {
    color: state.color.primary
  }

  theme.styles.global.p = {
    color: state.color.secondary
  }

  theme.styles.global.table = {
    color: state.color.secondary
  }

  theme.styles.global.label = {
    color: state.color.secondary
  }

  theme.styles.global.small = {
    color: state.color.secondary
  }

  theme.styles.global.button = {
    color: state.color.accent
  }

  theme.styles.global.h6 = {
    color: state.color.accent
  }

  theme.styles.global.h1 = {
    fontSize: "2em",
    fontWeight: '600' || 'bold',
    margin: '25px 0'
  }
  theme.styles.global.h2 = {
    fontSize: "1.6em",
    fontWeight: '600' || 'bold',
    margin: '15px 0'
  }
  theme.styles.global.h3 = {
    fontSize: "1.4em",
    fontWeight: '600' || 'bold',
    margin: '10px 0'
  }
  theme.styles.global.h4 = {
    fontSize: "1.2em",
    fontWeight: '600' || 'bold',
  }
  theme.styles.global.h5 = {
    fontSize: "1.1em",
    fontWeight: '600' || 'bold',
  }



  // theme.styles.global.svg = {
  //   color: `${state.color.accent} !important`
  // }

  const chakraTheme = extendTheme(theme);
  return (
    <ChakraProvider theme={chakraTheme}>
      <App />
    </ChakraProvider>
  )
}

export default ChakraThemeProvider