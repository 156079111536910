import { useState } from 'react';
import {
    Box, Stack, Text, useDisclosure, Flex, SimpleGrid
} from '@chakra-ui/react';
import SuborderModal from './SuborderModal';

const SuborderList = ({ subOrders, fetchData, orderObj }) => {

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [productsPrice, setProductsPrice] = useState([]);
    const [newOrder, setNewOrder] = useState({});

    const totalPrice = (arr) => {
        return arr.reduce(((acc, val) => (acc + (val.price * val.qty))), 0);
    };

    return (
        <>
            <Stack spacing='15px'>
                {
                    subOrders && subOrders.map((order) => ((

                        <Box key={order._id} cursor='pointer' bg="white" p={5} rounded={5} boxShadow="md" onClick={() => {
                            onOpen();
                            setNewOrder(order);
                            setProductsPrice(() => totalPrice(order.products))
                        }
                        }>
                            <SimpleGrid columns={4} spacing={10}>
                                <Box>
                                    <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={2}>Order Id</Text>
                                    <Text color="gray.500" fontSize=".9rem" ml='auto' lineHeight="1rem">{order.orderNo}</Text>
                                </Box>
                                <Box>
                                    <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={2}>Status</Text>
                                    <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{order.status}</Text>
                                </Box>
                                <Box>
                                    <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={2}>Sub Total</Text>
                                    <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">${
                                        parseFloat(order.subTotal).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
                                </Box>
                                <Box>
                                    <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={2}>Address</Text>
                                    <Box color="gray.500" fontSize=".9rem" lineHeight="1rem">
                                        <Text>
                                            {order.shippingAddress.firstName}
                                            {" "}
                                            {order.shippingAddress.lastName}
                                        </Text>
                                        <Text>
                                            {order.shippingAddress.street},
                                            {" "}
                                            {order.shippingAddress.house},
                                            {" "}
                                            {order.shippingAddress.city},
                                            {" "}
                                            {order.shippingAddress.state},
                                            {" "}
                                            {order.shippingAddress.zip}
                                        </Text>
                                    </Box>
                                </Box>
                            </SimpleGrid>
                        </Box>
                    )))

                }
            </Stack>
            <SuborderModal fetchData={fetchData} orderObj={orderObj} order={newOrder} onClose={onClose} isOpen={isOpen} productsPrice={productsPrice} />
        </>
    )
}

export default SuborderList;