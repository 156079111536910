import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
    useDisclosure,
    Container, Box, FormControl, FormLabel, FormHelperText, Input, Button, FormErrorMessage, useToast
} from '@chakra-ui/react'
import axios from 'axios';
import { config } from '../../config'
import { useHistory,  } from 'react-router-dom'
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb'
import DeleteComponent from '../../components/delete/DeleteComponent';


function BTDetails(props) {
    const { id: _id } = props.location.state;


    const token = localStorage.getItem('token');
    const { register, handleSubmit, errors, setValue } = useForm({
        mode: 'onTouched',
        shouldFocusError: false
    });

    const baseUrl = config.baseUrl;

    const history = useHistory();
    const toast = useToast()



    const [name, setName] = useState('')

    const { isOpen, onOpen, onClose } = useDisclosure();


    useEffect(() => {
        axios({
            url: `/api/v1/businessTypes/${_id}`,
            method: 'get',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` },
        })
            .then(res => {
                setValue('name', res.data.data.name)
                setName(res.data.data.name)
            })
            .catch(err => console.log(err))
    }, [])


    const formSubmit = (data) => {
        axios({
            url: `/api/v1/businessTypes/${_id}`,
            method: 'patch',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` },
            data: data,
        })
            .then((response) => {
                toast({
                    title: "Business Type Updated Successfully.",
                    description: "You have updated a business type.",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                })
                history.push('/admin/business-type');
            })
            .catch((error) => {
                console.log(error)
            })
    };

    const getDeleteApi = () => {
        const franchiseeId = localStorage.getItem('franchiseeId');
        let url = `/businessTypes/${_id}?adminType=SA`;
        if (franchiseeId !== 'null') {
            url = `/businessTypes/${_id}?adminType=CA&franchiseeId=${franchiseeId}`;
        }
        return url;
    };

    return (
        <Container maxWidth={1100} centerContent py={6}>
            <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg">
                <Breadcrumb pathname="Business Type" nameOrTitle={name} link={`/admin/business-type`} mb={5} />
                <form onSubmit={handleSubmit(formSubmit)}>
                    <FormControl id="businessType" mb="20px" isInvalid={errors.name}>
                        <FormLabel>Type</FormLabel>
                        <Input borderColor="gray.300" ref={register({ required: "Name field is required" })} name="name" type="text" />
                        {
                            errors.name
                                ? <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                                : <FormHelperText>Example: Retails, LifeStyle Tourism</FormHelperText>
                        }
                    </FormControl>
                    <Button type="submit" colorScheme="teal" variant="outline" size="sm">Save</Button>
                    <Button ml={2} colorScheme="red" variant="outline" size="sm" mr={3} onClick={onOpen}>
                        Delete
                    </Button>

                </form>
            </Box>
            <DeleteComponent
                isOpen={isOpen} onClose={onClose}
                getDeleteApi={getDeleteApi}
                routeOnDelete='/admin/business-type'
                showBackendMsg={false}
                toastSuccessMsgTitle="Business Type deleted successfully"
                toastErrMsgTitle="Cannot delete the Business Type" />
        </Container>
    )
}

export default BTDetails; 