import React, { useState } from 'react'; 
import { 
    Box, Container, Flex, Text 
} from '@chakra-ui/react'; 
import Body from './Rightside/Body'; 
import MenuOptions from './Leftside/MenuOptions'; 

function Menus() { 
   
    const [ treeData, setTreeData ] = useState([{ rootId: '10101010', items: {
        '10101010': { 
            id: "10101010",
            hasChildren: false,
            isExpanded: false,
            isChildrenLoading: false,
            data: { name: "All Menus", id: "10101010" }, 
            children: [] 
        } 
    }}]); 

    return ( 
        <Box height="calc(100vh - 50px)" overflowY="auto">
            <Container maxWidth={1100} centerContent py={6}> 
              
                <Box p={6} w="100%" bg="white" rounded="8px" shadow="lg"> 
                    <Flex w="100%" wrap={["wrap", "wrap", "nowrap", "nowrap"]}> 
                        <Box w={["100%", "100%", "30%", "30%"]} mr={["0", "0", "15px", "15px"]}> 
                            <Text fontSize="18px" fontWeight={700}> Add Menu Items </Text> 
                            <MenuOptions treeData={treeData} setTreeData={setTreeData} /> 
                        </Box> 
                        
                        <Box w={["100%", "100%", "70%", "70%"]}> 
                            <Box w="100%" height="auto"> 
                                <Body 
                                    treeData={treeData} 
                                    setTreeData={setTreeData} 
                                /> 
                            </Box> 
                        </Box> 
                    </Flex> 
                </Box> 
            </Container> 
        </Box>
    ) 
} 

export default Menus; 