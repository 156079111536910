const ReactSelectOptionMapper = (options, label = 'name') => {

    let newOptions = [];

    if (options.length) {
        newOptions = options.map(option => {
            let item = {};
            item.value = option._id;
            item.label = option[label];
            return item;
        })
    }

    return newOptions;
}

export default ReactSelectOptionMapper;