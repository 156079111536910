import { useState, useEffect } from 'react';
import {  Box, Center, Text, Image, Flex, SimpleGrid } from "@chakra-ui/react"

const Feature = (props) => {
    const [empty, setEmpty] = useState(false)

    useEffect(() => {

        const allImage = props?.feature?.every(item => item.image === '');
        setEmpty(allImage);

    }, [props.feature])

    return (
        <section id="feature" className="lan-container section">
            <SimpleGrid minChildWidth='220px' spacing='20px' px="15px">
            {
                props?.feature?.map((item, index) => {
                    return (
                        <Box p={4} borderRadius={10} border="1px" borderColor="gray.200" key={index}>
                            {item.image ? <Center mb="20px">
                                <Image src={item.image} width="60px" height="60px" objectFit="cover" alt={item.title} />
                            </Center> : <Box mb={empty ? '0px' : '20px'} h={empty ? '0px' : '60px'} w={empty ? '0px' : '60px'}></Box>}

                            <Box>
                                <Text as="h2" textAlign="center" fontSize="18px" fontWeight="bold" mb="10px">
                                    {item.title}
                                </Text>
                                <Text textAlign="center">{item.text}</Text>
                            </Box>

                        </Box>
                    );
                })
            }
            </SimpleGrid>
        </section>

    );
}

export default Feature;

