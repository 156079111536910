
const mapDate = (date) => {
    let day = new Date(date).getDate() 
    day = day > 9 ? day : `0${day}` 

    let month = new Date(date).getMonth() + 1 // january gives 0 
    month = month > 9 ? month : `0${month}` 
    
    let year = new Date(date).getFullYear() 
    year = year > 9 ? year : `0${year}` 

    return { day, month, year } 
} 

export default mapDate; 
