import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
    Container, Box, FormControl, FormLabel, Text,
    Textarea, Input, Button,
    FormErrorMessage, SimpleGrid, useToast,
    Checkbox, Flex,
    RadioGroup, Radio, Stack, HStack,
    useDisclosure
} from '@chakra-ui/react';
import axios from 'axios';
// import { useDropzone } from 'react-dropzone';
import { config } from '../../config';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactSelectOptionMapper from '../../service/ReactSelect';
import AddTag from '../Blog/helpers/AddTag';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import dataURItoBlob from '../Blog/helpers/dataURLtoBlob';
import ProductImageModal from './ProductImageModal';
import { nanoid } from 'nanoid';
import { editorFontList } from '../../service/utils/FontList';
import { generateSlug } from '../../service/utils/helper';
import PreviewHTML from '../Blog/helpers/PreviewHTML';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

function Business() {
    const role = localStorage.getItem('role');

    const { control, register, handleSubmit, getValues, setValue, errors, watch } = useForm({
        mode: 'onSubmit',
        shouldFocusError: true,
        shouldUnregister: true,
    });

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [tags, setTag] = useState([]);
    const [previewImage, setPreviewImage] = useState('');
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);


    // old img upload
    // const { getRootProps, getInputProps } = useDropzone({
    //     accept: 'image/*',
    //     onDrop: acceptedFiles => {
    //         setFiles(acceptedFiles.map(file => Object.assign(file, {
    //             preview: URL.createObjectURL(file)
    //         })));
    //     }
    // });

    const { isOpen: isOpenTag, onOpen: onOpenTag, onClose: onCloseTag } = useDisclosure();

    const [businessEditorState, setBusinessEditorState] = useState(() => EditorState.createWithContent(
        ContentState.createFromBlockArray(
            convertFromHTML('')
        )
    ))
    const [businessEditorRawHTML, setBusinessEditorRawHTML] = useState(draftToHtml(convertToRaw(businessEditorState.getCurrentContent())));
    const { isOpen: isBusinessEditorOpen, onOpen: onBusinessEditorOpen, onClose: onBusinessEditorClose } = useDisclosure();

    const [productEditorState, setProductEditorState] = useState(() => EditorState.createWithContent(
        ContentState.createFromBlockArray(
            convertFromHTML('')
        )
    ))
    const [productEditorRawHTML, setProductEditorRawHTML] = useState(draftToHtml(convertToRaw(productEditorState.getCurrentContent())));
    const { isOpen: isProductEditorOpen, onOpen: onProductEditorOpen, onClose: onProductEditorClose } = useDisclosure();

    const watchPriceType = watch("priceType", 'FIXED');
    const watchSelectedCategory = watch("parentcategory", 'parentcategory');
    const franchiseeId = localStorage.getItem('franchiseeId');
    const token = localStorage.getItem('token');

    const history = useHistory();
    const toast = useToast()
    const baseUrl = config.baseUrl;

    const [image, setImage] = useState(previewImage);
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();
    const [isCropped, setIsCropped] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const thumbs = files.map((file, i) => (
        <div style={thumb} key={i}>
            <div style={thumbInner}>
                <img
                    alt="product thumbs"
                    src={URL.createObjectURL(file)}
                    style={img}
                />
            </div>
        </div>
    ));

    const getTagList = useCallback(() => {
        const franchiseeId = localStorage.getItem('franchiseeId');
        let url = franchiseeId !== 'null'
            ? `/api/v1/tags?adminType=CA&franchiseeId=${franchiseeId}&sort=name`
            : '/api/v1/tags?adminType=SA&sort=name';

        axios({
            url: url,
            method: 'GET',
            baseURL: baseUrl,
        })
            .then(({ data }) => {
                let tagList = ReactSelectOptionMapper(data.data);
                setTag(tagList);

            })
            .catch(err => console.log(err))

    }, []);

    const getCategoryList = useCallback(() => {

        let url = franchiseeId !== 'null'
            ? `/api/v1/categories/parent?franchiseeId=${franchiseeId}&sort=name`
            : '/api/v1/categories/parent?sort=name';

        axios({
            url: url,
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` },
            method: 'GET'
        })
            .then((response) => {
                let options = ReactSelectOptionMapper(response.data.data);
                setCategory(options);
            })
            .catch((error) => {
                console.log(error);
            })

    }, []);

    useEffect(() => {
        setSelectedCategory(watchSelectedCategory?.value)
    }, [watchSelectedCategory]);

    const getSubCategoryList = () => {

        if (selectedCategory) {
            let url = franchiseeId !== 'null'
                ? `/api/v1/categories?parentId=${selectedCategory}&sort=name`
                : '/api/v1/categories?parent?sort=name';

            axios({
                url: url,
                baseURL: baseUrl,
                headers: { 'Authorization': `Bearer ${token}` },
                method: 'GET'
            })
                .then((response) => {
                    let options = ReactSelectOptionMapper(response.data.data);
                    setSubCategory(options);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };

    useEffect(() => {

        getCategoryList();
        getTagList();

    }, []);

    useEffect(() => {
        getSubCategoryList();
    }, [selectedCategory]);


    function imageHandler(e) {
        // To preview before upload --- Converting to base 64
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setPreviewImage(reader.result);
                setImage(reader.result);
            }
        };
        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0])
        };
    };

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas().toDataURL());
            setIsCropped(true);
            toast({
                title: "Image Is Cropped",
                description: 'Image Successfully Cropped',
                status: "success",
                duration: 2000,
                isClosable: true
            })
        };
    };

    useEffect(() => {
        setPreviewImage(cropData);
    }, [cropData]);


    const imageJSX = (

        <FormControl mb="15px" mt="15px">
            <Box>
                <FormLabel
                    w='100%'
                    h='100%'
                    lineHeight="60px"
                    border="1px dashed #BDBDBD"
                    htmlFor='image' textAlign="center" cursor="pointer"
                    // color="gray.500"
                    p='10px'
                >
                    Click To Upload Image
                </FormLabel>
                <Input
                    type="file"
                    name="image"
                    ref={register}
                    id='image'
                    accept="image/*"
                    onChange={(e) => imageHandler(e)}
                    style={{ display: "none" }} />
            </Box>

            <Flex justify="space-between" align='flex-start'>
                <Flex flexDirection='column' w="48%">

                    <Box>
                        {image ? <Cropper
                            style={{ height: 350, width: "100%" }}
                            zoomTo={0.0}
                            initialAspectRatio={1}
                            src={image}
                            aspectRatio={1}
                            viewMode={1}
                            preview=".img-preview-business"
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            cropBoxResizable={false}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                            guides={true}
                        />
                            : null}
                    </Box>
                    <Box alignSelf='flex-end' mt='5px' mr='25px'>
                        {image ? <Button
                            _hover={{ outline: 'none' }}
                            _focus={{ outline: 'none' }}
                            _active={{ bg: 'transparent', color: 'teal' }}
                            colorScheme="teal" variant="outline" size="sm"
                            onClick={getCropData}>
                            Crop Image
                        </Button> : null}
                    </Box>
                </Flex>

                {image ? <Box w="48%" display="inline-block" p='10px' boxSizing="border-box">
                    <Text >Preview</Text>
                    <Box className="img-preview-business" overflow="hidden" w='300px' height='300px'>

                    </Box>

                </Box> : null}
            </Flex>
        </FormControl>
    )


    const formSubmit = async (data) => {

        setLoading(true);
        let tagsData = data.tags?.map(type => type.value)
        let categoriesData = data.categories ? data.categories.map(category => category.value) : [];

        let price = "";
        let minPrice = "";
        let maxPrice = ""
        if (data.priceType === "FIXED") {
            price = data.price
        } else {
            minPrice = data.minPrice;
            maxPrice = data.maxPrice;
        };


        const businessData = new FormData();
        businessData.append('name', data.name);
        businessData.append('email', data.email);
        businessData.append('location', data.location);
        businessData.append('isFeatured', data.isFeatured);
        businessData.append('active', data.active);

        const franchiseeId = localStorage.getItem('franchiseeId');
        businessData.append('franchiseeId', franchiseeId);

        businessData.append('phone', data.phone);

        // Always send cropped image
        if (data.image[0] && isCropped) {
            let blob = dataURItoBlob(previewImage);
            businessData.append('image', blob);
        };

        businessData.append('address', data.address);
        let businessDetails = draftToHtml(convertToRaw(businessEditorState.getCurrentContent()));
        businessData.append('details', businessDetails);

        const productData = new FormData();
        productData.append('name', data.productName);
        productData.append('slug', data.slug);
        productData.append('summary', data.summary);
        productData.append('parentCategory', selectedCategory);

        let details = draftToHtml(convertToRaw(productEditorState.getCurrentContent()));
        productData.append('details', details);

        if (tagsData?.length > 0) {
            productData.append('tags', JSON.stringify(tagsData));
        }
        if (categoriesData.length > 0) {
            productData.append('categories', JSON.stringify(categoriesData));
        }
        productData.append('priceType', data.priceType);
        productData.append('price', price);
        productData.append('minPrice', minPrice);
        productData.append('maxPrice', maxPrice);
        productData.append('franchiseeId', franchiseeId);

        if (files.length) {
            files.forEach(file => {
                productData.append('images', file);
            })
        }

        // if (!data.image[0]) {
        //     setLoading(true);

        //     axios({
        //         url: '/api/v1/business/signup',
        //         method: 'post',
        //         baseURL: baseUrl,
        //         headers: {
        //             'Authorization': `Bearer ${token}`,
        //             'Content-Type': 'multipart/form-data'
        //         },
        //         data: businessData
        //     })
        //         .then(response => {
        //             const { business } = response.data;
        //             productData.append('businessId', business._id);

        //             axios({
        //                 url: '/api/v1/products',
        //                 method: 'post',
        //                 baseURL: baseUrl,
        //                 headers: {
        //                     'Authorization': `Bearer ${token}`,
        //                     'Content-Type': 'multipart/form-data'
        //                 },
        //                 data: productData

        //             }).then(() => {
        //                 toast({
        //                     title: "Product created Successfully.",
        //                     description: "You have just created a product",
        //                     status: "success",
        //                     duration: 4000,
        //                     isClosable: true
        //                 });
        //                 history.push(`/admin/business`);
        //             }).catch(error => console.log(error.message))

        //             toast({
        //                 title: "Business Created Successfully.",
        //                 description: "You have just created a business",
        //                 status: "success",
        //                 duration: 4000,
        //                 isClosable: true
        //             });

        //         })
        //         .catch(error => {
        //             toast({
        //                 title: "Business Is Not Created.",
        //                 description: error.message,
        //                 status: "error",
        //                 duration: 4000,
        //                 isClosable: true
        //             })

        //         }).finally(() => {
        //             setLoading(false);
        //         })

        // }

        // else if (data.image[0] && isCropped) {
        //     setLoading(true);

        //     axios({
        //         url: '/api/v1/business/signup',
        //         method: 'post',
        //         baseURL: baseUrl,
        //         headers: {
        //             'Authorization': `Bearer ${token}`,
        //             'Content-Type': 'multipart/form-data'
        //         },
        //         data: businessData
        //     })
        //         .then(response => {
        //             const { business } = response.data;
        //             productData.append('businessId', business._id);

        //             axios({
        //                 url: '/api/v1/products',
        //                 method: 'post',
        //                 baseURL: baseUrl,
        //                 headers: {
        //                     'Authorization': `Bearer ${token}`,
        //                     'Content-Type': 'multipart/form-data'
        //                 },
        //                 data: productData

        //             }).then(() => {
        //                 toast({
        //                     title: "Product created Successfully.",
        //                     description: "You have just created a product",
        //                     status: "success",
        //                     duration: 4000,
        //                     isClosable: true
        //                 })
        //                 history.push(`/admin/business`);
        //             }).catch(error => console.log(error.message))

        //             toast({
        //                 title: "Business Created Successfully.",
        //                 description: "You have just created a business",
        //                 status: "success",
        //                 duration: 4000,
        //                 isClosable: true
        //             })

        //         })
        //         .catch(error => {
        //             toast({
        //                 title: "Business Is Not Created.",
        //                 description: error.message,
        //                 status: "error",
        //                 duration: 4000,
        //                 isClosable: true
        //             })
        //         }).finally(() => {
        //             setLoading(false);
        //         })
        // };

        // if (data.image[0] && !isCropped) {
        //     toast({
        //         title: "Business Image Must Be Cropped",
        //         description: 'Crop Your Business Image',
        //         status: "error",
        //         duration: 4000,
        //         isClosable: true
        //     })
        // }

        // const productDataUpdate = null
        // const businessDataUpdate = null

        axios({
            url: '/api/v1/business/signup',
            method: 'post',
            baseURL: baseUrl,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
            data: businessData
        }).then(businessRes => {
            const { business } = businessRes.data;
            productData.append('businessId', business._id);
            const businessDataUpdate = businessRes

            axios({
                url: '/api/v1/products',
                method: 'post',
                baseURL: baseUrl,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: productData

            }).then(response => {
                const productDataUpdate = response
                let apiArray = [businessDataUpdate, productDataUpdate]

                if (businessDataUpdate.status == 201 && productDataUpdate.status == 201) {
                    toast({
                        title: "Business & Product Updated Successfully.",
                        description: "You have just updated the business and product",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });

                    if(productDataUpdate.status == 201){
                        setLoading(false);
                        history.push(`/admin/business`);
                    }
                }
                else if (businessDataUpdate.status == 201 && productDataUpdate.status != 201) {
                    toast({
                        title: "Business Updated Successfully.",
                        description: "You have just updated the business",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                    toast({
                        title: "Product Updated Failed.",
                        description: "Failed",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                    setLoading(false);
                }
                else if (businessDataUpdate.status != 201 && productDataUpdate.status == 201) {
                    toast({
                        title: "Business Updated Failed.",
                        description: "Failed",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                    toast({
                        title: "Product Updated Success.",
                        description: "You have just updated the business",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                    setLoading(false);
                }
                else {
                    toast({
                        title: "Business & Product Updated Failed.",
                        description: "Failed the business and product update",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                    setLoading(false);
                }
                // }).catch((err) => {
                //     toast({
                //         title: "Business & Product Updated Failed.",
                //         description: err.message,
                //         status: "error",
                //         duration: 4000,
                //         isClosable: true
                //     })
                // }).finally(() => {
                //     setLoading(false);
                // })
            })
        }).catch((err) => {
            toast({
                title: "Business & Product Updated Failed.",
                description: err.message,
                status: "error",
                duration: 4000,
                isClosable: true
            })
            setLoading(false);
        })


    };

    const onProductNameChange = async (e) => {
        let getSlug = await generateSlug(e.target.value)

        setValue('slug', getSlug)
    }

    return (
        <>
            <Container maxWidth={1100} centerContent py={6}>
                <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg" mb={4}>
                    <Breadcrumb pathname="Business" create={true} link={`/admin/business`} />
                </Box>
                <Box w="100%" rounded="lg">

                    <form onSubmit={handleSubmit(formSubmit)}>

                        {/* Business section */}
                        <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg" mb={6}>
                            <Text as={"h1"} mb={6}>Business Information</Text>
                            {/* Business Admin setup */}

                            <Box>
                                <FormControl id="name" mb="20px" isInvalid={errors?.name}>
                                    <FormLabel as={'p'} fontWeight="semibold">Name<span style={{ color: 'red' }}>*</span></FormLabel>
                                    <Input
                                        name="name" type="text"
                                        ref={register({ required: 'Name is required' })}
                                        borderColor="gray.300" variant="flushed" size="sm" />
                                    <FormErrorMessage>
                                        {errors?.name?.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </Box>

                            {/* Email & phone */}
                            <SimpleGrid columns={[1, 1, 2, 2]} spacing="20px">
                                <Box>
                                    <FormControl as={'p'} fontWeight="semibold" id="email" mb="20px" isInvalid={errors?.email}>
                                        <FormLabel as={'p'} fontWeight="semibold">Email<span style={{ color: 'red' }}>*</span></FormLabel>
                                        <Input
                                            name="email" type="email" borderColor="gray.300"
                                            ref={register({ required: 'Email is required' })}
                                            variant="flushed" size="sm" />
                                        <FormErrorMessage>
                                            {errors.email?.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                </Box>

                                <Box>
                                    <FormControl id="phone" mb="20px" isInvalid={errors?.phone}>
                                        <FormLabel as={'p'} fontWeight="semibold">Phone</FormLabel>
                                        <Input
                                            borderColor="gray.300" ref={register}
                                            name="phone" type="tel" variant="flushed" size="sm"
                                        />
                                    </FormControl>
                                </Box>

                            </SimpleGrid>

                            {/* Image & business location */}
                            {
                                role === 'CITYADMIN' &&
                                <SimpleGrid columns={[1, 1, 2, 2]} spacing="20px">
                                    <Box> {imageJSX} </Box>
                                    <Box mt='6px'>
                                        <FormControl id="location" mb="20px" isInvalid={errors?.location}>
                                            <FormLabel as={'p'} fontWeight="semibold">Location ( Embeded Google Map )</FormLabel>
                                            <Input
                                                name="location" type="text"
                                                ref={register({ required: false })}
                                                borderColor="gray.300" variant="flushed" size="sm" />
                                            <FormErrorMessage>
                                                {errors?.location?.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                    </Box>

                                </SimpleGrid>
                            }


                            {/* Address */}
                            <Box>
                                <FormControl id="address" mb="20px">
                                    <FormLabel as={'p'} fontWeight="semibold">Address</FormLabel>
                                    <Textarea borderColor="gray.300"
                                        ref={register}
                                        name="address"
                                        type="text"
                                        variant="flushed" size="sm"
                                    />
                                </FormControl>
                            </Box>

                            <Box>
                                <FormControl mb={4}>
                                    {/* <FormLabel m={0}>Description</FormLabel> */}
                                    <Flex m={0} justify="space-between" align='center'>
                                        <FormLabel as={'p'} fontWeight="semibold">Description</FormLabel>
                                        <Button bg='gray.300' size="sm" mb="10px" onClick={onBusinessEditorOpen}>Preview HTML</Button>
                                    </Flex>
                                    <Editor
                                        editorState={businessEditorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName rich-editor"
                                        onEditorStateChange={(editorState) => setBusinessEditorState(editorState)}
                                        toolbar={{
                                            fontFamily: {
                                                options: editorFontList,
                                                className: undefined,
                                                component: undefined,
                                                dropdownClassName: undefined,
                                            },
                                            options: ['inline', 'blockType', 'fontSize', 'fontFamily',
                                                'list', 'textAlign', 'colorPicker', 'link', 'embedded',
                                                'emoji', 'remove', 'history'],
                                            inline: { inDropdown: true },
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                            link: { inDropdown: true },
                                            history: { inDropdown: true }
                                        }}
                                    />
                                </FormControl>
                            </Box>

                            <Flex mb="20px" w='20%' gridGap={2}>

                                <FormControl id='isFeatured'>
                                    <Checkbox
                                        ref={register}
                                        name="isFeatured"
                                        colorScheme='green'>
                                        Feature
                                    </Checkbox>
                                </FormControl>

                                <FormControl id='active'>
                                    <Checkbox
                                        ref={register}
                                        name="active"
                                        colorScheme='green'
                                        defaultChecked={true}
                                    >
                                        Active
                                    </Checkbox>
                                </FormControl>
                            </Flex>
                        </Box>

                        {/* Product section */}

                        <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg" mb={6}>

                            <Text fontSize="20px" fontWeight="700" mb={6}>Product Information</Text>

                            <FormControl id="productName" mb={4} isInvalid={errors?.productName}>
                                <FormLabel as={'p'} fontWeight="semibold">Name<span style={{ color: 'red' }}>*</span></FormLabel>
                                <Input
                                    borderColor="gray.300"
                                    type="text"
                                    ref={register({ required: "Product name is required" })}
                                    name="productName"
                                    variant="flushed" size="sm"
                                    onChange={e => onProductNameChange(e)}
                                />
                                <FormErrorMessage>
                                    {errors?.productName?.message}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl id="slug" mb="15px" isInvalid={errors.slug}>
                                <FormLabel as={'p'} fontWeight="semibold" m={0}>Product slug</FormLabel>
                                <FormLabel m={0}></FormLabel>
                                <Input ref={register} variant="flushed" size="sm" type="text" name="slug" />
                                <FormErrorMessage mb="20px">{errors.slug && errors.slug.message}</FormErrorMessage>
                            </FormControl>

                            <FormControl id="summary" mb={4} isInvalid={errors?.summary}>
                                <FormLabel as={'p'} fontWeight="semibold">Short Description<span style={{ color: 'red' }}>*</span></FormLabel>
                                <Textarea
                                    borderColor="gray.300"
                                    ref={register({ required: "Description is required" })}
                                    name="summary"
                                    variant="flushed" size="sm"
                                />
                                <FormErrorMessage>
                                    {errors?.summary?.message}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl mb={4}>
                                <FormLabel as={'p'} fontWeight="semibold">Upload Product Images</FormLabel>
                                <Box
                                >

                                    {/* old img upload */}
                                    {/* <Box {...getRootProps({ className: 'dropzone' })}>
                                        <Input {...getInputProps()} type="file" />
                                        <Text
                                            textAlign="center"
                                            p="10px" cursor="pointer"
                                            color="gray.500">
                                            Click to select files
                                        </Text>
                                        {
                                        thumbs.length > 0 &&
                                        <Box style={thumbsContainer}>
                                            {thumbs}
                                        </Box>
                                    }

                                    </Box> */}

                                    <Button
                                        my={2}
                                        color="gray.600"
                                        size="sm"
                                        _hover={{ outline: 'none' }}
                                        _focus={{ outline: 'none' }}
                                        _active={{ bg: 'transparent', color: 'teal' }} onClick={onOpen}>Add Product Image</Button>
                                    <ProductImageModal key={nanoid()} files={files} setFiles={setFiles} onClose={onClose} isOpen={isOpen} isEdit={false} />

                                    {thumbs.length > 0 && <Box w='100%' h='1px' bg='gray.300'></Box>}


                                    {
                                        thumbs.length > 0 &&
                                        <Box my={2} style={thumbsContainer}>
                                            {thumbs}
                                        </Box>
                                    }
                                </Box>
                            </FormControl>

                            <FormControl mb={4}>
                                {/* <FormLabel m={0}>Description</FormLabel> */}
                                <Flex m={0} justify="space-between" align='center'>
                                    <FormLabel as={'p'} fontWeight="semibold">Description</FormLabel>
                                    <Button bg='gray.300' size="sm" mb="10px" onClick={onProductEditorOpen}>Preview HTML</Button>
                                </Flex>
                                <Editor
                                    editorState={productEditorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName rich-editor"
                                    onEditorStateChange={(editorState) => setProductEditorState(editorState)}
                                    toolbar={{
                                        fontFamily: {
                                            options: editorFontList,
                                            className: undefined,
                                            component: undefined,
                                            dropdownClassName: undefined,
                                        },
                                        options: ['inline', 'blockType', 'fontSize', 'fontFamily',
                                            'list', 'textAlign', 'colorPicker', 'link', 'embedded',
                                            'emoji', 'remove', 'history'],
                                        inline: { inDropdown: true },
                                        list: { inDropdown: true },
                                        textAlign: { inDropdown: true },
                                        link: { inDropdown: true },
                                        history: { inDropdown: true }
                                    }}
                                />
                            </FormControl>

                            <SimpleGrid minChildWidth="120px" spacing="40px">
                                <Box mb={4}>
                                    <FormControl id="parentcategory" isInvalid={errors?.parentcategory}>
                                        <FormLabel as={'p'} fontWeight="semibold">Category<span style={{ color: 'red' }}>*</span></FormLabel>
                                        <Controller
                                            control={control}
                                            name="parentcategory"
                                            as={Select}
                                            // isMulti
                                            options={category}
                                            defaultValue={null}
                                            rules={{ required: true }}
                                        />
                                        <FormErrorMessage>
                                            {/* {errors?.parentcategory?.message} */}
                                            {errors.parentcategory && <Text>Category is required.</Text>}
                                        </FormErrorMessage>
                                    </FormControl>
                                </Box>
                                <Box mb={4}>
                                    <FormControl id="categories" isInvalid={errors?.categories}>
                                        <FormLabel as={'p'} fontWeight="semibold">Sub Category</FormLabel>
                                        <Controller
                                            control={control}
                                            name="categories"
                                            as={Select}
                                            isMulti
                                            options={subCategory}
                                            defaultValue={null}
                                            rules={{ required: false }}
                                        />
                                        <FormErrorMessage>
                                            {/* {errors?.categories?.message} */}
                                            {errors.parentcategory && <Text>Sub-category is required.</Text>}
                                        </FormErrorMessage>
                                    </FormControl>
                                </Box>
                                <Box mb={4}>
                                    <FormControl id="tags" isInvalid={errors?.tags}>
                                        <HStack mb={1}>
                                            <FormLabel as={'p'} fontWeight="semibold">Tags</FormLabel>
                                            <Button
                                                px='8px'
                                                lineHeight="16px"
                                                fontWeight='300'
                                                rounded='sm'
                                                fontSize='10px'
                                                _focus={{
                                                    outline: 'none'
                                                }}
                                                colorScheme="gray"
                                                size="xs"
                                                onClick={onOpenTag}>Add New</Button>
                                        </HStack>
                                        <Controller
                                            control={control}
                                            name="tags"
                                            as={Select}
                                            isMulti
                                            options={tags}
                                            defaultValue={null}
                                            rules={{ required: false }}
                                        />
                                        <FormErrorMessage>
                                            {errors?.tags?.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                </Box>
                            </SimpleGrid>

                            <Box mb={4}>
                                <FormControl as="fieldset">
                                    <RadioGroup defaultValue="FIXED" mb="20px" id="priceType">
                                        <Stack spacing={5} direction="row">
                                            <FormLabel as={'p'} fontWeight="semibold">Price Type</FormLabel>
                                            <Radio name="priceType" ref={register} borderColor="gray.300" value="FIXED">
                                                Fixed
                                            </Radio>
                                            <Radio name="priceType" ref={register} borderColor="gray.300" value="RANGED">
                                                Range
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>
                                {
                                    watchPriceType === 'RANGED'
                                        ?
                                        <SimpleGrid minChildWidth="18rem" spacing={{ base: "5px", sm: "10px", md: "15px", lg: "30px", xl: "30px" }}>
                                            <Box>
                                                <Stack direction={["column", "row"]}>
                                                    <Text as={'p'} fontWeight="semibold" w={{ base: "100%", sm: "100%", md: "40%", lg: "30%", xl: "30%" }}>Min price</Text>
                                                    <FormControl w={{ base: "100%", sm: "100%", md: "60%", lg: "70%", xl: "70%" }} id="minPrice" isInvalid={errors?.minPrice}>
                                                        <Input type="text" ref={register({ required: "Min price is required" })} name="minPrice" variant="flushed" size="sm" borderColor="gray.300" />
                                                        <FormErrorMessage>
                                                            {errors?.minPrice?.message}
                                                        </FormErrorMessage>
                                                    </FormControl>
                                                </Stack>
                                            </Box>
                                            <Box>
                                                <Stack direction={["column", "row"]}>
                                                    <Text as={'p'} fontWeight="semibold" w={{ base: "100%", sm: "100%", md: "40%", lg: "30%", xl: "30%" }}>Max price</Text>
                                                    <FormControl w={{ base: "100%", sm: "100%", md: "60%", lg: "70%", xl: "70%" }} id="maxPrice" isInvalid={errors?.maxPrice}>
                                                        <Input type="text" ref={register({ required: "Max price is required" })} name="maxPrice" variant="flushed" size="sm" borderColor="gray.300" />
                                                        <FormErrorMessage>
                                                            {errors?.maxPrice?.message}
                                                        </FormErrorMessage>
                                                    </FormControl>
                                                </Stack>
                                            </Box>
                                        </SimpleGrid>
                                        : <Box>
                                            <Stack direction={["column", "row"]}>
                                                <Text as={'p'} fontWeight="semibold" w={{ base: "100%", sm: "100%", md: "25%", lg: "20%", xl: "20%" }}>Price<span style={{ color: 'red' }}>*</span></Text>
                                                <FormControl w={{ base: "100%", sm: "100%", md: "75%", lg: "80%", xl: "80%" }} id="price" isInvalid={errors?.price}>
                                                    <Input type="text" ref={register({ required: "Price is required" })} name="price" variant="flushed" size="sm" borderColor="gray.300" />
                                                    <FormErrorMessage>
                                                        {errors?.price?.message}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            </Stack>
                                        </Box>
                                }
                            </Box>

                            {/* <Box mb={4}>
                                <FormControl as="fieldset">
                                    <Stack spacing={10} direction="row">
                                        <Checkbox colorScheme="green" defaultIsChecked name="publish" ref={register}>
                                            Publish
                                        </Checkbox>
                                    </Stack>
                                </FormControl>
                            </Box> */}
                        </Box>

                        <Button
                            isLoading={loading}
                            loadingText='Saving...'
                            type="submit" colorScheme="teal"
                            variant="outline" size="sm"
                        >
                            Save
                        </Button>

                    </form>
                </Box>
            </Container>

            {/* modal for tag add */}
            <AddTag
                getTagList={getTagList}
                getValues={getValues}
                setValue={setValue}
                isOpen={isOpenTag} onClose={onCloseTag}
            />

            <PreviewHTML
                isOpen={isBusinessEditorOpen}
                onClose={onBusinessEditorClose}
                editorState={businessEditorState} setEditorState={setBusinessEditorState}
                rawHTML={businessEditorRawHTML} setRawHTML={setBusinessEditorRawHTML}
            />
            <PreviewHTML
                isOpen={isProductEditorOpen}
                onClose={onProductEditorClose}
                editorState={productEditorState} setEditorState={setProductEditorState}
                rawHTML={productEditorRawHTML} setRawHTML={setProductEditorRawHTML}
            />
        </>
    )
}

export default Business; 