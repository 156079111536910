import React from 'react';
import { Box, Button, Select, Flex } from '@chakra-ui/react';
import DatePicker from "react-datepicker";
import { getMonth, getYear } from 'date-fns';

const DatePickerComponent = ({ startDate, setStartDate }) => {
    const range = (start, end) => {
        return new Array(end - start).fill().map((d, i) => i + start);
    };
    const years = range(1990, getYear(new Date()) + 1, 1);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    return (
        <Box borderBottom ='1px solid silver' p={0}>
            <DatePicker
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                }) => (
                    <Flex justifyContent='center' m={1}>
                        <Button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                        </Button>
                        <Select
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                        >
                            {years.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </Select>

                        <Select
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                            }
                        >
                            {months.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </Select>

                        <Button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                        </Button>
                    </Flex>
                )}
                popperPlacement="top-end"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
            /></Box>
    )
}

export default DatePickerComponent