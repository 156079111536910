import axios from 'axios';
import React, {useState, useRef, useCallback} from 'react'
import { config } from '../../config';
import { Container, Box, Link,
 Flex, Spacer, Button
} from '@chakra-ui/react';
import { Link as RouteLink, useRouteMatch } from 'react-router-dom';
import ProductTable from './ProductTable'
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';

function ProductList() {
    const franchiseeId = localStorage.getItem('franchiseeId');
    const baseUrl = config.baseUrl; 
    const [products, setProduct] = useState([]); 
    let { url } = useRouteMatch(); 

    // const[menus, setMenu] = useState([]); 


    const [pageCount, setPageCount] = useState(0) 
    const fetchIdRef = useRef(0) 

    let columns = [ 
        { 
            Header: 'Name', 
            accessor: 'name'
        }, 
        { 
            Header: 'Price Type', 
            accessor: 'priceType'
        }, 
        { 
            Header: 'Price', 
            accessor: 'price'
        }, 
        { 
            Header: 'Excerpt', 
            accessor: 'summary'
        }, 
        { 
            Header: 'Published', 
            accessor: 'publish'
        } 
    ] 

    let sortOn = ['Name', 'Price'] 

    const fetchData = useCallback(({ pageSize, pageIndex, globalFilter, skipPageResetRef }) => { 
        const fetchId = ++fetchIdRef.current 
        const fields = 'name,priceType,price,summary,publish,slug,details';
        if(globalFilter) { 
          // Filtering on name field
            axios({
                url: `/api/v1/products?franchiseeId=${franchiseeId}&search=${globalFilter}`,
                method: 'get',
                baseURL: baseUrl
            }) 
            .then((res) => { 
                console.clear() 
                const {data: serverData, result: totalLength} = res.data 
                if (fetchId === fetchIdRef.current) { 
                    if(serverData.length >= 1) { 
                    skipPageResetRef.current = true 
                    setProduct(serverData) 
                    setPageCount(Math.ceil(totalLength/pageSize)) 
                    } 
                } 
            }) 
            .catch(err => console.log(err, 'Data fetch error')) 
        } 
        else { 
          // Pagination 
            axios({ 
                url: `/api/v1/products?franchiseeId=${franchiseeId}&page=${(pageIndex)+1}&limit=${pageSize}&fields=${fields}`, 
                method: 'get', 
                baseURL: baseUrl 
            }) 
            .then((res) => { 
                const {data: serverData, result: totalLength} = res.data 
                if (fetchId === fetchIdRef.current) { 
                    skipPageResetRef.current = true 
                    setProduct(serverData) 
                    setPageCount(Math.ceil(totalLength/pageSize)) 
                } 
            }) 
            .catch(err => console.log(err, 'Data fetch error')) 
        } 
    }, []) 


    return (
        <Container maxWidth={1100} centerContent my={6}> 
            <Box w="100%" bg="white" rounded="lg" boxShadow="lg"> 
                <Flex> 
                    <Box pt={4} px={4}> 
                        <Breadcrumb pathname="Product" /> 
                    </Box> 
                    <Spacer /> 
                    <Box py={3} px={4}> 
                        <Button colorScheme="teal" variant="outline" size="sm">
                            <Link color="blue.500" lineHeight="32px" as={RouteLink} to={`${url}/create`}> 
                                Create new 
                            </Link> 
                        </Button> 
                    </Box> 
                </Flex> 




                <ProductTable 
                    data={products} 
                    columns={columns} 
                    pageCount={pageCount} 
                    fetchData={fetchData} 
                    sortOn={sortOn} 
                    defaultPageSize={10} 
                    tableHeightInPage='58vh' 
                    selectNoOfRows={[5, 10, 20, 30, 50, 100]} 
                /> 
            </Box>
        </Container>    
    )
}

export default ProductList
