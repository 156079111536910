import React, { useState, useRef, useCallback } from 'react'
import { Box, Container, Flex, Spacer, Link, Button } from '@chakra-ui/react'
import axios from 'axios';
import { config } from '../../config'
import { Link as RouteLink, useRouteMatch } from 'react-router-dom'
import BusinessTable from './BusinessTable'
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb'


function BusinessList() {
    const franchiseeId = localStorage.getItem('franchiseeId');
    const [business, setBusiness] = useState([]);

    const baseUrl = config.baseUrl;
    let { url } = useRouteMatch();

    const [pageCount, setPageCount] = useState(0)
    const fetchIdRef = useRef(0)

    let columns = [
        {
            Header: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Email',
            accessor: 'email'
        },
        {
            Header: 'Phone',
            accessor: 'phone'
        },
        {
            Header: 'Featured',
            accessor: 'isFeatured',
        },
        {
            Header: 'Active',
            accessor: 'active'
        }
    ];

    let sortOn = ['Name', 'Email', 'Franchisee'];

    const onFetchData = useCallback(({ pageSize, pageIndex, globalFilter, skipPageResetRef, sortBy }) => {
        const fetchId = ++fetchIdRef.current;
        const fields = 'phone,email,name,active,isFeatured,franchiseeId,location';
        let getUrl = franchiseeId !== 'null'
            ? `/api/v1/business?franchiseeId=${franchiseeId}&`
            : '/api/v1/business?';

        let getSearchUrl = franchiseeId !== 'null'
            ? `/api/v1/business?franchiseeId=${franchiseeId}&`
            : '/api/v1/business?';


        if (globalFilter) {
            // Filtering on name field 
            axios({
                // url: `/api/v1/business?franchiseeId=${franchiseeId}&search=${globalFilter}`,
                url: `${getSearchUrl}search=${globalFilter}`,
                method: 'get',
                baseURL: baseUrl
            })
                .then((res) => {
                    const { data: serverData, result: totalLength } = res.data
                    if (fetchId === fetchIdRef.current) {
                        if (serverData.length >= 1) {
                            skipPageResetRef.current = true
                            setBusiness(serverData)
                            setPageCount(Math.ceil(totalLength / pageSize))
                        }
                    }
                })
                .catch(err => console.log(err, 'Data fetch error'))
        }
        else {
            // Pagination 
            axios({

                // url: `${getUrl}page=${(pageIndex) + 1}&limit=${pageSize}&fields=${fields}&sort=name`,
                url: `${getUrl}&fields=${fields}&sort=name`,
                method: 'get',
                baseURL: baseUrl
            })
                .then((res) => {
                    const { data: serverData, result: totalLength } = res.data

                    if (fetchId === fetchIdRef.current) {
                        const startRow = pageSize * pageIndex;
                        const endRow = startRow + pageSize;
                        if (sortBy.length === 0) {
                            setBusiness(serverData.sort().slice(startRow, endRow));
                        } else {
                            setBusiness(
                                serverData
                                    .sort((a, b) => {
                                        const field = sortBy[0].id;
                                        const desc = sortBy[0].desc;
                                        if (a[field] < b[field]) {
                                            return desc ? -1 : 1;
                                        }
                                        if (a[field] > b[field]) {
                                            return desc ? 1 : -1;
                                        }
                                        return 0;
                                    })
                                    .slice(startRow, endRow)
                            );

                        }
                        setPageCount(Math.ceil(totalLength / pageSize));
                    }

                })
                .catch(err => console.log(err, 'Data fetch error'))
        }
    }, [])


    return (
        <Container maxWidth={1100} centerContent py={6}>
            <Box w="100%" bg="white" rounded="lg" boxShadow="lg">
                <Flex>
                    <Box py={4} px={4}>
                        <Breadcrumb pathname="Business" />
                    </Box>
                    <Spacer />
                    <Box pt={4} px={4}>
                        <Button colorScheme="teal" variant="outline" size="sm">
                            <Link color="blue.500" lineHeight="32px" as={RouteLink} to={`${url}/create`}>Create new</Link>
                        </Button>
                    </Box>
                </Flex>


                <BusinessTable
                    data={business}
                    columns={columns}
                    pageCount={pageCount}
                    onFetchData={onFetchData}
                    sortOn={sortOn}
                    defaultPageSize={10}
                    tableHeightInPage='59vh'
                    selectNoOfRows={[5, 10, 20, 30, 50, 100]}
                />
            </Box>
        </Container>
    )
}

export default BusinessList; 
