import { Box, useRadio } from '@chakra-ui/react'

function ManyChatRadioCard(props) {
    const { getInputProps, getCheckboxProps } = useRadio(props)
  
    const input = getInputProps()
    const checkbox = getCheckboxProps()

    let borderColor = 'teal.600';
    let bgColor = "transparent";
    
    if (input.value === 'FACEBOOKMESSENGER' || input.value === 'TELEGRAM') {
      borderColor = 'blue.500';
      bgColor = "rgba(72, 149, 239, .1)"
    }else if(input.value === 'INSTAGRAM'){
      borderColor = 'pink.500';
      bgColor = "rgba(241, 192, 232, .2)"
    }else {
      borderColor = 'green.500';
      bgColor = "rgba(185, 251, 192, .2)";
    }
  
    return (
      <Box as='label'>
        <input {...input} />
        <Box
          {...checkbox}
          cursor='pointer'
          borderWidth='1px'
          fontSize='14px'
          borderRadius='md'
          _checked={{
            color: borderColor,
            borderColor: borderColor,
            bg:bgColor,
            outline: 'none',
            fontWeight: '400',
            fontSize: '14px'
          }}
          _focus={{
            outline: 'none'
          }}
          px={8}
          py={3}
        >
          {props.children}
        </Box>
      </Box>
    )
}

export default ManyChatRadioCard;