import {
  Badge, Box,
  Button,
  Container,
  Flex, FormControl, FormErrorMessage, FormLabel, Image, Input,
  Text,
  Textarea, useDisclosure, useToast
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { config } from '../../config';

import {
  ContentState, convertFromHTML, convertToRaw, EditorState
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
// import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import DeleteComponent from '../../components/delete/DeleteComponent';
import { editorFontList } from '../../service/utils/FontList';
import { customChunkRenderer, generateSlug } from '../../service/utils/helper';
import PreviewHTML from '../Blog/helpers/PreviewHTML';
import { uploadImage } from '../Blog/helpers/uploadImage';

const PageDetails = (props) => {
  const { id: _id } = useParams();
  const token = localStorage.getItem('token');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(''))
    )
  );
  const [previewImage, setPreviewImage] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [rawHTML, setRawHTML] = useState(
    draftToHtml(convertToRaw(editorState.getCurrentContent()))
  );
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const { register, handleSubmit, control, errors, setValue, getValues } =
    useForm();

  const [fontState, setFontState] = useState({
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'left',
  });

  const baseUrl = config.baseUrl;

  let history = useHistory();
  const toast = useToast();

  useEffect(() => {
    axios({
      url: `/api/v1/pages/${_id}`,
      method: 'get',
      baseURL: baseUrl,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const { data } = res.data;
        setPreviewImage(data.pageHeader?.image);
        // setPageTitle(data.pageTitle);

        setValue('headerTitle', data.pageHeader?.title);
        setValue('pageName', data.pageName);
        setValue('pageTitle', data.pageTitle);
        setValue('meta.description', data.meta?.description);
        setValue('meta.keywords', data.meta?.keywords);
        setValue('pageHeaderStyle', data?.pageHeaderStyle);
        setValue('slug', data?.slug);

        const sampleMarkup = data.pageContent;
        const contentBlock = htmlToDraft(sampleMarkup, customChunkRenderer);

        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function imageHandler(e) {
    // To preview before upload --- Converting to base 64
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreviewImage(reader.result);

        const formData = new FormData();
        formData.append('headerImage', e.target.files[0]);

        axios({
          // url: `/api/v1/franchisees/${_id}`,
          url: `/api/v1/pages/upload/${_id}`,
          method: 'post',
          baseURL: baseUrl,
          data: formData,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(({ data }) => {
            toast({
              title: 'Image updated.',
              description: 'Image updated successfully',
              status: 'success',
              duration: 4000,
              isClosable: true,
            });
          })
          .catch((err) => {
            toast({
              title: 'Image not updated',
              description: err.response.data?.message,
              status: 'error',
              duration: 4000,
              isClosable: true,
            });
          });
      }
    };

    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const getDeleteApi = () => {
    let url = `/pages/${_id}`;
    return url;
  };

  const franchiseeId = localStorage.getItem('franchiseeId');

  const formSubmit = (data) => {
    data.pageType = 'SAP';

    if (franchiseeId !== 'null') {
      data.pageType = 'CAP';
      data.franchiseeId = franchiseeId;
    }

    data.pageHeader = { title: data.headerTitle };
    // data.pageContent = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    data.pageContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
      null,
      null,
      (entity) => {
        // if (entity.type === 'DIV') { // Receive what you passed before, here (like id, or classList, etc.)
        //     return `<div>${entity.data.innerHTML}</div>`;
        // }
        if (entity.type === 'SCRIPT') {
          // Receive what you passed before, here (like id, or keyEvents, etc.)
          return `<script>${entity.data.innerHTML}</script>`;
        }
        if (entity.type === 'INS') {
          // Receive what you passed before, here (like id, or keyEvents, etc.)
          // console.log("ENTRY:::", entity);
          return `${entity.data.outerHTML}`;
        }
        return '';
      }
    );

    axios({
      url: `/api/v1/pages/${_id}`,
      method: 'patch',
      baseURL: baseUrl,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then((res) => {
        toast({
          title: 'Page Updated Successfully.',
          description: 'You have updated the page successfully.',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        // redirect
        history.push(`/admin/page`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadImageCallBack = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    const imageResponse = await uploadImage(formData, token);
    return new Promise((resolve, reject) => {
      if (imageResponse.success) {
        resolve({ data: { link: imageResponse?.url } });
      } else {
        reject('image upload failed');
      }
    });
  };
  const onProductNameChange = async (e) => {
    let getSlug = await generateSlug(e.target.value);

    setValue('slug', getSlug);
  };

  return (
    <Container maxWidth={1100} centerContent py={6}>
      <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg">
        <Breadcrumb
          pathname="Page"
          nameOrTitle={pageTitle}
          link={`/admin/page`}
          mb={5}
        />
        <form onSubmit={handleSubmit(formSubmit)}>
          <FormControl id="pageTitle" mb="15px" isInvalid={errors.pageTitle}>
            <FormLabel as={'p'} fontWeight="semibold" m={0}>
              Title
            </FormLabel>
            <Input
              ref={register({ required: 'Page title is required' })}
              variant="flushed"
              size="sm"
              type="text"
              name="pageTitle"
            />
            <FormErrorMessage mb="20px">
              {errors.pageTitle && errors.pageTitle.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl id="pageName" mb="15px" isInvalid={errors.pageName}>
            <FormLabel as={'p'} fontWeight="semibold" m={0}>
              Page name
            </FormLabel>
            <Input
              ref={register({ required: 'Page name is required' })}
              variant="flushed"
              size="sm"
              type="text"
              name="pageName"
              onChange={(e) => onProductNameChange(e)}
            />
            <FormErrorMessage mb="20px">
              {errors.pageName && errors.pageName.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl id="slug" mb="15px" isInvalid={errors.slug}>
            <FormLabel as={'p'} fontWeight="semibold" m={0}>
              Page slug
            </FormLabel>
            <Input
              ref={register}
              variant="flushed"
              size="sm"
              type="text"
              name="slug"
            />
            <FormErrorMessage mb="20px">
              {errors.slug && errors.slug.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl mb="15px" isInvalid={errors.meta?.description}>
            <FormLabel as={'p'} fontWeight="semibold" m={0}>
              Meta Description
            </FormLabel>
            <Textarea
              ref={register({ required: 'Meta description is required' })}
              variant="flushed"
              size="sm"
              type="text"
              name="meta.description"
            />
            <FormErrorMessage mb="20px">
              {errors.meta?.description?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.meta?.keywords}>
            <FormLabel as={'p'} fontWeight="semibold" m={0}>
              Meta Keywords
            </FormLabel>
            <Textarea
              ref={register({ required: 'Meta keywords are required' })}
              variant="flushed"
              size="sm"
              type="text"
              name="meta.keywords"
            />
            <FormErrorMessage mb="20px">
              {errors.meta?.keywords?.message}
            </FormErrorMessage>
          </FormControl>

          <Text as="h4" mt="50px" mb="20px" fontSize="1.1rem" fontWeight="600">
            Page Header
          </Text>
          <Box w="100%">
            <FormControl id="headerTitle" isInvalid={errors.headerTitle}>
              <FormLabel as={'p'} fontWeight="semibold" m={0}>
                Header Title
              </FormLabel>
              <Input
                ref={register({ required: 'Header title is required' })}
                variant="flushed"
                size="sm"
                type="text"
                name="headerTitle"
              />
              <FormErrorMessage mb="20px">
                {errors.headerTitle && errors.headerTitle.message}
              </FormErrorMessage>
            </FormControl>

            {/* <FormControl>
                            <Box my="30px">
                                <Text as={'h2'} fontSize="1.1rem" fontWeight="700" mb={5}>Font Style</Text>
                                <SimpleGrid minChildWidth="12rem" spacing="15px">

                                    <Box>
                                        <FormLabel as={'p'} fontWeight="semibold" fontSize="16px">Font Family</FormLabel>

                                        <Controller
                                            defaultValue='default'
                                            control={control}
                                            name="pageHeaderStyle.fontFamily"
                                            render={(
                                                { onChange, value, name, ref }
                                            ) => (
                                                <Select
                                                    size="sm"
                                                    value={value}
                                                    onChange={(e) => onChange(e.target.value)}
                                                >
                                                    {
                                                        editorFontList.map(font => (
                                                            <option key={font} value={font}>{font}</option>
                                                        ))
                                                    }
                                                </Select>)}
                                        />
                                    </Box>

                                    <Box>
                                        <FormLabel as={'p'} fontWeight="semibold" fontSize="16px">Font Size</FormLabel>

                                        <Controller
                                            defaultValue={14}
                                            control={control}
                                            name="pageHeaderStyle.fontSize"
                                            render={(
                                                { onChange, value, }
                                            ) => (

                                                <NumberInput
                                                    size="sm"
                                                    max={100} min={5}
                                                    value={value}
                                                    onChange={(value) => onChange(value)}
                                                >
                                                    <NumberInputField />
                                                    <NumberInputStepper>
                                                        <NumberIncrementStepper />
                                                        <NumberDecrementStepper />
                                                    </NumberInputStepper>
                                                </NumberInput>

                                            )}
                                        />
                                    </Box>

                                    <FormControl id="fontWeight">
                                        <FormLabel as={'p'} fontWeight="semibold" fontSize="16px">Font Weight</FormLabel>
                                        <Select
                                            ref={register} name="pageHeaderStyle.fontWeight"
                                            size="sm" defaultValue={fontState?.fontWeight}
                                        >
                                            {
                                                ["200", "300", "400", "500", "600", "700", "800"].map(item => (
                                                    <option key={item} value={item}>{item}</option>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>

                                    <FormControl id="textAlign">
                                        <FormLabel as={'p'} fontWeight="semibold" fontSize="16px">Text align</FormLabel>
                                        <Select
                                            ref={register} name="pageHeaderStyle.textAlign"
                                            size="sm" defaultValue={fontState?.textAlign}
                                        >
                                            {
                                                ["left", "center", "right"].map(item => (
                                                    <option key={item} value={item}>{item}</option>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>

                                </SimpleGrid>
                            </Box>
                        </FormControl> */}

            <FormControl mt={2}>
              <Box>
                <FormLabel
                  fontWeight="semibold"
                  htmlFor="headerImage"
                  textAlign="center"
                  cursor="pointer"
                  w="100%"
                  h="100%"
                  lineHeight="60px"
                  border="1px dashed #BDBDBD"
                  // color="gray.500"
                  p="10px">
                  Click To Upload Header Imaged
                </FormLabel>
                <Input
                  type="file"
                  name="headerImage"
                  ref={register}
                  id="headerImage"
                  accept="image/*"
                  onChange={(e) => imageHandler(e)}
                  style={{ display: 'none' }}
                />
              </Box>
              {previewImage && (
                <Image
                  src={previewImage}
                  alt="Page header image"
                  maxH="200px"
                  mt="10px"
                />
              )}
            </FormControl>
          </Box>
          <Box fontSize="0.8rem" mt="20px">
            Press &nbsp;
            <Badge colorScheme="red">Shift</Badge>
            {` `} + {` `}
            <Badge colorScheme="red">Enter</Badge>, to take new line
          </Box>
          <Box fontSize="0.8rem" mt="20px">
            Go to <Badge colorScheme="yellow">Preview HTML</Badge> to add
            contact form
          </Box>
          <Flex my={4} justify="space-between" align="center">
            <Text as="h4" fontSize="1.1rem" fontWeight="600">
              Page Content
            </Text>
            <Button bg="gray.300" size="sm" onClick={onOpenModal}>
              Preview HTML
            </Button>
          </Flex>
          <Box>
            <FormControl as={'p'} fontWeight="semibold" mb="15px" mt="20px">
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName rich-editor"
                onEditorStateChange={(editorState) =>
                  setEditorState(editorState)
                }
                toolbar={{
                  fontFamily: {
                    options: editorFontList,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                  image: {
                    uploadCallback: uploadImageCallBack,
                    previewImage: true,
                    alt: { present: true, mandatory: false },
                    inputAccept:
                      'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                    defaultSize: {
                      height: '250px',
                      width: 'auto',
                    },
                  },
                }}
              />
            </FormControl>
          </Box>

          <Button
            colorScheme="red"
            variant="outline"
            size="sm"
            mr={3}
            onClick={onOpen}
            rounded={4}>
            Delete
          </Button>
          <Button type="submit" colorScheme="teal" variant="outline" size="sm">
            Save
          </Button>
        </form>
      </Box>

      {/* Delete Page */}
      <DeleteComponent
        headerName="Page"
        isOpen={isOpen}
        onClose={onClose}
        getDeleteApi={getDeleteApi}
        routeOnDelete="/admin/page"
        showBackendMsg={false}
        toastSuccessMsgTitle="Page deleted successfully"
      // toastErrMsgTitle="Cannot delete the shipping method"
      />

      {/* modal for html preview */}
      <PreviewHTML
        isOpen={isOpenModal}
        onClose={onCloseModal}
        editorState={editorState}
        setEditorState={setEditorState}
        rawHTML={rawHTML}
        setRawHTML={setRawHTML}
      />
    </Container>
  );
};

export default PageDetails;
