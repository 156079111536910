import { ChevronRightIcon } from '@chakra-ui/icons';
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';


const CategoryBreadcrumb = ({ clickedLink, setClickedLink }) => {

    const cutArray = (i) => {
        let array = [...clickedLink];
        setClickedLink(array.splice(0, i));
    };


    return (
        <>
            <Breadcrumb fontWeight="medium" mb={2} separator={<ChevronRightIcon color="gray.700" />}>

                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to={`/admin/category`}>Category</BreadcrumbLink>
                </BreadcrumbItem>

                {
                    clickedLink.map((link, i) => (
                        <BreadcrumbItem key={i} onClick={() => cutArray(i + 1)}>
                            <BreadcrumbLink as={Link} to={`/admin/category/${link._id}`}>{link.name}</BreadcrumbLink>
                        </BreadcrumbItem>
                    ))
                }

            </Breadcrumb>
        </>
    )
}

export default CategoryBreadcrumb;