import { Box, Image } from '@chakra-ui/react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { ClampText, ClampTitle } from '../../components/elements/ClampedText'

function Product(props) {
    const theme = JSON.parse(localStorage.getItem('theme'));
    const pReview = JSON.parse(localStorage.getItem('pReview'));
    const product = props.product;
    const history = useHistory();

    const property = {
        imageUrl: "https://bit.ly/2Z4KKcF",
        imageAlt: "Rear view of modern home with pool",
        title: "Modern home in city center in the heart of historic Los Angeles",
        formattedPrice: "$1,900.00",
        reviewCount: 20,
        rating: 4,
    }
    const noImage = 'https://via.placeholder.com/350/000000/FFFFFF/?text=No Product Image'

    return (

        <>
            {
                product ?
                    <Box w="100%" borderWidth="1px" borderRadius="lg" borderColor="gray.300" overflow="hidden" >
                        <Image w="100%"
                            height="9rem"
                            objectFit="cover"
                            src={product.images.links[product.images.defaultImage - 1] ?? noImage}
                            alt={product.name}
                            cursor="pointer"
                            onClick={() => history.push(`/shop/product/${product.slug}`, { product: product })}
                        />

                        <Box p="3">
                            <ClampTitle
                                size=".9rem"
                                weight="700"
                                line={2}
                                color={`${theme.color.primary}`}
                                onClick={() =>
                                    history.push(`/shop/product/${product.slug}`, { product: product })
                                }
                            >
                                {product?.name}
                            </ClampTitle>
                            <ClampText size=".8rem" weight="300" line={3}>{product.summary}</ClampText>
                            <Box mt="6px">
                                <Box
                                    as="span"
                                    fontSize=".9rem"
                                    fontWeight="700"
                                    color={`${theme.color.primary}`}
                                >
                                    {
                                        product.priceType === "RANGED" ?
                                            `$${product.minPrice.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })} - 
                                        $${product.maxPrice.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}` :
                                            `$${product.price.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    : null
            }
        </>
    )
}

export default Product
