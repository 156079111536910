import {useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
    Box, Button, Input, FormControl, FormLabel, Text,
    InputGroup, InputRightElement, FormErrorMessage, SimpleGrid, useToast
} from '@chakra-ui/react' 
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons' 
import axios from 'axios'
import {config} from  "../config"; 

function ResetPassword() { 
    const { baseUrl } = config 
    const toast = useToast() 
    const history = useHistory() 
    const location = useLocation().search; 

    const { register, handleSubmit, errors, watch } = useForm({ 
        mode: 'onTouched', 
        shouldFocusError: true 
    }) 

    const [passwordVisible1, setPasswordVisible1] = useState(false) 
    const [passwordVisible2, setPasswordVisible2] = useState(false) 

    let newPassword = watch('newPassword') 


    const submitHandler = (data) => { 
        const token = new URLSearchParams(location).get('token'); 
        const id = new URLSearchParams(location).get('id'); 

        axios({ 
            url: `/api/v1/users/passwordReset?token=${token}&id=${id}`, 
            method: 'post', 
            baseURL: baseUrl, 
            data: data 
        }) 
        .then(res => { 
            toast({ 
                title: "Password Reset Successful", 
                description: "You have just reset your password successfully. Please login with your new password", 
                status: "success", 
                duration: 4000, 
                isClosable: true, 
            }) 
            setTimeout(() => history.push(`/`), 4000) 
        }) 
        .catch(err => console.log('err')) 
    } 

    return ( 
        <SimpleGrid placeItems="center" bg="#edf2f9" h="100vh"> 
            <Box bg="white" boxShadow="xl" rounded="lg" p={10} 
                borderTop="2px solid teal" 
                w={{base: '100%', sm:"100%", md: "500px", lg:"500px"}}> 
                <Box> 
                    <Text mb={5} fontWeight="600"> 
                        Change your password 
                    </Text> 

                    <form onSubmit={handleSubmit(submitHandler)}> 
                        <FormControl isInvalid={errors.newPassword}> 
                        
                            <FormLabel m={0} mt={4}>New Password</FormLabel> 
                            <InputGroup> 
                                <Input 
                                    type={passwordVisible1 ? 'text' : 'password'} 
                                    name="newPassword" autoComplete="off"
                                    ref={register({ 
                                        required: 'New password is required', 
                                        minLength: { 
                                            value: 8, 
                                            message: 'Minimum 8 characters' 
                                        } 
                                    })} 
                                    variant="flushed" size="sm" /> 
                                <InputRightElement cursor="pointer"> 
                                    { 
                                        passwordVisible1 ? 
                                        <ViewOffIcon 
                                            w={4} h={4} 
                                            onClick={() => setPasswordVisible1(!passwordVisible1)} /> 
                                        : 
                                        <ViewIcon 
                                            w={4} h={4} 
                                            onClick={() => setPasswordVisible1(!passwordVisible1)} /> 
                                    } 
                                </InputRightElement> 
                            </InputGroup> 
                            <FormErrorMessage mb="20px">{errors.newPassword?.message}</FormErrorMessage>
                        </FormControl> 


                        <FormControl isInvalid={errors.confirmNewPassword}> 
                            <FormLabel m={0} mt={4}>Confirm New Password</FormLabel> 
                            
                            <InputGroup> 
                                <Input 
                                    type={passwordVisible2 ? 'text' : 'password'}
                                    name="confirmNewPassword" autoComplete="off"
                                    ref={register({  
                                        required: 'Confirm new password field is required', 
                                        minLength: { 
                                            value: 8, 
                                            message: 'Minimum 8 characters', 
                                        }, 
                                        validate: (value) => value === newPassword || "Password doesn't match"
                                    })} 
                                    variant="flushed" size="sm" /> 
                                <InputRightElement cursor="pointer"> 
                                    { 
                                        passwordVisible2 ? 
                                        <ViewOffIcon 
                                            w={4} h={4} 
                                            onClick={() => setPasswordVisible2(!passwordVisible2)} /> 
                                        : 
                                        <ViewIcon 
                                            w={4} h={4} 
                                            onClick={() => setPasswordVisible2(!passwordVisible2)} /> 
                                    } 
                                </InputRightElement> 
                            </InputGroup> 
                            <FormErrorMessage mb="20px">{errors.confirmNewPassword?.message}</FormErrorMessage>
                        </FormControl> 
                            

                        <Button type="submit" colorScheme="teal" variant="solid" w="100%" mt={4}> 
                            Change Password 
                        </Button> 
                    </form> 
                </Box> 
            </Box> 
        </SimpleGrid> 
    ) 
} 

export default ResetPassword; 