import React from 'react'; 
import { Box } from '@chakra-ui/react'
import BlogListFetchData from './BlogListFetchData';

function BlogList() { 
    return ( 
        <Box> 
            <BlogListFetchData fetchAllUrl="/posts" id='' /> 
        </Box> 
    ) 
} 

export default BlogList; 