import React from 'react'
import { Box, Center } from '@chakra-ui/react'
import WebPagination from '../../../components/pagination/WebPagination';
import ProductList from './ProductList';

function FetchProducts(props) { 
   const { products, setNewPage, limit, total } = props; 
   return (
      <div>
         <Box> 
               <ProductList products={products} /> 
               { 
                  total > limit && 
                  <Center mt="20px"> 
                     <WebPagination 
                           limit={limit} 
                           total={total} 
                           setNewPage={setNewPage} 
                     /> 
                  </Center> 
               } 
         </Box> 
      </div>
   )
}

export default FetchProducts; 
