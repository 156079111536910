import React, { useState, useCallback, useRef } from 'react'
import {Container, Box, Flex, Spacer, 
Button, Link
} from '@chakra-ui/react'
import { Link as RouteLink, useRouteMatch } from 'react-router-dom'
import axios from 'axios'
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import { config } from '../../config';

import CouponTable from './CouponTable';


function CouponList() {
    const role = localStorage.getItem('role'); 
    let { url } = useRouteMatch();

    const[coupons, setCoupon] = useState([]);

    const franchiseeId = localStorage.getItem('franchiseeId'); 
    const baseURL = `${config.baseUrl}/api/v1`;

    const [pageCount, setPageCount] = useState(0) 
    const fetchIdRef = useRef(0) 

    let columns = [ 
        { 
            Header: 'Code', 
            accessor: 'code'
        }, 
        { 
            Header: 'Type', 
            accessor: 'couponType'
        }, 
        { 
            Header: 'Amount', 
            accessor: 'value'
        }, 
        { 
            Header: 'Maximum Use', 
            accessor: 'maxUse'
        }, 
        { 
            Header: 'Total used', 
            accessor: 'usedCount'
        } 
    ] 

    let sortOn = ['Coupon', 'Type', 'Total used', 'Maximum Use', 'Amount'] 

    if(role === 'SUPERADMIN') { 
        for(let i = columns.length; i > 1; i--) { 
            columns[i] = columns[i-1]; 
        } 
        columns[1] = { 
            Header: 'Franchisee', 
            accessor: 'franchiseeId.name' 
        } 
        sortOn.push('Franchisee'); 
    } 

    const fetchData = useCallback(({ pageSize, pageIndex, globalFilter, skipPageResetRef }) => { 
        const fetchId = ++fetchIdRef.current 
        
        const fields = 'code,couponType,value,maxUse,usedCount,franchiseeId';
        const token = localStorage.getItem('token');

        // let getUrl = `/coupons?franchiseeId=${franchiseeId}`;
        let getUrl = franchiseeId !== 'null'
            ? `coupons?franchiseeId=${franchiseeId}&` 
            : 'coupons?'; 
        
        let getSearchUrl = franchiseeId !== 'null' 
            ? `coupons?franchiseeId=${franchiseeId}&` 
            : 'coupons?'; 
        
        if(globalFilter) { 
          // Filtering on name field
            axios({
                url: `${getSearchUrl}search=${globalFilter}`,
                method: 'get', 
                baseURL: baseURL,
                headers: {'Authorization': `Bearer ${token}`}
            }) 
            .then((res) => { 
                const {data: serverData, result: totalLength} = res.data 
                if (fetchId === fetchIdRef.current) { 
                    if(serverData.length >= 1) { 
                        skipPageResetRef.current = true 
                        setCoupon(serverData) 
                        setPageCount(Math.ceil(totalLength/pageSize)) 
                    } 
                } 
            }) 
            .catch(err => console.log(err, 'Data fetch error')) 
        } 
        else { 
          // Pagination 
            axios({ 
                url: `${getUrl}page=${(pageIndex)+1}&limit=${pageSize}&fields=${fields}`, 
                method: 'get', 
                baseURL: baseURL,
                headers: {'Authorization': `Bearer ${token}`} 
            }) 
            .then((res) => { 
                const {data: serverData, result: totalLength} = res.data 
                if (fetchId === fetchIdRef.current) { 
                    skipPageResetRef.current = true 
                    setCoupon(serverData) 
                    setPageCount(Math.ceil(totalLength/pageSize)) 
                } 
            }) 
            .catch(err => console.log(err, 'Data fetch error')) 
        } 
    }, []) 

    return ( 
        <Container maxWidth={1100} centerContent my={6}>
            <Box w="100%" bg="white" rounded="lg" boxShadow="lg">

                <Flex pt={4} px={4}>
                    <Breadcrumb pathname="Coupon" />
                    <Spacer />
                    <Box>
                        <Button colorScheme="teal" variant="outline" size="sm">
                            <Link color="blue.500" lineHeight="32px" as={RouteLink} to={`${url}/create`}>Create new</Link>
                        </Button>
                    </Box>
                </Flex>

                <CouponTable
                    data={coupons} 
                    columns={columns} 
                    pageCount={pageCount} 
                    fetchData={fetchData} 
                    sortOn={sortOn} 
                    defaultPageSize={10} 
                    tableHeightInPage='58vh'  
                    selectNoOfRows={[5, 10, 20, 30, 50, 100]} 
                /> 
            </Box>
        </Container>
    )
}

export default CouponList
