import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  SimpleGrid,
  Box,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormErrorMessage,
  useToast,
  Checkbox,
} from '@chakra-ui/react';
import axios from 'axios';
import { config } from '../../config';
import DomainUtils from '../../service/utils/DomainUtils';

const LandingContactForm = (props) => {
  const theme = JSON.parse(localStorage.getItem('theme'));
  const toast = useToast();
  const { hasSubDomain } = props;
  const { register, handleSubmit, errors, control } = useForm({
    mode: 'onTouched',
    shouldFocusError: false,
  });

  const { title, text, checkboxLabel } = props.content
    ? props.content
    : { title: '', text: '' };
  const [isLoading, setIsLoading] = useState(false);
  const [globalSettings, setGlobalSettings] = useState({});

  useEffect(() => {
    setGlobalSettings(props?.globalSettings);
  }, [props]);

  const submitContact = async (data) => {
    setIsLoading(true);
    const { baseUrl } = config;
    const token = localStorage.getItem('token');

    if (hasSubDomain) {
      const [franchisee] = await DomainUtils();
      data.franchiseeId = franchisee;
    }

    axios({
      url: '/api/v1/contact',
      method: 'post',
      baseURL: baseUrl,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setIsLoading(false);
        toast({
          title: res.data.message,
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        toast({
          title: 'Error Occurred',
          description: err.response.data.message,
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      });
  };

  return (
    <section id="sectionContact" className="lan-container">
      <SimpleGrid
        minChildWidth="18rem"
        spacing="30px"
        px={15}
        py="2rem"
        align="left">
        {props.content?.showForm && (
          <form onSubmit={handleSubmit(submitContact)}>
            <Box>
              <FormControl
                id="firstName"
                mb="10px"
                isInvalid={errors.firstName}>
                <FormLabel as={'p'} fontWeight="semibold">
                  First Name
                </FormLabel>
                <Input
                  type="text"
                  name="firstName"
                  ref={register({ required: 'First name is required' })}
                  borderColor="gray.300"
                />
                <FormErrorMessage>
                  {' '}
                  {errors.firstName?.message}{' '}
                </FormErrorMessage>
              </FormControl>
              <FormControl id="lastName" mb="10px">
                <FormLabel as={'p'} fontWeight="semibold">
                  Last Name
                </FormLabel>
                <Input
                  type="text"
                  name="lastName"
                  ref={register}
                  borderColor="gray.300"
                />
              </FormControl>
              <FormControl id="email" mb="10px" isInvalid={errors.email}>
                <FormLabel as={'p'} fontWeight="semibold">
                  Email
                </FormLabel>
                <Input
                  type="email"
                  name="email"
                  ref={register({ required: 'Email is required' })}
                  borderColor="gray.300"
                />
                <FormErrorMessage> {errors.email?.message} </FormErrorMessage>
              </FormControl>
              <FormControl id="address" mb="10px" isInvalid={errors.message}>
                <FormLabel as={'p'} fontWeight="semibold">
                  Message
                </FormLabel>
                <Textarea
                  type="text"
                  name="message"
                  ref={register({ required: 'message is required' })}
                  borderColor="gray.300"
                />
                <FormErrorMessage> {errors.message?.message} </FormErrorMessage>
              </FormControl>

              <FormControl
                id="emailSubscribe"
                mb="10px"
                isInvalid={errors.message}
                display="flex"
                justifyContent="start">
                <Controller
                  control={control}
                  name="emailSubscribe"
                  defaultValue={false}
                  render={(
                    { onChange, onBlur, value, name, ref },
                    { invalid, isTouched, isDirty }
                  ) => {
                    return (
                      <Checkbox
                        mb={5}
                        colorScheme="teal"
                        onChange={(e) => onChange(e.target.checked)}
                        isChecked={value}>
                        {/* {`Subscribe to ${globalSettings?.title} Email List`} */}
                        <Text>{checkboxLabel}</Text>
                      </Checkbox>
                    );
                  }}
                />
                <FormErrorMessage> {errors.message?.message} </FormErrorMessage>
              </FormControl>

              <Button
                type="submit"
                size="md"
                height="48px"
                width="100%"
                loadingText="Sending"
                bg="white"
                color={theme?.color?.accent}
                border={`1px solid ${theme?.color?.accent}`}
                _hover={{
                  bg: `rgba(${
                    theme?.color?.accent
                      .match(/\w\w/g)
                      .map((x) => parseInt(x, 16))
                      .toString() + ', 0.05'
                  })`,
                }}>
                Send
              </Button>
            </Box>
          </form>
        )}
      </SimpleGrid>
    </section>
  );
};

export default LandingContactForm;
