import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import {
   Container, Box, Input, Button, SimpleGrid, Checkbox,
   FormControl, FormLabel, FormErrorMessage, useToast,
   Modal, ModalOverlay, ModalContent, ModalHeader,
   ModalFooter, ModalBody, ModalCloseButton, useDisclosure
} from '@chakra-ui/react'; 
import axios from 'axios'; 
import { config } from '../../config'; 
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb'; 
import ReactSelectOptionMapper from '../../service/ReactSelect';
import DeleteComponent from '../../components/delete/DeleteComponent';


function UpdateShippingMethod() {
   const history = useHistory();
   const { id } = useParams();
   const { baseUrl } = config;
   const toast = useToast();
   const { isOpen, onOpen, onClose } = useDisclosure();
   const { isOpen:isOpenDeleteModal, onOpen:onOpenDeleteModal, onClose:onCloseDeleteModal } = useDisclosure();
   const { register, handleSubmit, errors, control, watch, reset, setValue, getValues } = useForm({
      mode: 'onTouched',
      shouldFocusError: false
   })
   let min = watch('minDays');

   const [name, setName] = useState('');
   const [isDefaultPrev, setIsDefaultPrev] = useState(false); 
   // const [business, setBusiness] = useState([]); 

   const token = localStorage.getItem('token');
   const franchiseeId = localStorage.getItem('franchiseeId');
   // const businessId = localStorage.getItem('businessId');


   useEffect(() => {
      axios({
         method: 'get',
         baseURL: baseUrl,
         url: `/api/v1/shippingMethods/${id}?franchiseeId=${franchiseeId}`,
         headers: { 'Authorization': `Bearer ${token}` }
      })
      .then(res => { 
         // res.data.data.business = { 
         //    label: res.data.data.businessId.name, 
         //    value: res.data.data.businessId._id, 
         // } 
         reset(res?.data?.data); 
         setIsDefaultPrev(res?.data?.data?.isDefault); 
         setName(res.data?.data?.name); 
      }) 
      .catch(err => { 
         toast({ 
            title: "Error occured", 
            description: err?.response?.data?.message, 
            status: "error", 
            duration: 4000, 
            isClosable: true 
         }) 
      }) 
   }, []) 

   const submitHandler = (data) => { 
      data.franchiseeId = franchiseeId;
      // data.businessId = data.business.value; 
      
      axios({
         data: data,
         method: 'patch',
         baseURL: baseUrl,
         url: `/api/v1/shippingMethods/${id}`,
         headers: { 'Authorization': `Bearer ${token}` }
      })
      .then(res => {
         toast({
            title: "Updated Successfully.",
            status: "success",
            duration: 4000,
            isClosable: true
         })
         history.push(`/admin/shipping-method`);
      })
      .catch(err => {
         toast({
            title: "Error occured",
            description: err?.response?.data?.message,
            status: "error",
            duration: 4000,
            isClosable: true
         })
      }) 
   } 

   const handleDefault = (e) => { 
      if (isDefaultPrev) { 
         return true; 
      } 
      else { 
         onOpen(); 
      } 
   } 

   const sendMakeDefaultAPI = () => { 
      let makeDefaultData = { 
         franchiseeId, 
         // businessId: getValues('business').value, 
         isDefault: true 
      } 

      axios({
         data: makeDefaultData,
         method: 'patch',
         baseURL: baseUrl,
         url: `/api/v1/shippingMethods/changeDefault/${id}`,
         headers: { 'Authorization': `Bearer ${token}` }
      })
      .then(res => { 
         setValue('isDefault', true); 
         setIsDefaultPrev(true); 
      }) 
      .catch(err => { 
         toast({ 
            title: "Error occured",
            description: err?.response?.data?.message,
            status: "error",
            duration: 4000,
            isClosable: true
         }) 
      }) 
   } 

   const getDeleteApi = () => { 
      const franchiseeId = localStorage.getItem('franchiseeId');
      let url = `/shippingMethods/${id}?adminType=SA`; 
      if(franchiseeId !== 'null') { 
         url = `/shippingMethods/${id}?adminType=CA&franchiseeId=${franchiseeId}`; 
      } 
      return url; 
   } 

   return (
      <Container maxWidth={1100} centerContent my={6}>
         <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg">
            <Breadcrumb pathname="Shipping Methods" nameOrTitle={name} link={`/admin/shipping-method`} mb={5} />

            <form onSubmit={handleSubmit(submitHandler)}>
               <FormControl mb="15px" isInvalid={errors.name}>
                  <FormLabel as={'p'} fontWeight="semibold" m={0}> Name </FormLabel>
                  <Input
                     ref={register({ required: 'Name is required' })}
                     variant="flushed" borderColor="gray.300" size="sm"
                     name="name" type="text" />
                  <FormErrorMessage mb="20px">
                     {errors?.name?.message}
                  </FormErrorMessage>
               </FormControl>

               <FormLabel as={'p'} fontWeight="semibold" m={0} pb={2}>Estimate days</FormLabel>
               <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }} spacing="15px" mb={5}>
                  <FormControl mb="15px" isInvalid={errors?.minDays}>
                     <FormLabel as={'p'} fontWeight="semibold" m={0}>Min Day</FormLabel>
                     <Input
                        type="text" name="minDays"
                        ref={register({
                           required: 'Minimum day is required',
                           valueAsNumber: true,
                           min: {
                              value: 1,
                              message: 'Minimum 1 day required'
                           }
                        })}
                        variant="flushed" borderColor="gray.300" size="sm"
                     />
                     <FormErrorMessage>
                        {errors?.minDays?.message}
                     </FormErrorMessage>
                  </FormControl>

                  <FormControl mb="15px" isInvalid={errors?.maxDays}>
                     <FormLabel as={'p'} fontWeight="semibold" m={0}>Max Day</FormLabel>
                     <Input
                        ref={
                           register({
                              required: 'Maximum day is required',
                              valueAsNumber: true,
                              validate: (value) => value > min || 'Maximum must be greater than minimum'
                           })
                        }
                        variant="flushed" borderColor="gray.300" size="sm"
                        name="maxDays" type="text"
                     />
                     <FormErrorMessage>
                        {errors?.maxDays?.message}
                     </FormErrorMessage>
                  </FormControl>
               </SimpleGrid>


               <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }} spacing="15px">
                  <FormControl mb="15px" isInvalid={errors.cost}>
                     <FormLabel as={'p'} fontWeight="semibold" m={0}>Shipping Cost</FormLabel>
                     <Input
                        ref={register({
                           required: 'Shipping cost is required',
                           valueAsNumber: true,
                        })}
                        variant="flushed" borderColor="gray.300" size="sm"
                        name="cost" type="text" />
                     <FormErrorMessage mb="20px">
                        {errors?.cost?.message}
                     </FormErrorMessage>
                  </FormControl>


                  <FormControl as="fieldset">
                     <Controller
                        control={control}
                        name="isDefault"
                        defaultValue={false}
                        render={(
                           { onChange, onBlur, value, name, ref },
                           { invalid, isTouched, isDirty }
                        ) => {
                           return (
                              <Checkbox
                                 mb={5}
                                 colorScheme="teal"
                                 onChange={(e) => onChange(handleDefault(e))}
                                 isChecked={value}> 
                                 Default 
                              </Checkbox> 
                           ) 
                        }} 
                     /> 
                  </FormControl> 
               </SimpleGrid> 

               {/* <FormControl id="business" my={3} w={['100%', '100%', '50%', '50%']}>
                  <FormLabel>Business</FormLabel> 
                  <Controller 
                     control={control} 
                     name="business" 
                     as={Select} 
                     options={business} 
                     defaultValue={[]} 
                  /> 
               </FormControl>  */} 
               <Button colorScheme="red" variant="outline" size="sm" mr={3} onClick={onOpenDeleteModal}> 
                  Delete 
               </Button> 
               <Button type="submit" colorScheme="teal" variant="outline" size="sm" w="80px" rounded={4}>
                  Save
               </Button>
            </form>
         </Box>


         {/* Confirmation Modal */}
         <Modal isOpen={isOpen} onClose={onClose} isCentered> 
            <ModalOverlay /> 
            <ModalContent> 
               <ModalHeader>Confirm Default Method</ModalHeader> 
               <ModalCloseButton /> 
               <ModalBody> 
                  If you activate this method, 
                  This will be marked as default.
               </ModalBody> 

               <ModalFooter> 
                  <Button size="sm" colorScheme="blue" mr={3} 
                     onClick={() => {
                        sendMakeDefaultAPI(); 
                        onClose(); 
                     }}> 
                     Ok 
                  </Button> 
                  <Button size="sm" colorScheme="blue" mr={3} onClick={onClose}> 
                     Close
                  </Button> 
               </ModalFooter> 
            </ModalContent> 
         </Modal> 


         <DeleteComponent 
            headerName="Shipping Method"
            isOpen={isOpenDeleteModal} onClose={onCloseDeleteModal} 
            getDeleteApi={getDeleteApi} 
            routeOnDelete='/admin/shipping-method'
            showBackendMsg={true} 
            toastSuccessMsgTitle="Shipping method deleted successfully" 
            toastErrMsgTitle="Cannot delete the shipping method" /> 
      </Container> 
   )
}

export default UpdateShippingMethod;
