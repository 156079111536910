import React, { useEffect, useState } from 'react'
import {
    Box, SimpleGrid, Input,
    Text, Stack, Select,
    FormControl, FormLabel, FormErrorMessage, Button, Checkbox,
} from '@chakra-ui/react'
import { config, UsaState } from '../../config';
import { useForm, Controller } from 'react-hook-form'
import DomainUtils from '../../service/utils/DomainUtils';
import axios from 'axios';
import useDomain from '../../service/DomainHook';

function BillingAddressForm({ handleBillingAddress, onClose, billingAddress, shippingAddressList }) {
    const [hasSubDomain] = useDomain();
    const { register, handleSubmit, control, errors, reset } = useForm({
        mode: 'onSubmit',
        shouldFocusError: false,
        shouldUnregister: true
    });

    const [subscriberLabel, setSubscriberLabel] = useState('');

    useEffect(() => {
        if (billingAddress.shippingId) {
            const shipping = shippingAddressList.find(item => item.id === billingAddress.shippingId);
            reset({ billingAddress: shipping.address });
        } else {
            reset({ billingAddress: billingAddress.address });
        }

        return () => {
            reset({
                billingAddress: { country: "US" }
            })
        }

    }, [])

    
    const getData = async () => {
        const baseUrl = config.baseUrl;
        let getUrl = `${baseUrl}/api/v1/pages/superAdmin`;

        if (hasSubDomain) {
            const [franchisee] = await DomainUtils();
            getUrl = `${baseUrl}/api/v1/pages/cityAdmin/${franchisee}`
        }

        axios.get(getUrl)
            .then(response => {
                let {pageContact} = response.data.data
                localStorage.setItem("subscriberLabel", pageContact?.checkboxLabel);
                setSubscriberLabel(pageContact?.checkboxLabel || "Subscribe to Our Email List")
            })
            .catch(error => {
                setSubscriberLabel("Subscribe to Our Email List")
            });

    };

    useEffect(() => {
        let label = localStorage.getItem("subscriberLabel")

        if (!label) {
            getData();
            // setSubscriberLabel('Subscribe to Our Email List')
        }
        else {
            setSubscriberLabel(label || "Subscribe to Our Email List")
        }
    },[])

    return (
        <Box>
            <form onSubmit={handleSubmit(handleBillingAddress)}>
                <SimpleGrid minChildWidth="14rem" spacing="15px">


                    <Input type="hidden" name="billingAddress.country" defaultValue="US" ref={register} />


                    <FormControl mb={4} color="gray.600" id="billFirstname" isInvalid={errors.billingAddress?.firstName}>
                        <FormLabel htmlFor="billingAddressFirstname"  as={'p'} fontWeight="semibold">First Name</FormLabel>
                        <Input type="text" name="billingAddress.firstName" ref={register({ required: true })} />
                        <FormErrorMessage>
                            {errors.billingAddress?.firstName && errors.billingAddress?.firstName?.type === "required" && 'First name is required'}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl mb={4} color="gray.600" id="billingAddressLastname" isInvalid={errors.billingAddress?.lastName}>
                        <FormLabel htmlFor="billingAddressLastname"  as={'p'} fontWeight="semibold">Last Name</FormLabel>
                        <Input type="text" name="billingAddress.lastName" ref={register} />
                        <FormErrorMessage>
                            {errors.billingAddress?.lastName && errors.billingAddress?.lastName?.type === "required" && 'Last name is required'}
                        </FormErrorMessage>
                    </FormControl>
                </SimpleGrid>
                <Box mb={4}>
                    <Stack direction={["column", "row"]} spacing="10px">
                        <Text color="gray.500" fontWeight="700" fontSize=".9rem" lineHeight="1rem" mb={1}>Country / Region :</Text>
                        <Text color="gray.400" fontWeight="700" fontSize="1rem" lineHeight="1rem" mb={1}>United States (US)</Text>
                    </Stack>
                </Box>

                <FormControl mb={4} color="gray.600" id="billingAddressCompanyName">
                    <FormLabel htmlFor="billingAddressCompanyName"  as={'p'} fontWeight="semibold">Company Name</FormLabel>
                    <Input type="text" ref={register} name="billingAddress.company" placeholder="Company name (optional)" />
                </FormControl>
                <FormControl mb={4} color="gray.600" id="billingAddressStreetAddress" isInvalid={errors.billingAddress?.street}>
                    <FormLabel htmlFor="billingAddressStreetAddress"  as={'p'} fontWeight="semibold">Street Address</FormLabel>
                    <Input type="text" name="billingAddress.street" ref={register({ required: true })} />
                    <FormErrorMessage>
                        {errors.billingAddress?.street && errors.billingAddress?.street?.type === "required" && 'Street address is required'}
                    </FormErrorMessage>
                </FormControl>
                <FormControl mb={4} color="gray.600" id="house">
                    <Input type="text" name="billingAddress.house" ref={register} placeholder="Apartment, Suit, Unit etc (optional)" />
                </FormControl>

                <FormControl mb={4} color="gray.600" id="city" isInvalid={errors.billingAddress?.city}>
                    <FormLabel  as={'p'} fontWeight="semibold">Town / City</FormLabel>
                    <Input type="text" name="billingAddress.city" ref={register({ required: true })} />
                    <FormErrorMessage>
                        {errors.billingAddress?.city && errors.billingAddress?.city?.type === "required" && 'City name is required'}
                    </FormErrorMessage>
                </FormControl>
                <FormControl mb={4} color="gray.600" id="state" isInvalid={errors.billingAddress?.state}>
                    <FormLabel  as={'p'} fontWeight="semibold">State</FormLabel>
                    <Select ref={register({ required: true })} name="billingAddress.state">
                        {
                            UsaState.map(state => (
                                <option key={state.value} value={state.value}>{state.label}</option>
                            ))
                        }
                    </Select>
                    <FormErrorMessage>
                        {errors.billingAddress?.state && errors.billingAddress?.state?.type === "required" && 'State field is required'}
                    </FormErrorMessage>
                </FormControl>
                <FormControl mb={4} color="gray.600" id="zipCode" isInvalid={errors.billingAddress?.zip}>
                    <FormLabel  as={'p'} fontWeight="semibold">Zip code</FormLabel>
                    <Input type="number" name="billingAddress.zip" ref={register({ required: true })} />
                    <FormErrorMessage>
                        {errors.billingAddress?.zip && errors.billingAddress?.zip?.type === "required" && 'Zip code field is required'}
                    </FormErrorMessage>
                </FormControl>
                <FormControl mb={4} color="gray.600" id="phone" isInvalid={errors.billingAddress?.phone}>
                    <FormLabel  as={'p'} fontWeight="semibold">Phone</FormLabel>
                    <Input type="tel" name="billingAddress.phone" ref={register({ required: true })} />
                    <FormErrorMessage>
                        {errors.billingAddress?.phone && errors.billingAddress?.phone?.type === "required" && 'Phone field is required'}
                    </FormErrorMessage>
                </FormControl>
                <FormControl mb={4} color="gray.600" id="email" isInvalid={errors.billingAddress?.email}>
                    <FormLabel  as={'p'} fontWeight="semibold">Email</FormLabel>
                    <Input type="email" name="billingAddress.email" ref={register({ required: true })} />
                    <FormErrorMessage>
                        {errors.billingAddress?.email && errors.billingAddress?.email?.type === "required" && 'Email field is required'}
                    </FormErrorMessage>
                </FormControl>

                <FormControl
                    id="emailSubscribe"
                    mb="10px"
                    isInvalid={errors.message}
                    display="flex"
                    justifyContent="start"
                >
                    <Controller
                        control={control}
                        name="billingAddress.emailSubscribe"
                        defaultValue={false}
                        render={(
                            { onChange, onBlur, value, name, ref },
                            { invalid, isTouched, isDirty }
                        ) => {
                            return (
                                <Checkbox
                                    mb={5}
                                    colorScheme="teal"
                                    onChange={(e) => onChange(e.target.checked)}
                                    isChecked={value}
                                >
                                    {/* Subscribe to Gift Your Town Email List */}
                                    {subscriberLabel}
                                </Checkbox>
                            )
                        }}
                    />
                    <FormErrorMessage> {errors.message?.message} </FormErrorMessage>
                </FormControl>

                <Stack direction="row" spacing={4}>
                    <Button size="sm" colorScheme='black' variant='outline' onClick={onClose}>Cancel</Button>
                    <Button size="sm" colorScheme='green' variant='outline' type="submit">Save</Button>
                </Stack>
            </form>
        </Box>
    )
}

export default BillingAddressForm