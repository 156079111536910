import React from 'react'; 
import {Route, Redirect} from 'react-router-dom'; 
import Auth from './Auth'; 

function ProtectedRoute({component: Component, ...rest}) { 
    return ( 
        <Route 
            {...rest} 
            render={(props) => { 
                const key = localStorage.getItem('token') 
                
                if(key || Auth.isAuthenticated()) { 
                    return <Component {...props} /> 
                } 
                else { 
                    return <Redirect to={{ 
                        pathname: '/warning', 
                        state: { 
                            from: props.location 
                        } 
                    }} /> 
                } 
            }} 
        /> 
    ) 
} 

export default ProtectedRoute; 