import { StarIcon } from '@chakra-ui/icons';
import {
    Box, Button, FormControl, FormErrorMessage, FormLabel, Input, SimpleGrid, Text, Textarea, useToast
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { config } from '../../config';

function CreateProductReview(props) {
    const theme = JSON.parse(localStorage.getItem('theme'));
    const role = localStorage.getItem('role');
    const franchiseeId = localStorage.getItem('franchiseeId');
    const franchisee = localStorage.getItem('franchisee');

    const baseURL = `${config.baseUrl}/api/v1`;
    const { register, handleSubmit, errors, reset } = useForm({
        mode: 'onTouched',
        shouldFocusError: false
    })

    const toast = useToast()
    const [reviewPoint, setReviewPoint] = useState(0)
    const [reviewRating, setReviewRating] = useState([
        { iconColor: 'gray.400', selected: false },
        { iconColor: 'gray.400', selected: false },
        { iconColor: 'gray.400', selected: false },
        { iconColor: 'gray.400', selected: false },
        { iconColor: 'gray.400', selected: false }
    ])

    const reviewChecker = (index) => {
        let newReviewRating = [
            { iconColor: 'gray.400', selected: false },
            { iconColor: 'gray.400', selected: false },
            { iconColor: 'gray.400', selected: false },
            { iconColor: 'gray.400', selected: false },
            { iconColor: 'gray.400', selected: false }
        ]

        newReviewRating[index].selected = true;

        for (let i in reviewRating) {
            if (index >= i) {
                newReviewRating[i].iconColor = `rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 1'})`
            }
        }

        setReviewRating(newReviewRating);
        setReviewPoint(index + 1);
    }


    const submitHandler = (data) => {
        if (reviewPoint) {
            data.rating = reviewPoint;
            data.productId = props.productId;
            data.commentAt = Date.now();
            data.productSlug = props.productSlug;


            data.adminType = 'SA';


            if (franchiseeId & role === 'CITYADMIN') {
                data.franchiseeId = franchiseeId;
                data.adminType = 'CA'
            }

            if (franchisee) {
                data.franchiseeId = franchisee;
                data.adminType = 'CA'
            }
            axios({
                url: `/productComments`,
                method: 'post',
                baseURL: baseURL,
                data: data
            })
                .then(() => {
                    props.updateReviewHandler()
                    props.newReviewCreatedHandler()
                    const defaultReview = [
                        { iconColor: 'gray.400', selected: false },
                        { iconColor: 'gray.400', selected: false },
                        { iconColor: 'gray.400', selected: false },
                        { iconColor: 'gray.400', selected: false },
                        { iconColor: 'gray.400', selected: false }
                    ]
                    setReviewRating(defaultReview)

                    reset({
                        'message': '',
                        'authorName': '',
                        'authorEmail': ''
                    })
                    toast({
                        title: "You wrote a comment successfully",
                        status: "success",
                        duration: 4000,
                        isClosable: true
                    })
                })
                .catch(err => console.log(err))
        }
        else {
            toast({
                title: "Please give a rating",
                // description: "Must give a rating!",
                status: "error",
                duration: 4000,
                isClosable: true,
            })
        }

    }

    return (
        <Box
            mt="30px" p={6} rounded="md"
            borderWidth="1px" borderColor="gray.200"
        >
            <Text as="h3" mb="25px" fontSize="1.3em" fontWeight="600">
                Write your review
            </Text>

            <form onSubmit={handleSubmit(submitHandler)}>
                <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing={{ base: '10px', md: '5rem' }}>
                    <FormControl mb="15px">
                        <FormLabel m={0}>Name</FormLabel>
                        <Input
                            ref={register}
                            variant="flushed"
                            borderColor="gray.300" size="sm"
                            name="authorName" type="text" />
                    </FormControl>


                    <FormControl mb="15px" isInvalid={errors.authorEmail}>
                        <FormLabel m={0}>Email</FormLabel>
                        <Input
                            ref={register({
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address"
                                }
                            })}
                            variant="flushed"
                            borderColor="gray.300" size="sm"
                            name="authorEmail" type="text" />
                        <FormErrorMessage mb="20px">{errors?.authorEmail?.message}</FormErrorMessage>
                    </FormControl>
                </SimpleGrid>




                <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing={{ base: '10px', md: '5rem' }}>
                    <FormControl mb="15px" isInvalid={errors.message}>
                        <FormLabel m={0}>Review Details</FormLabel>
                        <Textarea
                            ref={register({ required: 'Message is required to review the product' })}
                            variant="flushed"
                            borderColor="gray.300" size="sm"
                            name="message" type="text" />
                        <FormErrorMessage mb="20px">{errors?.message?.message}</FormErrorMessage>
                    </FormControl>


                    <FormControl>
                        <FormLabel m={0}>Rating</FormLabel>
                        <Box mb="20px" pt="5px">
                            {
                                reviewRating.map((obj, i) => (
                                    <StarIcon
                                        key={i}
                                        w={4} h={4} mr="5px"
                                        color={obj.iconColor}
                                        cursor="pointer"
                                        onClick={() => reviewChecker(i)}
                                    />
                                ))
                            }
                        </Box>
                        {/* <Button 
                            type="submit" size="sm" colorScheme="teal" variant="outline">
                            Post
                        </Button>  */}
                        <Button type="submit" size="sm"
                            bg="white" color={theme?.color?.accent} border={`1px solid ${theme?.color?.accent}`}
                            _hover={{
                                bg: `rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 0.05'})`
                            }}>
                            Post
                        </Button>
                    </FormControl>
                </SimpleGrid>
            </form>
        </Box>
    )
}

export default CreateProductReview; 