import { Container, Box, Skeleton, HStack } from '@chakra-ui/react'
import React from 'react'

function PageSkeleton() {
    return (
        <Box>
            <Container maxWidth="100%">
                {/* <Box mb="20px" p="30px" shadow="lg" border="1px solid #EEE" rounded={4}> */}
                    <Skeleton h="350px" w="100%" mb={4} />
                {/* </Box> */}
                <Container mt={5} maxWidth={1160}>
                    <Skeleton h="8px" w="100%" mb="20px" />
                    <Skeleton h="8px" w="90%" mb="20px" />
                    <Skeleton h="8px" w="100%" mb="20px" />
                    <Skeleton h="8px" w="90%" mb="20px" />
                    <Skeleton h="8px" w="95%" mb="20px" />
                    <Skeleton h="8px" w="100%" mb="20px" />
                    <Skeleton h="8px" w="80%" mb="20px" />
                    <Skeleton h="8px" w="100%" mb="20px" />
                    <Skeleton h="8px" w="90%" mb="20px" />
                    <Skeleton h="8px" w="90%" mb="20px" />
                    <Skeleton h="8px" w="95%" mb="20px" />
                    <Skeleton h="8px" w="80%" />
                </Container>
            </Container>
        </Box>
    )
}

export default PageSkeleton
