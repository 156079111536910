import React, { useState, useRef, useCallback } from 'react';
import { Link as RouteLink, useRouteMatch } from 'react-router-dom';
import {
   Container, Box, Flex, Spacer, Button, Link
} from '@chakra-ui/react';
import axios from 'axios';
import { config } from '../../config';
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import ShippingTable from './ShippingTable';

function Shipping() {
   const baseURL = `${config.baseUrl}/api/v1`;
   const token = localStorage.getItem('token');
   let { url } = useRouteMatch();
   const franchiseeId = localStorage.getItem('franchiseeId');

   // const { register, handleSubmit, watch, control, errors } = useForm({
   //    mode: 'onTouched',
   //    shouldFocusError: false
   // });

   // let history = useHistory();
   // const toast = useToast(); 

   const [allMethods, setAllMethods] = useState([])

   const [pageCount, setPageCount] = useState(0);
   const fetchIdRef = useRef(0);

   let columns = [
      {
         Header: 'Method Name',
         accessor: 'name'
      },
      {
         Header: 'Minimum Days',
         accessor: 'minDays'
      },
      {
         Header: 'Maximum Days',
         accessor: 'maxDays'
      },
      {
         Header: 'Shipping Cost',
         accessor: 'cost'
      },
      {
         Header: 'Default',
         accessor: 'isDefault'
      },
   ];
   let sortOn = ['Method Name', 'Shipping Cost'];



   // const franchiseeId = localStorage.getItem('franchiseeId'); 
   const fetchData = useCallback(({ pageSize, pageIndex, globalFilter, skipPageResetRef }) => { 
      const fetchId = ++fetchIdRef.current; 

      if (globalFilter) { 
         // Filtering on name field 
         axios({ 
            url: `/shippingMethods?franchiseeId=${franchiseeId}&search=${globalFilter}`, 
            method: 'get', 
            baseURL: baseURL, 
            headers: { 'Authorization': `Bearer ${token}` } 
         }) 
            .then((res) => { 
               const { data: serverData, result: totalLength } = res.data; 
               if (fetchId === fetchIdRef.current) { 
                  if (serverData.length >= 1) { 
                     skipPageResetRef.current = true 
                     setAllMethods(serverData) 
                     setPageCount(Math.ceil(totalLength / pageSize)) 
                  } 
               } 
            }) 
            .catch(err => console.log(err, 'Data fetch error')) 
      } 
      else { 
         // Pagination 
         axios({
            url: `/shippingMethods?franchiseeId=${franchiseeId}&page=${(pageIndex) + 1}&limit=${pageSize}`,
            method: 'get',
            baseURL: baseURL,
            headers: { 'Authorization': `Bearer ${token}` }
         })
            .then((res) => { 
               const { data: serverData, result: totalLength } = res.data; 
               if (fetchId === fetchIdRef.current) { 
                  skipPageResetRef.current = true; 
                  setAllMethods(serverData); 
                  setPageCount(Math.ceil(totalLength / pageSize)); 
               } 
            })
            .catch(err => console.log(err, 'Data fetch error'))
      }
   }, [])

   return ( 
      <Container maxWidth={1100} centerContent my={6}> 
         <Box w="100%" bg="white" rounded="lg" boxShadow="lg" px={4}> 
            <Flex py={4}> 
               <Breadcrumb pathname="Shipping Methods" /> 
               <Spacer /> 
               <Button colorScheme="teal" variant="outline" size="sm"> 
                  <Link color="blue.500" lineHeight="32px" as={RouteLink} to={`${url}/create`}> 
                     Create new 
                  </Link> 
               </Button> 
            </Flex> 

            <ShippingTable 
               data={allMethods} 
               columns={columns} 
               pageCount={pageCount} 
               fetchData={fetchData} 
               sortOn={sortOn} 
               defaultPageSize={10} 
               tableHeightInPage='58vh' 
               selectNoOfRows={[5, 10, 20, 30, 50, 100]} 
            /> 
         </Box> 
      </Container> 
   ) 
} 

export default Shipping; 