import {
    Box, Text, Flex, Image,
    Input, Select, InputGroup, InputRightAddon,
    FormControl,
    FormLabel, useToast, HStack,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Modal, ModalOverlay, ModalContent,
    useDisclosure
} from '@chakra-ui/react'
import { config, fonts } from '../../config';
import axios from 'axios';
import { SketchPicker } from 'react-color';
import { editorFontList } from '../../service/utils/FontList';
import { useState } from 'react';

function Header({ register, pageContent, setPageContent, id, style, setStyle }) {
    const token = localStorage.getItem('token');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const toast = useToast()
    const { baseUrl } = config;

    function imageHandler(e) {
        const formData = new FormData();
        formData.append('headerImage', e.target.files[0]);
        setLoading(true);
        axios({
            url: `/api/v1/pages/upload/${id}`,
            method: 'post',
            baseURL: baseUrl,
            data: formData,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => {
                setPageContent({
                    ...pageContent,
                    pageHeader: {
                        ...pageContent.pageHeader,
                        image: response.data.data.pageHeader.image
                    }
                })
                toast({
                    title: "Header image updated successfully.",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                })
            })
            .catch(err => {
                console.log('error', err);
                toast({
                    title: "Header image update failed.",
                    description: err?.response?.data?.message,
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                })
            }).finally(() => {
                setLoading(false);
            })
    }

    return (
        <>
            <Box bg="white" rounded="md" boxShadow="lg" p={{ base: '10px', md: '30px' }} mb="3rem">
                <Text as="h1">
                    Header Section
                </Text>

                <div className="brand_logo"></div>

                {/* Title */}
                <Flex mb="20px" alignItems="center" flexWrap="wrap">
                    <FormControl id="title" mb="15px">
                        <FormLabel as={'p'} mr="20px" fontWeight="600"> Title </FormLabel>
                        <Input
                            type="text" placeholder="Title" name="header_title"
                            variant="flushed"
                            borderColor="gray.400"
                            ref={register()}
                        />
                    </FormControl>
                </Flex>

                <HStack spacing="30px">
                    <FormControl id="fontSize" mb="15px">
                        <FormLabel mr="20px" fontWeight="600">Font Family</FormLabel>
                        <Select
                            size="sm"
                            value={style.fontFamily}
                            onChange={(e => setStyle({ ...style, fontFamily: e.target.value }))}
                        >
                            {/* <option value="inherit">Default</option> */}
                            {
                                editorFontList.map(font => (
                                    <option key={font} value={font}>{font}</option>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl id="fontSize" mb="15px">
                        <FormLabel mr="20px" fontWeight="600">Font Size</FormLabel>
                        <NumberInput
                            size="sm"
                            defaultValue={style.fontSize}
                            value={style.fontSize}
                            max={72} min={40}
                            onChange={(value => setStyle({ ...style, fontSize: value }))}
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </FormControl>
                    <FormControl id="fontSize" mb="15px">
                        <FormLabel mr="20px" fontWeight="600">Font Weight</FormLabel>
                        <Select
                            size="sm"
                            value={style.fontWeight}
                            onChange={(e => setStyle({ ...style, fontWeight: e.target.value }))}
                        >
                            <option value='300'>300</option>
                            <option value='400'>400</option>
                            <option value='600'>600</option>
                            <option value='700'>700</option>
                        </Select>

                    </FormControl>
                    <FormControl id="fontSize" mb="15px">
                        <FormLabel mr="20px" fontWeight="600">Font Color</FormLabel>
                        <InputGroup flex="1 1" size="sm">
                            <Input
                                size="sm"
                                value={style.fontColor}
                                onChange={(e => setStyle({ ...style, fontColor: e.target.value }))}
                            />
                            <InputRightAddon roundedRight="8px" cursor="pointer" bg={style.fontColor} onClick={onOpen} />
                        </InputGroup>
                    </FormControl>

                </HStack>


                {/* Header Image */}
                <Flex mb="20px" justify="space-between">
                    <Box w='48%'
                    >
                        <FormControl mb={2}>
                            <FormLabel htmlFor='header_image' textAlign="center" cursor={loading ? 'not-allowed' : 'pointer'}
                                color={loading ? 'teal.500' : "gray.500"}
                                p='10px'
                                w='100%'
                                h='100%'
                                lineHeight="60px"
                                border="1px dashed #BDBDBD"
                            >
                                {loading ? 'Uploading Header Image' : 'Click To Upload Header Image'}
                            </FormLabel>
                            <Input
                                isDisabled={loading}
                                type="file"
                                name="header_image"
                                id='header_image'
                                accept="image/*"
                                onChange={(e) => imageHandler(e)}
                                style={{ display: "none" }} />

                        </FormControl>
                    </Box>

                    <Box w='48%'>
                        {
                            pageContent.pageHeader.image &&
                            <Image src={pageContent.pageHeader.image} alt="header_image" style={{ maxHeight: '250px' }} />
                        }
                    </Box>
                </Flex>
            </Box>

            <Modal
                isCentered
                onClose={onClose}
                isOpen={isOpen}
                motionPreset="slideInBottom"
                size="xs">

                <ModalOverlay />
                <ModalContent>
                    <SketchPicker
                        width="100%"
                        color={style.fontColor}
                        onChange={(color) => setStyle({ ...style, fontColor: color.hex })}
                    />
                </ModalContent>
            </Modal>
        </>
    )
}

export default Header; 