import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
    Box, Container, Input, Button, useToast, Checkbox,
    FormErrorMessage, FormLabel, FormControl, Flex, Image,
    SimpleGrid, useDisclosure,
} from '@chakra-ui/react'
import axios from 'axios'
import { config } from '../../config'
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import DeleteComponent from '../../components/delete/DeleteComponent';
import { FaTrash } from 'react-icons/fa';


function GreetingDetails() {
    const role = localStorage.getItem('role');
    const { id: _id } = useParams()
    const token = localStorage.getItem('token');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { control, register, handleSubmit, errors, reset } = useForm({
        mode: 'onTouched',
        shouldFocusError: false
    });

    const [previewImage, setPreviewImage] = useState('');
    const [name, setName] = useState('');

    const baseUrl = config.baseUrl;

    const toast = useToast();
    const history = useHistory();
    const franchiseeId = localStorage.getItem('franchiseeId');

    const getDeleteApi = () => {
        const franchiseeId = localStorage.getItem('franchiseeId');
        let url = `/greetingCards/${_id}?adminType=SA`;
        if (franchiseeId !== 'null') {
            url = `/greetingCards/${_id}?adminType=CA&franchiseeId=${franchiseeId}`;
        }
        return url;
    }

    function imageHandler(e) {
        // To preview before upload --- Converting to base 64
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setPreviewImage(reader.result)

                const formData = new FormData()
                formData.append('image', e.target.files[0]);

                axios({
                    url: `/api/v1/greetingCards/${_id}`,
                    method: 'post',
                    baseURL: baseUrl,
                    data: formData,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(({ data }) => {
                        toast({
                            title: "Image updated.",
                            description: "Image updated successfully",
                            status: "success",
                            duration: 4000,
                            isClosable: true,
                        })
                    })
                    .catch(err => {
                        toast({
                            title: "Image not updated",
                            description: JSON.stringify(err),
                            status: "fail",
                            duration: 4000,
                            isClosable: true,
                        })
                    })
            }
        }

        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0])
        }
    };

    useEffect(() => {
        axios({
            url: `/api/v1/greetingCards/${_id}`,
            method: 'get',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {

                let price = res?.data?.data?.price?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                res.data.data.price = price.replaceAll(',', '');
                reset(res.data.data);

                setName(res.data.data?.name);
                setPreviewImage(res.data?.data?.image);
            })
            .catch(err => console.log(err))
    }, [])


    const formSubmit = (data) => {
        // if(businessChoosen) { 
        const token = localStorage.getItem('token');

        data.franchiseeId = franchiseeId;
        let price = +data?.price
        price = price.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
        price = price.replaceAll(',', '');
        data.price = +price;


        axios({
            url: `/api/v1/greetingCards/${_id}`,
            method: 'patch',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` },
            data: data
        })
            .then(response => {
                toast({
                    title: "Greeting card updated Successfully.",
                    status: "success",
                    duration: 4000,
                    isClosable: true
                })
                history.push(`/admin/greeting-card`);
            })
            .catch(error => console.log(error))
        // } 
    }
    const removeGreetingImage = () => {
        const formData = new FormData();
        formData.append('image', '');

        axios({
            url: `/api/v1/greetingCards/${_id}`,
            method: 'PUT',
            baseURL: baseUrl,
            data: formData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            setPreviewImage('');
            toast({
                title: "Greeting image deleted successfully.",
                status: "info",
                duration: 4000,
                isClosable: true,
            })
        })
            .catch(error => {
                console.log(error.message);
            });
    };

    return (
        <Container maxWidth={1100} centerContent my={6}>
            <Box w="100%" bg="white" rounded="lg" boxShadow="lg" p={4}>
                <Breadcrumb pathname="Greeting Card" nameOrTitle={name} link={`/admin/greeting-card`} mb={5} />
                <form onSubmit={handleSubmit(formSubmit)}>
                    <SimpleGrid columns={[1, 1, 2, 2]} spacing="20px">
                        <Box>
                            {/* Name */}
                            <FormControl id="firstName" mb="20px" isInvalid={errors?.name}>
                                <Flex>
                                    <FormLabel as={'p'} fontWeight="semibold">Name</FormLabel>
                                    <Input
                                        name="name" type="text" mt="-5px" variant="flushed" size="sm" borderColor="gray.300"
                                        ref={register({ required: 'Name is required' })} />
                                </Flex>
                                <FormErrorMessage>
                                    {errors?.name?.message}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl id="price" mb="20px">
                                <Flex>
                                    <FormLabel as={'p'} fontWeight="semibold">Price</FormLabel>
                                    <Input
                                        type="text" name="price" mt="-5px" ref={register} size="sm"
                                        variant="flushed" borderColor="gray.300" />
                                </Flex>
                            </FormControl>

                            {/* Active */}
                            <FormControl>
                                <Controller
                                    control={control}
                                    name="active"
                                    defaultValue={true}
                                    render={(
                                        { onChange, onBlur, value, name, ref },
                                        { invalid, isTouched, isDirty }
                                    ) => {
                                        return (
                                            <Checkbox
                                                mb={5}
                                                colorScheme="teal"
                                                onChange={(e) => onChange(e.target.checked)}
                                                isChecked={value} as={'p'} fontWeight="semibold">
                                                Active
                                            </Checkbox>
                                        )
                                    }}
                                />
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl mb="20px" isInvalid={errors?.image}>
                                <Box>
                                    <FormLabel
                                        w='100%'
                                        h='100%'
                                        lineHeight="60px"
                                        border="1px dashed #BDBDBD"
                                        htmlFor='cardImage' textAlign="center" cursor="pointer"
                                        // color="gray.500" 
                                        p='10px'
                                    >
                                        Click To Upload Image

                                    </FormLabel>
                                    <Input
                                        type="file"
                                        name="cardImage"
                                        ref={register}
                                        id='cardImage'
                                        accept="image/*"
                                        onChange={(e) => imageHandler(e)}
                                        style={{ display: "none" }} />
                                </Box>
                                <FormErrorMessage> {errors?.image?.message} </FormErrorMessage>
                                {
                                    previewImage ?
                                        <Box display="inline-block" position="relative">
                                            <Image src={previewImage} alt="greeting_image" style={{ maxHeight: '200px' }} />
                                            <Button
                                                position="absolute"
                                                top="2px"
                                                right="2px"
                                                size="xs"
                                                borderRadius={0}
                                                _hover={{ outline: 'none' }}
                                                _focus={{ outline: 'none' }}
                                                _active={{ bg: 'red.400', color: 'white' }}
                                                onClick={removeGreetingImage}
                                            >
                                                <FaTrash />
                                            </Button>
                                        </Box> : null
                                }
                            </FormControl>
                        </Box>
                    </SimpleGrid>




                    <Button colorScheme="red" variant="outline" size="sm" mr={3} onClick={onOpen} rounded={4}>
                        Delete
                    </Button>
                    <Button
                        type="submit" colorScheme="teal"
                        variant="outline" size="sm" rounded={4}
                    >
                        Save
                    </Button>
                </form>
            </Box>

            <DeleteComponent
                headerName="Greeting Card"
                isOpen={isOpen} onClose={onClose}
                getDeleteApi={getDeleteApi}
                routeOnDelete='/admin/greeting-card'
                showBackendMsg={false}
                toastSuccessMsgTitle="Shipping method deleted successfully"
            // toastErrMsgTitle="Cannot delete the shipping method" 
            />
        </Container>
    )
}

export default GreetingDetails; 