import React from 'react'; 
import Select  from 'react-select'; 

function SelectBox(props) { 
    const { 
        Controller, control, name, options, 
        index, item, menuItems, setMenuItems, 
        tab=null, tabIndex=null, setLatestSelectedValue 
    } = props; 

    return ( 
        <Controller 
            control={control} 
            name={name} 
            defaultValue='' 
            render={( 
                { onChange, onBlur, value, name, ref }, 
                { invalid, isTouched, isDirty } 
            ) => ( 
                <Select 
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    options={options} 
                    onChange={(value) => { 
                        onChange(value); 
                        
                        let saveValue = { 
                            apiUrl: value?.getApiUrl, 
                            data: { 
                                id: value?.value, 
                                name: value?.label, 
                                fieldToShowAsLabel: value?.fieldToShowAsLabel, 
                                slug: value?.slug, 
                                contentId: value?.contentId
                            }, 
                            hasChildren: false, 
                            isExpanded: false, 
                            isChildrenLoading: false, 
                            children: [],
                            type: 'PAGE'
                        } 

                        let allItems = [...menuItems]; 
                        let obj = {...item}; 
                        
                        if(tab) { 
                            obj.tabPanel[tabIndex].selectedOption = value; 
                            saveValue.data['optionName'] = obj.tabPanel[tabIndex]?.optionName; 
                            saveValue.data['slugPrefix'] = obj.tabPanel[tabIndex]?.optionNameSlug; 
                            saveValue.data['apiType'] = obj.tabPanel[tabIndex]?.apiType; 
                        } 
                        else { 
                            // No tabs for example: Pages 
                            obj.selectedOption = value; 
                            saveValue.data['optionName'] = obj?.optionName; 
                            saveValue.data['slugPrefix'] = obj?.optionNameSlug; 
                            saveValue.data['apiType'] = obj?.apiType; 
                        } 
                        allItems[index] = obj; 
                        setMenuItems(allItems); 

                        setLatestSelectedValue(saveValue); 
                    }} 
                    value={value} 
                /> 
            )} 
        /> 
    ) 
} 

export default SelectBox; 