import React, { useEffect, useState, useRef } from 'react';
import {
    Container, Box, Alert, AlertIcon,
    AlertDescription, AlertTitle, Center, Text, Flex
} from '@chakra-ui/react'
import './webBlog.css'
import BlogListData from './BlogListData';
import WebPagination from '../../components/pagination/WebPagination'

import useDomain from '../../service/DomainHook';
import DomainUtils from '../../service/utils/DomainUtils';
import axios from 'axios';
import { config } from '../../config';

const style = {
    // backgroundImage: `url(${shop})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover'
}

function BlogListComponent(props) {
    const { list, total, limit, page, isEmpty, fetchData, setNewPage, fetchAllUrl, loading } = props;
    const isFirstRun = useRef(true)

    const [PageHeaderData, setPageHeaderData] = useState({});
    const [hasSubDomain] = useDomain();
    const baseURL = `${config.baseUrl}/api/v1`;
    const theme = JSON.parse(localStorage.getItem('theme'));

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
        }
        else {
            loading.current = true;
            fetchData()
        }
    }, [page])

    useEffect(async () => {
        window.scrollTo(0, 0);

        let franchisee = null;
        if (hasSubDomain) {
            const [franchiseeId] = await DomainUtils();
            franchisee = franchiseeId;
        }

        /*
        *---------------------------------------------------
        *   Get product 
        *---------------------------------------------------
        */

        axios({
            url: '/globalSettings',
            method: 'GET',
            baseURL: baseURL,
            params: {
                adminType: franchisee ? "CA" : "SA",
                franchiseeId: franchisee,
                sort: 'createdAt',
                limit: 1
            }
        })
            .then(response => {
                const { data } = response.data;
                // setProduct(productData);
                // setTotal(result);
                setPageHeaderData(data[0])

                // if (productData.length < 1) {
                //     setIsEmpty(true);
                // }
                // setLoading(false);
                // productFirstLoad.current = true;
            })
            .catch(error => {
                console.log(error);
                // setLoading(false);
            });

    }, []);

    // const pageHeaderStyle = JSON.parse(localStorage.getItem('pageHeaderStyle'));

    let pageHeaderStyle = {
        fontSize: 20
    }
    const ph = localStorage.getItem('pageHeaderStyle')

    if (ph == 'undefined') {
        console.log("IF:::");
    }
    else {
        pageHeaderStyle = JSON.parse(localStorage.getItem('pageHeaderStyle'))
    }

    return (
        <Box>
            <Box h="350px" bg="teal.300" mt="55px" style={{ ...style, backgroundImage: `url(${PageHeaderData?.blogHeaderImage})` }}>
                <Container
                    maxW="1100px"
                    // bg="white" 
                    // boxShadow={{base: null, sm: null, md: 'lg', lg: 'lg'}}s
                    rounded="md"
                    mt={["30px", "30px", "40px"]}
                    p={{ base: 3, sm: 3, md: 10, lg: 10 }}
                    // textShadow={["0", "0", "white"]}
                    // style={{ "text-shadow": "0 0 white" }}
                    height="100%"
                >
                    {/* <Center> */}
                    <Flex alignItems='center' justifyContent='center' h="100%">
                        {/* <Center> */}
                        <Text
                            // fontSize="2.5rem"
                            // color={theme?.color?.primary}
                            // textAlign="center"
                            width="100%"
                            style={{ ...pageHeaderStyle, fontSize: pageHeaderStyle?.fontSize + 'px' }}
                        >
                            Blog
                        </Text>
                        {/* </Center> */}
                        {/* <Center>
                            <InputGroup w={["90%", "90%", "80%", "50%"]}>
                                <Input type="text" bg="#FFF" onChange={productSearch} placeholder="Search product" />
                                <InputRightElement
                                    pointerEvents="none"
                                    children={<Search2Icon color="gray.300" />}
                                />
                            </InputGroup>
                        </Center> */}
                    </Flex>
                    {/* </Center> */}
                </Container>
            </Box>
            <Box bg={isEmpty ? "white" : "#edf2f9"}>
                <Container maxWidth={1100} centerContent pt={["20px", "20px", "20px", "30px"]}>

                    {
                        isEmpty ? (
                            <Alert
                                status="info"
                                variant="subtle"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                textAlign="center"
                                height="50vh"
                                colorScheme="teal"
                                mt="20px"
                                mb="40px">
                                <AlertIcon boxSize="40px" mr={0} />
                                <AlertTitle mt={4} mb={1} fontSize="lg">
                                    Sorry, No blog post found!
                                </AlertTitle>
                                <AlertDescription maxWidth="sm">
                                    No post has been posted yet.
                                </AlertDescription>
                            </Alert>
                        ) : (
                            <BlogListData list={list} fetchAllUrl={fetchAllUrl} />
                        )
                    }

                    {
                        total > limit &&
                        <WebPagination
                            limit={limit}
                            page={page}
                            total={total}
                            setNewPage={setNewPage}
                        />
                    }
                </Container>
            </Box>
        </Box>
    )
}

export default BlogListComponent; 