import { SimpleGrid, Box, Image, Text } from '@chakra-ui/react'

const About = (props) => {
    const image = props.content ? `${props.content.image}` : null;                        
    const {title, text} = props.content ? props.content : {title:"", text:""}; 

    return (
        <section id="about" className="lan-container section">
            <SimpleGrid minChildWidth="18rem" spacing="30px" p={15}>
                {
                    props.content?.image ?   
                    <Box>
                        <Image src={image} alt="about" width="100%" my={2}/>
                    </Box>
                    : null
                }
                <Box>
                    <Text as="h2" fontSize="2rem" fontWeight="bold" mb="10px">
                        {title}
                    </Text>
                    <Text>
                        {text}
                    </Text>
                </Box>
            </SimpleGrid>
        </section>
    );
}

export default About;