import React, { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { config } from '../../config'
import { Flex, Heading, Image, FormControl, FormLabel, Text } from '@chakra-ui/react';
import TableComponent from './Table';
import DatePickerComponent from './DatePickerComponent';
import useDomain from '../../service/DomainHook';

const TopViewedProducts = ({ styles }) => {
    const baseURL = `${config.baseUrl}/api/v1`;
    const [hasSubDomain] = useDomain();

    const [topViewData, setTopViewData] = useState([

    ]);
    const franchiseeId = localStorage.getItem('franchisee');
    const token = localStorage.getItem('token');

    const [startDate, setStartDate] = useState(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000));

    const [endDate, setEndDate] = useState(new Date());

    const formatDate = (date) => {
        return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    };


    const handleSubmit = () => {
        const payload = {
            franchiseeId: franchiseeId,
            fromDate: franchiseeId ? formatDate(startDate) : null,
            toDate: franchiseeId ? formatDate(endDate) : null
        };

        axios({
            url: `/products/topViewedProduct`,
            method: 'post',
            headers: { 'Authorization': `Bearer ${token}` },
            baseURL: baseURL,
            data: payload

        }).then((res) => {
            const serverData = res.data.data;
            setTopViewData(serverData);
        }).catch((err) => {
            console.log(err.message);
        })
    }

    useEffect(() => {
        handleSubmit();
    }, [startDate, endDate])


    const columns = useMemo(() => [
        {
            Header: 'Image',
            accessor: '_id.images',
            Cell: props => {
                let data = props.row.original._id?.images
                let index = data?.links?.length > 1 ? data?.defaultImage - 1 : 0
                return (
                    <Image src={data?.links[index] ? data?.links[index] : '/productImage.jpg'} alt='product image'  width="60px" rounded="8px" objectFit='cover' />

                );
            }

        },

        {
            Header: 'Name',
            accessor: '_id.name'
        },
        {
            Header: 'Count',
            accessor: 'viewCount',
            Cell: props => (
                <Text as={"h6"} style={{ textAlign: 'center', marginRight: '25px' }} >
                    {props.value}
                </Text>
            )
        },
        {
            Header: 'Price',
            accessor: '_id.priceType',

            Cell: props => {
                let data = props.row.original
                return (
                    <>
                        {
                            data._id?.priceType === 'FIXED' ? <span>${parseFloat(data._id.price).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span> :
                                <span>
                                    ${parseFloat(data._id?.minPrice).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    -${parseFloat(data._id?.maxPrice).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                </span>
                        }
                    </>

                );
            }
        }

    ], []);

    const data = useMemo(() => (topViewData), [topViewData]);

    return (
        <>
            <Flex flexDirection="column" {...styles} width={["100%", "100%", "50%", "50%"]}>
                <Heading size="md" textAlign="center" >Top Viewed Products</Heading>

                {
                    hasSubDomain ?
                        <Flex justifyContent="flex-end" align='center' mt={2}>
                            <FormControl flex="1 1">
                                <Flex justifyContent="flex-end">
                                    <FormLabel alignSelf="flex-end" m={0} px="10px">From Date:</FormLabel>
                                    <DatePickerComponent startDate={startDate} setStartDate={setStartDate} />
                                </Flex>
                            </FormControl>
                            <FormControl flex="1 1">
                                <Flex justifyContent="flex-end">
                                    <FormLabel alignSelf="flex-end" m={0} px="10px">To Date:</FormLabel>
                                    <DatePickerComponent startDate={endDate} setStartDate={setEndDate} />
                                </Flex>
                            </FormControl>
                        </Flex>
                        : null
                }
                <TableComponent columns={columns} data={data} title='' />
            </Flex>
        </>

    )
}

export default TopViewedProducts;