import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import { useParams, useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone';
import { config } from '../../config';
import { getCategories } from '../../service/Category';
import { getBusiness } from '../../service/Business';
import { getProduct } from '../../service/Product';
import { getTags } from '../../service/Tag';

import {
    Container, Box, Text, FormControl,
    FormLabel, Input, Textarea, SimpleGrid,
    Stack, Checkbox, Button, Image, Tooltip,
    useToast, Badge, Flex, Spacer, useDisclosure,
    Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalBody, ModalCloseButton
} from '@chakra-ui/react'
import { CheckIcon, SmallCloseIcon } from '@chakra-ui/icons'
import ReactSelectOptionMapper from '../../service/ReactSelect';
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DeleteComponent from '../../components/delete/DeleteComponent';
import { editorFontList } from '../../service/utils/FontList';

const ProductUpdate = (props) => {
    const { productSlug } = useParams();
    const toast = useToast();
    const history = useHistory();

    const [product, setProduct] = useState({});
    const [productId, setProductId] = useState({});
    const [categories, setCategories] = useState([]);
    const [business, setBusiness] = useState([]);
    const [tags, setTags] = useState([]);
    const [images, setImages] = useState([]);
    const [defaultIndex, setDefaultIndex] = useState(1);
    const [deletedImage, setDeletedImage] = useState([]);
    const [deleteImageIndex, setDeleteImageIndex] = useState('');
    const [files, setFiles] = useState([]);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(
        ContentState.createFromBlockArray(
            convertFromHTML(props?.location?.state)
        )
    ))

    const baseUrl = config.baseUrl;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure();

    const { control, register, handleSubmit, reset, watch, getValues, setValue } = useForm();

    const watchPriceType = watch("priceType", product.priceType);

    const franchiseeId = localStorage.getItem('franchiseeId');

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    let productUploadRef = useRef(false);
    useEffect(() => {
        if (productUploadRef.current && files.length > 0) {
            // Make sure to revoke the data uris to avoid memory leaks 
            files.forEach(file => URL.revokeObjectURL(file.preview));

            const formData = new FormData();
            files.forEach(file => {
                formData.append('images', file);
            })

            const token = localStorage.getItem('token');
            axios({
                url: `/api/v1/products/${productId}`,
                method: 'post',
                baseURL: baseUrl,
                headers: { 'Authorization': `Bearer ${token}` },
                data: formData
            })
                .then(response => {
                    setImages(response?.data?.data?.images?.links);
                    toast({
                        title: "Product images uploaded successfully",
                        status: "success",
                        duration: 9000,
                        isClosable: true
                    })
                    setFiles([]);
                })
                .catch(error => console.log(error));
        }
        else {
            productUploadRef.current = true;
        }
    }, [files])


    useEffect(async () => {
        const productDetails = await getProduct({ productSlug: productSlug });
        setProductId(productDetails?.data?._id);

        if (!productDetails.data) {
            productDetails.data = {}
        }

        if (Object.keys(productDetails.data).length > 0) {
            productDetails.data.tags = productDetails.data?.tags?.map(tag => {
                return {
                    label: tag?.name,
                    value: tag?._id
                }
            })

            productDetails.data.categories = productDetails.data?.categories?.map(category => {
                return {
                    label: category?.name,
                    value: category?._id
                }
            })

            productDetails.data.business = {
                label: productDetails.data?.businessId?.name,
                value: productDetails.data?.businessId?._id
            }
        }

        let price = productDetails.data.price?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })
        productDetails.data.price = price.replaceAll(',', '');


        setProduct(productDetails.data);
        setImages(productDetails.data?.images?.links);
        setDefaultIndex(productDetails.data?.images?.defaultImage);
        setValue('publish', productDetails.data?.publish);

        const business = await getBusiness({ franchiseeId: franchiseeId });
        const businessOptions = ReactSelectOptionMapper(business.data);
        setBusiness(businessOptions);

        const categories = await getCategories();
        const categoryOptions = ReactSelectOptionMapper(categories.data);
        setCategories(categoryOptions);

        let url = franchiseeId !== 'null'
            ? `?adminType=CA&franchiseeId=${franchiseeId}&tagType=PRODUCT`
            : '?adminType=SA&tagType=PRODUCT';

        const tags = await getTags(url);
        const tagOptions = ReactSelectOptionMapper(tags.data);
        setTags(tagOptions);
    }, [])

    useEffect(() => {
        reset(product);
    }, [product])

    const deleteImage = () => { 
        if (images.length > 1) { 
            let deletedImageLink = images[deleteImageIndex]; 
            const newImages = images.filter((item, i) => i !== deleteImageIndex); 
            const removedImage = [...deletedImage]; 
            removedImage.push(images[deleteImageIndex]); 
            setDeletedImage(removedImage); 
            setImages(newImages); 

            const token = localStorage.getItem('token'); 
            axios({ 
                url: `/api/v1/products/removeImage/${product?._id}`, 
                method: 'post', 
                baseURL: baseUrl, 
                headers: { 'Authorization': `Bearer ${token}` }, 
                data: { link: deletedImageLink } 
            }) 
            .then(res => { 
                toast({ 
                    title: "Image has been removed",
                    status: "success",
                    duration: 3000,
                    isClosable: true 
                }) 
            }) 
            .catch( err => console.log(err))
        } 
        else { 
            toast({
                title: "Sorry you have only one image to show",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }
    } 

    const getDeleteApi = () => { 
        let url = `/products/${product?._id}`; 
        return url; 
    } 

    const setDefaultImage = (index) => {
        index = ++index;
        setDefaultIndex(index);
        let position = '';
        switch (index) {
            case 1: position = 'st'
                break;
            case 2: position = 'nd'
                break;
            case 3: position = 'rd'
                break;
            default: position = 'th'
                break;
        }
        toast({
            title: `${index}${position} image has been set as default`,
            status: "success",
            duration: 3000,
            isClosable: true
        })
    } 

    const submitForm = (data) => {
        const token = localStorage.getItem('token');
        const franchisee = localStorage.getItem('franchiseeId');
        data.images = { 
            defaultImage: defaultIndex || 0,
            links: []
        } 


        data.categories = data.categories?.map(data => data.value)
        data.details = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        data.tags = data.tags?.map(data => data.value)
        data.businessId = data.business.value
        data.franchiseeId = franchisee

        axios({
            url: `/api/v1/products/${product._id}`,
            method: 'patch',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` },
            data: data
        })
            .then(res => {
                if (deletedImage.length > 1) {
                    const deleteImages = {
                        links: deletedImage
                    }
                    axios({
                        url: `/api/v1/products/${product._id}`,
                        method: 'delete',
                        baseURL: baseUrl,
                        headers: { 'Authorization': `Bearer ${token}` },
                        data: deleteImages
                    })
                    .then(response => {
                    
                    })
                    .catch(error => {
                        console.log(error)
                    })
                } 
                toast({
                    title: "Product update successfully",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                })
                history.push(`/admin/product`);
            })
            .catch(error => {
                console.log(error);
            }) 
    }

    return (
        <Container maxWidth={1100} centerContent py={6}>
            <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg" id="Product">
                <Breadcrumb pathname="Product" nameOrTitle={getValues('name')} mb={5} link={`/admin/product`} />
                <form onSubmit={handleSubmit(submitForm)}>
                    <FormControl id="name" mb={4}>
                        <FormLabel>Name</FormLabel>
                        <Input
                            borderColor="gray.300"
                            type="text"
                            ref={register}
                            name="name"
                            variant="flushed" size="sm"
                        />
                    </FormControl>
                    
                    <FormControl id="summary" mb={4}>
                        <FormLabel>Short Description</FormLabel>
                        <Textarea
                            borderColor="gray.300"
                            ref={register}
                            name="summary"
                            variant="flushed" size="sm"
                        />
                    </FormControl>

                    <FormControl mb={4}>
                        {/* <FormLabel>Upload product images</FormLabel> */}
                        <Box
                            // p={4} 
                            lineHeight="60px"
                            border="1px dashed #BDBDBD">
                            <Box {...getRootProps({ className: 'dropzone' })}>
                                <Input {...getInputProps()} type="file" />
                                <Text
                                    textAlign="center"
                                    p="10px" cursor="pointer"
                                    // color="gray.500"
                                >
                                    Click to upload images
                                </Text>
                            </Box>
                            {/* { 
                                thumbs.length > 0 && 
                                <Box style={thumbsContainer}> 
                                    {thumbs} 
                                </Box>  
                            }  */}
                        </Box>
                    </FormControl>

                    <Box mb={4}>
                        {/* <Text>Product Images</Text>  */}
                        <FormLabel>Product Images</FormLabel>
                        {/* <Stack direction={["column", "row"]}> */}
                        <SimpleGrid columns={[1, 1, 3, 5]} spacing="10px">
                            {
                                images?.map((image, index) =>
                                    <Box
                                        p={2} rounded={8} border="1px solid #BDBDBD"
                                        cursor="pointer" key={image}>
                                        <Flex direction="column" h="100%">
                                            <Box mb={2} h="60%">
                                                <Image
                                                    boxSize="200px"
                                                    // maxH="200px" 
                                                    // height="100%"
                                                    objectFit="cover"
                                                    src={image}
                                                    rounded={4}
                                                    alt={product.name}
                                                />
                                            </Box>

                                            <Spacer />

                                            <SimpleGrid minChildWidth="50px" spacing="4px">
                                                <Tooltip label="Click to set as default" fontSize="sm">
                                                    <Box
                                                        textAlign="center" bg="gray.200" rounded={2}
                                                        onClick={() => setDefaultImage(index)}>
                                                        <CheckIcon w={3} h={3} color="green.600" />
                                                    </Box>
                                                </Tooltip>
                                                <Tooltip label="Click to delete" fontSize="sm">
                                                    <Box
                                                        textAlign="center" bg="gray.200" rounded={2}
                                                        onClick={(e) => { 
                                                            onOpen(); 
                                                            setDeleteImageIndex(index); 
                                                        }}> 
                                                        <SmallCloseIcon color="red.300" />
                                                    </Box>
                                                </Tooltip>
                                            </SimpleGrid>
                                        </Flex>
                                    </Box>
                                )
                            }
                        </SimpleGrid>
                        {/* </Stack> */}
                    </Box>

                    <span fontSize="0.8rem">
                        Press &nbsp;
                        <Badge colorScheme="red">Shift</Badge>
                        &nbsp; + &nbsp;
                        <Badge colorScheme="red">Enter</Badge>
                        , to take new line
                    </span>

                    <FormControl mb="15px" mt="20px">
                        <FormLabel m={0}>Description</FormLabel>
                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName rich-editor"
                            onEditorStateChange={(editorState) => setEditorState(editorState)}
                            toolbar={{
                                fontFamily: {
                                    options: editorFontList,
                                    className: undefined,
                                    component: undefined,
                                    dropdownClassName: undefined,
                                },
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                                image: {
                                    defaultSize: {
                                        height: '250px',
                                        width: 'auto'
                                    }
                                }
                            }}
                        />
                    </FormControl>

                    <SimpleGrid minChildWidth="120px" spacing="40px">
                        <Box mb={4}>
                            <FormControl id="categories">
                                <FormLabel>Category</FormLabel>
                                <Controller
                                    control={control}
                                    name="categories"
                                    as={Select}
                                    isMulti
                                    options={categories}
                                    defaultValue=""
                                />
                            </FormControl>
                        </Box>

                        <Box mb={4}>
                            <FormControl id="business">
                                <FormLabel>Business</FormLabel>
                                <Controller
                                    control={control}
                                    name="business"
                                    as={Select}
                                    options={business}
                                    defaultValue=""
                                />
                            </FormControl>
                        </Box>
                    </SimpleGrid>

                    <Box mb={4}>
                        <FormControl id="tags">
                            <FormLabel>Tags</FormLabel>
                            <Controller
                                control={control}
                                name="tags"
                                as={Select}
                                isMulti
                                options={tags}
                                defaultValue=""
                            />
                        </FormControl>
                    </Box>

                    <Box mb={4}>
                        <FormControl as="fieldset">
                            <Box my={2}>
                                <input type="radio" id="fixed" name="priceType" value="FIXED" ref={register} />
                                <label style={{ marginLeft: '5px', marginRight: '20px' }} htmlFor="fixed">Fixed</label>
                                <input type="radio" id="ranged" name="priceType" value="RANGED" ref={register} />
                                <label style={{ marginLeft: '5px' }} htmlFor="ranged">Ranged</label>
                            </Box>
                        </FormControl>

                        {
                            watchPriceType === 'RANGED'
                                ?
                                <SimpleGrid minChildWidth="18rem" spacing={{ base: "5px", sm: "10px", md: "15px", lg: "30px", xl: "30px" }}>
                                    <Box>
                                        <Stack direction={["column", "row"]}>
                                            <Text w={{ base: "100%", sm: "100%", md: "40%", lg: "30%", xl: "30%" }}>Min price</Text>
                                            <FormControl w={{ base: "100%", sm: "100%", md: "60%", lg: "70%", xl: "70%" }} id="minPrice">
                                                <Input type="text" ref={register} name="minPrice" variant="flushed" size="sm" borderColor="gray.300" />
                                            </FormControl>
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Stack direction={["column", "row"]}>
                                            <Text w={{ base: "100%", sm: "100%", md: "40%", lg: "30%", xl: "30%" }}>Max price</Text>
                                            <FormControl w={{ base: "100%", sm: "100%", md: "60%", lg: "70%", xl: "70%" }} id="maxPrice">
                                                <Input type="text" ref={register} name="maxPrice" variant="flushed" size="sm" borderColor="gray.300" />
                                            </FormControl>
                                        </Stack>
                                    </Box>
                                </SimpleGrid>
                                : <Box>
                                    <Stack direction={["column", "row"]}>
                                        <Text w={{ base: "100%", sm: "100%", md: "25%", lg: "20%", xl: "20%" }}>Price</Text>
                                        <FormControl w={{ base: "100%", sm: "100%", md: "75%", lg: "80%", xl: "80%" }} id="price">
                                            <Input type="text" ref={register} name="price" variant="flushed" size="sm" borderColor="gray.300" />
                                        </FormControl>
                                    </Stack>
                                </Box>
                        }

                    </Box>

                    <Box mb={4}>
                        <FormControl as="fieldset">
                            <Stack spacing={10} direction="row">
                                {/* <Controller
                                    control={control}
                                    name="isGreeting"
                                    defaultValue={false}
                                    render={(props) => (
                                        <Checkbox 
                                            borderColor="gray.400"
                                            onChange={(e) => props.onChange(e.target.checked)}
                                            isChecked={props.value}> Is greeting
                                        </Checkbox>
                                    )}
                                /> */}

                                <Controller
                                    control={control}
                                    name="publish"
                                    defaultValue={false}
                                    render={(props) => (
                                        <Checkbox
                                            borderColor="gray.400"
                                            onChange={(e) => props.onChange(e.target.checked)}
                                            isChecked={props.value}>
                                            Publish
                                        </Checkbox>
                                    )}
                                />
                            </Stack>
                        </FormControl>
                    </Box>


                    <Button colorScheme="red" variant="outline" size="sm" mr={3} onClick={deleteOnOpen} rounded={4}> 
                        Delete 
                    </Button> 
                    <Button type="submit" colorScheme="teal" variant="outline" size="sm">Save</Button> 
                </form> 
            </Box> 


            {/* Image delete confirm box */}
            <Modal isOpen={isOpen} onClose={onClose} isCentered> 
                <ModalOverlay /> 
                <ModalContent m={5}> 
                    <ModalHeader>Delete Image</ModalHeader> 
                    <ModalCloseButton /> 
                    <ModalBody> 
                        <Text> 
                            Are you sure, you want to delete? 
                        </Text> 
                        <Box my={3}> 
                            <Button 
                                size="sm" colorScheme="red" variant="outline" 
                                onClick={() => { 
                                    onClose(); 
                                    deleteImage(); 
                                }}> 
                                Yes 
                            </Button> 
                            <Button 
                                size="sm" ml={3} colorScheme="teal" variant="outline" 
                                onClick={() => onClose()}> 
                                No 
                            </Button> 
                        </Box> 
                    </ModalBody> 
                </ModalContent> 
            </Modal> 
            
            
            {/* Delete Product */} 
            <DeleteComponent 
                headerName="Product"
                isOpen={deleteIsOpen} onClose={deleteOnClose} 
                getDeleteApi={getDeleteApi} 
                routeOnDelete='/admin/product' 
                showBackendMsg={false} 
                toastSuccessMsgTitle="Product deleted successfully" 
                // toastErrMsgTitle="Cannot delete the shipping method" 
            /> 
        </Container> 
    ) 
} 

export default ProductUpdate
