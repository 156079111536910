import React, { useState } from 'react';
import {
    Container, Box, FormControl,
    FormLabel, Input, Button, FormErrorMessage,
    SimpleGrid, useToast, InputRightElement,
    InputGroup, InputLeftElement, Image, Textarea
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { config } from '../../config';
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';


function FranchiseeRegister() {
    const { baseUrl, siteURL } = config;
    const toast = useToast();
    const history = useHistory();

    const { register, handleSubmit, errors, watch, getValues, setError, clearErrors } = useForm({
        mode: 'onSubmit',
        shouldFocusError: false,
    })

    let password = watch('password');

    const [previewImage, setPreviewImage] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    function imageHandler(e) {
        // To preview before upload --- Converting to base 64
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setPreviewImage(reader.result)
            }
        }

        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0])
        }
    }

    const subDomainChecker = () => {
        const token = localStorage.getItem('token');
        let domainKey = getValues('domainKey');

        domainKey = domainKey.replaceAll(' ', '-')

        let slug = ''
        let symbolArr = [
            '"', '~', '`', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '+', '=',
            '{', '}', '[', ']', ';', ':', "'", '|', '<', '>', ',', '.', '?', '/'
        ]
        for (let i = 0; i < domainKey.length; i++) {
            if (!symbolArr.includes(domainKey[i]) && domainKey[i] !== '\\') {
                slug = slug + domainKey[i].toLowerCase()
            }
        }

        if (slug) {
            axios({
                url: `/api/v1/franchisees?domainSlug=${slug}&limit=1`,
                method: 'get',
                baseURL: baseUrl,
                headers: { 'Authorization': `Bearer ${token}` }
            })
                .then(({ data }) => {
                    let { result } = data;
                    if (result >= 1) {
                        setError('domainKey', {
                            type: 'duplicate',
                            message: `${domainKey} already exist!`
                        })
                    }
                    else {
                        clearErrors('domainKey')
                    }
                })
                .catch(err => console.log(err))
        }
    }

    const onSubmit = (data) => {
        const token = localStorage.getItem('token');

        // Domain Extraction 
        let domain = data.domain.replace('http://', '');
        domain = domain.replace('https://', '');
        domain = domain.replace('www.', '');
        // data.domain = domain; 

        const formData = new FormData();

        formData.append('name', data.name);
        // formData.append('lastName', data.lastName); 
        formData.append('confirmPassword', data.confirmPassword);
        formData.append('password', data.password);
        formData.append('email', data.email);
        formData.append('domain', domain);
        formData.append('domainKey', data.domainKey);
        if (data.generatedDomain) {
            formData.append('generatedDomain', data.generatedDomain);
        }
        if (data.phone) {
            formData.append('phone', data.phone);
        }
        if (data.address) {
            formData.append('address', data.address);
        }
        if (data.image[0]) {
            formData.append('image', data.image[0])
        }

        axios({
            url: '/api/v1/franchisees/signup',
            method: 'post',
            baseURL: baseUrl,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
            data: formData,
        })
            .then(res => {
                toast({
                    title: "Registration Successful.",
                    description: "You have registered an franchesee account.",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                })
                history.push('/admin/franchisees');
            })
            .catch(err => {
                console.log(err);
                toast({
                    title: "Registration Unsuccessful.",
                    description: "Error occured",
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                })
            })
    }

    return (
        <Container maxWidth={1100} centerContent py={6}>
            <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg" >
                <Breadcrumb pathname="Franchisee" create={true} link={`/admin/franchisees`} mb={5} />
                {/* <Text as="h4" fontSize="1.4rem" mb="20px" fontWeight="600">Register</Text>  */}

                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <SimpleGrid columns={{base: 1, sm: 1, md: 2}} spacing={{base: '10px', md: '20px', lg: '20px'}}>  */}
                    <FormControl mb="15px" isInvalid={errors.name}>
                        <FormLabel m={0}>Name</FormLabel>
                        <Input
                            ref={register({ required: 'Name is required' })}
                            variant="flushed"
                            borderColor="gray.300" size="sm"
                            name="name" type="text" />
                        <FormErrorMessage mb="20px">{errors?.name?.message}</FormErrorMessage>
                    </FormControl>

                    {/* 
                        <FormControl mb="15px" isInvalid={errors.lastName}> 
                            <FormLabel m={0}>Last Name</FormLabel> 
                            <Input 
                                ref={register({ required: 'Last name is required' })} 
                                variant="flushed" 
                                borderColor="gray.300" size="sm" 
                                name="lastName" type="text" /> 
                            <FormErrorMessage mb="20px">{errors.lastName && errors.lastName.message}</FormErrorMessage> 
                        </FormControl>  */}
                    {/* </SimpleGrid>  */}

                    <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing={{ base: '10px', md: '20px', lg: '20px' }}>
                        <FormControl mb="15px" isInvalid={errors.email}>
                            <FormLabel m={0}>Email</FormLabel>
                            <Input
                                ref={register({
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address"
                                    }
                                })}
                                variant="flushed"
                                borderColor="gray.300" size="sm"
                                name="email" type="text" />
                            <FormErrorMessage mb="20px">{errors?.email?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl mb="15px" isInvalid={errors.phone}>
                            <FormLabel m={0}>Phone</FormLabel>
                            <Input
                                ref={register({
                                    // required: 'Phone Number is required',
                                    minLength: {
                                        value: 14,
                                        message: 'Minimum 14 characters'
                                    }
                                })}
                                variant="flushed"
                                borderColor="gray.300" size="sm"
                                name="phone" type="tel" />
                            <FormErrorMessage mb="20px">{errors?.phone?.message}</FormErrorMessage>
                        </FormControl>
                    </SimpleGrid>

                    <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing={{ base: '10px', md: '20px', lg: '20px' }}>
                        <FormControl isInvalid={errors.password}>
                            <FormLabel m={0}>Password</FormLabel>
                            <InputGroup>
                                <Input
                                    type={passwordVisible ? 'text' : 'password'}
                                    ref={register({
                                        required: 'Password is required',
                                        minLength: {
                                            value: 8,
                                            message: 'Minimum 8 characters'
                                        }
                                    })}
                                    variant="flushed"
                                    borderColor="gray.300" size="sm"
                                    name="password"
                                />

                                <InputRightElement cursor="pointer">
                                    {
                                        passwordVisible ?
                                            <ViewOffIcon w={4} h={4} onClick={() => setPasswordVisible(!passwordVisible)} />
                                            :
                                            <ViewIcon w={4} h={4} onClick={() => setPasswordVisible(!passwordVisible)} />
                                    }
                                </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.confirmPassword}>
                            <FormLabel m={0}>Confirm Password</FormLabel>
                            <InputGroup>
                                <Input
                                    type={confirmPasswordVisible ? 'text' : 'password'}
                                    ref={register({
                                        required: 'Confirm Password is required',
                                        minLength: {
                                            value: 8,
                                            message: 'Minimum 8 characters'
                                        },
                                        validate: (value) => value === password || "Password doesn't match"
                                    })}
                                    variant="flushed"
                                    borderColor="gray.300" size="sm"
                                    name="confirmPassword"
                                />

                                <InputRightElement cursor="pointer">
                                    {
                                        confirmPasswordVisible ?
                                            <ViewOffIcon w={4} h={4} onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)} />
                                            :
                                            <ViewIcon w={4} h={4} onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)} />
                                    }
                                </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>{errors.confirmPassword && errors.confirmPassword.message}</FormErrorMessage>
                        </FormControl>
                    </SimpleGrid>

                    {/* Image */}
                    <FormControl mb="15px" mt="15px">
                        <Box>
                            <FormLabel
                                w='100%'
                                h='100%'
                                lineHeight="60px"
                                border="1px dashed #BDBDBD"
                                htmlFor='image' textAlign="center" cursor="pointer"
                                // color="gray.500"
                                p='10px'
                            >
                                Click to upload Image
                            </FormLabel>
                            <Input
                                type="file"
                                name="image"
                                ref={register}
                                id='image'
                                accept="image/*"
                                onChange={(e) => imageHandler(e)}
                                style={{ display: "none" }} />

                        </Box>

                        {previewImage && <Image mt='5px' src={previewImage} alt="Franchisee image" maxH="200px" />}
                    </FormControl>

                    <Box mb="50px"></Box>

                    <FormControl mb="15px" isInvalid={errors.domainKey}>
                        <FormLabel m={0}>Sub domain</FormLabel>
                        <InputGroup>
                            <Input
                                ref={register({ required: 'Sub domain is required' })}
                                variant="flushed"
                                borderColor="gray.300" size="sm"
                                name="domainKey" type="text"
                                onBlur={subDomainChecker}
                            />
                            <InputRightElement
                                pointerEvents="none"
                                minW="15rem"
                                h="1.9rem"
                                bg="gray.200"
                                color="gray.400"
                                fontWeight="500"
                            >
                                {siteURL}
                            </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage mb="20px">{errors.domainKey?.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl mb="15px">
                        <FormLabel m={0}>Generated Domain</FormLabel>
                        <Input
                            ref={register}
                            variant="flushed"
                            borderColor="gray.300" size="sm"
                            isDisabled
                            name="generatedDomain" type="text" />
                    </FormControl>

                    <FormControl mb="15px">
                        <FormLabel m={0}>Custom Domain</FormLabel>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents="none"
                                w="4rem"
                                h="1.9rem"
                                bg="gray.200"
                                color="gray.400"
                                fontWeight="500"
                            >
                                www.
                            </InputLeftElement>
                            <Input
                                ref={register}
                                variant="flushed"
                                borderColor="gray.300" size="sm"
                                pl="4rem"
                                name="domain" type="text"
                            />
                        </InputGroup>
                    </FormControl>

                    <FormControl mb="15px">
                        <FormLabel m={0}>Address</FormLabel>
                        <Textarea
                            ref={register}
                            variant="flushed"
                            borderColor="gray.300" size="sm"
                            name="address" type="text" />
                    </FormControl>

                    <Button type="submit" colorScheme="teal" variant="outline" size="sm">Save</Button>
                </form>
            </Box>
        </Container>
    )
}

export default FranchiseeRegister; 