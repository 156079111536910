import React, { useState, useEffect } from 'react';
import {
    Box, List, ListItem, Flex, chakra, Spacer, Tooltip
} from "@chakra-ui/react";
import { Icon, ChevronDownIcon, HamburgerIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { CgWebsite, CgMenuBoxed } from 'react-icons/cg';
import { MdDashboard } from "react-icons/md";
import { FaBloggerB, FaFileInvoice, FaUsers } from 'react-icons/fa';
import { AiOutlineClose, AiOutlineProfile } from 'react-icons/ai';
import { IoTabletPortraitOutline } from 'react-icons/io5';
import { IoIosDesktop } from 'react-icons/io';
import { ImPinterest2 } from 'react-icons/im';
import { BsFillChatSquareDotsFill } from 'react-icons/bs';
import { FiSettings} from 'react-icons/fi';
import { RiPagesLine, RiAdminLine, RiMoneyDollarBoxFill} from 'react-icons/ri'
import {
    SiPagekit, SiBloglovin, SiNintendo, SiGoogle,
    SiGoogleanalytics,  SiGoogleadsense
} from 'react-icons/si';
import { BsTag } from 'react-icons/bs';
import { TiBusinessCard } from 'react-icons/ti';
// import { SiGooglesearchconsole } from 'react-icons/si'; 
import { FcDataConfiguration } from 'react-icons/fc';
import { VscGroupByRefType, VscSettings } from 'react-icons/vsc';
import { useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import SidenavMenu from './SidenavMenu';
import './Sidenav.css';


function SideNav({ navList, handleNavState, makeNavStateTrue, makeNavStateFalse, url }) {
    let location = useLocation();

    let [groupSiteState, setGroupSiteState] = useState(false);
    let [groupBlogState, setGroupBlogState] = useState(false);
    let [groupAdminSettingState, setGroupAdminSettingState] = useState(false);
    let [groupOperationState, setGroupOperationState] = useState(false);
    let [groupConfigurationState, setGroupConfigurationState] = useState(false);


    useEffect(() => {
        setGroupSiteState(false);
        setGroupBlogState(false);
        setGroupAdminSettingState(false);
        setGroupOperationState(false);
        setGroupConfigurationState(false);
    }, [navList]);


    const navbarOffInMobileViewHandler = () => {
        if (window.innerWidth <= 600) {
            handleNavState()
        }
    }


    const subMenuStateHandler = (state, setState) => {
        if (window.innerWidth >= 991) {
            if (navList) {
                setState(!state)
            }
            else {
                makeNavStateTrue()
                setTimeout(() => {
                    setState(true)
                }, 0)
            }
        }
        else if (window.innerWidth > 600) {
            if (navList) {
                makeNavStateFalse()
                setTimeout(() => {
                    setState(true)
                }, 0)
            }
            else {
                setState(!state)
            }
        }
        else {
            setState(!state)
        }
    }



    const role = localStorage.getItem('role');

    let path = location.pathname.split('/');
    path.shift();

    let mainPath;
    if (path.length === 1) {
        mainPath = path[path.length - 1]
    }
    else if (path.length === 2) {
        mainPath = path[path.length - 1]
    }
    else if (path.length === 3) {
        mainPath = path[path.length - 2]
    }
    else if (path.length === 4) {
        mainPath = path[path.length - 3]
    }


    return (
        <Box>
            <List
                className={`navigation ${navList ? 'active' : ''}`}
                bg="#2D3748" color="white" boxSizing="border-box" fontSize="15px">
                <Flex flexDir="column">
                    <Scrollbars
                        style={{ height: window.innerWidth > 600 ? `${(window.innerHeight - 50)}px` : '99vh' }}
                        autoHide
                        autoHideTimeout={500}
                        autoHideDuration={200}
                        renderThumbHorizontal={() => (<div style={{ background: '#718096', borderRadius: '6px' }} />)}
                        renderThumbVertical={() => (<div style={{ background: '#718096', borderRadius: '6px' }} />)}>
                        {/* Dashboard */}
                        {/* Menu item class for hover background */}
                        <Tooltip
                            label="Dashboard"
                            placement="right-end"
                            hasArrow
                            isDisabled={window.innerWidth >= 991 && navList && true}
                            closeOnClick={false}>
                            <ListItem className="menuItem">
                                <SidenavMenu
                                    menuName="Dashboard"
                                    navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                    menu2={false}
                                    url={url}
                                    icon={MdDashboard}
                                    mainPath={mainPath}
                                    matchPath="admin"
                                />
                            </ListItem>
                        </Tooltip>


                        {/* Site group */}
                        <ListItem className="menuItem">
                            <Tooltip
                                label="Sites"
                                placement="right-end"
                                hasArrow
                                isDisabled={window.innerWidth >= 991 && navList && true}
                                closeOnClick={false}>
                                <Flex w="100%" cursor="pointer"
                                    // onClick={siteState} 
                                    onClick={() => subMenuStateHandler(groupSiteState, setGroupSiteState)}>
                                    <span className="icon" style={{ marginTop: '-3px' }}>
                                        <Icon as={CgWebsite} w={5} h={5} />
                                    </span>
                                    <span className="title">
                                        Sites
                                    </span>
                                    <chakra.span className="icon" ml="auto" mt="-3px">
                                        {
                                            groupSiteState
                                                ? <Icon as={ChevronDownIcon} w={5} h={5} />
                                                : <Icon as={ChevronRightIcon} w={5} h={5} />
                                        }
                                    </chakra.span>
                                </Flex>
                            </Tooltip>
                            {
                                groupSiteState &&
                                <>
                                    <SidenavMenu
                                        menuName="Page"
                                        navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                        menu2
                                        url={`${url}/page`}
                                        icon={RiPagesLine}
                                        mainPath={mainPath}
                                        matchPath="page"
                                    />

                                    <SidenavMenu
                                        menuName="Menu"
                                        navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                        menu2
                                        url={`${url}/menu`}
                                        icon={CgMenuBoxed}
                                        mainPath={mainPath}
                                        matchPath="menu"
                                    />

                                    <SidenavMenu
                                        menuName="Footer"
                                        navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                        menu2
                                        url={`${url}/footer`}
                                        icon={SiNintendo}
                                        mainPath={mainPath}
                                        matchPath="footer"
                                    />

                                    <SidenavMenu
                                        menuName="Landing Page"
                                        navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                        menu2
                                        url={`${url}/landing-page`}
                                        icon={SiPagekit}
                                        mainPath={mainPath}
                                        matchPath="landing-page"
                                    />

                                    {
                                        role === 'SUPERADMIN' ? (
                                            <SidenavMenu
                                                menuName="Global Setting"
                                                navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                                menu2
                                                url={`${url}/global-setting`}
                                                icon={FiSettings}
                                                mainPath={mainPath}
                                                matchPath="global-setting"
                                            />
                                        ) : (
                                            <>
                                                <SidenavMenu
                                                    menuName="Global Setting"
                                                    navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                                    menu2
                                                    url={`${url}/global`}
                                                    icon={FiSettings}
                                                    mainPath={mainPath}
                                                    matchPath="global"
                                                />

                                                {/* <SidenavMenu
                                                    menuName="Shop Setting"
                                                    navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                                    menu2
                                                    url={`${url}/shop-setting`}
                                                    icon={SiPagekit}
                                                    mainPath={mainPath}
                                                    matchPath="shop-setting"
                                                /> */}
                                            </>
                                        )
                                    }

                                </>
                            }
                        </ListItem>


                        {/* Blog group */}
                        <ListItem className="menuItem">
                            <Tooltip
                                label="Blog"
                                placement="right-end"
                                hasArrow
                                isDisabled={window.innerWidth >= 991 && navList && true}
                                closeOnClick={false}>
                                <Flex w="100%" cursor="pointer"
                                    // onClick={blogState} 
                                    onClick={() => subMenuStateHandler(groupBlogState, setGroupBlogState)}>
                                    <span className="icon" style={{ marginTop: '-3px' }}>
                                        <Icon as={FaBloggerB} w={5} h={5} />
                                    </span>
                                    <span className="title">
                                        Blog
                                    </span>
                                    <chakra.span className="icon" ml="auto" mt="-3px">
                                        {
                                            groupBlogState
                                                ? <Icon as={ChevronDownIcon} w={5} h={5} />
                                                : <Icon as={ChevronRightIcon} w={5} h={5} />
                                        }
                                    </chakra.span>
                                </Flex>
                            </Tooltip>

                            {
                                groupBlogState &&
                                <>
                                    {/* Tag */}
                                    <SidenavMenu
                                        menuName="Tag"
                                        navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                        menu2
                                        url={`${url}/tag`}
                                        icon={BsTag}
                                        mainPath={mainPath}
                                        matchPath="tag"
                                    />


                                    {/* Blog */}
                                    <SidenavMenu
                                        menuName="Post"
                                        navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                        menu2
                                        url={`${url}/blog`}
                                        icon={SiBloglovin}
                                        mainPath={mainPath}
                                        matchPath="blog"
                                    />
                                </>
                            }
                        </ListItem>


                        {
                            role === 'SUPERADMIN'
                                ?
                                <>
                                    <ListItem className="menuItem">
                                        <Tooltip
                                            label="Admin Setting"
                                            placement="right-end"
                                            hasArrow
                                            isDisabled={window.innerWidth >= 991 && navList && true}
                                            closeOnClick={false}>
                                            <Flex w="100%" cursor="pointer"
                                                // onClick={adminSettingState} 
                                                onClick={() => subMenuStateHandler(groupAdminSettingState, setGroupAdminSettingState)}>
                                                <span className="icon" style={{ marginTop: '-3px' }}>
                                                    <Icon as={FiSettings} w={5} h={5} />
                                                </span>
                                                <chakra.span className="title" overflow={`${window.innerWidth >= 600 && 'hidden'}`}>
                                                    Admin setting
                                                </chakra.span>
                                                <chakra.span className="icon" ml="auto" mt="-3px">
                                                    {
                                                        groupAdminSettingState
                                                            ? <Icon as={ChevronDownIcon} w={5} h={5} />
                                                            : <Icon as={ChevronRightIcon} w={5} h={5} />
                                                    }
                                                </chakra.span>
                                            </Flex>
                                        </Tooltip>
                                        {
                                            groupAdminSettingState &&
                                            <>
                                                {/* Category */}
                                                {/* <SidenavMenu 
                                                menuName="Category" 
                                                navbarOffInMobileViewHandler={navbarOffInMobileViewHandler} 
                                                menu2 
                                                url={`${url}/category`} 
                                                icon={VscGroupByRefType} 
                                                mainPath={mainPath} 
                                                matchPath="category" 
                                            />   */}

                                                {/* Franchisees */}
                                                <SidenavMenu
                                                    menuName="Franchisees"
                                                    navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                                    menu2
                                                    url={`${url}/franchisees`}
                                                    icon={RiAdminLine}
                                                    mainPath={mainPath}
                                                    matchPath="franchisees"
                                                />

                                                {/* Bussiness Type */}
                                                {/* <SidenavMenu
                                                    menuName="Business Type"
                                                    navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                                    menu2
                                                    url={`${url}/business-type`}
                                                    icon={FiType}
                                                    mainPath={mainPath}
                                                    matchPath="business-type"
                                                /> */}

                                                {/* Bussiness */}
                                                {/* <SidenavMenu
                                                    menuName="Business"
                                                    navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                                    menu2
                                                    url={`${url}/business`}
                                                    icon={TiBusinessCard}
                                                    mainPath={mainPath}
                                                    matchPath="business"
                                                /> */}
                                                <SidenavMenu
                                                    menuName="Users"
                                                    navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                                    menu2
                                                    url={`${url}/users`}
                                                    icon={FaUsers}
                                                    mainPath={mainPath}
                                                    matchPath="users"
                                                />

                                                {/* Coupon */}
                                                {/* <SidenavMenu 
                                                menuName="Coupon" 
                                                navbarOffInMobileViewHandler={navbarOffInMobileViewHandler} 
                                                menu2 
                                                url={`${url}/coupon`} 
                                                icon={FaFileInvoice} 
                                                mainPath={mainPath} 
                                                matchPath="coupon" 
                                            />  */}

                                                {/* Greeting Card */}
                                                {/* <SidenavMenu 
                                                menuName="Greeting Card" 
                                                navbarOffInMobileViewHandler={navbarOffInMobileViewHandler} 
                                                menu2 
                                                url={`${url}/greeting-card`} 
                                                icon={AiOutlineProfile} 
                                                mainPath={mainPath} 
                                                matchPath="greeting-card" 
                                            />  */}
                                            </>
                                        }
                                    </ListItem>
                                </>
                                :
                                <>
                                    <ListItem className="menuItem">
                                        <Tooltip
                                            label="Operation"
                                            placement="right-end"
                                            hasArrow
                                            isDisabled={window.innerWidth >= 991 && navList && true}
                                            closeOnClick={false}>
                                            <Flex w="100%" cursor="pointer"
                                                // onClick={businessState} 
                                                onClick={() => subMenuStateHandler(groupOperationState, setGroupOperationState)}>
                                                <span className="icon" style={{ marginTop: '-3px' }}>
                                                    <Icon as={FiSettings} w={5} h={5} />
                                                </span>
                                                <span className="title">
                                                    Operation
                                                </span>
                                                <chakra.span className="icon" ml="auto" mt="-3px">
                                                    {
                                                        groupOperationState
                                                            ? <Icon as={ChevronDownIcon} w={5} h={5} />
                                                            : <Icon as={ChevronRightIcon} w={5} h={5} />
                                                    }
                                                </chakra.span>
                                            </Flex>
                                        </Tooltip>

                                        {
                                            groupOperationState &&
                                            <>
                                                {/* Category */}
                                                <SidenavMenu
                                                    menuName="Category"
                                                    navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                                    menu2
                                                    url={`${url}/category`}
                                                    icon={VscGroupByRefType}
                                                    mainPath={mainPath}
                                                    matchPath="category"
                                                />

                                                {/* Bussiness Type */}
                                                {/* <SidenavMenu
                                                    menuName="Business Type"
                                                    navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                                    menu2
                                                    url={`${url}/business-type`}
                                                    icon={FiType}
                                                    mainPath={mainPath}
                                                    matchPath="business-type"
                                                /> */}

                                                {/* Bussiness */}
                                                <SidenavMenu
                                                    menuName="Business"
                                                    navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                                    menu2
                                                    url={`${url}/business`}
                                                    icon={TiBusinessCard}
                                                    mainPath={mainPath}
                                                    matchPath="business"
                                                />

                                                {/* Product */}
                                                {/* <SidenavMenu 
                                                menuName="Product" 
                                                navbarOffInMobileViewHandler={navbarOffInMobileViewHandler} 
                                                menu2 
                                                url={`${url}/product`} 
                                                icon={FaProductHunt} 
                                                mainPath={mainPath} 
                                                matchPath="product" 
                                            />  */}

                                                {/* Coupon */}
                                                <SidenavMenu
                                                    menuName="Coupon"
                                                    navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                                    menu2
                                                    url={`${url}/coupon`}
                                                    icon={FaFileInvoice}
                                                    mainPath={mainPath}
                                                    matchPath="coupon"
                                                />


                                                {/* Order */}
                                                <SidenavMenu
                                                    menuName="Order"
                                                    navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                                    menu2
                                                    url={`${url}/order`}
                                                    icon={FaFileInvoice}
                                                    mainPath={mainPath}
                                                    matchPath="order"
                                                />

                                                {/* Users */}
                                                <SidenavMenu
                                                    menuName="Users"
                                                    navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                                    menu2
                                                    url={`${url}/users`}
                                                    icon={FaUsers}
                                                    mainPath={mainPath}
                                                    matchPath="users"
                                                />

                                                {/* Shipping Method */}
                                                <SidenavMenu
                                                    menuName="Email Subscribers"
                                                    navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                                    menu2
                                                    url={`${url}/email-subscribers`}
                                                    icon={FaFileInvoice}
                                                    mainPath={mainPath}
                                                    matchPath="email-subscribers"
                                                />

                                                {/* Shipping Method */}
                                                <SidenavMenu
                                                    menuName="Shipping Method"
                                                    navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                                    menu2
                                                    url={`${url}/shipping-method`}
                                                    icon={FaFileInvoice}
                                                    mainPath={mainPath}
                                                    matchPath="shipping-method"
                                                />

                                                {/* Greeting Card */}
                                                <SidenavMenu
                                                    menuName="Greeting Card"
                                                    navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                                    menu2
                                                    url={`${url}/greeting-card`}
                                                    icon={AiOutlineProfile}
                                                    mainPath={mainPath}
                                                    matchPath="greeting-card"
                                                />
                                            </>
                                        }
                                    </ListItem>
                                </>
                        }

                        {/* Configuration */}
                        <ListItem className="menuItem">
                            <Tooltip
                                label="Configuration"
                                placement="right-end"
                                hasArrow
                                isDisabled={window.innerWidth >= 991 && navList && true}
                                closeOnClick={false}>
                                <Flex w="100%" cursor="pointer"
                                    // onClick={blogState} 
                                    onClick={() => subMenuStateHandler(groupConfigurationState, setGroupConfigurationState)}>
                                    <span className="icon" style={{ marginTop: '-3px' }}>
                                        <Icon as={FcDataConfiguration} w={5} h={5} />
                                    </span>
                                    <span className="title">
                                        Configuration
                                    </span>
                                    <chakra.span className="icon" ml="auto" mt="-3px">
                                        {
                                            groupConfigurationState
                                                ? <Icon as={ChevronDownIcon} w={5} h={5} />
                                                : <Icon as={ChevronRightIcon} w={5} h={5} />
                                        }
                                    </chakra.span>
                                </Flex>
                            </Tooltip>

                            {
                                groupConfigurationState &&
                                <>
                                    {/* Chat config */}
                                    <SidenavMenu
                                        menuName="Chat Config"
                                        navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                        menu2
                                        url={`${url}/chat-config`}
                                        icon={BsFillChatSquareDotsFill}
                                        mainPath={mainPath}
                                        matchPath="chat-config"
                                    />

                                    {/* Google Analytics */}
                                    <SidenavMenu
                                        menuName="Google Analytics"
                                        navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                        menu2
                                        url={`${url}/google-analytics`}
                                        icon={SiGoogleanalytics}
                                        mainPath={mainPath}
                                        matchPath="google-analytics"
                                    />

                                    {/* Mail Config */}
                                    {
                                        (role === 'SUPERADMIN' || role === 'CITYADMIN' ) &&
                                        <SidenavMenu
                                            menuName="Mail Config"
                                            navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                            menu2
                                            url={`${url}/mail-config`}
                                            icon={VscSettings}
                                            mainPath={mainPath}
                                            matchPath="mail-config"
                                        />
                                    }

                                    {
                                        (role === 'SUPERADMIN' || role === 'CITYADMIN' ) &&
                                        <SidenavMenu
                                            menuName="Payment Config"
                                            navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                            menu2
                                            url={`${url}/payment-config`}
                                            icon={RiMoneyDollarBoxFill}
                                            mainPath={mainPath}
                                            matchPath="payment-config"
                                        />
                                    }

                                    {/* Google Search Console */}
                                    <SidenavMenu
                                        menuName="Google Search Console"
                                        navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                        menu2
                                        url={`${url}/google-search-console`}
                                        icon={SiGoogle}
                                        mainPath={mainPath}
                                        matchPath="google-search-console"
                                    />

                                    {/* Google AdSense */}
                                    <SidenavMenu
                                        menuName="Google AdSense"
                                        navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                        menu2
                                        url={`${url}/google-adsense`}
                                        icon={SiGoogleadsense}
                                        mainPath={mainPath}
                                        matchPath="google-adsense"
                                    />


                                    {/* Pinterest Verification */}
                                    <SidenavMenu
                                        menuName="Pinterest Verification"
                                        navbarOffInMobileViewHandler={navbarOffInMobileViewHandler}
                                        menu2
                                        url={`${url}/pinterest-verification`}
                                        icon={ImPinterest2}
                                        mainPath={mainPath}
                                        matchPath="pinterest-verification"
                                    />
                                </>
                            }
                        </ListItem>
                    </Scrollbars>


                    <Spacer />
                    { // on desktop only 
                        window.innerWidth > 991 &&
                        <Box
                            className={`icon ${navList && 'active'}`}
                            mb={0} cursor="pointer" transition="all 0.5s"
                            bg="#4A5568" onClick={handleNavState} >
                            {
                                navList
                                    ?
                                    <Icon as={IoTabletPortraitOutline} w={5} h={10} />
                                    :
                                    <Icon as={IoIosDesktop} w={5} h={10} />
                            }
                        </Box>
                    }

                    { // on tablet only 
                        window.innerWidth < 991 && window.innerWidth > 600 &&
                        <Box
                            className={`icon ${navList ? 'tabOff' : 'tabOn'}`}
                            mb={0} cursor="pointer" transition="all 0.4s"
                            bg="#4A5568" onClick={handleNavState} >
                            {
                                navList
                                    ?
                                    <HamburgerIcon w={5} h={10} />
                                    :
                                    <Icon as={AiOutlineClose} w={5} h={10} />
                            }
                        </Box>
                    }
                </Flex>
            </List>
        </Box>
    )
}

export default SideNav;