import React from 'react'
import { Box, Text } from '@chakra-ui/react'; 
import CategoryTree from '../../../components/tree/CategoryTree'; 
import ButtonCheckbox from '../../../components/filter/ButtonCheckbox'; 

function FilterSidebar(props) {
   let { 
      tag, tags, filterTagsHandler, 
      business, businesses, filterBusinessHandler, 
      category, catTree, filterCategoryHandler, 
   } = props; 
   const theme = JSON.parse(localStorage.getItem('theme')); 


   return ( 
      <Box px={5} py={4} 
         style={{zIndex: '10 !important'}}>
         <Box>
            <Text
               borderLeftWidth="4px"
               borderLeftColor={theme?.color?.accent}
               fontSize="1rem"
               letterSpacing="1px"
               fontWeight="500"
               mb={4} pl={2}
               bg="gray.200"
               rounded={2}
               textTransform="uppercase">
               Categories 
            </Text> 
            <CategoryTree filterCatHandler={filterCategoryHandler} categories={catTree} value={category} />
         </Box>

         {/* Business */}
         {/* <Box py={4}>
            <Box>
               <Text
                  borderLeftWidth="4px"
                  borderLeftColor={theme?.color?.accent}
                  fontSize="1rem"
                  fontWeight="500"
                  mb={4} pl={2}
                  bg="gray.200"
                  rounded={2}
                  letterSpacing="1px"
                  textTransform="uppercase">
                  Business
               </Text>
               <ButtonCheckbox filterItemHandler={filterBusinessHandler} data={businesses} value={business} />
            </Box>
         </Box> */}
         {/* <Box py={4}>
            <Box>
               <Text
                  borderLeftWidth="4px"
                  borderLeftColor={theme?.color?.accent}
                  fontSize="1rem"
                  fontWeight="500"
                  mb={4} pl={2}
                  bg="gray.200"
                  rounded={2}
                  letterSpacing="1px"
                  textTransform="uppercase">
                  Tags 
               </Text>
               <ButtonCheckbox filterItemHandler={filterTagsHandler} data={tags} value={tag} />
            </Box>
         </Box> */}
      </Box>
   )
}

export default FilterSidebar; 