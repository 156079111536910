import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Container, useToast } from '@chakra-ui/react'
import axios from 'axios'
import Header from './Header';
import Feature from './Feature';
import Main from './Main';
import About from './About';
import Cities from './Cities';
import Contact from './Contact';
import MetaData from './MetaData';
import { config } from '../../config'
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb'

import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';

function LandingPageCustomization() {
    const { baseUrl } = config
    const toast = useToast()

    const { register, handleSubmit, getValues, setValue, reset, errors } = useForm({
        shouldUnregister: true
    });
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [pageContent, setPageContent] = useState(null);
    const [refetch, setRefetch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [style, setStyle] = useState({
        fontSize: '40',
        fontWeight: '700',
        fontColor: 'inherit',
        fontFamily: 'inherit'
    })

    const [pageSliderStyle, setPageSliderStyle] = useState({});

    const [id, setId] = useState('');

    const submitForm = (data) => {
        // console.log(data)
        // console.log(pageSliderStyle)
        let pageName = 'SA Landing Page';
        let pageTitle = 'SAL';
        let pageType = 'SL';

        const franchiseeId = localStorage.getItem('franchiseeId')
        if (franchiseeId !== 'null') {
            pageName = `CA Landing Page - ${franchiseeId}`;
            pageTitle = 'CAL';
            pageType = 'CL';
        }
        setLoading(true);
        const submitData = {
            pageName: pageName,
            pageAbout: {
                text: data.about_text,
                title: data.about_title
            },
            pageContact: {
                text: data.contact_text,
                title: data.contact_title,
                showForm: data.show_form,
                toMail: data.contact_recipient,
                checkboxLabel: data.contact_checkbox_label
            },

            pageFeature: pageContent.pageFeature ? pageContent.pageFeature : [],
            pageHeader: {
                title: data.header_title,
                style: style
            },
            pageMain: draftToHtml(
                convertToRaw(editorState.getCurrentContent()),
                null,
                null,
                (entity) => {
                  // if (entity.type === 'DIV') { // Receive what you passed before, here (like id, or classList, etc.)
                  //     return `<div>${entity.data.innerHTML}</div>`;
                  // }
                  if (entity.type === 'SCRIPT') {
                    // Receive what you passed before, here (like id, or keyEvents, etc.)
                    return `<script>${entity.data.innerHTML}</script>`;
                  }
                  if (entity.type === 'INS') {
                    // Receive what you passed before, here (like id, or keyEvents, etc.)
                    // console.log("ENTRY:::", entity);
                    return `${entity.data.outerHTML}`;
                  }
                  return '';
                }
              ),
            pageSlider: {
                title: data.section_title,
                businessType: data.business_type,
                itemsToShow: data.city_items,
                style: data.business_bg
            },
            meta: data.meta
        }

        const token = localStorage.getItem('token')
        axios({
            url: `/api/v1/pages/${id}`,
            method: 'patch',
            baseURL: baseUrl,
            data: submitData,
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {
                toast({
                    title: "Landing Page Updated.",
                    description: "You have updated a landing page successfully.",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
                setRefetch((r) => !r);
            })
            .catch(err => {
                console.log(err);
                toast({
                    title: "Landing Page Update Failed!",
                    description: err?.response?.data?.message,
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                })
            }).finally(() => {
                setLoading(false);
            })
    }

    const setLandingPageInfo = (pageData) => {
        const { pageHeader, pageAbout, pageFeature, pageContact, pageSlider, pageMain, meta } = pageData;
        setStyle({
            fontSize: pageHeader.style?.fontSize ? pageHeader.style?.fontSize : '60',
            fontWeight: pageHeader.style?.fontWeight ? pageHeader.style?.fontWeight : '700',
            fontColor: pageHeader.style?.fontColor ? pageHeader.style?.fontColor : 'inherit',
            fontFamily: pageHeader.style?.fontFamily ? pageHeader.style?.fontFamily : 'inherit',
        })
        setPageContent({
            pageHeader,
            pageAbout,
            pageFeature,
            pageContact,
            pageSlider,
            pageMain,
            meta
        })
    }
    // console.log(pageSliderStyle)

    const getLandingPageContent = () => {
        const franchiseeId = localStorage.getItem('franchiseeId');
        let getUrl = `${baseUrl}/api/v1/pages/superAdmin`;

        if (franchiseeId !== 'null') {
            getUrl = `${baseUrl}/api/v1/pages/cityAdmin/${franchiseeId}`;
        }

        axios.get(getUrl)
            .then((res) => {
                setLandingPageInfo(res.data.data);
                setId(res.data.data._id);
                const data = res?.data?.data
                reset({
                    header_title: data?.pageHeader?.title,
                    header_image: data?.pageHeader?.image,

                    about_title: data?.pageAbout?.title,
                    about_text: data?.pageAbout?.text,
                    about_image: data?.pageAbout?.image,

                    city_items: data?.pageSlider?.itemsToShow,
                    section_title: data?.pageSlider?.title,
                    business_type: data?.pageSlider?.businessType,

                    contact_title: data?.pageContact?.title,
                    contact_checkbox_label: data?.pageContact?.checkboxLabel,
                    contact_text: data?.pageContact?.text,
                    showForm: data?.pageContact?.showForm,
                    contact_recipient: data?.pageContact?.toMail,
                    business_bg: data?.pageSlider?.style,

                    meta: data.meta
                })
                setPageSliderStyle({ ...pageSliderStyle, style: data?.pageSlider?.style })
            })
            .catch((err) => {
                console.log(err);
                toast({
                    title: "Fetching landing page info failed",
                    status: "error",
                    description: err?.response?.data?.message,
                    duration: 4000,
                    isClosable: true,
                })
            })
    }

    useEffect(() => {
        getLandingPageContent();
    }, [refetch])

    useEffect(() => {
        if (pageContent) {

            // reset({
            //     header_title: pageContent?.pageHeader?.title,
            //     header_image: pageContent?.pageHeader?.image,

            //     about_title: pageContent?.pageAbout?.title,
            //     about_text: pageContent?.pageAbout?.text,
            //     about_image: pageContent?.pageAbout?.image,

            //     city_items: pageContent?.pageSlider?.itemsToShow,
            //     section_title: pageContent?.pageSlider?.title,
            //     business_type: pageContent?.pageSlider?.businessType,

            //     contact_title: pageContent?.pageContact?.title,
            //     contact_text: pageContent?.pageContact?.text,
            //     showForm: pageContent?.pageContact?.showForm,
            //     contact_recipient: pageContent?.pageContact?.toMail,

            //     meta: pageContent.meta
            // })
        }
    }, [])


    return (
        <Container maxWidth={1140} centerContent py={4} border>
            <Box p={[0, 0, 4, 4]}>
                <Box bg="white" p={4} mb={3} boxShadow="md" rounded="md" >
                    <Breadcrumb pathname="Landing Page" nameOrTitle={pageContent?.pageHeader.title} mb={0} />
                </Box>

                {
                    pageContent ?
                        <form onSubmit={handleSubmit(submitForm)}>
                            <Header
                                id={id}
                                register={register}
                                setPageContent={setPageContent}
                                pageContent={pageContent}
                                style={style} setStyle={setStyle}
                            />

                            <MetaData register={register} />

                            <Main
                                pageContent={pageContent}
                                editorState={editorState}
                                setEditorState={setEditorState}
                            />

                            <Feature
                                id={id}
                                pageContent={pageContent}
                                setPageContent={setPageContent}
                            />

                            <About
                                id={id}
                                register={register}
                                setPageContent={setPageContent}
                                pageContent={pageContent}
                            />

                            <Cities
                                pageSlider={pageContent?.pageSlider}
                                register={register}
                                setValue={setValue}
                                getValues={getValues}
                                pageSliderStyle={pageSliderStyle}
                                setPageSliderStyle={setPageSliderStyle}
                            />

                            <Contact pageContact={pageContent?.pageContact} errors={errors} register={register} setValue={setValue} getValues={getValues} />

                            <Button type="submit" loadingText='Saving...' isLoading={loading} colorScheme="teal" variant="outline" boxShadow="lg" size="sm">
                                Save
                            </Button>
                        </form>
                        : null

                }

            </Box>
        </Container>
    )
}

export default LandingPageCustomization;


