import { Box, Container  } from '@chakra-ui/layout';

const PageHeader = (props) => { 
    
    const style = { 
        backgroundImage: props.image ? `url(${props.image})` : `none`,
        paddingTop:'55px',
        minHeight: '350px',
        backgroundColor: 'burlywood',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position:'relative'
    } 

    let pageHeaderStyle={
        fontSize: 20
    }
    const ph = localStorage.getItem('pageHeaderStyle')

    if(ph == 'undefined'){
        console.log("IF:::");
    }
    else{
       pageHeaderStyle = JSON.parse(localStorage.getItem('pageHeaderStyle'))
    }

    return (
        <header style={style}>
            <Container 
                maxW="1100px"
                // bg="white" 
                // boxShadow={{base: null, sm: null, md: 'lg', lg: 'lg'}}s
                rounded="md" 
                mt={["30px", "30px", "40px"]}
                p={{base: 3, sm: 3, md: 10, lg: 10}}
                // textShadow={["0", "0", "white"]}
                // style={{ "text-shadow": "0 0 white" }}
            > 
                {/* <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
                    <Box 
                        // color="white" 
                        fontSize="2.5rem" 
                        fontWeight="700" 
                        w={{ base: "360px", sm:"400px", md:"500px", lg:"600px" }}
                        textAlign="center"
                    >  */}
                        {/* <h1 style={{fontSize:"2.5rem", textAlign: "center"}}>{props.headerTitle}</h1> */}
                        <h1 style={{ ...pageHeaderStyle, fontSize: pageHeaderStyle?.fontSize + 'px' }}>{props.headerTitle}</h1>
                    {/* </Box> 
                </Box>  */}

            </Container> 
        </header> 
    ); 
} 

export default PageHeader;