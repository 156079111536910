import React, { useState, useEffect, useRef, useContext } from 'react'
import {
    Container, Box, Stack, Text, useToast,
    SimpleGrid, Flex, Spacer,
    Checkbox,
    Button, Modal, ModalOverlay, ModalContent, ModalCloseButton,
    ModalBody, Select,
    useDisclosure, Spinner,
    Alert, AlertTitle, AlertDescription, Center, Image, Divider, RadioGroup, Radio,
} from '@chakra-ui/react'
import { useForm, Controller } from 'react-hook-form'
import { config } from '../../config';
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios';
import collect from 'collect.js';
import { useHistory } from 'react-router-dom'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import successImage from '../../img/ordersuccess.png'
import orderError from '../../img/orderError.jpg'
import DomainUtils from '../../service/utils/DomainUtils';
import useDomain from '../../service/DomainHook';
import AddressForm from './AddressForm';
import BillingAddressForm from './BillingForm';
import GreetingCardForm from './GreetingCardForm';
import { CartContext } from '../../context/CartContext';
import GreetingCardSlider from './GreetingCardSlider';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";



import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";


// const stripePromise = loadStripe('STRIPE_PUBLISHABLE_API_KEY');

const StripeWrapper = (props) => {
    const [hasSubDomain] = useDomain();
    const [stripePromise, setStripePromise] = useState(null);
    const [paymentGatewayData, setPaymentGatewayData] = useState({});

    const baseURL = `${config.baseUrl}/api/v1`;

    const getPaymentGatewayData = async () => {
        let franchisee = null;
        let url = `/payments/payment-gateway-settings?adminType=SA`;
        if (hasSubDomain) {
            const [franchiseeId] = await DomainUtils();
            franchisee = franchiseeId;
            url = `/payments/payment-gateway-settings?adminType=CA&franchiseeId=${franchisee}`
        }

        try {
            const { data: { data: paymentGatewayObj } } = await axios({
                url: url,
                method: 'get',
                baseURL: baseURL,
                // params: { franchiseeId: franchisee, adminType: 'CA', isDefault: true }
            });
            setPaymentGatewayData(paymentGatewayObj);
            setStripePromise(
                loadStripe(
                    paymentGatewayObj.publicKey
                )
            )
        } catch (error) {
            console.log("No payment gateway method found")
        }
    }

    useEffect(() => {
        getPaymentGatewayData()
    }, [])

    return (
        <Elements stripe={stripePromise}>
            <Checkout {...props} paymentGatewayData={paymentGatewayData} />
        </Elements>
    )
};




function Checkout(props) {
    const [hasSubDomain] = useDomain();
    const [cart, setCart] = useState(null);
    const [mapShipToProd, setMapShipToProd] = useState({});
    const [shippingAddressList, setShippingAddressList] = useState([]);
    const [shippingMethod, setShippingMethod] = useState(null);
    const [sameBillingAddress, setSameBillingAddress] = useState(true);
    const [billingAddress, setBillingAddress] = useState({ hasAddress: false, shippingId: null, address: null });
    const [greetingCardList, setGreetingCardList] = useState([]);
    const [greetingCardCharge, setGreetingCardCharge] = useState(0);
    const [paymentRadioValue, setPaymentRadioValue] = useState('1')
    const paymentGatewayData = props?.paymentGatewayData;
    const [stripePromise, setStripePromise] = useState(null);

    // const stripePromise = loadStripe(
    //     "pk_test_2Q1mGi01loYjgDczY12NwfUR"
    // );

    const [orderStatus, setOrderStatus] = useState({
        success: false,
        error: false,
        errorMsg: ''
    });

    const [paymentStatus, setPaymentStatus] = useState({
        success: false,
        error: false,
    });

    const [successMsg, setSuccessMsg] = useState({
        email: '',
        orderNo: '',
        total: ''
    });
    const [loading, setLoading] = useState(false);

    // Cart Context
    const [cartItem, dispatch] = useContext(CartContext);

    // Stripe objects ------------------
    const stripe = useStripe();
    const elements = useElements();
    //---------------------------------

    // Address form ------------------
    const { register, handleSubmit, control, errors, reset } = useForm({
        mode: 'onSubmit',
        shouldFocusError: false,
        shouldUnregister: true
    });
    //---------------------------------

    const baseURL = `${config.baseUrl}/api/v1`;
    const theme = JSON.parse(localStorage.getItem('theme'));
    const history = useHistory();
    const toast = useToast();
    const selectedProductId = useRef(null);
    const shippingIdForGreeting = useRef(null);
    const mode = useRef('add');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: billIsOpen, onOpen: billOnOpen, onClose: billOnClose } = useDisclosure();
    const { isOpen: greetingIsOpen, onOpen: greetingOnOpen, onClose: greetingOnClose } = useDisclosure();
    const { isOpen: isStatusOpen, onOpen: onStatusOpen, onClose: onStatusClose } = useDisclosure();


    // const getPaymentGatewayData = async () =>{
    //     let franchisee = null;
    //     let url = `/payments/payment-gateway-settings?adminType=SA`;
    //     if (hasSubDomain) {
    //         const [franchiseeId] = await DomainUtils();
    //         franchisee = franchiseeId;
    //         url = `/payments/payment-gateway-settings?adminType=CA&franchiseeId=${franchisee}`
    //     }

    //     try {
    //         const { data: { data: paymentGatewayObj } } = await axios({
    //             url: url,
    //             method: 'get',
    //             baseURL: baseURL,
    //             // params: { franchiseeId: franchisee, adminType: 'CA', isDefault: true }
    //         });
    //         setPaymentGatewayData(paymentGatewayObj);
    //         setStripePromise(
    //             loadStripe(
    //                 paymentGatewayObj.publicKey
    //             )
    //         )
    //     } catch (error) {
    //         console.log("No payment gateway method found")
    //     }
    // }

    // Get Shipping method
    useEffect(() => {

        const fetchInitialData = async () => {
            let franchisee = null;
            if (hasSubDomain) {
                const [franchiseeId] = await DomainUtils();
                franchisee = franchiseeId;
            }

            try {
                const { data: { data: [shipping] } } = await axios({
                    url: '/shippingMethods',
                    method: 'get',
                    baseURL: baseURL,
                    params: { franchiseeId: franchisee, adminType: 'CA', isDefault: true }
                });
                setShippingMethod(shipping);
            } catch (error) {
                console.log("No shipping method found")
            }
        }

        fetchInitialData();
        // getPaymentGatewayData()

    }, []);

    // Get cart and local state setup
    useEffect(() => {
        const cartToken = localStorage.getItem('cartToken');
        if (cartToken) {
            axios({ url: `/cart/${cartToken}`, method: 'GET', baseURL: baseURL })
                .then(response => {
                    const { products } = response.data.data;
                    const shipMapProd = {};
                    products.forEach(product => {
                        shipMapProd[product._id] = {
                            shippingId: null,
                            isSame: false,
                        };

                    })
                    setMapShipToProd(shipMapProd);
                    setCart(response.data.data);

                })
                .catch(error => console.log(error))
        }

        // Greeting Cards
        const fetchGreetingCards = async () => {
            let franchisee = null;
            if (hasSubDomain) {
                const [franchiseeId] = await DomainUtils();
                franchisee = franchiseeId;
            }
            axios({ url: `/greetingCards?franchiseeId=${franchisee}&active=true`, method: 'GET', baseURL: baseURL })
                .then(response => {
                    setGreetingCardList(response.data.data);
                })
                .catch(error => console.log(error))
        }

        fetchGreetingCards();

    }, [])


    useEffect(() => {
        if (shippingAddressList.length) {
            let cardCharge = 0;
            shippingAddressList.forEach(item => {
                if (item.greetingCard) {
                    const card = greetingCardList.find(card => card._id === item.greetingCard);
                    if (card) {
                        cardCharge += parseFloat(card.price);
                    }
                }
            });
            setGreetingCardCharge(cardCharge);
        } else {
            setBillingAddress({ hasAddress: false, shippingId: null, address: null });
        }
    }, [shippingAddressList])

    // Get combined address as plain text
    const getCombinedAddressText = (address) => {
        let inputAddressText = `${address.firstName}_${address.lastName}_${address.street}_${address.city}`;
        inputAddressText = inputAddressText.toLowerCase().replace(/\s/g, '');
        return inputAddressText;
    }

    // Check address mutation/change 
    const checkAddressEquality = (inputAddress, listAddress) => {
        const inputAddressText = getCombinedAddressText(inputAddress);
        const isEqual = inputAddressText === listAddress;
        return isEqual;
    }

    // Create a new Address
    const createNewAddress = (data, shippingId = null) => {
        const shippingAddress = {
            id: uuidv4(),
            address: data.shippingAddress,
            greetingCard: null,
            note: '',
            slug: getCombinedAddressText(data.shippingAddress)
        }

        const mapShipToProdCopy = { ...mapShipToProd }
        mapShipToProdCopy[selectedProductId.current] = {
            isSame: false,
            shippingId: shippingAddress.id,
        }

        let shippingList = [...shippingAddressList];

        if (shippingId) {
            const hasShippingIds = Object.keys(mapShipToProdCopy).filter(id => mapShipToProdCopy[id].shippingId === shippingId);
            if (hasShippingIds.length === 0) {
                shippingList = shippingAddressList.filter(item => item.id !== shippingId);
            }
        }

        const sameSlugIndex = shippingList.findIndex(item => item.slug === shippingAddress.slug);
        if (sameSlugIndex === -1) {
            shippingList = [...shippingList, shippingAddress];
            shippingIdForGreeting.current = shippingAddress.id;
            greetingOnOpen();

        } else {
            mapShipToProdCopy[selectedProductId.current] = {
                isSame: false,
                shippingId: shippingList[sameSlugIndex].id,
            }
        }

        setMapShipToProd(mapShipToProdCopy);
        setShippingAddressList(shippingList);
        onClose();
    }


    // Handle address mapping for product
    const handleAddressMapping = (data) => {
        if (selectedProductId.current) {
            if (mode.current === 'add') {
                createNewAddress(data);
            }

            if (mode.current === 'edit') {
                let mapShipToProdCopy = { ...mapShipToProd }
                const { shippingId } = mapShipToProdCopy[selectedProductId.current];
                let shippingList = [...shippingAddressList];
                const shippingIndex = shippingList.findIndex(item => item.id === shippingId);
                const isSameAddress = checkAddressEquality(data.shippingAddress, shippingList[shippingIndex].slug);

                if (!isSameAddress) {
                    const slug = getCombinedAddressText(data.shippingAddress);
                    const sameSlugIndex = shippingList.findIndex(item => item.slug === slug);
                    if (sameSlugIndex !== -1) {
                        shippingList[sameSlugIndex] = {
                            ...shippingList[sameSlugIndex],
                            slug: slug,
                            addres: data.shippingAddress
                        };

                        mapShipToProdCopy[selectedProductId.current] = {
                            isSame: false,
                            shippingId: shippingList[sameSlugIndex].id
                        }

                        if (!mapShipToProdCopy[shippingId]) {
                            shippingList = shippingAddressList.filter(item => item.id !== shippingId);
                        }

                        setMapShipToProd(mapShipToProdCopy);
                        setShippingAddressList(shippingList);

                    } else {
                        createNewAddress(data, shippingId);
                    }

                } else {
                    const newShippingList = shippingList.map(item => {
                        if (item.id !== shippingId) return item;
                        item.address = data.shippingAddress;
                        return item;

                    })
                    setShippingAddressList(newShippingList);
                }

                onClose();
            }
        }
    }

    const handleAddressUpdate = (productId) => {
        selectedProductId.current = productId;
        const { shippingId } = mapShipToProd[productId];
        const shipping = shippingAddressList.find(address => address.id === shippingId);
        mode.current = 'edit';
        reset({
            shippingAddress: shipping.address
        })
        onOpen();
    }

    const handleAddressReset = (productId) => {
        const mapShipToProdCopy = { ...mapShipToProd };
        const { shippingId } = mapShipToProdCopy[productId];
        mapShipToProdCopy[productId].shippingId = null;
        mapShipToProdCopy[productId].isSame = false;

        const exists = Object.keys(mapShipToProdCopy).some((key) => {
            return mapShipToProdCopy[key].shippingId === shippingId;
        });

        if (!exists) {
            const shippingList = shippingAddressList.filter(item => item.id !== shippingId);
            setShippingAddressList(shippingList);
        }
        setMapShipToProd(mapShipToProdCopy);
        setPaymentRadioValue(0)
    }

    const closeAddressModal = () => {
        selectedProductId.current = null;
        mode.current = 'add';
        onClose();
    }

    const selectTypeChange = (productId, e) => {
        const mapShipToProdCopy = { ...mapShipToProd };
        mapShipToProdCopy[productId].isSame = e.target.checked;
        setMapShipToProd(mapShipToProdCopy);
    }

    const handleShippingSelect = (productId, e) => {
        const mapShipToProdCopy = { ...mapShipToProd };
        mapShipToProdCopy[productId].shippingId = e.target.value;
        setMapShipToProd(mapShipToProdCopy);
    }

    const getCombinedAddress = (shippingId) => {
        const shipping = shippingAddressList.find(address => address.id === shippingId);
        if (shippingId && shipping) {
            const combinedAddress = (
                <Box display="flex" mx="-10px">
                    <Box px="10px">
                        <Text color="gray.500" fontWeight="700" fontSize=".9rem" lineHeight="1rem">
                            <Text as="span" mr="4px" color="gray.600" fontWeight="700" fontSize=".9rem" lineHeight="1rem">Name:</Text>
                            {shipping?.address?.firstName} {shipping?.address?.lastName}
                        </Text>
                    </Box>
                    <Box px="10px">
                        <Text color="gray.500" fontWeight="700" fontSize=".9rem" lineHeight="1rem">
                            <Text as="span" mr="4px" color="gray.600" fontWeight="700" fontSize=".9rem" lineHeight="1rem">Address:</Text>
                            {shipping?.address?.street}, <br />
                            {shipping?.address?.city}
                        </Text>
                    </Box>
                </Box>
            )
            return combinedAddress;

        }
        return null;
    }

    const handleBillingAddressSubmit = (data) => {
        // if (billingAddress.shippingId) {
        //     const shippingAddressListCopy = [...shippingAddressList];
        //     const newShippingList = shippingAddressListCopy.map(item => {
        //         if (item.id === billingAddress.shippingId) {
        //             item.address = data.billingAddress;
        //         }
        //         return item;
        //     })
        //     setShippingAddressList(newShippingList);
        //     setBillingAddress({...billingAddress, hasAddress: true});
        // } else {
        //     setBillingAddress({hasAddress: true, shippingId: null, address: data.billingAddress});
        // }
        setBillingAddress({ hasAddress: true, shippingId: null, address: data.billingAddress });
        billOnClose();
    }

    const handleBillingSelect = (e) => {
        if (e.target.value) {
            const shipping = shippingAddressList.find(shipping => shipping.id === e.target.value);
            if (shipping.address.hasOwnProperty('phone') && shipping.address.hasOwnProperty('email')) {
                setBillingAddress({ hasAddress: true, shippingId: e.target.value, address: null });
            } else {
                billOnOpen();
                setBillingAddress({ hasAddress: false, shippingId: e.target.value, address: null });
            }
        }
    }

    const handleGeetingReset = (id) => {
        const shippingList = shippingAddressList.map(item => {
            if (item.id === id) return { ...item, greetingCard: null, note: '' }
            return item;
        })
        setShippingAddressList(shippingList);
    }

    const viewCardInfo = (shippingItem) => {
        const card = greetingCardList.find(item => item._id === shippingItem.greetingCard);

        greetingCardInfo.push({
            name: 'Greeting Card: ' + card?.name,
            quantity: '1',
            unit_amount: {
                value: card?.price,
                currency_code: "USD",
            }
        })

        greetingCardTotalFee = greetingCardTotalFee + +card?.price

        if (!card) return null;
        const cardPrice = parseFloat(card.price).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
        return (
            <Flex direction={["column", "row"]}>
                <Box w={["150px", "140px"]} h={["140px", "130px"]}>
                    <Image w="100%" h="100%" rounded={4} objectFit="cover" src={card.image} />
                </Box>
                <Box flex="1 1" px="10px" py="4px">
                    <Text color="gray.700" fontWeight="700" fontSize=".9rem" lineHeight="1rem" mb={1}>{card.name}</Text>
                    <Text color="gray.700" fontWeight="400" fontSize=".9rem" lineHeight="1rem" mb={1}>
                        ${cardPrice}
                    </Text>
                </Box>
                <Button color='red.400' size="xs" onClick={() => handleGeetingReset(shippingItem.id)}>Clear</Button>
            </Flex>
        )
    }

    /**
     * @function validateProductData
     * @description Validate if all product is mapped to shipping address 
     * @returns boolean
     * @author Saiful Islam
     */
    const validateProductData = () => {
        const hasProductError = Object.keys(mapShipToProd).some((key) => {
            return mapShipToProd[key].shippingId === null;
        });

        if (hasProductError) {
            toast({
                title: "Error!",
                description: "Shipping Address missing in product list",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
        return hasProductError;
    }

    /**
     * @function validateBilligAddress
     * @description Billing addres exists validation checker
     * @returns boolean
     * @author Saiful Islam
     */
    const validateBilligAddress = () => {
        if (!billingAddress.hasAddress) {
            toast({
                title: "Error!",
                description: "Billing Address is missing",
                status: "error",
                duration: 3000,
                isClosable: true,
            });

            return true
        }
        return false;
    }

    /**
     * @function processOrderPayload
     * @description Order payload process handler
     * @returns void
     * @author Saiful Islam
     */
    const processOrderPayload = (orderPayload) => {
        let cartSubTotal = 0;
        shippingAddressList.forEach(shipping => {
            let subTotal = 0;
            const productKeys = Object.keys(mapShipToProd).filter(key => mapShipToProd[key].shippingId === shipping.id);
            const products = collect(cart.products).whereIn('_id', productKeys).toArray();
            const greetingCard = shipping.greetingCard
                ? greetingCardList.find(card => card._id === shipping.greetingCard)
                : null

            const productTotal = collect(products).sum(product => product.totalPrice);
            const greetingCardPrice = greetingCard ? parseFloat(greetingCard.price) : 0;
            subTotal = productTotal + greetingCardPrice;
            cartSubTotal += subTotal

            orderPayload.details.push({
                subTotal: subTotal,
                shippingAddress: { ...shipping.address },
                products: products,
                greeting: greetingCard ? {
                    id: greetingCard._id,
                    name: greetingCard.name,
                    price: greetingCard.price,
                    message: shipping.note
                } : null
            })
        });

        orderPayload.shipping = {
            id: shippingMethod._id,
            charge: shippingMethod.cost
        }

        orderPayload.subTotal = cartSubTotal;
        orderPayload.total = cartSubTotal + shippingMethod.cost - orderPayload.discount.value;
        if (cart?.handleType === 'BY_ORDER') {
            orderPayload.total += cart.totalHandleFee;
        }
    }

    const formattedDate = () => {
        const d = new Date();
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    /**
     * @function orderSubmitHandler
     * @description Order place handler
     * @returns void
     * @author Saiful Islam
     */
    const orderSubmitHandler = async () => {
        const hasProductError = validateProductData();
        if (hasProductError) return;
        const billingAddressError = validateBilligAddress();
        if (billingAddressError) return;
        let franchisee = null;
        if (hasSubDomain) {
            const [franchiseeId] = await DomainUtils();
            franchisee = franchiseeId
        }
        const orderDate = formattedDate();

        const orderPayload = {
            orderDate: orderDate,
            franchiseeId: franchisee,
            discount: cart.isCouponApplied ? { id: cart.coupon.id, value: cart.couponDiscount } : { value: 0 },
            subTotal: 0,
            total: 0,
            totalHandleFee: cart?.totalHandleFee,
            handleType: cart?.handleType,
            details: []
        };

        if (billingAddress.shippingId) {
            const shipping = shippingAddressList.find(item => item.id === billingAddress.shippingId);
            orderPayload.billingAddress = { ...shipping.address }
        } else {
            orderPayload.billingAddress = { ...billingAddress.address }
        }

        setLoading(true);
        onStatusOpen();
        processOrderPayload(orderPayload);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: {
                name: orderPayload.billingAddress.firstName,
                email: orderPayload.billingAddress?.email,
                address: {
                    city: orderPayload.billingAddress?.city,
                    line1: orderPayload.billingAddress?.street,
                    state: orderPayload.billingAddress?.state,
                    postal_code: orderPayload.billingAddress?.zip
                }
            }
        });

        if (!error) {
            axios({
                url: '/orders',
                method: 'POST',
                baseURL: baseURL,
                data: orderPayload
            })
                .then(response => {
                    localStorage.removeItem('cartToken');
                    setOrderStatus({ ...orderStatus, success: true, error: false });
                    setSuccessMsg({
                        orderNo: response.data?.data?.no,
                        total: response.data?.data?.total,
                        email: orderPayload.billingAddress.email
                    });
                    dispatch({
                        type: 'SET_CART',
                        payload: 0
                    });
                    return response.data;
                })
                .then(async (res) => {

                    let franchisee = null;
                    if (hasSubDomain) {
                        const [franchiseeId] = await DomainUtils();
                        franchisee = franchiseeId;
                    }

                    try {
                        await axios({
                            url: '/payments/card',
                            method: 'post',
                            baseURL: baseURL,
                            data: {
                                token: paymentMethod,
                                order: { ...res.data },
                                franchisee
                            }
                        });

                        setPaymentStatus({
                            success: true,
                            error: false
                        });
                        setLoading(false);

                    } catch (error) {
                        setPaymentStatus({
                            success: false,
                            error: true,
                            errorMsg: error.response?.data?.message
                        });
                    }
                })
                .catch(error => {
                    setOrderStatus({
                        ...orderStatus,
                        success: false,
                        error: true,
                    });

                    setTimeout(() => {
                        onStatusClose();
                        setOrderStatus({
                            ...orderStatus,
                            error: false,
                            success: false
                        });
                        setLoading(false);
                    }, 3000);
                });

        } else {
            onStatusClose();
            setLoading(false);
            toast({
                title: "Card data invalid.",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }

    }

    /**
     * @function payPalOrderSubmitHandler
     * @description Paypal Order place handler
     * @returns void
     * @author Shahrear
     */
    const payPalOrderSubmitHandler = async (details) => {
        const hasProductError = validateProductData();
        if (hasProductError) return;
        const billingAddressError = validateBilligAddress();
        if (billingAddressError) return;
        let franchisee = null;
        if (hasSubDomain) {
            const [franchiseeId] = await DomainUtils();
            franchisee = franchiseeId
        }
        const orderDate = formattedDate();

        const orderPayload = {
            orderDate: orderDate,
            franchiseeId: franchisee,
            discount: cart.isCouponApplied ? { id: cart.coupon.id, value: cart.couponDiscount } : { value: 0 },
            subTotal: 0,
            total: 0,
            totalHandleFee: cart?.totalHandleFee,
            handleType: cart?.handleType,
            details: []
        };

        if (billingAddress.shippingId) {
            const shipping = shippingAddressList.find(item => item.id === billingAddress.shippingId);
            orderPayload.billingAddress = { ...shipping.address }
        } else {
            orderPayload.billingAddress = { ...billingAddress.address }
        }

        setLoading(true);
        onStatusOpen();
        processOrderPayload(orderPayload);

        axios({
            url: '/orders',
            method: 'POST',
            baseURL: baseURL,
            data: orderPayload
        })
            .then(response => {
                localStorage.removeItem('cartToken');
                setOrderStatus({ ...orderStatus, success: true, error: false });
                setSuccessMsg({
                    orderNo: response.data?.data?.no,
                    total: response.data?.data?.total,
                    email: orderPayload.billingAddress.email
                });
                dispatch({
                    type: 'SET_CART',
                    payload: 0
                });

                axios({
                    url: '/payments/by/paypal',
                    method: 'POST',
                    baseURL: baseURL,
                    data: {
                        order: {
                            _id: response.data.data._id
                        }
                    }
                }).then(response => {
                    console.log("response.data payPalOrderSubmitHandler", response.data);
                })


                return response.data;
            })
            .catch(error => {
                setOrderStatus({
                    ...orderStatus,
                    success: false,
                    error: true,
                });

                setTimeout(() => {
                    onStatusClose();
                    setOrderStatus({
                        ...orderStatus,
                        error: false,
                        success: false
                    });
                    setLoading(false);
                }, 3000);
            });
    }

    const redirectCustomer = () => {
        onStatusClose();
        history.push('/shop');
    }

    const getTotalAmout = () => {
        let total = 0;
        if (cart) {
            total = cart.cartTotal + greetingCardCharge + shippingMethod.cost - cart.couponDiscount;
            if (cart.handleType === 'BY_ORDER') {
                total += cart.totalHandleFee;
            }
        }
        return total;
    }

    const setPaymentRadioValueHandler = (e) => {
        const hasProductError = validateProductData();

        if (e == '2' && billingAddress.hasAddress && !hasProductError) {
            setPaymentRadioValue(e)
        }
        else if (e == '2' && hasProductError) {
            setPaymentRadioValue(0)
            if (hasProductError) return;
        }
        else if (e == '2' && !billingAddress.hasAddress) {
            setPaymentRadioValue(0)
            const billingAddressError = validateBilligAddress();
            if (billingAddressError) return;
        }

        if (e == '1') {
            setPaymentRadioValue(e)
        }
    }



    let itemTotalFee = 0;
    let greetingCardTotalFee = 0;

    const getItemsTotal = () => {
        cart?.products?.forEach(item => {
            // greetingCardInfo.push({
            //     name: item.name,
            //     quantity: item.qty + '',
            //     unit_amount: {
            //         value: item?.amount + '',
            //         currency_code: "USD",
            //     }
            // })
            itemTotalFee = itemTotalFee + item?.amount
        })

        return itemTotalFee + greetingCardTotalFee
    }



    const getBreakDownInfo = () => {
        return {
            item_total: {
                value: getItemsTotal() + '',
                currency_code: "USD",
            },
            shipping: {
                value: shippingMethod?.cost + '' || '0',
                currency_code: "USD",
            },
            discount: {
                value: cart?.couponDiscount + '' || '0',
                currency_code: "USD",
            },
            handling: getHandlingFee(),
        }
    }


    const getHandlingFee = () => {
        return {
            value: cart?.totalHandleFee + '',
            currency_code: "USD",
        }
    }


    let greetingCardInfo = [];
    const getItems = () => {
        // let greetingCardInfo = [];
        cart?.products?.forEach(item => {
            greetingCardInfo.push({
                name: item.name,
                quantity: item.qty + '',
                unit_amount: {
                    value: item?.amount + '',
                    currency_code: "USD",
                }
            })
            itemTotalFee = itemTotalFee + item?.amount
        })

        return greetingCardInfo
    }


    // console.log("cart==>", cart);
    // console.log("shippingMethod==>", shippingMethod);
    // console.log("shippingAddressList==>", shippingAddressList);
    // console.log("greetingCardCharge==>", greetingCardCharge);



    return (
        <>
            <Box bg="#EDF2F9">
                <Container maxWidth={1140} py="20px">
                    <Box mt="60px" mb={5}>
                        {
                            cartItem <= 0 ?
                                <Alert
                                    status="info"
                                    variant="subtle"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    textAlign="center"
                                    bg={`rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 0.4'})`}
                                    height="200px">
                                    <AlertTitle mt={4} mb={1} fontSize="lg">
                                        Your Cart is empty!
                                    </AlertTitle>
                                </Alert> :
                                <Stack direction={["column", "row"]} spacing="10px">
                                    {/* Product Information */}
                                    <Box w={{ base: "100%", sm: "100%", md: "50%", lg: "60%" }}>
                                        {/* Product list */}
                                        <Text as={"h2"} textTransform="uppercase" letterSpacing={1}>
                                            Checkout
                                        </Text>
                                        <Divider mb={2} />

                                        <Box
                                            rounded={5} px={5} py={4}
                                            bg="white" shadow="sm"
                                            mb={4}
                                        >
                                            {/* <Text color="gray.600" fontWeight="700" fontSize="1rem" lineHeight="1rem" mb={2}>Product Information</Text> */}
                                            {
                                                cart &&
                                                cart.products.map(item => {
                                                    return (
                                                        <Box key={item._id} mb={6}>
                                                            {/* <Divider /> */}
                                                            <Box bg="white" rounded={5} position="relative">
                                                                <Stack direction={["column", "row"]} spacing="10px" pt={3} pr={3} pb={3}>
                                                                    <Box w={["100%", "90px"]} h={["100px", "100px"]}>
                                                                        <Image w="100%" h="100%" rounded={4} objectFit="cover" src={item.image} />
                                                                    </Box>
                                                                    <Box w="18rem">
                                                                        <Text fontWeight="700" fontSize=".9rem" lineHeight="1rem" mb={1}>{item.name}</Text>
                                                                        <Text fontSize=".8rem" >Chosen: ${item.amount.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
                                                                        {
                                                                            item.handleFee > 0 &&
                                                                            <>
                                                                                <Text fontSize=".8rem" color="gray.600">
                                                                                    <Text as="span" fontSize=".8rem" color="gray.600">Quantity: 1</Text>
                                                                                    <Text px="5px" as="span" color="gray.800">|</Text>
                                                                                    <Text as="span" fontSize=".8rem" color="gray.600">Handle Fee: {item.handleFee.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
                                                                                </Text>
                                                                                <Text fontSize=".8rem" color="gray.600">Total: ${item.price.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
                                                                            </>
                                                                        }

                                                                    </Box>
                                                                    <SimpleGrid columns={2} spacing={1}>
                                                                        <Box w="6rem">
                                                                            <Text fontSize=".9rem">
                                                                                <Text as="span" display="inline-block" pr="4px" fontWeight={600}>
                                                                                    ${item.price.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                                                </Text>
                                                                                <Text as="span" fontWeight={600}>
                                                                                    X {item.qty}
                                                                                </Text>
                                                                            </Text>
                                                                        </Box>
                                                                        <Box w="6rem">
                                                                            <Text textAlign="center" fontSize=".9rem" fontWeight={600}>
                                                                                ${(item.totalPrice).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                                            </Text>
                                                                        </Box>
                                                                    </SimpleGrid>
                                                                </Stack>
                                                            </Box>
                                                            {/* Address handle UI */}
                                                            <Stack direction={["column", "row"]} spacing="10px" mb={2}>
                                                                {
                                                                    mapShipToProd[item._id].shippingId
                                                                        ? <Box width="100%">
                                                                            {getCombinedAddress(mapShipToProd[item._id].shippingId)}
                                                                            <Stack direction="row" mt={2}>
                                                                                <Button
                                                                                    color="blue.600" fontWeight="300" fontSize="13px"
                                                                                    size='xs'
                                                                                    lineHeight="14px"
                                                                                    onClick={() => handleAddressUpdate(item._id)}
                                                                                >
                                                                                    Change
                                                                                </Button>
                                                                                <Button
                                                                                    color="red.400" fontWeight="300" fontSize="13px"
                                                                                    size='xs'
                                                                                    lineHeight="14px"
                                                                                    onClick={() => handleAddressReset(item._id)}
                                                                                >
                                                                                    Clear
                                                                                </Button>
                                                                            </Stack>
                                                                        </Box>
                                                                        : mapShipToProd[item._id].isSame && shippingAddressList.length > 0
                                                                            ? <>
                                                                                <Checkbox
                                                                                    value={mapShipToProd[item._id].isSame}
                                                                                    isChecked={mapShipToProd[item._id].isSame}
                                                                                    onChange={(e) => selectTypeChange(item._id, e)}
                                                                                    size="sm"
                                                                                >
                                                                                    Use from previous
                                                                                </Checkbox>
                                                                                <Box width="70%">
                                                                                    <Select size="sm" onChange={(e) => handleShippingSelect(item._id, e)}>
                                                                                        <option key="shippingAddress" value="">Select Address</option>
                                                                                        {
                                                                                            shippingAddressList.map(addressItem => (
                                                                                                <option key={addressItem.id} value={addressItem.id}>
                                                                                                    {addressItem?.address?.firstName} {addressItem?.address?.lastName}, {addressItem?.address?.street}, {addressItem?.address?.city}
                                                                                                </option>
                                                                                            ))
                                                                                        }
                                                                                    </Select>
                                                                                </Box>
                                                                            </>
                                                                            : <>
                                                                                <Button
                                                                                    size="xs"
                                                                                    onClick={() => {
                                                                                        selectedProductId.current = item._id;
                                                                                        mode.current = 'add';
                                                                                        reset({
                                                                                            shippingAddress: {}
                                                                                        })
                                                                                        onOpen();
                                                                                    }}
                                                                                >Add Shipping</Button>
                                                                                {
                                                                                    shippingAddressList.length > 0 &&
                                                                                    <Checkbox
                                                                                        value={mapShipToProd[item._id].isSame}
                                                                                        isChecked={mapShipToProd[item._id].isSame}
                                                                                        onChange={(e) => selectTypeChange(item._id, e)}
                                                                                        size="sm"
                                                                                    >
                                                                                        Use from previous
                                                                                    </Checkbox>
                                                                                }
                                                                            </>
                                                                }
                                                            </Stack>
                                                        </Box>
                                                    );
                                                })
                                            }
                                        </Box>

                                        {/* Greeting Card */}
                                        {shippingAddressList.length > 0 && <Box rounded={5} px={5} py={4} bg="white" shadow="sm" mb={4}>
                                            <Text color="gray.600" fontWeight="700" fontSize="1rem" lineHeight="1rem" mb={2}>Greeting Card Per Shipping</Text>
                                            {
                                                shippingAddressList.map(item => (
                                                    <Box key={item.id} borderWidth={1} rounded="10px" mb={2} p={3} display="flex">
                                                        <Box flex="1 1">
                                                            <Text color="gray.500" fontWeight="700" fontSize=".9rem" lineHeight="1rem">
                                                                {item.address.firstName} {item.address.lastName},
                                                            </Text>
                                                            <Text color="gray.500" fontWeight="700" fontSize=".9rem" lineHeight="1rem">
                                                                {item.address.street},
                                                            </Text>
                                                            <Text color="gray.500" fontWeight="700" fontSize=".9rem" lineHeight="1rem">
                                                                {item.address.city}
                                                            </Text>
                                                        </Box>
                                                        <Stack direction="row" px="10px">
                                                            {
                                                                item.greetingCard ?
                                                                    <>
                                                                        <Box>
                                                                            {viewCardInfo(item)}
                                                                        </Box>
                                                                        <Button size="xs" onClick={() => {
                                                                            shippingIdForGreeting.current = item.id
                                                                            greetingOnOpen()
                                                                        }}>
                                                                            Edit
                                                                        </Button>
                                                                    </>
                                                                    :
                                                                    <Button size="xs" onClick={() => {
                                                                        shippingIdForGreeting.current = item.id
                                                                        greetingOnOpen()
                                                                    }}>
                                                                        Add Geeting
                                                                        <Text as="span" ml="4px" color="gray.500" fontWeight={300}>(optional)</Text>
                                                                    </Button>
                                                            }
                                                        </Stack>
                                                    </Box>
                                                ))
                                            }
                                        </Box>
                                        }

                                        {/* Billing address panel */}
                                        <Box rounded={5} px={5} py={4} bg="white" shadow="sm">
                                            <Text fontWeight="700" fontSize="1rem" lineHeight="1rem" mb={4}>Billing Address</Text>
                                            <Stack direction={["column", "row"]} spacing="10px" mb={2}>
                                                {
                                                    billingAddress.hasAddress ?
                                                        <Box width="100%">
                                                            {
                                                                billingAddress.shippingId
                                                                    ? getCombinedAddress(billingAddress.shippingId)
                                                                    : <Box display="flex" mx="-10px">
                                                                        <Box px="10px">
                                                                            <Text color="gray.500" fontWeight="700" fontSize=".9rem" lineHeight="1rem">
                                                                                <Text as="span" mr="4px" color="gray.600" fontWeight="700" fontSize=".9rem" lineHeight="1rem">Name:</Text>
                                                                                {billingAddress?.address?.firstName} {billingAddress?.address?.lastName}
                                                                            </Text>
                                                                        </Box>
                                                                        <Box px="10px">
                                                                            <Text color="gray.500" fontWeight="700" fontSize=".9rem" lineHeight="1rem">
                                                                                <Text as="span" mr="4px" color="gray.600" fontWeight="700" fontSize=".9rem" lineHeight="1rem">Address:</Text>
                                                                                {billingAddress?.address?.street} <br />
                                                                                {billingAddress?.address?.city}
                                                                            </Text>
                                                                        </Box>
                                                                    </Box>

                                                            }
                                                            <Stack direction="row" mt={2}>
                                                                <Button
                                                                    color="blue.600" fontWeight="300" fontSize="13px"
                                                                    size='xs'
                                                                    lineHeight="14px"
                                                                    onClick={billOnOpen}
                                                                >
                                                                    Change
                                                                </Button>
                                                                <Button
                                                                    color="red.400" fontWeight="300" fontSize="13px"
                                                                    size='xs'
                                                                    lineHeight="14px"
                                                                    onClick={() => {
                                                                        reset({
                                                                            billingAddress: { country: "US" }
                                                                        })
                                                                        setBillingAddress({ hasAddress: false, shippingId: null, address: null })
                                                                        setPaymentRadioValue(0)
                                                                    }}
                                                                >
                                                                    Clear
                                                                </Button>
                                                            </Stack>
                                                        </Box>
                                                        : sameBillingAddress && shippingAddressList.length > 0
                                                            ? <>
                                                                <Checkbox
                                                                    value={sameBillingAddress}
                                                                    isChecked={sameBillingAddress}
                                                                    onChange={(e) => setSameBillingAddress(e.target.checked)}
                                                                    size="sm"
                                                                >
                                                                    Use From Previous
                                                                </Checkbox>
                                                                <Box width="70%">
                                                                    <Select size="sm" value={billingAddress.hasAddress && billingAddress.shippingId} onChange={handleBillingSelect}>
                                                                        <option key="billingAddress" value="">Select Address</option>
                                                                        {
                                                                            shippingAddressList.map(addressItem => (
                                                                                <option key={addressItem.id} value={addressItem.id}>
                                                                                    {addressItem?.address?.firstName} {addressItem?.address?.lastName}, {addressItem?.address?.street}, {addressItem?.address?.city}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </Box>
                                                            </>
                                                            : <>
                                                                <Button size="xs"
                                                                    onClick={billOnOpen}
                                                                >Add Billing Address</Button>
                                                                {
                                                                    shippingAddressList.length > 0 &&
                                                                    <Checkbox
                                                                        value={sameBillingAddress}
                                                                        isChecked={sameBillingAddress}
                                                                        onChange={(e) => setSameBillingAddress(e.target.checked)}
                                                                        size="sm"
                                                                    >
                                                                        Use From Previous
                                                                    </Checkbox>
                                                                }
                                                            </>
                                                }
                                            </Stack>
                                        </Box>

                                    </Box>

                                    {/* Cart Information */}
                                    <Box w={{ base: "100%", sm: "100%", md: "38%", lg: "38%" }}>
                                        <Text as={"h2"} textTransform="uppercase" letterSpacing={1}>Order Summary</Text>
                                        <Divider mb={2} />

                                        <Box p={3} bg="white" rounded={5} mb="20px" shadow="sm">
                                            <Text fontWeight="700" fontSize="1rem" lineHeight="1rem" my={4}>Cart Information</Text>
                                            <Flex mb={2}>
                                                <Text>
                                                    Sub Total
                                                </Text>
                                                <Spacer />
                                                <Text fontWeight="700" color="#264653">
                                                    {
                                                        cart ?
                                                            `$${(cart.cartTotal).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
                                                            : '$0.00'
                                                    }
                                                </Text>
                                            </Flex>
                                            {
                                                cart?.couponDiscount > 0 ?
                                                    <Flex mb={2}>
                                                        <Text >
                                                            Discount
                                                        </Text>
                                                        <Spacer />
                                                        <Text fontWeight="700" color="#2A9D8F">
                                                            {
                                                                cart ?
                                                                    `- $${cart.couponDiscount.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
                                                                    : '- $0.00'
                                                            }
                                                        </Text>
                                                    </Flex>
                                                    : null
                                            }
                                            <Flex mb={2}>
                                                <Text >
                                                    Shipping Charge
                                                </Text>
                                                <Spacer />
                                                <Text fontWeight="700" color="#F4A261">
                                                    {
                                                        cart && shippingMethod ?
                                                            `+ $${(shippingMethod.cost).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
                                                            : '+ $0.00'
                                                    }
                                                </Text>
                                            </Flex>

                                            <Flex mb={2}>
                                                <Text>
                                                    Greeting Card Charge
                                                </Text>
                                                <Spacer />
                                                <Text fontWeight="700" color="#F4A261">
                                                    + ${greetingCardCharge.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                </Text>
                                            </Flex>

                                            <Flex mb={2}>
                                                <Text>
                                                    Handling Charge
                                                    {
                                                        cart && cart.handleType === 'BY_ITEM'
                                                        && <Text ml={1} fontWeight={300} as="span" fontSize="14px">(Included)</Text>
                                                    }
                                                </Text>
                                                <Spacer />
                                                <Text fontWeight="700" color={cart && cart.handleType === 'BY_ORDER' ? "#f4a261" : "blue.300"}>
                                                    {
                                                        cart && cart.handleType === 'BY_ORDER'
                                                        && <Text mr="2px" as="span" px="2px">+</Text>
                                                    }
                                                    ${cart?.totalHandleFee.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                </Text>
                                            </Flex>
                                            <Divider my="6px" />
                                            <Flex mb={2}>
                                                <Text fontWeight="700">
                                                    Total
                                                </Text>
                                                <Spacer />
                                                <Text fontWeight="700" color="gray.700" fontSize="18px">
                                                    {
                                                        (cart && shippingMethod) ?
                                                            `$${getTotalAmout().toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
                                                            : '$0.00'
                                                    }
                                                </Text>
                                            </Flex>

                                            <Box my={8}>
                                                <Text fontWeight="700" fontSize="1rem" lineHeight="1rem" mb={4}>Payment</Text>

                                                <RadioGroup onChange={setPaymentRadioValueHandler} value={paymentRadioValue} mb={4} defaultValue={1}>
                                                    <Stack direction='row'>
                                                        {
                                                            paymentGatewayData?.isStripeActive &&
                                                            <Radio value='1'>Card</Radio>
                                                        }
                                                        {
                                                            paymentGatewayData?.isPaypalActive &&
                                                            <Radio value='2'>PayPal</Radio>
                                                        }
                                                    </Stack>
                                                </RadioGroup>

                                                <Box borderWidth={1} p={3} rounded="10px" mb={5}>
                                                    {paymentRadioValue == 1 ?
                                                        <CardElement options={{ hidePostalCode: true }} />
                                                        :
                                                        paymentRadioValue == 2 ?
                                                            <>
                                                                <PayPalScriptProvider
                                                                    options={{
                                                                        "client-id": paymentGatewayData?.paypalClientId,
                                                                    }}
                                                                >
                                                                    <PayPalButtons
                                                                        style={{ layout: "horizontal" }}
                                                                        // onClick={(e) => {
                                                                        //     console.log("PayPalButtons click:::", e);
                                                                        //     // const hasProductError = validateProductData();
                                                                        //     // if (hasProductError) return;
                                                                        //     const billingAddressError = validateBilligAddress();
                                                                        //     if (billingAddressError) return;
                                                                        // }}
                                                                        createOrder={(data, actions) => {
                                                                            // console.log("data==>", data);
                                                                            // console.log("actions==>", actions);
                                                                            // console.log("billingAddress==>", billingAddress);

                                                                            let billingObj = {
                                                                                email: '',
                                                                                house: '',
                                                                                street: '',
                                                                                city: '',
                                                                                state: '',
                                                                                zip: '',
                                                                                country: '',
                                                                            }

                                                                            if (billingAddress?.shippingId) {
                                                                                const shipping = shippingAddressList.find(item => item.id === billingAddress.shippingId);
                                                                                billingObj = { ...shipping.address }
                                                                            } else {
                                                                                billingObj = { ...billingAddress.address }
                                                                            }

                                                                            return actions.order.create({
                                                                                // payer: {
                                                                                //     "name": {
                                                                                //         "given_name": `${billingAddress?.address?.firstName}`,
                                                                                //         "surname": `${billingAddress?.address?.lastName}`
                                                                                //     },
                                                                                //     "email_address": `${billingAddress?.address?.email}`,
                                                                                //     // "payer_id": "6GBL6N2VQWF4J",
                                                                                //     // "address": {
                                                                                //     //     "country_code": "US"
                                                                                //     // }
                                                                                // },

                                                                                purchase_units: [
                                                                                    {
                                                                                        amount: {
                                                                                            // value: cart?.cartTotal + '',
                                                                                            value: getTotalAmout().toLocaleString("en-US") || '$0.00',
                                                                                            currency_code: "USD",
                                                                                            breakdown: getBreakDownInfo(),
                                                                                            // shipping: {
                                                                                            //     // value: getTotalAmout()+'',
                                                                                            //     value: shippingMethod?.cost + '' || '0',
                                                                                            //     currency_code: "USD",
                                                                                            // },
                                                                                            // handling: getHandlingFee(),
                                                                                        },
                                                                                        items: getItems(),
                                                                                        // items: cart?.products,

                                                                                        shipping: {
                                                                                            name: {
                                                                                                full_name: `${billingAddress?.address?.firstName} ${billingAddress?.address?.lastName}`
                                                                                            },
                                                                                            // address: {
                                                                                            //     address_line_1: `${billingObj.house}, ${billingObj.street}`,
                                                                                            //     address_line_2: `${billingObj.city}, ${billingObj.state}`,
                                                                                            //     admin_area_1: '',
                                                                                            //     admin_area_2: '',
                                                                                            //     postal_code: billingObj.zip,
                                                                                            //     country_code: billingObj.country
                                                                                            // }
                                                                                        }
                                                                                    },
                                                                                ],
                                                                                // payment_source: {
                                                                                //     paypal: {
                                                                                //         experience_context: {
                                                                                //             payment_method_preference: "IMMEDIATE_PAYMENT_REQUIRED",
                                                                                //             payment_method_selected: "PAYPAL",
                                                                                //             brand_name: "EXAMPLE INC",
                                                                                //             locale: "en-US",
                                                                                //             landing_page: "LOGIN",
                                                                                //             shipping_preference: "SET_PROVIDED_ADDRESS",
                                                                                //             user_action: "PAY_NOW",
                                                                                //             return_url: "https://example.com/returnUrl",
                                                                                //             cancel_url: "https://example.com/cancelUrl"
                                                                                //         }
                                                                                //     }
                                                                                // }

                                                                            });
                                                                        }}
                                                                        onApprove={(data, actions) => {
                                                                            return actions.order.capture().then((details) => {
                                                                                payPalOrderSubmitHandler(details)
                                                                                const name = details.payer.name.given_name;

                                                                                localStorage.removeItem('cartToken');
                                                                                setOrderStatus({ ...orderStatus, success: true, error: false });
                                                                                setPaymentStatus({ ...paymentStatus, success: true, error: false });

                                                                                // redirectCustomer()
                                                                                // alert(`Transaction completed by ${name}`);
                                                                            });
                                                                        }}
                                                                    />
                                                                </PayPalScriptProvider>
                                                            </>
                                                            : "No Payment Gateway Activated"
                                                    }
                                                </Box>
                                                <Button
                                                    bg={`rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 1'})`}
                                                    color="white" border={`1px solid ${theme?.color?.accent}`}
                                                    _hover={{
                                                        bg: `rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 0.95'})`
                                                    }}
                                                    isFullWidth
                                                    onClick={orderSubmitHandler}
                                                    isLoading={loading}
                                                    loadingText="Processing..."
                                                    isDisabled={cart === null || (!loading ? false : true) || cartItem <= 0 || paymentRadioValue == 2}
                                                >
                                                    Place Order
                                                </Button>
                                            </Box>
                                        </Box>

                                    </Box>
                                </Stack>
                        }
                    </Box>
                </Container>
            </Box>

            {/* Address Modal */}
            <Modal isOpen={isOpen} onClose={onClose} size="xl" motionPreset='slideInBottom' scrollBehavior="outside">
                <ModalOverlay />
                <ModalContent py={4} mt="80px">
                    <ModalBody>
                        <AddressForm
                            handleAddressMapping={handleAddressMapping}
                            closeAddressModal={closeAddressModal}
                            register={register}
                            handleSubmit={handleSubmit}
                            errors={errors}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* Billing Address Modal */}
            <Modal isOpen={billIsOpen} onClose={billOnClose} size="xl" motionPreset='slideInBottom' scrollBehavior="outside">
                <ModalOverlay />
                <ModalContent py={4} mt="80px">
                    <ModalBody>
                        <BillingAddressForm
                            handleBillingAddress={handleBillingAddressSubmit}
                            onClose={billOnClose}
                            billingAddress={billingAddress}
                            shippingAddressList={shippingAddressList}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* Greeting Card Modal */}
            <Modal isOpen={greetingIsOpen} onClose={greetingOnClose} size="2xl" motionPreset='slideInBottom' scrollBehavior="outside">
                <ModalOverlay />
                <ModalContent py={4} mt="80px">
                    <ModalCloseButton />
                    <ModalBody>
                        {/* <GreetingCardForm
                            handleGreetingCard={handleGreetingCardSubmit}
                            onClose={greetingOnClose}
                            shippingId={shippingIdForGreeting}
                            greetingCardList={greetingCardList}
                        /> */}
                        {/* <GreetingCardSlider  
                            handleGreetingCard={handleGreetingCardSubmit}
                            onClose={greetingOnClose}
                            shippingId={shippingIdForGreeting}
                            greetingCardList={greetingCardList}
                        /> */}
                        <GreetingCardSlider
                            shippingAddressList={shippingAddressList}
                            onClose={greetingOnClose}
                            shippingId={shippingIdForGreeting}
                            greetingCardList={greetingCardList}
                            setShippingAddressList={setShippingAddressList}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* Order and payment status modal */}
            <Modal closeOnOverlayClick={false} onClose={onStatusClose} isOpen={isStatusOpen} isCentered>
                <ModalOverlay />
                <ModalContent w={{ base: "90%", sm: "90%" }}>
                    <ModalBody p={0}>
                        {
                            orderStatus.success
                                ?
                                <Alert
                                    status="success"
                                    variant="subtle"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    textAlign="center"
                                    height="auto"
                                    bg="white"
                                    rounded={6}
                                    pb={4}
                                >
                                    <AlertTitle mt={4} mb={1} fontSize="lg" color="teal.400">
                                        <Center mb={2}>
                                            <Image src={successImage} alt="Order Success" h={100} />
                                        </Center>
                                        Order successfully placed
                                    </AlertTitle>
                                    <AlertDescription maxWidth="sm" mt={4} fontSize=".9rem">
                                        <Box color="gray.600" mb={2}>
                                            <Text fontSize=".9rem" fontWeight="700" mb="2px">Your order number is: #{successMsg.orderNo}</Text>
                                            <Text fontSize=".7rem" fontWeight="700" lineHeight="14px">You will receive an email confirmation shortly to: {successMsg.email}</Text>
                                        </Box>
                                        {
                                            paymentStatus.success ?
                                                <Box mb={2}>
                                                    <Text fontSize=".9rem" fontWeight="700" color="teal.500" mb="2px">Payment success!</Text>
                                                    <Text fontSize=".7rem" fontWeight="700" color="gray.500" lineHeight="14px">Thanks, your card has been charged</Text>
                                                </Box>
                                                : paymentStatus.error ?
                                                    <Box mb={2}>
                                                        <Text fontSize=".9rem" fontWeight="700" color="red.500">Payment error!</Text>
                                                        <Text fontSize=".8rem" fontWeight="500" color="red.400" lineHeight="12px" mb={2}>{paymentStatus.errorMsg}</Text>
                                                        <Text fontSize=".8rem" fontWeight="600" color="teal.400" lineHeight="14px">You can paid later from your order page</Text>
                                                    </Box>
                                                    :
                                                    <Box mb={2}>
                                                        <Text fontSize=".9rem" fontWeight="700" color="gray.400" mb="2px">Payment processing...</Text>
                                                    </Box>
                                        }
                                        <Flex color="gray.500">
                                            <Box p="4" fontWeight="600">
                                                ORDER TOTAL
                                            </Box>
                                            <Spacer />
                                            <Box p="4" fontWeight="700">
                                                ${successMsg.total.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </Box>
                                        </Flex>
                                        <SimpleGrid minChildWidth="10rem">
                                            <Button
                                                isDisabled={(paymentStatus.success || paymentStatus.error) ? false : true}
                                                onClick={redirectCustomer}
                                                size="sm" colorScheme="teal"
                                                _focus={{ outline: "thin dotted" }}
                                                isFullWidth>Continue Shopping
                                            </Button>
                                        </SimpleGrid>
                                    </AlertDescription>
                                </Alert>
                                :
                                orderStatus.error ?
                                    <Alert
                                        variant="subtle"
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="center"
                                        textAlign="center"
                                        height="200px"
                                        rounded={6}
                                        bg="white"
                                        pb={4}
                                    >
                                        <AlertTitle mr={2} color="red.400" fontWeight="700" fontSize="1.4rem">
                                            <Center mb={2}>
                                                <Image src={orderError} h={100} />
                                            </Center>
                                            Sorry!
                                        </AlertTitle>
                                        <AlertDescription color="gray.600" fontWeight="700" fontSize="1rem">
                                            Order creation is failed!
                                        </AlertDescription>
                                    </Alert>
                                    :
                                    <Alert
                                        variant="subtle"
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="center"
                                        textAlign="center"
                                        height="200px"
                                        rounded={6}
                                        bg="white"
                                        pb={4}
                                    >
                                        <AlertTitle color="gray.600" fontWeight="700" fontSize="1.2rem">
                                            <Center p={5}>
                                                <Spinner
                                                    thickness="5px"
                                                    speed="0.75s"
                                                    emptyColor="gray.200"
                                                    color="teal.400"
                                                    size="xl"
                                                />
                                                <Text ml={4}>Processing....</Text>
                                            </Center>
                                        </AlertTitle>
                                    </Alert>
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )


}

export default StripeWrapper;