import React from 'react'; 
import { 
    Button, Text, Modal, ModalOverlay, ModalContent, 
    ModalHeader, ModalBody, ModalCloseButton, Box 
} from '@chakra-ui/react'; 

function DeleteConfirmation(props) { 
    let {
        header, confirmDelete, 
        isOpen, onClose, 
        isCentered, 
        summary = 'Are you sure, you want to delete?'
    } = props; 

    return ( 
        <Modal isOpen={isOpen} onClose={onClose} isCentered={isCentered}> 
            <ModalOverlay /> 
            <ModalContent m={5}> 
            <ModalHeader>{header}</ModalHeader> 
            <ModalCloseButton /> 
            <ModalBody> 
                <Text> 
                    {summary} 
                </Text> 
                <Box my={3}> 
                    <Button 
                        size="sm" colorScheme="red" variant="outline" 
                        onClick={() => { 
                            confirmDelete(); 
                            onClose(); 
                        }}> 
                    Yes 
                    </Button> 
                    <Button size="sm" ml={3} colorScheme="teal" variant="outline" onClick={onClose}> 
                        No 
                    </Button> 
                </Box> 
            </ModalBody> 
            </ModalContent> 
        </Modal> 
    ) 
} 

export default DeleteConfirmation; 