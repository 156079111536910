import { Badge, Box, Button, Container, Flex, FormControl, FormErrorMessage, FormLabel, HStack, Image, Input, Select as ChakraSelect, SimpleGrid, Text, Textarea, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
// import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios from 'axios';
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";
import "react-datepicker/dist/react-datepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import { config } from '../../config';
import useDomain from '../../service/DomainHook';
import { editorFontList } from '../../service/utils/FontList';
import { generateSlug } from '../../service/utils/helper';
import './blog.css';
import './Datepicker.css';
import AddTag from "./helpers/AddTag";
import dataURItoBlob from './helpers/dataURLtoBlob';
import PreviewHTML from './helpers/PreviewHTML';
import { uploadImage } from './helpers/uploadImage';

function Create() {
    const baseURL = `${config.baseUrl}/api/v1`;
    const token = localStorage.getItem('token');

    const toast = useToast();
    const history = useHistory();
    const [hasSubDomain] = useDomain();

    const [previewImage, setPreviewImage] = useState('');
    const [allTags, setAllTags] = useState([]);
    const [users, setUsers] = useState([]);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [rawHTML, setRawHTML] = useState(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenTag, onOpen: onOpenTag, onClose: onCloseTag } = useDisclosure();


    const statusOption = [
        { label: 'Publish Now', value: 'publish' },
        { label: 'Publish later', value: 'later' },
        { label: 'Unpublish', value: 'unpublish' } //default value
    ]

    const { register, handleSubmit, errors, control, getValues, setValue, watch } = useForm({
        mode: 'onSubmit',
        shouldFocusError: false,
        defaultValues: {
            tags: [],
            publish: true
        }
    });

    const publishOpt = watch('publishOpt');

    const [image, setImage] = useState(previewImage);
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();
    const [isCropped, setIsCropped] = useState(false);
    const [loading, setLoading] = useState(false);

    function imageHandler(e) {
        // To preview before upload --- Converting to base 64
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setPreviewImage(reader.result);
                setImage(reader.result);
            }
        };

        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0])
        };
    };

    const getCropData = () => {

        const id = 'img-crop-create';

        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas().toDataURL());
            setIsCropped(true);
            if (!toast.isActive(id)) {
                toast({
                    id,
                    title: "Image Cropped",
                    description: "Image Cropped Successfully",
                    status: "success",
                    duration: 500,
                    isClosable: true,
                });
            }

        };
    };


    useEffect(() => {
        setPreviewImage(cropData);
    }, [cropData]);

    const tagMapHandler = (tags) => {

        let newTags = [];
        if (tags.length) {
            newTags = tags.map(obj => {
                let item = {};
                item.value = obj._id;
                item.label = obj.name;
                return item;
            })
        };
        return newTags;
    };

    const getTagList = useCallback(() => {
        const franchiseeId = localStorage.getItem('franchiseeId');

        let url = franchiseeId !== 'null'
            ? `/tags?adminType=CA&franchiseeId=${franchiseeId}&sort=name`
            : '/tags?adminType=SA&sort=name';

        axios({
            url: url,
            method: 'get',
            baseURL: baseURL,
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(({ data }) => {
                let mappedTags = tagMapHandler(data.data)
                setAllTags(mappedTags);

            })
            .catch(err => console.log(err))
    }, []);

    // get users
    useEffect(() => {
        const fields = 'firstName';

        let url = `/users?role=SUPERADMIN&fields=${fields}&sort=name`;
        if (hasSubDomain) {
            url = `/users?franchiseeId=${franchiseeId}&sort=name`;
        }
        axios({
            url,
            method: 'get',
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
            .then((res) => {
                setUsers(res.data.data)
            })
            .catch(err => console.log(err, 'Data fetch error'))
    }, [])

    useEffect(() => getTagList(), []);

    const franchiseeId = localStorage.getItem('franchiseeId');

    const onSubmit = (data) => {

        if (data.featuredImage[0] && !isCropped) {
            toast({
                title: "Image Must Be Cropped",
                description: 'Crop Your Image',
                status: "error",
                duration: 3000,
                isClosable: true
            })
            return;
        };

        data.tags = data.tags?.map(type => type?.value);

        const formData = new FormData();

        if (franchiseeId !== 'null') {
            formData.append('adminType', 'CA');
            formData.append('franchiseeId', `${franchiseeId}`);
        }
        else {
            formData.append('adminType', 'SA');
        };

        formData.append('title', data.title);
        formData.append('authorId', data.author);
        formData.append('createdAt', data?.createdAt);

        // Always send cropped image
        if (data.featuredImage[0] && isCropped) {
            let blob = dataURItoBlob(previewImage);
            formData.append('image', blob);
        };

        formData.append('excerpt', data.excerpt);

        // update post status
        if (data?.publishOpt?.value === 'publish') {
            formData.append('status', 'publish');
        } else if (data?.publishOpt?.value === 'later') {
            formData.append('status', 'later');
        } else {
            formData.append('status', 'unpublish')
        }

        if (data?.publishOpt?.value === 'later') {
            formData.append('publishedAt', data?.publishDate);
        }

        // formData.append('body', draftToHtml(convertToRaw(editorState.getCurrentContent())));
        formData.append('body', draftToHtml(
            convertToRaw(editorState.getCurrentContent()),
            null,
            null,
            (entity) => {

                // if (entity.type === 'DIV') { // Receive what you passed before, here (like id, or classList, etc.)
                //     return `<div>${entity.data.innerHTML}</div>`;
                // }
                if (entity.type === 'SCRIPT') { // Receive what you passed before, here (like id, or keyEvents, etc.)
                    return `<script>${entity.data.innerHTML}</script>`;
                }
                if (entity.type === 'INS') { // Receive what you passed before, here (like id, or keyEvents, etc.)
                    // console.log("ENTRY:::", entity);
                    return `${entity.data.outerHTML}`;
                }
                return '';
            }
        ));
        // formData.append('publish', data.publish); 

        let tagList = JSON.stringify(data.tags);
        formData.append('tags', tagList);
        formData.append('slug', data.slug);

        setLoading(true);

        axios({
            url: '/posts',
            method: 'post',
            baseURL,
            data: formData,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({ data }) => {
                toast({
                    title: "Blog Post Created.",
                    description: "post created successfully",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
                setLoading(false);
                history.push(`/admin/blog/${data.data._id}`)
            })
            .catch(err => {
                toast({
                    title: "Post Not Created",
                    description: err.response.data?.message,
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
                setLoading(false);
            })
    };

    // upload image rich editor
    const uploadImageCallBack = async (file) => {
        const formData = new FormData();
        formData.append('image', file);
        const imageResponse = await uploadImage(formData, token);
        return new Promise(
            (resolve, reject) => {
                if (imageResponse.success) {
                    resolve({ data: { link: imageResponse?.url } });
                } else {
                    reject('image upload failed');
                }
            }
        );
    };

    const onProductNameChange = async (e) => {
        let getSlug = await generateSlug(e.target.value)

        setValue('slug', getSlug)
    }


    // JSX 
    return (
        <Container maxWidth={1100} centerContent py={6}>
            <Box w="100%" rounded="md" boxShadow='md' bg="white" p={4}>
                <Breadcrumb pathname="Blog" create={true} link={`/admin/blog`} mb={5} />
                <Text as="h1" fontSize="22px" fontWeight="600">
                    Create New Post
                </Text>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl mb="15px" isInvalid={errors.title} mt="20px">
                        <FormLabel as={'p'} fontWeight="semibold" m={0}>Title</FormLabel>
                        <Input
                            type="text"
                            name="title"
                            ref={register({ required: 'Title is required' })}
                            placeholder="Title"
                            variant="flushed" size="sm"
                            borderColor="#A0AEC0"
                            onChange={e => onProductNameChange(e)}
                        />
                        <FormErrorMessage> {errors.title?.message} </FormErrorMessage>
                    </FormControl>

                    <FormControl id="slug" mb="15px" isInvalid={errors.slug}>
                        <FormLabel as={'p'} fontWeight="semibold" m={0}>Blog slug</FormLabel>
                        <Input ref={register} variant="flushed" size="sm" type="text" name="slug" />
                        <FormErrorMessage mb="20px">{errors.slug && errors.slug.message}</FormErrorMessage>
                    </FormControl>

                    {/* Image */}

                    <FormControl isInvalid={errors.featuredImage} my="15px">
                        <Box>
                            <FormLabel htmlFor='featuredImage'
                                textAlign="center" cursor="pointer"
                                w='100%' h='100%'
                                lineHeight="60px"
                                border="1px dashed #BDBDBD"
                                // color="gray.500"
                                p='10px'
                            >
                                Click To Upload Feature Image
                            </FormLabel>
                            <Input
                                type="file"
                                name="featuredImage"
                                // ref={register}
                                ref={register({ required: 'Feature Image is required' })}
                                id='featuredImage'
                                accept="image/*"
                                onChange={(e) => imageHandler(e)}
                                style={{ display: "none" }} />

                            <Flex justify="space-between" align='flex-start'>
                                <Flex flexDirection='column' w="48%">
                                    <Box>
                                        {image ? <Cropper
                                            style={{ height: 350, width: "100%" }}
                                            zoomTo={0.0}
                                            initialAspectRatio={1}
                                            src={image}
                                            aspectRatio={1}
                                            viewMode={1}
                                            minCropBoxHeight={10}
                                            minCropBoxWidth={10}
                                            background={false}
                                            responsive={true}
                                            autoCropArea={1}
                                            cropBoxResizable={false}
                                            checkOrientation={false}
                                            onInitialized={(instance) => {
                                                setCropper(instance);
                                            }}
                                            guides={true}
                                        />
                                            : null}
                                    </Box>
                                    <Box alignSelf='flex-end' mt='5px'>
                                        {image ? <Button
                                            _hover={{ outline: 'none' }}
                                            _focus={{ outline: 'none' }}
                                            _active={{ bg: 'transparent', color: 'teal' }}
                                            colorScheme="teal" variant="outline" size="sm"
                                            onClick={getCropData}>
                                            Crop Image
                                        </Button> : null}
                                    </Box>
                                </Flex>
                                <Box w="48%">
                                    {image ? <Image src={previewImage} alt="Post featured image" maxH="350px" /> : null}
                                </Box>
                            </Flex>
                        </Box>
                        <FormErrorMessage mt="-15px"> {errors.featuredImage?.message} </FormErrorMessage>
                    </FormControl>

                    {/* Excerpt */}
                    <FormControl mb="15px" isInvalid={errors.excerpt} mt="20px">
                        <FormLabel as={'p'} fontWeight="semibold" m={0}>Excerpt</FormLabel>
                        <Textarea
                            type="text"
                            name="excerpt"
                            ref={register({ required: 'Excerpt is required' })}
                            placeholder="Excerpt"
                            variant="flushed" size="sm"
                            borderColor="#A0AEC0"
                        />
                        <FormErrorMessage> {errors.excerpt?.message} </FormErrorMessage>
                    </FormControl>

                    <span fontSize="0.8rem">
                        Press &nbsp;
                        <Badge colorScheme="red">Shift</Badge>
                        {` `} + {` `}
                        <Badge colorScheme="red">Enter</Badge>
                        , to take new line
                    </span>

                    <FormControl mb="10px" mt="20px">
                        <Flex m={0} justify="space-between" align='center'>
                            <FormLabel as={'p'} fontWeight="semibold">Body</FormLabel>
                            <Button bg='gray.300' size="sm" mb="10px" onClick={onOpen}>Preview HTML</Button>
                        </Flex>
                        <Editor
                            // handlePastedText={handlePastedText}
                            handlePastedText={() => false}
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName rich-editor"
                            onEditorStateChange={(editorState) => setEditorState(editorState)}
                            toolbar={{
                                fontFamily: {
                                    options: editorFontList,
                                    className: undefined,
                                    component: undefined,
                                    dropdownClassName: undefined,
                                },
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                                image: {
                                    uploadCallback: uploadImageCallBack,
                                    previewImage: true,
                                    alt: { present: true, mandatory: false },
                                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                    defaultSize: {
                                        height: '250px',
                                        width: 'auto'
                                    },
                                },
                            }}
                        />
                    </FormControl>

                    <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing={{ base: '10px', md: '20px', lg: '20px' }}>
                        <FormControl id="typeIds" mb="20px" isInvalid={errors.tags}>
                            <HStack mb={1}>
                                <FormLabel as={'p'} fontWeight="semibold">Tags</FormLabel>
                                <Button
                                    px='8px'
                                    lineHeight="16px"
                                    fontWeight='300'
                                    rounded='sm'
                                    fontSize='10px'
                                    _focus={{
                                        outline: 'none'
                                    }}
                                    colorScheme="gray"
                                    size="xs"
                                    onClick={onOpenTag}>Add New</Button>
                            </HStack>
                            <Controller
                                name="tags"
                                control={control}
                                options={allTags}
                                as={Select}
                                isMulti
                                defaultValue={``}
                                rules={{ required: true }}
                            />
                            <FormErrorMessage>{errors.tags?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl id="typeIds" mb="20px" borderBottom="1px solid #CBD5E0">
                            <FormLabel as={'p'} fontWeight="semibold">Created At</FormLabel>
                            <Controller
                                name="createdAt"
                                control={control}
                                // rules = {{ required: 'Date is required' }} 
                                defaultValue={Date.now()}
                                render={({ onChange, value }) => (
                                    <DatePicker
                                        placeholderText="Select date"
                                        dateFormat="MMMM d, yyyy h:mm aa" //new
                                        // showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="scroll"
                                        selected={value}
                                        closeOnScroll
                                        isClearable
                                        timeIntervals={30}
                                        // minDate={null} 
                                        showTimeSelect
                                        onChange={onChange}
                                    />
                                )}
                            />
                        </FormControl>
                    </SimpleGrid>

                    <Flex >
                        {/* Status*/}
                        <FormControl id="typeIds" mb="30px" mr="15px">
                            <FormLabel as={'p'} fontWeight="semibold">Status</FormLabel>
                            <Controller
                                name="publishOpt"
                                control={control}
                                options={statusOption}
                                as={Select}
                                defaultValue={{ label: 'Unpublish', value: 'unpublish' }}
                                rules={{ required: true }}
                            />
                        </FormControl>

                        {/* Post Schedule - Date picker */}
                        <FormControl mb={3} isInvalid={errors?.publishDate}>
                            {
                                publishOpt?.value === 'later' &&
                                <>
                                    <Box borderBottom={errors?.publishDate ? '2px solid #E53E3E' : "1px solid #CBD5E0"} m={0} p={0}>
                                        <FormLabel as={'p'} fontWeight="semibold">Schedule</FormLabel>
                                        <Controller
                                            name="publishDate"
                                            control={control}
                                            rules={{ required: 'Date is required' }}
                                            defaultValue={null}
                                            render={({ onChange, value }) => (
                                                <DatePicker
                                                    placeholderText="Select date"
                                                    // dateFormat="yyyy-MM-dd, h:mm aa"
                                                    dateFormat="MMMM d, yyyy" //new
                                                    selected={value}
                                                    closeOnScroll
                                                    isClearable
                                                    showTimeSelect
                                                    timeIntervals={30}
                                                    minDate={new Date()}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <FormErrorMessage>
                                        {errors.publishDate?.message}
                                    </FormErrorMessage>
                                </>
                            }
                        </FormControl>

                    </Flex>
                    {/* users */}
                    <FormControl id="typeIds" mb="20px" isInvalid={errors.author}>
                        <FormLabel as={'p'} fontWeight="semibold">Author</FormLabel>

                        <ChakraSelect name='author' w='50%' ref={register}>
                            {users && users.map(author => (
                                <option value={author._id} key={author._id}>{author.firstName}</option>
                            ))}
                        </ChakraSelect>

                        <FormErrorMessage>{errors.author?.message}</FormErrorMessage>
                    </FormControl>


                    <Button isLoading={loading} loadingText='Saving...' type="submit" colorScheme="teal" variant="outline" size="sm">
                        Save
                    </Button>
                </form>

                {/* modal for tag add */}
                <AddTag
                    getTagList={getTagList}
                    getValues={getValues}
                    setValue={setValue}
                    isOpen={isOpenTag} onClose={onCloseTag}
                />

                {/* modal for html preview */}
                <PreviewHTML
                    isOpen={isOpen}
                    onClose={onClose}
                    editorState={editorState} setEditorState={setEditorState}
                    rawHTML={rawHTML} setRawHTML={setRawHTML}
                />
            </Box>
        </Container>
    )
}

export default Create;
