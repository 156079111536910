import axios from 'axios';
import React, {useState, useRef, useCallback} from 'react'
import {config} from '../../config'
import { Container, Box, Link,
 Flex, Spacer, Button
} from '@chakra-ui/react';
import { Link as RouteLink, useRouteMatch } from 'react-router-dom';
import BussinessTypeTable from './BussinessTypeTable'
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb'

function BusinessTypeLists() { 
    const[businessTypes, setBusinessTypes] = useState([]);
    const baseURL = `${config.baseUrl}/api/v1`;

    let { url } = useRouteMatch();

    const [pageCount, setPageCount] = useState(0) 
    const fetchIdRef = useRef(0) 

    let columns = [ 
        { 
            Header: 'Name', 
            accessor: 'name'
        } 
    ] 

    let sortOn = ['Name']; 


    const fetchData = useCallback(({ pageSize, pageIndex, globalFilter, skipPageResetRef }) => { 
        const fetchId = ++fetchIdRef.current 

        if(globalFilter) { 
          // Filtering on name field 
            axios({ 
                url: `/businessTypes?name=${globalFilter}`, 
                method: 'get', 
                baseURL: baseURL 
            }) 
            .then((res) => { 
                const {data: serverData, result: totalLength} = res.data 
                if (fetchId === fetchIdRef.current) { 
                    if(serverData.length >= 1) { 
                    skipPageResetRef.current = true 
                    setBusinessTypes(serverData) 
                    setPageCount(Math.ceil(totalLength/pageSize)) 
                    } 
                } 
            }) 
            .catch(err => console.log(err, 'Data fetch error')) 
        } 
        else { 
          // Pagination 
            axios({ 
                url: `/businessTypes?page=${(pageIndex)+1}&limit=${pageSize}`, 
                method: 'get', 
                baseURL: baseURL 
            }) 
            .then((res) => { 
                const {data: serverData, result: totalLength} = res.data 
                if (fetchId === fetchIdRef.current) { 
                    skipPageResetRef.current = true 
                    setBusinessTypes(serverData) 
                    setPageCount(Math.ceil(totalLength/pageSize)) 
                } 
            }) 
            .catch(err => console.log(err, 'Data fetch error')) 
        } 
    }, []) 

    return ( 
        <Container maxWidth={1100} centerContent py={6}> 
            <Box w="100%" bg="white" rounded="lg" boxShadow="lg" p={4}> 
                <Flex> 
                    <Box> 
                        <Breadcrumb pathname="Business Type" />
                    </Box> 
                    <Spacer /> 
                    <Box> 
                        <Button colorScheme="teal" variant="outline" size="sm"> 
                            <Link color="blue.500" lineHeight="32px" as={RouteLink} to={`${url}/create`}>Create new</Link> 
                        </Button> 
                    </Box> 
                </Flex> 


                <BussinessTypeTable 
                    data={businessTypes} 
                    columns={columns} 
                    pageCount={pageCount} 
                    fetchData={fetchData} 
                    sortOn={sortOn} 
                    defaultPageSize={20} 
                    tableHeightInPage='50vh' 
                    selectNoOfRows={[5, 10, 20, 30, 50, 100]} 
                /> 
            </Box> 
        </Container>
    )
}

export default BusinessTypeLists
