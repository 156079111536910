import React, { useEffect, useState } from 'react';
import {
  Badge,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Button,
  Flex,
  Box,
  Code,
  Text,
} from '@chakra-ui/react';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { FaRegWindowClose, FaRegSave } from 'react-icons/fa';
import { customChunkRenderer } from '../../../service/utils/helper';

const PreviewHTML = ({
  isOpen,
  onClose,
  editorState,
  setEditorState,
  rawHTML,
  setRawHTML,
}) => {
  const toast = useToast();
  const [editorType, setEditorType] = useState('page');

useEffect(() => {
  if(window.location.pathname.search("blog") != -1){
    setEditorType('blog');
  }
}, [])

  const saveHTMLToEditor = () => {
    const contentBlock = htmlToDraft(rawHTML, customChunkRenderer);

    if (
      (contentBlock && rawHTML.length > 8) ||
      (contentBlock && rawHTML.length === 0)
    ) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }

    toast({
      title: 'Content Updated.',
      description: 'HTML content updated successfully',
      status: 'success',
      position: 'top',
      duration: 3000,
      isClosable: true,
    });
  };

  React.useEffect(() => {}, [rawHTML]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>HTML Preview</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <>
            <Box my={2} fontSize="1rem">
              Press &nbsp;
              <Badge colorScheme="red">Ctrl</Badge>
              {` `} + {` `}
              <Badge colorScheme="red">A</Badge>, to select all content
            </Box>
            {editorType == 'blog' ? 
              <>
                <Code colorScheme="teal" mt={2} mb={4}>
                  {'Insert <ins ...></ins> code snipet from AdSense where you want to show your ads'}
                </Code>
              </>
               : 
               <>
               <Code colorScheme="teal" mt={2} mb={4}>
                  {'Insert <ins ...></ins> code snipet from AdSense where you want to show your ads'}
                </Code>
               <Text colorScheme="green" fontSize={'md'}>
                Add this shortcode to add contact form
                </Text>
                <Code colorScheme="teal" mt={2} mb={4}>
                  {'<p>[contact-us id="contactUs"]</p>'}
                </Code>
               </>
              
            }
            
            <Textarea
              variant="filled"
              minH="60vh"
              w="100%"
              focusBorderColor="green.500"
              defaultValue={draftToHtml(
                convertToRaw(editorState.getCurrentContent()),
                null,
                null,
                (entity) => {
                  // if (entity.type === 'DIV') { // Receive what you passed before, here (like id, or classList, etc.)
                  //     return `<div>${entity.data.innerHTML}</div>`;
                  // }
                  if (entity.type === 'SCRIPT') {
                    // Receive what you passed before, here (like id, or keyEvents, etc.)
                    return `<script>${entity.data.innerHTML}</script>`;
                  }
                  if (entity.type === 'INS') {
                    // Receive what you passed before, here (like id, or keyEvents, etc.)
                    // console.log("ENTRY:::", entity);
                    return `${entity.data.outerHTML}`;
                  }
                  return '';
                }
              )}
              onChange={(e) => setRawHTML(e.target.value)}
              // _focus={{ backgroundColor: '#ffffff'}}
              style={{ backgroundColor: '#ffffff', border: '1px solid' }}
              autoFocus={true}
            />
            <Flex justify="flex-end" mt={5} mb={3}>
              <Button
                leftIcon={<FaRegWindowClose />}
                colorScheme="red"
                mr={3}
                variant="outline"
                onClick={onClose}>
                Close
              </Button>
              <Button
                leftIcon={<FaRegSave />}
                colorScheme="green"
                variant="outline"
                onClick={saveHTMLToEditor}>
                Save
              </Button>
            </Flex>
          </>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PreviewHTML;
