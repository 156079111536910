import axios from "axios"
import {config} from '../config'

const baseUrl = config.baseUrl;

export const getCategories = async (params) => {
    try {
        const response = await axios.get(`${baseUrl}/api/v1/categories`);
        return response.data;    
      } catch (error) {
        console.error(error);
      }
}