
import { useEffect } from 'react';
import {
    Box, Select, Button, chakra, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Flex, Table, Thead, Tbody,
    Tr, Th, Td, Heading
} from '@chakra-ui/react';
import { useTable, usePagination } from 'react-table';
import { Scrollbars } from 'react-custom-scrollbars';
import useDomain from '../../service/DomainHook';



const TableComponent = ({ columns, data, onFetchData, pageCount, title }) => {
    const [hasSubDomain] = useDomain();
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,


        state: { pageIndex, pageSize },
        preGlobalFilteredRows,
        setGlobalFilter,
        visibleColumns,

    } = useTable({
        columns,
        data,


        onFetchData,
        pageCount,
        initialState: { pageIndex: 0 },
        manualPagination: true,

    },


        usePagination,
    );

    // useEffect(() => {

    //     onFetchData({ pageIndex, pageSize })
    // }, [onFetchData, pageIndex, pageSize])

    return (
        <Flex flexDirection="column">

            <Heading size="md" textAlign="center" mb={6}>{title}</Heading>
            <Box h='40vh' overflow="auto">
                <Scrollbars autoHide autoHideTimeout={500} autoHideDuration={200}>

                    <Table {...getTableProps()}>
                        <Thead>

                            {headerGroups.map(headerGroup => (
                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <Th
                                            fontSize=".8rem"

                                            {...column.getHeaderProps()}>{column.render('Header')}
                                            <div>{column.canFilter ? column.render("Filter") : null}</div>


                                        </Th>

                                    ))}
                                </Tr>
                            ))}
                        </Thead>
                        <Tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (

                                    <Tr {...row.getRowProps()}
                                        cursor="pointer"
                                        _hover={{ background: '#0000000a' }}>
                                        {row.cells.map(cell => {
                                            return <Td
                                                fontSize=".8rem"
                                                {...cell.getCellProps()} onClick={() => console.info(row.values)}>{cell.render('Cell')}</Td>
                                        })}
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </Scrollbars>
            </Box>

            {/* Pagination */}
            {
                hasSubDomain ?
                    <Box p="20px">
                        <Flex flexWrap="wrap">
                            <Button
                                onClick={() => gotoPage(0)}
                                size="xs"
                                mr="10px" colorScheme="teal"
                                variant="outline"
                                disabled={!canPreviousPage}
                            >
                                {`<<`}
                            </Button>
                            <Button
                                onClick={() => previousPage()}
                                size="xs"
                                mr="10px" colorScheme="teal"
                                variant="outline"
                                disabled={!canPreviousPage}
                            >
                                {`<`}
                            </Button>
                            <Button
                                onClick={() => nextPage()}
                                size="xs"
                                mr="10px" colorScheme="teal"
                                variant="outline"
                                disabled={!canNextPage}
                            >
                                {`>`}
                            </Button>
                            <Button
                                onClick={() => gotoPage(pageCount - 1)}
                                size="xs"
                                mr="10px" colorScheme="teal"
                                variant="outline"
                                disabled={!canNextPage}
                            >
                                {`>>`}
                            </Button>

                            <chakra.span mr="10px" fontWeight="600">
                                {pageIndex + 1} of {pageCount}
                            </chakra.span>

                            <chakra.span>
                                | Go to page &nbsp;
                            </chakra.span>
                            <NumberInput
                                defaultValue={pageIndex + 1}
                                min={1} max={pageCount}
                                onChange={(value) => gotoPage((+value) - 1)}
                                w="80px" size="sm" mr="20px">
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>

                            <Box>
                                <Select
                                    value={pageSize} variant="flushed" size="sm"
                                    w="110px" colorScheme="red"
                                    onChange={e => setPageSize(Number(e.target.value))}>
                                    {
                                        [3, 5, 10, 20, 30].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>
                                                Show {pageSize}
                                            </option>
                                        ))
                                    }
                                </Select>
                            </Box>
                        </Flex>
                    </Box>
                    : null

            }
        </Flex>
    )
}

export default TableComponent;