import React, { useState, useEffect } from 'react'
import { Container, Flex, Box, Text, SimpleGrid } from '@chakra-ui/react'
import axios from 'axios';
import {  useParams } from 'react-router-dom'
import { config } from '../../config';
import shop from '../../img/shop.jpg'
import Product from './Product';
import ShopSkeleton from './ShopSkeleton';
import DomainUtils from '../../service/utils/DomainUtils'; 


const style = {
    backgroundImage: `url(${shop})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover'
}

function Category(props) {

    const [ loading, setLoading ] = useState(true);
    const [ products, setProducts ] = useState([]);

    const { sourceId, sourceName } = props.location.state;
    const { sourceType, slug } = useParams();
    const baseURL = `${config.baseUrl}/api/v1`;

    useEffect(async () => {

        window.scrollTo(0, 0);

        const [ franchiseeId ] = await DomainUtils(); 

        /*
        *---------------------------------------------------
        *   Get all products by category
        *---------------------------------------------------
        */
        const url = getUrl(franchiseeId);
        if (url) {
           axios.get(`${baseURL}/${url}`)
           .then(response => {
               setProducts(response.data.data);
               setLoading(false);
           })
           .catch(error => {
               console.log(error);
               setLoading(false);
            })
        }

    }, [])

    const getUrl = (franchisee) => { 
        let url = '';
        switch (sourceType) {
            case 'category':
                url = `products?franchiseeId=${franchisee}&categories=${sourceId}&fields=images,name,priceType,price,minPrice,maxPrice,slug`
                break;
            case 'business':  
                url = `products?franchiseeId=${franchisee}&businessId=${sourceId}&fields=images,name,priceType,price,minPrice,maxPrice,slug`
                break;
            default:
                url = '';
                break;
        }
        return url;
    }

    return (
        <Box>
            
            <Box h="350px" bg="teal.300" mt="55px" style={style}> 
                <Flex justifyContent="center" h="100%"> 
                    <Text 
                        color="white" fontSize="40px" 
                        fontWeight="600" alignSelf="center"> 
                        {sourceName}
                    </Text> 
                </Flex> 
            </Box> 

            <Container maxW={1140} mt="15px" py={4}>
                {
                    loading ? (
                        <ShopSkeleton header={false} item={2} />
                    ): (
                        <Box>
                            <Text as="h3" color="gray.700" mb="30px" fontWeight="600" fontSize="1.3em">{sourceName}</Text>
                            <SimpleGrid minChildWidth="13rem" spacingX="15px" spacingY="25px">
                                {
                                    products.map(product => {
                                        return (
                                            <Box key={product._id}>
                                                <Product product={product} />
                                            </Box>
                                        )
                                    })
                                }
                            </SimpleGrid>
                        </Box>
                    )
                }
            </Container>
        </Box>
    )
}

export default Category
