import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react'
import axios from 'axios'
import { config } from '../../config'
import MetaDecorator from '../../components/metaDecorator';
import BlogListComponent from './BlogListComponent';
import useDomain from '../../service/DomainHook';
import DomainUtils from '../../service/utils/DomainUtils';
import BlogListSkeleton from './BlogListSkeleton';


function BlogListFetchData(props) {

    let { fetchAllUrl, id } = props;
    fetchAllUrl = fetchAllUrl + id;

    const baseURL = `${config.baseUrl}/api/v1`;

    let loading = useRef(true);
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [hasSubDomain] = useDomain();
    const [isEmpty, setIsEmpty] = useState(false);
    const limit = 10;

    const fetchBlogData = async () => {

        let franchisee = null;
        if (hasSubDomain) {
            const [franchiseeId] = await DomainUtils();
            franchisee = franchiseeId;
        }
        const params = {}

        if (hasSubDomain) {
            params.adminType = 'CA';
            params.franchiseeId = franchisee;
        }
        else {
            params.adminType = 'SA';
        }
        params.page = 1;
        params.limit = limit;
        params.status = 'publish';
        params.fields = 'tags,author,title,createdAt,featureImageUrl,excerpt,slug'
        params.sort = '-createdAt'
        axios({
            url: fetchAllUrl,
            method: 'get',
            baseURL: baseURL,
            params: params
        })
            .then(({ data }) => {
                let orgData = data.data;
                setList(orgData);
                loading.current = false;
                setPage(1);
                setTotal(data.result);
                if (data.result <= 0) {
                    setIsEmpty(true);
                } else {
                    setIsEmpty(false);
                }
                window.scrollTo(0, 0)
            })
            .catch(err => console.log(err))
    }


    useEffect(() => {
        fetchBlogData();
    }, [fetchAllUrl])

    const setNewPage = (data) => {
        data.selected = data.selected + 1;
        setPage(data.selected)
    }

    const fetchData = async () => {
        let franchisee = null;
        if (hasSubDomain) {
            const [franchiseeId] = await DomainUtils();
            franchisee = franchiseeId;
        }

        const params = {}
        if (hasSubDomain) {
            params.adminType = 'CA';
            params.franchiseeId = franchisee;
        }
        else {
            params.adminType = 'SA';
        }
        params.page = page;
        params.limit = limit;
        params.status = 'publish';
        params.fields = 'tags,author,title,createdAt,featureImageUrl,excerpt,slug'
        params.sort = '-createdAt'
        axios({
            url: fetchAllUrl,
            method: 'get',
            baseURL: baseURL,
            params: params
        })
            .then(({ data }) => {
                loading.current = false;
                setList(data.data);
                setTotal(data.result)
                window.scrollTo(0, 0);
            })
            .catch(err => console.log(err))
    }

    return (
        <Box>
            <MetaDecorator
                title="Gift Your Town | Blog"
                description="Gift your town blog."
            />
            {
                loading.current ? (
                    <BlogListSkeleton />
                ) : (

                    <BlogListComponent
                        list={list}
                        total={total}
                        limit={limit}
                        page={page}
                        isEmpty={isEmpty}
                        fetchData={fetchData}
                        setNewPage={setNewPage}
                        fetchAllUrl={props.fetchAllUrl}
                        loading={loading}
                    />
                )
            }
        </Box>
    )
}

export default BlogListFetchData; 