import styled from '@emotion/styled'

export const ClampText = styled.p`
    width: 100%;
    color: ${ props => props.color ? props.color  : '#616161'};
    font-size: ${ props => props.size ? props.size  : '1rem'};
    font-weight: ${ props => props.weight ? props.weight  : '400'};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${ props => props.line ? props.line : 1};
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ClampTitle = styled.h4`
    width: 100%;
    color: ${ props => props.color ? props.color  : '#4A5568'};
    font-size: ${ props => props.size ? props.size  : '1rem'};
    font-weight: ${ props => props.weight ? props.weight  : '400'};
    line-height:  ${ props => props.lineHeight ? props.lineHeight  : '1.2rem'};
    cursor: pointer;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${ props => props.line ? props.line : 1};
    overflow: hidden;
    text-overflow: ellipsis;
`;