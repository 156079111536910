/**
 * Splits a string in half by tag name
 * i.e. '<p>' or 'p'
 * returns array of 2 strings
 *
*/
export const splitTextByTag = (text, tag) => {
    let closingTag = '';
    // clean text from line breaks
    let cleanText = text.replace(/\r?\n|\r/g, '');
    let resultArray = [];
  
    // Add openning bracket if not specified
    if (tag.indexOf('<') === -1) {
      closingTag = '<';
    }
    // Add closing slash if not specified
    if (tag.indexOf('/') === -1) {
      // case when specifying tag without slash i.e. <p>
      if (tag.indexOf('<') !== -1) {
        closingTag =  ['</', tag.slice(1)].join('');
      } else {
        closingTag += '/';
      }
    }
    // Add closing bracket if not specified
    if (tag.indexOf('>') === -1) {
      closingTag += tag + '>';
    }

    // Split by closing tag
    let splittedArray = cleanText && cleanText.split(closingTag);
    console.log('splittedArray',splittedArray);
    // Remove empty elements
    splittedArray = splittedArray.filter((text) => text.length)
    // add back tag removed by split
    splittedArray = splittedArray.map((text,index) => index != splittedArray.length - 1 ? text + closingTag : splittedArray.length == 1 ? text + closingTag : text);
    // get first block of text
    // resultArray[0] = splittedArray[0]
    // // join the rest if any
    // if (splittedArray.length > 1) {
    //   splittedArray.shift()
    //   resultArray[1] = splittedArray.join('')
    // }
    const body = [];
    const insReg = /<ins ?(.*)|(\<\/ins>)/g;
     splittedArray.map((content)=>{
        const match = content.match(insReg);
        if(match){
            body.push({type: 'html', content: content.replace(insReg,'')})
            body.push({type: 'ads', content: match[0]})
        }else{
            body.push({type: 'html', content: content})
        }
     } )
    
    return body;
  }