import React from 'react';
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
    Box, Container, Input, Button, useToast,
    FormControl, FormLabel, FormErrorMessage
} from '@chakra-ui/react'
import axios from 'axios'
import { config } from '../../config'
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';



function Create() {
    const baseURL = `${config.baseUrl}/api/v1`;
    const token = localStorage.getItem('token');
    const toast = useToast();
    const history = useHistory();

    const { register, handleSubmit, errors } = useForm({
        mode: 'onTouched',
        shouldFocusError: false
    })

    const submitHandler = (data) => {
        const franchiseeId = localStorage.getItem('franchiseeId');


        data.adminType = 'SA';

        if (franchiseeId !== "null") {
            data.adminType = "CA";
            data.franchiseeId = franchiseeId;
        }

        axios({
            url: '/tags',
            method: 'post',
            baseURL: baseURL,
            headers: { 'Authorization': `Bearer ${token}` },
            data
        })
            .then((res) => {
                toast({
                    title: "Tag created successfully",
                    description: "You have just created a tag successfully",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                })

                history.push(`/admin/tag`);
            })
            .catch((err) => {
                if(err.response){
                    toast({
                        title: "Error!",
                        description: err.response.data.message,
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    })
                }
            })
    }

    return (
        <>
            <Container maxWidth={1100} centerContent my={6}>
                <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg">
                    <Breadcrumb pathname="Tag" create={true} link={`/admin/tag`} mb={5} />

                    <form onSubmit={handleSubmit(submitHandler)}>
                        <FormControl mb="15px" isInvalid={errors.name}>
                            <FormLabel m={0}> Name </FormLabel>
                            <Input
                                ref={register({ required: 'Tag Name is required' })}
                                variant="flushed" borderColor="gray.300" size="sm"
                                name="name" type="text" />
                            <FormErrorMessage mb="20px">
                                {errors?.name?.message}
                            </FormErrorMessage>
                        </FormControl>

                        <Button type="submit" colorScheme="teal" variant="outline" size="sm" rounded={4}>
                            Save
                        </Button>
                    </form>
                </Box>
            </Container>
        </>
    )
}

export default Create;











// -------------------------------------------------------------------------
// -------------------------------------------------------------------------
// -------------------------------------------------------------------------
// -------------------------------------------------------------------------


// import React, {useState, useEffect, useRef} from 'react';
// import {useHistory} from 'react-router-dom'
// import {useForm} from 'react-hook-form'
// import {
//     Box, Container, Input, Button, useToast, Radio, RadioGroup, Stack, Text
// } from '@chakra-ui/react'
// import axios from 'axios'
// import {config} from '../../config'
// import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';

// function Create() { 
    // const baseURL = `${config.baseUrl}/api/v1`;
    // const token = localStorage.getItem('token');
    // const toast = useToast();
    // const history = useHistory();
//     const ref = useRef(null) 

//     const { register, handleSubmit, errors, control, watch } = useForm({ 
//         mode: 'onTouched', 
//         shouldFocusError: false 
//     }) 

//     useEffect(() => { 
//         ref.current.focus(); 
//     }, []);

//     const [tagName, setTagName] = useState('');
//     const [tagType, setTagType] = useState('BLOG');

//     const submitHandler = () => { 
//         let data = { name: tagName, tagType: tagType } 
//         tagName ? ( 
//             axios({ 
//                 url: '/tags', 
//                 method: 'post', 
//                 baseURL: baseURL, 
//                 headers: {'Authorization': `Bearer ${token}`}, 
//                 data 
//             }) 
//             .then((res) => { 
//                 toast({ 
//                     title: "Tag created.", 
//                     description: "You have just created a tag successfully", 
//                     status: "success", 
//                     duration: 2500, 
//                     isClosable: true, 
//                 }) 

//                 history.push(`/admin/tag`); 
//             }) 
//             .catch((err) => console.log('Error', err) ) 
//         ) 
//         : (
//             toast({ 
//                 title: "Error Occured.",
//                 description: "Unable to create empty tag!",
//                 status: "error",
//                 duration: 2500,
//                 isClosable: true,
//             }) 
//         )
//     } 

//     return ( 
//         <Container maxWidth={1100} centerContent my={6}> 
//             <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg"> 
//                 <Breadcrumb pathname="Tag" create={true} link={`/admin/tag`} mb={5} /> 
//                 <Input 
//                     type="text" mb={3} display="block" ref={ref} 
//                     onChange={ (e) => setTagName(e.target.value) } 
//                     placeholder="Enter a tag name" autoFocus 
//                     variant="flushed" borderColor="#a0a3a8" 
//                 /> 
//                 <Box my={4} display="flex">
//                     <Text mr={4}>Select a tag type :</Text>
//                     <RadioGroup onChange={setTagType} value={tagType}>
//                         <Stack direction="row">
//                             <Radio size="md" value="BLOG" >Blog Type</Radio>
//                             <Radio value="PRODUCT">Product Type</Radio>
//                         </Stack>
//                     </RadioGroup>
//                 </Box>
//                 <Button 
//                     onClick={submitHandler} 
//                     colorScheme="teal" variant="outline" size="sm" rounded={4}> 
//                     Save 
//                 </Button> 
//             </Box> 
//         </Container> 
//     ) 
// } 

// export default Create;

