export const editorFontList = [
    "ABeeZee",
    "Abel",
    "Abhaya Libre",
    "Abril Fatface",
    "Aclonica",
    "Acme",
    "Actor",
    "Adamina",
    "Advent Pro",
    "Aguafina Script",
    "AkayaKanadaka",
    "AkayaTelivigala",
    "Akronim",
    "Aladin",
    "Alata",
    "Alatsi",
    "Aldrich",
    "Alef",
    "Alegreya",
    "Alegreya Sans",
    "Alegreya Sans SC",
    "Alegreya SC",
    "Aleo",
    "Alex Brush",
    "Alfa Slab One",
    "Alice",
    "Alike",
    "Alike Angular",
    "Allan",
    "Allerta",
    "Allerta Stencil",
    "Allura",
    "Almarai",
    "Almendra",
    "Almendra Display",
    "Almendra SC",
    "Amarante",
    "Amaranth",
    "Amatic SC",
    "Amethysta",
    "Amiko",
    "Amiri",
    "Amita",
    "Anaheim",
    "Andada",
    "Andada Pro",
    "Andale Mono",
    "Andika",
    "Andika+New+Basic",
    "Angkor",
    "Anonymous Pro",
    "Antic",
    "Antic Didone",
    "Antic Slab",
    "Anton",
    "Antonio",
    "Arapey",
    "Arbutus",
    "Arbutus Slab",
    "Architects Daughter",
    "Archivo",
    "Archivo Black",
    "Archivo Narrow",
    "Aref Ruqaa",
    "Arial",
    "Arima Madurai",
    "ASAP Condensed",
    "Assistant",
    "Barlow",
    "BioRhyme",
    "Bitter",
    "Brawler",
    "Caladea",
    "Cardo",
    "Carme",
    "Chivo",
    "Comic Sans MS",
    "Cormorant",
    "Eczar",
    "Encode Sans",
    "Encode Sans Semi Condensed",
    "Enriqueta",
    "Epilogue",
    "Fira Sans",
    "Frank Ruhl Libre",
    "Gelasio",
    "Georgia",
    "Hahmlet",
    "Headland One",
    "Impact",
    "Inconsolata",
    "Inknut Antiqua",
    "Inter",
    "JetBrains Mono",
    "Karla",
    "Lato",
    "Libre Baskerville",
    "Libre Franklin",
    "Lora",
    "Manrope",
    "Merriweather",
    "Montserrat",
    "Neuton",
    "Nunito",
    "Nunito Sans",
    "Old Standard TT",
    "Open Sans",
    "Oswald",
    "Oxygen",
    "Playfair Display",
    "Poppins",
    "Proza Libre",
    "PT Sans",
    "PT Serif",
    "Raleway",
    "Roboto",
    "Roboto Slab",
    "Rokkitt",
    "Rubik",
    "Sora",
    "Source Sans Pro",
    "Source Serif Pro",
    "Space Grotesk",
    "Space Mono",
    "Spectral",
    "Symbol",
    "Tahoma",
    "Times New Roman",
    "Verdana",
    "Work Sans"
    
]
