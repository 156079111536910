import {
    Box, Button, Flex, FormControl, FormErrorMessage,
    FormLabel,
    Heading, Input, Modal, ModalBody,
    ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, useToast
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { config } from '../../config';

const EditSubCategories = ({ subCategories, setSubCategory, clickedLink, setClickedLink, payloadCatNames }) => {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure();
    const { register, handleSubmit, errors } = useForm({
        mode: 'onTouched',
        shouldFocusError: false
    });

    const { id: _id } = useParams();
    const baseUrl = config.baseUrl;
    const token = localStorage.getItem('token');
    const franchiseeId = localStorage.getItem('franchiseeId');
    const [selectedSubcategory, setSelectedSubcategory] = useState('');
    const toast = useToast();

    useEffect(() => {
        axios({
            url: `/api/v1/categories/${_id}`,
            method: 'get',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {

                getClickedLink(res.data.data);
            })
            .catch(err => console.log(err))
    }, []);


    const subCategorySubmit = async (data) => {

        setSubCategory([...subCategories, data.subcategoryName]);
        payloadCatNames.current.push(data.subcategoryName);

        onClose();
    };

    const getClickedLink = (category) => {

        if (!clickedLink.find(({ _id }) => _id === category._id)) {
            setClickedLink([...clickedLink, category]);
        }

    };

    const confirmDelete = () => {
        let url = `/categories/${selectedSubcategory}?adminType=SA`;

        if (franchiseeId !== 'null') {
            url = `/categories/${selectedSubcategory}?adminType=CA&franchiseeId=${franchiseeId}`;
        };

        const newCategories = subCategories.filter(({ _id }) => _id !== selectedSubcategory);

        axios({
            url: url,
            method: 'delete',
            baseURL: `${config.baseUrl}/api/v1`,
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then((res) => {
                toast({
                    title: 'Subcategory deleted successfully',
                    status: "success",
                    duration: 4000,
                    isClosable: true
                })
                setSubCategory(newCategories);
            })
            .catch((err) => {
              
                toast({
                    title: err?.response?.data?.message,
                    status: "error",
                    duration: 4000,
                    isClosable: true
                })
            });
    };

    const deleteLocalSubCategory = (name) => {

        const newCategories = subCategories.filter((cat) => cat !== name);
        setSubCategory(newCategories);

        const payloadCatNamesNew = payloadCatNames.current.filter((cat) => cat !== name);
        payloadCatNames.current = payloadCatNamesNew;

        toast({
            title: 'Subcategory deleted successfully',
            status: "success",
            duration: 4000,
            isClosable: true
        });
    };

    useEffect(() => {

    }, [subCategories, selectedSubcategory, payloadCatNames]);


    return (
        <Box>

            <Flex mt={10} mb={0}>
                <FormLabel as={'p'} fontWeight="semibold" mr={5}>Subcategories</FormLabel>
                <Button onClick={() => {
                    onOpen();
                }} colorScheme="teal" variant="outline" size="xs">Add</Button>
            </Flex>

            <Flex flexDirection='column' ml={5}>
                {subCategories && subCategories.map((category, i) => (
                    <Flex w="50%" justify="space-between" align='center' key={i} borderBottom='1px solid black' mb={2}>
                        <Link to={`/admin/category/${category._id}`} style={{
                            pointerEvents: category._id ? 'auto' : 'none'
                        }} >
                            <Box my={2} onClick={() => { getClickedLink(category) }}>
                                {category.name ? category.name : category}
                            </Box>
                        </Link>
                        {category._id ? <Text fontSize="1rem" cursor="pointer" onClick={() => {
                            deleteOnOpen();
                            setSelectedSubcategory(category._id);
                        }}>X</Text>
                            : <Text fontSize="1rem" cursor="pointer" onClick={() => {
                                deleteLocalSubCategory(category);
                            }}>X</Text>}
                    </Flex>
                ))}
            </Flex>

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add New Subcategory</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={handleSubmit(subCategorySubmit)} id="sub-cat-form">
                            <FormControl id="subcategoryName" mb="15px" isInvalid={errors.subcategoryName}>
                                <Input placeholder="Subcategory Name" ref={register({ required: true })}
                                    variant="flushed" borderColor="gray.300" size="sm"
                                    name="subcategoryName" type="text" />
                                <FormErrorMessage mb="20px">{errors.subcategoryName && "Sub category field is required"}</FormErrorMessage>
                            </FormControl>
                            <Button size="sm" colorScheme="red" variant="outline" mr={3} onClick={onClose} >
                                Cancel
                            </Button>
                            <Button colorScheme="teal" variant="outline" size="sm" type="submit">Add</Button>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>


            {/* Modal for delete */}
            <Modal isOpen={deleteIsOpen} onClose={deleteOnClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Delete Subcategory</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    <Text>
                     Are you sure, you want to delete?
                  </Text>
                    </ModalBody>

                    <ModalFooter>
                        <Button size="sm" colorScheme="teal" variant="outline" mr={3} onClick={deleteOnClose}>
                            No
                        </Button>
                        <Button size="sm" colorScheme="red" variant="outline" onClick={() => {
                            confirmDelete();
                            deleteOnClose();
                        }}>Yes</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    )
};

export default EditSubCategories;