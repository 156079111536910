import React, { useState } from 'react';

import {
    Box, Flex, Text, Textarea, Input, FormControl,
    FormLabel, Switch,
} from '@chakra-ui/react'

function Contact({ register, pageContact, errors }) {

    const [showMail, setShowMail] = useState(pageContact?.showForm);

    return (
        <Box bg="white" rounded="md" boxShadow="lg" p={{ base: '10px', md: '30px' }} mt="3rem" mb="2rem">
            <Text as="h1" fontSize="25px" mb="20px">
                Contact Section
            </Text>

            <Flex mb="20px">
                <FormControl>
                    <FormLabel fontWeight="600"> Title </FormLabel>
                    <Input
                        placeholder="Title"
                        variant="flushed"
                        name="contact_title"
                        ref={register()}
                    />
                </FormControl>
            </Flex>
            <Flex mb="20px">
                <FormControl>
                    <FormLabel fontWeight="600"> Details </FormLabel>
                    <Textarea
                        placeholder="Details"
                        name="contact_text"
                        variant="flushed"
                        ref={register()}
                    />
                </FormControl>

            </Flex>
            {showMail && <Flex mb="20px" flexDirection="column">
                <FormControl id='contact_recipient' isInvalid={errors.contact_recipient}>
                    <FormLabel fontWeight="600" htmlFor='contact_recipient'> Mail of Recipient  </FormLabel>
                    <Input
                        id='contact_recipient'
                        placeholder="Mail of Recipient"
                        variant="flushed"
                        name="contact_recipient"
                        type="text"

                        ref={register({
                            required: true,
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            }
                        })}
                    />
                    <Text mt='5px' color='crimson'>
                        {errors.contact_recipient?.type === 'required' && 'Email is required'}
                        {errors.contact_recipient?.type === 'pattern' && 'Invalid Email'}
                    </Text>

                </FormControl>
            </Flex>}

            <Flex mb="20px">
                <FormControl>
                    <FormLabel fontWeight="600"> Subscribe Checkbox Label </FormLabel>
                    <Input
                        placeholder="Checkbox label"
                        variant="flushed"
                        name="contact_checkbox_label"
                        ref={register()}
                    />
                </FormControl>
            </Flex>

            <FormControl display='flex' align='center'>
                
                    <FormLabel  htmlFor='show_form' mb='0'>
                        Show Form?
                    </FormLabel>
                <Switch
                    mt={1}
                        onChange={() => setShowMail(!showMail)}
                        defaultChecked={pageContact?.showForm} name='show_form' id='show-form' ref={register()} size='sm' />
        
            </FormControl>

        </Box>
    )
}

export default Contact;