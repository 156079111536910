import React, { useEffect, useRef, useState } from 'react';
import {
    Box, Text, Flex, Center, Alert,
    AlertIcon, AlertTitle, AlertDescription, background, Tag
} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons'
import { FaFilter } from 'react-icons/fa';
import { IoCloseOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../../config';
import ShopSkeleton from '../ShopSkeleton';
import { Scrollbars } from 'react-custom-scrollbars';
import FilterSidebar from './FilterSidebar';
import FetchProducts from './FetchProducts';

function ProductFilter(props) {
    const baseURL = `${config.baseUrl}/api/v1`;
    const theme = JSON.parse(localStorage.getItem('theme'));
    const { state } = props.location;

    const tags = state?.tags;
    const businesses = state?.allBusiness;
    const catTree = state?.catTree;

    // const category = state ? state.category ? state.category : null : null; 
    // const business = state ? state.business ? state.business : null : null; 
    // const tag = state ? state.tag ? state.tag : null : null; 

    // const [fillterCategories, setFilterCategories] = useState(category ? [category] : []); 
    // const [fillterBusiness, setFilterBusiness] = useState(business ? [business] : []); 
    // const [fillterTags, setFillterTags] = useState(tag ? [tag] : []); 




    let filterData = JSON.parse(localStorage.getItem('filterValue'));

    const category = filterData?.category?.length > 0 ? filterData?.category : null;
    const business = filterData?.business?.length > 0 ? filterData?.business : null;
    const tag = filterData?.tag?.length > 0 ? filterData?.tag : null;

    const [filterCategories, setFilterCategories] = useState(filterData?.category);
    const [filterBusiness, setFilterBusiness] = useState(filterData?.business);
    const [filterTags, setFilterTags] = useState(filterData?.tag);

    const [products, setProducts] = useState([]);
    const [isProductLoading, setIsProductLoading] = useState(true);
    const [cat, setCat] = useState([]);
    const [menuState, setMenuState] = useState(false);

    const history = useHistory();
    const filterInfo = useRef([]);
    const businessInfo = useRef([]);
    const tagsInfo = useRef([]);


    useEffect(() => {
        // filterData = JSON.parse(localStorage.getItem('filterValue'));
        setFilterCategories(filterData?.category)
        
        axios({ url: `/categories`, method: 'get', baseURL: baseURL })
            .then(res => setCat(res.data.data))
            .catch(err => console.log(err))
    }, [])


    // --------------------- // 
    // *** Product fetch *** // 
    // --------------------- // 

    const limit = 20;
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(10);

    const setNewPage = (data) => {
        data.selected = data.selected + 1;
        setPage(data.selected)
    }


    const fetchProduct = (page) => {
        if (filterCategories?.length || filterBusiness?.length || filterTags?.length) {
            setIsProductLoading(true);
            getCatName();
            getBusinessName();
            getTagsName();

            const data = {
                categories: filterCategories,
                businesses: filterBusiness,
                tags: filterTags
            }

            // const url = `/products/filter?isGreeting=false&publish=true&page=${page}&limit=${limit}&fields=-tags,-categories,-businessId,-details,-summary`
            const url = `/products/filter?publish=true&page=${page}&limit=${limit}&fields=-tags,-categories,-businessId,-details`
            axios({
                url: url,
                method: 'post',
                baseURL: baseURL,
                data
            })
                .then(res => {
                    setTotal(res.data.result);
                    setProducts(res.data.data);
                })
                .catch(error => console.log(error))
                .finally(() => setIsProductLoading(false))
        }
        else {
            history.push('/shop');
        }
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        fetchProduct(1);
    }, [filterCategories, filterTags]);


    const isFirstRun = useRef(true)
    useEffect(() => {
        window.scrollTo(0, 0);

        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        fetchProduct(page);
    }, [page]);

    // --------------------- // 
    // ***      END      *** // 
    // --------------------- // 




    const filterCategoryHandler = (e) => {
        let data;
    
        if (e.target.checked) {
            const newCategories = [...filterCategories];
            data = '';
            data = newCategories;
          
            newCategories.push(e.target.value);
            setFilterCategories(newCategories);
        } else {
            const newCategories = [...filterCategories].filter(value => value !== e.target.value);
            data = '';
            data = newCategories;
            setFilterCategories(newCategories);
        }

        let filterValue = JSON.parse(localStorage.getItem('filterValue'));
        filterValue.category = data;
        localStorage.setItem('filterValue', JSON.stringify(filterValue));
    }

    const filterBusinessHandler = (e) => {
        let data;
        if (e.target.checked) {
            const newBusiness = [...filterBusiness];
            newBusiness.push(e.target.value);
            data = newBusiness;
            setFilterBusiness(newBusiness);
        } else {
            const newBusiness = [...filterBusiness].filter(value => value !== e.target.value);
            data = newBusiness;
            setFilterBusiness(newBusiness);
        }

        let filterValue = JSON.parse(localStorage.getItem('filterValue'));
        filterValue.business = data;
        localStorage.setItem('filterValue', JSON.stringify(filterValue));
    }

    const filterTagsHandler = (e) => {
        let data;
        if (e.target.checked) {
            const newTags = [...filterTags];
            newTags.push(e.target.value);
            data = newTags;
            setFilterTags(newTags);
        } else {
            const newTags = [...filterTags].filter(value => value !== e.target.value);
            data = newTags;
            setFilterTags(newTags);
        }

        let filterValue = JSON.parse(localStorage.getItem('filterValue'));
        filterValue.tag = data;
        localStorage.setItem('filterValue', JSON.stringify(filterValue));
    }

    useEffect(() => {
        // getCatName = () => {} -- same works, 
        // works only on first load 

        const catNames = [];
        filterCategories.forEach(item => {
            const catItem = cat.find(value => value._id === item);
            catNames.push(catItem?.name);
        })
        filterInfo.current = catNames;
    }, [cat])

    const getCatName = () => {
        const catNames = [];
        filterCategories?.forEach(item => {
            const catItem = cat.find(value => value._id === item);
            catNames.push(catItem?.name);
        })
        filterInfo.current = catNames;
    }

    const getBusinessName = () => {
        const businessNames = [];
        filterBusiness?.forEach(item => {
            const businessItem = businesses.find(value => value._id === item);
            businessNames.push(businessItem?.name);
        })
        businessInfo.current = businessNames;
    }

    const getTagsName = () => {
        const tagNames = [];
        filterTags?.forEach(item => {
            const tagsItem = tags.find(value => value._id === item);
            tagNames.push(tagsItem?.name);
        })
        tagsInfo.current = tagNames;
    }


    return (
        <Box mt="70px">
            {/* Filter Icon */}
            <Box
                ml={5} bg="#edf2f9" p={3} w="fit-content"
                rounded="sm"
                display={{ base: 'block', sm: 'block', md: 'none', lg: 'none' }}
                onClick={() => setMenuState(!menuState)}
                cursor="pointer">
                <Icon as={FaFilter} />
            </Box>


            <Flex>
                <Box
                    flex="1" mt={4}
                    position={menuState && 'relative'}
                    zIndex="2"
                    // bg={menuState && 'white'} 
                    // w={menuState && '70%'} 
                    // rounded={menuState && 'lg'} 
                    // borderWidth={menuState && '2px'} 
                    // borderColor={menuState && 'gray.200'} 
                    display={{
                        base: menuState ? 'block' : 'none',
                        sm: menuState ? 'block' : 'none',
                        md: 'block', lg: 'block'
                    }}>

                    {
                        menuState
                            ?
                            <Box
                                position="fixed"
                                bg='white' w='70%' rounded='lg'
                                borderWidth='2px' borderColor='gray.200'>
                                <Scrollbars
                                    style={{ height: `${(window.innerHeight - 110)}px` }}
                                    autoHide
                                    autoHideTimeout={500}
                                    autoHideDuration={200}>
                                    <Box>
                                        <Icon
                                            as={IoCloseOutline}
                                            h={6} w={6} ml="auto" mr="10px" mt={1}
                                            display="block" color="gray.400" cursor="pointer"
                                            onClick={() => menuState && setMenuState(!menuState)} />
                                        <FilterSidebar
                                            filterCategoryHandler={filterCategoryHandler}
                                            catTree={catTree}
                                            category={category}
                                            filterBusinessHandler={filterBusinessHandler}
                                            businesses={businesses}
                                            business={business}
                                            filterTagsHandler={filterTagsHandler}
                                            tags={tags}
                                            tag={tag}
                                        />
                                    </Box>
                                </Scrollbars>
                            </Box>
                            :
                            <FilterSidebar
                                filterCategoryHandler={filterCategoryHandler}
                                catTree={catTree}
                                category={category}
                                filterBusinessHandler={filterBusinessHandler}
                                businesses={businesses}
                                business={business}
                                filterTagsHandler={filterTagsHandler}
                                tags={tags}
                                tag={tag}
                            />
                    }
                </Box>

                <Box flex="4" p={5} mt={2}
                    onClick={() => menuState && setMenuState(!menuState)}>
                    {/* Tag List */}
                    <Box>
                        {
                            filterInfo.current.length > 0 &&
                            <Flex wrap="wrap" mb={1}>
                                <Text mr={2} fontSize=".9rem" fontWeight="600">
                                    Products of category:
                                </Text>
                                {
                                    filterInfo?.current.map((item, i) => (
                                        <Box
                                            mb="8px" mr={2} px={2} rounded={2}
                                            bg={`rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', .5'})`}
                                            key={i}
                                            fontSize=".8rem" lineHeight="22px"
                                            letterSpacing="1px">
                                            {item}
                                        </Box>
                                    ))
                                }
                            </Flex>
                        }


                        {
                            businessInfo.current.length > 0 &&
                            <Flex wrap="wrap" mb={1}>
                                <Text mr={2} fontSize=".9rem" fontWeight="600">Products from business: </Text>
                                {
                                    businessInfo.current.map((item, i) => (
                                        <Box
                                            mb="8px" mr={2} px={2} rounded={2}
                                            bg={`rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', .5'})`}
                                            key={i}
                                            fontSize=".8rem" lineHeight="22px"
                                            letterSpacing="1px">
                                            {item}
                                        </Box>
                                    ))
                                }
                            </Flex>
                        }


                        {
                            tagsInfo.current.length > 0 &&
                            <Flex wrap="wrap" mb={4}>
                                <Text mr={2} fontSize=".9rem" fontWeight="600">
                                    Products from tags:
                                </Text>
                                {
                                    tagsInfo?.current?.map((item, i) => (
                                        <Tag
                                            mb="8px" mr={2} px={2} rounded={2}
                                            // bg={`rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', .5'})`}
                                            key={i}
                                            fontSize=".8rem" lineHeight="22px"
                                            letterSpacing="1px"
                                            _hover={{
                                                background: theme?.color?.accent
                                            }}
                                        >
                                            {item}
                                        </Tag>
                                    ))
                                }
                            </Flex>
                        }
                    </Box>

                    <Box>
                        {
                            products.length > 0 &&
                            <FetchProducts
                                products={products}
                                limit={limit}
                                total={total}
                                setNewPage={setNewPage}
                            />
                        }
                    </Box>

                    {
                        !isProductLoading && products.length === 0 &&
                        // products.length === 0 && 
                        <Alert
                            status="info" variant="subtle"
                            flexDirection="column" alignItems="center"
                            justifyContent="center" textAlign="center"
                            height="38vh"
                            // colorScheme="teal"
                            bg={`rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', .4'})`}
                        >
                            <AlertIcon boxSize="40px" mr={0} />
                            <AlertTitle mt={4} mb={1} fontSize="lg">
                                Sorry no product found!
                            </AlertTitle>
                            <AlertDescription maxWidth="sm">
                                No product has been added to this shop yet.
                            </AlertDescription>
                        </Alert>
                    }
                </Box>
            </Flex>



        </Box>
    )
}

export default ProductFilter; 