import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import {
    Box, Text, Flex, Icon, Image, Button, Tag
} from '@chakra-ui/react';
import { FaCalendarAlt, FaUserAlt, FaCommentAlt } from "react-icons/fa";
import axios from 'axios'
import { config } from '../../config'

function PostData(props) {
    const baseURL = `${config.baseUrl}/api/v1`;
    const { obj, address, date } = props;

    const history = useHistory();
    const [countComment, setCountComment] = useState(0);

    useEffect(() => {
        axios({
            url: `/blogComments?postId=${obj?._id}&page=1&limit=1`,
            method: 'get',
            baseURL: baseURL
        })
            .then(res => setCountComment(res.data?.result))
            .catch(err => console.log(err))
    }, [])

    const theme = JSON.parse(localStorage.getItem('theme'));

    const getDate = (date) => {
        let day = new Date(date).getDate();
        day = day > 9 ? day : `0${day}`;

        let month = new Date(date).getMonth() + 1;
        month = month > 9 ? month : `0${month}`;

        let year = new Date(date).getFullYear();

        return `${year}-${month}-${day}`;
    }

    return (
        <Box>

            <Flex gridGap={2} flexDirection={{ base: 'column', sm: 'row' }}>

                <Image
                    src={obj.featureImageUrl} alt={obj.title}
                    maxW={{ base: '100%', md: '40%' }} 
                    objectFit="cover" mt="10px" cursor="pointer"
                    h={{ base: 'auto', sm: '200px', md: '300px', lg: '400px' }}
                    rounded="4px"
                    onClick={() => history.push(`${address}/${obj.slug}`)} />

                <Box px={{ base: '0px', md: '15px' }}>

                    <Text
                        as="h1" fontSize={["18px", "18px", "20px", "25px"]} fontWeight="600" mb="10px" cursor="pointer"
                        onClick={() => history.push(`${address}/${obj.slug}`)}>
                        {obj.title}
                    </Text>


                    <Flex flexWrap="wrap" mb="10px" fontSize={["12px", "12px", "14px", "15px"]}>
                        <Box >
                            <Flex flexWrap="wrap">
                                <Icon as={FaCalendarAlt} mt="2px" /> &nbsp; &nbsp;
                                <Text mr="10px" color={theme?.color?.accent}>
                                    {`${date?.month} ${date?.day}, ${date?.year}`}
                                    {/* {getDate(obj?.createdAt)}  */}
                                </Text> |
                            </Flex>
                        </Box>

                        <Box>
                            <Flex flexWrap="wrap">
                                <Icon as={FaUserAlt} mt="2px" ml="10px" /> &nbsp; &nbsp;
                                <Text
                                    mr="10px"
                                    fontWeight="bold"
                                    color={theme?.color?.accent}
                                    cursor="pointer"
                                    _hover={{ filter: 'brightness(85%)' }}>
                                    {`${obj?.authorId?.firstName}`}
                                </Text>
                            </Flex>
                        </Box>

                        <Box>
                            <Flex flexWrap="wrap">
                                | <Icon as={FaCommentAlt} mt="2px"
                                    ml="10px" /> &nbsp; &nbsp;
                                <Text
                                    color={theme?.color?.accent}
                                    cursor="pointer"
                                    _hover={{ filter: 'brightness(85%)' }}>
                                    {
                                        countComment > 1
                                            ? `${countComment} comments`
                                            : `${countComment} comment`
                                    }
                                </Text>
                            </Flex>
                        </Box>
                    </Flex>
                    <Text mt="5px" fontSize={["14px", "14px", "15px", "16px"]}>
                        {obj.excerpt}
                        
                    </Text>
                    <Button

                        onClick={() => history.push(`${address}/${obj.slug}`)}
                        boxSizing="border-box"
                        cursor="pointer"
                        fontSize={["10px", "10px", "14px", "14px"]}
                        color={theme?.color?.accent}
                        bg="transparent"
                        transition="0.4s all ease"
                        h="1.5rem" px="0.3rem"
                        border={`1px solid ${theme?.color?.accent}`}
                        _hover={{
                            bg: "transparent",
                            // color: '#9a82f9',
                            // color={darken({theme?.color?.accent}, 10)}, 
                            filter: 'brightness(85%)',
                            border: `1px solid ${theme?.color?.accent}`
                        }}>
                        Read more
                    </Button>
                    {/* <Box mt='20px'>
                        {
                            obj.tags?.map((tag, i) => (
                                <Tag
                                    px='15px'

                                    key={i} cursor="pointer"
                                    fontSize={["13px", "13px", "14px", "14px"]}
                                    rounded={3} mr="10px" mt="10px" textTransform="uppercase"
                                    bg="linear-gradient(to right, #BEE3F8 50%, #edf2f9 50%)"
                                    backgroundPosition="right bottom"
                                    backgroundSize="200% 100%" transition="all .5s ease-out"
                                    _hover={{ backgroundPosition: 'left bottom' }}
                                    onClick={() => history.push(`/blog/tag/${tag._id}`)}>
                                    {tag.name}
                                </Tag>
                            ))
                        }
                    </Box> */}
                </Box>

            </Flex>




        </Box>
    )
}

export default PostData;