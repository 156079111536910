import axios from "axios"
import {config} from '../config'

const baseUrl = config.baseUrl;

export const getBusiness = async (params) => {

    try {
        const response = await axios.get(`${baseUrl}/api/v1/business?franchiseeId=${params.franchiseeId}`)
        return response.data;   

      } catch (error) {
        console.error(error);
      }
}