import { Badge, Box, Button, Container, Flex, FormControl, FormErrorMessage, FormLabel, HStack, Icon, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea, useDisclosure, useToast } from '@chakra-ui/react';
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Editor } from "react-draft-wysiwyg";
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
// import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios from 'axios';
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FaTrashAlt, FaUserAlt } from 'react-icons/fa';
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import DeleteComponent from '../../components/delete/DeleteComponent';
import { config } from '../../config';
import useDomain from '../../service/DomainHook';
import ReactSelectOptionMapper from '../../service/ReactSelect';
import { editorFontList } from '../../service/utils/FontList';
import { customChunkRenderer, generateSlug } from '../../service/utils/helper';
import { getDate } from '../../web/Blog/BlogDetails';
import './blog.css';
import AddTag from './helpers/AddTag';
import dataURItoBlob from './helpers/dataURLtoBlob';
import PreviewHTML from './helpers/PreviewHTML';
import { uploadImage } from './helpers/uploadImage';

function BlogDetails() {
    const baseURL = `${config.baseUrl}/api/v1`;
    const token = localStorage.getItem('token');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();

    const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();
    const { isOpen: isOpenTag, onOpen: onOpenTag, onClose: onCloseTag } = useDisclosure();

    const toast = useToast();
    const history = useHistory();
    const { id } = useParams();
    const [hasSubDomain] = useDomain();

    const statusOption = [
        { label: 'Publish Now', value: 'publish' },
        { label: 'Publish later', value: 'later' },
        { label: 'Unpublish', value: 'unpublish' } //default value
    ];

    const { register, handleSubmit, errors, control, reset, setValue, getValues, watch } = useForm();
    const publishOpt = watch('publishOpt');

    // My state 
    const [post, setPost] = useState({});
    const [title, setTitle] = useState('');
    const [previewImage, setPreviewImage] = useState();
    const [rawImage, setRawImage] = useState(null);
    const [allTags, setAllTags] = useState([]);
    const [blogComments, setBlogComments] = useState([]);
    const [users, setUsers] = useState([]);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [selectedId, setSelectedId] = useState({
        _id: '',
        postId: ''
    });
    const [toggleComments, setToggleComments] = useState(false);

    const [rawHTML, setRawHTML] = useState(draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
        null,
        null,
        (entity) => {
            // console.log("State entity.typeL:::", entity);
            // // if (entity.type === 'DIV') { // Receive what you passed before, here (like id, or classList, etc.)
            // //     return `<div>${entity.data.innerHTML}</div>`;
            // // }
            // if (entity.type === 'SCRIPT') { // Receive what you passed before, here (like id, or keyEvents, etc.)
            //     console.log("State entity.data.innerHTML:::", entity.data.innerHTML);
            //     return `<script>${entity.data.innerHTML}</script>`;
            // }
            // return '';
        }
    ));

    const [publishDefaultValue, setPublishDefaultValue] = useState(null);
    const [publishedAtValue, setPublishedAtValue] = useState(null);
    const [publishStatus, setPublishedStatus] = useState('');
    const [image, setImage] = useState(previewImage);
    const [cropData, setCropData] = useState("#");
    const [isCropped, setIsCropped] = useState(false);
    const [cropper, setCropper] = useState();
    const [hidePreview, setHidePreview] = useState(false);
    const [loading, setLoading] = useState(false);

    function imageHandler(e) {
        // To preview before upload --- Converting to base 64
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setPreviewImage(reader.result);
                setImage(reader.result);
                setHidePreview(true);
                setRawImage(e.target.files[0]);
            }
        }
        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0])
        }
    };

    const getCropData = () => {

        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas().toDataURL());
            setPreviewImage(cropData);
            setIsCropped(true);
        };

        setLoading(true);

        const formData = new FormData();
        formData.append('image', dataURItoBlob(cropper.getCroppedCanvas().toDataURL()));

        axios({
            url: `/posts/${post?._id}`,
            method: 'post',
            baseURL,
            data: formData,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {

                toast({
                    title: "Image Updated.",
                    description: "Image Uploaded Successfully",
                    status: "success",
                    duration: 2500,
                    isClosable: true,
                });
                setLoading(false);
            })
            .catch(err => {
                toast({
                    title: "Image not updated",
                    description: JSON.stringify(err),
                    status: "error",
                    duration: 2500,
                    isClosable: true,
                })
            })
    };

    useEffect(() => {
        setPreviewImage(cropData);
    }, [cropData]);

    const tagMapHandler = (tags) => {

        let newTags = [];
        if (tags.length) {
            newTags = tags.map(obj => {
                let item = {};
                item.value = obj._id;
                item.label = obj.name;
                return item;
            })
        }

        return newTags;
    };

    const franchiseeId = localStorage.getItem('franchiseeId');

    const fetchBlogData = () => {
        let getUrl = franchiseeId !== 'null'
            ? `/posts/${id}?adminType=CA&franchiseeId=${franchiseeId}`
            : `/posts/${id}?adminType=SA`;

        axios({
            url: getUrl,
            method: 'get',
            baseURL: baseURL
        })
            .then((res) => {
                const blogData = res?.data?.data[0];
                blogData.tagData = res.data.data[0]?.tagData?.map(tag => {
                    let obj = {
                        value: tag?._id,
                        label: tag?.name
                    }
                    return obj;
                })


                // let postTime = res.data.data;
                let newStatus = blogData?.status;

                if (newStatus === 'publish') {
                    setPublishDefaultValue(statusOption[0])
                } else if (newStatus === 'later') {
                    setPublishDefaultValue(statusOption[1])
                    setPublishedStatus(blogData?.status)

                } else {
                    setPublishDefaultValue(statusOption[2])
                }

                setPost(blogData);
                setTitle(blogData?.title)
                setPreviewImage(blogData?.featureImageUrl);
                setCropData(blogData?.featureImageUrl);

                if (blogData?.publishedAt) {
                    setPublishedAtValue(new Date(blogData?.publishedAt));
                }
                else {
                    setPublishedAtValue(null);
                }

                // setBlogComments(blogData?.blogCommentsData ?? []);

                blogData.createdAt = new Date(blogData?.createdAt);

                // convert html to raw editor data
                const sampleMarkup = blogData?.body;
                const contentBlock = htmlToDraft(sampleMarkup, customChunkRenderer);

                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

                    const editorStateCreate = EditorState.createWithContent(contentState);

                    setEditorState(editorStateCreate);

                };

                const fetchData = {
                    ...res.data.data[0],
                    author: {
                        label: blogData?.authorData?.firstName,
                        value: blogData?.authorData?._id
                    },
                    // publishDate: new Date(blogData?.publishedAt)
                }

                reset(fetchData);

            })
            .catch(err => console.log(err, 'Data fetch error'))
    };

    const fetchBlogCommentsData = () => {
        let getUrl = franchiseeId !== 'null'
            ? `/posts/${id}?adminType=CA&franchiseeId=${franchiseeId}`
            : `/posts/${id}?adminType=SA`;

        axios({
            url: getUrl,
            method: 'get',
            baseURL: baseURL
        })
            .then((res) => {
                const blogData = res?.data?.data[0];
                setBlogComments(blogData?.blogCommentsData ?? []);
            })
            .catch(err => console.log(err, 'Data fetch error'))
    }


    const getTagList = useCallback(() => {
        const franchiseeId = localStorage.getItem('franchiseeId');

        let url = franchiseeId !== 'null'
            ? `/tags?adminType=CA&franchiseeId=${franchiseeId}&sort=name`
            : '/tags?adminType=SA&sort=name';

        axios({
            url: url,
            method: 'get',
            baseURL: baseURL,
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(({ data }) => {
                let mappedTags = tagMapHandler(data.data)
                setAllTags(mappedTags);

            })
            .catch(err => console.log(err))
    }, []);

    useEffect(() => getTagList(), []);
    // get users
    useEffect(() => {

        const fields = 'firstName';
        let url = `/users?role=SUPERADMIN&fields=${fields}&sort=name`;

        if (hasSubDomain) {
            url = `/users?franchiseeId=${franchiseeId}&sort=name`;
        }
        axios({
            url,
            method: 'get',
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
            .then((res) => {

                const authorList = ReactSelectOptionMapper(res.data.data, 'firstName');
                setUsers(authorList);
            })
            .catch(err => console.log(err, 'Data fetch error'))
    }, [])

    const getDeleteApi = () => {
        const franchiseeId = localStorage.getItem('franchiseeId');
        let url = `/posts/${id}?adminType=SA`;
        if (franchiseeId !== 'null') {
            url = `/posts/${id}?adminType=CA&franchiseeId=${franchiseeId}`;
        }
        return url;
    }

    useEffect(() => {

        if (publishOpt && publishOpt?.value === 'later') {
            setPublishedStatus('later');

        }

        if (publishOpt && publishOpt?.value !== 'later') {
            setPublishedStatus('');

        }


    }, [publishOpt, isCropped]);

    const updateImage = () => {
        const formData = new FormData()
        formData.append('image', rawImage);

        if (!isCropped) {
            axios({
                url: `/posts/${post?._id}`,
                method: 'post',
                baseURL,
                data: formData,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(({ data }) => {

                })
                .catch(err => {

                })

        }
        return null;
    }

    const submitHandler = (data) => {
        console.log(data);
        if (rawImage && !isCropped) {
            toast({
                title: "Image Must Be Cropped",
                description: 'Crop Your Image',
                status: "error",
                duration: 4000,
                isClosable: true
            })
            return;
        };

        setLoading(true);

        let payload = {
            title: data.title,
            slug: data.slug,
            tags: data.tagData?.map(tag => tag.value),
            excerpt: data.excerpt,
            body: draftToHtml(
                convertToRaw(editorState.getCurrentContent()),
                null,
                null,
                (entity) => {

                    // if (entity.type === 'DIV') { // Receive what you passed before, here (like id, or classList, etc.)
                    //     return `<div>${entity.data.innerHTML}</div>`;
                    // }
                    if (entity.type === 'SCRIPT') { // Receive what you passed before, here (like id, or keyEvents, etc.)
                        return `<script>${entity.data.innerHTML}</script>`;
                    }
                    if (entity.type === 'INS') { // Receive what you passed before, here (like id, or keyEvents, etc.)
                        // console.log("ENTRY:::", entity);
                        return `${entity.data.outerHTML}`;
                    }
                    if (entity.type === 'GoogleAd') { // Receive what you passed before, here (like id, or keyEvents, etc.)
                        // console.log("ENTRY:::", entity);
                        return `${entity.data.outerHTML}`;
                    }
                    return '';
                }
            ),
            createdAt: data?.createdAt,
            authorId: data?.author?.value
        }

        // checking publish status value
        if (data.publishOpt) {
            payload.status = data?.publishOpt?.value

        } else {
            payload.status = publishDefaultValue.value
        }


        if (data.publishOpt?.value === 'later' || data?.publishDate) {
            payload.publishedAt = data?.publishDate;
        }

        if (franchiseeId !== 'null') {
            payload.adminType = 'CA'
            payload.franchiseeId = franchiseeId
        }
        else {
            payload.adminType = 'SA'
        }


        const postDataUpdate = axios({
            url: `/posts/${post?._id}`,
            method: 'patch',
            baseURL,
            data: payload,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        // .then(({ data }) => {
        //     history.push(`/admin/blog`)
        // })
        // .catch(err => {
        // });


        let apiArray = null;

        if (rawImage && isCropped) {
            apiArray = [postDataUpdate, updateImage()]
        } else {
            apiArray = [postDataUpdate]
        }

        axios.all(apiArray).then(response => {

            toast({
                title: "Blog Post Updated.",
                description: "Post updated successfully",
                status: "success",
                duration: 4000,
                isClosable: true,
            })
            setLoading(false);
        }).catch((err) => {
            toast({
                title: "Post Not Updated",
                description: err.message,
                status: "error",
                duration: 4000,
                isClosable: true,
            });
            setLoading(false);

        })
    };

    const uploadImageCallBack = async (file) => {
        const formData = new FormData();
        formData.append('image', file);
        const imageResponse = await uploadImage(formData, token);
        return new Promise(
            (resolve, reject) => {
                if (imageResponse.success) {
                    resolve({ data: { link: imageResponse?.url } });
                } else {
                    reject('image upload failed');
                }
            }
        );

    };

    const onProductNameChange = async (e) => {
        let getSlug = await generateSlug(e.target.value)

        setValue('slug', getSlug)
    }
    const deleteBlogComment = () => {

        let url = `/blogComments?adminType=SA&commentId=${selectedId._id}&postId=${selectedId.postId}`;
        if (hasSubDomain) {
            url = `/blogComments?adminType=CA&franchiseeId=${franchiseeId}&commentId=${selectedId._id}&postId=${selectedId.postId}`
        }
        axios({
            url: url,
            method: 'delete',
            baseURL: baseURL,
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then((res) => {

                toast({
                    title: res.data.message,
                    status: "success",
                    duration: 4000,
                    isClosable: true
                })
                setToggleComments((s) => !s);
            })
            .catch((err) => {
                toast({
                    title: err?.response?.data?.message,
                    status: "error",
                    duration: 4000,
                    isClosable: true
                })
            })
    };
    console.log({ publishStatus })

    useEffect(() => {
        fetchBlogData();
    }, []);

    useLayoutEffect(() => {
        fetchBlogCommentsData();
    }, [toggleComments]);

    return (
        <>
            <Container maxWidth={1100} centerContent py={6}>
                <Tabs>
                    <TabList>
                        <Tab>Blog Detail</Tab>
                        <Tab>Blog Comments</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <Box w='100%' rounded="md" boxShadow='md' bg="white" p={4}>
                                <Breadcrumb pathname="Blog" nameOrTitle={title} link={`/admin/blog`} mb={5} />

                                <form onSubmit={handleSubmit(submitHandler)}>
                                    <FormControl mb="15px" isInvalid={errors.title} mt="20px">
                                        <FormLabel as={'p'} fontWeight="semibold" m={0}>Title</FormLabel>
                                        <Input
                                            type="text"
                                            name="title"
                                            ref={register({ required: 'Title is required' })}
                                            placeholder="Title"
                                            variant="flushed" size="sm"
                                            borderColor="#A0AEC0"
                                            onChange={e => onProductNameChange(e)}
                                        />
                                        <FormErrorMessage> {errors.title?.message} </FormErrorMessage>
                                    </FormControl>

                                    <FormControl id="slug" mb="15px" isInvalid={errors.slug}>
                                        <FormLabel as={'p'} fontWeight="semibold" m={0}>Blog slug</FormLabel>
                                        <Input ref={register} variant="flushed" size="sm" type="text" name="slug" />
                                        <FormErrorMessage mb="20px">{errors.slug && errors.slug.message}</FormErrorMessage>
                                    </FormControl>


                                    <FormControl my="15px" isInvalid={errors.featuredImage}>
                                        <Box>
                                            <FormLabel fontWeight="semibold" htmlFor='featuredImage' textAlign="center" cursor="pointer"
                                                w='100%'
                                                h='100%'
                                                lineHeight="60px"
                                                border="1px dashed #BDBDBD"
                                                // color="gray.500"
                                                p='10px'
                                            >
                                                Click To Upload Feature Image

                                            </FormLabel>
                                            <Input
                                                type="file"
                                                name="featuredImage"
                                                ref={register}
                                                id='featuredImage'
                                                accept="image/*"
                                                onChange={(e) => imageHandler(e)}
                                                style={{ display: "none" }} />

                                        </Box>
                                        <Flex justify="space-between" align='flex-start'>

                                            <Flex flexDirection='column' w="48%">
                                                <Box>
                                                    {image ? <Cropper
                                                        style={{ height: 350, width: "100%" }}
                                                        zoomTo={0.0}
                                                        initialAspectRatio={1}
                                                        src={image}
                                                        aspectRatio={1 / 1}
                                                        viewMode={1}
                                                        minCropBoxHeight={10}
                                                        minCropBoxWidth={10}
                                                        background={false}
                                                        responsive={true}
                                                        autoCropArea={1}
                                                        // autoCrop={true}
                                                        cropBoxResizable={false}
                                                        checkOrientation={false}
                                                        // center={true}
                                                        onInitialized={(instance) => {
                                                            setCropper(instance);
                                                        }}
                                                        guides={true}
                                                    />
                                                        : null}
                                                </Box>
                                                <Box alignSelf='flex-end' mt='5px'>
                                                    {image ? <Button
                                                        isLoading={loading}
                                                        loadingText='Cropping & Uploading...'
                                                        _hover={{ outline: 'none' }}
                                                        _focus={{ outline: 'none' }}
                                                        _active={{ bg: 'transparent', color: 'teal' }}
                                                        colorScheme="teal" variant="outline" size="sm"
                                                        onClick={getCropData}>
                                                        Crop Image
                                                    </Button> : null}
                                                </Box>

                                            </Flex>
                                            <Box w="48%">
                                                {image ? <Image src={previewImage} alt="Post featured image" maxH="350px" /> : null}
                                            </Box>
                                        </Flex>
                                        <Box>
                                            {!hidePreview ? <Image src={previewImage} alt="Post featured image" maxH="350px" /> : null}
                                        </Box>
                                    </FormControl>

                                    <FormControl mb="15px" isInvalid={errors.excerpt} mt="20px">
                                        <FormLabel as={'p'} fontWeight="semibold" m={0}>Excerpt</FormLabel>
                                        <Textarea
                                            type="text"
                                            name="excerpt"
                                            ref={register({ required: 'Excerpt is required' })}
                                            placeholder="Excerpt"
                                            variant="flushed" size="sm"
                                            borderColor="#A0AEC0"
                                        />
                                        <FormErrorMessage> {errors.excerpt?.message} </FormErrorMessage>
                                    </FormControl>

                                    <span fontSize="0.8rem">
                                        Press &nbsp;
                                        <Badge colorScheme="red">Shift</Badge>
                                        &nbsp; + &nbsp;
                                        <Badge colorScheme="red">Enter</Badge>
                                        , to take new line
                                    </span>

                                    <FormControl mb="10px" mt="20px">
                                        <Flex m={0} justify="space-between" align='center'>
                                            <FormLabel as={'p'} fontWeight="semibold">Body</FormLabel>
                                            <Button bg='gray.300' size="sm" mb="10px" onClick={onOpenModal}>Preview HTML</Button>
                                        </Flex>

                                        <Editor
                                            handlePastedText={() => false}
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName rich-editor"
                                            onEditorStateChange={(editorState) => setEditorState(editorState)}
                                            toolbar={{
                                                fontFamily: {
                                                    options: editorFontList,
                                                    className: undefined,
                                                    component: undefined,
                                                    dropdownClassName: undefined,
                                                },
                                                inline: { inDropdown: true },
                                                list: { inDropdown: true },
                                                textAlign: { inDropdown: true },
                                                link: { inDropdown: true },
                                                history: { inDropdown: true },
                                                image: {
                                                    uploadCallback: uploadImageCallBack,
                                                    previewImage: true,
                                                    alt: { present: true, mandatory: false },
                                                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                                    defaultSize: {
                                                        height: '250px',
                                                        width: 'auto'
                                                    },
                                                },
                                            }}
                                        />
                                    </FormControl>

                                    <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing={{ base: '10px', md: '20px', lg: '20px' }}>
                                        <FormControl id="typeIds" mb="20px" isInvalid={errors.tags}>
                                            <HStack mb={1}>
                                                <FormLabel as={'p'} fontWeight="semibold">Tags</FormLabel>
                                                <Button
                                                    px='8px'
                                                    lineHeight="16px"
                                                    fontWeight='300'
                                                    rounded='sm'
                                                    fontSize='10px'
                                                    _focus={{
                                                        outline: 'none'
                                                    }}
                                                    colorScheme="gray"
                                                    size="xs"
                                                    onClick={onOpenTag}>
                                                    Add New
                                                </Button>
                                            </HStack>
                                            <Controller
                                                name="tagData"
                                                control={control}
                                                options={allTags}
                                                as={Select}
                                                isMulti
                                                defaultValue={``}
                                                // required 
                                                rules={{ required: true }}

                                            />
                                            <FormErrorMessage>{errors.tags?.message}</FormErrorMessage>
                                        </FormControl>

                                        <FormControl id="typeIds" mb="20px" borderBottom="1px solid #CBD5E0">
                                            <FormLabel as={'p'} fontWeight="semibold">Created At</FormLabel>
                                            <Controller
                                                name="createdAt"
                                                control={control}
                                                // rules = {{ required: 'Date is required' }} 
                                                defaultValue={null}
                                                render={({ onChange, value }) => (
                                                    <DatePicker
                                                        placeholderText="Select date"
                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                        selected={value}
                                                        closeOnScroll
                                                        isClearable
                                                        timeIntervals={30}
                                                        onChange={onChange}
                                                        showTimeSelect
                                                        // showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                )}
                                            />
                                            {/* <FormErrorMessage>{ errors.tags?.message }</FormErrorMessage>  */}
                                        </FormControl>
                                    </SimpleGrid>

                                    <Flex>
                                        <FormControl id="typeIds" mb="20px" mr="15px">
                                            <FormLabel as={'p'} fontWeight="semibold">Status</FormLabel>
                                            <Controller
                                                name="publishOpt"
                                                control={control}
                                                options={statusOption}
                                                as={Select}
                                                defaultValue={publishDefaultValue}


                                            />

                                        </FormControl>

                                        {/* Post Schedule - Date picker */}
                                        <FormControl mb={3} isInvalid={errors?.publishDate}>
                                            {
                                                publishStatus === 'later' &&
                                                <>
                                                    <Box borderBottom={errors?.publishDate ? '2px solid #E53E3E' : "1px solid #CBD5E0"} m={0} p={0}>
                                                        <FormLabel as={'p'} fontWeight="semibold">Schedule</FormLabel>
                                                        <Controller
                                                            name="publishDate"
                                                            control={control}
                                                            rules={{ required: 'Date is required' }}
                                                            defaultValue={publishedAtValue}
                                                            render={({ onChange, value }) => {
                                                                return <DatePicker
                                                                    placeholderText="Select date"
                                                                    // dateFormat="yyyy-MM-dd, h:mm aa"
                                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                                    selected={value}
                                                                    closeOnScroll
                                                                    isClearable
                                                                    showTimeSelect
                                                                    timeIntervals={30}
                                                                    minDate={new Date()}
                                                                    onChange={onChange}
                                                                />
                                                            }}
                                                        />
                                                    </Box>
                                                    <FormErrorMessage>
                                                        {errors?.publishDate?.message}
                                                    </FormErrorMessage>
                                                </>
                                            }
                                        </FormControl>
                                    </Flex>

                                    <FormControl id="typeIds" mb="30px" mr="15px" w="50%">
                                        <FormLabel as={'p'} fontWeight="semibold">Author</FormLabel>
                                        <Controller
                                            name="author"
                                            control={control}
                                            options={users}
                                            as={Select}
                                            defaultValue={null}
                                            rules={{ required: true }}
                                        />
                                    </FormControl>

                                    <Button
                                        disabled={loading}
                                        colorScheme="red" variant="outline" size="sm" mr={3} onClick={onOpen}>
                                        Delete
                                    </Button>

                                    <Button
                                        isLoading={loading}
                                        loadingText='Saving...'
                                        type="submit" colorScheme="teal" variant="outline" size="sm">
                                        Save
                                    </Button>
                                </form>
                            </Box>
                        </TabPanel>
                        <TabPanel>
                            <Box minW={400} rounded="md" boxShadow='md' bg="white" p={4}>

                                <Stack spacing={3}>
                                    {blogComments.length === 0 ? <Text>No comments found</Text> : null}

                                    {blogComments.map((comment) => (

                                        <Box
                                            key={comment._id} mt="10px" p={6} rounded="md" boxShadow="sm"
                                            borderWidth="1px" borderColor="gray.200" >
                                            <Flex justifyContent='space-between' alignItems={'center'}>
                                                <Flex>
                                                    <Icon as={FaUserAlt} color="gray.700" />
                                                    <Text
                                                        fontWeight="700" mb="2px" ml="10px"
                                                        fontSize="14px" color="gray.700" >
                                                        {comment?.authorName}
                                                    </Text>
                                                </Flex>
                                                <FaTrashAlt style={{
                                                    cursor: 'pointer'
                                                }}

                                                    onClick={() => {
                                                        onOpenDelete();
                                                        setSelectedId({
                                                            _id: comment._id,
                                                            postId: comment.postId
                                                        });

                                                    }}
                                                />
                                            </Flex>
                                            <Text fontSize="13px" color="gray.400" fontStyle="italic" mb="15px">
                                                {/* Created at: {`${date.day}-${date.month}-${date.year}`} */}
                                                Created at: {getDate(comment?.commentAt)}
                                            </Text>
                                            <Text fontSize="14px" mt="5px" color="gray.600">
                                                {comment?.message}
                                            </Text>
                                        </Box>

                                    ))}
                                </Stack>

                            </Box>
                        </TabPanel>

                    </TabPanels>
                </Tabs>


                <DeleteComponent
                    headerName='Post'
                    isOpen={isOpen} onClose={onClose}
                    getDeleteApi={getDeleteApi}
                    routeOnDelete='/admin/blog'
                    showBackendMsg={false}
                    toastSuccessMsgTitle="Post deleted successfully"
                    toastErrMsgTitle="Cannot delete the post" />
            </Container>

            {/* modal for tag add */}
            <AddTag
                getTagList={getTagList}
                getValues={getValues}
                setValue={setValue}
                isOpen={isOpenTag} onClose={onCloseTag}
            />

            {/* modal for html preview */}
            <PreviewHTML
                isOpen={isOpenModal}
                onClose={onCloseModal}
                editorState={editorState} setEditorState={setEditorState}
                rawHTML={rawHTML} setRawHTML={setRawHTML}
            />
            <Modal isOpen={isOpenDelete} onClose={onCloseDelete} isCentered>
                <ModalOverlay />
                <ModalContent m={5}>
                    <ModalHeader >Delete Comment?</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Are you sure, you want to delete?
                    </ModalBody>

                    <ModalFooter>
                        <Button size="sm" mr={3} colorScheme="teal" variant="outline" onClick={onCloseDelete}>
                            No
                        </Button>

                        <Button size="sm" colorScheme="red" variant="outline"
                            onClick={() => {
                                deleteBlogComment();
                                onCloseDelete();
                            }}>Yes</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default BlogDetails;
