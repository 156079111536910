import React from 'react'
import { Box, SimpleGrid, Input, 
    Text, Stack, Select,
    FormControl, FormLabel, FormErrorMessage, Button,
} from '@chakra-ui/react'
import { UsaState } from '../../config';


function AddressForm({handleAddressMapping, closeAddressModal, register, handleSubmit, errors }) {

    return (
        <Box>
            <form onSubmit={handleSubmit(handleAddressMapping)}>
                <SimpleGrid minChildWidth="14rem" spacing="15px">

                    <Input type="hidden" name="shippingAddress.country" defaultValue="US" ref={register} />

                    <FormControl mb={4} color="gray.600" id="billFirstname" isInvalid={errors.shippingAddress?.firstName}>
                        <FormLabel htmlFor="shippingAddressFirstname"  as={'p'} fontWeight="semibold">First Name</FormLabel>
                        <Input type="text" name="shippingAddress.firstName" ref={register({required : true})} />
                        <FormErrorMessage>
                            { errors.shippingAddress?.firstName && errors.shippingAddress?.firstName?.type === "required" && 'First name is required' }
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl mb={4} color="gray.600" id="shippingAddressLastname" isInvalid={errors.shippingAddress?.lastName}>
                        <FormLabel htmlFor="shippingAddressLastname"  as={'p'} fontWeight="semibold">Last Name</FormLabel>
                        <Input type="text" name="shippingAddress.lastName" ref={register} /> 
                        <FormErrorMessage>
                            { errors.shippingAddress?.lastName && errors.shippingAddress?.lastName?.type === "required" && 'Last name is required' }
                        </FormErrorMessage>
                    </FormControl>
                </SimpleGrid>
                <Box mb={4}>
                    <Stack direction={["column", "row"]} spacing="10px">
                        <Text color="gray.500" fontWeight="700" fontSize=".9rem" lineHeight="1rem" mb={1}>Country / Region :</Text>
                        <Text color="gray.400" fontWeight="700" fontSize="1rem" lineHeight="1rem" mb={1}>United States (US)</Text>
                    </Stack>
                </Box>

                <FormControl mb={4} color="gray.600" id="shippingAddressCompanyName">
                    <FormLabel htmlFor="shippingAddressCompanyName"  as={'p'} fontWeight="semibold">Company Name</FormLabel>
                    <Input type="text" ref={register} name="shippingAddress.company" placeholder="Company name (optional)" />
                </FormControl>
                <FormControl mb={4} color="gray.600" id="shippingAddressStreetAddress" isInvalid={errors.shippingAddress?.street}>
                    <FormLabel htmlFor="shippingAddressStreetAddress"  as={'p'} fontWeight="semibold">Street Address</FormLabel>
                    <Input type="text" name="shippingAddress.street" ref={register({required : true})} />
                    <FormErrorMessage>
                        { errors.shippingAddress?.street && errors.shippingAddress?.street?.type === "required" && 'Street address is required' }
                    </FormErrorMessage>
                </FormControl>
                <FormControl mb={4} color="gray.600" id="house">
                    <Input type="text" name="shippingAddress.house" ref={register} placeholder="Apartment, Suit, Unit etc (optional)" />
                </FormControl>

                <FormControl mb={4} color="gray.600" id="city" isInvalid={errors.shippingAddress?.city}>
                    <FormLabel  as={'p'} fontWeight="semibold">Town / City</FormLabel>
                    <Input type="text" name="shippingAddress.city" ref={register({required : true})} />
                    <FormErrorMessage>
                        { errors.shippingAddress?.city && errors.shippingAddress?.city?.type === "required" && 'City name is required' }
                    </FormErrorMessage>
                </FormControl>
                <FormControl mb={4} color="gray.600" id="state" isInvalid={errors.shippingAddress?.state}>
                    <FormLabel  as={'p'} fontWeight="semibold">State</FormLabel>
                    <Select ref={register({required: true})} name="shippingAddress.state">
                        {
                            UsaState.map(state => (
                                <option key={state.value} value={state.value}>{state.label}</option>
                            ))
                        }
                    </Select>
                    <FormErrorMessage>
                        { errors.shippingAddress?.state && errors.shippingAddress?.state?.type === "required" && 'State field is required' }
                    </FormErrorMessage>
                </FormControl>
                <FormControl mb={4} color="gray.600" id="zipCode" isInvalid={errors.shippingAddress?.zip}>
                    <FormLabel  as={'p'} fontWeight="semibold">Zip code</FormLabel>
                    <Input type="number" name="shippingAddress.zip" ref={register({required : true})} />
                    <FormErrorMessage>
                        { errors.shippingAddress?.zip && errors.shippingAddress?.zip?.type === "required" && 'Zip code field is required' }
                    </FormErrorMessage>
                </FormControl>
                {/* <FormControl mb={4} color="gray.600" id="phone" isInvalid={errors.shippingAddress?.phone}>
                    <FormLabel  as={'p'} fontWeight="semibold">Phone</FormLabel>
                    <Input type="tel" name="shippingAddress.phone" ref={register({required : true})} />
                    <FormErrorMessage>
                        { errors.shippingAddress?.phone && errors.shippingAddress?.phone?.type === "required" && 'Phone field is required' }
                    </FormErrorMessage>
                </FormControl>
                <FormControl mb={4} color="gray.600" id="email" isInvalid={errors.shippingAddress?.email}>
                    <FormLabel  as={'p'} fontWeight="semibold">Email</FormLabel>
                    <Input type="email" name="shippingAddress.email" ref={register({required : true})} />
                    <FormErrorMessage>
                        { errors.shippingAddress?.email && errors.shippingAddress?.email?.type === "required" && 'Email field is required' }
                    </FormErrorMessage>
                </FormControl> */}
                <Stack direction="row" spacing={4}>
                    <Button size="sm" colorScheme='black' variant='outline' onClick={closeAddressModal}>Cancel</Button>
                    <Button size="sm" colorScheme='green' variant='outline' type="submit">Save</Button>
                </Stack>
            </form>
        </Box>
    )
}

export default AddressForm