import React, { useState, useEffect, useCallback, useRef } from 'react';
import { config } from '../../config';
import axios from 'axios';
import {
    Box, Flex, Text, Input, SimpleGrid,
    InputGroup, InputRightElement,
    Alert, AlertTitle, AlertIcon, AlertDescription, Center, Container
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { Icon, Search2Icon } from '@chakra-ui/icons'
import { FaFilter } from 'react-icons/fa'
import { IoCloseOutline } from 'react-icons/io5'
import shop from '../../img/shop.jpg';
import MetaDecorator from '../../components/metaDecorator';
import useDomain from '../../service/DomainHook';
import DomainUtils from '../../service/utils/DomainUtils';
import ShopSkeleton from './ShopSkeleton';
import { Scrollbars } from 'react-custom-scrollbars';
import FilterSidebar from './ProductFilter/FilterSidebar'
import SingleProduct from './SingleProduct';
import WebPagination from '../../components/pagination/WebPagination';


const style = {
    // backgroundImage: `url(${shop})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover'
}


function Shop() {
    const theme = JSON.parse(localStorage.getItem('theme')); 
    const baseURL = `${config.baseUrl}/api/v1`;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [products, setProduct] = useState([]);
    const [PageHeaderData, setPageHeaderData] = useState({});
    const [menuState, setMenuState] = useState(false);

    const [hasSubDomain] = useDomain();

    const [isEmpty, setIsEmpty] = useState(false);

    const [catTree, setCatTree] = useState([])
    const [business, setBusiness] = useState([])
    const [tags, setTags] = useState([]);

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState({ selected: 0 });
    const limit = 25;
    const productFirstLoad = useRef(false);

    const fetchProduct = useCallback(async (pageNumber) => {
        let franchisee = null;
        if (hasSubDomain) {
            const [franchiseeId] = await DomainUtils();
            franchisee = franchiseeId;
        }

        /*
        *---------------------------------------------------
        *   Get product 
        *---------------------------------------------------
        */
        axios({
            url: '/products',
            method: 'GET',
            baseURL: baseURL,
            params: {
                franchiseeId: franchisee,
                publish: true,
                sort: 'name',
                limit,
                page: pageNumber + 1
            }
        })
            .then(response => {
                const { data: productData, pageHeader } = response.data;
                setProduct(productData);
                setPageHeaderData(pageHeader)
            })
            .catch(error => {
                console.log(error);
            });

    }, [])

    useEffect(() => {
        if (productFirstLoad.current) {
            window.scrollTo(0, 0);
            fetchProduct(page.selected);
        }
    }, [page.selected]);



    useEffect(async () => {
        window.scrollTo(0, 0);

        let franchisee = null;
        if (hasSubDomain) {
            const [franchiseeId] = await DomainUtils();
            franchisee = franchiseeId;
        }

        /*
        *---------------------------------------------------
        *   Get product 
        *---------------------------------------------------
        */

        axios({
            url: '/products',
            method: 'GET',
            baseURL: baseURL,
            params: {
                franchiseeId: franchisee,
                publish: true,
                sort: 'name',
                limit,
                page: 1
            }
        })
            .then(response => {
                const { data: productData, result, pageHeader } = response.data;
                setProduct(productData);
                setTotal(result);
                setPageHeaderData(pageHeader)

                if (productData.length < 1) {
                    setIsEmpty(true);
                }
                setLoading(false);
                productFirstLoad.current = true;
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });

    }, []);

    useEffect(async () => {
        let franchisee = null;
        if (hasSubDomain) {
            const [franchiseeId] = await DomainUtils();
            franchisee = franchiseeId;
        }

        // Tags
        axios({
            // url: `/tags`, 
            url: `/tags?adminType=CA&franchiseeId=${franchisee}`,
            method: 'get',
            baseURL: baseURL
        })
            .then(res => {
                setTags(res.data.data);
            })
            .catch(err => console.log(err))

        // cat tree 
        axios({ url: `/categories/hierarchy/${franchisee}`, method: 'get', baseURL: baseURL })
            .then(res => {
                setCatTree(res.data.category)
            })
            .catch(err => console.log(err))

    }, [])


    const productSearch = (e) => {
        history.push(`/shop/search`, { searchKey: e.target.value });
    }

    localStorage.setItem('filterValue', JSON.stringify({}));
    const filterCatHandler = (e) => {
        if (e.target.checked) {
            localStorage.setItem('filterValue', JSON.stringify({ category: [e.target.value], business: [], tag: [] }));
            history.push('/shop/filter', { category: e.target.value, tags, allBusiness: business, catTree });
        }
    }

    // const filterBusinessHandler = (e) => { 
    //     if (e.target.checked) { 
    //         localStorage.setItem('filterValue', JSON.stringify({business: [e.target.value], category: [], tag: []})); 
    //         history.push('/shop/filter', {business: e.target.value, tags, allBusiness: business, catTree}); 
    //     } 
    // } 

    const filterTagHandler = (e) => {
        if (e.target.checked) {
            localStorage.setItem('filterValue', JSON.stringify({ tag: [e.target.value], business: [], category: [] }));
            history.push('/shop/filter', { tag: e.target.value, tags, allBusiness: business, catTree });
        }
    }

    const pageHeaderStyle = JSON.parse(localStorage.getItem('pageHeaderStyle'));

    return (
        <>
            <Box>
                <MetaDecorator
                    title="Gift Your Town | Shop"
                    description="Gift certificates"
                    keywords="Online Certificate, Eat our food"
                />

                <Box h="350px" bg="teal.300" mt="55px" style={{ ...style, backgroundImage: `url(${PageHeaderData?.shopHeaderImage})` }}>
                    <Container
                        maxW="1100px"
                        // bg="white" 
                        // boxShadow={{base: null, sm: null, md: 'lg', lg: 'lg'}}s
                        rounded="md"
                        mt={["30px", "30px", "40px"]}
                        p={{ base: 3, sm: 3, md: 10, lg: 10 }}
                        // textShadow={["0", "0", "white"]}
                        // style={{ "text-shadow": "0 0 white" }}
                        height="100%"
                    >
                        {/* <Center h="90%"> */}
                        <Flex alignItems='center' h="100%">
                            <Box w="100%" >
                                <Text
                                    // fontSize="2.5rem"
                                    // color={theme?.color?.primary}
                                    // textAlign="center"
                                    width="100%"
                                    style={{ ...pageHeaderStyle, fontSize: pageHeaderStyle?.fontSize + 'px' }}
                                >
                                    Shop
                                </Text>
                                <br />
                                {/* <Center> */}
                                {/* </Center> */}
                                <Box w="100%">
                                    <InputGroup w="100%">
                                        <Input w="100%" type="text" bg="#FFF" onChange={productSearch} placeholder="Search product" />
                                        <InputRightElement
                                            pointerEvents="none"
                                            children={<Search2Icon color="gray.300" />}
                                        />
                                    </InputGroup>
                                </Box>
                            </Box>
                        </Flex>
                        {/* </Center> */}
                    </Container>
                </Box>

                {/* Filter Icon */}
                <Box
                    ml={5} bg="#edf2f9" p={3} w="fit-content"
                    display={{ base: 'block', sm: 'block', md: 'none', lg: 'none' }}
                    onClick={() => setMenuState(!menuState)}
                    cursor="pointer">
                    <Icon as={FaFilter} />
                </Box>

                <Flex>
                    <Box
                        flex="1" mt={4}
                        position={menuState && 'relative'}
                        zIndex="2"
                        display={{
                            base: menuState ? 'block' : 'none',
                            sm: menuState ? 'block' : 'none',
                            md: 'block', lg: 'block'
                        }}>

                        {
                            menuState
                                ?
                                <Box
                                    position="fixed" top="65px"
                                    bg='white' w='70%' rounded='lg'
                                    borderWidth='2px' borderColor='gray.200'>
                                    <Scrollbars
                                        style={{ height: `${(window.innerHeight - 55)}px` }}
                                        autoHide
                                        autoHideTimeout={500}
                                        autoHideDuration={200}>
                                        <Box>
                                            <Icon
                                                as={IoCloseOutline}
                                                h={6} w={6} ml="auto" mr="10px" mt={1}
                                                display="block" color="gray.400" cursor="pointer"
                                                onClick={() => menuState && setMenuState(!menuState)} />
                                            <FilterSidebar
                                                filterCategoryHandler={filterCatHandler}
                                                catTree={catTree}
                                                // filterBusinessHandler={filterBusinessHandler} 
                                                // businesses={business} 
                                                filterTagsHandler={filterTagHandler}
                                                tags={tags}
                                            />
                                        </Box>
                                    </Scrollbars>
                                </Box>
                                :
                                <FilterSidebar
                                    filterCategoryHandler={filterCatHandler}
                                    catTree={catTree}
                                    // filterBusinessHandler={filterBusinessHandler} 
                                    // businesses={business} 
                                    filterTagsHandler={filterTagHandler}
                                    tags={tags}
                                />
                        }
                    </Box>



                    {/* Product List */}
                    <Box flex="4" onClick={() => menuState && setMenuState(!menuState)}>
                        {
                            loading ? (
                                <ShopSkeleton item={3} />
                            ) : (
                                <Box px={4} py={8}>

                                    <SimpleGrid columns={{ base: 2, sm: 2, md: 2, lg: 3, xl: 4, '2xl': 5 }} spacing="20px">

                                        {
                                            products.map(product => (
                                                <SingleProduct key={product._id} product={product} />
                                            ))
                                        }

                                    </SimpleGrid>

                                    {
                                        total > 25 &&
                                        <Flex justifyContent="center" mt={8}>
                                            <WebPagination
                                                page={page}
                                                limit={limit}
                                                total={total}
                                                setNewPage={setPage}
                                            />
                                        </Flex>
                                    }

                                    {
                                        isEmpty && (
                                            <Alert
                                                status="info"
                                                variant="subtle"
                                                flexDirection="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                textAlign="center"
                                                height="38vh"
                                                colorScheme="teal">
                                                <AlertIcon boxSize="40px" mr={0} />
                                                <AlertTitle mt={4} mb={1} fontSize="lg">
                                                    Sorry no product found!
                                                </AlertTitle>
                                                <AlertDescription maxWidth="sm">
                                                    No product has been added to this shop yet.
                                                </AlertDescription>
                                            </Alert>
                                        )
                                    }

                                </Box>

                            )
                        }
                    </Box>
                </Flex>
            </Box>
        </>
    )
}

export default Shop; 
