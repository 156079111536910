export const reducer = (state, action) => {
    switch (action.type) {
        case 'THEME_CHANGE':
            return {
                ...state,
                color: action.payload.color,
                fonts: action.payload.fonts
            }
        case 'COLOR_CHANGE':
            return {
                ...state,
                color: action.payload.color
            };   
        case 'FONT_CHANGE':
            return {
                ...state,
                fonts: {
                    ...state.fonts,
                    ...action.payload.fonts
                }
            };       
    }
}








