import React from 'react'
import { Container, Box, Alert, AlertIcon, SimpleGrid, Stack, Flex, Heading } from '@chakra-ui/react'
import useDomain from './../service/DomainHook';
import OrderList from './DashBoard/Order/OrderList';
import TopPurchasedProducts from './DashBoard/TopPurchasedProducts';
import TopViewedProducts from './DashBoard/TopViewedProducts';
import BlogComments from './DashBoard/BlogComments';
import SalesInfo from './DashBoard/SalesInfo';
import BlogDemo from './DashBoard/BlogDemo';
import TopSellingProduct from './DashBoard/TopSell/TopSellingProduct';
import Franchisees from './Franchisees/Franchisees';

function Dashboard() {
    const [hasSubDomain] = useDomain();

    const styles = {
        bg: 'white',
        rounded: 'lg',
        p: 5,
        boxShadow: 'md'

    };

    return (
        <>
            {hasSubDomain ? <Box w='100%' p={5}>

                <Stack direction={["column", "row"]} spacing={5}>
                    {/* <BlogDemo styles={styles} /> */}
                    <OrderList styles={styles} />
                    {/* <TopPurchasedProducts styles={styles} /> */}
                    <BlogComments styles={styles} />
                    {/* <SalesInfo styles={styles} /> */}
                </Stack>

                <Stack mt={5} direction={["column", "row"]} spacing={5}>
                    <TopViewedProducts styles={styles} />
                    <TopSellingProduct styles={styles} />
                </Stack>
            </Box> :
                <>
                    {/* <Container maxWidth={1100} centerContent mt={6}>
                        <Box w="100%" {...styles}>
                            <Alert status="info">
                                <AlertIcon />
                                Dashboard section's work is in progress
                            </Alert>
                        </Box>
                    </Container> */}
                    <Box w='100%' p={5}>
                        <Stack direction={["column", "row"]} spacing={5}>
                            <Franchisees styles={styles} componentType="dashboard"/>
                            <BlogComments styles={styles} />
                        </Stack>
                        <Stack mt={5} direction={["column", "row"]} spacing={5}>
                            <TopViewedProducts styles={styles} />
                            <TopSellingProduct styles={styles} />
                        </Stack>
                    </Box>
                </>

            }
        </>

    )
}

export default Dashboard;
