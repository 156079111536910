import React from 'react';
import {Box, Flex, Text, Image, Center} from '@chakra-ui/react'
import image404 from '../img/404.jpg'

function Error404() { 
    return ( 
        <Box h="100vh" bg="#edf2f9" fontFamily="Poppins"> 
            <Flex justifyContent="center" alignItems="center" h="100%" w="100%" flexDirection="column"> 
                <Box bg="white" p="50px" h="100%" w="100%"> 
                    <Text as="h1" fontSize="80px" fontWeight="700" textAlign="center" color="red.500"> 
                        404 
                    </Text> 
                    <Text as="h2" fontSize="30px" fontWeight="700" textAlign="center"> 
                        Page Not Found 
                    </Text> 
                    <Center>
                        <Image src={image404} alt="404 not found" w="600px" />
                    </Center>
                </Box> 
            </Flex> 
        </Box> 
    ) 
} 

export default Error404; 