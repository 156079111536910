import React, { useState, useCallback, useRef } from 'react'
import { Container, Box, Flex, Icon, Button, useDisclosure, toast, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'
import axios from 'axios'
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb'
import EmailSubscriberTable from './EmailSubscriberTable'
import { config } from '../../config'
import { CSVConverter } from '../../service/utils/helper'
import { CSVLink } from 'react-csv'
import { FaFileCsv, FaTrash } from 'react-icons/fa'
import useDomain from '../../service/DomainHook'
import moment from 'moment'

function EmailSubscriberList() {

    const [orders, setOrders] = useState([]);
    const [orderDownloadable, setOrderDownloadable] = useState([]);
    const [selectedEmailSubscriber, setSelectedEmailSubscriber] = useState();
    const [reload, setReload] = useState(false)
    const [pageCount, setPageCount] = useState(0)

    const fetchIdRef = useRef(0)
    const { isOpen, onOpen, onClose } = useDisclosure();
    const franchiseeId = localStorage.getItem('franchiseeId');
    const [hasSubDomain] = useDomain();
    const baseURL = `${config.baseUrl}/api/v1`;
    const token = localStorage.getItem('token');
    const toast = useToast();

    const deleteEmailSubscriber = () => {
        let url = `/emailSubscribers/${selectedEmailSubscriber}`;
        if (hasSubDomain) {
            url = `/emailSubscribers/${selectedEmailSubscriber}/${franchiseeId}`;
        }
        axios({
            url: url,
            method: 'delete',
            baseURL: baseURL,
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then((res) => {
             
                toast({
                    title: res.data.message,
                    status: "success",
                    duration: 4000,
                    isClosable: true
                })
                setReload((res) => !res);
            })
            .catch((err) => {
                toast({
                    title: err?.response?.data?.message,
                    status: "error",
                    duration: 4000,
                    isClosable: true
                })
            })
    }

    let columns = [
        {
            Header: 'Name',
            accessor: '',
            Cell: props => {
                let data = props.row.original;
                return (
                    `${data.firstName} ${data.lastName}`
                );
            }
        },
        {
            Header: 'Email',
            accessor: 'email'
        },
        {
            Header: 'Message',
            accessor: 'message'
        },
        {
            Header: 'Action',
            accessor: '',
            Cell: props => {
                let data = props.row.original;
                return (
                    <Button bg='none' onClick={() => {
                        onOpen();
                        setSelectedEmailSubscriber(data.email);
                    }}>
                        <FaTrash />
                    </Button>
                );
            }
        }
    ]

    let sortOn = ['Order Id', 'Order date', 'Order Status']


    const fetchData = useCallback(({ pageSize, pageIndex, globalFilter, skipPageResetRef, startDate }) => {

        const franchiseeId = localStorage.getItem('franchiseeId');
        const baseURL = `${config.baseUrl}/api/v1`;
        const fields = 'orderNo,orderDate,status,total,-franchiseeId';
        const sorts = '-createdAt';

        const fetchId = ++fetchIdRef.current

        if (globalFilter) {
            // Filtering on name field
            axios({
                url: `/emailSubscribers?franchiseeId=${franchiseeId}&search=${globalFilter}`,
                method: 'get',
                baseURL: baseURL
            })
                .then((res) => {
                    const { data: serverData, result: totalLength } = res.data
                    if (fetchId === fetchIdRef.current) {
                        if (serverData.length >= 1) {
                            skipPageResetRef.current = true
                            setOrders(serverData)
                            setPageCount(Math.ceil(totalLength / pageSize))

                            let convertedData = CSVConverter.arrayToCSVConverter(serverData)
                            setOrderDownloadable(convertedData)
                        }
                    }
                })
                .catch(err => console.log(err, 'Data fetch error'))
        }
        else if (startDate) {
            let month = '' + (startDate.getMonth() + 1);
            let day = '' + startDate.getDate();
            let year = startDate.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            let searchDate = [day, month, year].join('/');


            const token = localStorage.getItem('token')
            axios({
                url: `/emailSubscribers/filter/${franchiseeId}`,
                method: 'post',
                baseURL: baseURL,
                headers: { 'Authorization': `Bearer ${token}` },
                data: { searchDate: searchDate }
            })
                .then((res) => {
                    const { data: serverData, result: totalLength } = res.data
                    if (fetchId === fetchIdRef.current) {
                        if (serverData.length >= 1) {
                            skipPageResetRef.current = true
                            setOrders(serverData)
                            setPageCount(Math.ceil(totalLength / pageSize))

                            let convertedData = CSVConverter.arrayToCSVConverter(serverData)
                            setOrderDownloadable(convertedData)
                        }
                        else {
                            skipPageResetRef.current = true
                            setOrders(serverData)
                            setPageCount(Math.ceil(totalLength / pageSize))
                        }
                    }
                })
                .catch(err => console.log(err))
        }
        else {
            // Pagination 
            axios({
                url: `/emailSubscribers?franchiseeId=${franchiseeId}&page=${(pageIndex) + 1}&limit=${pageSize}&fields=&sort=`,
                method: 'get',
                baseURL: baseURL
            })
                .then((res) => {
                    const { data: serverData, result: totalLength } = res.data
                    if (fetchId === fetchIdRef.current) {
                        skipPageResetRef.current = true
                        setOrders(serverData)
                        setPageCount(Math.ceil(totalLength / pageSize))

                        let convertedData = CSVConverter.arrayToCSVConverter(serverData)
                        setOrderDownloadable(serverData)
                    }
                })
                .catch(err => console.log(err, 'Data fetch error'))
        }
    }, [])

    const csvHeaders = [
        { label: "Email", key: "email" },
        { label: "First Name", key: "firstName" },
        { label: "Last Name", key: "lastName" },
        { label: "Message", key: "message" },
        // { label: "Product_Info (Name)", key: "subOrders.products.name" }
    ];

    return (
        <Container maxWidth={1100} centerContent my={6}>
            <Box w="100%" bg="white" rounded="lg" boxShadow="lg">
                <Flex justifyContent="space-between" pt={4} px={4}>
                    <Breadcrumb pathname="Email Subscriber" />
                    <Button colorScheme="teal" variant="outline" size="sm">
                        <CSVLink
                            // data={CSVConverter.ConvertToCSV(orderDownloadable)}
                            headers={csvHeaders}
                            data={ orderDownloadable }
                            filename={`Email_Subscriber_List_${moment(new Date()).format('YYYY-MM-DD')}`}
                        >
                            <Icon as={FaFileCsv} fontSize="15px" /> Download CSV
                        </CSVLink>
                    </Button>
                </Flex>
                <EmailSubscriberTable
                    reload={reload}
                    data={orders}
                    columns={columns}
                    pageCount={pageCount}
                    fetchData={fetchData}
                    sortOn={sortOn}
                    defaultPageSize={10}
                    tableHeightInPage='59vh'
                    selectNoOfRows={[5, 10, 20, 30, 50, 100]}
                />
            </Box>

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent m={5}>
                    <ModalHeader >Delete Subscriber Email {selectedEmailSubscriber}?</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Are you sure, you want to delete?
                    </ModalBody>

                    <ModalFooter>
                        <Button size="sm" mr={3} colorScheme="teal" variant="outline" onClick={onClose}>
                            No
                        </Button>

                        <Button size="sm" colorScheme="red" variant="outline"
                            onClick={() => {
                                deleteEmailSubscriber();
                                onClose();
                            }}>Yes</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </Container>
    )
}

export default EmailSubscriberList
