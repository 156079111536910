import { useState, useEffect, useRef } from 'react';
import {
    Box, Table, Thead, Tbody,
    Tr, Th, Td, chakra, Button, Input,
    NumberInput, NumberInputField,
    NumberInputStepper, NumberIncrementStepper,
    NumberDecrementStepper, Flex, Select, Icon
} from '@chakra-ui/react'
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons"

import { useTable, useSortBy, usePagination, useGlobalFilter, useAsyncDebounce } from "react-table"
import { useRouteMatch, useHistory } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'
import { BsCheck } from 'react-icons/bs';
import { IoCloseOutline } from 'react-icons/io5';

// Search component 
function GlobalSearch({ globalFilter, setGlobalFilter }) {
    const [value, setValue] = useState(globalFilter)

    const onChange = useAsyncDebounce(value => {
        // Set undefined to remove the filter entirely 
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <Box mb={4}>
            <Input
                type="text"
                w="50%"
                variant="flushed"
                placeholder="Search"
                value={value || ''}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
        </Box>
    )
}

function BusinessTable({
    // props 
    data,
    columns,
    onFetchData,
    pageCount: controlledPageCount,
    sortOn,
    defaultPageSize,
    tableHeightInPage,
    selectNoOfRows
}) {
    const skipPageResetRef = useRef()

    // const columnList = useMemo(() => columns, []) 
    // const dataList = useMemo(() => data, []) 

    useEffect(() => {
        // After the table has updated, always remove the flag 
        skipPageResetRef.current = false
    })

    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: defaultPageSize },
        manualPagination: true,
        manualSortBy: true,
        pageCount: controlledPageCount,
        autoResetPage: false,
        autoResetGlobalFilter: false,
        autoResetSortBy: false,

        autoResetFilters: !skipPageResetRef.current,
        autoResetExpanded: !skipPageResetRef.current,
        autoResetGroupBy: !skipPageResetRef.current,
        autoResetSelectedRows: !skipPageResetRef.current,
        // autoResetSortBy: !skipPageResetRef.current,
        autoResetRowState: !skipPageResetRef.current,
        // !skipPageResetRef.current
    },
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        prepareRow,
        pageCount,
        state: { pageIndex, pageSize, globalFilter, sortBy },
        setPageSize,
        gotoPage,
        setGlobalFilter
    } = tableInstance;



    useEffect(() => {
        onFetchData({ pageIndex, pageSize, globalFilter, skipPageResetRef, sortBy })
    }, [onFetchData, pageIndex, pageSize, globalFilter, sortBy])

    const history = useHistory();
    let { url } = useRouteMatch();

    if (url.endsWith('/')) {
        url = url.slice(0, -1)
    }

    return (
        <Box overflow={'auto'}>
            {/* Search & List */}
            <Box pt={3} pb={1} px={4}>
                <GlobalSearch
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />

                <Box >
                    {/* <Scrollbars autoHide autoHideTimeout={500} autoHideDuration={200}> */}
                    <Table {...getTableProps()}>
                        <Thead>
                            {
                                headerGroups?.map(headerGroup => (
                                    <Tr {...headerGroup.getHeaderGroupProps()} bg="gray.300">
                                        {
                                            headerGroup.headers?.map(column => (
                                                <Th
                                                    {
                                                    ...column.getHeaderProps(
                                                        sortOn.includes(column.Header)
                                                        && column.getSortByToggleProps()
                                                    )
                                                    }
                                                    fontSize=".8rem"
                                                    sx={{ width: column.Header === 'ID' && { base: '', sm: '', md: '14%', lg: '9%' } }}
                                                >

                                                    {/* Display each cell header */}
                                                    {column.render('Header')}

                                                    {/* Display icons for sorting */}
                                                    <chakra.span pl="10px">
                                                        {
                                                            column.isSorted
                                                                ?
                                                                (
                                                                    column.isSortedDesc
                                                                        ? <TriangleDownIcon aria-label="sorted descending" />
                                                                        : <TriangleUpIcon aria-label="sorted ascending" />
                                                                )
                                                                : null
                                                        }
                                                    </chakra.span>
                                                </Th>
                                            ))
                                        }
                                    </Tr>
                                ))
                            }
                        </Thead>

                        <Tbody {...getTableBodyProps()} >
                            {
                                page?.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <Tr
                                            {...row.getRowProps()}
                                            _hover={{ background: '#0000000a' }}
                                            cursor="pointer"
                                            onClick={
                                                () => { history.push(`${url}/edit/${row.original._id}`) }
                                            }>
                                            {
                                                row?.cells?.map(cell => {
                                                    if (typeof cell.render('Cell').props.cell.value === 'boolean') {
                                                        return (
                                                            <Td p={'5px 5px 2px'} fontSize=".9rem" {...cell.getCellProps()}>
                                                                {cell.render('Cell').props.cell.value ?
                                                                    <Icon ml={5} as={BsCheck} h={6} w={6} color="green.500" />
                                                                    : <Icon ml={5} as={IoCloseOutline} h={6} w={6} color="red.500" />}
                                                            </Td>
                                                        )
                                                    }

                                                    else {
                                                        return (
                                                            <Td p={'5px 5px 2px'}  {...cell.getCellProps()} fontSize=".9rem">
                                                                {cell.render('Cell')}
                                                            </Td>
                                                        )
                                                    }
                                                })
                                            }
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>
                    {/* </Scrollbars> */}
                </Box>
            </Box>


            {/* Pagination */}
            <Box p="20px">
                <Flex flexWrap="wrap">
                    <Button
                        onClick={() => gotoPage(0)}
                        size="xs"
                        mr="10px" colorScheme="teal"
                        variant="outline"
                        disabled={!canPreviousPage}
                    >
                        {`<<`}
                    </Button>
                    <Button
                        onClick={() => previousPage()}
                        size="xs"
                        mr="10px" colorScheme="teal"
                        variant="outline"
                        disabled={!canPreviousPage}
                    >
                        {`<`}
                    </Button>
                    <Button
                        onClick={() => nextPage()}
                        size="xs"
                        mr="10px" colorScheme="teal"
                        variant="outline"
                        disabled={!canNextPage}
                    >
                        {`>`}
                    </Button>
                    <Button
                        onClick={() => gotoPage(Math.ceil(pageCount - 1))}
                        size="xs"
                        mr="10px" colorScheme="teal"
                        variant="outline"
                        disabled={!canNextPage}
                    >
                        {`>>`}
                    </Button>

                    <chakra.span mr="10px" fontWeight="600">
                        {Math.ceil(pageIndex + 1)} of {pageCount}
                    </chakra.span>

                    <chakra.span>
                        | Go to page &nbsp;
                    </chakra.span>
                    <NumberInput
                        defaultValue={Math.ceil(pageIndex + 1)}
                        min={1}
                        max={pageCount}
                        onChange={(value) => gotoPage((+Math.ceil(value)) - 1)}
                        w="80px" size="sm" mr="20px"
                        keepWithinRange={true}
                        value={pageIndex + 1}
                    >
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>

                    <Box>
                        <Select
                            value={pageSize} variant="flushed" size="sm"
                            w="110px" colorScheme="red"
                            onChange={e => setPageSize(Number(e.target.value))}>
                            {
                                selectNoOfRows.map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))
                            }
                        </Select>
                    </Box>
                </Flex>
            </Box>
        </Box>
    )
}

export default BusinessTable;
