import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from '@chakra-ui/react';
import axios from 'axios';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { config } from '../../config';

function DeleteComponent(props) {
   const baseUrl = `${config.baseUrl}/api/v1`;
   const token = localStorage.getItem('token');
   const toast = useToast();
   const history = useHistory();

   const {
      headerName,
      isOpen, onClose,
      getDeleteApi,
      routeOnDelete,
      showBackendMsg,
      toastSuccessMsgTitle,
      toastErrMsgTitle,
      bodyObject=null
   } = props;

   const confirmDelete = () => {
      axios({
         url: getDeleteApi(),
         method: 'delete',
         baseURL: baseUrl,
         headers: { 'Authorization': `Bearer ${token}` },
         data: {bodyObject}
      })
         .then((res) => {
            toast({
               title: toastSuccessMsgTitle,
               status: "success",
               duration: 4000,
               isClosable: true
            })
            history.push(routeOnDelete);
         })
         .catch((err) => {
            const msg = showBackendMsg ? err?.response?.data?.message : toastErrMsgTitle;
            toast({
               title: msg,
               status: "error",
               duration: 4000,
               isClosable: true
            })
         })
   }

   return (
      <div>
         <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent isCentered>
               <ModalHeader>Delete {headerName}</ModalHeader>
               <ModalCloseButton />
               <ModalBody>
                  <Text>
                     Are you sure, you want to delete?
                  </Text>
                 
               </ModalBody>
               <ModalFooter>
               <Button
                        size="sm" mr={3} colorScheme="teal" variant="outline"
                        onClick={onClose}>
                        No
                     </Button>
                     <Button
                        size="sm" colorScheme="red" variant="outline"
                        onClick={() => {
                           confirmDelete()
                           onClose()
                        }}>
                        Yes
                     </Button>
               </ModalFooter>
            </ModalContent>
         </Modal>
      </div>
   )
}

export default DeleteComponent; 