import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import axios from 'axios';

import PageHeader from './PageHeader';

import { config } from '../../config';
import MetaDecorator from '../../components/metaDecorator';
import useDomain from '../../service/DomainHook';
import Error404 from '../Error404';
import PageSkeleton from './PageSkeleton';
import DomainUtils from '../../service/utils/DomainUtils';
import LandingContact from '../Landing/landingContact';
import PageBodyWIthContactForm from './PageBodyWIthContactForm';

function PageBySlug() {
  const [pageContent, setPageContent] = useState([]);
  const [hasSubDomain] = useDomain();
  const [notFound, setNotFound] = useState(false);
  const [hasContactForm, setHasContactForm] = useState(false);
  const [page, setPage] = useState({
    menu: [],
    pageHeader: {
      title: null,
      image: null,
    },
    pageFeature: [],
    pageSlider: null,
    social: [],
  });

  const [pageTitle, setPageTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');

  const [headerImage, setHeaderImage] = useState('');
  const [headerTitle, setHeaderTitle] = useState('');
  const [headerStyle, setHeaderStyle] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  let { pageSlug } = useParams();

  let baseUrl = config.baseUrl;

  useEffect(() => {
    window.scrollTo(0, 0);
    setNotFound(false);
    setIsLoading(true);

    let url = `/api/v1/pages/slug/${pageSlug}`;
    if (hasSubDomain) {
      const franchisee = localStorage.getItem('franchisee');
      url = `/api/v1/pages/slug/${pageSlug}/${franchisee}`;
    }

    const getContactFormData = async () => {
      let getUrl = `${baseUrl}/api/v1/pages/superAdmin`;

      if (hasSubDomain) {
        const [franchisee] = await DomainUtils();
        getUrl = `${baseUrl}/api/v1/pages/cityAdmin/${franchisee}`;
      }

      await axios
        .get(getUrl)
        .then((response) => {
          // const { pageContact } = response?.data?.data;
          // localStorage.setItem('subscriberLabel', pageContact?.checkboxLabel);
          // response?.data?.data.pageContact.showForm && setHasContactForm(true);
          setHasContactForm(true);
          setPage(response?.data?.data);
        })
        .catch((error) => console.log('error:::', error));
    };

    if (pageSlug) {
      // get page content by route name
      axios({
        url: url,
        method: 'get',
        baseURL: baseUrl,
      })
        .then((response) => {
          const data = response.data.data;
          if (data) {
            setPageTitle(`Gift Your Town | ${data?.pageTitle}`);
            setMetaKeywords(data?.meta?.keywords);
            setMetaDescription(data?.meta?.description);
            if (Object.keys(data).length) {
              const myArray = data?.pageContent.split(
                '<p>[contact-us id="contactUs"]</p>'
              );
              if (myArray.length > 1) {
                getContactFormData();
              }
              setPageContent(myArray);
              setHeaderImage(data?.pageHeader?.image);
              setHeaderTitle(data?.pageHeader?.title);
              setHeaderStyle(data?.pageHeaderStyle);
            }
            setIsLoading(false);
          } else {
            setNotFound(true);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setNotFound(true);
    }
  }, [pageSlug]);

  return notFound ? (
    <Error404 />
  ) : isLoading ? (
    <Box bg="#edf2f9" py={{ base: '30px', sm: '30px', md: '40px', lg: '40px' }}>
      <PageSkeleton />
    </Box>
  ) : (
    <>
      <Box
        bg="#edf2f9"
        py={{ base: '30px', sm: '30px', md: '40px', lg: '40px' }}>
        <MetaDecorator
          title={pageTitle}
          description={metaDescription}
          keywords={metaKeywords}
        />
        {pageContent ? (
          <>
            <PageHeader
              title={pageTitle}
              image={headerImage}
              headerTitle={headerTitle}
              headerStyle={headerStyle}
            />
            <Box px={{ base: '5px', sm: '5px', md: '40px', lg: '40px' }}>
              <PageBodyWIthContactForm
                content={pageContent}
                hasContactForm={hasContactForm}
                hasSubDomain={hasSubDomain}
                page={page}
              />
            </Box>
          </>
        ) : (
          <Error404 />
        )}
      </Box>
    </>
  );
}

export default PageBySlug;
