import React, { useState, useEffect } from 'react' 
import { Container, Box, Text,  ListItem, UnorderedList } from '@chakra-ui/react' 
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb' 
import {config} from '../../config' 
import axios from 'axios' 

function CategoryHirarchy() { 
   const baseURL = `${config.baseUrl}/api/v1`;
   const [ categories, setCategories ] = useState([]); 
   const [isEmpty, setIsEmpty] = useState(false);
   const franchiseeId = localStorage.getItem('franchiseeId'); 

   useEffect(() => { 
      axios({ 
         url: `/categories/hierarchy/${franchiseeId}`, 
         method: 'get', 
         baseURL: baseURL
      }) 
      .then((res) => { 
         setIsEmpty(false);
         setCategories(res.data.category);  
      }) 
      .catch(err => {
         if (err?.response?.data.status === 'fail') {
            setIsEmpty(true);
         }
         
      }) 
   }, []) 

   const getList = (lists) => {
      let listElement = lists.map((list) => { 
         return ( 
            <ListItem key={list.id} fontSize="15px"> 
             <Text>{list.name}</Text>
            {
               list.child.length > 0 &&
               <UnorderedList ml="24px">
                  { getList(list.child)}
               </UnorderedList> 
            }  
            </ListItem>
         ) 
      })  
      return listElement;
   }

   return ( 
      <Container maxWidth={1100} centerContent py={6}> 
         <Box w="100%" bg="white" rounded="lg" boxShadow="lg" p={4}> 
            <Breadcrumb pathname="Category" nameOrTitle="Hierarchy" link={`/admin/category`} mb={5} /> 
            {
               isEmpty 
               ? <Text>No data found</Text>
               : <UnorderedList px={10}> 
               { 
                  categories?.map((category) => { 
                     return ( 
                        <ListItem key={category.id}> 
                        <Text fontWeight={700}>{category.name}</Text>
                        {
                           category.child.length > 0 &&
                           <UnorderedList ml="20px">
                              { getList(category.child) }
                           </UnorderedList>
                           
                        }  
                        </ListItem>
                     ) 
                  }) 
               } 
               </UnorderedList> 
            }
         </Box> 
      </Container> 
   ) 
} 

export default CategoryHirarchy; 
