import React, { useMemo, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import { config } from '../../../config'
import {
    Select, Flex, Heading, Box,
    FormLabel, FormControl, Badge, Icon
} from '@chakra-ui/react';
import DatePickerComponent from '../DatePickerComponent';
import OrderTable from './OrderTable';
import { CSVLink, CSVDownload } from "react-csv";
import { FaCalendarAlt, FaFileCsv } from 'react-icons/fa';
import { CSVConverter } from '../../../service/utils/helper';


const OrderList = ({ styles }) => {
    const baseURL = `${config.baseUrl}/api/v1`;
    const [startDate, setStartDate] = useState(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000));
    const [endDate, setEndDate] = useState(new Date());

    const [orderData, setOrderData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [filterType, setFilterType] = useState('all');

    const fetchIdRef = useRef(0);

    const formatDate = (date) => {
        return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    };

    const onFetchData = useCallback(({ pageSize, pageIndex }) => {

        const franchiseeId = localStorage.getItem('franchisee');
        const token = localStorage.getItem('token');

        let payload = {
            franchiseeId,
            fromDate: formatDate(startDate),
            toDate: formatDate(endDate)
        }

        if (filterType !== "all") {
            payload = { ...payload, status: filterType }
        } else {
            payload = {
                franchiseeId,
                fromDate: formatDate(startDate),
                toDate: formatDate(endDate)
            }
        }

        const fetchId = ++fetchIdRef.current
        axios({
            url: '/orders/dashboard',
            method: 'POST',
            baseURL: baseURL,
            data: payload,
            headers: {
                'Authorization': `Bearer ${token}`
            }

        }).then((res) => {
            const serverData = res.data.data;
            if (fetchId === fetchIdRef.current) {
                const startRow = pageSize * pageIndex
                const endRow = startRow + pageSize
                setOrderData(serverData.slice(startRow, endRow))
                setPageCount(Math.ceil(serverData.length / pageSize))
            }

        }).catch((err) => {
            console.log(err.message);
        })

    }, [startDate, filterType, endDate])


    const columns = useMemo(() => [
        {
            Header: 'Order ID',
            accessor: 'orderNo'
        },

        {
            Header: 'Status',
            accessor: 'status',
            Cell: (props) => {
                let data = props.row.original;
                let element = <Badge>{data.status}</Badge>
                switch (data.status) {
                    case 'PROCESSING':
                        element = <Badge colorScheme="purple">{data.status}</Badge>
                        break;
                    case 'PAYMENT_PENDING':
                        element = <Badge colorScheme="pink">{data.status}</Badge>
                        break;
                    case 'COMPLETE':
                        element = <Badge colorScheme="green">{data.status}</Badge>
                        break;
                    case 'FAILED':
                        element = <Badge colorScheme="red">{data.status}</Badge>
                        break;
                    case 'ONHOLD':
                        element = <Badge colorScheme="orange">{data.status}</Badge>
                        break;
                    case 'REFUNDED':
                        element = <Badge colorScheme="yellow">{data.status}</Badge>
                        break;
                    case 'CANCELED':
                        element = <Badge>{data.status}</Badge>
                        break;
                    default:
                        break;
                }
                return element;
            }

        },
        {
            Header: 'Total',
            accessor: 'total',
            Cell: (props) => {
                let data = props.row.original;
                return (
                    <span>${parseFloat(data.total).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                )
            }

        }
    ], []);

    const data = useMemo(() => (orderData), [orderData]);


    return (
        <Box {...styles} width={["100%", "100%", "50%", "50%"]}>
            {/* <Flex justifyContent="space-between" align='center' mt={2} gridGap={2}> */}
                <Heading size="md" textAlign="center">Order List</Heading>
                {/* <CSVLink data={ CSVConverter.ConvertToCSV(data)}><Icon as={FaFileCsv} fontSize="20px" /></CSVLink>
                { typeof CSVConverter.ConvertToCSV(data) } */}
            {/* </Flex> */}
            

            <Flex justifyContent="space-between" align='center' mt={2} gridGap={2}>
                <Select placeholder='Filter By' size="sm" w='90%' mt={2} px="10px"
                    onChange={(e) => setFilterType(e.target.value)} defaultValue={filterType}
                >   <option value='all'>All</option>
                    <option value='PROCESSING'>Processing</option>
                    <option value='PAYMENT_PENDING'>Payment Pending</option>
                    <option value='COMPLETE'>Completed</option>
                    <option value='FAILED'>Failed</option>
                    <option value='ONHOLD'>On Hold</option>
                    <option value='REFUNDED'>Refunded</option>
                    <option value='CANCELED'>Canceled</option>

                </Select>
                <FormControl >
                    <Flex >
                        <FormLabel alignSelf="flex-end" m={0} px="5px">From Date:</FormLabel>
                        <Box w="45%"> <DatePickerComponent startDate={startDate} setStartDate={setStartDate} /></Box>
                    </Flex>
                </FormControl>
                <FormControl >
                    <Flex >
                        <FormLabel alignSelf="flex-end" m={0} px="5px">To Date:</FormLabel>
                        <Box w='45%'> <DatePickerComponent startDate={endDate} setStartDate={setEndDate} /></Box>
                    </Flex>
                </FormControl>

                {/* <CSVLink data={data}><Icon as={FaFileCsv} fontSize="20px" /></CSVLink> */}

            </Flex>

            <OrderTable columns={columns} onFetchData={onFetchData} data={data} pageCount={pageCount} title='' />

        </Box>
    )
}

export default OrderList;