import axios from "axios"
import { config } from '../config'

const baseURL = `${config.baseUrl}/api/v1`;

export const getFooter = async (params) => { 
    try { 
        let footer = {
            title: '', 
            copyrightText: '', 
            menu: []
        }

        const response = await axios({
            url: '/footers',
            method: 'get',
            baseURL: baseURL,
            params: params
        });
        
        if (response.data.data.length) {
            footer = response.data.data[0];
        }
        return footer;   

    } catch (error) {
        console.error(error);
    }
} 
