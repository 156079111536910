import axios from "axios"
import {config} from '../config'

const baseUrl = config.baseUrl;

export const getTags = async (ext) => {
    try {
        const response = await axios.get(`${baseUrl}/api/v1/tags${ext}`);
        return response.data;    
      } catch (error) {
        console.error(error);
      }
}