import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
    Box, Container, Input, Button, useToast, Checkbox,
    FormErrorMessage, FormLabel, FormControl, Flex, Image,
    SimpleGrid
} from '@chakra-ui/react'
import axios from 'axios'
import { config } from '../../config'
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';


function Create() {

    const toast = useToast();
    const history = useHistory();
    const baseURL = `${config.baseUrl}`;
    const token = localStorage.getItem('token');
    const franchiseeId = localStorage.getItem('franchiseeId');

    const { control, register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        shouldFocusError: false
    });

    const [previewImage, setPreviewImage] = useState('');

    function imageHandler(e) {
        // To preview before upload --- Converting to base 64
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setPreviewImage(reader.result)
            }
        }

        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0])
        }
    }

    const submitHandler = (data) => {

        const formData = new FormData();

        formData.append('franchiseeId', franchiseeId);
        formData.append('name', data.name);

        if (previewImage) {
            formData.append('image', data.image[0]);

        } else {
            formData.append('image', '');
        }

        if (data.active) { formData.append('active', data.active) }

        let price = +data?.price
        price = price.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
        price = price.replaceAll(',', '');
        data.price = +price;
        if (data.price) { formData.append('price', data.price) }

        axios({
            url: '/api/v1/greetingCards',
            method: 'post',
            baseURL: baseURL,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        })
            .then(() => {
                toast({
                    title: "Greeting card created successfully.",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                })
                history.push(`/admin/greeting-card`);
            })
            .catch(error => console.log(error))
    }

    return (
        <Container maxWidth={1100} centerContent my={6}>
            <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg">
                <Breadcrumb pathname="Greeting Card" create={true} link={`/admin/greeting-card`} mb={5} />

                <form onSubmit={handleSubmit(submitHandler)}>
                    <SimpleGrid columns={[1, 1, 2, 2]} spacing="20px">
                        <Box>
                            {/* Name */}
                            <FormControl id="name" mb="20px" isInvalid={errors?.name}>
                                <Flex>
                                    <FormLabel as={'p'} fontWeight="semibold">Name</FormLabel>
                                    <Input
                                        name="name" type="text" mt="-5px" variant="flushed" size="sm" borderColor="gray.300"
                                        ref={register({ required: 'Name is required' })} />
                                </Flex>
                                <FormErrorMessage>
                                    {errors?.name?.message}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl id="price" mb="20px">
                                <Flex>
                                    <FormLabel as={'p'} fontWeight="semibold">Price</FormLabel>
                                    <Input
                                        type="text" name="price" ref={register} size="sm"
                                        mt="-5px" variant="flushed" borderColor="gray.300" />
                                </Flex>
                            </FormControl>

                            {/* Active */}
                            <FormControl>
                                <Controller
                                    control={control}
                                    name="active"
                                    defaultValue={true}
                                    render={(
                                        { onChange, onBlur, value, name, ref },
                                        { invalid, isTouched, isDirty }
                                    ) => {
                                        return (
                                            <Checkbox
                                                mb={5}
                                                colorScheme="teal"
                                                onChange={(e) => onChange(e.target.checked)}
                                                isChecked={value}
                                                as={'p'} fontWeight="semibold"
                                            >
                                                Active
                                            </Checkbox>
                                        )
                                    }}
                                />
                            </FormControl>

                        </Box>
                        <Box>
                            <FormControl mb="20px" isInvalid={errors?.image}>
                                <Box>
                                    <FormLabel
                                        w='100%'
                                        h='100%'
                                        lineHeight="60px"
                                        border="1px dashed #BDBDBD"
                                        htmlFor='image' textAlign="center" cursor="pointer"
                                        // color="gray.500"
                                        p='10px'
                                    >
                                        Click To Upload Image
                                    </FormLabel>
                                    <Input
                                        type="file"
                                        name="image"
                                        ref={register({ required: false })}
                                        id='image'
                                        accept="image/*"
                                        onChange={(e) => imageHandler(e)}
                                        style={{ display: "none" }} />
                                </Box>
                                {/* <FormErrorMessage> {errors?.image?.message} </FormErrorMessage> */}
                                {previewImage && <Image mt='5px' src={previewImage} alt="Greeting card" maxH="200px" />}
                            </FormControl>
                        </Box>
                    </SimpleGrid>

                    <Button
                        type="submit" colorScheme="teal"
                        variant="outline" size="sm" rounded={4}>
                        Save
                    </Button>
                </form>
            </Box>
        </Container>
    )
}

export default Create; 