import { useEffect, useState, useContext, useRef } from 'react'
import {
  Box, Flex, Text, IconButton, Image,
  Button, Stack, Collapse, Icon, Link, Badge,
  // Popover, PopoverTrigger, PopoverContent,
  useColorModeValue, useColorMode,
  useDisclosure,
  Container,
  Center,
  InputGroup,
  Input,
  InputRightElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
} from '@chakra-ui/react';

import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  Search2Icon,
} from '@chakra-ui/icons';

import { Link as RouteLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart, faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import { config } from '../../config';
import useDomain from '../../service/DomainHook';
import DomainUtils from '../../service/utils/DomainUtils';
import { CartContext } from '../../context/CartContext';
import LoginModal from '../modal/LoginModal';
import { ThemeContext } from '../../context/ThemeContext';
import { useHistory } from 'react-router-dom';
import { debounce } from '../../service/utils/helper';

function MainNavbar() {
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: loginIsOpen, onOpen: loginOnOpen, onClose: loginOnClose } = useDisclosure();
  const [state, dispatch] = useContext(CartContext);
  const [theme] = useContext(ThemeContext);
  const [rootItem, setRootItem] = useState(null);
  const [items, setItems] = useState(null);
  const [logo, setLogo] = useState('');
  const [hasSubDomain] = useDomain();
  const baseURL = `${config.baseUrl}/api/v1`;

  const [globalSearchWord, setGlobalSearchWord] = useState();
  const [globalSearchData, setGlobalSearchData] = useState([]);
  const limit = 10;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const input = useRef();
  const [menuStyle, setMenuStyle] = useState({});

  const setNewPage = (data) => {
    data.selected = data.selected + 1;
    setPage(data.selected)
  }

  useEffect(async () => {
    const params = {
      adminType: 'SA',
      publish: true
    }

    if (hasSubDomain) {
      const [franchisee] = await DomainUtils();
      params.adminType = 'CA';
      params.franchiseeId = franchisee;
    }

    if (logo === '') {
      let url = `${baseURL}/globalSettings?adminType=SA&fields=logo&sort=createdAt&limit=1`;
      if (hasSubDomain) {
        const [franchiseeId] = await DomainUtils();
        url = `${baseURL}/globalSettings?adminType=CA&franchiseeId=${franchiseeId}&fields=logo&sort=createdAt&limit=1`
      }

      axios.get(url)
        .then(response => {
          const [globalSetting] = response.data.data;
          if (globalSetting) {
            if ('logo' in globalSetting) {
              setLogo(globalSetting.logo);
            }
          }
        })
        .catch(error => console.log(error));
    }

    let cartToken = localStorage.getItem('cartToken');
    if (cartToken) {
      axios({ url: `/cart/${cartToken}`, method: 'GET', baseURL: baseURL })
        .then(response => {
          const { products } = response.data.data;
          if (products.length > 0) {
            dispatch({
              type: 'SET_CART',
              payload: products.length
            })
          }
        })
        .catch(error => console.log(error))
    }

  }, [])


  useEffect(() => {
    const fetchMenu = async () => {
      let url = '/menus/SA';
      if (hasSubDomain) {
        const [franchiseesId] = await DomainUtils();
        url = `/menus/CA/${franchiseesId}`;
      }

      try {
        const { data: { data: menu } } = await axios.get(url, { baseURL });
        if (menu) {
          const rootId = menu.content.rootId;
          setRootItem(menu.content.items[rootId]);
          setItems(menu.content.items);
          setMenuStyle(menu.style)

        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchMenu();
  }, [])

  const globalSearch = (e) => {
    // const history = useHistory();
    let franchiseeId = localStorage.getItem('franchisee');
    setGlobalSearchWord(e.target.value);

    debounce(() => {
      if (e.target.value !== '') {
        axios({
          url: `/globalSettings/globalSearch?search=${e.target.value}&franchiseeId=${franchiseeId}&page=${page}&limit=${limit}`,
          method: 'get',
          baseURL: baseURL
        })
          .then(response => {
            const { data, result } = response.data;

            let searchData = []
            if (response.status == 200) {
              data.map((gItem, gIndex) => {
                let searchObj = {
                  id: gItem._id,
                  name: gItem.name || gItem.title,
                  summary: gItem.summary || gItem.detais,
                  pageSource: gItem.pageSource,
                  url: ""
                }

                switch (gItem?.pageSource) {
                  case "blogContent":
                    searchObj.url = "/blog/" + gItem.slug

                    searchData.push(searchObj)
                    if (gItem?.blogTagInfo) {
                      gItem?.blogTagInfo.map((item, index) => {
                        searchObj.id = item._id
                        searchObj.name = item.name || item.title
                        searchObj.summary = item.summary || item.detais
                        searchObj.url = "/blog/tag/" + item._id

                        searchData.push(searchObj)
                      })
                    }

                    break;

                  case "productContent":
                    searchObj.url = "/shop/product/" + gItem.slug

                    searchData.push(searchObj)
                    if (gItem?.productTagInfo) {
                      gItem?.productTagInfo.map((item, index) => {
                        searchObj.id = item._id
                        searchObj.name = item.name || item.title
                        searchObj.summary = item.summary || item.detais
                        searchObj.url = "/shop/tag/" + item._id

                        searchData.push(searchObj)
                      })
                    }

                    break;

                  case "businessContent":
                    searchObj.url = "/shop/product/" + gItem.slug

                    searchData.push(searchObj)
                    if (gItem?.productTagInfo) {
                      gItem?.productTagInfo.map((item, index) => {
                        searchObj.id = item._id
                        searchObj.name = item.name || item.title
                        searchObj.summary = item.summary || item.detais
                        searchObj.url = "/shop/tag/" + item._id

                        searchData.push(searchObj)
                      })
                    }

                    break;

                  default:
                    break;
                }

              })
              searchData = [...new Set(searchData)]
              setGlobalSearchData(searchData);

            }
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        // history.push(`/all/search`); 
      }

    })
  }


  return (
    <>
      <Box
        position="fixed" top={0} left={0} w="100%" zIndex={9999} bg="white"
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
      >
        <Container maxW={1160}>
          <Flex
            bg={useColorModeValue('white', 'gray.800')}
            color={useColorModeValue('gray.600', 'white')}
            minH={'60px'}
            justifyContent={'center'}
            py={{ base: 2 }}
            px={{ base: 4 }}
            align={'center'}>
            <Flex
              flex={{ base: 1, md: 'auto' }}
              ml={{ base: -2 }}
              display={{ base: 'flex', md: 'none' }}>
              <IconButton
                onClick={onToggle}
                icon={
                  isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                }
                variant={'ghost'}
                aria-label={'Toggle Navigation'}
              />
            </Flex>
            <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
              <RouteLink to="/">
                {
                  logo
                    ? (
                      <Image src={logo} /* maxW="120px" */ maxH="56px" alt="logo"
                        ml={{ base: '10px', sm: '10px', md: '10px', lg: '0' }}
                        cursor="pointer" objectFit="contain"
                      />
                    )
                    : (
                      <Text
                        textAlign={{ base: 'center', md: 'left' }}
                        color={'gray.800'}>
                        Logo
                      </Text>
                    )
                }
              </RouteLink>

              <Flex justifyContent={'flex-end'} width={'90%'} display={{ base: 'none', md: 'flex' }} ml={5}>
                {
                  rootItem && items &&
                  <DesktopNav rootItem={rootItem} items={items} menuStyle={menuStyle} />
                }
              </Flex>
            </Flex>


            <Stack
              flex={{ base: 1, md: 0 }}
              justify={'flex-end'}
              direction={'row'}
              spacing={2}
            >

              <Center>
                <Popover>
                  <PopoverTrigger>
                    <Search2Icon color="black.300" w="50px" />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>
                      <InputGroup w={["90%", "10%", "10%", "90%"]}>
                        <Input
                          h="30px"
                          w="100%"
                          ref={input}
                          value={globalSearchWord}
                          type="text"
                          bg="#FFF"
                          onChange={globalSearch}
                          placeholder="Search"
                          autoFocus={true}
                        />
                        <InputRightElement
                          h="30px"
                          pointerEvents="none"
                          children={
                            <Search2Icon
                              color="gray.800"
                              style={{ ...menuStyle, fontSize: menuStyle?.fontSize + 'px' }}
                            />
                          }
                        />
                      </InputGroup>
                    </PopoverHeader>
                    <PopoverBody maxHeight="70vh" scrollBehavior={'smooth'} overflow={'scroll'}>
                      {
                        globalSearchData.length > 0 ?
                          globalSearchData.map((gItem, gIndex) => {

                            return (
                              <RouteLink to={gItem.url} target="_blank">
                                <Stat id={gItem.id}>
                                  <StatLabel>{gItem.name || gItem.title}</StatLabel>
                                  <StatHelpText >{gItem.summary || gItem.detais}</StatHelpText>
                                </Stat>
                              </RouteLink>
                            )
                          })
                          : "No data available"
                      }
                    </PopoverBody>
                  </PopoverContent>
                </Popover>

              </Center>

              {
                hasSubDomain &&
                <Button
                  as={RouteLink}
                  to="/cart"
                  fontSize={'sm'}
                  // fontWeight={500}
                  color="gray.800"
                  style={{ ...menuStyle, fontSize: menuStyle?.fontSize + 'px' }}
                  size="sm"
                  ml={4}
                  variant={'link'}
                >
                  <FontAwesomeIcon icon={faShoppingCart} size="sm" />
                  <Badge
                    bg={theme.color.accent}
                    px={1}
                    ml={1}
                    mt={'-10px'}
                    d="inline"
                    fontSize="12px"
                    lineHeight="16px"
                  >
                    {state}
                  </Badge>
                </Button>
              }

              <Button
                // fontSize={'sm'}
                // fontWeight={500}
                variant={'link'}
                onClick={loginOnOpen}
                color="gray.800"
                style={{ ...menuStyle, fontSize: menuStyle?.fontSize + 'px' }}
              >
                <FontAwesomeIcon icon={faSignInAlt} size="sm" style={{ marginRight: '6px' }} />
                Admin
              </Button>
            </Stack>

          </Flex>
        </Container>


        <Collapse in={isOpen} animateOpacity>
          {
            rootItem && items &&
            <MobileNav rootItem={rootItem} items={items} toggleNavBar={onToggle} />
          }
        </Collapse>

      </Box>
      {/* Login Modal */}
      <LoginModal isOpen={loginIsOpen} onOpen={loginOnOpen} onClose={loginOnClose} />
    </>
  );
}

const menuBuilder = (children, items) => {
  return children.map((navItemId) => {
    switch (items[navItemId].type) {
      case 'SYSTEM_MENU':
        return systemMenuItem(items[navItemId], items)
      case 'PAGE':
        return pageMenuItem(items[navItemId], items)
      case 'CUSTOM_MENU':
        return customLinkMenuItem(items[navItemId], items)
      default:
        return null;
    }
  })
}

const systemMenuItem = (navItem, items) => {
  return (
    <Box key={navItem.id}>
      <Popover trigger={'hover'} placement={'bottom-start'}>
        <PopoverTrigger>
          <Text
            mb={0}
            as={RouteLink}
            px={2}
            to={`/${navItem.data.slug}`}
            // fontSize={'sm'}
            // fontWeight={500}
            color="gray.800"
            _hover={{
              textDecoration: 'none',
            }}>
            {navItem.data.name}
          </Text>
        </PopoverTrigger>

        {navItem.children.length > 0 && (
          <PopoverContent
            borderWidth={1}
            boxShadow={'xl'}
            p={4}
            mt={4}
            rounded={'xl'}
            minW={'xs'}>
            <Stack>
              {navItem.children.map((child) => getDestopSubmenu(child, items))}
            </Stack>
          </PopoverContent>
        )}

      </Popover>
    </Box>
  )
}

const pageMenuItem = (navItem, items) => {
  return (
    <Box key={navItem.id}>
      <Popover trigger={'hover'} placement={'bottom-start'}>
        <PopoverTrigger>
          <Text
            mb={0}
            as={RouteLink}
            px={2}
            to={`/page-content/${navItem.data.slug}`}
            // fontSize={'sm'}
            // fontWeight={500}
            color="gray.800"
            _hover={{
              textDecoration: 'none',
            }}>
            {navItem.data.name}
          </Text>
        </PopoverTrigger>

        {navItem.children.length > 0 && (
          <PopoverContent
            borderWidth={1}
            boxShadow={'xl'}
            p={4} mt={4}
            rounded={'xl'}
            minW={'xs'}>
            <Stack>
              {navItem.children.map((child) => getDestopSubmenu(child, items))}
            </Stack>
          </PopoverContent>
        )}

      </Popover>
    </Box>
  )
}

const customLinkMenuItem = (navItem, items) => {
  return (
    <Box key={navItem.id}>
      <Popover trigger={'hover'} placement={'bottom-start'}>
        <PopoverTrigger>
          {/* <Link
            isExternal
            p={2}
            href={ navItem.data.linkText.includes("https://") || navItem.data.linkText.includes("http://")
              ? navItem.data.linkText
              : `//${navItem.data.linkText}`
            }
            fontSize={'sm'}
            fontWeight={500}
            color="gray.800"
            _hover={{ textDecoration: 'none'}}
          >
            {navItem.data.labelText}
          </Link> */}
          <Text
            as={Link}
            isExternal
            mb={0}
            px={2}
            href={navItem.data.linkText.includes("https://") || navItem.data.linkText.includes("http://")
              ? navItem.data.linkText
              : `//${navItem.data.linkText}`
            }
            // fontSize={'sm'}
            // fontWeight={500}
            color="gray.800"
            _hover={{
              textDecoration: 'none',
            }}>
            {navItem.data.labelText}
          </Text>
        </PopoverTrigger>

        {navItem.children.length > 0 && (
          <PopoverContent
            borderWidth={1}
            boxShadow={'xl'}
            p={4} mt={4}
            rounded={'xl'}
            minW={'xs'}>
            <Stack>
              {navItem.children.map((child) => getDestopSubmenu(child, items))}
            </Stack>
          </PopoverContent>
        )}

      </Popover>
    </Box>
  )
}

const DesktopNav = ({ rootItem, items, menuStyle }) => {
  return (
    <Stack direction={'row'} spacing={10} align={'center'} style={{ ...menuStyle, fontSize: menuStyle?.fontSize + 'px' }}>
      {menuBuilder(rootItem.children, items, menuStyle)}
    </Stack>
  );
};



const getDestopSubmenu = (navItemId, items) => {
  const navItem = items[navItemId];
  let linkElement = null;
  switch (navItem.type) {
    case 'SYSTEM_MENU':
      linkElement = (
        <Text
          display="block"
          as={RouteLink}
          p={2}
          to={`/page-content/${navItem.data.slug}`}
          // fontSize={'sm'}
          // fontWeight={500}
          color="gray.800"
          _hover={{
            textDecoration: 'none',
            bg: 'gray.200',
            rounded: 'md'
          }}>
          {navItem.data.name}
        </Text>
      )
      break;
    case 'PAGE':
      linkElement = (
        <Text
          display="block"
          as={RouteLink}
          p={2}
          to={`/page-content/${navItem.data.slug}`}
          // fontSize={'sm'}
          // fontWeight={500}
          color="gray.800"
          _hover={{
            textDecoration: 'none',
            bg: 'gray.200',
            rounded: 'md'
          }}>
          {navItem.data.name}
        </Text>
      )
      break;
    case 'CUSTOM_MENU':
      linkElement = (
        <Text
          isExternal
          display="block"
          as={Link}
          p={2}
          href={navItem.data.linkText.includes("https://") || navItem.data.linkText.includes("http://")
            ? navItem.data.linkText
            : `//${navItem.data.linkText}`
          }
          // fontSize={'sm'}
          // fontWeight={500}
          _hover={{
            textDecoration: 'none',
            bg: 'gray.200',
            rounded: 'md'
          }}>
          {navItem.data.labelText}
        </Text>
      )
      break;
    default:
      break;
  }

  return (
    <Box key={navItemId}>
      <Popover trigger={'hover'} placement={'bottom-start'}>
        <PopoverTrigger>
          {linkElement}
        </PopoverTrigger>

        {
          navItem.children.length > 0 &&
          <PopoverContent
            border={0}
            boxShadow={'xl'}
            p={4}
            rounded={'xl'}
            minW={'xs'}
          >
            <Stack>
              {navItem.children.map((child) => getDestopSubmenu(child, items))}
            </Stack>
          </PopoverContent>
        }
      </Popover>
    </Box>
  )
}

const MobileNav = ({ rootItem, items, toggleNavBar }) => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}>
      {rootItem.children.map((child) => (
        <MobileNavItem key={child.id} navId={child} items={items} toggleNavBar={toggleNavBar} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ key, navId, items, toggleNavBar }) => {
  const { isOpen, onToggle } = useDisclosure();
  const navItem = items[navId];
  const color = useColorModeValue('gray.600', 'gray.200');

  let linkElement = null;
  switch (navItem.type) {
    case 'SYSTEM_MENU':
      linkElement = (
        <Text
          display="block"
          flex="1 1"
          as={RouteLink}
          to={`/${navItem.data.slug}`}
          fontWeight={600}
          color={color}>
          {navItem.data.name}
        </Text>
      )
      break;
    case 'PAGE':
      linkElement = (
        <Text
          display="block"
          flex="1 1"
          as={RouteLink}
          to={`/page-content/${navItem.data.slug}`}
          fontWeight={600}
          color={color}>
          {navItem.data.name}
        </Text>
      )
      break;
    case 'CUSTOM_MENU':
      linkElement = (
        <Text
          display="block"
          flex="1 1"
          as={Link}
          isExternal
          href={navItem.data.linkText}
          fontWeight={600}
          color={color}>
          {navItem.data.labelText}
        </Text>)
      break;
    default:
      break;
  }

  return (
    <Stack spacing={4} key={key}>
      <Flex
        py={2}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Box onClick={toggleNavBar}>
          {linkElement}
        </Box>
        {navItem.children.length > 0 && (
          <Icon
            onClick={navItem.children.length > 0 && onToggle}
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
        >
          {navItem.children.length &&
            navItem.children.map((child) => (
              <MobileNavItem key={child.id} navId={child} items={items} />
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default MainNavbar;