import React, { useState, useCallback, useRef } from 'react'
import { Container, Box, Flex, Icon, Button } from '@chakra-ui/react'
import axios from 'axios'
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb'
import OrderTable from './OrderTable'
import { config } from '../../config'
import { CSVConverter } from '../../service/utils/helper'
import { CSVLink } from 'react-csv'
import { FaFileCsv } from 'react-icons/fa'
import moment from 'moment'

function OrderList() {

    const [orders, setOrders] = useState([]);
    const [orderDownloadable, setOrderDownloadable] = useState([]);

    const [pageCount, setPageCount] = useState(0)
    const fetchIdRef = useRef(0)

    let columns = [
        {
            Header: 'Order Id',
            accessor: 'orderNo'
        },
        {
            Header: 'Order date',
            accessor: 'orderDate'
        },
        {
            Header: 'Order Status',
            accessor: 'status'
        },
        {
            Header: 'Total',
            accessor: 'total'
        }
    ]

    let sortOn = ['Order Id', 'Order date', 'Order Status']


    const fetchData = useCallback(({ pageSize, pageIndex, globalFilter, skipPageResetRef, startDate }) => {

        const franchiseeId = localStorage.getItem('franchiseeId');
        const baseURL = `${config.baseUrl}/api/v1`;
        const fields = 'orderNo,orderDate,status,total,-franchiseeId';
        const sorts = '-createdAt';

        const fetchId = ++fetchIdRef.current

        if (globalFilter) {
            // Filtering on name field
            axios({
                url: `/orders?franchiseeId=${franchiseeId}&search=${globalFilter}`,
                method: 'get',
                baseURL: baseURL
            })
                .then((res) => {
                    const { data: serverData, result: totalLength } = res.data
                    if (fetchId === fetchIdRef.current) {
                        if (serverData.length >= 1) {
                            skipPageResetRef.current = true
                            setOrders(serverData)
                            setPageCount(Math.ceil(totalLength / pageSize))

                            let convertedData = CSVConverter.arrayToCSVConverter(serverData)
                            setOrderDownloadable(convertedData)
                        }
                    }
                })
                .catch(err => console.log(err, 'Data fetch error'))
        }
        else if (startDate) {
            let month = '' + (startDate.getMonth() + 1);
            let day = '' + startDate.getDate();
            let year = startDate.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            let searchDate = [day, month, year].join('/');


            const token = localStorage.getItem('token')
            axios({
                url: `/orders/filter/${franchiseeId}`,
                method: 'post',
                baseURL: baseURL,
                headers: { 'Authorization': `Bearer ${token}` },
                data: { searchDate: searchDate }
            })
                .then((res) => {
                    const { data: serverData, result: totalLength } = res.data
                    if (fetchId === fetchIdRef.current) {
                        if (serverData.length >= 1) {
                            skipPageResetRef.current = true
                            setOrders(serverData)
                            setPageCount(Math.ceil(totalLength / pageSize))

                            let convertedData = CSVConverter.arrayToCSVConverter(serverData)
                            setOrderDownloadable(convertedData)
                        }
                        else {
                            skipPageResetRef.current = true
                            setOrders(serverData)
                            setPageCount(Math.ceil(totalLength / pageSize))
                        }
                    }
                })
                .catch(err => console.log(err))
        }
        else {
            // Pagination 
            axios({
                url: `/orders?franchiseeId=${franchiseeId}&page=${(pageIndex) + 1}&limit=${pageSize}&fields=&sort=`,
                method: 'get',
                baseURL: baseURL
            })
                .then((res) => {
                    const { data: serverData, result: totalLength } = res.data
                    if (fetchId === fetchIdRef.current) {
                        skipPageResetRef.current = true
                        setOrders(serverData)
                        setPageCount(Math.ceil(totalLength / pageSize))

                        let convertedData = CSVConverter.arrayToCSVConverter(serverData)
                        setOrderDownloadable(convertedData)
                    }
                })
                .catch(err => console.log(err, 'Data fetch error'))
        }
    }, [])

    return (
        <Container maxWidth={1100} centerContent my={6}>
            <Box w="100%" bg="white" rounded="lg" boxShadow="lg">
                <Flex justifyContent="space-between" pt={4} px={4}>
                    <Breadcrumb pathname="Order" />
                    <Button colorScheme="teal" variant="outline" size="sm">
                        <CSVLink
                            // data={CSVConverter.ConvertToCSV(orderDownloadable)}
                            // headers={csvHeaders}
                            data={ orderDownloadable }
                            filename={`Order_List_${moment(new Date()).format('YYYY-MM-DD')}`}
                        >
                            <Icon as={FaFileCsv} fontSize="15px" /> Download CSV
                        </CSVLink>
                    </Button>
                </Flex>
                <OrderTable
                    data={orders}
                    columns={columns}
                    pageCount={pageCount}
                    fetchData={fetchData}
                    sortOn={sortOn}
                    defaultPageSize={10}
                    tableHeightInPage='59vh'
                    selectNoOfRows={[5, 10, 20, 30, 50, 100]}
                />
            </Box>
        </Container>
    )
}

export default OrderList
