import React, { useState, useEffect, useRef } from 'react'; 
import { useHistory, useParams } from "react-router-dom"; 
import { useForm, Controller } from 'react-hook-form'; 
import Select from 'react-select'; 
import DatePicker from "react-datepicker"; 
import { 
    Container, Box, FormControl, FormLabel, Input, FormErrorMessage, RadioGroup, 
    Radio, Button, Textarea, InputLeftElement, InputGroup, InputRightElement, 
    useToast, SimpleGrid, Stack, chakra, useDisclosure
} from '@chakra-ui/react'; 
import axios from 'axios'; 
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb'; 
import ReactSelectOptionMapper from '../../service/ReactSelect'; 
import { PostRequest } from '../../service/PostRequest'; 
import { config } from '../../config'; 
import DeleteComponent from '../../components/delete/DeleteComponent';


function CouponUpdate(props) { 
    const role = localStorage.getItem('role'); 
    const franchiseeId = localStorage.getItem('franchiseeId'); 
    const baseUrl = config.baseUrl; 
    let history = useHistory(); 
    let {id} = useParams(); 
    const toast = useToast(); 

    const { isOpen, onOpen, onClose } = useDisclosure(); 
    const { register, handleSubmit, watch, control, reset, errors, getValues } = useForm({ 
        mode: 'onChange', 
        shouldFocusError: false 
    });
    
    let type = watch('couponType', 'PERCENTAGE'); 
    let minExpireDate = watch('activeAt'); 
    
    const [ coupon, setCoupon ] = useState([]); 

    const [ activeAtValue, setActiveAtValue] = useState(null); 
    const [ expireAtValue, setExpireAtValue] = useState(null); 

    const [allFranchisee, setAllFranchisee] = useState([]); 
    const [selectedFranchisee, setSelectedFranchisee] = useState(null); 
    const [showErrMsgForFranchisee, setShowErrMsgForFranchisee] = useState(false); 

    useEffect(() => { 
        const franchiseeId = localStorage.getItem('franchiseeId'); 
        const token = localStorage.getItem('token'); 
        const params = { 
            adminType: 'SA' 
        } 

        if(franchiseeId !== 'null') { 
            params.adminType = 'CA'; 
            params.franchiseeId = franchiseeId; 
        } 

        axios({ 
            method: 'get', 
            params, 
            baseURL: baseUrl, 
            url: `/api/v1/coupons/${id}`, 
            headers: {'Authorization': `Bearer ${token}`} 
        }) 
        .then(res => { 
            if(res.data?.data?.activeAt) { 
                setActiveAtValue(new Date(res.data?.data?.activeAt)); 
            } 
            if(res.data?.data?.expireAt) { 
                setExpireAtValue(new Date(res.data?.data?.expireAt)); 
            } 
            res.data.data.maxUse = +res.data?.data?.maxUse; 
            setCoupon(res.data.data); 

            let obj = { 
                value: res.data.data?.franchiseeId?._id, 
                label: res.data.data?.franchiseeId?.name 
            } 
            setSelectedFranchisee(obj) 
        }) 
        .catch(err => console.log(err)) 
    }, []) 
    
    useEffect(() => { 
        if(role === 'SUPERADMIN') { 
            axios({ 
                method: 'get', 
                baseURL: baseUrl, 
                url: '/api/v1/franchisees?fields=name' 
            }) 
            .then(res => { 
                let franchiseeOptions = ReactSelectOptionMapper(res.data.data); 
                setAllFranchisee(franchiseeOptions); 
            }) 
            .catch(err => console.log(err)) 
        } 
    }, []) 
    
    let couponRef = useRef(false) 
    useEffect(() => { 
        if(couponRef.current) { 
            reset(coupon); 
        } 
        else { 
            couponRef.current = true; 
        } 
    }, [coupon]) 

    const getDeleteApi = () => { 
        let url = `/coupons/${id}`; 
        return url; 
    } 

    const formSubmit = (data) => { 
        if(!showErrMsgForFranchisee) { 
            const token = localStorage.getItem('token'); 
            if(role === 'SUPERADMIN') { 
                data.franchiseeId = selectedFranchisee?.value; 
            } 
            else { 
                data.franchiseeId = franchiseeId; 
            } 
    
            const couponPost = PostRequest({ 
                url:`/coupons/${coupon._id}`, 
                method:'patch', 
                token: token, 
                data: data 
            }); 
    
            couponPost.then((response) => {
                if (response.data.status === "fail" || response.data.status === "error") { 
                    toast({
                        title: "Coupon creation failed",
                        description: response.data.message,
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    }); 
                } else { 
                    toast({
                        title: "Coupon created successfully",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                    history.push(`/admin/coupon`);
                }
            });
        } 
    } 

    return (
        <Container maxWidth={1100} centerContent py={6}>
            <Box py={4} px={4} w="100%" bg="white" rounded="lg" boxShadow="lg">
                <Breadcrumb pathname="Coupon" nameOrTitle={coupon.code} link={`/admin/coupon`} mb={5} />
                {/* <Text as="h4" fontSize="1.4rem" mb="20px" fontWeight="600">Coupon update</Text>  */}
                <form onSubmit={handleSubmit(formSubmit)}>
                    <SimpleGrid columns={[1, 1, 2, 2]} spacing="20px"> 
                        <FormControl id="code" mb="15px" isInvalid={errors.code}>
                            <FormLabel as={'p'} fontWeight="semibold" m={0}>Code name</FormLabel>
                            <Input 
                                ref={register({ required: 'Code is required' })} 
                                variant="flushed" borderColor="gray.300" size="sm" 
                                name="code" type="text" /> 
                            <FormErrorMessage mb="20px"> 
                                { errors?.code?.message } 
                            </FormErrorMessage> 
                        </FormControl> 

                        <FormControl id="" mb="15px" isInvalid={errors.maxUse}> 
                            <FormLabel as={'p'} fontWeight="semibold" m={0}>Maximum Use</FormLabel> 
                            <Input 
                                type="number" name="maxUse" 
                                ref={ 
                                    register({ 
                                        required: 'Maximum use field is required', 
                                        validate: (value) => { 
                                            return Number.isInteger(+value) || 'Accepts only integer number'
                                        } 
                                    }) 
                                } 
                                variant="flushed" borderColor="gray.300" size="sm" 
                            /> 

                            <FormErrorMessage mb="20px"> 
                                { errors?.maxUse?.message} 
                            </FormErrorMessage> 
                        </FormControl> 
                    </SimpleGrid> 


                    <SimpleGrid columns={[1, 1, 2, 2]} spacing="20px"> 
                        <FormControl as="fieldset"> 
                            <Controller 
                                control={control} 
                                name="couponType" 
                                defaultValue="PERCENTAGE" 
                                render={({ onChange, onBlur, value }) => ( 
                                    <RadioGroup defaultValue="PERCENTAGE" mb="20px" id="couponType" onChange={onChange} value={value}>
                                        <FormLabel as={'p'} fontWeight="semibold" mb={3}>Type</FormLabel>
                                        <Stack spacing={5} direction="row">
                                            <Radio colorScheme="teal" borderColor="gray.300" value="PERCENTAGE">
                                                Percentage
                                            </Radio>
                                            <Radio colorScheme="teal" borderColor="gray.300" value="FIXED">
                                                Fixed
                                            </Radio> 
                                        </Stack>
                                    </RadioGroup>
                                )} 
                            /> 
                        </FormControl> 
                        
                        <FormControl id="value" mb="15px" isInvalid={errors?.value}>
                            <FormLabel as={'p'} fontWeight="semibold" m={0}>Value</FormLabel>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    color="gray.500"
                                    fontSize="1.1em"
                                    children={ type === 'FIXED' ? '$' : '' }
                                    w=".9rem"
                                    h="1.9rem"
                                />
                                <Input 
                                    pl="20px"
                                    ref={register({ required: 'Value is required' })} 
                                    variant="flushed" borderColor="gray.300" size="sm" 
                                    name="value" type="number" 
                                />
                                <InputRightElement
                                    pointerEvents="none"
                                    color="gray.500"
                                    fontSize="1em"
                                    children={ type === 'PERCENTAGE' ? '%' : '' }
                                    h="1.9rem"
                                />
                            </InputGroup>
                            <FormErrorMessage mb="20px"> 
                                { errors?.value?.message} 
                            </FormErrorMessage> 
                        </FormControl> 
                    </SimpleGrid> 



                    <SimpleGrid columns={[1, 1, 2, 2]} spacing="20px"> 
                        <FormControl id="activeAt" mb="15px" isInvalid={errors.activeAt}> 
                            <> 
                                <Box 
                                    borderBottom={errors?.activeAt ? '2px solid #E53E3E' : "1px solid #CBD5E0"} 
                                    m={0} p={0}> 
                                    <FormLabel as={'p'} fontWeight="semibold">Active at</FormLabel> 
                                    <Controller 
                                        name="activeAt" 
                                        control={control} 
                                        rules = {{ required: 'Active at is required' }} 
                                        defaultValue={activeAtValue} 
                                        render = {({onChange, value}) => { 
                                            if(value) {
                                                value = typeof value === 'string' ? new Date(value) : value; 
                                            } 
                                            return <DatePicker 
                                                placeholderText="Select date" 
                                                dateFormat="MMMM d, yyyy - h:mm aa" 
                                                selected={value} 
                                                closeOnScroll 
                                                isClearable 
                                                showTimeSelect 
                                                timeIntervals={30} 
                                                minDate={new Date()} 
                                                onChange={onChange} 
                                            /> 
                                        }} 
                                    /> 
                                </Box> 
                                <FormErrorMessage> 
                                    { errors?.activeAt?.message } 
                                </FormErrorMessage> 
                            </> 
                        </FormControl> 

                        <FormControl id="expireAt" mb="15px" isInvalid={errors.expireAt}> 
                            <>
                                <Box 
                                    borderBottom={errors?.expireAt ? '2px solid #E53E3E' : "1px solid #CBD5E0"} 
                                    m={0} p={0}> 
                                    <FormLabel as={'p'} fontWeight="semibold">Expire at</FormLabel> 
                                    <Controller 
                                        name="expireAt" 
                                        control={control} 
                                        rules = {{ required: 'Expire at is required' }} 
                                        defaultValue={expireAtValue} 
                                        render = {({onChange, value}) => { 
                                            if(value) {
                                                value = typeof value === 'string' ? new Date(value) : value; 
                                            } 
                                            return <DatePicker 
                                                placeholderText="Select date" 
                                                dateFormat="MMMM d, yyyy - h:mm aa" 
                                                selected={value} 
                                                closeOnScroll 
                                                isClearable 
                                                showTimeSelect 
                                                timeIntervals={30} 
                                                minDate={minExpireDate || new Date()} 
                                                onChange={onChange} 
                                            /> 
                                        }} 
                                    /> 
                                </Box> 
                                <FormErrorMessage> 
                                    { errors?.expireAt?.message } 
                                </FormErrorMessage> 
                            </> 
                        </FormControl> 
                    </SimpleGrid> 

                    <SimpleGrid columns={[1, 1, 2, 2]} spacing="20px"> 
                        { 
                            role === 'SUPERADMIN' && 
                            <FormControl mb="20px"> 
                                <FormLabel as={'p'} fontWeight="semibold">Franchisee</FormLabel> 
                                <Select 
                                    value={selectedFranchisee} 
                                    options={allFranchisee} 
                                    menuPlacement="top" 
                                    onChange={(v) => { 
                                        setShowErrMsgForFranchisee(false); 
                                        setSelectedFranchisee(v); 
                                    }} 
                                /> 
                                { 
                                    showErrMsgForFranchisee && 
                                    <> 
                                        <Box mt={1}></Box> 
                                        <chakra.span color='#E53E3E' mb="20px" fontSize="0.875rem"> 
                                            Franchisee is required 
                                        </chakra.span> 
                                    </> 
                                } 
                            </FormControl> 
                        } 
                        
                        <FormControl id="code" mb="15px"> 
                            <FormLabel as={'p'} fontWeight="semibold" m={0}>Total Used</FormLabel> 
                            <Input 
                                type="text" name="usedCount" ref={register} readonly
                                variant="flushed" borderColor="gray.300" size="lg" 
                                value={coupon.usedCount} fontSize="0.875rem"
                            /> 
                        </FormControl> 
                    </SimpleGrid> 


                    <FormControl id="code" mb="15px">
                        <FormLabel as={'p'} fontWeight="semibold" mb={3}>Note</FormLabel>
                        <Textarea variant="flushed" name="descriptions" ref={register}  placeholder="Here is a sample placeholder" />   
                    </FormControl>

                    {/* <FormControl as="fieldset">
                        <Controller
                            control={control}
                            name="active"
                            defaultValue={true}
                            render={(
                                { onChange, onBlur, value, name, ref },
                                { invalid, isTouched, isDirty }
                            ) => {
                                return (
                                    <Checkbox 
                                        mb={5}
                                        colorScheme="teal"
                                        onChange={(e) =>  onChange(e.target.checked)}
                                        isChecked={value}
                                    >Active</Checkbox>
                                )
                            }}
                        /> 
                    </FormControl> */} 

                    <Button colorScheme="red" variant="outline" size="sm" mr={3} onClick={onOpen} rounded={4}> 
                        Delete 
                    </Button> 

                    <Button 
                        type="submit" colorScheme="teal" variant="outline" size="sm" rounded={4}
                        onClick={() => {
                            if(!selectedFranchisee && role === 'SUPERADMIN') { 
                                setShowErrMsgForFranchisee(true) 
                            } 
                        }}> 
                        Save 
                    </Button> 

                </form>
            </Box> 

            <DeleteComponent 
                headerName="Coupon"
                isOpen={isOpen} onClose={onClose} 
                getDeleteApi={getDeleteApi} 
                routeOnDelete='/admin/coupon'
                showBackendMsg={false} 
                toastSuccessMsgTitle="Coupon deleted successfully" 
                // toastErrMsgTitle="Cannot delete the shipping method" 
            /> 
        </Container> 
    ) 
} 

export default CouponUpdate
