import { Box, Button, Container, Flex, Link, Spacer } from '@chakra-ui/react';
import axios from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { Link as RouteLink, useRouteMatch } from 'react-router-dom';
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import { config } from '../../config';
import CategoryTable from './CategoryTable';

function CategoryList() {

    const [category, setCategory] = useState([]);
    const baseURL = `${config.baseUrl}/api/v1`;
    const franchiseeId = localStorage.getItem('franchiseeId');
    const token = localStorage.getItem('token');


    let { url } = useRouteMatch();

    const [pageCount, setPageCount] = useState(0)
    const fetchIdRef = useRef(0)

    let columns = [
        {
            Header: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Parent',
            accessor: 'parentId.name'
        }
    ]

    let sortOn = ['Name', 'Parent']


    const fetchData = useCallback(({ pageSize, pageIndex, globalFilter, skipPageResetRef }) => {
        const fetchId = ++fetchIdRef.current

        if (globalFilter) {
            // Filtering on name field
            axios({
                url: `/categories?search=${globalFilter}`,
                method: 'get',
                baseURL: baseURL,
                params: { franchiseeId }
            })
                .then((res) => {
                    const { data: serverData, result: totalLength } = res.data
                    if (fetchId === fetchIdRef.current) {
                        if (serverData.length >= 1) {
                            skipPageResetRef.current = true
                            setCategory(serverData)
                            setPageCount(Math.ceil(totalLength / pageSize))
                        }
                    }
                })
                .catch(err => console.log(err, 'Data fetch error'))
        }
        else {
            // Pagination 

            axios({
                url: `/categories/parent?franchiseeId=${franchiseeId}&sort=name&page=${(pageIndex) + 1}&limit=${pageSize}`,
                method: 'get',
                baseURL: baseURL,
                headers: { 'Authorization': `Bearer ${token}` },
                // params: {franchiseeId}
            })
                .then((res) => {
                    const { data: serverData, result: totalLength } = res.data
                    if (fetchId === fetchIdRef.current) {
                        skipPageResetRef.current = true
                        setCategory(serverData)
                        setPageCount(Math.ceil(totalLength / pageSize))
                    }
                })
                .catch(err => console.log(err, 'Data fetch error'))
        }
    }, [])


    return (
        <Container maxWidth={1100} centerContent py={6}>
            <Box w="100%" bg="white" rounded="lg" boxShadow="lg">
                <Flex pt={4} px={4}>
                    <Breadcrumb pathname="Category" />

                    <Spacer />
                    <Box>
                        <Button colorScheme="teal" variant="outline" size="sm" mr="10px">
                            <Link color="blue.500" lineHeight="32px" as={RouteLink} to={`${url}/hierarchy`}>Hierarchy</Link>
                        </Button>

                        <Button colorScheme="teal" variant="outline" size="sm">
                            <Link color="blue.500" lineHeight="32px" as={RouteLink} to={`${url}/create`}>Create new</Link>
                        </Button>
                    </Box>
                </Flex>



                <CategoryTable
                    data={category}
                    columns={columns}
                    pageCount={pageCount}
                    fetchData={fetchData}
                    sortOn={sortOn}
                    defaultPageSize={20}
                    tableHeightInPage='58vh'
                    selectNoOfRows={[5, 10, 20, 30, 50, 100]}
                />
            </Box>
        </Container>
    )
}

export default CategoryList
