import axios from "axios"
import { config } from '../config'

const baseURL = `${config.baseUrl}/api/v1`;


export const getSubDomain = (host) => {
    try {
        const domains = host?.split('.');
        //const domains = ['sk', 'pc-staging', 'com'];
        const initialDomain = domains.find(
            (element) => element === config.siteURL.split('.')[0]
        );
        if (initialDomain) {
            const subdomain = host?.split('.')[0];
            return subdomain;
        } else {
            return host;
        }
    } catch (error) {
        console.log(error);
    }
};

export const getFranchisee = async (domain) => {
    try {
        const response = await axios({
            url: `/franchisees/store_name/${domain}`,
            method: 'get',
            baseURL: baseURL
        });
        return response.data;

    } catch (error) {
        console.error(error);
        return error.response.data;
    }
}


export const getFranchiseeNew = async (domain) => {
    try {
        const response = await axios({
            url: `/franchisees/store_name/${domain}`,
            method: 'get',
            baseURL: baseURL,
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return error.response.data;
    }
};
