import React, {useState, useEffect} from 'react'; 
import { useForm } from 'react-hook-form'; 
import { 
    Container, Box, Text, Button, Flex, Input, Checkbox, 
    FormControl, FormLabel, InputGroup, InputRightElement, 
    FormErrorMessage, useToast, SimpleGrid, Select
} from '@chakra-ui/react'; 
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'; 
import axios from 'axios'; 
import {config} from  "../../config"; 
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb'; 


function MailConfig() { 
    const { baseUrl } = config; 
    const toast = useToast(); 
    
    const { register, handleSubmit, errors, reset } = useForm({ 
        mode: 'onChange', 
        shouldFocusError: false 
    }) 


    const [passwordVisible, setPasswordVisible] = useState(false); 
    const [ID, setID] = useState(''); 

    useEffect(() => { 
        const token = localStorage.getItem('token') 
        const franchiseeId = localStorage.getItem('franchiseeId') 
        const role = localStorage.getItem('role') 
        let url = ''

        if(!franchiseeId || franchiseeId == null || role === 'SUPERADMIN'){
            url = `/api/v1/mail-settings?adminType=SA&limit=1`
        }
        else{
            url = `/api/v1/mail-settings?adminType=CA&franchiseeId=${franchiseeId}&limit=1`
        }

        axios({ 
            url: url, 
            method: 'get', 
            baseURL: baseUrl, 
            headers: {'Authorization': `Bearer ${token}`} 
        }) 
        .then(res => { 
            reset(res.data.data[0]) 
            setID(res.data.data[0]?._id) 
        }) 
        .catch(err => console.log(err)) 
    }, []) 

    const submitHandler = (data) => { 
        const franchiseeId = localStorage.getItem('franchiseeId') 
        const token = localStorage.getItem('token'); 
        const role = localStorage.getItem('role') 

        let method = 'post'; 
        let url = `/api/v1/mail-settings`; 

        if(!franchiseeId || franchiseeId == null || role === 'SUPERADMIN'){
            data.adminType = 'SA'; 
        }
        else{
            data.adminType = 'CA'; 
            data.franchiseeId = franchiseeId; 
        }
        

        if(ID) { 
            method = 'patch'; 
            url = `/api/v1/mail-settings/${ID}`; 
        } 

        axios({ 
            url: url, 
            method: method, 
            baseURL: baseUrl, 
            headers: {'Authorization': `Bearer ${token}`}, 
            data: data 
        }) 
        .then(res => { 
            if(ID) { 
                toast({ 
                    title: "Mail Configuration Updated", 
                    // description: "You have just updated mail configuration successfully.", 
                    status: "success", 
                    duration: 4000, 
                    isClosable: true, 
                }) 
            } 
            else {
                toast({ 
                    title: "Mail Configuration Created", 
                    status: "success", 
                    duration: 4000, 
                    isClosable: true, 
                }) 
            }

            setID(res.data.data._id); 
        }) 
        .catch(err => console.log(err)) 
    } 

    return ( 
        <Container maxWidth={1100} centerContent my={6}> 
            <Box w="100%" bg="white" rounded="lg" boxShadow="lg" p={4}> 
                <Flex> 
                    <Breadcrumb pathname="Mail Config" /> 
                </Flex> 

                <form onSubmit={handleSubmit(submitHandler)}> 
                    <SimpleGrid 
                        columns={{base: 1, sm: 1, md: 2, lg: 2}} 
                        spacing="15px"> 
                        <FormControl isInvalid={errors.name} mr={4}> 
                            <FormLabel as={'p'} fontWeight="semibold" m={0} mt={4}>Name</FormLabel> 
                            <Input 
                                type="text" name="name" variant="flushed" size="sm" 
                                ref={register({ required: 'Name is required' })}/> 
                            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                        </FormControl> 

                        <FormControl> 
                            <Flex mt={4}> 
                                <FormLabel as={'p'} fontWeight="semibold" m={0}>Active</FormLabel> 
                                <Checkbox 
                                    defaultIsChecked ml={2} 
                                    name="active" 
                                    ref={register} 
                                /> 
                            </Flex> 
                        </FormControl> 
                    </SimpleGrid> 

                    <Text as={'h1'} fontSize="1.1rem" fontWeight="700" mt={6}> 
                        Connection Information 
                    </Text> 
                    <SimpleGrid 
                        columns={{base: 1, sm: 1, md: 2, lg: 2}} 
                        spacing="15px"> 
                        <FormControl isInvalid={errors.smtpHost} mr={4}> 
                            <FormLabel as={'p'} fontWeight="semibold" m={0} mt={4}>SMTP Server</FormLabel> 
                            <Input 
                                type="text" name="smtpHost" variant="flushed" size="sm" 
                                ref={register({ required: 'SMTP Server is required' })}/> 
                            <FormErrorMessage>{errors.smtpHost?.message}</FormErrorMessage>
                        </FormControl> 

                        <FormControl isInvalid={errors.smtpPort} mr={4}> 
                            <FormLabel as={'p'} fontWeight="semibold" m={0} mt={4}>SMTP Port</FormLabel> 
                            <Input 
                                type="text" name="smtpPort" variant="flushed" size="sm" 
                                ref={register({ 
                                    required: 'SMTP Port is required', 
                                    validate: (data) => { 
                                        data = +data; 
                                        return data || 'SMTP port must be number'
                                    }
                                })}/> 
                            <FormErrorMessage>{errors.smtpPort?.message}</FormErrorMessage>
                        </FormControl> 
                    </SimpleGrid> 

                    

                    <Text as={'h1'} fontSize="1.1rem" fontWeight="700" mt={6}> 
                        Security & authentication 
                    </Text> 

                    <FormControl>
                        <FormLabel as={'p'} fontWeight="semibold" mt={4}>Connection Security</FormLabel> 
                        <Select 
                            placeholder="Select" name="smtpEncryption" 
                            ref={register}> 
                            { 
                                ['Auto', 'None', 'SSL', 'TLS', 'STARTTLS'].map((value, i) => { 
                                    return ( 
                                        <option value={value} key={i}> 
                                            {value} 
                                        </option> 
                                    ) 
                                }) 
                            } 
                        </Select> 
                    </FormControl> 
                    
                    
                    <SimpleGrid 
                        columns={{base: 1, sm: 1, md: 2, lg: 2}} 
                        spacing="15px"> 
                        <FormControl isInvalid={errors.smtpUser} mr={4}> 
                            <FormLabel as={'p'} fontWeight="semibold" mt={4}>Email/User</FormLabel> 
                            <Input 
                                borderColor="gray.300" color="gray.500" 
                                type="text" name="smtpUser" id="mailConfigEmail"
                                variant="flushed" size="sm"
                                autoComplete="off"
                                ref={register({ 
                                    required: 'Email/user is required', 
                                    // pattern: { 
                                    //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 
                                    //     message: "Invalid email address" 
                                    // } 
                                })} 
                            /> 
                            <FormErrorMessage mb="20px">{errors.smtpUser?.message}</FormErrorMessage> 
                        </FormControl> 

                        <FormControl isInvalid={errors.smtpPass}> 
                            <FormLabel as={'p'} fontWeight="semibold" mt={4}>Password</FormLabel> 
                            <InputGroup> 
                                <Input 
                                    type={passwordVisible ? 'text' : 'password'} 
                                    borderColor="gray.300" color="gray.500" 
                                    name="smtpPass" id="mailConfigPassword"
                                    variant="flushed" size="sm" 
                                    autoComplete="off"
                                    ref={register({ 
                                        required: 'password is required', 
                                        minLength: { value: 8, message: 'Minimum 8 characters' } 
                                    })} 
                                /> 
                                <InputRightElement cursor="pointer"> 
                                { 
                                    passwordVisible ? 
                                    <ViewOffIcon w={4} h={4} onClick={() => setPasswordVisible(!passwordVisible)} /> 
                                    : 
                                    <ViewIcon w={4} h={4} onClick={() => setPasswordVisible(!passwordVisible)} /> 
                                } 
                                </InputRightElement> 
                            </InputGroup> 
                            <FormErrorMessage mb="10px">{errors.smtpPass?.message}</FormErrorMessage> 
                        </FormControl> 
                    </SimpleGrid> 

                    <Button type="submit" colorScheme="teal" variant="outline" size="sm" mt={6}> 
                        Save 
                    </Button> 
                </form> 
            </Box> 
        </Container> 
    ) 
} 

export default MailConfig;