import React from 'react'
import { Box } from '@chakra-ui/react';
// import DOMPurify from 'dompurify';
import PageEditorBody from '../Page/PageEditorBody';

const Main = (props) => {
    return (
        <>
            {
                props?.pageMain?.length < 15 || !props?.pageMain ?
                    null
                    : 
                    <section id="pageMain" className="lan-container section">
                        <Box p={15}>
                            <PageEditorBody body={props.pageMain}/>
                        </Box>
                    </section>
            }

        </>
    )
}
export default Main;