import React from 'react';
import {SimpleGrid, Flex, Spacer, Box, Skeleton, SkeletonText} from '@chakra-ui/react'

function ShopSkeleton(props) {
    const { header=true, item=3 } = props;
    return (
        <Box mt={5}>
            {
                Array(item).fill().map((_, i) => {
                    return (
                        <Box mb="50px" key={i}>
                            {
                                header &&
                                <Flex  mb={5}>
                                    <Box>
                                        <Skeleton height="20px" width="180px" />
                                    </Box>
                                    <Spacer />
                                    <Box>
                                        <Skeleton height="20px" width="180px" />
                                    </Box>
                                </Flex>
                            }
                            <SimpleGrid minChildWidth="14rem" spacing="40px">
                                <Box>
                                    <Skeleton>
                                        <Box minHeight="180px"></Box>
                                    </Skeleton>
                                    <Skeleton height="20px" mt={4} />
                                    <SkeletonText mt="4" noOfLines={4} spacing="5" />
                                </Box>
                                <Box>
                                    <Skeleton>
                                        <Box minHeight="180px"></Box>
                                    </Skeleton>
                                    <Skeleton height="20px" mt={4} />
                                    <SkeletonText mt="4" noOfLines={4} spacing="5" />
                                </Box>
                                <Box>
                                    <Skeleton>
                                        <Box minHeight="180px"></Box>
                                    </Skeleton>
                                    <Skeleton height="20px" mt={4} />
                                    <SkeletonText mt="4" noOfLines={4} spacing="5" />
                                </Box>
                                <Box>
                                    <Skeleton>
                                        <Box minHeight="180px"></Box>
                                    </Skeleton>
                                    <Skeleton height="20px" mt={4} />
                                    <SkeletonText mt="4" noOfLines={4} spacing="5" />
                                </Box>
                            </SimpleGrid>
                        </Box>

                    )
                })
            }

        </Box>
    )
}

export default ShopSkeleton
