import React, { useEffect, useState } from 'react';
import { Box, Text, FormControl ,Badge, useDisclosure, Flex, Button} from '@chakra-ui/react';
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from 'html-to-draftjs';
import { editorFontList } from '../../service/utils/FontList';
import draftToHtml from 'draftjs-to-html';
import PreviewHTML from '../Blog/helpers/PreviewHTML';

const Main = ({ pageContent, editorState, setEditorState }) => {

    // const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [previewImage, setPreviewImage] = useState('');
    const [rawHTML, setRawHTML] = useState(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {

        const sampleMarkup = pageContent?.pageMain;

        if (sampleMarkup) {
            const contentBlock = htmlToDraft(sampleMarkup);

            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);

            setEditorState(editorState);

        }

    }, []);

    return (
        <Box bg="white" rounded="md" boxShadow="lg" p={{ base: '10px', md: '30px' }} mb="3rem">

            {/* <Text as="h4" fontSize="1.4rem" mb="20px" fontWeight="600">
                Main Section
            </Text> */}
            <Flex my={4} justify="space-between" align='center'>
                <Text as="h4" fontSize="1.4rem" mb="20px" fontWeight="600">
                    Main Section
                </Text>
                <Button bg='gray.300' size="sm" onClick={onOpen}>Preview HTML</Button>
            </Flex>
            
            <FormControl mb="15px" mt="20px">
                <Box fontSize="0.8rem" mb="20px">
                    Press &nbsp;
                    <Badge colorScheme="red">Shift</Badge>
                    {` `} + {` `}
                    <Badge colorScheme="red">Enter</Badge>
                    , to take new line
                </Box>
                <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName rich-editor"
                    onEditorStateChange={(editorState) => setEditorState(editorState)}
                    toolbar={{
                        fontFamily: {
                            options: editorFontList,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                        },
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                        image: {

                        },
                    }}
                />
            </FormControl>

            <PreviewHTML
                isOpen={isOpen}
                onClose={onClose}
                editorState={editorState} setEditorState={setEditorState}
                rawHTML={rawHTML} setRawHTML={setRawHTML}
            />
        </Box>
    )
}

export default Main;