import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../config';
import {
    Box, Button, Container, FormControl, Flex,
    Input, Select, Text, SimpleGrid, useToast,
    FormErrorMessage, RadioGroup, Radio, Stack, Spacer, InputGroup, InputRightAddon, useDisclosure, Modal, ModalOverlay, ModalContent, FormLabel
} from '@chakra-ui/react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { v4 as uuid } from 'uuid';
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import { SketchPicker } from 'react-color';


function FooterNew() {
    let [pages, setPage] = useState([]);
    let [num, setNum] = useState(0);

    const [name, setName] = useState('');
    const [menu, setMenu] = useState([{
        id: uuid(),
        title: "Menu",
        links: []
    }]);

    const [footerStyle, setFooterStyle] = useState({});

    const { isOpen: isOpenFooterStyle, onOpen: onOpenFooterStyle, onClose: onCloseFooterStyle } = useDisclosure()

    const history = useHistory()
    const toast = useToast();

    const { register, handleSubmit, reset, errors } = useForm({
        mode: 'onTouched',
        shouldFocusError: false,
        defaultValues: {
            noOfSection: 1
        }
    });

    useEffect(() => {
        const baseUrl = config.baseUrl;
        const franchiseeId = localStorage.getItem('franchiseeId');
        const pageParams = {
            pageType: 'SAP',
        }
        if (franchiseeId !== 'null') {
            pageParams.pageType = 'CAP';
            pageParams.franchiseeId = franchiseeId;
        }
        pageParams.fields = 'pageName,pageTitle,slug';

        // Get all pages
        axios({
            url: '/api/v1/pages',
            method: 'get',
            baseURL: baseUrl,
            params: pageParams
        })
            .then(res => {

                setPage(res.data?.data);
            })
            .catch(err => console.log(err));

    }, [])


    useEffect(() => {

        const baseUrl = config.baseUrl;
        const franchiseeId = localStorage.getItem('franchiseeId');

        const params = {
            adminType: 'SA'
        }

        if (franchiseeId !== 'null') {
            params.franchiseeId = franchiseeId;
            params.adminType = 'CA'
        }

        // Get footer info 
        delete params.menuType;

        axios({
            url: '/api/v1/footers',
            method: 'get',
            baseURL: baseUrl,
            params: params
        })
            .then(response => {
                if (response.data.data.length > 0) {
                    const data = response.data.data[0];
                    const resetData = {
                        name: data.name,
                        footer: {
                            title: data.footer.title,
                            copyrightText: data.footer.copyrightText
                        },
                        facebook: data.social[0]?.link,
                        linkedin: data.social[1]?.link,
                        twitter: data.social[2]?.link,
                        instagram: data.social[3]?.link,
                        youtube: data.social[4]?.link,
                        pinterest: data.social[5]?.link,
                    }
                    reset(resetData);
                    setNum(data.footer.menu.length);
                    setMenu(data.footer.menu);
                    setName(data.name);
                    setFooterStyle(data?.footer?.style || {})
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [reset])


    const sectionNumberHandler = (e) => {
        let value = e.target.value;
        if (value > num) {
            let diff = value - num;
            let newMenu = [];
            for (let index = 0; index < diff; index++) {
                newMenu.push({ id: uuid(), title: "Menu", links: [] });
            }
            const menuList = [...menu];
            const newMenuList = menuList.concat(newMenu);
            setMenu(newMenuList);
        } else {
            let diff = num - value;
            const menuList = [...menu];
            const length = menuList.length;
            menuList.splice(length - diff, diff);
            setMenu(menuList);
        }
        setNum(e.target.value);
    }

    const menuSectionTitleHandler = (i, e) => {
        let menuList = [...menu];
        menuList[i].title = e.target.value;
        setMenu(menuList);
    }

    const addNewLink = (index) => {
        // const link = menuOptions[0];
        // let menuList = [...menu];
        // if (link) {
        //     menuList[index].links.push(link);
        //     setMenu(menuList);
        // }

        const link = pages[0];
        let menuList = [...menu];
        if (link) {
            menuList[index].links.push({ ...link, linkType: 'INTERNAL' });
            setMenu(menuList);
        }else{
            alert('Please create at least on page to add Footer.');
        }
    }

    const menuOptionsHandler = (i, j, e) => {
        // let menuList = [...menu];
        // menuList[i].links[j] = menuOptions.find(item => item._id === e.target.value);
        // setMenu(menuList);

        let menuList = [...menu];
        const page = pages.find(item => item._id === e.target.value);
        menuList[i].links[j] = { ...page, linkType: 'INTERNAL' }
        setMenu(menuList);
    }

    const linkTypeHandler = (i, j, val) => {
        let link = null;
        switch (val) {
            case 'EXTERNAL':
                link = {
                    linkType: val,
                    name: "",
                    route: "",
                    _id: uuid()
                }
                break;
            case 'INTERNAL':
                link = { ...pages[0], linkType: 'INTERNAL' };
                break;
            default:
                break;
        }
        let menuList = [...menu];
        menuList[i].links[j] = link;
        setMenu(menuList);
    };

    const customLinkInputHandler = (i, j, type, e) => {
        let menuList = [...menu];
        let link = menuList[i].links[j];
        if (type === 'name') {
            menuList[i].links[j] = { ...link, name: e.target.value }
        }
        else {
            menuList[i].links[j] = { ...link, route: e.target.value }
        }
        setMenu(menuList)
    }

    const linkDeleteHandler = (i, j) => {
        let newMenu = [...menu];
        newMenu[i].links.splice(j, 1);
        setMenu(newMenu);
    }

    const formSubmit = (data) => {

        const baseUrl = config.baseUrl;
        const franchiseeId = localStorage.getItem('franchiseeId');

        const { title, copyrightText } = data.footer;
        const social = [
            { label: 'Facebook', link: data.facebook },
            { label: 'Linkedin', link: data.linkedin },
            { label: 'Twitter', link: data.twitter },
            { label: 'Instagram', link: data.instagram },
            { label: 'Youtube', link: data.youtube },
            { label: 'Pinterest', link: data.pinterest }
        ]
        const postData = {
            name: data.name,
            footer: { title, copyrightText, menu: menu, style: footerStyle },
            social
        }

        // API Data Sending
        const token = localStorage.getItem('token');

        postData.adminType = 'SA';

        if (franchiseeId !== 'null') {
            postData.adminType = 'CA';
            postData.franchiseeId = franchiseeId;
        }

        let url = '/api/v1/footers';
        let method = 'post';

        axios({
            url: url,
            method: method,
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` },
            data: postData
        })
            .then(() => {
                toast({
                    title: "Footer section update successfully.",
                    description: "You can find your change while viewing a page",
                    status: "success",
                    duration: 2500,
                    isClosable: true,
                })

                // history.push(`/admin/footer`)
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <Container maxWidth={1100} centerContent py={4} mb={4}>
            <Box w="100%" bg="white" p={3} rounded='lg' boxShadow="xl">

                <form onSubmit={handleSubmit(formSubmit)}>
                    <Box p={[3, 4]} /* bg="#EDF2F9" */ rounded="md" boxShadow="md">
                        <Breadcrumb pathname="Footer" nameOrTitle={name} mb={5} />
                        <Box>
                            <FormControl mb="15px" isInvalid={errors.name}>
                                <FormLabel as={'p'} fontWeight="semibold">Footer Name</FormLabel>
                                <Input
                                    name="name"
                                    ref={
                                        register({
                                            required: 'Footer name is required'
                                        })
                                    }
                                    variant="flushed" size="sm" type="text"
                                    style={{ borderColor: '#a2acb7' }}
                                />
                                <FormErrorMessage mb="20px">{errors.name?.message}</FormErrorMessage>
                            </FormControl>

                            <FormControl id="title" mb="15px">
                                <FormLabel as={'p'} fontWeight="semibold">Title</FormLabel>
                                <Input
                                    name="footer.title" ref={register}
                                    variant="flushed" size="sm" type="text"
                                    style={{ borderColor: '#a2acb7' }}
                                />
                            </FormControl>

                            <FormControl id="copyrightText" mb="25px">
                                <FormLabel as={'p'} fontWeight="semibold">Copyright Text</FormLabel>
                                <Input
                                    name="footer.copyrightText" ref={register}
                                    variant="flushed" size="sm" type="text"
                                    style={{ borderColor: '#a2acb7' }}
                                />
                            </FormControl>

                            {/* <FormControl id="copyrightText" mb="25px"> */}
                            <InputGroup flex="1 1" size="sm" mb="25px" alignItems='center'>
                                <FormLabel as={'p'} fontWeight="semibold">Background Color</FormLabel>
                                <Input
                                    w="155px"
                                    size="sm"
                                    value={footerStyle?.backgroundColor}    // || pageSlider?.style?.backgroundColor
                                    onChange={(e => setFooterStyle({ ...footerStyle, backgroundColor: e.target.value }))}
                                />
                                <InputRightAddon
                                    roundedRight="8px"
                                    cursor="pointer"
                                    bg={footerStyle.backgroundColor}    // || pageSlider?.style?.backgroundColor
                                    onClick={onOpenFooterStyle}
                                />
                            </InputGroup>
                            {/* </FormControl> */}

                        </Box>

                        <Box bg="#EDF2F9" rounded={10} mb={10} p={5}>
                            {/* <Text as="h4" mb="15px" fontWeight="600">Footer section handle</Text> */}
                            <Flex mb={8}>
                                <Text mr="10px" mt={{ base: '0px', md: '10px', lg: '10px' }} fontWeight="600">
                                    Select Number of Section
                                </Text>
                                <Select
                                    width="80%"
                                    borderColor="gray.300"
                                    value={num} onChange={(e) => sectionNumberHandler(e)}>
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </Select>
                            </Flex>


                            <Text as="h4" mb="15px" fontWeight="600">{`Footer ${num > 1 ? 'Sections' : 'Section'}`}</Text>
                            <SimpleGrid spacing="15px" columns={{ base: 1, md: 2, lg: 3 }}>
                                {
                                    menu.map((item, i) => (
                                        <Box key={item.id} p={[3, 3, 4, 4]} bg="white" boxShadow="md" rounded="md">
                                            <Text fontSize="0.9rem" fontWeight="600" mb={2}>
                                                Enter Section Title
                                            </Text>
                                            <Input
                                                type="text"
                                                style={{ borderColor: '#CBD5E0' }}
                                                onChange={(e) => menuSectionTitleHandler(i, e)}
                                                value={item.title}
                                                variant="flushed"
                                            />

                                            <Box mt={3}>
                                                <Flex>
                                                    <Text fontSize="0.9rem" fontWeight="600" mb={1}>
                                                        Links
                                                    </Text>
                                                    <Spacer />
                                                    <Button size="xs" rounded={3} onClick={() => addNewLink(i)}>
                                                        <FaPlus /> <span style={{ marginLeft: "5px" }}>Add new</span>
                                                    </Button>
                                                </Flex>
                                            </Box>

                                            <Box mt={3}>
                                                {item.links?.map((link, j) => {
                                                    return (
                                                        <Box key={`${link._id}-${i}-${j}`} p={2} borderWidth={1} borderColor="gray.300" rounded={4} mb={2} bg="gray.50">
                                                            <Flex>
                                                                <Text fontSize="0.8rem" fontWeight="600" mb={3}>
                                                                    Link type
                                                                </Text>
                                                                <Spacer />

                                                                <FaTrash
                                                                    cursor="pointer"
                                                                    size={12}
                                                                    color="#5e6472"
                                                                    onClick={() => linkDeleteHandler(i, j)}
                                                                />
                                                            </Flex>

                                                            <FormControl mb={2}>
                                                                <RadioGroup size="sm" colorScheme="gray" onChange={(val) => linkTypeHandler(i, j, val)} value={link.linkType}>
                                                                    <Stack direction="row" spacing="10px">
                                                                        <Radio value="INTERNAL" borderColor="gray.400">Internal</Radio>
                                                                        <Radio value="EXTERNAL" borderColor="gray.400">External</Radio>
                                                                    </Stack>
                                                                </RadioGroup>
                                                            </FormControl>

                                                            {
                                                                link.linkType === 'EXTERNAL'
                                                                    ?
                                                                    <FormControl>
                                                                        <Input
                                                                            type="text"
                                                                            mb={2}
                                                                            borderColor="gray.300"
                                                                            variant="flushed"
                                                                            size="sm"
                                                                            placeholder="Link Title"
                                                                            onChange={(e) => customLinkInputHandler(i, j, 'name', e)}
                                                                            value={link.name}
                                                                        />
                                                                        <Input
                                                                            type="text"
                                                                            borderColor="gray.300"
                                                                            variant="flushed"
                                                                            size="sm"
                                                                            placeholder="Link URL"
                                                                            onChange={(e) => customLinkInputHandler(i, j, 'route', e)}
                                                                            value={link.route}
                                                                        />
                                                                    </FormControl>
                                                                    : <Select
                                                                        border='1px solid blue'
                                                                        placeholder="Select option"
                                                                        style={{ borderColor: '#CBD5E0' }}
                                                                        onChange={(e) => menuOptionsHandler(i, j, e)}
                                                                        size="sm"
                                                                        value={link._id}>
                                                                        {
                                                                            pages.map((page) => (
                                                                                <option value={page._id} key={page._id}>
                                                                                    {page.pageName}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                            }
                                                        </Box>
                                                    )
                                                })}
                                            </Box>

                                        </Box>
                                    ))
                                }
                            </SimpleGrid>
                        </Box>

                        {/* Social Links */}
                        <Box>
                            <Text as="h4" mb="5px" fontWeight="600"> Social Links </Text>

                            <SimpleGrid spacing="15px" columns={{ base: 1, md: 2, lg: 3 }}>
                                <Box p={[2, 2, 4, 4]} bg="white" boxShadow="md" rounded="md">
                                    <FormLabel as={'p'} fontWeight="semibold"> Facebook </FormLabel>
                                    <Input type="text" placeholder="URL" name="facebook" ref={register} style={{ borderColor: '#a2acb7' }} variant="flushed" />
                                </Box>
                                <Box p={[2, 2, 4, 4]} bg="white" boxShadow="md" rounded="md">
                                    <FormLabel as={'p'} fontWeight="semibold"> Linkedin </FormLabel>
                                    <Input type="text" placeholder="URL" name="linkedin" ref={register} style={{ borderColor: '#a2acb7' }} variant="flushed" />
                                </Box>
                                <Box p={[2, 2, 4, 4]} bg="white" boxShadow="md" rounded="md">
                                    <FormLabel as={'p'} fontWeight="semibold"> Twitter </FormLabel>
                                    <Input type="text" placeholder="URL" name="twitter" ref={register} style={{ borderColor: '#a2acb7' }} variant="flushed" />
                                </Box>
                                <Box p={[2, 2, 4, 4]} bg="white" boxShadow="md" rounded="md">
                                    <FormLabel as={'p'} fontWeight="semibold"> Instagram </FormLabel>
                                    <Input type="text" placeholder="URL" name="instagram" ref={register} style={{ borderColor: '#a2acb7' }} variant="flushed" />
                                </Box>
                                <Box p={[2, 2, 4, 4]} bg="white" boxShadow="md" rounded="md">
                                    <FormLabel as={'p'} fontWeight="semibold"> Youtube </FormLabel>
                                    <Input type="text" placeholder="URL" name="youtube" ref={register} style={{ borderColor: '#a2acb7' }} variant="flushed" />
                                </Box>
                                <Box p={[2, 2, 4, 4]} bg="white" boxShadow="md" rounded="md">
                                    <FormLabel as={'p'} fontWeight="semibold"> Pinterest </FormLabel>
                                    <Input type="text" placeholder="URL" name="pinterest" ref={register} style={{ borderColor: '#a2acb7' }} variant="flushed" />
                                </Box>
                            </SimpleGrid>
                        </Box>
                    </Box>
                    <Button type="submit" colorScheme="teal" mt={4} variant="outline" boxShadow="xl" size="sm">
                        Save
                    </Button>
                </form>
            </Box>

            <Modal
                isCentered
                onClose={onCloseFooterStyle}
                isOpen={isOpenFooterStyle}
                motionPreset="slideInBottom"
                size="xs">

                <ModalOverlay />
                <ModalContent>
                    <SketchPicker
                        width="100%"
                        color={footerStyle.backgroundColor} // || pageSlider?.style?.backgroundColor
                        onChange={(color) => setFooterStyle({ ...footerStyle, backgroundColor: color.hex })}
                    />
                </ModalContent>
            </Modal>

        </Container>
    )
}

export default FooterNew;