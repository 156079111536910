import axios from "axios"
import {config} from '../config'

const baseUrl = config.baseUrl;

export const getProduct = async (params) => { 
    const franchiseeId = localStorage.getItem('franchiseeId')
    try { 
        const response = await axios.get(`${baseUrl}/api/v1/products/slug/${params.productSlug}/${franchiseeId}`);
        return response.data; 
      } catch (error) { 
        console.error(error);
      }
}

export const getAllProduct = async (params) => {
    try {
      const response = await axios.get(`${baseUrl}/api/v1/products?franchisee.value=${params.franchisee}&categories.value=${params.category}`);
      return response.data; 
    } catch (error) {
      console.error(error);
    }
}

export const getProductByCategory = async (url) => {
  try {
    const response = await axios.get(`${url}`);
    return response.data; 
  } catch (error) {
    console.error(error);
  }
}