
class Auth { 
    constructor() { 
        this.authenticated = false 
    } 

    signin(cb) { 
        this.authenticated = true 
        cb() 
    } 

    signout(cb) { 
        this.authenticated = false 
        cb() 
    } 

    isAuthenticated() { 
        return this.authenticated
    } 
} 


export default new Auth(); 