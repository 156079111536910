import { useState, useEffect } from 'react'
import Carousel from 'react-elastic-carousel'
import styled from 'styled-components';
import {
    Text, Stack, Box, Radio, RadioGroup,
    FormControl, FormLabel, FormErrorMessage, Button, Textarea, useToast,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

const CarousalItem = styled.div`
    width: 400px;
    margin: 10px 10px;
    padding: 10px;
    background-color: #FFFFFF;
`;

const CarousalImage = styled.img`
    width: 100%;
    border-radius: 4px 4px 0 0;
    height: 120px;
    object-fit: contain;
    border: none; 
`;

const GreetingCardSlider = (props) => {

    const { shippingAddressList, onClose, shippingId, greetingCardList, setShippingAddressList } = props;
    const [greetingId, setGreetingId] = useState("")
    const { register, handleSubmit, control, errors, setValue } = useForm({
        mode: 'onChange',
        shouldFocusError: true,
        shouldUnregister: true
    });

    const toast = useToast();


    const breakPoints = [
        { width: 1, itemsToShow: 4 },
        { width: 550, itemsToShow: 5, itemsToScroll: 2, pagination: false },
        { width: 850, itemsToShow: 5 },
        { width: 1150, itemsToShow: 5, itemsToScroll: 2 },
        { width: 1450, itemsToShow: 5 },
        { width: 1750, itemsToShow: 6 }
    ];

    const handleGreeting = (data) => {
        if (shippingId.current && greetingId) {
            const shippingList = shippingAddressList.map(item => {
                if (item.id === shippingId.current) {
                    return { ...item, greetingCard: greetingId, note: data.greetingCard.note }
                }
                return item;
            })
            setShippingAddressList(shippingList);
            shippingId.current = null;
            onClose();
        } else {
            toast({
                description: "No Card is selected!",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
    }

    useEffect(() => {
        const shippingAddess = shippingAddressList.find(item => item.id === shippingId.current);
        if (shippingAddess) {
            setGreetingId(shippingAddess.greetingCard);
            setValue("greetingCard.note", shippingAddess.note);
        }
    }, [])


    return (
        <Box>
            <form onSubmit={handleSubmit(handleGreeting)}>
                <FormLabel htmlFor="greetingCardNote" fontSize="sm">Choose An Optional Greeting Card</FormLabel>
                <RadioGroup onChange={setGreetingId} value={greetingId}>
                    <Carousel breakPoints={breakPoints} showArrows={true} pagination={false}>
                        {
                            greetingCardList.map(card => {
                                const cardPrice = parseFloat(card.price ?? 0).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                                return (
                                    <CarousalItem key={card._id}>
                                        <CarousalImage src={card.image} />
                                        <Radio value={card._id}>
                                            <Text color="gray.700" fontWeight="500" fontSize=".9rem" lineHeight="1rem">{card.name}</Text>
                                        </Radio>
                                        <Text color="blue.500" fontWeight="500" fontSize=".9rem" lineHeight="1rem" ml={5} mt={2} mb={1}>${cardPrice}</Text>
                                    </CarousalItem>
                                )
                            })
                        }
                    </Carousel>
                </RadioGroup>

                <FormControl mb={4} color="gray.600" id="greetingCardNote" isInvalid={errors.greetingCard?.note}>
                    <FormLabel htmlFor="greetingCardNote" fontSize="sm">Write An Optional Note</FormLabel>
                    <Textarea name="greetingCard.note" ref={register({ required: false })} />
                    <FormErrorMessage>
                        {errors.greetingCard?.note && errors.greetingCard?.note?.type === "required" && 'Note is required'}
                    </FormErrorMessage>
                </FormControl>

                <FormLabel htmlFor="greetingCardNote" fontSize="11px" fontWeight={500}>(Include your name in the note so they know who this is from!)</FormLabel>

                <Stack direction="row" spacing={4}>
                    <Button size="sm" colorScheme='black' variant='outline' onClick={() => {
                        onClose();
                        shippingId.current = null;
                    }}>Cancel</Button>
                    <Button size="sm" colorScheme='green' variant='outline' type="submit">Save</Button>
                </Stack>

            </form>
        </Box>
    );
}

export default GreetingCardSlider;
