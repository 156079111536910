import {
    Box,
    Button, Container, FormControl, FormErrorMessage, Input, useDisclosure, useToast
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import DeleteComponent from '../../components/delete/DeleteComponent';
import { config } from '../../config';
import CategoryBreadcrumb from './CategoryBreadcrumb';
import EditSubCategories from './EditSubCategories';


function CategoryDetails() {
    const { id: _id } = useParams();
    const token = localStorage.getItem('token');
    const { register, handleSubmit, errors, setValue } = useForm({
        mode: 'onTouched',
        shouldFocusError: false
    });

    const [categories, setCategory] = useState([]);
    const [name, setName] = useState('')
    const [subCategories, setSubCategory] = useState([]);
    const [mainParentId, setMainParentId] = useState('');
    const franchiseeId = localStorage.getItem('franchiseeId');
    const [clickedLink, setClickedLink] = useState([]);

    const payloadCatNames = useRef([]);

    const baseUrl = config.baseUrl;
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        axios({
            url: `/api/v1/categories/${_id}`,
            method: 'get',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {
                setMainParentId(res.data.data.parentId?._id);

                setName(res.data.data.name)
                setValue('name', res.data.data.name)
                if (res?.data?.data?.parentId) {
                    res.data.data.parentId = {
                        value: res.data.data.parentId?._id,
                        label: res.data.data.parentId?.name,
                    }
                    setValue('parentId', res.data.data.parentId)
                }
            })
            .catch(err => console.log(err))
    }, [_id])

    const optionMapHandler = (options) => {
        let newOptions = [];

        if (options.length) {
            newOptions = options.map(option => {
                let item = {};
                item.value = option._id;
                item.label = option.name;
                return item;
            })
        }

        return newOptions;
    };

    useEffect(() => {

        getSubCategoryList();

        axios({
            url: '/api/v1/categories',
            baseURL: baseUrl,
            method: 'get',
            params: {
                limit: 1000,
                franchiseeId
            }
        })
            .then((response) => {
                let options = optionMapHandler(response.data.data);
                setCategory(options);
            })
            .catch((error) => {
                console.log(error);
            })
    }, [_id])


    const formSubmit = (data) => {

        if (!data.parentId) {
            // data.parentId = undefined; 
            delete data.parentId;
        }
        else {
            data.parentId = data?.parentId?.value;
        }

        if (mainParentId) {
            data.parentId = mainParentId
        }

        axios({
            url: `/api/v1/categories/${_id}`,
            method: 'patch',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` },
            data: data
        })
            .then(res => {
                if (payloadCatNames.current.length > 0) {
                    submitSubCategories();
                };
                toast({
                    title: "Category Updated Successfully.",
                    description: "You have updated a category.",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                })
                getSubCategoryList();
            })
            .catch(err => {
                console.log(err.response);
                if (err.response) {
                    toast({
                        title: "Error!",
                        description: err.response.data.message,
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    })
                }
            })
    }

    const getDeleteApi = () => {
        let url = `/categories/${_id}?adminType=SA`;
        if (franchiseeId !== 'null') {
            url = `/categories/${_id}?adminType=CA&franchiseeId=${franchiseeId}`;
        }
        return url;
    };

    const submitSubCategories = () => {

        const data = {
            franchiseeId: franchiseeId,
            parentId: _id,
            subCategories: payloadCatNames.current,
        };

        axios({
            url: '/api/v1/categories/subcategory',
            method: 'post',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` },
            data: data
        })
            .then(res => {

                toast({
                    title: "Subcategory Created Successfully.",
                    description: "You have created a category.",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                })
                payloadCatNames.current = [];
            })
            .catch(err => {
                console.log(err.response);
                if (err.response) {
                    toast({
                        title: "Error!",
                        description: err.response.data.message,
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    })
                }
            })
    };

    const getSubCategoryList = () => {

        let url = franchiseeId !== 'null'
            ? `/api/v1/categories?parentId=${_id}&sort=name`
            : '/api/v1/categories?parent?sort=name';

        axios({
            url: url,
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` },
            method: 'GET'
        })
            .then((response) => {
                let options = response.data.data;
                setSubCategory(options);
            })
            .catch((error) => {
                console.log(error);
            })

    };


    useEffect(() => {

    }, [subCategories, _id]);


    return (
        <Container maxWidth={1100} centerContent py={6}>
            <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg">

                <CategoryBreadcrumb clickedLink={clickedLink} setClickedLink={setClickedLink} />
                <form>
                    <FormControl id="name" mb="15px" isInvalid={errors.name}>
                        <Input
                            w='50%'
                            placeholder="category name"
                            ref={register({ required: true })}
                            variant="flushed" borderColor="gray.300" size="sm"
                            name="name" type="text" />
                        <FormErrorMessage mb="20px">{errors.name && "Name field is required"}</FormErrorMessage>
                    </FormControl>
                  
                </form>
                <EditSubCategories clickedLink={clickedLink} setClickedLink={setClickedLink} subCategories={subCategories} setSubCategory={setSubCategory}
                    payloadCatNames={payloadCatNames}

                />
                <Box mt={5}>
                    <Button mr={3} onClick={handleSubmit(formSubmit)} colorScheme="teal" variant="outline" size="sm">Save</Button>
                    <Button colorScheme="red" variant="outline" size="sm" onClick={onOpen}>
                        Delete
                    </Button>

                </Box>
            </Box>
            <DeleteComponent
                headerName='Category'
                isOpen={isOpen} onClose={onClose}
                getDeleteApi={getDeleteApi}
                routeOnDelete='/admin/category'
                showBackendMsg={false}
                toastSuccessMsgTitle="Category deleted successfully"
                toastErrMsgTitle="Cannot delete cate" />
        </Container>
    )
}

export default CategoryDetails; 
