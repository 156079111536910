import React, { useState } from 'react'
import { Box, Flex } from "@chakra-ui/react";
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import SideNav from '../components/navigation/Sidenav';
import Header from '../components/header/Header';
import ProtectedRoute from '../Auth/ProtectedRoute';
import LandingPageCustomization from './LandingPage'
import Page from './Page/Page';
import Menus from './Menus/Menu';
import PageList from './Page/PageList';
import BusinessTypeLists from './BussinessType/BusinessTypeList';
import Business from './Business/Business';
import BusinessList from './Business/BusinessList';
import FooterNew from './Footer/FooterNew';
import Franchisees from './Franchisees/Franchisees'
import FranchiseeRegister from './Franchisees/FranchiseeRegister'
import Tag from './Tag/Tag'
import TagCreate from './Tag/Create'
import Blog from './Blog/Blog'
import CreateBlog from './Blog/Create'
import BlogDetails from './Blog/BlogDetails'
import Product from './Product/Product';
import CategoryList from './Category/CategoryList';
import BusinessType from './BussinessType/BusinessType';
import Category from './Category/Category';
import ProductList from './Product/ProductList';
import ProductUpdate from './Product/ProductUpdate';
import PageDetails from './Page/PageDetails';
import TagDetails from './Tag/TagDetails';
import BTDetails from './BussinessType/BTDetails'
import CategoryDetails from './Category/CategoryDetails';
import FranchiseeDetails from './Franchisees/FranchiseeDetails';
import BusinessDetails from './Business/BusinessDetails';
import OrderList from './Order/OrderList';
import Order from './Order/Order';
import GlobalSetting from './GlobalSettings/GlobalSetting';
import Dashboard from './Dashboard';
import Users from './Users/Users';
import CreateUser from './Users/CreateUser';
import CouponList from './Coupon';
import Coupon from './Coupon/Coupon';
import MailConfig from './MailConfig/MailConfig'
import CouponUpdate from './Coupon/CouponUpdate';
import Global from './Franchisees/Global';
import CategoryHirarchy from './Category/CategoryHierarchy';
import GoogleAnalytics from './GoogleAnalytics/GoogleAnalytics';
import ChatConfig from './ChatConfig/ChatConfig';
import Shipping from './ShippingMethod/Shipping';
import ShippingMethodCreate from './ShippingMethod/ShippingMethodCreate';
import UpdateShippingMethod from './ShippingMethod/UpdateShippingMethod';
import Greeting from './GreetingCard/Greeting';
import GreetingDetails from './GreetingCard/GreetingDetails';
import GreetingCreate from './GreetingCard/Create';
import GoogleSearchConsole from './GoogleSearchConsole/GoogleSearchConsole';
import GoogleAdSense from './GoogleAdSense/GoogleAdSense';
import PinterestVerification from './PinterestVerification/PinterestVerification';
import Error404 from '../web/Error404';

import EmailSubscriberList from './EmailSubscriber/EmailSubscriberList';
import PaymentConfig from './PaymentConfig/PaymentConfig';


function DashboardRouter() {
    const [showAdminData, setShowAdminData] = useState(false)
    let [navList, setNavList] = useState(true)

    const toggleShowAdminData = () => setShowAdminData(!showAdminData)

    const handleNavState = () => setNavList(!navList)
    const makeNavStateTrue = () => setNavList(true)
    const makeNavStateFalse = () => setNavList(false)

    let { path, url } = useRouteMatch();

    const role = localStorage.getItem('role');

    return (
        <Flex style={{ height: '100vh' }} onClick={() => showAdminData && toggleShowAdminData()}>
            <SideNav
                navList={navList}
                handleNavState={handleNavState}
                makeNavStateTrue={makeNavStateTrue}
                makeNavStateFalse={makeNavStateFalse}
                url={url}
            />

            <Box bg="#edf2f9" w="100%" style={{ overflowY: 'auto', transition: 'all 0.4s' }}
                onClick={() => {
                    window.innerWidth <= 600 && !navList && handleNavState()
                }}>
                <Header
                    showAdminData={showAdminData}
                    toggleShowAdminData={toggleShowAdminData}
                    handleNavState={handleNavState}
                    navList={navList} />

                <Switch>
                    <Route exact path={path} component={Dashboard} />

                    <ProtectedRoute path={`${path}/business-type/edit/:id`} component={BTDetails} />
                    <Route path={`${path}/business/edit/:id`} component={BusinessDetails} />
                    <Route path={`${path}/page/create`} component={Page} />
                    <Route path={`${path}/blog/create`} component={CreateBlog} />
                    <Route path={`${path}/tag/create`} component={TagCreate} />
                    <Route path={`${path}/business/create`} component={Business} />
                    <ProtectedRoute path={`${path}/business-type/create`} component={BusinessType} />
                    <Route path={`${path}/blog/:id`} component={BlogDetails} />
                    <Route path={`${path}/page/:id`} component={PageDetails} />
                    <Route path={`${path}/tag/:id`} component={TagDetails} />
                    <Route exact path={`${path}/page`} component={PageList} />
                    <Route exact path={`${path}/menu`} component={Menus} />
                    <Route exact path={`${path}/tag`} component={Tag} />
                    <Route exact path={`${path}/blog`} component={Blog} />
                    <Route exact path={`${path}/footer`} component={FooterNew} />
                    <Route path={`${path}/coupon/create`} component={Coupon} />
                    <Route path={`${path}/coupon/edit/:id`} component={CouponUpdate} />
                    <Route exact path={`${path}/mail-config`} component={MailConfig} />
                    <Route exact path={`${path}/payment-config`} component={PaymentConfig} />

                    {/* Access business in CA only */}
                    {/* {role === 'CITYADMIN' &&  />} */}

                    <ProtectedRoute path={`${path}/landing-page`} component={LandingPageCustomization} />
                    <ProtectedRoute path={`${path}/google-analytics`} component={GoogleAnalytics} />
                    <ProtectedRoute path={`${path}/google-search-console`} component={GoogleSearchConsole} />
                    <ProtectedRoute path={`${path}/google-adsense`} component={GoogleAdSense} />
                    <ProtectedRoute path={`${path}/pinterest-verification`} component={PinterestVerification} />
                    <ProtectedRoute path={`${path}/chat-config`} component={ChatConfig} />
                    <ProtectedRoute exact path={`${path}/business-type`} component={BusinessTypeLists} />
                    <Route exact path={`${path}/coupon`} component={CouponList} />



                    {
                        role === 'SUPERADMIN'
                            ? <Switch>
                                <Route path={`${path}/franchisees/edit/:id`} component={FranchiseeDetails} />
                                <Route path={`${path}/franchisees/register`} component={FranchiseeRegister} />
                                <Route exact path={`${path}/franchisees`} component={Franchisees} />
                                {/* <Route exact path={`${path}/mail-config`} component={MailConfig} /> */}
                                <Route exact path={`${path}/users`} component={Users} />
                                <Route exact path={`${path}/users/create`} component={CreateUser} />
                                <ProtectedRoute path={`${path}/global-setting`} component={GlobalSetting} />
                                <Route exact path={`${path}/*`} component={Error404} />
                            </Switch>
                            : <Switch>
                                <Route path={`${path}/shipping-method/edit/:id`} component={UpdateShippingMethod} />
                                <Route path={`${path}/product/edit/:productSlug`} component={ProductUpdate} />
                                <Route path={`${path}/order/edit/:id`} component={Order} />
                                <Route path={`${path}/greeting-card/edit/:id`} component={GreetingDetails} />
                                <Route path={`${path}/greeting-card/create`} component={GreetingCreate} />
                                <Route path={`${path}/shipping-method/create`} component={ShippingMethodCreate} />
                                <Route path={`${path}/category/hierarchy`} component={CategoryHirarchy} />
                                <Route path={`${path}/category/create`} component={Category} />
                                <Route path={`${path}/category/:id`} component={CategoryDetails} />
                                <Route path={`${path}/product/create`} component={Product} />
                                <Route exact path={`${path}/category`} component={CategoryList} />
                                <Route exact path={`${path}/shipping-method`} component={Shipping} />

                                <Route exact path={`${path}/email-subscribers`} component={EmailSubscriberList} />

                                <Route exact path={`${path}/greeting-card`} component={Greeting} />
                                <Route exact path={`${path}/product`} component={ProductList} />
                                <Route exact path={`${path}/order`} component={OrderList} />
                                <Route exact path={`${path}/users`} component={Users} />
                                <Route exact path={`${path}/users/create`} component={CreateUser} />

                                <Route exact path={`${path}/business`} component={BusinessList} />

                                <Route exact path={`${path}/global`} component={Global} />
                                {/* <Route exact path={`${path}/shop-setting`} component={ShopSetup} /> */}
                                <Route exact path={`${path}/*`} component={Error404} />


                            </Switch>
                    }

                    {/* {
                        role === 'CITYADMIN' || role === 'SUPERADMIN' ?
                            <Switch>
                                
                                
                            </Switch>
                            : null
                    } */}
                </Switch>
            </Box>
        </Flex>
    )
}

export default DashboardRouter; 