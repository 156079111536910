import React, {useState, useRef, useCallback} from 'react';
import { useRouteMatch, Link as RouteLink } from 'react-router-dom'
import {
    Container, Box, Flex, Button, Link
} from '@chakra-ui/react'; 
import axios from 'axios' 
import BlogTable from './BlogTable'
import {config} from '../../config'
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb'

function Blog() { 
    const baseURL = `${config.baseUrl}/api/v1`; 
    const  franchiseeId = localStorage.getItem('franchiseeId');

    let { url } = useRouteMatch();

    if(url.endsWith('/')) { 
        url = url.slice(0, -1) 
    } 

    const [posts, setPosts] = useState([]); 

    const [pageCount, setPageCount] = useState(0) 
    const fetchIdRef = useRef(0) 

    const sortOn = ['Title', 'Created At', 'Publish'] 

    let columns = [
        { 
            Header: 'Title', 
            accessor: 'title'
        }, 
        {
            Header: 'Created At', 
            accessor: 'createdAt',
        }, 
        { 
            Header: 'Excerpt', 
            accessor: 'excerpt',
        }, 
        { 
            Header: 'Publish', 
            accessor: 'status'
        }
    ] 


    const fetchData = useCallback(({ pageSize, pageIndex, globalFilter, skipPageResetRef }) => { 
        const fetchId = ++fetchIdRef.current 
        const fields = 'title,excerpt,status,createdAt,-tags';
        let getUrl = franchiseeId !== 'null' 
            ? `/posts?adminType=CA&franchiseeId=${franchiseeId}` 
            : '/posts?adminType=SA'; 
        
        if(globalFilter) { 
          // Filtering on name field 
            axios({ 
                url: `${getUrl}&search=${globalFilter}`, 
                method: 'get', 
                baseURL: baseURL 
            }) 
            .then((res) => {  
                const { data: serverData, result: totalLength } = res.data 
               
                if (fetchId === fetchIdRef.current) { 
                    if(serverData.length >= 1) { 
                        skipPageResetRef.current = true 
                        setPosts(serverData);
                        setPageCount(Math.ceil(totalLength/pageSize)) 
                    } 
                } 
            }) 
            .catch(err => console.log(err, 'Data fetch error')) 
        } 
        else { 
          // Pagination 
            axios({ 
                url: `${getUrl}&page=${(pageIndex) + 1}&limit=${pageSize}&fields=${fields}&sort=-createdAt`, 
                method: 'get', 
                baseURL: baseURL 
            }) 
            .then((res) => { 
                const {data: serverData, result: totalLength} = res.data 
                if (fetchId === fetchIdRef.current) { 
                    skipPageResetRef.current = true 
                    setPosts(serverData) 
                    setPageCount(Math.ceil(totalLength/pageSize)) 
                } 
            }) 
            .catch(err => console.log(err, 'Data fetch error')) 
        } 
    }, []) 

    return ( 
        <Container maxWidth={1100} centerContent my={6}> 
            <Box w="100%" bg="white" rounded="lg" boxShadow="lg"> 
                <Flex justifyContent="space-between" pt={4} px={4}> 
                    <Breadcrumb pathname="Blog" /> 
                    <Button colorScheme="teal" variant="outline" size="sm">
                        <Link color="blue.500" lineHeight="32px" as={RouteLink} to={`${url}/create`}> 
                            Create new 
                        </Link> 
                    </Button> 
                </Flex> 

                <BlogTable 
                    data={posts} 
                    columns={columns} 
                    pageCount={pageCount} 
                    fetchData={fetchData} 
                    sortOn={sortOn} 
                    defaultPageSize={10} 
                    tableHeightInPage='58vh' 
                    selectNoOfRows={[5, 10, 20, 30, 50, 100]} 
                /> 
            </Box> 
        </Container> 
    ) 
} 

export default Blog;