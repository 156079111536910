import React, { useState } from 'react';
import axios from 'axios';
import CreateProductReview from './CreateProductReview';
import ProductReviewFetchData from './ProductReviewFetchData';
import { config } from '../../config';

function ProductComment(props) {
    const baseURL = `${config.baseUrl}/api/v1`;

    const [reviews, setReviews] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const limit = 2;

    const updateReviewHandler = () => {


        axios({
            url: `/productComments?productId=${props.productId}&page=${page}&limit=${limit}`,
            method: 'get',
            baseURL: baseURL
        })
            .then(res => {
                setReviews(res.data?.data)
                setTotal(res.data?.result)
            })
            .catch(err => console.log(err))
    }

    return (
        <>
            <ProductReviewFetchData
                productSlug={props.productSlug}
                productId={props.productId}
                reviews={reviews}
                setReviews={setReviews}
                total={total}
                setTotal={setTotal}
                page={page}
                setPage={setPage}
                limit={limit}
            />

            <CreateProductReview
                productId={props.productId}
                productSlug={props.productSlug}
                updateReviewHandler={updateReviewHandler}
                newReviewCreatedHandler={props.newReviewCreatedHandler}
            />
        </>
    )
}

export default ProductComment; 