import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import GoogleAd from '../Blog/GoogleAd';
import { splitTextByTag } from '../Blog/split-text-by-tag';

const PageBody = styled.div`
  // margin-top: 20px;
  p {
    margin: 10px 0;
    min-height: 10px;
  }
  ul,
  li {
    margin-left: 1.5em;
  }
  ol,
  li {
    margin-left: 1.5em;
  }
  blockquote {
    text-align: justify;
    border-left: 5px solid #dddddd;
    padding: 20px 15px 20px 40px;
    margin: 20px 0;
    background: #edf2f7;
    border-radius: 5px;
    @media (max-width: 500px) {
      padding: 10px 15px 10px 25px;
    }
  }
  img {
    margin: 10px 0;
  }
  pre {
    background: #f1f1f1;
    border-radius: 3px;
    padding: 1px 10px;
    margin: 15px 0;
    padding: 20px;
  }
`;

const PageEditorBody = ({body}) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        if(body){
            const splitBody = splitTextByTag(body,'<ins>');
            setData(splitBody);
        }
    }, [])
    
  return (
    <div>
        {data?.map((cnt)=>
            (
            <>
            { cnt.type == 'html' ? 
            <PageBody
                className="rich-editor"
                dangerouslySetInnerHTML={{ __html: cnt.content }}
            /> : <GoogleAd ins={cnt.content}/>}
            </>
            )
        )}
  </div>
  )
}

export default PageEditorBody