import React, { useEffect, useRef } from 'react'; 
import { Box, Center } from '@chakra-ui/react' 
import axios from 'axios' 
import { config } from '../../../config'; 
import CommentList from './CommentList'; 
import WebPagination from '../../../components/pagination/WebPagination'

function FetchListData(props) { 
    const baseURL = `${config.baseUrl}/api/v1`; 
    
    const setNewPage = (data) => { 
        data.selected = data.selected + 1; 
        props.setPage(data.selected) 
    } 

    useEffect(() => { 
        axios({ 
            url: `/blogComments?postId=${props.postId}&page=1&limit=${props.limit}`, 
            method: 'get', 
            baseURL: baseURL 
        }) 
        .then(res => { 
            props.setComments(res.data?.data) 
            props.setTotalComment2(res.data?.result) 
            props.updateCommentCounter(res.data?.result) 
            props.setPage(1) 
        }) 
        .catch(err => console.log(err)) 
    }, []) 


    const isFirstRun = useRef(true) 
    useEffect(() => { 
        if(isFirstRun.current) { 
            isFirstRun.current = false; 
        } 
        else { 
            axios({ 
                url: `/blogComments?postId=${props.postId}&page=${props.page}&limit=${props.limit}`, 
                method: 'get', 
                baseURL: baseURL 
            }) 
            .then(res => { 
                props.setComments(res.data?.data) 
                props.setTotalComment2(res.data?.result) 
                props.updateCommentCounter(res.data?.result) 
            }) 
            .catch(err => console.log(err)) 
        } 
    }, [props.page]) // data fetch on pagination page change 

    return ( 
        <Box> 
            <CommentList 
                comments={props.comments} 
                total={props.totalComment2} 
                totalComment={props.totalComment} 
                updateCommentCounter={props.updateCommentCounter} 
                // mapDate={props.mapDate}
                getDate={props.getDate}
            /> 
            { 
                props.totalComment2 > props.limit && 
                <Center mt="20px"> 
                    <WebPagination 
                        limit={props.limit} 
                        page={props.page} 
                        total={props.totalComment2} 
                        setNewPage={setNewPage} 
                    /> 
                </Center> 
            } 
        </Box> 
    ) 
} 

export default FetchListData; 