import React from 'react';
import { useRouteMatch } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import './webBlog.css'
import PostData from './PostData';

function BlogListData({ list }) {
    let { url } = useRouteMatch()

    // Details page url-algo generate for blog & tag details 
    url = url.split('/');
    url.shift();
    for (let i = url.length - 1; i >= 0; i--) {
        if (url[i] === 'blog') {
            break;
        }
        url.pop()
    }

    let address = '/'
    for (let i = 0; i < url.length; i++) {
        address = address + url[i] + '/'
    }
    address = address.slice(0, -1)
    // ****   End   **** //


    const mapDate = (date) => {
        let day = new Date(date).getDate()
        day = day > 9 ? day : `0${day}`

        let month = new Date(date).getMonth() + 1 // january gives 0 
        // month = month > 9 ? month : `0${month}` 
        switch (month) {
            case 1: { month = 'January'; break; }
            case 2: { month = 'February'; break; }
            case 3: { month = 'March'; break; }
            case 4: { month = 'April'; break; }
            case 5: { month = 'May'; break; }
            case 6: { month = 'June'; break; }
            case 7: { month = 'July'; break; }
            case 8: { month = 'August'; break; }
            case 9: { month = 'September'; break; }
            case 10: { month = 'October'; break; }
            case 11: { month = 'November'; break; }
            case 12: { month = 'December'; break; }
            default:
                return;
        }

        let year = new Date(date).getFullYear()
        year = year > 9 ? year : `0${year}`

        return { day, month, year }
    }

    return (
        <Box w="100%" mt={["0", "0", "0", "20px"]}>
            {
                list?.map((obj, i) => {
                    const date = mapDate(obj.createdAt)
                    return (
                        <Box mb="40px" key={i} bg="white" p={[4, 4, 6, 8]} rounded="md" boxShadow="md">
                            <PostData
                                obj={obj}
                                address={address}
                                date={date}
                            />
                        </Box>
                    )
                })
            }
        </Box>
    )
}

export default BlogListData; 