import { useForm } from 'react-hook-form'
import {
    Box, Text, Button, SimpleGrid, FormControl,
    FormLabel, Input, Textarea, FormErrorMessage,
    useToast
} from '@chakra-ui/react'
import axios from 'axios'
import { config } from '../../../config';

function CreateBlogComment(props) {
    const baseURL = `${config.baseUrl}/api/v1`;
    const theme = JSON.parse(localStorage.getItem('theme'));
    const franchiseeId = localStorage.getItem('franchiseeId');


    const { register, handleSubmit, errors, reset } = useForm({
        mode: 'onTouched',
        shouldFocusError: false
    })
    const toast = useToast()

    const submitHandler = (data) => {
        data.postId = props?.postId;
        data.commentAt = Date.now();
        data.adminType = 'CA';
        data.franchiseeId = franchiseeId;
        axios({
            url: `/blogComments`,
            method: 'post',
            baseURL: baseURL,
            data: data
        })
            .then((res) => {
                props.commentListHandler()
                reset({ 'authorName': '', 'authorEmail': '', 'message': '' })
                props.updateCommentCounter(props.totalComment + 1);
                toast({
                    title: "You wrote a comment successfully",
                    status: "success",
                    duration: 4000,
                    isClosable: true
                })
            })
            .catch(err => console.log(err))
    }

    return (
        <Box mt="30px" p={6} rounded="md" borderWidth="1px" borderColor="gray.200">
            <Text as="h2" mb="25px" color={theme?.color?.primary}>
                Write a comment
            </Text>

            <form onSubmit={handleSubmit(submitHandler)}>
                <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing={{ base: '10px', md: '5rem' }}>
                    <FormControl mb="15px">
                        <FormLabel as={'p'} fontWeight="semibold" m={0}>Name</FormLabel>
                        <Input
                            ref={register}
                            variant="flushed"
                            borderColor="gray.300" size="sm"
                            name="authorName" type="text" />
                    </FormControl>


                    <FormControl mb="15px" isInvalid={errors.authorEmail}>
                        <FormLabel as={'p'} fontWeight="semibold" m={0}>Email</FormLabel>
                        <Input
                            ref={register({
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address"
                                }
                            })}
                            variant="flushed"
                            borderColor="gray.300" size="sm"
                            name="authorEmail" type="text" />
                        <FormErrorMessage mb="20px">{errors?.authorEmail?.message}</FormErrorMessage>
                    </FormControl>
                </SimpleGrid>

                <FormControl mb="15px" isInvalid={errors?.message}>
                    <FormLabel as={'p'} fontWeight="semibold" m={0}>Your Comment</FormLabel>
                    <Textarea
                        name="message" type="text"
                        ref={register({ required: 'Comment is required!' })}
                        variant="flushed" borderColor="gray.300" size="sm" />
                    <FormErrorMessage mb="20px">{errors?.message?.message}</FormErrorMessage>
                </FormControl>


                <Button type="submit" size="sm"
                    bg="white" color={theme?.color?.accent} border={`1px solid ${theme?.color?.accent}`}
                    _hover={{
                        bg: `rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 0.05'})`
                    }}>
                    Post
                </Button>
            </form>
        </Box>
    )
}

export default CreateBlogComment; 