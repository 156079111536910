import React, { useState } from 'react'
import { Checkbox, Flex, Text, FormLabel, Box } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons'


const Category = ({ data, filterCatHandler, value, theme }) => {
    return (

        <>
            {data?.map((tree) => (
                <SubCategory key={tree.id} child={tree} filterCatHandler={filterCatHandler} value={value} theme={theme} />
            ))}
        </>

    );
};


const SubCategory = ({ child, filterCatHandler, value, theme }) => {

    const [childVisible, setChildVisibility] = useState(true);
    const hasChild = child.child ? true : false;

    return (
        <>
            <Flex m={0} cursor='pointer' onClick={() => setChildVisibility((v) => !v)}>
                <Checkbox
                    id={child.id}
                    mr={2}
                    borderColor={theme?.color?.accent}
                    colorScheme={'gray'}
                    size="sm"
                    value={child.id}
                    defaultChecked={value?.includes(child.id)}
                    onChange={filterCatHandler}
                >
                    <FormLabel htmlFor={child.id} mt='6px' fontSize="12px" fontWeight="400" letterSpacing="1px"
                        textTransform="uppercase">
                        {child.name}
                    </FormLabel>
                </Checkbox>
                <Text ml='auto' color='black'> {hasChild && childVisible ? <ChevronDownIcon display={child?.child?.length > 0 ? 'block' : 'none'} p={0} w={5} h={5} /> : <ChevronRightIcon w={5} h={5} p={0} display={child?.child?.length > 0 ? 'block' : 'none'} />
                }
                </Text>

            </Flex>

            {hasChild && childVisible && (

                <Box pl='15px'>
                    <Category data={child.child} filterCatHandler={filterCatHandler} value={value} theme={theme} />
                </Box>

            )}
        </>
    );
};

function CategoryTree({ filterCatHandler, categories, value }) {
    const theme = JSON.parse(localStorage.getItem('theme'));

    return (
        <Category theme={theme} data={categories} filterCatHandler={filterCatHandler} value={value} />
    )

};

export default CategoryTree;


