import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
    Box, Container, Input, Button, useToast, FormControl,
    FormLabel, FormErrorMessage, SimpleGrid,
    InputRightElement, InputGroup
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import useDomain from '../../service/DomainHook';
import { config } from '../../config';
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import 'react-phone-input-2/lib/style.css';


function CreateUser() {

    const [hasSubDomain] = useDomain();

    const franchiseeId = localStorage.getItem('franchiseeId');
    const token = localStorage.getItem('token');

    const baseURL = `${config.baseUrl}/api/v1`;

    const toast = useToast();
    const history = useHistory();

    const { register, handleSubmit, errors, watch, setError, clearErrors, control } = useForm({
        mode: 'onTouched',
        shouldFocusError: false
    })

    let password = watch('password');
    let confirmPassword = watch('confirmPassword');

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const submitHandler = (data) => {

        let url = '/users/superAdmin';

        if (hasSubDomain) {
            url = '/users/cityAdmin'
            data.franchiseeId = franchiseeId;
        };

        axios({
            url: url,
            method: 'post',
            baseURL: baseURL,
            headers: { 'Authorization': `Bearer ${token}` },
            data
        })
            .then((res) => {

                toast({
                    title: res.data.message,
                    status: "success",
                    duration: 4000,
                    isClosable: true
                })

                history.push(`/admin/users`);
            })
            .catch((err) => {
                toast({
                    title: "Error Occurred.",
                    description: err?.response?.data?.message,
                    status: "error",
                    duration: 4000,
                    isClosable: true
                })
            })
    }


    return (
        <>
            <Container maxWidth={1100} centerContent my={6}>
                <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg">
                    <Breadcrumb pathname="Users" create={true} link={`/admin/users`} mb={5} />

                    <form onSubmit={handleSubmit(submitHandler)}>
                        <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing={{ base: '10px', md: '20px', lg: '20px' }}>
                            <FormControl mb="15px" isInvalid={errors.firstName}>
                                <FormLabel as={'p'} fontWeight="semibold" m={0}>First Name</FormLabel>
                                <Input
                                    ref={register({ required: 'First name is required' })}
                                    variant="flushed"
                                    borderColor="gray.300" size="sm"
                                    name="firstName" type="text" />
                                <FormErrorMessage mb="20px">{errors?.firstName?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl mb="15px">
                                <FormLabel as={'p'} fontWeight="semibold" m={0}>Last name</FormLabel>
                                <Input
                                    ref={register}
                                    variant="flushed"
                                    borderColor="gray.300" size="sm"
                                    name="lastName" type="text" />
                            </FormControl>
                        </SimpleGrid>

                        {/* Email & Phone No. */}
                        <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing={{ base: '10px', md: '20px', lg: '20px' }}>
                            <FormControl mb="15px" isInvalid={errors.email}>
                                <FormLabel as={'p'} fontWeight="semibold" m={0}>Email</FormLabel>
                                <Input
                                    ref={register({
                                        required: 'Email is required',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid email address"
                                        }
                                    })}
                                    variant="flushed"
                                    borderColor="gray.300" size="sm"
                                    name="email" type="text" />
                                <FormErrorMessage mb="20px">{errors?.email?.message}</FormErrorMessage>
                            </FormControl>

                            <FormControl mb="15px">
                                <FormLabel as={'p'} fontWeight="semibold" m={0}>Phone Number</FormLabel>
                                <Controller
                                    name="phone"
                                    control={control}
                                    defaultValue={null}
                                    as={
                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            inputClass='customWidth'
                                        />
                                    }
                                />
                            </FormControl>
                        </SimpleGrid>

                        <SimpleGrid mb="15px" columns={{ base: 1, sm: 1, md: 2 }} spacing={{ base: '10px', md: '20px', lg: '20px' }}>
                            <FormControl isInvalid={errors.password}>
                                <FormLabel as={'p'} fontWeight="semibold" m={0}>Password</FormLabel>
                                <InputGroup>
                                    <Input
                                        type={passwordVisible ? 'text' : 'password'}
                                        ref={register({
                                            required: 'Password is required',
                                            minLength: {
                                                value: 8,
                                                message: 'Minimum 8 characters'
                                            },
                                            validate: (value) => {
                                                if (errors.confirmPassword?.message) {
                                                    if (value === confirmPassword) {
                                                        clearErrors('confirmPassword');
                                                    }
                                                }
                                                else if (value.length >= 8 && confirmPassword.length >= 8) {
                                                    if (value !== confirmPassword) {
                                                        setError('confirmPassword', {
                                                            type: 'unmatched',
                                                            message: "Password doesn't match"
                                                        })
                                                    }
                                                }
                                            }
                                        })}
                                        variant="flushed"
                                        borderColor="gray.300" size="sm"
                                        name="password"
                                    />

                                    <InputRightElement cursor="pointer" position="relative" zIndex="0">
                                        {
                                            passwordVisible ?
                                                <ViewOffIcon w={4} h={4} onClick={() => setPasswordVisible(!passwordVisible)} />
                                                :
                                                <ViewIcon w={4} h={4} onClick={() => setPasswordVisible(!passwordVisible)} />
                                        }
                                    </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={errors.confirmPassword}>
                                <FormLabel as={'p'} fontWeight="semibold" m={0}>Confirm Password</FormLabel>
                                <InputGroup>
                                    <Input
                                        type={confirmPasswordVisible ? 'text' : 'password'}
                                        ref={register({
                                            required: 'Confirm Password is required',
                                            minLength: {
                                                value: 8,
                                                message: 'Minimum 8 characters'
                                            },
                                            validate: (value) => value === password || "Password doesn't match"
                                        })}
                                        variant="flushed"
                                        borderColor="gray.300" size="sm"
                                        name="confirmPassword"
                                    />

                                    <InputRightElement cursor="pointer">
                                        {
                                            confirmPasswordVisible ?
                                                <ViewOffIcon w={4} h={4} onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)} />
                                                :
                                                <ViewIcon w={4} h={4} onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)} />
                                        }
                                    </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>{errors.confirmPassword && errors.confirmPassword.message}</FormErrorMessage>
                            </FormControl>
                        </SimpleGrid>

                        <Button type="submit" colorScheme="teal" variant="outline" size="sm" rounded={4}>
                            Save
                        </Button>
                    </form>
                </Box>
            </Container>
        </>
    )
}

export default CreateUser;
