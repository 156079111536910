import {useState} from 'react';
import {useHistory} from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
    Box, Button, Input, FormControl, 
    FormLabel, Text, FormErrorMessage, SimpleGrid
} from '@chakra-ui/react' 
import axios from 'axios' 
import {config} from  "../config"; 

function ForgotPassword() { 
    const { baseUrl } = config 
    const history = useHistory() 
    const { register, handleSubmit, errors } = useForm({ 
        mode: 'onTouched', 
        shouldFocusError: true 
    }) 

    const [serverResponse, setServerResponse] = useState(false) 

    const submitHandler = (data) => { 
        axios.post(`${baseUrl}/api/v1/users/requestResetPassword`, data) 
        .then(res => { 
            setServerResponse(true) 
        }) 
        .catch(err => console.log('err')) 
    } 

    return ( 
        <SimpleGrid placeItems="center" bg="#edf2f9" h="100vh"> 
            <Box bg="white" boxShadow="xl" rounded="lg" p={10} 
                borderTop="2px solid teal"
                w={{base: '100%', sm:"100%", md: "500px", lg:"500px"}}> 
                { 
                    !serverResponse && 
                    <Box> 
                        <Text mb={5} fontWeight="600"> 
                            Forgot your password? 
                        </Text> 

                        <form onSubmit={handleSubmit(submitHandler)}> 
                            <FormControl isInvalid={errors.email}> 
                                <FormLabel color="black">Email address</FormLabel> 
                                <Input 
                                    color="gray.500" 
                                    type="email" name="email" 
                                    variant="flushed" size="sm" 
                                    borderColor="#A0AEC0" 
                                    ref={register({ 
                                        required: 'Email is required', 
                                        pattern: { 
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 
                                            message: "Invalid email address" 
                                        } 
                                    })} 
                                /> 
                                <FormErrorMessage mb="10px">{errors.email?.message}</FormErrorMessage> 
                            </FormControl> 

                            <Button type="submit" colorScheme="teal" variant="solid" w="100%" mt={4}> 
                                Request Password reset 
                            </Button> 
                        </form> 
                    </Box> 
                } 

                { 
                    serverResponse && 
                    <Box> 
                        <Text fontWeight="600"> 
                            Password reset link has been sent. Please check your email. 
                        </Text> 
                        <Text fontWeight="600" color="teal.500" mt={2}> 
                            Link is valid for next 24 hours only.  
                        </Text> 
                        <Text mt={4}> 
                            Follow the link to get back to your account. 
                        </Text> 

                        <Text 
                            color="teal.500" fontWeight="600" mt={5}
                            cursor="pointer" textAlign="center" 
                            _hover={{textDecoration: 'underline', color: 'teal.600'}} 
                            onClick={() => history.push('/')}> 
                            Back to home page 
                        </Text> 
                    </Box> 
                } 
            </Box> 
        </SimpleGrid> 
    ) 
} 

export default ForgotPassword; 