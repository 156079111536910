import React, { useState } from "react"; 
import { useForm, Controller } from 'react-hook-form';  
import Select  from 'react-select'; 
import { 
    Box, Flex, Text, Tooltip, Icon, Input, 
    useDisclosure, useToast
} from '@chakra-ui/react'; 
import { RiEdit2Line, RiDeleteBin6Line } from 'react-icons/ri'; 
import axios from 'axios'; 
import DeleteConfirmation from './DeleteConfirmation'; 
import { config } from '../../../../config'; 
import './Card.css'



const Card = (props) => { 
    const { item, onCollapse, onExpand, snapshot, treesState, updateState } = props; 
    const baseUrl = `${config?.baseUrl}/api/v1`; 
    const { register, handleSubmit, errors, control, reset, setValue } = useForm({ 
        mode: 'onTouched', 
        shouldFocusError: false 
    }) 
    const { isOpen, onOpen, onClose } = useDisclosure(); 
    const toast = useToast(); 

    let [fieldData, setFieldData] = useState([]); 
    let [defaultData, setDefaultData] = useState({}); 
    let [expandToEdit, setExpandToEdit] = useState(false); 
    let [removableMenuIndex, setRemovableMenuIndex] = useState(null); 

    let isCardOpen = false; 


    const itemRemoveHandler = () => { 
        let trees = treesState; 
        let {items} = treesState[0]; 

        if(!items[removableMenuIndex]?.hasChildren) { 
            for(let i in items) { 
                const item = items[i]; 
                let newItem = {...item}; 
                
                if(newItem?.hasChildren) { 
                    let result = newItem?.children?.includes(removableMenuIndex); 
                    if(result) { 
                        let newChildrens = newItem?.children.filter((value) => value !== removableMenuIndex); 
                        if(newChildrens.length === 0) { 
                            newItem.hasChildren = false; 
                        } 
                        newItem.children = newChildrens; 
                        delete items[removableMenuIndex]; 
                        
                        items[i] = newItem; 
                        trees[0].items = items; 
                        updateState(trees); 
                    } 
                } 
            } 
        } 
        else { 
            toast({ 
                title: "Cannot delete menu which has children", 
                status: "error", 
                duration: 4000, 
                isClosable: true, 
            }); 
        } 
    } 

    const editHandler = (fieldName, value) => { 
        let menu = JSON.parse(JSON.stringify(item)); 
        menu.data[fieldName] = value; 

        let trees = [...treesState]; 
        trees[0].items[menu.id] = menu; 
        updateState(trees); 
    } 

    const inputFieldPropertyValues = (fieldName, placeholder="") => { 
        return { 
            type: 'text', placeholder: placeholder, 
            defaultValue: item?.data[fieldName], size: 'xs', 
            variant: 'flushed', borderColor: 'gray.300', color: 'gray.800', 
            onChange: (e) => editHandler(fieldName, e.target.value) 
        } 
    } 

    const fetchData = () => {
        axios({url: item?.apiUrl, method: 'get', baseURL: baseUrl}) 
        .then(res => { 
            let data = res?.data?.data?.map(obj => { 
                if(item?.data?.id === obj._id) { 
                    let data = { 
                        value: obj?._id, 
                        label: obj[item?.data?.fieldToShowAsLabel] 
                    } 
                    setValue('something', data); 
                } 

                return { 
                    value: obj._id, 
                    label: obj[item?.data?.fieldToShowAsLabel], 
                    fieldToShowAsLabel: item?.data?.fieldToShowAsLabel, 

                    slug: obj?.slug, 
                    contentId: obj?._id, 

                    apiUrl: item?.apiUrl 
                }
            }) 
            setFieldData(data); 
        }) 
        .catch(err => { 
            console.log('Error while fetching data'); 
        }) 
    } 

    const openEditableHandler = (e) => { 
        e.stopPropagation(); 
        item?.data?.name && item?.apiUrl && fetchData(); 
        const menuCard = e.currentTarget.closest('.menu-card-info'); 
        const parentCard = menuCard.parentNode; 
        const currentCard = menuCard.nextSibling; 

        const elements = document.querySelectorAll('.show-menu-editable'); 
        const parentElements = document.querySelectorAll('.border-show'); 

        elements.forEach(element => { 
            if(!element.classList.contains(item.id)) { 
                element.classList.remove('show-menu-editable'); 
            } 
        }); 

        parentElements.forEach(element => { 
            if(!element.classList.contains(item.id)) { 
                element.classList.remove('border-show'); 
            } 
        }); 

        currentCard.classList.toggle('show-menu-editable'); 
        parentCard.classList.toggle('border-show'); 
    } 


    return ( 
        <>
            <Box bg={snapshot.isDragging ? '#f8cd80': 'white'} p={2} m={1} rounded="3px"
                onClick={() => (item?.isExpanded ? onCollapse() : onExpand())} 
                cursor={item?.hasChildren && 'pointer'} className={`${item?.id}`}
            > 
                <Flex className="menu-card-info"> 
                    <> 
                    { 
                        item?.hasChildren && ( 
                            <Box w="25px"> 
                                {item?.isExpanded ? "-" : "+"} 
                            </Box> 
                        ) 
                    } 
                    </> 
                    
                    <Flex w="100%">
                        <Box maxW="55%">
                            <Text fontSize="0.8rem" mt="3px">
                                {item?.data?.name || item?.data?.labelText} 
                            </Text> 
                        </Box> 
                        

                        <Flex ml="auto" w="45%"> 
                            <Box w="100%"> 
                                <Text 
                                    fontSize="0.65rem" mt="5px" textAlign="right"
                                    px={2} color="gray.600"> 
                                    {item?.data?.optionName} 
                                </Text> 
                            </Box> 
                            <Box textAlign="right" ml="auto" w="60px"> 
                                <Tooltip label="Edit"> 
                                    <span> 
                                        <Icon as={RiEdit2Line} w="4" h="4" mt="1px" mr={2} 
                                        cursor="pointer" _hover={{ color: 'teal' }} 
                                        onClick={(e) => { 
                                            isCardOpen = !isCardOpen; 
                                            openEditableHandler(e); 
                                        }} 
                                        /> 
                                    </span> 
                                </Tooltip> 
                                <Tooltip label="Remove"> 
                                    <span> 
                                        <Icon as={RiDeleteBin6Line} w="4" h="4" mt="1px" cursor="pointer" _hover={{ color: 'red.300' }} 
                                            onClick={(e) => { 
                                                e.stopPropagation(); 
                                                setRemovableMenuIndex(item?.id); 
                                                onOpen(); 
                                            }} 
                                        /> 
                                    </span> 
                                </Tooltip> 
                            </Box> 
                        </Flex> 
                    </Flex> 
                </Flex> 
                
                
                <Box mt={6} className={`menu-card-edit ${item?.id}`}> 
                    <Box> 
                        { 
                            'labelText' in item?.data 
                            ? 
                                // Custom Link 
                                <Box> 
                                    <Flex> 
                                        <Text fontSize="0.8rem" fontWeight="500" color="gray.600" mr={2} mt="2px"> 
                                            Label: 
                                        </Text> 
                                        <Input {...inputFieldPropertyValues('labelText')} /> 
                                    </Flex> 
                                    
                                    
                                    <Flex mt={3}> 
                                        <Text fontSize="0.8rem" fontWeight="500" color="gray.600" mr={2} mt="2px"> 
                                            Link: 
                                        </Text> 
                                        <Input {...inputFieldPropertyValues('linkText', 'Link')} /> 
                                    </Flex> 
                                </Box> 
                            : 
                                // System menu OR Select fields 
                                <> 
                                    <Flex> 
                                        <Text fontSize="0.8rem" fontWeight="500" color="gray.600" mr={2} mt="2px"> 
                                            Label: 
                                        </Text> 
                                        <Input {...inputFieldPropertyValues('name')} id="labelField" /> 
                                    </Flex> 


                                    { 
                                        item.type !== 'SYSTEM_MENU' &&
                                        <Flex mt={3}> 
                                            <Text fontSize="0.7rem" fontWeight="500" color="gray.600" mr={2} mt="10px"> 
                                                {/* Content:  */} 
                                                {item?.data?.optionName?.toUpperCase()}:
                                            </Text> 
                                            <Box w="100%" flex="1 1"> 
                                                <Controller 
                                                    control={control} name="something" 
                                                    defaultValue={defaultData} 
                                                    render={( 
                                                        { onChange, onBlur, value, name, ref }, 
                                                        { invalid, isTouched, isDirty } 
                                                    ) => ( 
                                                        <Select 
                                                            menuPortalTarget={document.body} 
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            value={value} 
                                                            options={fieldData} 
                                                            onChange={(value) => { 
                                                                onChange(value); 

                                                                let trees = [...treesState]; 
                                                                trees[0].items[item?.id] = {...trees[0].items[item?.id]}; 
                                                                trees[0].items[item?.id].apiUrl = value?.apiUrl; 

                                                                trees[0].items[item?.id].data.id = value?.value; 
                                                                trees[0].items[item?.id].data.contentId = value?.contentId; 
                                                                trees[0].items[item?.id].data.name = value?.label; 
                                                                trees[0].items[item?.id].data.slug = value?.slug; 

                                                                let menu = JSON.parse(JSON.stringify(item)); 
                                                                menu.data.name = value?.label; 

                                                                document.getElementById("labelField").value = value?.label;
                                                                updateState(trees); 
                                                            }} 
                                                        /> 
                                                    )} 
                                                /> 
                                            </Box> 
                                        </Flex> 
                                    } 
                                </> 
                        } 
                    </Box> 
                </Box> 
            
            </Box> 

            <DeleteConfirmation 
                isOpen={isOpen} 
                onClose={onClose} 
                header="Delete Menu" 
                confirmDelete={itemRemoveHandler} 
                isCentered={true} 
            /> 
        </> 
    ); 
} 

export default Card; 