import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Container,
  Box,
  Flex,
  Button,
  useToast,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
  SimpleGrid,
  Input,
} from '@chakra-ui/react';
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import { config } from '../../config';
import axios from 'axios';

function GoogleAnalytics() {
  const toast = useToast();
  const baseURL = `${config.baseUrl}/api/v1`;

  const { register, handleSubmit, errors, control, reset } = useForm({
    mode: 'onTouched',
    shouldFocusError: false,
  });

  const [hasList, setHasList] = useState(false);
  const [ID, setID] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    const franchiseeId = localStorage.getItem('franchiseeId');

    let api = `/googleAnalytics?adminType=SA&limit=1`;
    if (role === 'CITYADMIN') {
      api = `/googleAnalytics?adminType=CA&franchiseeId=${franchiseeId}&limit=1`;
    }

    axios({
      url: api,
      method: 'get',
      baseURL: baseURL,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {
        console.log(data);
        if (data.data.length > 0) {
          setHasList(true);
          setID(data.data[0]._id);
          reset(data.data[0]);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const submitHandler = (data) => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    const franchiseeId = localStorage.getItem('franchiseeId');
    data.adminType = 'SA';

    let method = 'post',
      api = `/googleAnalytics?adminType=SA`;
    if (hasList) {
      method = 'patch';
      api = `/googleAnalytics/${ID}?adminType=SA`;
    }

    if (role === 'CITYADMIN') {
      data.adminType = 'CA';
      data.franchiseeId = franchiseeId;
      api = `/googleAnalytics?adminType=CA&franchiseeId=${franchiseeId}`;
      if (hasList) {
        method = 'patch';
        api = `/googleAnalytics/${ID}?adminType=CA&franchiseeId=${franchiseeId}`;
      }
    }
    console.log(data);
    axios({
      url: api,
      method: method,
      baseURL: baseURL,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(({ data }) => {
        toast({
          title: `Google-analytics code ${
            hasList ? 'updated' : 'created'
          } successfully.`,
          description: `You have just ${
            hasList ? 'updated' : 'created'
          } Google-analytics code successfully.`,
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        reset(data.data);
        setHasList(true);
        setID(data.data._id);
      })
      .catch((err) => {
        toast({
          title: 'Error Occured',
          description: err.response.data.message,
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      });
  };

  return (
    <Container maxWidth={1100} centerContent mt={6}>
      <Box w="100%" bg="white" rounded="lg" boxShadow="lg" px={4}>
        <Flex py={4}>
          <Breadcrumb pathname="Google Analytics" />
        </Flex>

        <form onSubmit={handleSubmit(submitHandler)}>
          {/* <Flex>  */}
          <SimpleGrid
            columns={{ base: 1, sm: 1, md: 2, lg: 2 }}
            spacing="15px"
            mb={5}>
            <FormControl mb="15px" isInvalid={errors.code}>
              <FormLabel as={'p'} fontWeight="semibold" m={0}>
                Google Analytics 4 Mesurement ID
              </FormLabel>
              <Input
                ref={register({ required: 'Analytics ID is required' })}
                variant="flushed"
                borderColor="gray.300"
                size="sm"
                name="ga4Code"
                type="text"
              />
              <FormErrorMessage mb="20px">
                {errors?.code?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl mb="15px" isInvalid={errors.code}>
              <FormLabel as={'p'} fontWeight="semibold" m={0}>
                Google Tag Manager Code
              </FormLabel>
              <Input
                ref={register({
                  required: 'Google Tag Manager ID is required',
                })}
                variant="flushed"
                borderColor="gray.300"
                size="sm"
                name="gtmCode"
                type="text"
              />
              <FormErrorMessage mb="20px">
                {errors?.gtmCode?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl>
              <Flex>
                <FormLabel as={'p'} fontWeight="semibold" m={0} mr="10px">
                  Google Analytics 4 Active
                </FormLabel>
                <Controller
                  control={control}
                  name="ga4CodeActive"
                  defaultValue={false}
                  render={(props) => (
                    <Checkbox
                      onChange={(e) => props.onChange(e.target.checked)}
                      isChecked={props.value}
                    />
                  )}
                />
              </Flex>
            </FormControl>
            <FormControl>
              <Flex>
                <FormLabel as={'p'} fontWeight="semibold" m={0} mr="10px">
                  Google Tag Manager Active
                </FormLabel>
                <Controller
                  control={control}
                  name="gtmActive"
                  defaultValue={false}
                  render={(props) => (
                    <Checkbox
                      onChange={(e) => props.onChange(e.target.checked)}
                      isChecked={props.value}
                    />
                  )}
                />
              </Flex>
            </FormControl>
          </SimpleGrid>

          <Button
            type="submit"
            colorScheme="teal"
            variant="outline"
            size="sm"
            mb={4}>
            Save
          </Button>
        </form>
      </Box>
    </Container>
  );
}

export default GoogleAnalytics;
