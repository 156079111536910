import { config } from '../../src/config'

function useDomain() {

    let hasSubDomain = false;
    const siteURL = config.siteURL;
    const urlPath = window.location.host.split(siteURL);

    if (urlPath[0] !== '') {
        hasSubDomain = true;
    } 

    return [ hasSubDomain ]; 
    
}

export default useDomain;
