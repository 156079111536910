import React from 'react'; 
import {useHistory} from 'react-router-dom'
import {
    Box, Flex, Text, useDisclosure
} from '@chakra-ui/react'

import { Icon, HamburgerIcon } from '@chakra-ui/icons'
import { FaUserCircle, FaUserAlt } from "react-icons/fa"
import { HiOutlineLogout } from "react-icons/hi"
import Auth from '../../Auth/Auth'
import ChangePasswordeModal from './ChangePasswordModal'

function Header({handleNavState, toggleShowAdminData, showAdminData}) { 
    const history = useHistory(); 
    const { isOpen, onOpen, onClose } = useDisclosure() 

    const logoutHandler = () => { 
        Auth.signout(() => { 
            localStorage.removeItem('token') 
            localStorage.removeItem('role') 
            localStorage.removeItem('franchiseeId') 
            localStorage.removeItem('email') 
            history.push('/');
        }) 
    } 

    return ( 
        <>
            <Box bg="#f8f8f8" h="60px" shadow="md"> 
                <Flex> 
                
                    <Box w="100px">
                        { 
                            window.innerWidth <= 600 && 
                            <HamburgerIcon 
                                w={5} h={10} ml={15} mt="10px"
                                cursor="pointer" 
                                alignSelf="flex-start"
                                onClick={handleNavState} 
                            /> 
                        } 
                    </Box>
                    <Flex pt={3} justifyContent="flex-end" alignItems="center" w="100%"> 
                        <Icon 
                            w={5} h={10} mr={15} 
                            as={FaUserCircle} 
                            cursor="pointer"
                            onClick={() => toggleShowAdminData()}/> 
                    </Flex> 
                </Flex> 
                
                { 
                    showAdminData && 
                    <Box 
                        w="300px" bg="white" 
                        borderTop="3px solid teal" 
                        ml="auto" mr="10px" mt="8px" 
                        rounded="lg" boxShadow="lg" 
                        position="relative" zIndex="2" > 

                        <Flex p={4} flexWrap="wrap"> 
                            <Icon as={FaUserAlt} w={30} h={30} mr="10px" color="teal.500" mt="5px" /> 
                            <Box> 
                                <Text fontSize="0.9rem"> 
                                    { localStorage.getItem('name') } 
                                </Text> 
                                <Text fontSize="0.9rem"> 
                                    { localStorage.getItem('email') } 
                                </Text> 
                            </Box> 
                        </Flex> 
                        
                        <Box w="100%" h="3px" bg="#718096" /> 

                        <Box p={4} mt={2}> 
                            <Flex 
                                cursor="pointer" 
                                _hover={{ color: 'teal.500' }} 
                                onClick={() => { 
                                    toggleShowAdminData() 
                                    onOpen() 
                                }}> 
                                <Icon w={5} h={5} mr="10px" as={FaUserCircle} color="teal.500" /> 
                                <Text fontSize="0.8rem"> 
                                    Change Password 
                                </Text> 
                            </Flex> 
                            <Flex mt={3} onClick={logoutHandler} cursor="pointer" _hover={{ color: 'red.500' }}> 
                                <Icon w={5} h={5} mr="10px" as={HiOutlineLogout} color="red.500" /> 
                                <Text fontSize="0.8rem"> 
                                    Logout 
                                </Text> 
                            </Flex> 
                        </Box> 
                    </Box> 
                } 
            </Box> 

            
            
            <ChangePasswordeModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} logoutHandler={logoutHandler} /> 
        </> 
    ) 
} 

export default Header; 