import { StarIcon } from '@chakra-ui/icons';
import { Box, Button, Checkbox, Container, Flex, FormControl, FormErrorMessage, FormLabel, HStack, Icon, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, SimpleGrid, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea, useDisclosure, useToast } from '@chakra-ui/react';
import axios from 'axios';
import "cropperjs/dist/cropper.css";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { nanoid } from 'nanoid';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import Cropper from "react-cropper";
import { Editor } from 'react-draft-wysiwyg';
import { Controller, useForm } from 'react-hook-form';
import { FaTrash, FaTrashAlt, FaUserAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import DeleteComponent from '../../components/delete/DeleteComponent';
import { config } from '../../config';
import useDomain from '../../service/DomainHook';
import ReactSelectOptionMapper from '../../service/ReactSelect';
import { editorFontList } from '../../service/utils/FontList';
import { generateSlug } from '../../service/utils/helper';
import { getDate } from '../../web/Blog/BlogDetails';
import AddTag from '../Blog/helpers/AddTag';
import dataURItoBlob from '../Blog/helpers/dataURLtoBlob';
import PreviewHTML from '../Blog/helpers/PreviewHTML';
import ProductImageModal from './ProductImageModal';

function BusinessDetails() {
    const { id: _id } = useParams()
    const token = localStorage.getItem('token');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
    const { control, register, handleSubmit, errors, reset, getValues, watch, setValue } = useForm({
        mode: 'onTouched',
        shouldFocusError: false,
        shouldUnregister: true
    });

    const [hasSubDomain] = useDomain();

    const [previewImage, setPreviewImage] = useState('');
    const [isFeatured, setIsFeatured] = useState(false);
    const [active, setIsActive] = useState(false);
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState({});
    const [productComments, setProductComments] = useState([]);
    const [image, setImage] = useState(previewImage);
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();
    const [isCropped, setIsCropped] = useState(false);
    const [hidePreview, setHidePreview] = useState(false);
    const [rawImage, setRawImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [cropping, setCropping] = useState(false);

    const [businessEditorState, setBusinessEditorState] = useState(() => EditorState.createWithContent(
        ContentState.createFromBlockArray(
            convertFromHTML('')
        )
    ))
    const [businessEditorRawHTML, setBusinessEditorRawHTML] = useState(draftToHtml(convertToRaw(businessEditorState.getCurrentContent())));
    const { isOpen: isBusinessEditorOpen, onOpen: onBusinessEditorOpen, onClose: onBusinessEditorClose } = useDisclosure();
    const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure();



    // Product Edit
    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedId, setSelectedId] = useState({
        _id: '',
        productId: ''
    });
    const [toggleComments, setToggleComments] = useState(false);
    const [tags, setTag] = useState([]);
    const [images, setImages] = useState([]);
    const [defaultIndex, setDefaultIndex] = useState(1);
    const [files, setFiles] = useState([]);
    const { isOpen: isOpenTag, onOpen: onOpenTag, onClose: onCloseTag } = useDisclosure();
    const [productEditorState, setProductEditorState] = useState(() => EditorState.createWithContent(
        ContentState.createFromBlockArray(
            convertFromHTML('')
        )
    ))
    const [productEditorRawHTML, setProductEditorRawHTML] = useState(draftToHtml(convertToRaw(productEditorState.getCurrentContent())));
    const { isOpen: isProductEditorOpen, onOpen: onProductEditorOpen, onClose: onProductEditorClose } = useDisclosure();
    const watchPriceType = watch("priceType", product?.priceType);
    const watchSelectedCategory = watch("parentcategory", product?.parentcategory);
    const franchiseeId = localStorage.getItem('franchiseeId');


    const baseUrl = config.baseUrl;

    const toast = useToast();

    function imageHandler(e) {
        // To preview before upload --- Converting to base 64
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setPreviewImage(reader.result);
                setImage(reader.result);
                setHidePreview(true);
                setRawImage(e.target.files[0]);

            }
        }

        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0])
        }
    }

    useEffect(() => {
        axios({
            url: `/api/v1/business/${_id}`,
            method: 'get',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {
                const businessData = res.data.data;

                if (businessData?.products?.length == 0) {
                    var data = new FormData();
                    data.append('name', 'Test Product');
                    data.append('slug', 'test-product');
                    data.append('summary', 'Test Summary');
                    data.append('parentCategory', '6272dbbebb232010880f1652');
                    data.append('price', '0.00');
                    data.append('priceType', 'FIXED');
                    data.append('franchiseeId', franchiseeId);
                    data.append('businessId', businessData?._id);

                    axios({
                        url: `/api/v1/products`,
                        baseURL: baseUrl,
                        method: 'post',
                        data: data,
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then(function (response) {
                            console.log(JSON.stringify(response.data));
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                }




                let formData = {
                    "isFeatured": businessData?.isFeatured,
                    "active": businessData?.active,
                    "_id": businessData?._id,
                    "typeIds": businessData?.typeIds,
                    "name": businessData?.name,
                    "email": businessData?.email,
                    "phone": businessData?.phone,
                    "address": businessData?.address,
                    "franchiseeId": businessData?.franchiseeId,
                    "image": businessData?.image,
                    "location": businessData?.location,
                    "details": businessData?.details,
                    pName: businessData?.products[0]?.name || '',
                    slug: businessData?.products[0]?.slug || '',
                    publish: businessData?.products[0]?.publish || false,
                    summary: businessData?.products[0]?.summary || '',
                    pDetails: businessData?.products[0]?.details || '',
                    priceType: businessData?.products[0]?.priceType,
                    price: businessData?.products[0]?.price || '',
                    minPrice: businessData?.products[0]?.minPrice || '',
                    maxPrice: businessData?.products[0]?.maxPrice || '',
                    businessId: businessData?.products[0]?.businessId || '',
                    pageSource: businessData?.products[0]?.pageSource || '',
                    images: businessData?.products[0]?.images,
                    parentCategory: businessData?.products[0]?.parentCategory || '',
                    tags: businessData?.products[0]?.tags || [],
                    categories: businessData?.products[0]?.categories || [],
                    // "_id": "622664adb292e36470854471",
                    // "viewCount": 20,

                }

                // Product Category & Tag
                const categoryData = ReactSelectOptionMapper(businessData?.products[0]?.categories || []);
                const tagData = ReactSelectOptionMapper(businessData?.products[0]?.tags || []);

                formData.categories = categoryData
                formData.tags = tagData

                // const productData = { ...product, categories: categoryData, tags: tagData }
                // convert html to raw editor data
                const sampleMarkup = businessData?.products[0]?.details || '';
                const productContentBlock = htmlToDraft(sampleMarkup);

                if (productContentBlock) {
                    const productContentState = ContentState.createFromBlockArray(productContentBlock.contentBlocks);
                    const productEditorState = EditorState.createWithContent(productContentState);
                    setProductEditorState(productEditorState);
                };
                setImages(businessData?.products[0]?.images?.links);
                setDefaultIndex(businessData?.products[0]?.images?.defaultImage || '');
                setSelectedCategory(businessData?.products[0]?.parentCategory)
                // setValue('slug', product?.slug)

                reset(formData);
                setPreviewImage(businessData?.image);
                setIsFeatured(businessData?.isFeatured);
                setIsActive(businessData?.active);
                setProducts(businessData.products);
                setProduct(businessData.products[0])

                const contentBlock = htmlToDraft(businessData?.details);

                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    setBusinessEditorState(editorState);
                };

                // setValue('pName', product?.name)
                // setValue('slug', product?.slug)
                // setValue('summary', product?.summary)
                // setValue('price', product?.price)
            })
            .catch(err => console.log(err))



        getTagList();
        getCategoryList();
    }, [])

    const fetchBusiness = useCallback(() => {
        axios({
            url: `/api/v1/business/${_id}`,
            method: 'get',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {
                const businessData = res.data.data;

                setProducts(businessData.products);
                setProduct(businessData.products[0])
                // setValue('pName', product?.name)
                // setValue('slug', product?.slug)
                // setValue('summary', product?.summary)
                // setValue('price', product?.price)
            })
            .catch(err => console.log(err))
    }, [_id])

    const getCropData = () => {

        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas().toDataURL());
            setPreviewImage(cropData);
            setIsCropped(true);
        };

        setCropping(true);

        const formData = new FormData();
        formData.append('image', dataURItoBlob(cropper.getCroppedCanvas().toDataURL()));

        axios({
            url: `/api/v1/business/${_id}`,
            method: 'post',
            baseURL: baseUrl,
            data: formData,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({ data }) => {
                toast({
                    title: "Image updated.",
                    description: "Image updated successfully",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                })
            })
            .catch(err => {
                toast({
                    title: "Image not updated",
                    description: JSON.stringify(err),
                    status: "fail",
                    duration: 4000,
                    isClosable: true,
                })
            }).finally(() => {
                setCropping(false);
            })
    };

    useEffect(() => {
        setPreviewImage(cropData);
    }, [cropData]);


    const deleteImageHandler = () => {
        axios({
            url: `/api/v1/business/${_id}`,
            method: 'put',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` },

        }).then((response) => {

            setPreviewImage('');
            setImage('')

            toast({
                title: response.data.message,
                status: "info",
                duration: 4000,
                isClosable: true,
            })
        }).catch((error) => {
            toast({
                title: error.message,
                status: "error",
                duration: 4000,
                isClosable: true,
            })
        })
    };

    const updateImage = () => {

        const formData = new FormData()
        formData.append('image', rawImage);

        if (!isCropped) {
            axios({
                url: `/api/v1/business/${_id}`,
                method: 'post',
                baseURL: baseUrl,
                data: formData,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(({ data }) => {

                })
                .catch(err => {

                })

        }

        return null;

    };

    const imageJSX = (
        <FormControl mb="15px" mt="15px">
            <Box>
                <FormLabel
                    w='100%'
                    h='100%'
                    lineHeight="60px"
                    border="1px dashed #BDBDBD"
                    htmlFor='businessImage' textAlign="center" cursor="pointer"
                    // color="gray.500"
                    p='10px'
                >
                    Click to upload Image
                </FormLabel>
                <Input
                    type="file"
                    name="businessImage"
                    ref={register}
                    id='businessImage'
                    accept="image/*"
                    onChange={(e) => imageHandler(e)}
                    style={{ display: "none" }} />

            </Box>
            <Flex justify="space-between" align='flex-start'>

                <Flex flexDirection='column' w="48%">
                    <Box>
                        {image ? <Cropper
                            style={{ height: 350, width: "100%" }}
                            zoomTo={0.0}
                            initialAspectRatio={1}
                            src={image}
                            aspectRatio={1}
                            viewMode={1}
                            preview=".img-preview-business"
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            cropBoxResizable={false}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                            guides={true}
                        />
                            : null}
                    </Box>
                    <Box alignSelf='flex-end' mt='5px' mr='25px'>
                        {image ? <Button
                            isLoading={cropping}
                            loadingText='Cropping & Uploading...'
                            _hover={{ outline: 'none' }}
                            _focus={{ outline: 'none' }}
                            _active={{ bg: 'transparent', color: 'teal' }}
                            colorScheme="teal" variant="outline" size="sm"
                            onClick={getCropData}>
                            Crop Image
                        </Button> : null}
                    </Box>

                </Flex>
                {image && <Box w="48%" display='inline-block' position='relative' p='10px' boxSizing="border-box" visibility={isCropped ? 'visible' : 'hidden'}>
                    <Text >Preview</Text>
                    <Box className="img-preview-business" overflow="hidden" w='300px' height='300px'>

                    </Box>
                    <Button
                        onClick={deleteImageHandler}
                        position="absolute"
                        top="5px"
                        right="-10"
                        size="xs"
                        borderRadius={0}
                        _hover={{ outline: 'none' }}
                        _focus={{ outline: 'none' }}
                        _active={{ bg: 'red.400', color: 'white' }}>
                        <FaTrash />
                    </Button>
                </Box>}

            </Flex>
            <Box>
                {!hidePreview && previewImage ? <Box position="relative" display="inline-block">
                    <Image mt='5px' src={previewImage} alt="Business image" maxH="200px" />
                    <Button
                        onClick={deleteImageHandler}
                        position="absolute"
                        top="5px"
                        right="0px"
                        size="xs"
                        borderRadius={0}
                        _hover={{ outline: 'none' }}
                        _focus={{ outline: 'none' }}
                        _active={{ bg: 'red.400', color: 'white' }}>
                        <FaTrash />
                    </Button>
                </Box> : null}
            </Box>
        </FormControl>
    )


    const formSubmit = (data) => {

        if (rawImage && !isCropped) {
            toast({
                title: "Business Image Must Be Cropped",
                description: 'Crop Your Business Image',
                status: "error",
                duration: 4000,
                isClosable: true
            })
            return;
        };

        setLoading(true);

        const franchiseeId = localStorage.getItem('franchiseeId');
        const token = localStorage.getItem('token');


        // Business Data
        const businessDetails = draftToHtml(convertToRaw(businessEditorState.getCurrentContent()));
        let businessData = data

        businessData.franchiseeId = franchiseeId;
        businessData.details = businessDetails;


        // const productDataUpdate = null
        const businessDataUpdate = axios({
            url: `/api/v1/business/${_id}`,
            method: 'patch',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` },
            data: businessData
        })
        // .then((res) => {
        //     console.log("axios business Data Update", res);



        //     // history.push(`/admin/business`);
        // })
        // .catch(error => {

        // })

        // Product Data
        const pDetails = draftToHtml(convertToRaw(productEditorState.getCurrentContent()));
        let productData = data

        productData.name = data.pName
        productData.categories = data.categories?.map(type => type.value);
        productData.parentCategory = selectedCategory
        productData.tags = data.tags?.map(type => type.value);
        productData.details = pDetails;
        productData.images = {
            defaultImage: defaultIndex,
            links: images
        }

        const productDataUpdate = axios({
            url: `/api/v1/products/${product._id}`,
            method: 'patch',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` },
            data: productData
        })
        // .then((res) => {
        //     // toast({
        //     //     title: "Product Updated Successfully.",
        //     //     status: "success",
        //     //     duration: 4000,
        //     //     isClosable: true,
        //     // })
        //     fetchBusiness();
        //     console.log("axios product Data Update", res);
        // })
        // .catch(error => {
        //     console.log(error)
        // }).finally(() => {
        //     // setLoading(false);
        // })




        let apiArray = null;

        if (rawImage && isCropped) {
            apiArray = [businessDataUpdate, productDataUpdate, updateImage()]
        } else {
            apiArray = [businessDataUpdate, productDataUpdate]
        }

        axios.all(apiArray).then(response => {
            console.log("axios all response", response);
            if (response[0].status == 200 && response[1].status == 200) {
                toast({
                    title: "Business & Product Updated Successfully.",
                    description: "You have just updated the business and product",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            }
            else if (response[0].status == 200 && response[1].status != 200) {
                toast({
                    title: "Business Updated Successfully.",
                    description: "You have just updated the business",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
                toast({
                    title: "Product Updated Failed.",
                    description: "Failed",
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
            }
            else if (response[0].status != 200 && response[1].status == 200) {
                toast({
                    title: "Business Updated Failed.",
                    description: "Failed",
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
                toast({
                    title: "Product Updated Success.",
                    description: "You have just updated the business",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            }
            else {
                toast({
                    title: "Business & Product Updated Failed.",
                    description: "Failed the business and product update",
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
            }
            fetchBusiness();
        }).catch((err) => {
            toast({
                title: "Business & Product Updated Failed.",
                description: err.message,
                status: "error",
                duration: 4000,
                isClosable: true
            })
        }).finally(() => {
            setLoading(false);
        })

    }

    const getDeleteApi = () => {
        let url = `/business/${_id}`;
        return url;
    };

    const deleteImage = (index, e) => {
        e.stopPropagation();

        const newImages = [...images];
        const deleteImage = newImages[index];
        newImages.splice(index, 1);
        setImages(newImages);
        if (newImages.length < defaultIndex) {
            setDefaultIndex(1);
        }

        const token = localStorage.getItem('token');
        axios({
            url: `/api/v1/products/removeImage/${product?._id}`,
            method: 'patch',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` },
            data: { link: deleteImage }
        })
            .then(res => {
                toast({
                    title: "Image has been removed",
                    status: "success",
                    duration: 3000,
                    isClosable: true
                })
            })
            .catch(err => console.log(err))

    }


    // const deleteProduct = () => {
    //     const token = localStorage.getItem('token');
    //     axios({
    //         url: `/api/v1/products/${product._id}`,
    //         method: 'DELETE',
    //         baseURL: baseUrl,
    //         headers: { 'Authorization': `Bearer ${token}` },
    //     })
    //         .then(() => {
    //             toast({
    //                 title: "Product Deleted Successfully.",
    //                 status: "success",
    //                 duration: 4000,
    //                 isClosable: true,
    //             })
    //             onClose();
    //             fetchProduct();
    //         })
    //         .catch(error => {
    //             console.log(error)
    //         })
    // }


    const getTagList = useCallback(() => {

        let url = franchiseeId !== 'null'
            ? `/api/v1/tags?adminType=CA&franchiseeId=${franchiseeId}&sort=name`
            : '/api/v1/tags?adminType=SA&sort=name';

        axios({
            url: url,
            method: 'GET',
            baseURL: baseUrl,
        })
            .then(({ data }) => {
                let tagList = ReactSelectOptionMapper(data.data);
                setTag(tagList);

            })
            .catch(err => console.log(err))

    }, []);

    const getCategoryList = useCallback(() => {

        let url = franchiseeId !== 'null'
            ? `/api/v1/categories/parent?franchiseeId=${franchiseeId}&sort=name`
            : '/api/v1/categories/parent?sort=name';

        axios({
            url: url,
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` },
            method: 'GET'
        })
            .then((response) => {
                let options = ReactSelectOptionMapper(response.data.data);
                setCategory(options);
            })
            .catch((error) => {
                console.log(error);
            })

    }, []);

    useEffect(() => {
        const selectedParentCategory = category.find((cat) => cat.value === product?.parentCategory);

        if (selectedParentCategory) {
            setValue('parentcategory', selectedParentCategory || '');
        }

    }, [category, product])

    useEffect(() => {
        setSelectedCategory(watchSelectedCategory?.value)
    }, [watchSelectedCategory]);

    const getSubCategoryList = () => {

        if (selectedCategory) {
            let url = franchiseeId !== 'null'
                ? `/api/v1/categories?parentId=${selectedCategory}&sort=name`
                : '/api/v1/categories?parent?sort=name';

            axios({
                url: url,
                baseURL: baseUrl,
                headers: { 'Authorization': `Bearer ${token}` },
                method: 'GET'
            })
                .then((response) => {
                    let options = ReactSelectOptionMapper(response.data.data);
                    setSubCategory(options);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };

    useEffect(() => {
        getSubCategoryList();
    }, [selectedCategory]);

    // upload image onload
    useEffect(() => {
        if (files.length > 0) {
            const formData = new FormData();
            files.forEach(file => {
                formData.append('images', file);
            })

            const token = localStorage.getItem('token');
            axios({
                url: `/api/v1/products/${product?._id}`,
                method: 'post',
                baseURL: baseUrl,
                headers: { 'Authorization': `Bearer ${token}` },
                data: formData
            })
                .then(response => {
                    setImages(response?.data?.data?.images?.links);
                    toast({
                        title: "Product images uploaded successfully",
                        status: "success",
                        duration: 5000,
                        isClosable: true
                    })
                    setFiles([]);
                })
                .catch(error => console.log(error));
        }
    }, [files])

    const onProductNameChange = async (e) => {
        let getSlug = await generateSlug(e.target.value)

        setValue('slug', getSlug)
    }

    const getProductComments = async (e) => {

        let url = `/api/v1/productComments?productId=${product?.id}`

        axios({
            url: url,
            method: 'get',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` }
        }).then((res) => {
            setProductComments(res.data.data)
        })
    }

    useLayoutEffect(() => {
        getProductComments();
    }, [product?.id, toggleComments])


    const deleteProductComment = () => {
        let url = `/api/v1/productComments?adminType=SA&commentId=${selectedId._id}&productId=${selectedId.productId}`;
        if (hasSubDomain) {
            url = `/api/v1/productComments?adminType=CA&franchiseeId=${franchiseeId}&commentId=${selectedId._id}&productId=${selectedId.productId}`;
        }
        axios({
            url: url,
            method: 'delete',
            baseURL: baseUrl,
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then((res) => {

                toast({
                    title: res.data.message,
                    status: "success",
                    duration: 4000,
                    isClosable: true
                })
                setToggleComments((s) => !s);
            })
            .catch((err) => {
                toast({
                    title: err?.response?.data?.message,
                    status: "error",
                    duration: 4000,
                    isClosable: true
                })
            })
    };


    return (
        <Container maxWidth={1100} centerContent py={6}>

            <Tabs>
                <TabList>
                    <Tab>Business Detail</Tab>
                    <Tab>Product Comments</Tab>

                </TabList>

                <TabPanels>
                    <TabPanel>
                        <form onSubmit={handleSubmit(formSubmit)}>
                            <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg" mb={4}>
                                <Breadcrumb pathname="Business" nameOrTitle={getValues('name')} link={`/admin/business`} />
                            </Box>
                            <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg" mb={6}>
                                <Text as="h1" mb="15px">Business Information</Text>


                                {/* Business Admin setup */}
                                <FormControl id="name" mb="20px" isInvalid={errors?.name}>
                                    <FormLabel as={'p'} fontWeight="semibold">Name<span style={{ color: 'red' }}>*</span></FormLabel>
                                    <Input
                                        name="name" type="text"
                                        ref={register({ required: 'Name is required' })}
                                        borderColor="gray.300" variant="flushed" size="sm"
                                    />
                                    <FormErrorMessage>
                                        {errors?.name?.message}
                                    </FormErrorMessage>
                                </FormControl>

                                {/* email & phone */}
                                <SimpleGrid columns={[1, 1, 2, 2]} spacing="20px">
                                    <Box>
                                        <FormControl id="email" mb="20px" isInvalid={errors?.email}>
                                            <FormLabel as={'p'} fontWeight="semibold">Email<span style={{ color: 'red' }}>*</span></FormLabel>
                                            <Input
                                                name="email" type="email" borderColor="gray.300"
                                                ref={register({ required: 'Email is required' })}
                                                variant="flushed" size="sm" />
                                            <FormErrorMessage>
                                                {errors.email?.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                    </Box>

                                    <Box>
                                        <FormControl id="phone" mb="20px" isInvalid={errors?.phone}>
                                            <FormLabel as={'p'} fontWeight="semibold">Phone</FormLabel>
                                            <Input
                                                borderColor="gray.300" ref={register}
                                                name="phone" type="tel" variant="flushed" size="sm"
                                            />
                                        </FormControl>
                                    </Box>
                                </SimpleGrid>

                                <SimpleGrid columns={[1, 1, 2, 2]} spacing="20px">
                                    <Box> {imageJSX} </Box>
                                    <FormControl id="location" mt='6px' mb="20px" isInvalid={errors?.location}>
                                        <FormLabel as={'p'} fontWeight="semibold">Location ( Embeded Google Map )</FormLabel>
                                        <Input
                                            name="location" type="text"
                                            ref={register({ required: false })}
                                            borderColor="gray.300" variant="flushed" size="sm"
                                        />
                                        <FormErrorMessage>
                                            {errors?.location?.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                </SimpleGrid>

                                <Box>
                                    <FormControl id="address" mb="20px">
                                        <FormLabel as={'p'} fontWeight="semibold">Address</FormLabel>
                                        <Textarea borderColor="gray.300"
                                            ref={register}
                                            name="address"
                                            type="text"
                                            variant="flushed" size="sm"
                                        />
                                    </FormControl>
                                </Box>

                                <Box>
                                    <FormControl mb={4}>
                                        {/* <FormLabel m={0}>Description</FormLabel> */}
                                        <Flex m={0} justify="space-between" align='center'>
                                            <FormLabel as={'p'} fontWeight="semibold">Description</FormLabel>
                                            <Button bg='gray.300' size="sm" mb="10px" onClick={onBusinessEditorOpen}>Preview HTML</Button>
                                        </Flex>
                                        <Editor
                                            editorState={businessEditorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName rich-editor"
                                            onEditorStateChange={(editorState) => setBusinessEditorState(editorState)}
                                            toolbar={{
                                                fontFamily: {
                                                    options: editorFontList,
                                                    className: undefined,
                                                    component: undefined,
                                                    dropdownClassName: undefined,
                                                },
                                                options: ['inline', 'blockType', 'fontSize', 'fontFamily',
                                                    'list', 'textAlign', 'colorPicker', 'link', 'embedded',
                                                    'emoji', 'remove', 'history'],
                                                inline: { inDropdown: true },
                                                list: { inDropdown: true },
                                                textAlign: { inDropdown: true },
                                                link: { inDropdown: true },
                                                history: { inDropdown: true }
                                            }}
                                        />
                                    </FormControl>
                                </Box>


                                <Flex mb="20px" w='20%' gridGap={2}>

                                    <FormControl id='isFeatured'>
                                        <Checkbox
                                            onChange={(e) => setIsFeatured(e.target.checked)}
                                            ref={register}
                                            name="isFeatured"
                                            isChecked={isFeatured}
                                            colorScheme='green' as={'p'} fontWeight="semibold">
                                            Feature

                                        </Checkbox>
                                    </FormControl>

                                    <FormControl id='active'>
                                        <Checkbox
                                            onChange={(e) => setIsActive(e.target.checked)}
                                            ref={register}
                                            name="active"
                                            isChecked={active}
                                            colorScheme='green' as={'p'} fontWeight="semibold">
                                            Active

                                        </Checkbox>
                                    </FormControl>

                                </Flex>

                                {/* <Button colorScheme="red" variant="outline" size="sm" mr={3} onClick={onOpen} rounded={4}>
                        Delete
                    </Button>

                    <Button
                        isLoading={loading}
                        loadingText='Saving...'
                        type="submit" colorScheme="teal"
                        variant="outline" size="sm"
                    >
                        Save
                    </Button> */}
                                {/* </form> */}
                            </Box>

                            <PreviewHTML
                                isOpen={isBusinessEditorOpen}
                                onClose={onBusinessEditorClose}
                                editorState={businessEditorState} setEditorState={setBusinessEditorState}
                                rawHTML={businessEditorRawHTML} setRawHTML={setBusinessEditorRawHTML}
                            />

                            {/* Delete Page */}
                            <DeleteComponent
                                headerName="Business"
                                isOpen={isDeleteModalOpen} onClose={onDeleteModalClose}
                                getDeleteApi={getDeleteApi}
                                routeOnDelete='/admin/business'
                                showBackendMsg={false}
                                toastSuccessMsgTitle="Business deleted successfully"
                                bodyObject={{
                                    image: previewImage,
                                    products: products
                                }}
                            // toastErrMsgTitle="Cannot delete the shipping method" 
                            />

                            {/* <BusinessProductList products={products} fetchProduct={fetchBusiness} businessId={_id} /> */}



                            {/* Product Edit */}

                            <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg">
                                <Box display="flex" justifyContent="space-between">
                                    <Text as={'h1'}>Product Information </Text>
                                </Box>

                                <>
                                    {/* <BusinessProductEdit product={products[0]} fetchProduct={fetchBusiness} />  */}
                                    <Box>
                                        {/* <form onSubmit={handleSubmit(productSubmitForm)}> */}

                                        <FormControl id="name" mb={4} isInvalid={errors?.name}>
                                            <FormLabel as={'p'} fontWeight="semibold">Name<span style={{ color: 'red' }}>*</span></FormLabel>
                                            <Input
                                                borderColor="gray.300"
                                                type="text"
                                                ref={register({ required: "Product name is required" })}
                                                name="pName"
                                                variant="flushed" size="sm"
                                                onChange={e => onProductNameChange(e)}
                                            />
                                            <FormErrorMessage>
                                                {errors?.pName?.message}
                                            </FormErrorMessage>
                                        </FormControl>

                                        <FormControl id="slug" mb="15px" isInvalid={errors.slug}>
                                            <FormLabel as={'p'} fontWeight="semibold" m={0}>Product slug</FormLabel>
                                            <Input ref={register} variant="flushed" size="sm" type="text" name="slug" />
                                            <FormErrorMessage mb="20px">{errors.slug && errors.slug.message}</FormErrorMessage>
                                        </FormControl>

                                        <FormControl id="summary" mb={4} isInvalid={errors?.summary}>
                                            <FormLabel as={'p'} fontWeight="semibold">Short Description<span style={{ color: 'red' }}>*</span></FormLabel>
                                            <Textarea
                                                borderColor="gray.300"
                                                ref={register({ required: "Description is required" })}
                                                name="summary"
                                                variant="flushed" size="sm"
                                            />
                                            <FormErrorMessage>
                                                {errors?.summary?.message}
                                            </FormErrorMessage>
                                        </FormControl>

                                        <FormControl mb={4}>
                                            <FormLabel as={'p'} fontWeight="semibold">Upload Product Images</FormLabel>
                                            <Box>
                                                <Button
                                                    my={2}
                                                    color="gray.600"
                                                    size="sm"
                                                    _hover={{ outline: 'none' }}
                                                    _focus={{ outline: 'none' }}
                                                    _active={{ bg: 'transparent', color: 'teal' }} onClick={onOpen}>Add Product Image</Button>
                                                <ProductImageModal key={nanoid()} files={files} setFiles={setFiles} onClose={onClose} isOpen={isOpen} isEdit={true} />

                                                {images?.length > 0 && <Box w='100%' h='1px' bg='gray.300'></Box>}


                                                <Flex flexWrap="wrap" mt={2} mb={8} gridGap={2}>
                                                    {
                                                        images?.map((image, index) => (
                                                            <Box key={image} rounded="8px" p={1}
                                                                maxWidth="150px"
                                                                minWidth="120px"
                                                                borderWidth={index + 1 === defaultIndex ? "2px" : "0"}
                                                                borderColor="green.600"
                                                                cursor="pointer"
                                                                onClick={() => setDefaultIndex(index + 1)}
                                                                position="relative"
                                                            >
                                                                <Image
                                                                    src={image}
                                                                    width="100%" height="100%"
                                                                    objectFit="cover"
                                                                    rounded="8px"
                                                                />
                                                                {
                                                                    index + 1 === defaultIndex
                                                                        ? <Text fontSize="14px" textAlign="center" lineHeight="16px" mt="5px">Default</Text>
                                                                        : null
                                                                }

                                                                <Button size="xs" position="absolute" top="0px" right="0px" onClick={(e) => deleteImage(index, e)}>
                                                                    <FaTrashAlt />
                                                                </Button>

                                                            </Box>
                                                        ))
                                                    }
                                                </Flex>


                                            </Box>
                                        </FormControl>

                                        <FormControl mb={4}>
                                            {/* <FormLabel m={0}>Description</FormLabel> */}
                                            <Flex m={0} justify="space-between" align='center'>
                                                <FormLabel as={'p'} fontWeight="semibold">Description</FormLabel>
                                                <Button bg='gray.300' size="sm" mb="10px" onClick={onProductEditorOpen}>Preview HTML</Button>
                                            </Flex>
                                            <Editor
                                                editorState={productEditorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName rich-editor"
                                                onEditorStateChange={(editorState) => setProductEditorState(editorState)}
                                                toolbar={{
                                                    fontFamily: {
                                                        options: editorFontList,
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                    },
                                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily',
                                                        'list', 'textAlign', 'colorPicker', 'link', 'embedded',
                                                        'emoji', 'remove', 'history'],
                                                    inline: { inDropdown: true },
                                                    list: { inDropdown: true },
                                                    textAlign: { inDropdown: true },
                                                    link: { inDropdown: true },
                                                    history: { inDropdown: true }
                                                }}
                                            />
                                        </FormControl>

                                        <SimpleGrid minChildWidth="120px" spacing="40px">
                                            <Box mb={4}>
                                                <FormControl id="parentcategory" isInvalid={errors?.parentcategory}>
                                                    <FormLabel as={'p'} fontWeight="semibold">Category<span style={{ color: 'red' }}>*</span></FormLabel>
                                                    <Controller
                                                        control={control}
                                                        name="parentcategory"
                                                        as={Select}
                                                        // isMulti
                                                        options={category}
                                                        defaultValue={null}
                                                        // rules={{ required: "Category is required" }}
                                                        rules={{ required: false }}
                                                    />
                                                    <FormErrorMessage>
                                                        {errors?.categories?.message}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            </Box>
                                            <Box mb={4}>
                                                <FormControl id="categories" isInvalid={errors?.categories}>
                                                    <FormLabel as={'p'} fontWeight="semibold">Sub Category</FormLabel>
                                                    <Controller
                                                        control={control}
                                                        name="categories"
                                                        as={Select}
                                                        isMulti
                                                        options={subCategory}
                                                        defaultValue={null}
                                                        rules={{ required: false }}
                                                    />
                                                    <FormErrorMessage>
                                                        {errors?.categories?.message}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            </Box>
                                            <Box mb={4}>
                                                <FormControl id="tags" isInvalid={errors?.tags}>
                                                    <HStack mb={1}>
                                                        <FormLabel as={'p'} fontWeight="semibold">Tags</FormLabel>
                                                        <Button

                                                            px='8px'
                                                            lineHeight="16px"
                                                            fontWeight='300'
                                                            rounded='sm'
                                                            fontSize='10px'
                                                            _focus={{
                                                                outline: 'none'
                                                            }}
                                                            colorScheme="gray"
                                                            size="xs"
                                                            onClick={onOpenTag}>Add New</Button>

                                                    </HStack>
                                                    <Controller
                                                        control={control}
                                                        name="tags"
                                                        as={Select}
                                                        isMulti
                                                        options={tags}
                                                        defaultValue={null}
                                                        rules={{ required: "Tags is required" }}
                                                    />
                                                    <FormErrorMessage>
                                                        {errors?.tags?.message}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            </Box>
                                        </SimpleGrid>

                                        <Box mb={4}>
                                            <FormControl as="fieldset">
                                                <Controller
                                                    name="priceType"
                                                    control={control}
                                                    defaultValue="FIXED"
                                                    render={({ value, onChange }) => (
                                                        <RadioGroup mb="20px" value={value} onChange={(value) => onChange(value)}>
                                                            <Stack spacing={5} direction="row">
                                                                <FormLabel as={'p'} fontWeight="semibold">Price Type</FormLabel>
                                                                <Radio borderColor="gray.300" value="FIXED">
                                                                    Fixed
                                                                </Radio>
                                                                <Radio borderColor="gray.300" value="RANGED">
                                                                    Range
                                                                </Radio>
                                                            </Stack>
                                                        </RadioGroup>
                                                    )}
                                                />
                                            </FormControl>
                                            {
                                                watchPriceType === 'RANGED'
                                                    ?
                                                    <SimpleGrid minChildWidth="18rem" spacing={{ base: "5px", sm: "10px", md: "15px", lg: "30px", xl: "30px" }}>
                                                        <Box>
                                                            <Stack direction={["column", "row"]}>
                                                                <FormLabel
                                                                    as={'p'} fontWeight="semibold"
                                                                    w={{ base: "100%", sm: "100%", md: "40%", lg: "30%", xl: "30%" }}
                                                                >
                                                                    Min price
                                                                </FormLabel>
                                                                <FormControl w={{ base: "100%", sm: "100%", md: "60%", lg: "70%", xl: "70%" }} id="minPrice" isInvalid={errors?.minPrice}>
                                                                    <Input type="text" ref={register({ required: "Min price is required" })} name="minPrice" variant="flushed" size="sm" borderColor="gray.300" />
                                                                    <FormErrorMessage>
                                                                        {errors?.minPrice?.message}
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </Stack>
                                                        </Box>
                                                        <Box>
                                                            <Stack direction={["column", "row"]}>
                                                                <FormLabel
                                                                    as={'p'}
                                                                    fontWeight="semibold"
                                                                    w={{ base: "100%", sm: "100%", md: "40%", lg: "30%", xl: "30%" }}
                                                                >
                                                                    Max price
                                                                </FormLabel>
                                                                <FormControl w={{ base: "100%", sm: "100%", md: "60%", lg: "70%", xl: "70%" }} id="maxPrice" isInvalid={errors?.maxPrice}>
                                                                    <Input type="text" ref={register({ required: "Max price is required" })} name="maxPrice" variant="flushed" size="sm" borderColor="gray.300" />
                                                                    <FormErrorMessage>
                                                                        {errors?.maxPrice?.message}
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </Stack>
                                                        </Box>
                                                    </SimpleGrid>
                                                    : <Box>
                                                        <Stack direction={["column", "row"]}>
                                                            <FormLabel as={'p'} fontWeight="semibold" w={{ base: "100%", sm: "100%", md: "25%", lg: "20%", xl: "20%" }}>Price<span style={{ color: 'red' }}>*</span></FormLabel>
                                                            <FormControl w={{ base: "100%", sm: "100%", md: "75%", lg: "80%", xl: "80%" }} id="price" isInvalid={errors?.price}>
                                                                <Input type="text" ref={register({ required: "Price is required" })} name="price" variant="flushed" size="sm" borderColor="gray.300" />
                                                                <FormErrorMessage>
                                                                    {errors?.price?.message}
                                                                </FormErrorMessage>
                                                            </FormControl>
                                                        </Stack>
                                                    </Box>
                                            }
                                        </Box>

                                        <Button colorScheme="red" variant="outline" size="sm" mr={3} onClick={onDeleteModalOpen} rounded={4}>
                                            Delete
                                        </Button>

                                        <Button
                                            type="submit" colorScheme="teal"
                                            variant="outline" size="sm"
                                            isLoading={loading}
                                            loadingText='Saving...'
                                        >
                                            Save
                                        </Button>

                                    </Box>
                                </>


                                <AddTag
                                    getTagList={getTagList}
                                    getValues={getValues}
                                    setValue={setValue}
                                    isOpen={isOpenTag} onClose={onCloseTag}
                                />

                                <PreviewHTML
                                    isOpen={isProductEditorOpen}
                                    onClose={onProductEditorClose}
                                    editorState={productEditorState} setEditorState={setProductEditorState}
                                    rawHTML={productEditorRawHTML} setRawHTML={setProductEditorRawHTML}
                                />
                            </Box>
                        </form>

                    </TabPanel>
                    <TabPanel>


                        <Box maxW={1100} minW={500} rounded="md" boxShadow='md' bg="white" p={4}>
                            <Stack spacing={3} w='100%'>
                                {productComments.length === 0 ? <Text>No comments found</Text> : null}
                                {productComments?.map((product, i) => (

                                    <Box
                                        width={'100%'}
                                        key={productComments.id} mt="10px" p={6} rounded="md" boxShadow="sm"
                                        borderWidth="1px" borderColor="gray.200">
                                        <Flex justifyContent='space-between' alignItems={'center'}>
                                            <Flex>
                                                <Icon as={FaUserAlt} color="gray.700" />
                                                <Text
                                                    fontWeight="700" mb="2px" ml="10px"
                                                    fontSize="14px" color="gray.700" >
                                                    {product?.authorName}
                                                </Text>


                                            </Flex>
                                            <FaTrashAlt style={{
                                                cursor: 'pointer'
                                            }}

                                                onClick={() => {
                                                    onOpenDelete();
                                                    setSelectedId({
                                                        _id: product._id,
                                                        productId: product.productId
                                                    })
                                                }}
                                            />
                                        </Flex>
                                        <Text fontSize="13px" color="gray.400" fontStyle="italic" mb="15px">
                                            {/* Created at: {`${date.day}-${date.month}-${date.year}`} */}
                                            Created at: {getDate(product?.commentAt)}
                                        </Text>
                                        <Text fontSize="14px" mt="5px" color="gray.600">
                                            {product?.message}
                                        </Text>
                                        <Flex alignItems={'center'} gap={2}>
                                            <Text>Rating</Text>
                                            {Array.from(Array(product.rating).keys()).map(() => (
                                                <StarIcon color='#4A90E2' />

                                            ))}
                                        </Flex>
                                    </Box>

                                ))}
                            </Stack>

                        </Box>

                    </TabPanel>


                </TabPanels>
            </Tabs>
            <Modal isOpen={isOpenDelete} onClose={onCloseDelete} isCentered>
                <ModalOverlay />
                <ModalContent m={5}>
                    <ModalHeader >Delete Product Comment?</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Are you sure, you want to delete?
                    </ModalBody>

                    <ModalFooter>
                        <Button size="sm" mr={3} colorScheme="teal" variant="outline" onClick={onCloseDelete}>
                            No
                        </Button>

                        <Button size="sm" colorScheme="red" variant="outline"
                            onClick={() => {
                                deleteProductComment();
                                onCloseDelete();
                            }}>Yes</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Container>
    )
}

export default BusinessDetails; 