import React from 'react'
import { Box, FormControl, FormLabel, Textarea, Text } from '@chakra-ui/react';

const MetaData = ({ register }) => {
    return (
        <Box bg="white" rounded="md" boxShadow="lg" p={{ base: '10px', md: '30px' }} mb="3rem">
            <Text as="h4" fontSize="1.4rem" mb="20px" fontWeight="600">
                Meta Data Section
            </Text>
            <FormControl mb="15px">
                <FormLabel as={'p'} fontWeight="semibold" m={0}>Meta Description</FormLabel>
                <Textarea
                    name="meta.description"
                    ref={register()}
                    variant="flushed" size="sm" type="text"
                />
            </FormControl>
            <FormControl>
                <FormLabel as={'p'} fontWeight="semibold" m={0}>Meta Keywords</FormLabel>
                <Textarea
                    name="meta.keywords"
                    ref={register()}
                    variant="flushed" size="sm" type="text"
                />
            </FormControl>

        </Box>
    )
}

export default MetaData;