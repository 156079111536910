import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
   Container, Box, Flex, Button, useToast, Checkbox, Input, Text,
   SimpleGrid, FormControl, FormLabel, FormErrorMessage, Select,
   Badge, HStack, Stack, useRadioGroup
} from '@chakra-ui/react';
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import { config } from '../../config';
import axios from 'axios';
import ManyChatRadioCard from './ManyChatRadioCard';

function ChatConfig() {
   const toast = useToast();
   const baseURL = `${config.baseUrl}/api/v1`;

   const [hasList, setHasList] = useState(false);
   const [ID, setID] = useState('');
   const [manychatOptionValue, setManychatOptionValue] = useState('FACEBOOKMESSENGER');
   const [manyChatDetails, setManyChatDetails] = useState({
      FACEBOOKMESSENGER: { serviceType: 'FACEBOOKMESSENGER', serviceCode: '' },
      // INSTAGRAM: { serviceType:'INSTAGRAM', serviceCode: '' },
      WHATSAPP: { serviceType: 'WHATSAPP', serviceCode: '' },
      TELEGRAM: { serviceType: 'TELEGRAM', serviceCode: '' }
   });

   const [chatType, setChatType] = useState('twakto');
   const [active, setActive] = useState(false);

   const { register, handleSubmit, errors, control, reset } = useForm({
      mode: 'onTouched',
      shouldFocusError: false,
      shouldUnregister: true
   })

   const manychatOptions = ['FACEBOOKMESSENGER', 'WHATSAPP', 'TELEGRAM']
   const { getRootProps, getRadioProps } = useRadioGroup({
      name: 'manychatChannel',
      defaultValue: 'facebook',
      value: manychatOptionValue,
      onChange: (value) => {
         setManychatOptionValue(value)
      }
   });
   const manychatRadioGroup = getRootProps()


   const manyChatValueHandler = (e, key) => {
      const manyChatDetailsCopy = { ...manyChatDetails }
      manyChatDetailsCopy[key].serviceCode = e.target.value;
      setManyChatDetails(manyChatDetailsCopy);
   }


   useEffect(() => {
      const token = localStorage.getItem('token')
      const role = localStorage.getItem('role')
      const franchiseeId = localStorage.getItem('franchiseeId')

      let api = role === 'CITYADMIN'
         ? `/chatConfig?adminType=CA&franchiseeId=${franchiseeId}&limit=1`
         : `/chatConfig?adminType=SA&limit=1`

      axios({
         url: api,
         method: 'get',
         baseURL: baseURL,
         headers: { 'Authorization': `Bearer ${token}` }
      })
         .then(({ data }) => {
            if (data.data.length > 0) {
               const { manyChatDetails: manyChatList, chatType, active } = data.data[0];
               const manyChatDetailsCopy = { ...manyChatDetails };
               manyChatList.forEach(channel => {
                  manyChatDetailsCopy[channel.serviceType].serviceCode = channel.serviceCode
               });

               setChatType(chatType);
               setManyChatDetails(manyChatDetailsCopy);
               setHasList(true);
               setActive(active);
               setID(data.data[0]._id)
               if (chatType === 'twakto') {
                  reset(data.data[0]);
               }
            }
         })
         .catch(err => console.log(err))
   }, [])

   const submitHandler = (data) => {
      const token = localStorage.getItem('token');
      const role = localStorage.getItem('role');
      const franchiseeId = localStorage.getItem('franchiseeId');
      data.adminType = 'SA';
      data.chatType = chatType;
      data.active = active;

      if (chatType === 'manychat') {
         const manyChatDetailsData = [];
         Object.keys(manyChatDetails).forEach(key => {
            if (manyChatDetails[key].serviceCode !== '') {
               manyChatDetailsData.push(manyChatDetails[key]);
            }
         });
         data.manyChatDetails = manyChatDetailsData;
      } else {
         data.manyChatDetails = []
      }

      let method = hasList ? 'patch' : 'post';
      let api = hasList ? `/chatConfig/${ID}?adminType=SA` : `/chatConfig?adminType=SA`;

      if (role === 'CITYADMIN') {
         data.adminType = 'CA'
         data.franchiseeId = franchiseeId
         api = `/chatConfig?adminType=CA&franchiseeId=${franchiseeId}`
         if (hasList) {
            method = 'patch'
            api = `/chatConfig/${ID}?adminType=CA&franchiseeId=${franchiseeId}`
         }
      }


      axios({
         url: api,
         method: method,
         baseURL: baseURL,
         headers: { 'Authorization': `Bearer ${token}` },
         data: data
      })
         .then(res => {
            toast({
               title: `Customer support feature ${hasList ? 'updated' : 'created'} successfully.`,
               status: "success",
               duration: 4000,
               isClosable: true
            });
            reset(res.data.data)
            setHasList(true)
            setID(res.data.data._id)
         })
         .catch(err => {
            toast({
               title: 'Error Occured',
               description: err.response.data.message,
               status: "error",
               duration: 4000,
               isClosable: true
            })
         })
   }

   return (
      <Container maxWidth={1100} centerContent mt={6} >
         <Box w="100%" bg="white" rounded="lg" boxShadow="lg" px={4}>
            <Flex py={4}>
               <Text flex="1 1" fontSize="16px" fontWeight={700}>Chat Config</Text>
            </Flex>

            <Flex py={4} mb={2}>
               {/* Select Chat */}
               <Box flex="1 1">
                  <Select
                     placeholder="Select option"
                     value={chatType} onChange={(e => setChatType(e.target.value))} >
                     <option value="twakto">Tawk.to</option>
                     <option value="manychat">Manychat</option>
                  </Select>
               </Box>
               <FormControl pl="20px" flex="1 1" alignSelf="center">
                  <Checkbox
                     onChange={e => setActive(e.target.checked)}
                     isChecked={active}
                     value={active}
                     as={'p'} fontWeight="semibold"
                  >Active</Checkbox>
               </FormControl>
            </Flex>

            {
               chatType &&
               <FormLabel as={'p'} fontWeight="semibold" mb={3} >
                  {
                     chatType === 'twakto' ?
                        'Twak.to'
                        : chatType.charAt(0).toUpperCase() + chatType.slice(1)
                  } Config
               </FormLabel>
            }

            {/* Tawk.to Form */}
            {
               chatType === 'twakto' &&
               <form onSubmit={handleSubmit(submitHandler)}>
                  <SimpleGrid
                     columns={{ base: 1, sm: 1, md: 2, lg: 2 }}
                     spacing="15px" mb={4}>

                     <FormControl mb="15px" isInvalid={errors.propertyId}>
                        <FormLabel as={'p'} fontWeight="semibold" m={0}>Property ID</FormLabel>
                        <Input
                           ref={register({ required: 'PropertyId is required' })}
                           variant="flushed" borderColor="gray.300" size="sm"
                           name="propertyId" type="text" />
                        <FormErrorMessage>
                           {errors?.propertyId?.message}
                        </FormErrorMessage>
                     </FormControl>

                     <FormControl mb="15px" isInvalid={errors.chatId}>
                        <FormLabel as={'p'} fontWeight="semibold" m={0}>Chat ID</FormLabel>
                        <Input
                           ref={register({ required: 'Chat ID is required' })}
                           variant="flushed" borderColor="gray.300" size="sm"
                           name="chatId" type="text" />
                        <FormErrorMessage >
                           {errors?.chatId?.message}
                        </FormErrorMessage>
                     </FormControl>

                  </SimpleGrid>

                  <Button type="submit" colorScheme="teal" variant="outline" size="sm" mb={4}>
                     Save
                  </Button>
               </form>
            }
            {/* END Tawk.to */}

            {/* Manychat Form */}
            {
               chatType === 'manychat' &&
               <>
                  <Box>
                     <HStack {...manychatRadioGroup} mb="10px">
                        {manychatOptions.map((value) => {
                           const radio = getRadioProps({ value })
                           return (
                              <ManyChatRadioCard key={value} {...radio}>
                                 {value.toLocaleUpperCase()}
                              </ManyChatRadioCard>
                           )
                        })}
                     </HStack>
                     {
                        manychatOptionValue === 'FACEBOOKMESSENGER' &&
                        <Stack direction='row' spacing="10px">
                           <Badge colorScheme='green'>Overlay Widgets</Badge>
                        </Stack>
                     }
                  </Box>
                  <form style={{ marginTop: "30px" }} onSubmit={handleSubmit(submitHandler)}>
                     <SimpleGrid
                        columns={{ base: 1, sm: 1, md: 2, lg: 2 }}
                        spacing="15px" mb={4}
                     >

                        {
                           manychatOptionValue === 'FACEBOOKMESSENGER' &&
                           <FormControl mb="15px">
                              <FormLabel m={0}>ID: Facebook</FormLabel>
                              <Input
                                 value={manyChatDetails['FACEBOOKMESSENGER']?.serviceCode}
                                 variant="flushed" borderColor="gray.300" size="sm"
                                 onChange={(e) => manyChatValueHandler(e, 'FACEBOOKMESSENGER')}
                                 type="text" />
                           </FormControl>
                        }

                        {
                           manychatOptionValue === 'WHATSAPP' &&
                           <FormControl mb="15px">
                              <FormLabel m={0}>Whatsapp Link:</FormLabel>
                              <Input
                                 value={manyChatDetails['WHATSAPP']?.serviceCode}
                                 variant="flushed" borderColor="gray.300" size="sm"
                                 onChange={(e) => manyChatValueHandler(e, 'WHATSAPP')}
                                 type="text" />
                           </FormControl>
                        }

                        {
                           manychatOptionValue === 'TELEGRAM' &&
                           <FormControl mb="15px">
                              <FormLabel m={0}>Telegram Link:</FormLabel>
                              <Input
                                 value={manyChatDetails['TELEGRAM']?.serviceCode}
                                 variant="flushed" borderColor="gray.300" size="sm"
                                 onChange={(e) => manyChatValueHandler(e, 'TELEGRAM')}
                                 type="text" />
                           </FormControl>
                        }

                     </SimpleGrid>
                     <Button type="submit" colorScheme="teal" variant="outline" size="sm" mb={4}>
                        Save
                     </Button>
                  </form>
               </>
            }
            {/* END Manychat */}

         </Box>
      </Container>
   )
}

export default ChatConfig; 