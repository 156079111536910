import React, {useState } from 'react'; 
import { useForm, Controller } from 'react-hook-form'; 
import { v4 as uuid }  from 'uuid'; 
import {
    Box, Flex, Accordion, AccordionItem, AccordionPanel, 
    Button,  FormControl, Input
} from '@chakra-ui/react';
import CollapsableButton from './CollapsableButton'; 
import SelectBox from './SelectBox'; 
import SystemMenuSelect from './SystemMenuSelect'; 
import axios from 'axios'; 
import { config } from '../../../config'; 
import useDomain from '../../../service/DomainHook'


function MenuOptions(props) { 
    const {treeData, setTreeData} = props;
    const baseUrl = `${config.baseUrl}/api/v1`; 
    const { register, handleSubmit, errors, control, reset } = useForm({ 
        mode: 'onTouched', 
        shouldFocusError: false 
    }) 

    const [hasSubDomain] = useDomain();
    let systemMenuOption = [ 
        { value: 'BLOG', label: 'Blog', slug: 'blog' }
    ] 
    if (hasSubDomain) {
        systemMenuOption = [ 
            { value: 'BLOG', label: 'Blog', slug: 'blog' },
            { value: 'SHOP', label: 'Shop', slug: 'shop' }
        ] 
    }

    const [menuItems, setMenuItems] = useState([ 
        { 
            _id: '0',
            type: 'SYSTEM_MENU_SELECT',
            show: true,
            CollapsableOptionName: 'System Menu', 
            options: systemMenuOption
        }, 
        { 
            _id: '1',
            type: 'SELECT',
            show: true, 
            CollapsableOptionName: 'Pages', 
            name: 'Pages', 
            optionName: 'Page', 
            optionNameSlug: 'page', 
            fieldToShowAsLabel: 'pageName', 
            options: [], 
            selectedOption: {}, 
            apiType: 'page', 
            getApiUrl: () => { 
                const fields = 'pageName,pageTitle,slug'; 
                const franchiseeId = localStorage.getItem('franchiseeId'); 
                return franchiseeId !== 'null' 
                ? `/pages?pageType=CAP&franchiseeId=${franchiseeId}&fields=${fields}` 
                : `/pages?pageType=SAP&fields=${fields}`; 
            } 
        }, 
        { 
            _id: '5',
            type: 'LINK', 
            show: true, 
            CollapsableOptionName: 'Custom Link', 
            optionName: 'Custom link', 
            optionNameSlug: 'custom/link', 
            labelText: '', 
            linkText: ''
        } 
    ]); 
    
    const [selectedMenu, setSelectedMenu] = useState({}); 
    const [ menulist, setMenulist ] = useState([]); 

    // Page Select Handler 
    const onlySelectHandler = (item, index) => { 
        const length = menuItems[index]?.options?.length;
        if(!length) { 
            let url = item?.getApiUrl(); 
            axios({ url, method: 'get', baseURL: baseUrl }) 
            .then(res => { 
               
                let selectedMenu = menuItems[index]; // All menu of left side in selectedMenu 
                
                let data = res?.data?.data?.map(obj => ({ 
                    value: obj._id, 
                    label: obj[item?.fieldToShowAsLabel], 
                    fieldToShowAsLabel: item?.fieldToShowAsLabel, 

                    slug: obj?.slug, 
                    contentId: obj?._id, 
                    getApiUrl: item?.getApiUrl() 
                })) 
                selectedMenu.options = data; 
                
                let newArr = [...menuItems]; 
                newArr[index] = selectedMenu; 
                setMenuItems(newArr); 
            }) 
            .catch(err => console.log(err)) 
        } 
    } 

    // Only Link Handler 
    const onlyLinkHandler = (item, field, index, value) => { 
        let obj = {...item}; 
        obj[field] = value; 
        let allItem = [...menuItems]; 
        allItem[index] = obj; 
        setMenuItems(allItem); 
        
        let id = uuid(); 
        setLatestSelectedValue({ 
            id, 
            data: { 
                id, 
                labelText: obj.labelText, 
                linkText: obj.linkText,
                optionName: 'Custom', 
                slug: 'custom-link', 
            }, 
            hasChildren: false, 
            isExpanded: false, 
            isChildrenLoading: false, 
            children: [],
            type: 'CUSTOM_MENU'
        }); 
    }


    const setLatestSelectedValue = (value) => setSelectedMenu(value); 


    const addToMenu = () => { 
        if(Object.keys(selectedMenu)?.length > 0) { 
            if(selectedMenu?.data?.name || selectedMenu?.data?.labelText) { 
                if(Object.keys(selectedMenu)?.length > 0) { 
                    let {rootId, items} = treeData[0]; 
                    
                    let itemId = uuid(); 
                    items['10101010'].children.push(itemId); 
                    items['10101010'].hasChildren = true; 

                    items[itemId] = {...selectedMenu}; 
                    items[itemId].id = itemId; 

                    let obj = { rootId, items }; 
                    setTreeData([obj]); 
                    reset(); 
                } 
                

                // reset 
                if(Object.keys(selectedMenu).length > 0) { 
                    setMenulist([...menulist, selectedMenu]); 
                    setSelectedMenu({}); 
                    let allMenus = [...menuItems]; 
                    
                    const newMenuSet = allMenus?.map(menu => { 
                        if(menu.type === 'SELECT') { 
                        menu.selectedOption = {}; 
                        return menu; 
                        } 
                        else if(menu.type === 'TAB') { 
                            menu?.tabPanel?.map(tab => { 
                                tab.selectedOption = {}; 
                            }) 
                            return menu; 
                        } 
                        else if(menu.type === 'LINK') { 
                            menu.labelText = ''; 
                            menu.linkText = ''; 
                            return menu; 
                        } 
                        else if(menu.type === 'SYSTEM_MENU_SELECT') { 
                            return menu; 
                        } 
                    }) 
                    setMenuItems(newMenuSet); 
                    reset(); 
                } 
            }
        } 
    } 


    return ( 
        <Flex w="100%" bg="white" height="92%" direction="column" 
            mt={3} boxShadow="sm" borderWidth={1}> 
            <Accordion allowToggle> 
            { 
                menuItems?.map((item, index) => { 
                    if(item?.show) { 
                        if (item?.type === 'SELECT') {
                            return (
                                <AccordionItem onClick={() => onlySelectHandler(item, index)} key={item?._id}>
                                    <CollapsableButton name={item?.CollapsableOptionName} />
                                    <AccordionPanel mb={2} pb={0}>
                                        <SelectBox 
                                            Controller={Controller} 
                                            control={control} 
                                            name={item?.name} 
                                            options={item?.options} 
                                            menuItems={menuItems} 
                                            setMenuItems={setMenuItems} 
                                            index={index} item={item} 
                                            tab={null} tabIndex={null} 
                                            setLatestSelectedValue={setLatestSelectedValue} 
                                        />
                                    </AccordionPanel>
                                </AccordionItem>
                            )
                        }
                        else if (item?.type === 'SYSTEM_MENU_SELECT') { 
                            return ( 
                                <AccordionItem key={item?._id}>
                                    <CollapsableButton name={item?.CollapsableOptionName} />
                                    <AccordionPanel mb={2} pb={0}>
                                        <SystemMenuSelect 
                                            Controller={Controller} 
                                            control={control} 
                                            name={item?.name} 
                                            options={item?.options} 
                                            setLatestSelectedValue={setLatestSelectedValue} 
                                            tab={null} tabIndex={null} 
                                            menuItems={menuItems} 
                                            setMenuItems={setMenuItems} 
                                            index={index} item={item} 
                                        />
                                    </AccordionPanel>
                                </AccordionItem>
                            )
                        }
                        else if (item?.type === 'LINK') { 
                            return ( 
                                <AccordionItem key={item?._id}>
                                    <CollapsableButton name={item?.CollapsableOptionName} />
                                    <AccordionPanel mb={2} pb={0}>
                                        <FormControl>
                                            <Input
                                                type="text" value={item?.labelText}
                                                size="sm" placeholder="Label"
                                                variant="flushed" borderColor="gray.300"
                                                onChange={(e) => {
                                                    onlyLinkHandler(item, 'labelText', index, e.target.value);
                                                }} 
                                            /> 
                                        </FormControl>
                                        <FormControl> 
                                            <Input
                                                type="text" value={item?.linkText}
                                                size="sm" placeholder="Link"
                                                variant="flushed" borderColor="gray.300"
                                                onChange={(e) => {
                                                    onlyLinkHandler(item, 'linkText', index, e.target.value);
                                                }}
                                            /> 
                                        </FormControl>
                                    </AccordionPanel>
                                </AccordionItem>
                            )
                        }
                        else { 
                            return (<Box key={item?._id}></Box>) 
                        } 
                    } 
                    else { 
                        return (<Box key={item?._id}></Box>) 
                    } 
                }) 
            } 
            </Accordion> 


            {/* Add to menu button */} 
            <Box mt="auto" textAlign="right"> 
                <Button 
                    size="sm" fontWeight="500" rounded={0} 
                    // colorScheme={Object.keys(selectedMenu).length ? 'teal' : 'gray'} 
                    colorScheme={ 
                        Object.keys(selectedMenu).length 
                        ? 
                            selectedMenu?.data?.name || selectedMenu?.data?.labelText
                            ? 'teal'
                            : 'gray' 
                        : 'gray' 
                    } 
                    cursor={ 
                        Object.keys(selectedMenu).length 
                        ? 
                            selectedMenu?.data?.name || selectedMenu?.data?.labelText
                            ? 'pointer' 
                            : 'not-allowed' 
                        : 'not-allowed' 
                    } 
                    w="100%" onClick={addToMenu}> 
                    Add to menu 
                </Button> 
            </Box> 
        </Flex> 
    ) 
} 

export default MenuOptions; 