import React, { useState, useEffect, useRef } from 'react'; 
import { Box, Center } from '@chakra-ui/react'; 
import axios from 'axios'; 
import { config } from '../../config'; 
import ProductReviewList from './ProductReviewList'; 
import WebPagination from '../../components/pagination/WebPagination'; 

function ProductReviewFetchData(props) { 
    const baseURL = `${config.baseUrl}/api/v1`; 
    const { 
        reviews, setReviews, 
        total, setTotal, 
        page, setPage, limit 
    } = props; 

    const setNewPage = (data) => { 
        data.selected = data.selected + 1; 
        setPage(data.selected) 
    } 

    useEffect(() => { 
        axios({ 
            url: `/productComments?productId=${props.productId}&page=1&limit=${limit}`, 
            method: 'get', 
            baseURL: baseURL 
        }) 
        .then(res => { 
            setReviews(res.data?.data) 
            setTotal(res.data?.result) 
            setPage(1) 
        }) 
        .catch(err => console.log(err)) 
    }, [props.productId]) 

    
    const isFirstRun = useRef(true) 
    useEffect(() => { 
        if(isFirstRun.current) { 
            isFirstRun.current = false; 
        } 
        else { 
            axios({ 
                url: `/productComments?productSlug=${props.productSlug}&page=${page}&limit=${limit}`, 
                method: 'get', 
                baseURL: baseURL 
            }) 
            .then(res => { 
                setReviews(res.data?.data) 
                setTotal(res.data?.result) 
            }) 
            .catch(err => console.log(err)) 
        } 
    }, [page]) // data fetch on pagination page change 

    return ( 
        <Box> 
            <ProductReviewList reviews={reviews} total={total} /> 
            { 
                total > limit && 
                <Center mt="20px"> 
                    <WebPagination 
                        limit={limit} 
                        page={page} 
                        total={total} 
                        setNewPage={setNewPage} 
                    /> 
                </Center> 
            } 
        </Box> 
    ) 
} 

export default ProductReviewFetchData; 