import React, { useState, useEffect } from 'react'
import {
    Box, Flex, Modal, Text, SimpleGrid, Select,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton, useToast, Stack, Image
} from '@chakra-ui/react'
import axios from 'axios';
import { config } from '../../config';

const SuborderModal = ({ order, isOpen, onClose, productsPrice, fetchData, orderObj }) => {

    const baseURL = `${config.baseUrl}/api/v1`;
    const token = localStorage.getItem('token');
    const toast = useToast();
    const [status, setStatus] = useState('PAYMENT_PENDING');

    const handleUpdate = async (value) => {

        await axios({
            url: `orders/${order?._id}`,
            method: 'patch',
            baseURL: baseURL,
            headers: { 'Authorization': `Bearer ${token}` },
            data: {
                status: value
            }
        })
            .then(response => {
                setStatus(value);
                fetchData();
                toast({
                    title: 'Order Status Updated.',
                    description: "We've updated your order status.",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
            })
            .catch(error => {
                toast({
                    title: 'Order Status Update Error',
                    description: "Failed to update order status",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            })
    };

    useEffect(() => {
        setStatus(order.status);
    }, [order]);

    const priceConvert = (price) => {
        return parseFloat(price).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="6xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Sub-order Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody>

                    <Flex
                        bg="white"
                        // boxShadow="md"
                        w='100%'
                        rounded={5}
                        justify="space-between"
                        px={[2, 2, 5, 5]} py={5}>

                        <Box flexGrow={1}>
                            <Text color="gray.600" fontWeight="700" fontSize="1rem" lineHeight="1rem" mb={4}>Shipping Address</Text>
                            <SimpleGrid minChildWidth="14rem" spacing="15px">
                                <Box mb={4}>
                                    <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>First Name</Text>
                                    <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{order?.shippingAddress?.firstName}</Text>
                                </Box>
                                <Box mb={4}>
                                    <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Last Name</Text>
                                    <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{order?.shippingAddress?.lastName}</Text>
                                </Box>
                            </SimpleGrid>

                            <Box mb={4}>
                                <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Email</Text>
                                <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{order?.shippingAddress?.email}</Text>
                            </Box>

                            <Box mb={4}>
                                <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Phone</Text>
                                <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{order?.shippingAddress?.phone}</Text>
                            </Box>

                            <Box mb={4}>
                                <Stack direction={["column", "row"]} spacing="10px">
                                    <Text color="gray.500" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Country / Region :</Text>
                                    <Text color="gray.400" fontWeight="600" fontSize="1rem" lineHeight="1rem" mb={1}>United State (US)</Text>
                                </Stack>
                            </Box>

                            <Box mb={4}>
                                <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Street Address</Text>
                                <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{order?.shippingAddress?.street}</Text>
                            </Box>

                            <Box mb={4}>
                                <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Apartment / Suit / Unit</Text>
                                <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{order?.shippingAddress?.house}</Text>
                            </Box>

                            <SimpleGrid minChildWidth="10rem" spacing="15px">

                                <Box mb={4}>
                                    <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Town / City</Text>
                                    <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{order?.shippingAddress?.city}</Text>
                                </Box>

                                <Box mb={4}>
                                    <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>State</Text>
                                    <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{order?.shippingAddress?.state}</Text>
                                </Box>

                                <Box mb={4}>
                                    <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Zip</Text>
                                    <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{order?.shippingAddress?.zip}</Text>
                                </Box>

                            </SimpleGrid>
                        </Box>

                        <Box
                            ml={6}
                            flexGrow={1}

                            rounded={5}
                            px={[2, 2, 5, 5]} pb={5}>

                            {/*Product Information */}

                            <Text color="gray.600" fontWeight="700" fontSize="1rem" lineHeight="1rem" mb={2}>
                                Products
                            </Text>
                            <Box>
                                {
                                    order?.products && order?.products.map(((product) => (
                                        <Flex gridGap={2} mb={3}>
                                            <Image src={product.image} alt='product image' w='80px' h='80px' objectFit='cover' rounded={5} />
                                            <Box>
                                                <Flex gridGap={2}>
                                                    <Text
                                                        color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>
                                                        Name:
                                                    </Text>
                                                    <Text color="gray.500" fontSize=".9rem" lineHeight="1rem" s>{product.name}</Text>

                                                </Flex>
                                                <Flex gridGap={2}>
                                                    <Text
                                                        color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>
                                                        Price:
                                                    </Text>
                                                    <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">${priceConvert(product.amount)}</Text>
                                                    {/* <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">${priceConvert(product.price)}</Text> */}

                                                </Flex>
                                                <Flex gridGap={2}>
                                                    <Text
                                                        color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>
                                                        Quantity:
                                                    </Text>
                                                    <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{product.qty}</Text>

                                                </Flex>
                                                {orderObj?.handleType == 'BY_ITEM' && <Flex gridGap={2}>
                                                    <Text
                                                        color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>
                                                        Handling Fee:
                                                    </Text>
                                                    <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">${priceConvert(product?.handleFee ? product?.handleFee : 0)}</Text>

                                                </Flex>}

                                            </Box>

                                        </Flex>
                                    )))
                                }

                                <Flex gridGap={2} mb={2}>
                                    <Text
                                        color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>
                                        Greeting Card:
                                    </Text>
                                    <Text color="gray.500" fontSize=".9rem" lineHeight="1rem" wordBreak='break-all'>{order?.greetingCard?.name || 'N/A'}</Text>
                                </Flex>

                                <Flex gridGap={2} mb={2}>
                                    <Text
                                        color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>
                                        Greeting Message:
                                    </Text>
                                    <Text color="gray.500" fontSize=".9rem" lineHeight="1rem" wordBreak='break-all'>{order?.greetingCard?.message || 'N/A'}</Text>
                                </Flex>
                            </Box>

                            {/* <ProductTable columns={productColumns} data={productData} /> */}

                            {/* Additional Information */}
                            <Box as='form' w={['100%', '100%', '40%', '40%']} pt={5} rounded={5} pb={5}>
                                <Text color="gray.600" fontWeight="700" fontSize="1rem" lineHeight="1rem" mb={5}>
                                    Additional Information
                                </Text>

                                <Box w='90%'>
                                    <Flex>
                                        <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Product Price</Text>
                                        <Text color="gray.500" fontSize=".9rem" ml='auto' lineHeight="1rem">${priceConvert(productsPrice)}</Text>
                                    </Flex>

                                    <Flex borderBottom='1px solid gray'>
                                        <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Greeting Price</Text>
                                        <Text color="gray.500" fontSize=".9rem" ml='auto' lineHeight="1rem">${priceConvert(order?.greetingCard?.price ? order?.greetingCard?.price : 0)}</Text>
                                    </Flex>

                                    {/* <Flex>
                                        <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Handling Fee</Text>
                                        <Text color="gray.500" fontSize=".9rem" ml='auto' lineHeight="1rem">${priceConvert(orderObj?.totalHandleFee ? orderObj?.totalHandleFee : 0)}</Text>
                                    </Flex>

                                    <Flex>
                                        <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Shipping Fee</Text>
                                        <Text color="gray.500" fontSize=".9rem" ml='auto' lineHeight="1rem">${priceConvert(orderObj?.shipping?.charge ? orderObj?.shipping?.charge : 0)}</Text>
                                    </Flex>

                                    <Flex borderBottom='1px solid gray'>
                                        <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Discount</Text>
                                        <Text color="gray.500" fontSize=".9rem" ml='auto' lineHeight="1rem">${priceConvert(orderObj?.discount?.value ? orderObj?.discount?.value : 0)}</Text>
                                    </Flex> */}

                                    <Flex mt={2}>
                                        <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Total</Text>
                                        <Text ml='auto' color="gray.500" fontSize=".9rem" lineHeight="1rem">${priceConvert(order?.subTotal ? order?.subTotal : 0)}</Text>
                                        {/* <Text ml='auto' color="gray.500" fontSize=".9rem" lineHeight="1rem">${priceConvert(orderObj?.total ? orderObj?.total : 0)}</Text> */}
                                        {/* <Text ml='auto' color="gray.500" fontSize=".9rem" lineHeight="1rem">${priceConvert((order?.greetingCard?.price ? order?.greetingCard?.price : 0) + productsPrice)}</Text> */}
                                    </Flex>
                                </Box>


                                {/* order Status */}

                                <Text mt={5} color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Order Status</Text>
                                <Select size='sm' w='150px' onChange={(e) =>

                                    handleUpdate(e.target.value)


                                }
                                    value={status} name="status">
                                    <option value="PAYMENT_PENDING">Payment-Pending</option>
                                    <option value="PROCESSING">Processing</option>
                                    <option value="FAILED">Failed</option>
                                    <option value="CANCELED">Canceled</option>
                                    <option value="COMPLETE">Complete</option>
                                    <option value="ONHOLD">On-Hold</option>
                                    <option value="REFUNDED">Refunded</option>
                                </Select>
                            </Box>
                        </Box>

                    </Flex>





                </ModalBody>


            </ModalContent>
        </Modal>
    )
}

export default SuborderModal;