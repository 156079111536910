import styled from 'styled-components';
import {config} from '../../config';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';


const baseURL = `${config.baseUrl}/api/v1`;

// Styles for product description section
export const ProductDescription = styled.div`
    p { 
        margin: 0;
        min-height: 18px;
    } 
    ul, li { 
        margin-left: 1.5em; 
    } 
    ol, li { 
        margin-left: 1.5em; 
    } 
    blockquote { 
        text-align: justify; 
        border-left: 5px solid #dddddd; 
        padding: 20px 15px 20px 40px; 
        margin: 20px 0; 
        background: #EDF2F7;
        border-radius: 5px;
        @media (max-width: 500px) {
            padding: 10px 15px 10px 25px; 
        }
    } 
    img {
        margin: 10px 0;
    }
    pre { 
        background: #f1f1f1;
        border-radius: 3px;
        padding: 1px 10px;
        margin: 15px 0;
        padding: 20px;
    } 
`

// Add product to cart
export const handleProductAdd = async (price, quantity, product) => {
    const franchisee = localStorage.getItem('franchisee');
    const selectedProduct = {
        productId:product._id,
        productName: product.name,
        productImage: product.images.links[product.images.defaultImage - 1],
        amount: price,
        qty: quantity.current
    }

    const data = { ...selectedProduct, franchiseeId: franchisee };

    let cartToken = localStorage.getItem('cartToken');
    if (cartToken) {
        data.token = cartToken;
    } else {
        const token = uuidv4();
        localStorage.setItem('cartToken', token);
        data.token = token;
    }

    try { 
        const response = await axios({
            url: '/cart',
            method: 'POST',
            baseURL: baseURL,
            data: data
        });
        return {
            status: 'ok',
            message: response.data.message,
            itemCount: response.data.itemCount
        }
    } 
    catch (error) { 
        return {
            status: 'fail',
            message: error?.response.data.message
        }
    }
}

export const OptionMapper = (options) => {

    let newOptions = [];

    if (options.length) {
        newOptions = options.map(option => {
            let item = {};
            item.value = option._id;
            item.label = `${option.name} ($${option?.price?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })})`;
            item.price = option.price;
            return item;
        })
    }

    return newOptions;
}


export const getShopPageLayout = async ({franchisee}) => { 
    try { 
        const response = await axios.get(`${baseURL}/shops/${franchisee}`);
        return response.data; 
      } catch (error) { 
        console.error(error);
      }
}