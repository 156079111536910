import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
    Box, Button, FormControl, FormLabel, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select, SimpleGrid, Text, useToast,
} from '@chakra-ui/react';
import Tree from './Tree/Tree';
import axios from 'axios';
import { config } from '../../../config';
import useDomain from '../../../service/DomainHook';
import DomainUtils from '../../../service/utils/DomainUtils';
// import Select from 'react-select/dist/declarations/src/Select';
import { editorFontList } from '../../../service/utils/FontList';

function Body(props) {
    const toast = useToast();
    const [hasSubDomain] = useDomain();
    const { treeData, setTreeData } = props;
    const baseURL = `${config.baseUrl}/api/v1`;

    const { register, handleSubmit, control, reset, setValue } = useForm({
        mode: 'onSubmit',
        shouldFocusError: false
    })
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setDisabled] = useState(true);
    const menuId = useRef(null);

    const [fontState, setFontState] = useState({
        fontFamily: "Poppins",
        fontSize: 14,
        fontWeight: 400,
    })


    useEffect(() => {
        const fetchMenu = async () => {
            let url = '/menus/SA';
            if (hasSubDomain) {
                const [franchiseesId] = await DomainUtils();
                url = `/menus/CA/${franchiseesId}`;
            }

            try {
                const { data: { data: menu } } = await axios.get(url, { baseURL });
                if (menu) {
                    setTreeData([menu.content]);
                    menuId.current = menu._id;

                    setFontState({
                        ...fontState,
                        fontFamily: menu?.style?.fontFamily,
                        fontSize: parseInt(menu?.style?.fontSize),
                        fontWeight: menu?.style?.fontWeight,
                    });

                    setValue('style.fontFamily', menu?.style?.fontFamily)
                    setValue('style.fontSize', menu?.style?.fontSize)
                    setValue('style.fontWeight', menu?.style?.fontWeight)
                }
                setDisabled(false);
            } catch (error) {
                console.log(error);
            }
        }
        fetchMenu();
    }, [])


    const submitHandler = async (data) => {
        setIsLoading(true);
        let url = '/menus';
        let method = 'POST'
        if (menuId.current) {
            url = `/menus/${menuId.current}`;
            method = 'PATCH';
        }
        // setIsLoading(true);
        const token = localStorage.getItem('token');
        const franchiseeId = localStorage.getItem('franchiseeId');

        let menuPayload = {
            adminType: 'SA',
            content: treeData[0],
            style: data.style
        }

        if (hasSubDomain) {
            menuPayload.adminType = 'CA';
            menuPayload.franchiseeId = franchiseeId;
        }

        try {
            const { data: { data: menu } } = await axios({
                url,
                method,
                baseURL,
                data: menuPayload,
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (menu) {
                toast({
                    title: `Menu saved Successfully`,
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
                menuId.current = menu._id;
            }
            setDisabled(false);
            setIsLoading(false)
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <Button
                type="submit" colorScheme="teal"
                variant="outline" size="sm" mb="6px"
                isLoading={isLoading}
                disabled={isDisabled}
            >
                Save
            </Button>

            <Box pb={4}>

                <Box h="100%" w="100%">
                    {/* <Text>Style Section</Text> */}

                    <Box mb="30px">
                        <Text fontSize="1.1rem" fontWeight="700" mb={5}>Font Style</Text>
                        <SimpleGrid minChildWidth="12rem" spacing="15px">

                            {/* Font Family */}
                            <Box>
                                <FormLabel fontSize="16px">Font Family</FormLabel>

                                <Controller
                                    defaultValue='default'
                                    control={control}
                                    name="style.fontFamily"
                                    render={(
                                        { onChange, value, name, ref }
                                    ) => (
                                        <Select
                                            size="sm"
                                            value={value}
                                            onChange={(e) => onChange(e.target.value)}
                                        >
                                            {
                                                editorFontList.map(font => (
                                                    <option key={font} value={font}>{font}</option>
                                                ))
                                            }
                                        </Select>)}
                                />
                            </Box>



                            {/* font size */}
                            <Box>
                                <FormLabel fontSize="16px">Font Size</FormLabel>

                                <Controller
                                    defaultValue={14}
                                    control={control}
                                    name="style.fontSize"
                                    render={(
                                        { onChange, value, }
                                    ) => (

                                        <NumberInput
                                            size="sm"
                                            max={100} min={5}
                                            value={value}
                                            onChange={(value) => onChange(value)}
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>

                                    )}
                                />
                            </Box>




                            {/* font weight */}
                            <FormControl id="fontWeight">
                                <FormLabel fontSize="16px">Font Weight</FormLabel>
                                <Select
                                    ref={register} name="style.fontWeight"
                                    size="sm" defaultValue={fontState?.fontWeight}
                                >
                                    {
                                        ["200", "300", "400", "500", "600", "700", "800"].map(item => (
                                            <option key={item} value={item}>{item}</option>
                                        ))
                                    }
                                </Select>
                            </FormControl>

                        </SimpleGrid>
                    </Box>

                </Box>
            </Box>
            <Box pb={4}>
                <Box h="100%" w="100%" overflow="auto">
                    <Tree treeData={treeData} setTreeData={setTreeData} />
                </Box>
            </Box>
        </form>
    )
}

export default Body; 