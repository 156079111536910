import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom';
import {
    Container, Box, Stack, Text, SimpleGrid, Select,
    useToast,
    Flex,
    Icon,
    Button

} from '@chakra-ui/react'
import axios from 'axios'
import { config } from '../../config';
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import SuborderList from './SuborderList';
import { CSVConverter } from '../../service/utils/helper';
import { CSVLink } from 'react-csv';
import { FaFileCsv } from 'react-icons/fa';
import moment from 'moment';

function Order() {
    const { id } = useParams();
    const history = useHistory();
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [subOrders, setSubOrders] = useState([])
    const [status, setStatus] = useState('PAYMENT_PENDING');
    const [orderDownloadable, setOrderDownloadable] = useState([]);

    const baseURL = `${config.baseUrl}/api/v1`;
    const franchiseeId = localStorage.getItem('franchiseeId');

    const token = localStorage.getItem('token');
    const toast = useToast();

    const fetchData = () => {

        const params = {
            adminType: 'SA'
        }

        if (franchiseeId !== 'null') {
            params.adminType = 'CA';
            params.franchiseeId = franchiseeId;
        }

        axios({
            method: 'get',
            params,
            baseURL: baseURL,
            url: `/orders/${id}`,
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {
                setOrder(res.data.data);
                setStatus(res.data.data.status);
                setSubOrders(res.data.data.subOrders)

                let convertedData = CSVConverter.arrayToCSVConverter([res.data.data])
                setOrderDownloadable(convertedData)
            })
            .catch(err => console.log(err))
    }


    useEffect(() => {
        fetchData();
    }, [])


    const handleUpdate = async (value) => {

        await axios({
            url: `orders/${id}`,
            method: 'patch',
            baseURL: baseURL,
            headers: { 'Authorization': `Bearer ${token}` },
            data: {
                status: value
            }
        })
            .then(response => {
                setStatus(value);
                toast({
                    title: 'Order Status Updated.',
                    description: "We've updated your order status.",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
            })
            .catch(error => {
                toast({
                    title: 'Order Status Update Error',
                    description: "Failed to update order status",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
                console.log(error.response.data);
            })
    };



    const onDelete = async () => {
        setLoading(true)

        await axios({
            url: `orders/${id}`,
            method: 'DELETE',
            baseURL: baseURL,
            headers: { 'Authorization': `Bearer ${token}` },
            // data: {
            //     status: value
            // }
        })
            .then(response => {
                toast({
                    title: 'Order deleted successfully.',
                    // description: "We've updated your order status.",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                history.push(`/admin/order`);
                setLoading(false)
            })
            .catch(error => {
                toast({
                    title: 'Failed to delete order. Try again later',
                    // description: "Failed to update order status",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
                setLoading(false)
            })
    };

    const priceConvert = (price) => {
        return parseFloat(price).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    };

    const csvHeaders = [
        { label: "Order_ID & Billing_Address", key: "orderNo" },
        { label: "Sub_Order & Shipping_Address", key: "subOrders" },
        { label: "Product_Info (Name)", key: "subOrders.products.name" }
    ];
    

    return (
        <Container maxWidth={1100}>
            <Box py={6}>
                <Box bg="white" p={4} mb={3} boxShadow="md" rounded="md" >
                    <Flex justifyContent="space-between" align='center' mt={2} gridGap={2}>
                        <Breadcrumb
                            pathname="Order" nameOrTitle={order?.orderNo}
                            link={`/admin/order`}
                        />
                        <Button colorScheme="teal" variant="outline" size="sm">
                            <CSVLink
                                // data={CSVConverter.ConvertToCSV(orderDownloadable)}
                                // headers={csvHeaders}
                                data={orderDownloadable}
                                filename={`Order_${order?.orderNo}_${moment(new Date()).format('YYYY-MM-DD')}`}
                            >
                                <Icon as={FaFileCsv} fontSize="15px" /> Download CSV
                            </CSVLink>
                        </Button>
                    </Flex>
                </Box>
                <Stack direction={["column", "row"]} spacing="10px" bg="white" boxShadow="md">
                    <Box
                        w={['100%', '100%', '50%', '50%']}
                        rounded={5}
                        px={[2, 2, 5, 5]} py={5}>
                        <Text color="gray.600" fontWeight="700" fontSize="1rem" lineHeight="1rem" mb={4}>Billing Address</Text>
                        <Box>
                            <SimpleGrid minChildWidth="14rem" spacing="15px">
                                <Box mb={4}>
                                    <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>First Name</Text>
                                    <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{order?.billingAddress?.firstName}</Text>
                                </Box>
                                <Box mb={4}>
                                    <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Last Name</Text>
                                    <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{order?.billingAddress?.lastName}</Text>
                                </Box>
                            </SimpleGrid>

                            <Box mb={4}>
                                <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Email</Text>
                                <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{order?.billingAddress?.email}</Text>
                            </Box>

                            <Box mb={4}>
                                <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Phone</Text>
                                <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{order?.billingAddress?.phone}</Text>
                            </Box>

                            <Box mb={4}>
                                <Stack direction={["column", "row"]} spacing="10px">
                                    <Text color="gray.500" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Country / Region :</Text>
                                    <Text color="gray.400" fontWeight="600" fontSize="1rem" lineHeight="1rem" mb={1}>United State (US)</Text>
                                </Stack>
                            </Box>

                            <Box mb={4}>
                                <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Street Address</Text>
                                <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{order?.billingAddress?.street}</Text>
                            </Box>

                            <Box mb={4}>
                                <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Apartment / Suit / Unit</Text>
                                <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{order?.billingAddress.house}</Text>
                            </Box>

                            <SimpleGrid minChildWidth="10rem" spacing="15px">

                                <Box mb={4}>
                                    <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Town / City</Text>
                                    <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{order?.billingAddress?.city}</Text>
                                </Box>

                                <Box mb={4}>
                                    <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>State</Text>
                                    <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{order?.billingAddress?.state}</Text>
                                </Box>

                                <Box mb={4}>
                                    <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Zip</Text>
                                    <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">{order?.billingAddress?.zip}</Text>
                                </Box>

                            </SimpleGrid>
                        </Box>

                        <hr />
                        <Button
                            disabled={loading}
                            colorScheme="red"
                            variant="outline"
                            size="sm"
                            mt={3}
                            onClick={onDelete}
                        >
                            Delete
                        </Button>
                    </Box>

                    {/* Additional Information */}
                    <Box
                        w={['100%', '100%', '50%', '50%']}
                        pt={5} rounded={5}
                        px={[2, 2, 5, 5]} pb={5}>
                        <Text color="gray.600" fontWeight="700" fontSize="1rem" lineHeight="1rem" mb={4}>Additional Information</Text>

                        <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Shipping Fee</Text>
                        <Text mb={3} color="gray.500" fontSize=".9rem" lineHeight="1rem">${priceConvert(order?.shipping?.charge)}</Text>

                        <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Handling Fee ({order?.handleType})</Text>
                        <Text mb={3} color="gray.500" fontSize=".9rem" lineHeight="1rem">${priceConvert(order?.totalHandleFee ? order?.totalHandleFee : 0)}</Text>

                        <Text color="gray.600" fontWeight="600" fontSize=".9rem" lineHeight="1rem" mb={1}>Discount</Text>
                        <Text color="gray.500" fontSize=".9rem" lineHeight="1rem">${priceConvert(order?.discount.value)}</Text>

                        {/* order Status */}
                        <Box as='form' w={['100%', '100%', '40%', '40%']} pt={5} rounded={5} pb={5}>
                            <Text color="gray.600" fontWeight="700" fontSize="1rem" lineHeight="1rem" mb={2}>
                                Order Status
                            </Text>

                            <Select size='sm' w='150px' onChange={(e) =>

                                handleUpdate(e.target.value)

                            } value={status} name="status">
                                <option value="PAYMENT_PENDING">Payment-Pending</option>
                                <option value="PROCESSING">Processing</option>
                                <option value="FAILED">Failed</option>
                                <option value="CANCELED">Canceled</option>
                                <option value="COMPLETE">Complete</option>
                                <option value="ONHOLD">On-Hold</option>
                                <option value="REFUNDED">Refunded</option>
                            </Select>
                        </Box>
                    </Box>
                </Stack>


                {/* Sub order list */}
                <Box mt="20px">

                    <Box bg="white" p={4} boxShadow="md" rounded="md" mb={4}>
                        <Flex mt={2} justifyContent='space-between'>
                            <Text color="gray.600" fontWeight="700" fontSize="1rem" lineHeight="1rem" >Sub-order List</Text>
                            <Text color="gray.600" fontWeight="700" fontSize="1rem" lineHeight="1rem" >Sub Total: ${priceConvert(order?.subTotal ? order?.subTotal : 0)}</Text>
                            <Text color="gray.600" fontWeight="700" fontSize="1rem" lineHeight="1rem" >Total: ${priceConvert(order?.total ? order?.total : 0)}</Text>
                        </Flex>
                    </Box>

                    <SuborderList subOrders={subOrders} fetchData={fetchData} orderObj={order} />

                </Box>
            </Box>
        </Container>
    )
}

export default Order; 