import React, { createContext, useReducer } from 'react'

const initialState = 0;
const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_CART':
            return action.payload;    
        default :
            return state;      
    }
}


export const CartContext = createContext();

function CartContextProvider(props) {
    const [state, dispatch] =  useReducer(reducer, initialState);
    return (
        <CartContext.Provider value={[state, dispatch]}>
            {props.children}
        </CartContext.Provider>
    )
}

export default CartContextProvider