import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Flex, Box, Text, SimpleGrid } from '@chakra-ui/react'
import axios from 'axios';
import { config } from '../../config';
import shop from '../../img/shop.jpg'
import Product from './Product';
import ShopSkeleton from './ShopSkeleton';
import DomainUtils from '../../service/utils/DomainUtils';


const style = {
    backgroundImage: `url(${shop})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover'
}

function ProductByTag() { 
    const { id } = useParams(); 
    const [tagName, setTagName] = useState()
    const [ loading, setLoading ] = useState(true);
    const [ products, setProducts ] = useState([]);

    const baseURL = `${config.baseUrl}/api/v1`;

    useEffect(async () => { 
        window.scrollTo(0, 0) 
        const [ franchiseeId ] = await DomainUtils(); 
        axios.get(`${baseURL}/products?franchiseeId=${franchiseeId}&tags=${id}&publish=true&fields=images,isGreeting,maxPrice,minPrice,name,price,priceType,slug`) 
        .then(response => { 
            setProducts(response.data.data); 
            setLoading(false); 
        }) 
        .catch(error => { 
            console.log(error) 
        }) 

        axios.get(`${baseURL}/tags/${id}`) 
        .then(res => setTagName(res.data.data.name)) 
        .catch(err => console.log(err)) 
    }, []) 

    return ( 
        <Box> 
            <Box h="350px" bg="teal.300" mt="55px" style={style}> 
                <Flex justifyContent="center" h="100%"> 
                    <Text 
                        color="white" fontSize="40px" 
                        fontWeight="600" alignSelf="center"> 
                        { tagName } 
                    </Text> 
                </Flex> 
            </Box> 

            <Container maxW={1140} mt="15px" py={4}>
                {
                    loading ? (
                        <ShopSkeleton header={false} item={2} />
                    ): (
                        <Box>
                            <Text as="h3" mb="15px" fontSize="1.3em">All products by Tag</Text> 
                            <SimpleGrid 
                                minChildWidth="13rem" 
                                spacingX="15px" spacingY="25px"> 
                                { 
                                    products.map(product => { 
                                        return ( 
                                            <Box key={product._id}> 
                                                <Product product={product} /> 
                                            </Box> 
                                        ) 
                                    }) 
                                } 
                            </SimpleGrid>
                        </Box>
                    )
                }
            </Container>
        </Box>
    )
}

export default ProductByTag; 
