import React, { useEffect } from 'react'
import {
    Box, Image,
    Text, Stack, Select,
    FormControl, FormLabel, FormErrorMessage, Button, Textarea,
} from '@chakra-ui/react'
import { useForm, Controller } from 'react-hook-form'

function GreetingCardForm({ handleGreetingCard, onClose, shippingId, greetingCardList }) {

    const { register, handleSubmit, control, errors, reset } = useForm({
        mode: 'onChange',
        shouldFocusError: true,
        shouldUnregister: true
    });

    const viewCardInfo = (value) => {
        const card = greetingCardList.find(item => item._id === value);
        if (!card) return null;
        const cardPrice = parseFloat(card.price).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
        return (
            <Stack direction={["column", "row"]} spacing="10px" pt={3} pr={3} pb={3}>
                <Box
                    w="10rem"
                // w={["60px", "50px"]} h={["60px", "50px"]}
                >
                    <Image w="100%" h="100%" rounded={4} objectFit="cover" src={card.image} />
                </Box>
                <Box w="18rem">
                    <Text color="gray.700" fontWeight="700" fontSize=".9rem" lineHeight="1rem" mb={1}>{card.name}</Text>
                    <Text color="gray.700" fontWeight="400" fontSize=".9rem" lineHeight="1rem" mb={1}>${cardPrice}</Text>
                </Box>
            </Stack>
        )
    }

    return (
        <Box>
            <form onSubmit={handleSubmit(handleGreetingCard)}>
                <FormControl mb={4} color="gray.600" id="state" isInvalid={errors.greetingCard?.id}>
                    <FormLabel fontSize="sm">Greeting Card</FormLabel>
                    <Controller
                        defaultValue=""
                        control={control}
                        name="greetingCard.id"
                        rules={{ required: true }}
                        render={({ value, onChange }) => (
                            <>
                                <Select value={value} onChange={(e => onChange(e.target.value))}>
                                    <option key="greetingCard" value="">
                                        Select greeting card
                                    </option>
                                    {
                                        greetingCardList.map(card => (
                                            <option key={card._id} value={card._id}>
                                                {card.name}
                                            </option>
                                        ))
                                    }
                                </Select>
                                {value && viewCardInfo(value)}
                            </>
                        )}
                    />
                    <FormErrorMessage>
                        {errors.greetingCard?.id && errors.greetingCard?.id?.type === "required" && 'State field is required'}
                    </FormErrorMessage>
                </FormControl>

                <FormControl mb={4} color="gray.600" id="greetingCardNote" isInvalid={errors.greetingCard?.note}>
                    <FormLabel htmlFor="greetingCardNote" fontSize="sm">Note</FormLabel>
                    <Textarea name="greetingCard.note" ref={register({ required: true })} />
                    <FormErrorMessage>
                        {errors.greetingCard?.note && errors.greetingCard?.note?.type === "required" && 'Note is required'}
                    </FormErrorMessage>
                </FormControl>

                <Stack direction="row" spacing={4}>
                    <Button size="sm" colorScheme='black' variant='outline' onClick={() => {
                        onClose();
                        shippingId.current = null;
                    }}>Cancel</Button>
                    <Button size="sm" colorScheme='green' variant='outline' type="submit">Save</Button>
                </Stack>
            </form>
        </Box>
    )
}

export default GreetingCardForm