import { useEffect } from 'react';
import {
    Box, Flex, Table, Thead, Tbody,
    Tr, Th, Td, Heading
} from '@chakra-ui/react';
import { useTable, usePagination } from 'react-table';
import { Scrollbars } from 'react-custom-scrollbars';



const TopSellingTable = ({ columns, data, onFetchData, pageCount, title }) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: { pageIndex, pageSize }

    } = useTable({
        columns,
        data,
        onFetchData,
        pageCount,
        initialState: { pageIndex: 0 },
        manualPagination: true,
    }, usePagination);

    useEffect(() => {
        onFetchData({ pageIndex, pageSize })
    }, [onFetchData, pageIndex, pageSize])

    return (
        <Flex flexDirection="column">

            <Heading size="md" textAlign="center" mb={6}>{title}</Heading>
            <Box h='40vh' overflow="auto">
                <Scrollbars autoHide autoHideTimeout={500} autoHideDuration={200}>

                    <Table {...getTableProps()}>
                        <Thead>

                            {headerGroups.map(headerGroup => (
                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <Th
                                            fontSize=".8rem"

                                            {...column.getHeaderProps()}>{column.render('Header')}
                                            <div>{column.canFilter ? column.render("Filter") : null}</div>


                                        </Th>

                                    ))}
                                </Tr>
                            ))}
                        </Thead>
                        <Tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (

                                    <Tr {...row.getRowProps()}
                                        cursor="pointer"
                                        _hover={{ background: '#0000000a' }}>
                                        {row.cells.map(cell => {
                                            return <Td
                                                fontSize=".8rem"
                                                {...cell.getCellProps()} onClick={() => console.info(row.values)}>{cell.render('Cell')}</Td>
                                        })}
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </Scrollbars>
            </Box>
        </Flex>
    )
}

export default TopSellingTable;