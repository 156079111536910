import React from 'react';
import {
    Box, Flex, Text, Select, FormLabel, FormControl, Input, InputGroup, InputRightAddon, Modal, ModalOverlay, ModalContent, useDisclosure
} from '@chakra-ui/react';
import useDomain from '../../service/DomainHook';
import { SketchPicker } from 'react-color';


function Cities({ pageSlider, register, pageSliderStyle, setPageSliderStyle }) {
    const role = localStorage.getItem('role');
    const [hasSubDomain] = useDomain();

    const { isOpen: isOpenPageSlider, onOpen: onOpenPageSlider, onClose: onClosePageSlider } = useDisclosure()

    return (
        <Box bg="white" rounded="md" boxShadow="lg" p={{ base: '10px', md: '30px' }} mt="3rem">
            <Text as="h1" fontSize="25px" mb="20px">
                {role === 'SUPERADMIN' ? 'Franchisee' : 'Business'} Section
            </Text>
            <Flex mb="20px">
                <FormControl>
                    <FormLabel fontWeight="600"> Title </FormLabel>
                    <Input
                        placeholder="Title"
                        variant="flushed"
                        name="section_title"
                        ref={register()}
                    />
                </FormControl>
            </Flex>
            <Flex flexWrap="wrap" mb="20px">
                <Text mr="20px" mt={{ base: '0px', md: '10px', lg: '10px' }} fontWeight="600" w='180px'>
                    Select Number of Items
                </Text>
                <Select
                    // placeholder="Select option"
                    w="155px"
                    name="city_items"
                    ref={register()}>

                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>

                </Select>
            </Flex>
            {

                hasSubDomain ?
                    <Flex flexWrap="wrap" mb="20px">
                        <Text mr="20px" mt={{ base: '0px', md: '10px', lg: '10px' }} fontWeight="600" w='180px'>
                            Select Business Type
                        </Text>
                        <Select
                            // placeholder="Select option"
                            w="155px"
                            // ml={4}
                            name="business_type"
                            ref={register()}>

                            <option value="featured">Featured</option>
                            <option value="all">All</option>


                        </Select>
                    </Flex>
                    : null
            }

            <Flex flexWrap="wrap" mb="20px">
                <Text mr="20px" mt={{ base: '0px', md: '10px', lg: '10px' }} fontWeight="600" w='180px'>
                    Background Color
                </Text>
                <InputGroup flex="1 1" size="sm">
                    <Input
                        w="155px"
                        size="sm"
                        name="business_bg"
                        ref={register()}
                        value={pageSliderStyle?.style || pageSlider?.style}
                        onChange={(e => setPageSliderStyle({ ...pageSliderStyle, style: e.target.value }))}
                    />
                    <InputRightAddon roundedRight="8px" cursor="pointer" bg={pageSliderStyle.style || pageSlider?.style?.backgroundColor} onClick={onOpenPageSlider} />
                </InputGroup>
            </Flex>

            <Modal
                isCentered
                onClose={onClosePageSlider}
                isOpen={isOpenPageSlider}
                motionPreset="slideInBottom"
                size="xs">

                <ModalOverlay />
                <ModalContent>
                    <SketchPicker
                        width="100%"
                        color={pageSliderStyle.style || pageSlider?.style?.backgroundColor}
                        onChange={(color) => setPageSliderStyle({ ...pageSliderStyle, style: color.hex })}
                    />
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default Cities;