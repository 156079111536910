import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Center, Spinner, Link, Button, Stack, Icon } from '@chakra-ui/react';
import ProtectedRoute from './Auth/ProtectedRoute';

import MainLanding from './web/Landing/mainLanding';
import DashboardRouter from './admin/DashboardRouter';
import Error404 from './web/Error404';
import AuthWarning from './Auth/AuthWarning';

import BlogList from './web/Blog/BlogList';
import BlogDetails from './web/Blog/BlogDetails';
import ProductDetails from './web/Shop/ProductDetails';
import Category from './web/Shop/Category';
import Cart from './web/Shop/Cart';
import Checkout from './web/Shop/Checkout';
import ForgetPassword from './components/ForgetPassword';
import ResetPassword from './components/ResetPassword';
import axios from 'axios';
import { config } from './config';
import TagBlogList from './web/Blog/tagSearchBlog/TagBlogList';
import useDomain from './service/DomainHook';
import DomainUtils from './service/utils/DomainUtils';
// import Navigation from "./components/navigation/Navigaiton";
import Footer from './components/footer/Footer';
import productByTag from './web/Shop/ProductByTag';
import ProductSearch from './web/Shop/ProductSearch';
import ProductFilter from './web/Shop/ProductFilter/ProductFilter';
import TawktoConfig from './chat/TawktoConfig';
import ManyChatConfig from './chat/ManyChatConfig';
import ShopPage from './web/Shop/ShopPage';
import { ThemeContext } from './context/ThemeContext';
import { FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';
import PageBySlug from './web/Page/PageBySlug';
import MainNavbar from './components/navigation/MainNavbar';
import StripeWrapper from './web/Shop/Checkout';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';
import Verification from './verification';

function App() {
  const [hasSubDomain] = useDomain();

  const [state, dispatch] = useContext(ThemeContext);

  const baseURL = `${config.baseUrl}/api/v1`;
  const location = useLocation();
  const history = useHistory();

  const [themeUpdated, setThemeUpdated] = useState(false);
  const [gscContent, setGscContent] = useState('');
  const [googleAdSenseCode, setGoogleAdSenseCode] = useState('');
  const [pvContent, setPvContent] = useState('');
  const [inValidDomain, setInValidDomain] = useState(false);
  const [loader, setLoader] = useState(true);
  const [previewFavIcon, setPreviewFavIcon] = useState('');

  useEffect(() => {
    history.listen((location) => {
      // ReactGA.set({ page: location.pathname });
      // ReactGA.pageview(location.pathname);
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
      // console.log('ReactGA:::', ReactGA);
      // console.log('TagManager:::', TagManager);
    });
  }, [location]);

  useEffect(async () => {
    let api = `/googleAnalytics?adminType=SA&limit=1`;
    if (hasSubDomain) {
      const [franchisee] = await DomainUtils();
      api = `/googleAnalytics?adminType=CA&franchiseeId=${franchisee}&limit=1`;
    }
    axios({
      url: api,
      method: 'get',
      baseURL: baseURL,
    })
      .then((res) => {
        //console.log(res.data?.data[0]);
        if (res.data?.data.length > 0) {
          res.data?.data[0].ga4CodeActive &&
            ReactGA.initialize(res.data?.data[0]?.ga4Code, { debug: true });

          res.data?.data[0].gtmActive &&
            TagManager.initialize({
              gtmId: res.data?.data[0]?.gtmCode,
            });
        }
      })
      .catch((err) => console.log(err));
  });

  // useEffect(() => ReactGA.initialize('G-YTLR96L01D', { debug: true }), []);
  useEffect(() => TawktoConfig(), []);
  useEffect(() => ManyChatConfig(), []);

  // Set theme to localhost
  useEffect(async () => {
    let api = `/globalSettings?adminType=SA&limit=1&sort=createdAt&fields=theme,fontFamily,fontSize,fontWeight,favIcon,pageHeaderStyle`;
    if (hasSubDomain) {
      const [franchisee] = await DomainUtils();
      api = `/globalSettings?adminType=CA&franchiseeId=${franchisee}&sort=createdAt&limit=1&fields=theme,shopLayout,pReview,fontFamily,fontSize,fontWeight,favIcon,pageHeaderStyle`;
    }

    axios({ url: api, method: 'get', baseURL: baseURL })
      .then((res) => {
        let data = res.data.data;
        if (data.length === 0) {
          localStorage.setItem('theme', JSON.stringify({ color: state.color }));
        } else {
          data = data[0];
          setPreviewFavIcon(data?.favIcon);
          let payload = {
            color: state.color,
            fonts: {
              family: data.fontFamily,
              size: data.fontSize ? data.fontSize : state.fonts.size,
              weight: data.fontWeight ? data.fontWeight : state.fonts.weight,
            },
          };

          if (data?.theme?.color) {
            localStorage.setItem('theme', JSON.stringify(data.theme));
            payload = {
              ...payload,
              color: data.theme.color,
            };
          } else {
            localStorage.setItem(
              'theme',
              JSON.stringify({ color: state.color })
            );
          }
          localStorage.setItem('shop', data.shopLayout);
          localStorage.setItem('pReview', data.pReview);
          localStorage.setItem(
            'pageHeaderStyle',
            JSON.stringify(data.pageHeaderStyle)
          );

          dispatch({
            type: 'THEME_CHANGE',
            payload,
          });
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(async () => {
    let googleSearchConsoleContent = localStorage.getItem(
      'googleSearchConsoleContent'
    );
    let googleAdSense = localStorage.getItem('googleAdSense');
    let pinterestVerificationContent = localStorage.getItem(
      'pinterestVerificationContent'
    );

    // Google Search Console
    if (!googleSearchConsoleContent) {
      let api = `/googleSearchConsole?adminType=SA&limit=1`;
      if (hasSubDomain) {
        const [franchisee] = await DomainUtils();
        api = `/googleSearchConsole?adminType=CA&franchiseeId=${franchisee}&limit=1`;
      }
      axios({
        url: api,
        method: 'get',
        baseURL: baseURL,
      })
        .then((res) => {
          if (res.data?.data.length > 0) {
            setGscContent(res.data.data[0].content);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setGscContent(googleSearchConsoleContent);
    }

    // Google Ad Sense
    if (!googleAdSense) {
      let api = `/googleAdSense?adminType=SA&limit=1`;
      if (hasSubDomain) {
        const [franchisee] = await DomainUtils();
        api = `/googleAdSense?adminType=CA&franchiseeId=${franchisee}&limit=1`;
      }
      axios({
        url: api,
        method: 'get',
        baseURL: baseURL,
      })
        .then((res) => {
          if (res.data?.data.length > 0) {
            setGoogleAdSenseCode(res.data.data[0]?.clientCode);

            // localStorage.setItem(res.data.data[0]?.clientCode);
            localStorage.setItem('googleAdSense', res.data.data[0]?.clientCode);

          }
        })
        .catch((err) => console.log(err));
    } else {
      setGoogleAdSenseCode(googleAdSense);
    }

    // if (!pinterestVerificationContent) {
    // 	let api = `/pinterestVerification?adminType=SA&limit=1`;
    // 	if (hasSubDomain) {
    // 		const [franchisee] = await DomainUtils();
    // 		api = `/pinterestVerification?adminType=CA&franchiseeId=${franchisee}&limit=1`;
    // 	}
    // 	axios({
    // 		url: api,
    // 		method: "get",
    // 		baseURL: baseURL,
    // 	})
    // 		.then((res) => {
    // 			if (res.data?.data.length > 0) {
    // 				setPvContent(res.data.data[0].content);
    // 			}
    // 		})
    // 		.catch((err) => console.log(err));
    // } else {
    // 	setPvContent(pinterestVerificationContent);
    // }
  }, []);

  useEffect(async () => {
    if (hasSubDomain) {
      const [franchisee] = await DomainUtils();
      setLoader(false);
      if (!franchisee) {
        setInValidDomain(true);
      }
    } else {
      setLoader(false);
    }
  }, []);

  const theme = JSON.parse(localStorage.getItem('theme'));

  if (hasSubDomain && inValidDomain) {
    return <Error404 />;
  } else {
    if (loader) {
      return (
        <Center h="100vh">
          <Spinner color="gray.600" size="lg" />
        </Center>
      );
    } else {
      return (
        <>
          <Helmet
            style={[
              {
                cssText: `
							
							a{
								color: ${theme?.color?.accent}
							}
						`,
              },
            ]}>
            {previewFavIcon && <link rel="icon" href={previewFavIcon} />}

            {gscContent && (
              <meta name="google-site-verification" content={gscContent} />
            )}
            {/* {
							googleAdSenseCode &&
							<script
								async
								src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${googleAdSenseCode}`}
								crossorigin="anonymous">
							</script>
						} */}

            {/* {pvContent && <meta name="p:domain_verify" content={pvContent} />} */}

            {/* 
						{`<style type="text/css>
							h1{
								color: "red"
							}
							h2{
								color: "blue"
							}
							h3{
								color: "green"
							}
							h4{
								color: "orange"
							}
						</style>`} */}
          </Helmet>

          {/* {location.pathname.split("/")[1] !== "admin" && <Navigation />} */}
          {location.pathname.split('/')[1] !== 'admin' && <MainNavbar />}
          <main>
            <Switch>
              <Route exact path="/" component={MainLanding} />
              <Route exact path="/blog" component={BlogList} />
              <ProtectedRoute path="/admin" component={DashboardRouter} />
              <Route path="/warning" component={AuthWarning} />
              <Route path="/forget-password" component={ForgetPassword} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/blog/tag/:id" component={TagBlogList} />
              <Route path="/blog/:slug" component={BlogDetails} />
              <Route path="/verification/status" component={Verification} />
              {/* <Route path="/:slug" component={BlogDetails} /> */}
              {/* <Route path="/page/:pageRoute" component={PageComponent} /> */}
              <Route path="/page-content/:pageSlug" component={PageBySlug} />

              {hasSubDomain ? (
                <Switch>
                  <Route exact path="/shop" component={ShopPage} />
                  <Route exact path="/cart" component={Cart} />
                  <Route exact path="/checkout" component={StripeWrapper} />
                  <Route exact path="/shop/search" component={ProductSearch} />
                  <Route exact path="/shop/filter" component={ProductFilter} />
                  <Route exact path="/shop/tag/:id" component={productByTag} />
                  <Route
                    exact
                    path="/shop/product/:slug"
                    component={ProductDetails}
                  />
                  <Route
                    exact
                    path="/product/:slug"
                    component={ProductDetails}
                  />
                  <Route
                    exact
                    path="/shop/:sourceType/:slug"
                    component={Category}
                  />
                  <Route exact path="/*" component={Error404} />
                  <Route exact path="page/*" component={Error404} />
                </Switch>
              ) : null}

              <Route exact path="/*" component={Error404} />
            </Switch>
          </main>

          {location.pathname.split('/')[1] !== 'admin' && (
            <>
              <Footer />
              <Stack
                id="manychatBtnContainer"
                position="fixed"
                right="10px"
                bottom="10px"
                display="none">
                <Button
                  visibility="hidden"
                  id="manychatBtnWhatsapp"
                  as={Link}
                  colorScheme="green"
                  href="">
                  <Icon as={FaWhatsapp} />
                </Button>
                <Button
                  visibility="hidden"
                  id="manychatBtnTelegram"
                  as={Link}
                  colorScheme="blue"
                  href="#">
                  <Icon as={FaTelegramPlane} />
                </Button>
              </Stack>
            </>
          )}
        </>
      );
    }
  }
}

export default App;
