import { config } from '../config';

async function ManyChatConfig() {
   const franchiseeId = localStorage.getItem('franchisee') || null;
   let api = franchiseeId
      ? `${config.baseUrl}/api/v1/chatConfig?adminType=CA&franchiseeId=${franchiseeId}&limit=1&active=true`
      : `${config.baseUrl}/api/v1/chatConfig?adminType=SA&limit=1&active=true`;

   fetch(api, {
      method: 'get',
      headers: { 'Content-Type': 'application/json' }
   })
      .then(response => response.json())
      .then(res => {
         if (res.data[0]) {
            const { chatType, manyChatDetails } = res.data[0];
            if (chatType === 'manychat') {

               let whatsappBtn = document.getElementById("manychatBtnWhatsapp");
               whatsappBtn.setAttribute("href", "#");
               whatsappBtn.style.visibility = "hidden";
               let telegramBtn = document.getElementById("manychatBtnTelegram");
               telegramBtn.setAttribute("href", "#");
               telegramBtn.style.visibility = "hidden";

               manyChatDetails.forEach(channel => {
                  if (channel.serviceType === 'FACEBOOKMESSENGER') {
                     let s1 = document.createElement("script");
                     let s2 = document.createElement("script");
                     let s0 = document.getElementsByTagName("script")[0];

                     // s1.async=true; 
                     s1.src = `//widget.manychat.com/${channel.serviceCode}.js`;
                     s1.defer = "defer";
                     // s1.setAttribute('crossorigin','*'); 

                     s2.async = true;
                     s2.src = `https://mccdn.me/assets/js/widget.js`;
                     s2.defer = "defer";
                     s2.setAttribute('crossorigin', '*');

                     s0.parentNode.insertBefore(s1, s0);
                     // s0.parentNode.insertBefore(s2,s0); 
                  }
                  if (channel.serviceType === 'WHATSAPP' && channel.serviceCode !== '') {
                     let chatBtns = document.getElementById("manychatBtnContainer");
                     chatBtns.style.display = "flex";
                     let whatsappBtn = document.getElementById("manychatBtnWhatsapp");
                     whatsappBtn.style.visibility = "visible";
                     whatsappBtn.setAttribute("href", channel.serviceCode);
                     whatsappBtn.setAttribute("target", "_black");
                  }
                  if (channel.serviceType === 'TELEGRAM' && channel.serviceCode !== '') {
                     let chatBtns = document.getElementById("manychatBtnContainer");
                     chatBtns.style.display = "flex";
                     let telegramBtn = document.getElementById("manychatBtnTelegram");
                     telegramBtn.style.visibility = "visible";
                     telegramBtn.setAttribute("href", channel.serviceCode);
                     telegramBtn.setAttribute("target", "_black");
                  }
               })
            } else {
               let chatBtns = document.getElementById("manychatBtnContainer");
               chatBtns.style.display = "none";

               let whatsappBtn = document.getElementById("manychatBtnWhatsapp");
               whatsappBtn.setAttribute("href", "#");
               whatsappBtn.style.visibility = "hidden";

               let telegramBtn = document.getElementById("manychatBtnTelegram");
               telegramBtn.setAttribute("href", "#");
               telegramBtn.style.visibility = "hidden";
            }
         }
      })
      .catch((error) => console.error('Error:', error));
};

export default ManyChatConfig; 