import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import LoginForm from '../LoginForm';

function LoginModal(props) { 
    const { isOpen, onClose } = props;

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered> 
            <ModalOverlay />
            <ModalContent>
                <ModalHeader as="h2">Admin Sign In</ModalHeader>
                <ModalCloseButton />

                <ModalBody> 
                    <LoginForm onClose={onClose} /> 
                </ModalBody> 
            </ModalContent>
        </Modal>
    )
}

export default LoginModal
