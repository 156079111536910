import { config } from '../../../config'
import axios from 'axios';

export const uploadImage = async (data, token) => {
    const response = {
        success: false,
        url: ''
    }
    try {
        const responseData = await axios.request({
            url: '/posts/uploadImage',
            method: 'POST',
            baseURL: `${config.baseUrl}/api/v1`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }

        })

        response.success = true
        response.url = responseData.data.url

    } catch (error) {
        response.success = false
        response.url = ''
    }

    return response;
};