import { useLocation } from 'react-router-dom'
import './LandingHeader.css';
import { Box, Text } from '@chakra-ui/layout';

const LandingHeader = (props) => { 
    const location = useLocation() 
    const path = location.pathname.split('/')
    const bg = { 
        backgroundImage: props.image ? `url(${props.image})` : `none`,
    } 
    const { title } = props; 

    return (
        <header className="lan-main-header" style={bg}>
            <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
                <Box 
                    color={props.style?.fontColor ? props.style?.fontColor : 'inherit'} 
                    fontSize={props.style?.fontSize ? `${props.style?.fontSize}px` : '60px'} 
                    fontWeight={props.style?.fontWeight ? props.style?.fontWeight : '700'} 
                    lineHeight={props.style?.fontSize ? `${props.style?.fontSize}px` : '60px'} 
                    fontFamily={props.style?.fontFamily ? `${props.style?.fontFamily}` : 'inherit'}
                    w={{ base: "360px", sm:"400px", md:"500px", lg:"800px" }}
                    textAlign="center"> 
                    { 
                        path[1] !== 'page' && 
                        <Text 
                            p={2}
                            rounded={4}
                            d="inline-block" 
                            textAlign="center"
                            color={props.style?.fontColor ? props.style?.fontColor : 'inherit'} 
                        > 
                            {title} 
                        </Text> 
                    } 
                </Box> 
            </Box> 
        </header> 
    ); 
} 

export default LandingHeader;