import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
    Box, Stack, Button,
    Input, FormControl, FormLabel,
    InputGroup, InputRightElement,
    FormErrorMessage, Text
} from "@chakra-ui/react"
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { config } from "../config";
import axios from 'axios';
import Auth from '../Auth/Auth';
import useDomain from '../service/DomainHook'
import { getFranchiseeNew, getSubDomain } from '../service/Franchisee'


const LoginForm = ({ onClose }) => {
    const theme = JSON.parse(localStorage.getItem('theme'));
    const history = useHistory()
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const urlPath = window?.location?.host;



    const { register, handleSubmit, errors } = useForm({
        mode: 'onTouched',
        shouldFocusError: false
    })

    const [hasSubDomain] = useDomain();

    const { baseUrl } = config;


    const submitHandler = async (data) => {
        const subDomain = getSubDomain(urlPath);

        const { franchisee: fid } = await getFranchiseeNew(subDomain);
        if (subDomain) {
            data.subDomain = subDomain;
        }
        setErrorMessage('');
        data.hasSubDomain = hasSubDomain;
        data.franchisee = fid ? fid : null;

        axios.post(`${baseUrl}/api/v1/users/login`, data)
            .then(res => {
                let token = res.data.token;
                let role = res.data.role;
                let email = res.data.email;
                let franchiseeId = res.data.franchiseeId ? res.data.franchiseeId : null;

                localStorage.setItem('email', email);
                localStorage.setItem('token', token);
                localStorage.setItem('role', role);
                localStorage.setItem('franchiseeId', franchiseeId);
                localStorage.setItem('authorId', res.data.id);
                localStorage.setItem('name', res.data.name);

                Auth.signin(() => {
                    history.push('/admin');
                })
            })
            .catch((err) => setErrorMessage(err?.response?.data?.message))
    }

    return (
        <Box w="100%" pb={5}>
            <form onSubmit={handleSubmit(submitHandler)}>
                <Stack spacing={5}>
                    <FormControl isInvalid={errors.email}>
                        <FormLabel color="black">Email Address</FormLabel>
                        <Input
                            borderColor="gray.300" color="gray.500"
                            type="email"
                            name="email"
                            ref={register({
                                required: 'Email is required',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address"
                                }
                            })}
                        />
                        <FormErrorMessage mb="20px">{errors.email?.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.password}>
                        <FormLabel color="black">Password</FormLabel>
                        <InputGroup>
                            <Input
                                type={passwordVisible ? 'text' : 'password'}
                                borderColor="gray.300" color="gray.500"
                                name="password" autoComplete="off"
                                ref={register({
                                    required: 'password is required'
                                })}
                            />
                            <InputRightElement cursor="pointer">
                                {
                                    passwordVisible ?
                                        <ViewOffIcon w={4} h={4} onClick={() => setPasswordVisible(!passwordVisible)} />
                                        :
                                        <ViewIcon w={4} h={4} onClick={() => setPasswordVisible(!passwordVisible)} />
                                }
                            </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage mb="10px">{errors.password?.message}</FormErrorMessage>
                    </FormControl>

                    {
                        errorMessage &&
                        <Text
                            m={0} p={0} mt="5px !important" as="h6"
                            color="red.500" fontSize="0.7rem" cursor="pointer">
                            {errorMessage}
                        </Text>
                    }

                    <Text
                        m={0} p={0} mt="5px !important" as="h6"
                        color="teal.500" fontSize="0.7rem" cursor="pointer"
                        onClick={() => {
                            onClose(false)
                            history.push('/forget-password')
                        }}>
                        Forgot your password?
                    </Text>

                    <Button
                        type="submit"
                        bg={`rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 1'})`}
                        color="white" border={`1px solid ${theme?.color?.accent}`}
                        _hover={{
                            bg: `rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 0.95'})`
                        }}>
                        Login
                    </Button>
                </Stack>
            </form>
        </Box>
    )
}

export default LoginForm; 