import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form'
import {
   Container, Box, Flex, Button, useToast, Checkbox,
   FormControl, FormLabel, FormErrorMessage, Textarea,
   SimpleGrid
} from '@chakra-ui/react'
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb'
import { config } from '../../config'
import axios from 'axios';

function GoogleAdSense() { 
   const toast = useToast(); 
   const baseURL = `${config.baseUrl}/api/v1`; 

   const { register, handleSubmit, errors, control, reset } = useForm({ 
      mode: 'onTouched', 
      shouldFocusError: false 
   }) 

   const [hasList, setHasList] = useState(false);  

   useEffect(() => { 
      const role = localStorage.getItem('role'); 
      const token = localStorage.getItem('token'); 
      const franchiseeId = localStorage.getItem('franchiseeId'); 

      let api = `/googleAdSense?adminType=SA&limit=1`; 
      if (role === 'CITYADMIN') { 
         api = `/googleAdSense?adminType=CA&franchiseeId=${franchiseeId}&limit=1`
      } 

      axios({ 
         url: api, 
         method: 'get', 
         baseURL: baseURL, 
         headers: { 'Authorization': `Bearer ${token}` } 
      }) 
      .then(({ data }) => { 
         if (data.data.length > 0) { 
            setHasList(true); 
            reset(data.data[0]); 
         } 
      }) 
      .catch(err => console.log(err)) 
   }, []) 


   const submitHandler = (data) => { 
      const role = localStorage.getItem('role'); 
      const token = localStorage.getItem('token'); 
      const franchiseeId = localStorage.getItem('franchiseeId'); 

      data.adminType = 'SA'; 
      if (role === 'CITYADMIN') { 
         data.adminType = 'CA'; 
         data.franchiseeId = franchiseeId; 
      } 

      axios({
         url: 'googleAdSense',
         method: 'post',
         baseURL: baseURL,
         headers: { 'Authorization': `Bearer ${token}` },
         data: data
      })
      .then((res) => { 
         toast({ 
            title: `Google-adsense code ${hasList ? 'updated' : 'created'} successfully.`,
            status: "success", 
            duration: 4000, 
            isClosable: true
         });  

         setHasList(true); 
         localStorage.setItem('googleAdSense', data?.clientCode); 
      }) 
      .catch(err => { 
         toast({ 
            title: 'Error Occured', 
            description: err.response.data.message, 
            status: "error", 
            duration: 4000, 
            isClosable: true 
         }) 
      }) 
   } 


   return ( 
      <Container maxWidth={1100} centerContent mt={6} > 
         <Box w="100%" bg="white" rounded="lg" boxShadow="lg" px={4}> 
            <Flex py={4}> 
               <Breadcrumb pathname="Google AdSense" /> 
            </Flex> 

            <form onSubmit={handleSubmit(submitHandler)}> 
               {/* <Flex>  */} 
               <SimpleGrid 
                  columns={{ base: 1, sm: 1, md: 2, lg: 2 }} 
                  spacing="15px" mb={5}> 
                  <FormControl mb="15px" isInvalid={errors.clientCode}> 
                     <FormLabel as={'p'} fontWeight="semibold" m={0}>Client Code</FormLabel> 
                     <Textarea 
                        ref={register({ required: 'Client code is required' })} 
                        variant="flushed" borderColor="gray.300" size="sm" 
                        name="clientCode" type="text" /> 
                     <FormErrorMessage mb="20px"> 
                        {errors?.clientCode?.message} 
                     </FormErrorMessage> 
                  </FormControl> 

                  <FormControl> 
                     <Flex> 
                        <FormLabel as={'p'} fontWeight="semibold" m={0} mr="10px">Active</FormLabel> 
                        <Controller 
                           control={control} 
                           name="active" 
                           defaultValue={true} 
                           render={props => 
                              <Checkbox 
                                 onChange={e => props.onChange(e.target.checked)} 
                                 isChecked={props.value} 
                              /> 
                           } 
                        /> 
                     </Flex> 
                  </FormControl> 
               </SimpleGrid> 

               <Button type="submit" colorScheme="teal" variant="outline" size="sm" mb={4}> 
                  Save 
               </Button> 
            </form> 
         </Box> 
      </Container> 
   ) 
} 

export default GoogleAdSense; 