import React from 'react'; 
import { useParams } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import BlogListFetchData from '../BlogListFetchData';

function TagBlogList() { 
    const params = useParams(); 
    const { id } = params; 

    return ( 
        <Box> 
            <BlogListFetchData 
                fetchAllUrl="/posts?tags=" 
                id={id} /> 
        </Box> 
    ) 
} 

export default TagBlogList; 