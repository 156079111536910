import { useState, useEffect, useRef } from 'react';
import {
    Box, Text, Table, Thead, Tbody,
    Tr, Th, Td, chakra, Button, Input,
    NumberInput, NumberInputField, Badge,
    NumberInputStepper, NumberIncrementStepper,
    NumberDecrementStepper, Flex, Select
} from '@chakra-ui/react'
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons"

import { useTable, useSortBy, usePagination, useGlobalFilter, useAsyncDebounce } from "react-table"
import { useRouteMatch, useHistory } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'
import useDomain from '../../service/DomainHook';

// Search component 
function GlobalSearch({ globalFilter, setGlobalFilter }) {
    const [value, setValue] = useState(globalFilter)

    const onChange = useAsyncDebounce(value => {
        // Set undefined to remove the filter entirely 
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <Box my={5}>
            <Text fontWeight="600"> Search </Text>
            <Input
                type="text"
                maxW="250px"
                variant="flushed"
                placeholder="Search"
                value={value || ''}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
        </Box>
    )
}



function FranchiseeTable({
    // props 
    data,
    columns,
    fetchData,
    pageCount: controlledPageCount,
    sortOn,
    defaultPageSize,
    tableHeightInPage,
    selectNoOfRows,
    componentType
}) {
    const skipPageResetRef = useRef()
    const [hasSubDomain] = useDomain();

    // const columnList = useMemo(() => columns, []) 
    // const dataList = useMemo(() => data, []) 

    useEffect(() => {
        // After the table has updated, always remove the flag 
        skipPageResetRef.current = false
    })

    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: defaultPageSize },
        manualPagination: true,
        pageCount: controlledPageCount,
        autoResetPage: false,
        autoResetGlobalFilter: false,

        autoResetFilters: !skipPageResetRef.current,
        autoResetExpanded: !skipPageResetRef.current,
        autoResetGroupBy: !skipPageResetRef.current,
        autoResetSelectedRows: !skipPageResetRef.current,
        autoResetSortBy: !skipPageResetRef.current,
        autoResetRowState: !skipPageResetRef.current,
        // !skipPageResetRef.current
    },
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        prepareRow,
        pageCount,
        state: { pageIndex, pageSize, globalFilter },
        setPageSize,
        gotoPage,
        setGlobalFilter
    } = tableInstance;


    useEffect(() => {
        fetchData({ pageIndex, pageSize, globalFilter, skipPageResetRef })
    }, [fetchData, pageIndex, pageSize, globalFilter])

    const history = useHistory();
    let { url } = useRouteMatch();

    if (url.endsWith('/')) {
        url = url.slice(0, -1)
    }


    return (
        <>
            {/* Search & List */}
            <>
                {
                    componentType !== 'dashboard' ?
                        <GlobalSearch
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                        : null
                }

                <Box
                    overflow="auto"
                >

                    <Table {...getTableProps()}>
                        <Thead>
                            {
                                headerGroups?.map(headerGroup => (
                                    <Tr {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            headerGroup.headers?.map(column => (
                                                <Th
                                                    {
                                                    ...column.getHeaderProps(
                                                        sortOn.includes(column.Header)
                                                        && column.getSortByToggleProps()
                                                    )
                                                    }
                                                    fontSize=".8rem"
                                                    sx={{ width: column.Header === 'ID' && { base: '', sm: '', md: '14%', lg: '9%' } }}
                                                >

                                                    {/* Display each cell header */}
                                                    {column.render('Header')}

                                                    {/* Display icons for sorting */}
                                                    <chakra.span pl="10px">
                                                        {
                                                            column.isSorted
                                                                ?
                                                                (
                                                                    column.isSortedDesc
                                                                        ? <TriangleDownIcon aria-label="sorted descending" />
                                                                        : <TriangleUpIcon aria-label="sorted ascending" />
                                                                )
                                                                : null
                                                        }
                                                    </chakra.span>
                                                </Th>
                                            ))
                                        }
                                    </Tr>
                                ))
                            }
                        </Thead>

                        <Tbody {...getTableBodyProps()} >
                            {
                                page?.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <Tr
                                            {...row.getRowProps()}
                                            _hover={{ background: '#0000000a' }}
                                            cursor="pointer"
                                            onClick={
                                                () => { history.push(`${url}/edit/${row.original._id}`, { id: row.original._id }) }
                                            }>
                                            {
                                                row?.cells?.map(cell => {
                                                    if (typeof cell.render('Cell').props.cell.value === 'boolean') {
                                                        return (
                                                            <Td p={'10px'} fontSize=".9rem"  {...cell.getCellProps()}>
                                                                {cell.render('Cell').props.cell.value ?
                                                                    <Badge colorScheme="green">Published</Badge> :
                                                                    <Badge colorScheme="red">Not Published</Badge>}
                                                            </Td>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <Td {...cell.getCellProps()} p={'10px'} fontSize=".9rem" >
                                                                {cell.render('Cell')}
                                                            </Td>
                                                        )
                                                    }
                                                })
                                            }
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>

                </Box>
            </>


            {/* Pagination */}
            {
                componentType !== 'dashboard' ?
                    <Box>
                        <Flex mt="30px" flexWrap="wrap">
                            <Button
                                onClick={() => gotoPage(0)}
                                size="xs"
                                mr="10px" colorScheme="teal"
                                variant="outline"
                                disabled={!canPreviousPage}
                            >
                                {`<<`}
                            </Button>
                            <Button
                                onClick={() => previousPage()}
                                size="xs"
                                mr="10px" colorScheme="teal"
                                variant="outline"
                                disabled={!canPreviousPage}
                            >
                                {`<`}
                            </Button>
                            <Button
                                onClick={() => nextPage()}
                                size="xs"
                                mr="10px" colorScheme="teal"
                                variant="outline"
                                disabled={!canNextPage}
                            >
                                {`>`}
                            </Button>
                            <Button
                                onClick={() => gotoPage(Math.ceil(pageCount - 1))}
                                size="xs"
                                mr="10px" colorScheme="teal"
                                variant="outline"
                                disabled={!canNextPage}
                            >
                                {`>>`}
                            </Button>

                            <chakra.span mr="10px" fontWeight="600">
                                {Math.ceil(pageIndex + 1)} of {pageCount}
                            </chakra.span>

                            <chakra.span>
                                | Go to page &nbsp;
                            </chakra.span>
                            <NumberInput
                                defaultValue={pageIndex + 1}
                                min={1} max={pageCount}
                                onChange={(value) => gotoPage((+Math.ceil(value)) - 1)}
                                w="80px" size="sm" mr="20px"
                                value={pageIndex + 1}
                            >
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>

                            <Box>
                                <Select
                                    value={pageSize} variant="flushed" size="sm"
                                    w="110px" colorScheme="red"
                                    onChange={e => setPageSize(Number(e.target.value))}>
                                    {
                                        selectNoOfRows.map(pageSize => (
                                            <option key={pageSize} value={pageSize}>
                                                Show {pageSize}
                                            </option>
                                        ))
                                    }
                                </Select>
                            </Box>
                        </Flex>
                    </Box>
                    : null

            }
        </>
    )
}

export default FranchiseeTable;


