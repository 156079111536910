import React from 'react';
import { Container } from '@chakra-ui/react';
import styled from 'styled-components';
import DOMPurify from 'dompurify';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import LandingContact from '../Landing/landingContact';
import LandingContactForm from '../Landing/landingContactForm';
import PageEditorBody from './PageEditorBody';



function PageBodyWIthContactForm({
  content,
  hasContactForm,
  hasSubDomain,
  page,
}) {
  return (
    <Container
      maxW="1100px"
      bg="white"
      boxShadow={{ base: null, sm: null, md: 'lg', lg: 'lg' }}
      rounded="md"
      mt={['30px', '30px', '40px']}
      p={{ base: 3, sm: 3, md: 10, lg: 10 }}>
      {content.length > 0 &&
        content.map((cnt, index) => (
          <>
            {cnt && <PageEditorBody body={cnt}/>}
            {hasContactForm && index != content.length - 1 && (
              <LandingContactForm
                content={page.pageContact}
                hasSubDomain={hasSubDomain}
                globalSettings={page?.globalSettings}
              />
            )}
          </>
        ))}
    </Container>
  );
}

export default PageBodyWIthContactForm;
