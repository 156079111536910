import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import {
    Container, Box, Text, Input, SimpleGrid,
    InputGroup, InputRightElement, Center,
    Alert, AlertIcon, AlertTitle
} from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons'
import shop from '../../img/shop.jpg';
import axios from 'axios';
import { config } from '../../config'
import useDomain from '../../service/DomainHook';
import DomainUtils from '../../service/utils/DomainUtils';
import Product from './Product';
import WebPagination from '../../components/pagination/WebPagination';
import _debounce from 'lodash.debounce';


const style = {
    backgroundImage: `url(${shop})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover'
}

function ProductSearch(props) {
    const history = useHistory();

    const [searchWord, setSearchWord] = useState();
    const [products, setProducts] = useState([]);
    const [isEmpty, setIsEmpty] = useState(false);
    const { searchKey } = props.location.state;
    const input = useRef();
    const [hasSubDomain] = useDomain();
    const baseURL = `${config.baseUrl}/api/v1`;
    const franchisee = useRef(null);


    // --------------------- // 
    // *** Product fetch *** // 
    // --------------------- // 

    const limit = 10;
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(10);

    const setNewPage = (data) => {
        data.selected = data.selected + 1;
        setPage(data.selected)
    }

    // ---------------------- 
    // --------- END -------- 
    // ---------------------- 



    useEffect(async () => {
        input.current.focus();
        if (hasSubDomain) {
            const [franchiseeId] = await DomainUtils();
            franchisee.current = franchiseeId;
        }

        setSearchWord(searchKey);

        axios({
            // url:`/products?search=${searchKey}&franchiseeId=${franchisee.current}&page=${page}&limit=${limit}&publish=true&isGreeting=false&fields=images,name,priceType,price,minPrice,maxPrice,slug`,
            url: `/products?search=${searchKey}&franchiseeId=${franchisee.current}&page=${page}&limit=${limit}&publish=true&fields=images,name,priceType,price,minPrice,maxPrice,slug`,
            method: 'get',
            baseURL: baseURL
        })
            .then(response => {
                const { data: allProducts, result } = response.data;
                setProducts(allProducts);
                setTotal(result);
                allProducts.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
            })
            .catch(error => {
                console.log(error);
            });
    }, [page])



    const productSearch = (e) => {
        // setSearchWord(e.target.value);

        if (e.target.value !== '') {

            axios({
                // url:`/products?search=${e.target.value}&franchiseeId=${franchisee.current}&page=${page}&limit=${limit}&publish=true&isGreeting=false&fields=images,name,priceType,price,minPrice,maxPrice,slug`,
                url: `/products?search=${e.target.value}&franchiseeId=${franchisee.current}&page=${page}&limit=${limit}&publish=true&fields=images,name,priceType,price,minPrice,maxPrice,slug`,
                method: 'get',
                baseURL: baseURL
            })
                .then(response => {
                    const { data: allProducts, result } = response.data;
                    setProducts(allProducts);
                    setTotal(result);
                    allProducts.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            history.push(`/shop`);
        }
    };

    const debouncedProductSearch = useRef(_debounce(productSearch, 1000));

    const handleChange = (e) => {
        setSearchWord(e.target.value);

        debouncedProductSearch.current(e);
    };

    return (
        <Box>
            <Box h="350px" bg="teal.300" mt="55px" style={style}>
                <Center h="90%">
                    <Box w="100%">
                        <Center>
                            <Text color="white" fontSize="40px" fontWeight="600">
                                SHOP
                            </Text>
                        </Center>
                        <Center>
                            <InputGroup w={["90%", "90%", "80%", "50%"]}>
                                <Input ref={input} value={searchWord} type="text" bg="#FFF" onChange={handleChange} placeholder="Search product" />
                                <InputRightElement
                                    pointerEvents="none"
                                    children={<Search2Icon color="gray.300" />}
                                />
                            </InputGroup>
                        </Center>
                    </Box>
                </Center>
            </Box>

            <Container maxW={1140} mt="15px" py={4}>
                {
                    products.length > 0 &&
                    <Box>
                        <Text as="h3" mb="15px" fontSize="1.3em">Search results</Text>
                        <SimpleGrid
                            columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 4, '2xl': 5 }}
                            spacing="15px" mb={5}>
                            {
                                products.map((p, i) => <Product key={i} product={p} />)
                            }
                        </SimpleGrid>
                        {
                            total > limit &&
                            <Center mt="20px">
                                <WebPagination limit={limit} page={page} total={total} setNewPage={setNewPage} />
                            </Center>
                        }
                    </Box>
                }


                {
                    isEmpty &&
                    <Alert
                        status="info"
                        variant="subtle"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                        height="200px">
                        <AlertIcon boxSize="40px" mr={0} />
                        <AlertTitle mt={4} mb={1} fontSize="lg">
                            Product not found!
                        </AlertTitle>
                    </Alert>
                }
            </Container>
        </Box>
    )
}

export default ProductSearch; 