import React, { useState, useEffect } from 'react'; 
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import {
   Container, Box, Input, Button, SimpleGrid, Checkbox,
   FormControl, FormLabel, FormErrorMessage, useToast
} from '@chakra-ui/react';
import axios from 'axios';
import { config } from '../../config';
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';
import ReactSelectOptionMapper from '../../service/ReactSelect';


function Create() { 
   const { baseUrl } = config; 
   const franchiseeId = localStorage.getItem('franchiseeId'); 
   const toast = useToast(); 
   const history = useHistory(); 
   const { register, handleSubmit, errors, control, watch } = useForm({
      mode: 'onTouched',
      shouldFocusError: false
   }) 
   let min = watch('minDays'); 

   const submitHandler = (data) => { 
      const token = localStorage.getItem('token'); 

      data.franchiseeId = franchiseeId; 
      // data.businessId = data.business.value; 
      
      axios({ 
         method: 'post', 
         data: data, 
         baseURL: baseUrl, 
         url: '/api/v1/shippingMethods', 
         headers:  {'Authorization': `Bearer ${token}`} 
      }) 
      .then(res => { 
         toast({ 
            title: "Created Successfully.", 
            status: "success", 
            duration: 4000, 
            isClosable: true 
         }) 
         history.push(`/admin/shipping-method`); 
      }) 
      .catch(err => {
         toast({ 
            title: "Error occured", 
            description: err?.response?.data?.message, 
            status: "error", 
            duration: 4000, 
            isClosable: true 
         }) 
      }) 
   } 

   return (
      <Container maxWidth={1100} centerContent my={6}>
         <Box p={4} w="100%" bg="white" rounded="lg" boxShadow="lg">
            <Breadcrumb pathname="Shipping Methods" create={true} link={`/admin/shipping-method`} mb={5} />

            <form onSubmit={handleSubmit(submitHandler)}>
               <FormControl mb="15px" isInvalid={errors.name}>
                  <FormLabel as={'p'} fontWeight="semibold" m={0}> Name </FormLabel> 
                  <Input
                     ref={register({ required: 'Name is required' })}
                     variant="flushed" borderColor="gray.300" size="sm"
                     name="name" type="text" />
                  <FormErrorMessage mb="20px">
                     {errors?.name?.message}
                  </FormErrorMessage>
               </FormControl>

               <FormLabel as={'p'} fontWeight="semibold" m={0} pb={2}>Estimate days</FormLabel> 
               <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }} spacing="15px" mb={5}> 
                  <FormControl mb="15px" isInvalid={errors?.minDays}> 
                     <FormLabel as={'p'} fontWeight="semibold" m={0}>Min Day</FormLabel> 
                     <Input 
                        type="text" name="minDays" 
                        ref={register({ 
                           required: 'Minimum day is required', 
                           valueAsNumber: true,
                           min: { 
                              value: 1, 
                              message: 'Minimum 1 day required'
                           } 
                        })} 
                        variant="flushed" borderColor="gray.300" size="sm" 
                     /> 
                     <FormErrorMessage> 
                        {errors?.minDays?.message} 
                     </FormErrorMessage> 
                  </FormControl> 

                  <FormControl mb="15px" isInvalid={errors?.maxDays}> 
                     <FormLabel as={'p'} fontWeight="semibold" m={0}>Max Day</FormLabel> 
                     <Input 
                        ref={
                           register({ 
                              required: 'Maximum day is required', 
                              valueAsNumber: true,
                              validate: (value) => value > min || 'Maximum must be greater than minimum'
                           }) 
                        } 
                        variant="flushed" borderColor="gray.300" size="sm" 
                        name="maxDays" type="text" 
                     /> 
                     <FormErrorMessage> 
                        {errors?.maxDays?.message} 
                     </FormErrorMessage> 
                  </FormControl> 
               </SimpleGrid> 


               <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }} spacing="15px"> 
                  <FormControl mb="15px" isInvalid={errors.cost}> 
                     <FormLabel as={'p'} fontWeight="semibold" m={0}>Shipping Cost</FormLabel> 
                     <Input 
                        ref={register({ 
                           required: 'Shipping cost is required', 
                           valueAsNumber: true,
                        })} 
                        variant="flushed" borderColor="gray.300" size="sm" 
                        name="cost" type="text" /> 
                     <FormErrorMessage mb="20px"> 
                        {errors?.cost?.message} 
                     </FormErrorMessage> 
                  </FormControl> 
                  <FormControl as="fieldset"> 
                     <Controller 
                        control={control} 
                        name="isDefault" 
                        defaultValue={false} 
                        render={( 
                           { onChange, onBlur, value, name, ref }, 
                           { invalid, isTouched, isDirty } 
                        ) => { 
                           return ( 
                              <Checkbox 
                                 mb={5} 
                                 colorScheme="teal" 
                                 onChange={(e) => onChange(e.target.checked)} 
                                 isChecked={value}> 
                                 Default 
                              </Checkbox> 
                           ) 
                        }} 
                     /> 
                  </FormControl> 
               </SimpleGrid> 
               
               {/* <FormControl id="business" my={3} w={['100%', '100%', '50%', '50%']}>
                  <FormLabel>Business</FormLabel>
                  <Controller 
                     control={control}
                     name="business"
                     as={Select}
                     options={business}
                     defaultValue={[]} 
                  />
               </FormControl>  */} 

               <Button type="submit" colorScheme="teal" size="sm" w="100px" rounded={4}> 
                  Save 
               </Button> 
            </form>
         </Box>
      </Container>
   )
}

export default Create;
