import React, { createContext, useReducer } from 'react'
import { reducer } from './reducer'

const initialState = {
    color: {
        accent: "#319795",
        primary: "#2D3748",
        secondary: "#718096",
    },
    fonts: {
        family: "Poppins",
        size: '14',
        weight: '400'
    }
}

export const ThemeContext = createContext();

function ThemeContextProvider(props) {
    const [state, dispatch] =  useReducer(reducer, initialState);
    return (
        <ThemeContext.Provider value={[state, dispatch]}>
            {props.children}
        </ThemeContext.Provider>
    )
}

export default ThemeContextProvider