import axios from 'axios';
import React, {useState, useRef, useCallback} from 'react'
import {config} from '../../config'
import { Container, Box, Link,
 Flex, Spacer, Button
} from '@chakra-ui/react';
import { Link as RouteLink, useRouteMatch } from 'react-router-dom';
import PageTable from './PageTable'
import Breadcrumb from '../../components/breadCrumbs/Breadcrumb';

function PageList() { 
    const[pages, setPages] = useState([]);
    const [pageCount, setPageCount] = useState(0) 
    const fetchIdRef = useRef(0) 
    const baseURL = `${config.baseUrl}/api/v1`;

    const sortOn = ['Page Name', 'Page Title'] 

    let columns = [ 
        { 
            Header: 'Page Name', 
            accessor: 'pageName'
        }, 
        { 
            Header: 'Page Title', 
            accessor: 'pageTitle'
        } 
    ] 

    let { url } = useRouteMatch();

    const fetchData = useCallback(({ pageSize, pageIndex, globalFilter, skipPageResetRef }) => { 
        const fetchId = ++fetchIdRef.current 
        const fields = 'pageName,pageTitle'; 

        const franchiseeId = localStorage.getItem('franchiseeId'); 
        let getUrl = franchiseeId !== 'null' 
            ? `/pages?pageType=CAP&franchiseeId=${franchiseeId}` 
            : `/pages?pageType=SAP`; 
        
        if(globalFilter) { 
          // Filtering on name field
            axios({
                url: `${getUrl}&search=${globalFilter}`,
                method: 'get',
                baseURL: baseURL 
            }) 
            .then((res) => { 
                const {data: serverData, result: totalLength} = res.data 
                if (fetchId === fetchIdRef.current) { 
                    if(serverData.length >= 1) { 
                    skipPageResetRef.current = true 
                    setPages(serverData) 
                    setPageCount(Math.ceil(totalLength/pageSize)) 
                    } 
                } 
            }) 
            .catch(err => console.log(err, 'Data fetch error')) 
        } 
        else { 
          // Pagination 
            axios({ 
                url: `${getUrl}&page=${(pageIndex)+1}&limit=${pageSize}&fields=${fields}`, 
                method: 'get', 
                baseURL: baseURL 
            }) 
            .then((res) => { 
                const {data: serverData, result: totalLength} = res.data 
                if (fetchId === fetchIdRef.current) { 
                    skipPageResetRef.current = true 
                    setPages(serverData) 
                    setPageCount(Math.ceil(totalLength/pageSize)) 
                } 
            }) 
            .catch(err => console.log(err, 'Data fetch error')) 
        } 
    }, []) 



    return (
        <Container maxWidth={1100} centerContent  my={6}>
            <Box w="100%" bg="white" rounded="lg" boxShadow="lg">

                <Flex pt={4} px={4}>
                    <Breadcrumb pathname="Page" /> 
                    <Spacer />
                    <Box>
                        <Button colorScheme="teal" variant="outline" size="sm">
                            <Link color="blue.500" lineHeight="32px" as={RouteLink} to={`${url}/create`}>Create new</Link>
                        </Button>
                    </Box>
                </Flex>
                

                <PageTable 
                    data={pages} 
                    columns={columns} 
                    pageCount={pageCount} 
                    fetchData={fetchData} 
                    sortOn={sortOn} 
                    defaultPageSize={10} 
                    tableHeightInPage='58vh'  
                    selectNoOfRows={[5, 10, 20, 30, 50, 100]} 
                /> 

            </Box>

        </Container>
    )
}

export default PageList
