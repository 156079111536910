import React, { useEffect, useState, useRef, useContext } from 'react'
import {
    Container, Box, Image, Flex, Text, Tag,
    Stack, NumberInput, NumberInputField,
    NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, AspectRatio,
    Button, SimpleGrid, Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalFooter, ModalBody, ModalCloseButton, useDisclosure,
    Alert, AlertIcon, AlertTitle, useToast, TagLabel, background
} from '@chakra-ui/react'

import { InfoIcon, CloseIcon } from '@chakra-ui/icons'
import DOMPurify from 'dompurify';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../config';
import { handleProductAdd, ProductDescription } from './ShopService';
import ProductDetailSkeleton from './ProductDetailSkeleton';
import Product from './Product';
import ProductComment from './ProductComment'
import ProductAvgRating from './ProductAvgRating';
import { CartContext } from '../../context/CartContext';
import { getGoogleMapSource } from '../../service/utils/getGoogleMapSource';
import useDomain from '../../service/DomainHook';
import DomainUtils from '../../service/utils/DomainUtils';
import SingleProduct from './SingleProduct';
import MetaDecorator from '../../components/metaDecorator';

function ProductDetails() {
    const theme = JSON.parse(localStorage.getItem('theme'));
    const pReview = JSON.parse(localStorage.getItem('pReview'));
    const baseURL = `${config.baseUrl}/api/v1`;
    let quantity = useRef(1);
    const history = useHistory();
    const { slug } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [product, setProduct] = useState({});
    const [displayImage, setDisplayImage] = useState('')
    const [businessImage, setBusinessImage] = useState('')
    const [relatedProduct, setRelatedProduct] = useState([])
    const [loading, setLoading] = useState(true);
    const [price, setPrice] = useState(0);
    const [warn, setWarning] = useState(null);
    const [handlingCharge, setHandlingCharge] = useState(null);
    const [handleType, setHandleType] = useState(null);
    const [locationSource, setLocationSource] = useState(null);
    const [amount, setAmount] = useState(0);
    const [filterCategories, setFilterCategories] = useState([]);
    const [catTree, setCatTree] = useState([])

    const toast = useToast();

    const { 1: dispatch } = useContext(CartContext);
    const [hasSubDomain] = useDomain();
    const noImage = 'https://via.placeholder.com/350/000000/FFFFFF/?text=No Product Image'

    useEffect(async () => {
        //Get Handling Charge

        let franchisee = null;
        if (hasSubDomain) {
            const [franchiseeId] = await DomainUtils();
            franchisee = franchiseeId;
        }

        axios({ url: `/categories/hierarchy/${franchisee}`, method: 'get', baseURL: baseURL })
            .then(res => {
                setCatTree(res.data.category)
            })
            .catch(err => console.log(err))


        const fetchGlobalSettings = async () => {
            let url = '/globalSettings?adminType=SA';
            if (hasSubDomain) {
                const [franchiseeId] = await DomainUtils();
                url = `/globalSettings?adminType=CA&franchiseeId=${franchiseeId}`;
            }

            axios({
                url: url,
                method: 'get',
                baseURL: baseURL
            }).then((response) => {
                if (response.data.data[0].handleType === 'BY_ITEM') {
                    let fee = parseFloat(response.data.data[0].handlingCharge).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                    setHandlingCharge(fee);
                } else {
                    setHandlingCharge(0);
                }
                setHandleType(response.data.data[0].handleType)
            })
        }

        fetchGlobalSettings();

    }, [])


    const getProductInfo = async () => {
        window.scrollTo(0, 0);
        if (hasSubDomain) {
            const [franchiseeId] = await DomainUtils();
            axios({
                url: `/products/slug/${slug}/${franchiseeId}`,
                method: 'get',
                baseURL: baseURL
            })
                .then(response => {
                    let { data: productInfo } = response.data;
                    if (productInfo) {

                        setDisplayImage(productInfo.images?.links[productInfo.images?.defaultImage - 1]);
                        setBusinessImage(productInfo.businessId?.image);
                        setProduct(productInfo);
                        let productPrice = productInfo.priceType === "FIXED" ? productInfo.price : productInfo.minPrice;
                        setPrice(parseFloat(productPrice));
                        setLoading(false);
                        const tagId = productInfo.tags.map(tag => tag._id);

                        // Process location 
                        const locationSrc = getGoogleMapSource(productInfo.businessId.location);
                        setLocationSource(locationSrc);
                        if (tagId.length > 0) {
                            axios({
                                url: `/products/filter?fields=-details&limit=5`,
                                method: 'POST',
                                baseURL: baseURL,
                                data: {
                                    tags: tagId
                                }
                            })
                                .then(res => setRelatedProduct(res.data.data))
                                .catch(err => console.log(err))
                        }

                    }
                    else {
                        setProduct({})
                        setLoading(false);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    console.log(error.message);
                })
        }

    }

    useEffect(() => {
        getProductInfo();
    }, [slug])



    const handlePrice = (value) => {

        if (parseFloat(value) < parseFloat(product?.minPrice) || parseFloat(value) > parseFloat(product?.maxPrice)) {
            setWarning(`Price Range Must Between $${product?.minPrice} to $${product?.maxPrice}`);
            return;
        }

        if (parseFloat(value) >= parseFloat(product?.minPrice) || parseFloat(value) <= parseFloat(product?.maxPrice)) {
            setAmount(parseFloat(value));
            setWarning(null)
        }
        // else if(parseFloat(value) <= parseFloat(product?.maxPrice)){
        //     setAmount(parseFloat(value));
        //     setWarning(null)
        // }

        if (!value) {
            setWarning(null)
            setAmount(parseFloat(handlingCharge) - parseFloat(handlingCharge))

        }

    };

    useEffect(() => {
        setPrice(amount);
    }, [amount])

    const handleProductAddToCart = async () => {
        const response = await handleProductAdd(price, quantity, product);
        if (response.status === 'ok') {
            onOpen();
            dispatch({
                type: 'SET_CART',
                payload: response.itemCount
            })
        } else {
            toast({
                title: 'Failed',
                description: response.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
    };

    const modalCloseHandler = (e) => {
        let name = e.target.name;
        history.push(`/${name}`);
        onClose();
    };


    // *** Avg rating section data *** // 
    const [countComment, setCountComment] = useState(0);
    const [fractions, setFraction] = useState(0)
    const [reviewData, setReviewData] = useState([
        { iconColor: 'gray.500' },
        { iconColor: 'gray.500' },
        { iconColor: 'gray.500' },
        { iconColor: 'gray.500' },
        { iconColor: 'gray.500' }
    ])



    const newReviewCreatedHandler = () => {
        axios({
            url: `/productComments?productId=${product?._id}&fields=rating,-_id`,
            method: 'get',
            baseURL: baseURL
        })
            .then(res => {
                let sum = 0;
                for (let obj of res.data.data) {
                    sum = sum + obj.rating;
                }
                setCountComment(res.data.result);

                let avg = +(sum / res.data.result).toFixed(2);
                if (!avg) avg = 0;

                const fraction = (avg - Math.floor(avg)).toFixed(2);
                setFraction(+fraction);

                let data = Math.floor(avg);
                if (!data) data = 0;

                let reviewCopy = [
                    { iconColor: 'gray.500' },
                    { iconColor: 'gray.500' },
                    { iconColor: 'gray.500' },
                    { iconColor: 'gray.500' },
                    { iconColor: 'gray.500' }
                ];
                let i = 0;
                for (i = 0; i < data; i++) {
                    reviewCopy[i].iconColor = `rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 1'})`;
                }
                if (i === data && i <= 4 && i != 0) {
                    reviewCopy[i].iconColor = 'mix';
                }
                setReviewData(reviewCopy);
            })
            .catch(err => console.log(err))
    }

    const categoryTagHandlder = (category) => {
        let filterValue = JSON.parse(localStorage.getItem('filterValue'));
        filterValue.category = [category._id];
        filterValue.businesse = []
        filterValue.tag = []

        localStorage.setItem('filterValue', JSON.stringify(filterValue));

        history.push('/shop/filter', { category: category._id, tags: [], allBusiness: [], catTree });
    }

    const tagHandler = (tagData) => {
        let filterValue = JSON.parse(localStorage.getItem('filterValue'));
        filterValue.category = [];
        filterValue.businesse = []
        filterValue.tag = [tagData._id]

        localStorage.setItem('filterValue', JSON.stringify(filterValue));

        history.push('/shop/filter', { category: "", tags: product?.tags, allBusiness: [], catTree });
    }

    // *** END *** // 

    return (
        <>
            <Box pt="60px">

                <Container maxW={1140} mt="15px" py={4}>
                    {
                        loading ? (
                            <ProductDetailSkeleton />
                        ) : (
                            <>
                                {
                                    Object.keys(product).length > 0
                                        ?
                                        <>
                                            <MetaDecorator
                                                title={product?.name}
                                                description={product?.summary}
                                                keywords={product?.businessId?.name}
                                                featureImage={product?.images.links.length > 0 ? displayImage : noImage}
                                            />
                                            <Box w="100%">
                                                <Flex flexWrap="wrap">
                                                    <Box flexGrow="1" flexBasis={{ base: "100%", sm: "100%", md: "40%", lg: "40%" }} minHeight="280px">
                                                        <Image
                                                            w="500px"
                                                            h="400px"
                                                            objectFit="cover"
                                                            src={product?.images.links.length > 0 ? displayImage : noImage}
                                                            alt={product?.name}
                                                            border="1px solid #E0E0E0"
                                                            p={1}
                                                            rounded={2}
                                                        />
                                                        <Box mt={2}>
                                                            <SimpleGrid columns={{ base: 4, sm: 4, md: 3, lg: 4 }}
                                                                spacing="15px" mb={5}>
                                                                {
                                                                    product?.images?.links?.map((image, i) => (
                                                                        <Image
                                                                            key={i}
                                                                            boxSize="90px"
                                                                            objectFit="cover"
                                                                            src={image}
                                                                            // _hover={{transform: "rotate(-8deg)"}} 
                                                                            // transition="all 0.3s"
                                                                            border="1px solid #E0E0E0"
                                                                            p={1} cursor="pointer"
                                                                            rounded={2}
                                                                            onClick={() => setDisplayImage(image)}
                                                                        />
                                                                    ))
                                                                }
                                                            </SimpleGrid>

                                                            <Flex align="center" mr="10px" mt="20px" mb="20px">

                                                                {product?.tags.length >= 1 ? <Text fontSize='14px' mr={3} >Tags:</Text> : null}
                                                                {
                                                                    product?.tags?.map((tag, i) => (
                                                                        <Tag
                                                                            key={i} cursor="pointer"
                                                                            rounded={3} textTransform="uppercase"
                                                                            fontSize='14px' mr="10px"
                                                                            // bg="linear-gradient(to right, #BEE3F8 50%, #edf2f9 50%)"
                                                                            backgroundPosition="right bottom"
                                                                            backgroundSize="200% 100%" transition="all .5s ease-out"
                                                                            _hover={{
                                                                                backgroundPosition: 'left bottom',
                                                                                backgroundColor: theme?.color?.accent
                                                                            }}
                                                                            onClick={() => tagHandler(tag)}
                                                                        >
                                                                            {tag.name}
                                                                        </Tag>
                                                                    ))
                                                                }
                                                            </Flex>


                                                        </Box>
                                                    </Box>

                                                    <Box flexGrow="1" flexBasis="60%" pl={{ base: "0", sm: "0", md: "15px", lg: "20px" }}>
                                                        <Text as="h1" fontSize="1.3rem" fontWeight="600" color={theme?.color?.primary}>
                                                            {product?.name}
                                                        </Text>

                                                        {/* Avg Rating section */}
                                                        <Box mb="30px">
                                                            {
                                                                pReview &&
                                                                <ProductAvgRating
                                                                    countComment={countComment}
                                                                    setCountComment={setCountComment}
                                                                    fractions={fractions}
                                                                    setFraction={setFraction}
                                                                    reviewData={reviewData}
                                                                    setReviewData={setReviewData}
                                                                    productId={product?._id}
                                                                    newReviewCreatedHandler={newReviewCreatedHandler}
                                                                />
                                                            }
                                                        </Box>

                                                        <Box>
                                                            {
                                                                product?.priceType === "RANGED" ?
                                                                    <Stack direction="row" mb={2}>
                                                                        <Text fontSize="1rem" lineHeight={2} fontWeight="600">Choose amount $</Text>
                                                                        <NumberInput
                                                                            // defaultValue={product?.minPrice}
                                                                            onChange={handlePrice} min={product?.minPrice} max={product?.maxPrice} size="sm">
                                                                            <NumberInputField />
                                                                            <NumberInputStepper>
                                                                                {/* <NumberIncrementStepper />
                                                                                <NumberDecrementStepper /> */}
                                                                            </NumberInputStepper>
                                                                        </NumberInput>
                                                                        <Text>{warn}</Text>
                                                                    </Stack> : null
                                                            }
                                                        </Box>

                                                        <Stack direction="row" mb={2}>
                                                            <Text color={theme?.color?.primary} fontWeight="700">
                                                                {

                                                                    product?.priceType === "FIXED" ? `Price: $${product?.price.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
                                                                        : `Min: $${product?.minPrice.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
                                                                }
                                                            </Text>
                                                        </Stack>


                                                        <Text mb={3}>{product?.summary}</Text>
                                                        {
                                                            handleType && handleType === 'BY_ITEM' &&
                                                            <>
                                                                <Text fontSize="1.1rem" fontWeight="600" mb={3} color={theme?.color?.primary}>Handling Fee: {`$${handlingCharge}`}</Text>
                                                                <Text>
                                                                    A {`$${handlingCharge}`} handling fee helps us cover payment processing,
                                                                    marketing, website fees, and more.
                                                                </Text>
                                                            </>
                                                        }

                                                        <Box my={2} >
                                                            <Stack direction="row">
                                                                <Text fontSize="1.1rem" fontWeight="600" color={theme?.color?.primary}>Total</Text>
                                                                <Text fontSize="1.1rem" fontWeight="600" color={theme?.color?.primary}>
                                                                    {
                                                                        handleType && handleType === 'BY_ITEM' ?
                                                                            product?.priceType === "FIXED" ? `Price: $${(parseFloat(product?.price) + parseFloat(handlingCharge)).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
                                                                                : `$${(parseFloat(handlingCharge) + parseFloat(amount)).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
                                                                            : product?.priceType === "FIXED" ? `Price: $${parseFloat(product?.price)}`
                                                                                : `$${parseFloat(amount).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
                                                                    }
                                                                </Text>
                                                            </Stack>
                                                        </Box>

                                                        <Box>
                                                            <Flex align="center" gridGap={2}>
                                                                <Text fontSize="1.1rem" fontWeight="600" color={theme?.color?.primary}>Quantity:</Text>
                                                                <NumberInput defaultValue={1} min={1} onChange={(val) => quantity.current = parseInt(val)}>
                                                                    <NumberInputField />
                                                                    <NumberInputStepper>
                                                                        {/* <NumberIncrementStepper />
                                                                        <NumberDecrementStepper /> */}
                                                                    </NumberInputStepper>
                                                                </NumberInput>
                                                                {/* <Button onClick={handleProductProcess} colorScheme="teal">Buy Now</Button> */}
                                                                <Button
                                                                    onClick={handleProductAddToCart}
                                                                    bg={`rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 1'})`}
                                                                    color="white" border={`1px solid ${theme?.color?.accent}`}
                                                                    _hover={{
                                                                        bg: `rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 0.75'})`,
                                                                        // backgroundPosition: 'left bottom',
                                                                        // backgroundColor: theme?.color?.accent
                                                                    }}>
                                                                    Add To Cart
                                                                </Button>
                                                            </Flex>
                                                        </Box>

                                                    </Box>
                                                </Flex>
                                            </Box>





                                            <Box flexGrow="1" flexBasis={{ base: "100%", sm: "100%", md: "40%", lg: "40%" }} minHeight="280px">
                                                <Box>
                                                    <Flex width="100%" justifyContent="space-between" alignItems="end">
                                                        <Box >
                                                            <Box mt="30px">
                                                                <Text as="h4" fontWeight="600" fontSize="1.3rem" mb="5px">Product Description</Text>
                                                                <ProductDescription
                                                                    className="rich-editor"
                                                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product?.details) }}
                                                                />
                                                            </Box>

                                                            <Box mt="30px">
                                                                <Text as="h4" fontWeight="600" fontSize="1.3rem" mb="5px">About {product?.businessId?.name}</Text>
                                                                <ProductDescription
                                                                    className="rich-editor"
                                                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product?.businessId?.details) }}
                                                                />
                                                            </Box>

                                                            <Box mt="30px">
                                                                <Text fontWeight="600" fontSize="1.3rem" mt={3} color={theme?.color?.primary}>Where To Find Us</Text>
                                                                <Text fontSize="1.1rem" fontWeight="500" mb={1} color={theme?.color?.secondary}> {product?.businessId?.name}</Text>
                                                                <Text fontSize="1.1rem" fontWeight="500" mb={1} color={theme?.color?.secondary}> {product?.businessId?.address}</Text>
                                                                <Text fontSize="1.1rem" fontWeight="500" mb={0} color={theme?.color?.secondary}> {product?.businessId?.phone}</Text>
                                                            </Box>

                                                        </Box>
                                                        <Image
                                                            w="300px"
                                                            // h="200px"
                                                            objectFit="cover"
                                                            src={product?.businessId?.image?.length > 0 ? businessImage : noImage}
                                                            alt={product?.businessId?.image}
                                                            border="1px solid #E0E0E0"
                                                            p={1}
                                                            rounded={2}
                                                        />
                                                    </Flex>
                                                    <Box mt="10px" mb="30px">
                                                        {
                                                            locationSource &&
                                                            <AspectRatio ratio={16 / 9}>
                                                                <iframe
                                                                    src={locationSource}
                                                                    width="600" height="350"
                                                                    allowFullScreen=""
                                                                    loading="lazy"
                                                                ></iframe>
                                                            </AspectRatio>

                                                        }
                                                    </Box>
                                                </Box>



                                            </Box>








                                            {/* <Flex align="center" mr="10px" mt="10px">

                                                {product?.tags.length >= 1 ? <Text fontSize='14px' mr={3}>Tags:</Text> : null}
                                                {
                                                    product?.tags?.map((tag, i) => (
                                                        <Tag
                                                            key={i} cursor="pointer"
                                                            rounded={3} textTransform="uppercase"
                                                            fontSize='14px' mr="10px"
                                                            bg="linear-gradient(to right, #BEE3F8 50%, #edf2f9 50%)"
                                                            backgroundPosition="right bottom"
                                                            backgroundSize="200% 100%" transition="all .5s ease-out"
                                                            _hover={{ backgroundPosition: 'left bottom' }}
                                                            onClick={() => tagHandler(tag) }>
                                                            {tag.name}
                                                        </Tag>
                                                    ))
                                                }
                                            </Flex> */}

                                            <Flex align="center" mr="10px" mt="10px">
                                                {product?.categories.length >= 1 ? <Text fontSize='14px' mr={3}>Categories:</Text> : null}
                                                {
                                                    product?.categories?.map((cat, i) => (
                                                        <Tag
                                                            key={i} cursor="pointer"
                                                            fontSize='14px'
                                                            mr="10px"
                                                            rounded={3} textTransform="uppercase"
                                                            // bg="linear-gradient(to right, #BEE3F8 50%, #edf2f9 50%)"
                                                            backgroundPosition="right bottom"
                                                            backgroundSize="200% 100%" transition="all .5s ease-out"
                                                            _hover={{
                                                                backgroundPosition: 'left bottom',
                                                                backgroundColor: theme?.color?.accent
                                                            }}//{{ backgroundPosition: 'left bottom' }}
                                                            onClick={() => categoryTagHandlder(cat)}
                                                        >
                                                            {cat.name}
                                                        </Tag>
                                                    ))
                                                }
                                            </Flex>



                                            {/* Comment Section */}
                                            {
                                                pReview &&
                                                <Box id="productComment" pt="35px">
                                                    <ProductComment
                                                        productSlug={product?.slug}
                                                        productId={product?._id}
                                                        newReviewCreatedHandler={newReviewCreatedHandler}
                                                    />
                                                </Box>
                                            }

                                            {
                                                relatedProduct.length > 1 &&
                                                <Box mt={10}>
                                                    <Text as="h3" mb="10px" fontSize="1.3em" fontWeight="600">
                                                        Related Product
                                                    </Text>

                                                    <SimpleGrid
                                                        columns={{ base: 2, sm: 3, md: 3, lg: 4 }}
                                                        spacing="15px" mb={5}>
                                                        {
                                                            relatedProduct.map((p, i) => {
                                                                if (p._id !== product._id) {
                                                                    return <SingleProduct key={i} product={p} />
                                                                }
                                                            })
                                                        }
                                                    </SimpleGrid>
                                                </Box>
                                            }
                                        </>
                                        :
                                        <>
                                            <Alert
                                                status="info"
                                                variant="subtle"
                                                flexDirection="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                textAlign="center"
                                                height="38vh"
                                                // colorScheme="teal"
                                                bg={`rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 0.4'})`}
                                            >
                                                <AlertIcon boxSize="40px" mr={0} />
                                                <AlertTitle mt={4} mb={1} fontSize="lg">
                                                    Sorry, product not found!
                                                </AlertTitle>
                                            </Alert>
                                        </>
                                }
                            </>
                        )
                    }

                </Container>
            </Box>





            {/* modals */}
            {/* Modal */}
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent w={{ base: "90%", sm: "90%" }}>
                    <ModalHeader>
                        <InfoIcon mt="-2px" w={5} h={5} mr={2} color={`rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 0.8'})`} />
                        <Box as="span" fontWeight={300}>Message</Box>
                    </ModalHeader>
                    <ModalCloseButton>
                        <CloseIcon w={3} h={3} color="red.400" />
                    </ModalCloseButton>
                    <ModalBody>
                        <Alert
                            status="success"
                            variant="subtle"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            textAlign="center"
                            height="200px"
                            bg="">
                            <AlertIcon boxSize="40px" mr={0} />
                            <AlertTitle mt={4} mb={1} fontSize="lg">
                                Product added to cart successfully!
                            </AlertTitle>
                        </Alert>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            fontWeight={300} size="sm"
                            // colorScheme="teal" 
                            bg={`rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 1'})`}
                            color="white" border={`1px solid ${theme?.color?.accent}`}
                            _hover={{
                                bg: `rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 0.95'})`
                            }}
                            mr={3} onClick={modalCloseHandler} name="shop">
                            Continue shopping
                        </Button>
                        <Button
                            fontWeight={300} size="sm"
                            // colorScheme="teal" 
                            bg={`rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 1'})`}
                            color="white" border={`1px solid ${theme?.color?.accent}`}
                            _hover={{
                                bg: `rgba(${theme?.color?.accent.match(/\w\w/g).map(x => parseInt(x, 16)).toString() + ', 0.95'})`
                            }}
                            onClick={modalCloseHandler} name="cart">
                            Go to cart
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </>
    )
}

export default ProductDetails; 