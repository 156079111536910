import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
	Box, Center, Table, Thead, Tbody,
	Tr, Th, Td, chakra, Button, Input,
	NumberInput, NumberInputField, Icon,
	NumberInputStepper, NumberIncrementStepper,
	NumberDecrementStepper, Flex, Select
} from '@chakra-ui/react'
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons"

import { useTable, useSortBy, usePagination, useGlobalFilter, useAsyncDebounce } from "react-table"
import { Scrollbars } from 'react-custom-scrollbars'
import { BsCheck } from 'react-icons/bs'
import { IoCloseOutline } from 'react-icons/io5'

// Search component 
function GlobalSearch({ globalFilter, setGlobalFilter }) {
	const [value, setValue] = useState(globalFilter)

	const onChange = useAsyncDebounce(value => {
		// Set undefined to remove the filter entirely 
		setGlobalFilter(value || undefined)
	}, 200)

	return (
		<Box mb={4}>
			<Input
				type="text"
				maxW="250px"
				variant="flushed"
				placeholder="Search"
				value={value || ''}
				onChange={e => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
			/>
		</Box>
	)
}

function BlogTable({
	// props 
	data,
	columns,
	fetchData,
	pageCount: controlledPageCount,
	sortOn,
	defaultPageSize,
	tableHeightInPage,
	selectNoOfRows
}) {
	const skipPageResetRef = useRef()
	const history = useHistory();


	useEffect(() => {
		// After the table has updated, always remove the flag 
		skipPageResetRef.current = false
	})

	const tableInstance = useTable({
		columns,
		data,
		// columns: columnList,
		// data: dataList,
		initialState: { pageIndex: 0, pageSize: defaultPageSize },
		manualPagination: true,
		pageCount: controlledPageCount,
		autoResetPage: false,
		autoResetGlobalFilter: false,

		autoResetFilters: !skipPageResetRef.current,
		autoResetExpanded: !skipPageResetRef.current,
		autoResetGroupBy: !skipPageResetRef.current,
		autoResetSelectedRows: !skipPageResetRef.current,
		autoResetSortBy: !skipPageResetRef.current,
		autoResetRowState: !skipPageResetRef.current,
		// !skipPageResetRef.current
	},
		useGlobalFilter,
		useSortBy,
		usePagination
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		previousPage,
		canPreviousPage,
		nextPage,
		canNextPage,
		prepareRow,
		pageCount,
		state: { pageIndex, pageSize, globalFilter },
		setPageSize,
		gotoPage,
		setGlobalFilter
	} = tableInstance;


	useEffect(() => {
		fetchData({ pageIndex, pageSize, globalFilter, skipPageResetRef })
	}, [fetchData, pageIndex, pageSize, globalFilter])


	let { url } = useRouteMatch();

	if (url.endsWith('/')) {
		url = url.slice(0, -1)
	}

	const mapDate = (date) => {
		let day = new Date(date).getDate();
		day = day > 9 ? day : `0${day}`;

		let month = new Date(date).getMonth() + 1 // january gives 0 
		// month = month > 9 ? month : `0${month}`

		let year = new Date(date).getFullYear()
		year = year > 9 ? year : `0${year}`;

		switch (month) {
			case 1: { month = 'January'; break; }
			case 2: { month = 'February'; break; }
			case 3: { month = 'March'; break; }
			case 4: { month = 'April'; break; }
			case 5: { month = 'May'; break; }
			case 6: { month = 'June'; break; }
			case 7: { month = 'July'; break; }
			case 8: { month = 'August'; break; }
			case 9: { month = 'September'; break; }
			case 10: { month = 'October'; break; }
			case 11: { month = 'November'; break; }
			case 12: { month = 'December'; break; }
			default: return month;
		}
		let newDate = { month, day, year }
		return newDate;
	};

	return (
		<Box>
			{/* Search & List */}
			<Box py={3} px={4}>
				<GlobalSearch
					globalFilter={globalFilter}
					setGlobalFilter={setGlobalFilter}
				/>

				<Box h={tableHeightInPage} overflow="auto">
					<Scrollbars autoHide autoHideTimeout={500} autoHideDuration={200}>
						<Table {...getTableProps()}>
							<Thead>
								{
									headerGroups?.map(headerGroup => (
										<Tr {...headerGroup.getHeaderGroupProps()}
											bg="gray.300">
											{
												headerGroup.headers?.map(column => (
													<Th
														{
														...column.getHeaderProps(
															sortOn.includes(column.Header)
															&& column.getSortByToggleProps()
														)
														}
														fontSize=".8rem"
														sx={{ width: column.Header === 'Created At' && { base: 200, sm: 200, md: 200, lg: 200 } }}

													>

														{/* Display each cell header */}
														{column.render('Header')}

														{/* Display icons for sorting */}
														<chakra.span pl="10px">
															{
																column.isSorted
																	?
																	(
																		column.isSortedDesc
																			? <TriangleDownIcon aria-label="sorted descending" />
																			: <TriangleUpIcon aria-label="sorted ascending" />
																	)
																	: null
															}
														</chakra.span>
													</Th>
												))
											}
										</Tr>
									))
								}
							</Thead>

							<Tbody {...getTableBodyProps()} >
								{
									page?.map((row, i) => {
										prepareRow(row)
										return (
											<Tr
												{...row.getRowProps()}
												_hover={{ background: '#0000000a' }}
												cursor="pointer"
												onClick={
													() => { history.push(`${url}/${row.original._id}`) }
												}>
												{
													row?.cells?.map((cell, j) => {
														let date = ''
														if (cell.render('Cell').props.cell.column.Header === 'Created At') {
															date = cell.render('Cell').props.cell.value

														}

														let createDate = mapDate(date);


														if (cell.render('Cell').props.cell.column.Header === 'Publish') {

															return (
																<Td fontSize=".8rem" {...cell.getCellProps()} w="100px">
																	<Center>
																		{

																			cell.render('Cell').props.cell.value === 'publish'
																				? <Icon as={BsCheck} h={6} w={6} color="green.500" />
																				: <Icon as={IoCloseOutline} h={6} w={6} color="red.500" />
																		}
																	</Center>
																</Td>
															)
														}
														else if (cell.render('Cell').props.cell.column.Header === 'Created At') {
															return (
																<Td fontSize=".8rem" {...cell.getCellProps()}>
																	{
																		`${createDate.month} ${createDate.day}, ${createDate.year}`
																	}
																</Td>
															)
														}
														else {
															return (
																<Td {...cell.getCellProps()} fontSize=".8rem">
																	{cell.render('Cell')}
																</Td>
															)
														}
													})
												}
											</Tr>
										)
									})
								}
							</Tbody>
						</Table>
					</Scrollbars>
				</Box>
			</Box>


			{/* Pagination */}
			<Box p="20px">
				<Flex flexWrap="wrap">
					<Button
						onClick={() => gotoPage(0)}
						size="xs"
						mr="10px" colorScheme="teal"
						variant="outline"
						disabled={!canPreviousPage}
					>
						{`<<`}
					</Button>
					<Button
						onClick={() => previousPage()}
						size="xs"
						mr="10px" colorScheme="teal"
						variant="outline"
						disabled={!canPreviousPage}
					>
						{`<`}
					</Button>
					<Button
						onClick={() => nextPage()}
						size="xs"
						mr="10px" colorScheme="teal"
						variant="outline"
						disabled={!canNextPage}
					>
						{`>`}
					</Button>
					<Button
						onClick={() => gotoPage(Math.ceil(pageCount - 1))}
						size="xs"
						mr="10px" colorScheme="teal"
						variant="outline"
						disabled={!canNextPage}
					>
						{`>>`}
					</Button>

					<chakra.span mr="10px" fontWeight="600">
						{ Math.ceil( pageIndex + 1)} of {pageCount}
					</chakra.span>

					<chakra.span>
						| Go to page &nbsp;
					</chakra.span>
					<NumberInput
						defaultValue={Math.ceil(pageIndex + 1)}
						min={1} max={pageCount}
						onChange={(value) => gotoPage((+Math.ceil(value)) - 1)}
						w="80px" size="sm" mr="20px"
						keepWithinRange={true}
						value={pageIndex+1}
					>
						<NumberInputField />
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					</NumberInput>

					<Box>
						<Select
							value={pageSize} variant="flushed" size="sm"
							w="110px" colorScheme="red"
							onChange={e => setPageSize(Number(e.target.value))}>
							{
								selectNoOfRows.map(pageSize => (
									<option key={pageSize} value={pageSize}>
										Show {pageSize}
									</option>
								))
							}
						</Select>
					</Box>
				</Flex>
			</Box>
		</Box>
	)
}

export default BlogTable; 