import React, { useEffect, useState } from 'react';
// import AOS from 'aos'
import './mainLanding.css';
// import "aos/dist/aos.css";
// import LandingFooter from './landingFooter';
import LandingContact from './landingContact';
import ElasticCarousal from '../../styledComponents/ElasticCarousal';
import LandingHeader from './LandingHeader';
import Main from './Main';
import About from './About';
import Feature from './Feature';
// import LandingNav from './LandingNav';
import { Box } from '@chakra-ui/react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { config } from '../../config';
import MetaDecorator from '../../components/metaDecorator';
import useDomain from '../../service/DomainHook';
import DomainUtils from '../../service/utils/DomainUtils';

const MainLanding = () => {
  const [hasSubDomain] = useDomain();

  const [page, setPage] = useState({
    menu: [],
    pageHeader: {
      title: null,
      image: null,
    },
    pageFeature: [],
    pageSlider: null,
    social: [],
  });

  const location = useLocation();

  const getData = async () => {
    window.scrollTo(0, 0);

    const baseUrl = config.baseUrl;
    // AOS.init({
    //     offset: 100,
    //     duration: 600, // values from 0 to 3000, with step 50ms
    //     easing: 'ease', // default easing for AOS animations
    //     once: false
    // });

    let getUrl = `${baseUrl}/api/v1/pages/superAdmin`;

    if (hasSubDomain) {
      const [franchisee] = await DomainUtils();
      getUrl = `${baseUrl}/api/v1/pages/cityAdmin/${franchisee}`;
    }

    await axios
      .get(getUrl)
      .then((response) => {
        const { pageContact } = response?.data?.data;
        localStorage.setItem('subscriberLabel', pageContact?.checkboxLabel);

        // console.log("response?.data?.data:::", response?.data?.data);
        setPage(response?.data?.data);
      })
      .catch((error) => console.log('error:::', error));
  };

  useEffect(() => {
    getData();
  }, [location]);

  return (
    <div>
      <MetaDecorator
        title={page?.pageHeader?.title}
        description={page?.meta?.description}
        keywords={page?.meta?.keywords}
        featureImage={page.pageHeader?.image}
      />

      <LandingHeader
        image={page.pageHeader?.image}
        title={page.pageHeader?.title}
        style={page.pageHeader?.style}
      />

      <div>
        <Feature feature={page.pageFeature} />
        <Main pageMain={page.pageMain} />
        <About content={page.pageAbout} />

        {/* <Box py="50px" style={page?.pageSlider?.style}>
          {page?.pageSlider?.itemsToShow > 0 ? (
            <ElasticCarousal
              pageSlider={page?.pageSlider}
              hasSubDomain={hasSubDomain}
            />
          ) : null}
        </Box> */}

        <Box py="50px" bg={page?.pageSlider?.style}>
          {page?.pageSlider?.itemsToShow > 0 ? (
            <ElasticCarousal
              pageSlider={page?.pageSlider}
              hasSubDomain={hasSubDomain}
            />
          ) : null}
        </Box>

        <LandingContact
          content={page.pageContact}
          hasSubDomain={hasSubDomain}
          globalSettings={page?.globalSettings}
        />
        {/* <LandingFooter content={page.footer} social={page.social} /> */}
      </div>
    </div>
  );
};

export default MainLanding;
