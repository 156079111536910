import React, { useState } from 'react'; 
import { useForm } from 'react-hook-form' 
import { 
    Modal, ModalOverlay, ModalContent, ModalHeader, useToast, 
    ModalBody, ModalCloseButton, InputGroup, InputRightElement, 
    Button, FormControl, FormLabel, Input, FormErrorMessage 
} from "@chakra-ui/react" 
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons' 
import axios from 'axios' 
import {config} from '../../config'

function ChangePasswordeModal(props) { 
    const { baseUrl } = config 
    const toast = useToast() 

    const { isOpen, onClose } = props; 

    const { register, handleSubmit, errors, watch } = useForm({ 
        mode: 'onTouched', 
        shouldFocusError: false 
    }) 

    const [passwordVisible1, setPasswordVisible1] = useState(false) 
    const [passwordVisible2, setPasswordVisible2] = useState(false) 
    const [passwordVisible3, setPasswordVisible3] = useState(false) 

    let newPassword = watch('newPassword') 

    const formSubmit = (data) => { 
        data.email = localStorage.getItem('email') 
        const token = localStorage.getItem('token'); 
        axios({ 
            url: '/api/v1/users/changePassword', 
            method: 'post', 
            baseURL: baseUrl, 
            headers: {'Authorization': `Bearer ${token}`}, 
            data: data 
        }) 
        .then(res => { 
            toast({ 
                title: "Password Changed Successfully", 
                description: "Password changed. So next time you have to login with new password", 
                status: "success", 
                duration: 4000, 
                isClosable: true, 
            }) 
            onClose() 
        }) 
        .catch(err => console.log(err)) 
    } 

    return ( 
        <> 
            <Modal isOpen={isOpen} onClose={onClose} isCentered> 
                <ModalOverlay /> 
                <ModalContent> 
                    <ModalHeader>Change Your Password</ModalHeader> 
                    <ModalCloseButton /> 
                    <ModalBody> 
                        <form onSubmit={handleSubmit(formSubmit)}> 
                            <FormControl isInvalid={errors.currentPassword}> 
                                <FormLabel m={0}>Current Password</FormLabel> 
                                <InputGroup> 
                                    <Input 
                                        type={passwordVisible1 ? 'text' : 'password'}
                                        name="currentPassword" autoComplete="off"
                                        ref={register({ 
                                            required: 'Current password is required', 
                                            minLength: { 
                                                value: 8, 
                                                message: 'Minimum 8 characters' 
                                            } 
                                        })} 
                                        variant="flushed" size="sm" /> 
                                    <InputRightElement cursor="pointer"> 
                                        { 
                                            passwordVisible1 ? 
                                            <ViewOffIcon 
                                                w={4} h={4} 
                                                onClick={() => setPasswordVisible1(!passwordVisible1)} /> 
                                            : 
                                            <ViewIcon 
                                                w={4} h={4} 
                                                onClick={() => setPasswordVisible1(!passwordVisible1)} /> 
                                        } 
                                    </InputRightElement> 
                                </InputGroup> 
                                <FormErrorMessage mb="20px">{errors.currentPassword?.message}</FormErrorMessage> 
                            </FormControl> 

                            <FormControl isInvalid={errors.newPassword}> 
                                <FormLabel m={0} mt={4}>New Password</FormLabel> 

                                <InputGroup> 
                                    <Input 
                                        type={passwordVisible2 ? 'text' : 'password'} 
                                        name="newPassword" autoComplete="off"
                                        ref={register({ 
                                            required: 'New password is required', 
                                            minLength: { 
                                                value: 8, 
                                                message: 'Minimum 8 characters' 
                                            } 
                                        })} 
                                        variant="flushed" size="sm" /> 
                                    <InputRightElement cursor="pointer"> 
                                        { 
                                            passwordVisible2 ? 
                                            <ViewOffIcon 
                                                w={4} h={4} 
                                                onClick={() => setPasswordVisible2(!passwordVisible2)} /> 
                                            : 
                                            <ViewIcon 
                                                w={4} h={4} 
                                                onClick={() => setPasswordVisible2(!passwordVisible2)} /> 
                                        } 
                                    </InputRightElement> 
                                </InputGroup> 
                                <FormErrorMessage mb="20px">{errors.newPassword?.message}</FormErrorMessage>
                            </FormControl> 

                            <FormControl isInvalid={errors.newConfirmPassword}> 
                                <FormLabel m={0} mt={4}>Confirm New Password</FormLabel> 
                                
                                <InputGroup> 
                                    <Input 
                                        type={passwordVisible3 ? 'text' : 'password'}
                                        name="newConfirmPassword" autoComplete="off"
                                        ref={register({  
                                            required: 'Confirm new password field is required', 
                                            minLength: { 
                                                value: 8, 
                                                message: 'Minimum 8 characters', 
                                            }, 
                                            validate: (value) => value === newPassword || "Password doesn't match"
                                        })} 
                                        variant="flushed" size="sm" /> 
                                    <InputRightElement cursor="pointer"> 
                                        { 
                                            passwordVisible3 ? 
                                            <ViewOffIcon 
                                                w={4} h={4} 
                                                onClick={() => setPasswordVisible3(!passwordVisible3)} /> 
                                            : 
                                            <ViewIcon 
                                                w={4} h={4} 
                                                onClick={() => setPasswordVisible3(!passwordVisible3)} /> 
                                        } 
                                    </InputRightElement> 
                                </InputGroup> 
                                <FormErrorMessage mb="20px">{errors.newConfirmPassword?.message}</FormErrorMessage>
                            </FormControl> 
                            
                            <Button 
                                type="submit" 
                                my={5} 
                                colorScheme="teal" 
                                variant="outline" 
                                boxShadow="lg" > 
                                Save 
                            </Button> 
                        </form> 
                    </ModalBody> 
                </ModalContent> 
            </Modal> 
        </> 
    ) 
} 

export default ChangePasswordeModal; 