import React, { useState } from 'react';
import {
    SimpleGrid, Flex,
    Box, Input, Textarea, Image,
    Text, Button, FormControl, FormLabel, Center, useToast
} from '@chakra-ui/react';
import { config } from '../../config'
import axios from 'axios';
import { BsTrash } from "react-icons/bs";
import { FaTrash } from 'react-icons/fa';

function Feature({ id, pageContent, setPageContent }) {

    const { pageFeature } = pageContent;
    const token = localStorage.getItem('token');
    const toast = useToast();
    const { baseUrl } = config;
    const [selectedIndex, setSelectedIndex] = useState(0);

    const featureTextHandler = (e, index) => {
        const features = [...pageContent.pageFeature];
        features[index][e.target.name] = e.target.value;
        setPageContent({
            ...pageContent,
            pageFeature: features
        })
    };

    const addFeatureHandler = () => {
        const features = [...pageContent.pageFeature];
        features.push({ title: '', text: '', image: '' });
        setPageContent({
            ...pageContent,
            pageFeature: features
        })
    };

    const deleteFeatureItem = (index) => {
        const features = [...pageContent.pageFeature].filter((_, idx) => idx !== index);
        setPageContent({
            ...pageContent,
            pageFeature: features
        });
    };

    const imageHandler = (e) => {

        const formData = new FormData();
        formData.append('featureImage', e.target.files[0]);
        formData.append('imageIndex', selectedIndex);
        const features = [...pageContent.pageFeature];

        axios({
            url: `/api/v1/pages/upload/${id}`,
            method: 'post',
            baseURL: baseUrl,
            data: formData,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                features[selectedIndex].image = response.data.data.pageFeature[selectedIndex].image;
                setPageContent({
                    ...pageContent,
                    pageFeature: features
                })
                toast({
                    title: "Feature image updated successfully.",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                })
            })
            .catch(err => {
                console.log('error', err);
            })
    }


    const deleteImageHandler = (index) => {

        const features = [...pageContent.pageFeature];
        features[index].image = '';

        const dataObj = {
            imageUrl: pageContent.pageFeature.image,
            field: `pageFeature.${index}.image`
        };

        axios({
            url: `/api/v1/pages/removeImage/${id}`,
            method: 'PATCH',
            baseURL: baseUrl,
            data: dataObj,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            setPageContent({
                ...pageContent,
                pageFeature: features
            });
            toast({
                title: "Feature image deleted successfully.",
                status: "info",
                duration: 4000,
                isClosable: true,
            })
        })
            .catch(error => {
                console.log(error.message);
            });
    };


    return (
        <Box bg="white" rounded="md" boxShadow="lg" p={{ base: '10px', md: '30px' }}>
            <Flex>
                <Text as="h4" fontSize="1.4rem" fontWeight="600" mr="20px">
                    Feature Section
                </Text>

                <Button
                    alignSelf="center"
                    onClick={addFeatureHandler}
                    size="xs"
                    borderRadius="sm"
                >
                    Add New
                </Button>

            </Flex>

            <SimpleGrid
                columns={{ base: '1', md: '2', xl: '3' }}
                spacing="20px" p={["10px", "10px", "20px"]} mt="20px"
            >
                {
                    pageFeature.map((feature, index) => {
                        return (
                            <Box borderWidth={1} p="15px" rounded="md" boxShadow="sm" position="relative" key={index}>
                                <Input
                                    name="title"
                                    mb="10px"
                                    mt='8px'
                                    size="sm"
                                    placeholder="Title"
                                    variant='filled'
                                    type="text"
                                    value={feature.title}
                                    onChange={(e) => featureTextHandler(e, index)}
                                />
                                <Textarea
                                    size='sm'
                                    name="text"
                                    variant='filled'
                                    placeholder="Body Text"
                                    value={feature.text}
                                    onChange={(e) => featureTextHandler(e, index)}
                                />


                                <FormControl mr={1} mt="15px"


                                >
                                    <Box
                                        mb='5px'

                                    >
                                        <FormLabel htmlFor='featureImg'
                                            textAlign="center"
                                            cursor="pointer"
                                            fontSize='sm'
                                            color="gray.500"
                                            p='10px 5px 5px'
                                            w='100%'
                                            h='100%'
                                            lineHeight="20px"
                                            border="1px dashed #BDBDBD"
                                            onClick={() => setSelectedIndex(index)}
                                        >
                                            Upload Icon

                                        </FormLabel>
                                        <Input

                                            type="file"
                                            name="featureImg"
                                            id='featureImg'
                                            accept="image/*"
                                            onChange={(e) => imageHandler(e)}
                                            style={{ display: "none" }} />

                                    </Box>

                                </FormControl>

                                {feature?.image ?

                                    <Center>

                                        <Box display="inline-block" position='relative'>
                                            <Image objectFit='cover' maxW='200px' h='auto' src={feature?.image} alt="Feature image" />

                                            {/* Delete Image */}
                                            <Button
                                                position="absolute"
                                                top="1px"
                                                right="1px"
                                                size="xs"
                                                borderRadius={0}
                                                _hover={{ outline: 'none' }}
                                                _focus={{ outline: 'none' }}
                                                _active={{ bg: 'red.400', color: 'white' }}
                                                onClick={() => deleteImageHandler(index)}
                                            >
                                                <FaTrash />
                                            </Button>
                                        </Box>
                                    </Center> : null}


                                {/* Delete Feature Item */}
                                <Button
                                    p={0} mr='2px' mt='1px' bg='transparent' position='absolute' top='-1' right='-2' size='sm'
                                    _hover={{
                                        bg: 'transparent',
                                    }}
                                    _active={{
                                        bg: 'transparent',
                                    }}
                                    _focus={{ outline: 'none' }}
                                    onClick={() => deleteFeatureItem(index)}>
                                    <BsTrash />
                                </Button>
                            </Box>
                        )
                    })
                }

            </SimpleGrid>

        </Box>
    );
}

export default Feature;
