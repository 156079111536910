import React from 'react';
import { NavLink } from 'react-router-dom'; 
import { chakra, Icon, Box } from '@chakra-ui/react'; 
import './Sidenav.css'; 

function SidenavMenu(props) { 
    const { 
        menuName, 
        navbarOffInMobileViewHandler, 
        menu2, 
        url, 
        icon, 
        mainPath, 
        matchPath 
    } = props; 
    return ( 
        <> 
            <NavLink to={url} onClick={navbarOffInMobileViewHandler}> 
                {/* <Box>  */}
                    <chakra.span 
                        className={`icon ${menu2 && 'icon2'}`} 
                        mt="-3px" color={mainPath === matchPath && 'teal.500'}> 
                        <Icon as={icon} w={5} h={5} /> 
                    </chakra.span> 

                    <Box>
                        <chakra.span 
                            className={`title ${menu2 && 'title2'}`} 
                            color={mainPath === matchPath && 'teal.500'}> 
                            { menuName } 
                        </chakra.span> 
                    </Box>
                {/* </Box> */}
            </NavLink> 
        </> 
    ) 
} 

export default SidenavMenu;