import React from 'react'; 
import {Box, AccordionButton, AccordionIcon} from '@chakra-ui/react'

function CollapsableButton({name}) { 
    return ( 
        <h5> 
            <AccordionButton> 
                <Box flex="1" textAlign="left"> 
                    { name } 
                </Box> 
                <AccordionIcon /> 
            </AccordionButton> 
        </h5> 
    ) 
} 

export default CollapsableButton; 